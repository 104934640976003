import GMaps from "@/components/shared/Maps/GMaps";

const PinLocation = () => {
  const containerStyles = {
    width: "100%",
    height: "400px",
  };

  return (
    <div>
      <h4 className="font-medium text-xl">Pin the location of your property</h4>
      <div className="flex items-start gap-4 mt-5">
        <div className="px-3 py-3 flex flex-col gap-2 bg-laara-secondary/35 border border-[#A3A3A3] rounded-[8px] max-w-[728px] w-full h-[618px]">
          <div className="max-w-[648px] h-[558px] w-full mx-auto space-y-5">
            <p className="text-[#6D6D6D] text-lg">
              This is the location we'll show guests on our site. Move the map
              to find the exact location of your property, then click to drop
              the pin.
            </p>
            <div className="mx-auto">
              <GMaps
                containerStyle={containerStyles}
                interactive={true}
                placesAutoComplete={true}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PinLocation;
