import { clsx } from "clsx";
import { twMerge } from "tailwind-merge";

export function cn(...inputs) {
  return twMerge(clsx(inputs));
}

export const getStatus = (status) => {
  switch (status) {
    case "PENDING_PAYMENT":
      return "pending";
    case "UPCOMING":
      return "upcoming";
    case "AWAITING_REVIEW":
      return "awaiting review";
    case "CHECKED_IN":
      return "checked in";
    case "COMPLETED":
      return "completed";
    case "FAILED":
      return "failed";
    default:
      return null;
  }
};

export const getStatusBadgeColor = (status, text) => {
  switch (status) {
    case "PENDING_PAYMENT":
      return text ? "text-[#F5BD02]" : "bg-pending";
    case "UPCOMING":
      return text ? "text-laara-primary" : "bg-laara-primary";
    case "AWAITING_REVIEW":
      return text ? "text-awaiting-review" : "bg-awaiting-review";
    case "CHECKED_IN":
      return text ? "text-checked-in" : "bg-checked-in";
    case "COMPLETED":
      return text ? "text-completed" : "bg-completed";
    case "FAILED":
      return "text-red-500";
    default:
      return null;
  }
};
