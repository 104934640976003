import { TfiAngleLeft } from 'react-icons/tfi';
import { useNavigate } from 'react-router-dom';  // Assuming you're using react-router

const BookingSuccess = () => {
  const navigate = useNavigate();

  return (
    <div className='p-4 flex flex-col mx-auto lg:ml-16 h-screen'>
      {/* Back Button */}
      <div className='flex items-center text-[hsla(0,0%,38%,1)] gap-2 '>
        < TfiAngleLeft 
          className="hover:scale-125 duration-300 cursor-pointer hover:shadow-2xl font-semibold" 
          size={20} 
          onClick={() => navigate(-1)}  // Go back to the previous page
        /> 
        <h3 className='font-semibold'>Booking</h3>
      </div>

      {/* Success Container */}
      <div className='lg:w-[413px] mx-auto flex items-center flex-col border rounded-lg p-4 shadow-2xl'>
        <svg className='w-24 h-24 mt-4 mb-2' viewBox="0 0 96 95" fill="none" xmlns="http://www.w3.org/2000/svg">
          <circle cx="48" cy="47.5" r="47.5" fill="#0466C8" fillOpacity="0.05"/>
          <path fillRule="evenodd" clipRule="evenodd" d="M48.4993 69.3346C61.386 69.3346 71.8327 58.8879 71.8327 46.0013C71.8327 33.1147 61.386 22.668 48.4993 22.668C35.6127 22.668 25.166 33.1147 25.166 46.0013C25.166 58.8879 35.6127 69.3346 48.4993 69.3346ZM61.4698 40.1686C62.1145 39.4485 62.0534 38.3422 61.3333 37.6975C60.6132 37.0528 59.5069 37.1139 58.8622 37.834L51.0008 46.6148C49.4078 48.394 48.3351 49.5859 47.4179 50.3568C46.5442 51.0912 46.031 51.2513 45.5827 51.2513C45.1344 51.2513 44.6212 51.0912 43.7475 50.3568C42.8303 49.5859 41.7575 48.394 40.1646 46.6147L38.1365 44.3495C37.4918 43.6294 36.3855 43.5683 35.6654 44.213C34.9453 44.8577 34.8842 45.964 35.5289 46.6841L37.6436 49.0461C39.1272 50.7034 40.3647 52.0857 41.4955 53.0361C42.6917 54.0415 43.9818 54.7513 45.5827 54.7513C47.1835 54.7513 48.4737 54.0415 49.6699 53.0361C50.8006 52.0857 52.0381 50.7034 53.5218 49.0461L61.4698 40.1686Z" fill="#0466C8"/>
        </svg>

        {/* Text Content */}
        <div className='text-center lg:w-[318px] '>
          <h3 className='font-semibold text-lg'>Reservation Complete</h3>
          <p className='font-normal text-base'>
            You have made a successful payment of $180 (15% of the total reservation fee of $1,200). 
            The remaining balance is due upon check-in.
          </p>
        </div>

        {/* Done Button */}
        <button className='w-full lg:w-[337px] border rounded-full mt-7 mb-4 p-3 hover:drop-shadow-2xl hover:shadow-xl hover:translate-y-1 duration-300 transition-all bg-[hsla(210,96%,40%,1)] text-white'>
          Done
        </button>
      </div>
    </div>
  );
}

export default BookingSuccess;
