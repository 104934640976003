import React from "react";
import { Link } from "react-router-dom";

const Unauthenticated = () => {
  return (
    <div className="flex flex-col xl:flex-row items-center gap-10">
      <Link
        to="/landingpage"
        className="text-laara-primary font-medium text-xl"
      >
        List A Property
      </Link>
      <div className="flex flex-col justify-center items-center xl:flex-row gap-3 w-[189px] xl:w-auto ">
        <Link
          to="/hostlogin"
          className="text-laara-primary font-semibold text-xl py-2 px-6 rounded-[32px] border border-laara-primary text-center w-full"
        >
          Sign In
        </Link>
        <Link
          to="/hostregister"
          className="text-white font-semibold text-xl bg-laara-primary py-2 px:[30px] xl:px-7 rounded-[32px] w-full text-center"
        >
          Register
        </Link>
      </div>
    </div>
  );
};

export default Unauthenticated;
