import { useState } from 'react';
import { IoChevronBackSharp } from "react-icons/io5";

const SchedulePromo = () => {
  const [activeButton, setActiveButton] = useState('schedule'); // Default active button
  const [discountType, setDiscountType] = useState('flat');

  const handleSubmit = (e) => {
    e.preventDefault();
    // Handle form submission logic here
  };

  return (
    <main className="flex flex-col p-4 w-auto">
      <div className="flex items-center gap-2 mb-4">
        <IoChevronBackSharp size={20} className="hover:scale-150 hover:drop-shadow-lg cursor-pointer" />
        <h1 className="text-sm lg:text-xl font-bold">Promotions</h1>
      </div>
      <div className="flex flex-row gap-4 mb-6">
        <button
          onClick={() => setActiveButton('start')}
          className={`border text-sm rounded-3xl p-2 ${activeButton === 'start' ? 'bg-blue-500 text-white' : 'bg-[hsla(185,100%,98%,1)] text-[hsla(210,96%,40%,1)]'} hover:bg-blue-700 hover:text-white transition-colors`}
        >
          Start Promotion
        </button>
        <button
          onClick={() => setActiveButton('schedule')}
          className={`border text-xs lg:text-sm rounded-3xl p-2 ${activeButton === 'schedule' ? 'bg-blue-500 text-white' : 'bg-[hsla(185,100%,98%,1)] text-[hsla(210,96%,40%,1)]'} hover:bg-blue-700 hover:text-white transition-colors`}
        >
          Schedule a Promotion
        </button>
        <button
          onClick={() => setActiveButton('history')}
          className={`border text-sm rounded-3xl p-2 ${activeButton === 'history' ? 'bg-blue-500 text-white' : 'bg-[hsla(185,100%,98%,1)] text-[hsla(210,96%,40%,1)]'} hover:bg-blue-700 hover:text-white transition-colors`}
        >
          Promotion History
        </button>
      </div>
      <div className="lg:mr-[250px] lg:pb-9">
        <form onSubmit={handleSubmit} className="flex flex-col mx-auto p-8 bg-[hsla(185,100%,98%,1)] lg:w-[1224px] lg:h-[600px] shadow-sm rounded-md">
          <div className="mb-4">
            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="title">
              Promotion Title
            </label>
            <input
              type="text"
              id="title"
              name="title"
              placeholder="Black Friday, New Year's, etc."
              className="w-full px-3 py-2 border-[1px] rounded-md text-gray-700 focus:outline-none focus:border-blue-300 focus:border-[2px]"
              required
            />
          </div>
          <div className="mb-4">
            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="description">
              Promotion Description
            </label>
            <textarea
              id="description"
              name="description"
              placeholder="What is it about? e.g., Valentine, Ramadan, Christmas, etc."
              className="w-full px-3 py-2 border-[1px] rounded-md text-gray-700 focus:outline-none focus:border-blue-300 focus:border-[2px]"
              required
            />
          </div>
          <div className="mb-4">
            <span className="block text-gray-700 text-sm font-bold mb-2">Promotion Dates</span>
            <div className="flex flex-col lg:flex-row items-center gap-2">
              <div className=" w-full">
                <label htmlFor="startDate" className="text-sm text-gray-700">Start Date</label>
                <input
                  type="date"
                  id="startDate"
                  name="startDate"
                  className="px-3 py-2 border-[1px] rounded-md text-gray-500 focus:outline-none focus:border-blue-300 focus:border-[2px]"
                  required
                />
              </div>
              <div className=" w-full">
                <label htmlFor="endDate" className="text-sm text-gray-700">End Date</label>
                <input
                  type="date"
                  id="endDate"
                  name="endDate"
                  className="px-3 py-2 border-[1px] rounded-md text-gray-500 focus:outline-none focus:border-blue-300 focus:border-[2px]"
                  required
                />
              </div>
            </div>
          </div>
          <div className="mb-4">
            <span className="block text-gray-700 text-sm font-bold mb-2">Promotion Type</span>
            <div className="flex items-center mb-2">
              <input
                type="radio"
                id="flat"
                name="discountType"
                value="flat"
                checked={discountType === 'flat'}
                onChange={() => setDiscountType('flat')}
                className="mr-2"
              />
              <label htmlFor="flat" className="text-gray-700">
                Flat Amount
              </label>
            </div>
            <div className="flex items-center">
              <input
                type="radio"
                id="percentage"
                name="discountType"
                value="percentage"
                checked={discountType === 'percentage'}
                onChange={() => setDiscountType('percentage')}
                className="mr-2"
              />
              <label htmlFor="percentage" className="text-gray-700">
                Percentage Discount
              </label>
            </div>
          </div>
          <div className="flex justify-end">
            <button
              type="submit"
              className="relative w-[305px] items-center text-center px-2 py-3 border-[1px] border-transparent text-sm font-medium rounded-3xl shadow-sm text-white bg-[hsla(210,96%,40%,1)] hover:drop-shadow-lg hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 transition-all duration-0 lg:mr-[105px]"
            >
              Start Promotion
            </button>
          </div>
        </form>
      </div>
    </main>
  );
};

export default SchedulePromo;
