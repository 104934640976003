import { useState, useCallback, useEffect } from "react";
import { FiUploadCloud, FiX } from "react-icons/fi";
import { useDropzone } from "react-dropzone";
import Consider from "@/components/Listings/Consider";
import useFetchRoomDetails from "./globalrooms/useFetchRoomDetails";
import { useParams } from "react-router-dom";
import Header from "./globalrooms/Header";
import Modal from "./globalrooms/PictureModal";
import { uploadRoomImages } from "@/api";

const RoomPhoto = () => {
  const { id, number } = useParams();
  const { rooms, loading, error } = useFetchRoomDetails(id);
  const [photos, setPhotos] = useState([]);
  const [localPhotos, setLocalPhotos] = useState([]);
  const [roomType, setRoomType] = useState("");
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedImageIndex, setSelectedImageIndex] = useState(null);

  // Handle file drop
  const onDrop = useCallback((acceptedFiles) => {
    const newPhotos = acceptedFiles.map((file) => ({
      file,
      preview: URL.createObjectURL(file),
    }));

    // Replace the current photos with the newly uploaded ones
    setPhotos(newPhotos);
    setLocalPhotos(newPhotos.map((p) => p.file));
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  useEffect(() => {
    if (!loading && rooms.length > 0) {
      const currentRoom = rooms.find((room) => room.number === number);
      if (currentRoom) {
        setRoomType(currentRoom.roomTypes.name);
        setPhotos(
          currentRoom.roomTypes.roomTypeImages.map((img) => ({
            url: img.images.url,
          })),
        );
      } else {
        console.error("Room not found");
      }
    }
  }, [loading, rooms, number]);

  const removePhoto = (index) => {
    setPhotos((prevPhotos) => prevPhotos.filter((_, i) => i !== index));
    setLocalPhotos((prevLocalPhotos) =>
      prevLocalPhotos.filter((_, i) => i !== index),
    );
  };

  const openModal = (index = 0) => {
    setSelectedImageIndex(index);
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
  };

  const handleSaveChanges = async (event) => {
    event.preventDefault();

    const roomTypeId = rooms.find((room) => room.number === number).roomTypes
      .id;
    const formData = new FormData();

    localPhotos.forEach((photo) => {
      formData.append("images", photo); // Use the key 'images' as in Postman
    });

    try {
      const result = await uploadRoomImages(id, roomTypeId, formData);
      alert("Photos uploaded successfully:", result);
    } catch (error) {
      console.error("Error uploading photos:", error);
    }
  };

  if (loading) {
    return (
      <div className="flex justify-center items-center h-screen">
        <div
          className="w-16 h-16 border-4 border-t-4 border-gray-200 border-t-blue-500 border-solid rounded-full animate-spin"
          aria-label="Loading"
        ></div>
      </div>
    );
  }

  if (error) return <p>Error: {error}</p>;

  return (
    <>
      <form>
        <div className="p-8 bg-base-white">
          <Header
            linkTo={`/room-details/${id}/${roomType}/${number}`}
            title="Room Photos"
          />
          <h4 className="p-8 pl-0">Where will your guests sleep?</h4>
          <div className="flex flex-wrap lg:flex-nowrap gap-20">
            <div className="w-[1077px] border mx-auto bg-laara-secoondary rounded-lg p-8">
              <p className="text-sm font-medium text-left mb-2 p-1">
                <span className="font-normal text-black">
                  Upload additional high-quality photos of the room.
                </span>
                <span className="text-[#6D6D6D]">
                  {" "}
                  Photos should showcase the bed(s), decor, and overall ambiance
                  of the sleeping space.
                </span>
              </p>

              <div
                {...getRootProps()}
                className="rounded-lg p-3 mx-3 mb-2 text-center cursor-pointer border border-dashed"
              >
                <input {...getInputProps()} />
                <div className="w-full flex items-center justify-center">
                  <FiUploadCloud
                    className="text-lg text-gray-400 mb-1"
                    size={30}
                  />
                </div>
                {isDragActive ? (
                  <p className="text-sm text-gray-500">
                    Drop the files here ...
                  </p>
                ) : (
                  <p className="text-sm font-medium">
                    Drag and drop or click to upload photos
                  </p>
                )}
              </div>

              {photos.length > 0 && (
                <div className="flex gap-3 mt-6">
                  <div className="w-3/4 flex flex-col items-center">
                    {/* Main image is always the first image */}
                    <img
                      src={photos[0].preview || photos[0].url}
                      alt="Main room photo"
                      className="w-full h-80 object-cover rounded-lg"
                    />
                    {photos.length > 4 && (
                      <button
                        type="button"
                        onClick={() => openModal(0)}
                        className="mt-2 py-2 px-4 bg-blue-500 text-white rounded-[36px]"
                      >
                        View All Photos
                      </button>
                    )}
                  </div>

                  <div className="w-1/4 flex flex-col gap-2">
                    {photos.slice(1, 5).map((photo, index) => (
                      <div key={index} className="relative">
                        <img
                          src={photo.preview || photo.url}
                          alt={`Room photo ${index + 1}`}
                          className="w-full h-24 object-cover rounded-lg cursor-pointer"
                          onClick={() => openModal(index + 1)}
                        />
                        <button
                          type="button"
                          onClick={(e) => {
                            e.stopPropagation();
                            removePhoto(index + 1);
                          }}
                          className="absolute top-1 right-1 bg-red-500 text-white rounded-full p-0.5 text-xs"
                        >
                          <FiX size={20} />
                        </button>
                      </div>
                    ))}
                  </div>
                </div>
              )}
            </div>

            <div className="w-[400px]">
              <Consider
                title={
                  "Here are some tips on taking great photos of your property"
                }
                data={[
                  "If you don't know who took a photo, don't use it",
                  "Don't include other people in your photos",
                  "Take photos in natural light and use a tripod for the best quality",
                  "Ensure the photo matches the room type you're listing",
                ]}
              />
            </div>
          </div>

          <div className="flex justify-between mt-8">
            <button
              onClick={handleSaveChanges}
              className="py-2 px-4 bg-blue-500 text-white rounded-[36px] w-[216px] h-12"
            >
              Save Changes
            </button>
          </div>
        </div>
      </form>

      {modalOpen && (
        <Modal
          isOpen={modalOpen}
          closeModal={closeModal}
          images={photos.map((photo) => photo.preview || photo.url)}
        />
      )}
    </>
  );
};

export default RoomPhoto;
