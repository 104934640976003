import React from 'react';
import tick from "@/assets/ListingProcess/tick-circle.svg"
import house from "@/assets/ListingProcess/house-2.svg"
import Group from "@/assets/ListingProcess/Group.svg"
import Gallery from "@/assets/ListingProcess/gallery.svg"
import Final from "@/assets/ListingProcess/Final.svg"

export const NextStep1 = () => {
    const steps = [
        { number: 1, title: 'Boardroom details', description: 'The basics, add your property name, address amenities and more', icon: tick, status: 'completed', action: 'Edit' },
        { number: 2, title: 'Rooms', description: 'Tell us about your first room, once you have set one up you can add more', icon: house, status: 'current', action: 'Add room' },
        { number: 3, title: 'Pricing', description: 'Share the prices you would want guests to pay for your property', icon: Group, status: 'upcoming' },
        { number: 4, title: 'Photos', description: 'Share some photos of your place for guests to know what to expect', icon: Gallery, status: 'upcoming', action: 'add photos' },
        { number: 5, title: 'Final steps', description: 'Set up payment and invoicing before you open for bookings', icon: Final, status: 'upcoming', action: 'add final details' },
    ];

    function Step({ number, title, description, icon, status, action }) {
        const getStatusColor = () => {
            switch (status) {
                case 'completed':
                    return 'text-white';
                case 'current':
                    return 'text-[#0466C8]';
                default:
                    return 'text-gray-500';
            }
        };

        const getActionButton = () => {
            if (!action) return null;
        
            let buttonClass = 'w-full py-2 px-6 rounded-full ';
            
            switch (status) {
                case 'completed':
                    buttonClass += 'text-[#0466C8]';
                    break;
                case 'current':
                    buttonClass += 'bg-[#0466C8] text-white';
                    break;
                case 'upcoming':
                    if (number === 4) {
                        buttonClass += 'text-[#0466C8] border border-[#0466C8]';
                    } else if (number === 5) {
                        buttonClass += 'bg-[#A3A3A3] text-[#918E8E] border border-[#A3A3A3]';
                    }
                    break;
            }
        
            return <button className={buttonClass}>{action}</button>;
        };

        return (
            <div className="w-full border rounded-lg overflow-hidden bg-[#F4FEFF]">
                <div className="flex flex-col sm:flex-row items-start sm:items-center justify-between w-full h-full p-3 sm:p-4">
                    <div className="flex items-center mb-2 sm:mb-0">
                        <div className={`rounded-full p-1 mr-3 ${getStatusColor()}`}>
                            <img src={icon} alt="" className="w-6 h-6 sm:w-8 sm:h-8" />
                        </div>
                        <div>
                            <h3 className="font-normal text-sm sm:text-base leading-5 sm:leading-6 text-[#626262]">Step {number}</h3>
                            <h3 className="text-xl sm:text-lg font-medium leading-6 sm:leading-7 text-left text-[#000000]">{title}</h3>
                            <p className="text-sm sm:text-sm font-normal leading-4 sm:leading-5 text-left text-[#6D6D6D] mt-1">{description}</p>
                        </div>
                    </div>
                    <div className="mt-2 sm:mt-0 sm:ml-4">
                        {getActionButton()}
                    </div>
                </div>
            </div>
        );
    }

    return (
        <div className="flex justify-center items-center min-h-screen p-4">
            <div className="w-full max-w-[849px]">
                <div className="flex flex-col gap-4 sm:gap-6 md:gap-8">
                    {steps.map((step, index) => (
                        <Step key={index} {...step} />
                    ))}
                </div>
            </div>
        </div>
    );
}