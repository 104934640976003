import { useState, useEffect } from 'react';
import rating from "@/assets/profile/Star 1.png";
import Profile3 from "@/assets/profile/Profile3.png"; 
import { getHostProfile } from '@/api'; 

export const HostPublic = () => {
  const [profileData, setProfileData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  // Fetch data from API
  useEffect(() => {
    const fetchProfileData = async () => {
      try {
        const { user } = await getHostProfile(); // Call the API function to get the host profile
        setProfileData(user); // Set the user data
        setLoading(false);
      } catch (err) {
        console.error("Failed to fetch data:", err);
        setError(err.message);
        setLoading(false);
      }
    };

    fetchProfileData();
  }, []);

  if (loading) {
    return (
      <div className="flex justify-center items-center h-screen">
        <div className="w-16 h-16 border-4 border-t-4 border-gray-200 border-t-blue-500 border-solid rounded-full animate-spin"></div>
      </div>
    );
  }

  if (error) {
    return <div>{error}</div>;
  }

  return (
    <div className="max-w-[1440px] mx-auto px-4">
      <div className="max-w-[1088px] mx-auto mt-[50px] flex flex-col gap-[52px] mb-[200px]">
        <div className="flex flex-col lg:flex-row justify-between gap-8 lg:gap-16">
          {/* Side */}
          <div className="w-full lg:w-[333px] flex flex-col justify-between">
            <div className="max-w-[333px] h-full flex flex-col justify-between ">
              <div>
                {/* Display the number of properties listed */}
                <div className="w-full flex pr-4 mb-10 ">
                  <div className="w-full p-12 pt-2">
                    <span className="text-sm sm:text-base text-[#626262]">Listed Properties</span>
                    <h2 className="font-semibold text-laara-primary text-3xl lg:text-[26px] leading-tight p-3 pl-0">
                      {profileData?.properties?.length || 0} {/* Display property count */}
                    </h2>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* Main */}
          <div className="flex-1">
            {['Email address', 'Phone Number', 'Bio'].map((field) => (
              <div key={field} className={`mb-4 p-2 ${field === 'Bio' ? 'min-h-[132px]' : 'min-h-[76px]'} overflow-hidden`}>
                <div className="text-[#4F4F4F] text-lg sm:text-xl font-medium leading-tight mb-2">
                  {field}
                </div>
                <div className={`text-[#626262] text-sm sm:text-base ${field === 'Phone Number' ? 'font-semibold' : 'font-normal'}`}>
                  {field === 'Email address' && profileData.emailAddress}
                  {field === 'Phone Number' && profileData.phone}
                  {field === 'Bio' && profileData.bio}
                </div>
              </div>
            ))}
          </div>
        </div>

        {/* Reviews Section */}
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-4">
          {profileData?.reviews?.map((review, index) => (
            <div
              key={index}
              className="bg-white rounded-[30px] border border-gray-100 p-3 sm:p-4 w-full max-w-[616px] h-full max-h-[231px]"
            >
              <div className="relative flex flex-col sm:flex-row">
                <img src={Profile3} alt="Profile" className="w-16 h-16 sm:w-20 sm:h-20 rounded-full mb-2 sm:mb-0 sm:mr-4" />
                <div className="flex-grow pr-6">
                  <div className="flex justify-between items-start mb-2">
                    <div>
                      <h3 className="font-semibold text-base sm:text-lg text-laara-primary truncate">
                        {review.author}
                      </h3>
                      <div className="flex items-center mt-1">
                        {[...Array(5)].map((_, i) => (
                          <img key={i} src={rating} alt="Rating" className="w-3 h-3 sm:w-4 sm:h-4 rounded-full" />
                        ))}
                        <span className="ml-1 text-xs sm:text-sm text-gray-600">{review.rating}</span>
                      </div>
                    </div>
                    <div className="text-right text-xs sm:text-sm">
                      <span className="block text-[#888888]">{review.time}</span>
                      <span className="block text-[#4F4F4F]">{review.date}</span>
                    </div>
                  </div>
                  <p className="text-[#4F4F4F] text-sm sm:text-base italic font-normal mt-2 line-clamp-3 sm:line-clamp-none">
                    {review.text}
                  </p>
                </div>
                <button className="absolute top-[-1px] right-[-15px] p-2">
                  <div className="flex flex-col items-center justify-between w-4 h-4 sm:w-5 sm:h-5">
                    <div className="w-1 h-1 bg-black rounded-full"></div>
                    <div className="w-1 h-1 bg-black rounded-full"></div>
                    <div className="w-1 h-1 bg-black rounded-full"></div>
                  </div>
                </button>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
