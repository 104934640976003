import React, { useState } from 'react';
import { Send } from 'lucide-react';

const ChatMessage = ({ message, isUser }) => (
  <div className={`flex flex-col ${isUser ? 'items-end' : 'items-start'} mt-3 w-full`}>
    <div className={`overflow-hidden flex-1 shrink gap-2.5 px-4 py-2.5 rounded-xl ${
      isUser ? 'bg-sky-600 text-gray-100' : 'bg-slate-200 text-zinc-600'
    } ${isUser ? 'max-w-[238px]' : 'max-w-[217px]'}`}>
      {message.text}
    </div>
    <div className={`gap-2.5 py-1 mt-1 text-xs ${isUser ? 'text-right' : 'text-left'} text-zinc-600`}>
      {message.timestamp}
    </div>
  </div>
);

export default function ChatReservations() {
  const [messages, setMessages] = useState([
    { text: "m placerat. elit. placerat. s", isUser: false, timestamp: "Wed 6:55 AM" },
    { text: "m placerat. elit.", isUser: true, timestamp: "Sent 16h ago" },
    { text: "m placerat. elit. placerat. sm placerat. elit. placerat. sm placerat. elit. placerat. s", isUser: true, timestamp: "Sent 16h ago" },
    { text: "elementum nec ullamco", isUser: false, timestamp: "Sent 16h ago" },
  ]);
  const [newMessage, setNewMessage] = useState("");

  const handleSendMessage = () => {
    if (newMessage.trim()) {
      setMessages([...messages, { text: newMessage, isUser: true, timestamp: "Just now" }]);
      setNewMessage("");
    }
  };

  return (
    <div className="flex overflow-hidden flex-col items-center px-2 pt-2 pb-[600px] max-md:pb-24">
      <div className="flex justify-between items-center p-2 max-w-full text-2xl font-medium tracking-normal whitespace-nowrap text-zinc-600 w-[1246px]">
        <div className="flex flex-wrap flex-1 shrink gap-4 items-center self-stretch my-auto w-full basis-0 min-h-[56px] min-w-[240px] max-md:max-w-full">
          <img
            loading="lazy"
            src="https://cdn.builder.io/api/v1/image/assets/TEMP/286d1da95092db996b7f74401b677416e198b43c69492471b8222c8704afd7b1"
            className="object-contain shrink-0 self-stretch my-auto w-6 aspect-square"
            alt=""
          />
          <div className="self-stretch my-auto w-[309px]">Chat</div>
        </div>
      </div>
      <div className="flex overflow-hidden flex-col p-2 mt-2 max-w-full rounded-2xl w-[1264px]">
        <div className="flex flex-wrap gap-10 justify-between items-center px-2.5 py-2 w-full max-md:max-w-full">
          <div className="flex gap-1 items-center self-stretch my-auto text-sm font-semibold whitespace-nowrap text-zinc-600">
            <div className="flex gap-1.5 items-center self-stretch my-auto">
              <img
                loading="lazy"
                src="https://cdn.builder.io/api/v1/image/assets/TEMP/54a9c8f39d0ce18fc0f190c0c87c389d5240087242fe69cd8a4fada0cc7a8089"
                className="object-contain shrink-0 self-stretch my-auto w-8 rounded-full aspect-square"
                alt="Alex"
              />
              <div className="self-stretch my-auto">Alex</div>
            </div>
            <img
              loading="lazy"
              src="https://cdn.builder.io/api/v1/image/assets/TEMP/2ed9b09093ce3644982b48eb7022d45f5ec4a0f0524c37ef6e2778dc3f2d66e3"
              className="object-contain shrink-0 self-stretch my-auto w-2 aspect-square rounded-[64px]"
              alt=""
            />
          </div>
        </div>
        <div className="flex overflow-hidden flex-wrap items-end p-2 mt-2 w-full rounded-xl border border-solid border-zinc-600 border-opacity-20 max-md:max-w-full">
          <div className="flex flex-col flex-1 shrink px-4 pb-1.5 w-full rounded-xl basis-0 min-w-[240px] max-md:max-w-full">
            
            <div className="flex flex-col w-full text-base max-md:max-w-full">
              {messages.map((message, index) => (
                <ChatMessage key={index} message={message} isUser={message.isUser} />
              ))}
            </div>
          </div>
          <div className="flex flex-col justify-between px-px w-4 min-h-[348px] max-md:hidden">
            <img
              loading="lazy"
              src="https://cdn.builder.io/api/v1/image/assets/TEMP/87c7f085c48eab8506442b791b773375b7a7332efe48505cde58a4b297de8fed"
              className="object-contain w-3.5 aspect-square"
              alt=""
            />
            <img
              loading="lazy"
              src="https://cdn.builder.io/api/v1/image/assets/TEMP/ac599be5ef7c9995c49edfc540ff3a3bfd92116a5bf6d763be741e4f688401e3"
              className="object-contain mt-72 w-3.5 aspect-[0.26] max-md:mt-10"
              alt=""
            />
          </div>
        </div>
        <div className="flex flex-wrap justify-between p-2 mt-2 w-full text-base rounded-2xl border border-solid border-zinc-600 border-opacity-50 min-h-[68px] text-zinc-600 max-md:max-w-full">
          <div className="flex flex-wrap flex-1 shrink gap-2.5 items-center h-full basis-0 min-w-[240px] max-md:max-w-full">
            <img
              loading="lazy"
              src="https://cdn.builder.io/api/v1/image/assets/TEMP/03a49aea5b8cf0d0fb801b8fce7a517d2ca7dfc4c9b6c1a96af63484096ccf02"
              className="object-contain shrink-0 self-stretch my-auto w-6 aspect-square"
              alt=""
            />
            <input
              type="text"
              value={newMessage}
              onChange={(e) => setNewMessage(e.target.value)}
              placeholder="Type a message here"
              className="flex-grow bg-transparent focus:outline-none"
            />
          </div>
          <button onClick={handleSendMessage} className="shrink-0 my-auto">
            <Send className="w-7 h-7 text-zinc-600" />
          </button>
        </div>
      </div>
    </div>
  );
}