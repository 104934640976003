import { useState, useEffect, useRef } from "react";
import search from "@/assets/hero/search.svg";
import GuestOverlay from "@/components/Wishlist/GuestOverlay";
import DateOverlay from "@/components/Wishlist/DateOverlay";
import PlacesAutoComplete from "@/components/shared/GooglePlacesAutoComplete/PlacesAutoComplete";
import dayjs from "dayjs";
import axios from "axios";
import { headers, baseURL } from "@/constants/apiconfig";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import {
  setSearchLocation,
  setendDate,
  setstartDate,
  setguests,
} from "@/redux/searchBar/searchbar";

const Picker = ({ setFilteredData }) => {
  const { searchLocation, searchStartDate, searchEndDate, searchGuests } =
    useSelector((state) => state.searchBar);

  const [location, setLocation] = useState(searchLocation || "");
  const [date, setDate] = useState({
    from: searchStartDate || null,
    to: searchEndDate || null,
  });
  const [totalGuests, setTotalGuests] = useState(searchGuests || 0);

  const [popup, setPopup] = useState("none");
  const dateRef = useRef(null);
  const guestRef = useRef(null);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const token = useSelector((state) => state.user.accessToken);

  const togglePopup = (value) => {
    setPopup((prev) => (prev === value ? "none" : value));
  };

  const handleChange = (e) => {
    const newLocation = e.target.value;

    setLocation(newLocation);
    setSearchLocation(newLocation);
  };

  const formatDate = (date) => {
    if (!date) return "";
    const d = new Date(date);
    return `${d.getDate()}/${d.getMonth() + 1}/${d.getFullYear()}`;
  };

  const updateTotalGuests = (adults, children, infants) => {
    const total = adults + children + infants;
    // setTotalGuests(total);
    dispatch(setguests(total));
  };

  const buildQueryParams = () => {
    // Ensure date is correctly formatted (optional check for null)
    let place = searchLocation.split(",");
    place = place[0];

    const startDate = date.from ? dayjs(date.from).toISOString() : null;
    const endDate = date.to ? dayjs(date.to).toISOString() : null;

    // Build query params
    const queryParams = new URLSearchParams({
      offset: 0,
      limit: 10,
      location: place,
      guests: totalGuests,
      // startDate: startDate || "",
      // endDate: endDate || "",
    }).toString();

    return queryParams;
  };

  const handleSearch = async () => {
    const queryParams = buildQueryParams();
    // Navigate to the SearchResults page with the query parameters
    navigate(`/searchresults?${queryParams}`);
    try {
      const res = await axios.get(`${baseURL}/search?${queryParams}`, {
        headers: headers(token),
      });

      setFilteredData(res.data.data);
    } catch (error) {
      throw new Error(error?.message);
    }
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dateRef.current && !dateRef.current.contains(event.target)) {
        setPopup((prev) => (prev === "date" ? "none" : prev));
      }
      if (guestRef.current && !guestRef.current.contains(event.target)) {
        setPopup((prev) => (prev === "guest" ? "none" : prev));
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div className="xl:mx-auto w-full xl:px-[88px]">
      <div className="bg-[#F4FEFF] rounded-[30px] lg:rounded-[66px] flex flex-col lg:flex-row items-center justify-between h-auto lg:h-[79px] relative p-4 lg:p-6 mt-10 ">
        <span className="w-full lg:w-1/4 mb-4 lg:mb-0 flex flex-col items-center lg:items-start">
          <input
            type="text"
            value={searchLocation}
            onChange={(e) => dispatch(setSearchLocation(e.target.value))}
            placeholder="Pick a location"
            className="w-full text-[#4066C8] focus:outline-none bg-transparent border-none"
          />
          {/* <PlacesAutoComplete /> */}
          <p className="text-base text-[#1E1E1EB5]">Where are you going to?</p>
        </span>

        <span
          ref={dateRef}
          className={`w-full lg:w-1/4 mb-4 lg:mb-0 h-[79px] justify-center ${
            popup === "date"
              ? "bg-white shadow-lg rounded-[60px]"
              : "hover:bg-gray-200 hover:bg-opacity-60 rounded-[60px]"
          } relative flex flex-col items-center justify-start`}
        >
          <button
            onClick={() => togglePopup("date")}
            className="w-full font-medium text-lg text-[#4066C8]"
          >
            {date?.from
              ? `${formatDate(date.from)} - ${formatDate(date.to)}`
              : "Add dates"}
          </button>
          <p className="text-base text-[#1E1E1EB5]">
            Check In - Check Out Dates
          </p>
          {popup === "date" && (
            <div className="absolute top-full left-0 mt-2 z-10 bg-white rounded-lg shadow border w-[90vw] lg:w-auto">
              <DateOverlay date={date} setDate={setDate} />
            </div>
          )}
        </span>

        <span
          ref={guestRef}
          className={`w-full lg:w-1/4 mb-4 lg:mb-0  h-[79px] justify-center ${
            popup === "guest"
              ? "bg-white shadow-lg rounded-[60px]"
              : "hover:bg-gray-200 hover:bg-opacity-60 rounded-[60px]"
          } relative flex flex-col items-center justify-center`}
        >
          <button
            onClick={() => togglePopup("guest")}
            className="w-full text-center font-medium text-lg text-[#4066C8]"
          >
            {totalGuests > 0 ? `${totalGuests} guests` : "Add guests"}
          </button>
          <p className="text-base text-[#1E1E1EB5] text-left">
            Number of Guests
          </p>
          {popup === "guest" && (
            <div className="absolute top-full left-0 mt-2 z-10 bg-white rounded-lg shadow-lg w-[90vw] lg:w-auto">
              <GuestOverlay updateTotalGuests={updateTotalGuests} />
            </div>
          )}
        </span>

        <span className="w-full lg:w-auto">
          <button
            onClick={handleSearch}
            className="bg-[#0466C8] rounded-[60px] p-4 flex items-center justify-center w-full lg:w-[50px] lg:h-[50px] text-xl gap-[5.65px]"
          >
            <p className="lg:hidden">Search</p>
            <img src={search} alt="search" className="w-8 h-8" />
          </button>
        </span>
      </div>
    </div>
  );
};

export default Picker;
