import { useState } from "react";
import MyCalendar from "./Calendar";
import MyFlexible from "./Flexible";
const DateOverlay = ({date,setDate}) => {
  const [activeTab, setActiveTab] = useState("calendar");

  const toggleTab = (tab) => {
    setActiveTab(tab);
  };
  return (
    <div
      className="absolute rounded-lg mt-5 md:ml-0 bg-white text-[#292929] sm:max-md:-ml-36  md:w-[580px] grow shrink-0 z-20 max-sm:w-full "
    >
      <div className="w-full flex flex-col lg:flex-row">
        <h1
          className={`border-b-2 font-medium text-center ${
            activeTab === "calendar"
              ? "text-[#0466C8] border-[#0466C8] pb-3"
              : "text-[#A3A3A3]"
          } cursor-pointer lg:w-1/2 lg:text-lg`}
          onClick={() => toggleTab("calendar")}
        >
          Calendar
        </h1>
        <h1
          className={`font-medium border-b-2 text-center mt-5 lg:mt-0 ${
            activeTab === "flexible"
              ? "text-[#0466C8] border-[#0466C8] pb-3"
              : "text-[#A3A3A3]"
          } cursor-pointer lg:w-1/2 lg:text-lg`}
          onClick={() => toggleTab("flexible")}
        >
          I'm Flexible
        </h1>
      </div>
      {activeTab === "calendar" && (
        <MyCalendar
        date={date} setDate={setDate}
        />
      )}
      {activeTab === "flexible" && <MyFlexible />} 
    </div>
  );
};
export default DateOverlay;
