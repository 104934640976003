import { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { setFormData } from "@/redux/Listing/listingSlice";
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectLabel,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import plus from "@/assets/shared/blue-plus.svg";
import minus from "@/assets/shared/blue-minus.svg";
import Bed from "@/assets/ListingProcess/Bed.svg";

const RoomDetails = ({ category }) => {
  const dispatch = useDispatch();
  const selectedRoomType = useSelector(
    (state) => state.hostListing.formData.roomType
  );
  const { maxGuests } = selectedRoomType;

  const [beds, setBeds] = useState([
    { name: "Single", description: "single bed: 90cm - 130cm", quantity: 0 },
    { name: "Double", description: "double bed: 131cm - 150cm", quantity: 0 },
    { name: "Large", description: "large bed: 151cm - 180cm", quantity: 0 },
    {
      name: "ExtraLarge",
      description: "extra large bed: 181cm - 210cm",
      quantity: 0,
    },
  ]);

  const roomTypes = [
    { value: "standard-single", label: "Standard Single Room" },
    { value: "executive-single", label: "Executive Single Room" },
    { value: "deluxe-single", label: "Deluxe Single Room" },
    { value: "standard-double", label: "Standard Double Room" },
    { value: "executive-double", label: "Executive Double Room" },
    { value: "deluxe-double", label: "Deluxe Double Room" },
    { value: "standard-twin", label: "Standard Twin Room" },
    { value: "executive-twin", label: "Executive Twin Room" },
    { value: "deluxe-twin", label: "Deluxe Twin Room" },
    { value: "standard-family", label: "Standard Family Room" },
    { value: "executive-family", label: "Executive Family Room" },
    { value: "deluxe-family", label: "Deluxe Family Room" },
  ];

  const handleRoomTypeChange = (name, value) => {
    dispatch(setFormData({ roomType: { ...selectedRoomType, [name]: value } }));
  };

  const handleMaxGuestsChange = (val) => {
    dispatch(
      setFormData({ roomType: { ...selectedRoomType, maxGuests: val } })
    );
  };

  const handleBedTypes = (type, change) => {
    const updatedBeds = beds.map((bed) =>
      bed.name === type
        ? { ...bed, quantity: Math.max(0, bed.quantity + change) }
        : bed
    );
    setBeds(updatedBeds);

    const filteredBeds = updatedBeds.filter((bed) => bed.quantity > 0);
    dispatch(
      setFormData({ roomType: { ...selectedRoomType, bedTypes: filteredBeds } })
    );
  };

  const handleSmoking = (e) => {
    const { value } = e.target;

    const smokingPolicy = {
      type: "Smoking",
      description:
        value === "yes"
          ? "Smoking is allowed in the property"
          : "Smoking is not allowed in the property",
      isMandatory: true,
    };

    // Only focus on the smoking policy for now
    const updatedPolicies = [smokingPolicy];

    dispatch(
      setFormData({
        roomType: {
          ...selectedRoomType,
          policies: updatedPolicies, // Replace policies with just the smoking policy
        },
      })
    );
  };

  return (
    <div>
      <form className="relative space-y-6">
        <div className="w-full max-w-[733px] p-4 bg-laara-secondary border rounded-lg">
          <div className="space-y-6 max-w-[653px]  ">
            <div className="space-y-2">
              <label htmlFor="roomType" className="block font-medium text-lg">
                Select room type
              </label>

              <Select
                id="roomType"
                name="roomType"
                value={selectedRoomType.name}
                // LEAVE NAME AS IT IS
                onValueChange={(value) => handleRoomTypeChange("name", value)}
              >
                <SelectTrigger className="p-2 rounded-[8px] bg-white border border-[#6D6D6D] md:max-w-[70%] ">
                  <SelectValue placeholder="Select check in time" />
                </SelectTrigger>
                <SelectContent>
                  <SelectGroup>
                    <SelectLabel>Check in from</SelectLabel>
                    {roomTypes.map((item) => (
                      <SelectItem key={item.value} value={item.value}>
                        {item.label}
                      </SelectItem>
                    ))}
                  </SelectGroup>
                </SelectContent>
              </Select>
            </div>

            <div className="space-y-2">
              <label
                htmlFor="numberOfRooms"
                className="block font-medium text-base"
              >
                Number of rooms you have of this type
              </label>
              <input
                id="numberOfRooms"
                type="number"
                name="numRooms"
                value={selectedRoomType.numRooms}
                onChange={handleRoomTypeChange}
                className="p-2 w-full md:max-w-[263px] text-base border border-gray-300 rounded-md focus:outline-none"
                min="1"
              />

              <label
                htmlFor="roomDescription"
                className="block font-medium text-[14px] leading-[20px] sm:text-[16px] sm:leading-[24px] md:text-[20px] md:leading-[30px]"
              >
                Room Description
              </label>
              <input
                id="roomDescription"
                type="text"
                name="description"
                value={selectedRoomType.description}
                onChange={handleRoomTypeChange}
                className=" p-2 text-base border border-gray-300 rounded-md focus:outline-none"
              />
            </div>
          </div>
        </div>

        <div className="w-full max-w-[733px] p-4 bg-laara-secondary border  rounded-lg">
          <div className="space-y-4">
            <label className="block font-semibold text-base">
              Type of beds available in this room
            </label>
            {beds.map((bed) => (
              <div
                key={bed.name}
                className="flex md:flex-wrap items-start justify-start gap-3 md:gap-8 w-full max-w-[543px]"
              >
                {/* BED ICON */}
                <div className="flex items-start w-[162px] md:w-20 mb-2 sm:mb-0 ">
                  <img src={Bed} alt="bed icon" className="w-[62px] h-[46px]" />
                </div>

                {/* BED DESCRIPTION */}
                <div className="w-full md:w-[190px] mb-2 sm:mb-0  ">
                  <span
                    className={`text-sm sm:text-base ${
                      bed.quantity > 0 ? "font-bold" : ""
                    }`}
                  >
                    {bed.name} bed(s)
                  </span>
                  <br />
                  <span className="text-[12px] sm:text-sm text-[#6D6D6D] ">
                    {bed.description}
                  </span>
                </div>
                {/* BED BUTTONS */}
                <div className="flex items-center justify-between border border-[#6D6D6D] rounded-lg">
                  <button
                    type="button"
                    name="bedTypes"
                    onClick={() => {
                      handleBedTypes(bed.name, -1);
                    }}
                    className="w-10 h-10 flex items-center justify-center"
                  >
                    <img src={minus} alt="negative" className="" />
                  </button>
                  <span className="w-8 text-center text-base">
                    {bed.quantity}
                  </span>
                  <button
                    type="button"
                    name="bedTypes"
                    onClick={() => {
                      handleBedTypes(bed.name, 1);
                    }}
                    className="w-10 h-10 flex items-center justify-center"
                  >
                    <img
                      src={plus}
                      alt="negative"
                      className="w-[15px] h-[15px] "
                    />
                  </button>
                </div>
              </div>
            ))}
          </div>
        </div>

        <div className="w-full max-w-[733px] p-4 bg-laara-secondary border rounded-lg">
          <label className="block mb-2 font-normal text-lg">
            Maximum number of guests who can stay in this room
          </label>

          <div className="flex justify-start">
            <div className="flex items-center justify-between  border border-[#6D6D6D] rounded-lg">
              <button
                type="button"
                className="size-10 flex items-center justify-center"
                onClick={() =>
                  handleMaxGuestsChange(Math.max(1, maxGuests - 1))
                }
              >
                <img src={minus} alt="negative" className="w-[15px] h-[3px] " />
              </button>
              <span className="w-8 text-center">{maxGuests}</span>
              <button
                type="button"
                name="maxGuests"
                className="size-10 flex items-center justify-center "
                onClick={() => handleMaxGuestsChange(maxGuests + 1)}
              >
                <img src={plus} alt="add" className="size-[15px] " />
              </button>
            </div>
          </div>
        </div>

        <div className="w-full max-w-[733px] p-4 bg-laara-secondary border rounded-lg">
          <div>
            <label className="block mb-2 font-normal text-lg">
              Do you allow smoking in this room?
            </label>
            <div className="flex flex-col">
              <label className="inline-flex items-center mb-2">
                <input
                  type="radio"
                  name="Smoking"
                  value="yes"
                  onChange={handleSmoking}
                  className="form-radio"
                />
                <span className="ml-2 text-[14px] sm:text-[16px]">Yes</span>
              </label>
              <label className="inline-flex items-center mb-2">
                <input
                  type="radio"
                  name="Smoking"
                  value="no"
                  onChange={handleSmoking}
                  className="form-radio"
                />
                <span className="ml-2 text-[14px] sm:text-[16px]">No</span>
              </label>
            </div>
          </div>
        </div>

        <div className="w-full max-w-[733px] p-4 bg-laara-secondary border rounded-lg">
          <div>
            <label className="block mb-2 font-normal text-lg">
              How big is the room?
            </label>
            <label className="block font-normal text-base">
              Room size - optional
            </label>

            <div className="relative w-full sm:w-[317px]">
              <input
                type="number"
                value={parseInt(selectedRoomType.typeSize)}
                name="typeSize"
                onChange={(e) =>
                  handleRoomTypeChange("typeSize", parseInt(e.target.value))
                }
                className="w-full p-2 border rounded-lg pl-4 text-[14px] sm:text-[16px] focus:outline-none"
              />
              <span className="absolute top-1/2 right-3 transform -translate-y-1/2 text-black text-[14px] sm:text-[16px]">
                Sq.ft
              </span>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};

export default RoomDetails;
