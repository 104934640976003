import React, { useState } from 'react';
import profileuser from "@/assets/AdminDash/Group 1.svg"
import Background from "@/assets/AdminDash/Background.svg"
import { Doughnut, Line } from 'react-chartjs-2';
import chroma from 'chroma-js';
import { Chart as ChartJS, ArcElement, Tooltip, CategoryScale, LinearScale, PointElement, LineElement, Title, Filler, Legend } from 'chart.js';
import { Edit, Move, Trash2 } from 'lucide-react';
import EditTaskModal from "@/Modals/EditTaskModal"
import DeleteTaskModal from '@/Modals/DeleteTaskModal';

ChartJS.register(ArcElement, Tooltip, Legend);

function PlatformUsers() {
  const createData = (value, total, color) => {
    const lightColor = chroma(color).brighten(0.7).hex();
    return {
      labels: ['Value', 'Remaining'],
      datasets: [
        {
          data: [value, total - value],
          backgroundColor: [color, lightColor],
          borderColor: [color, lightColor],
          borderWidth: 1,
        },
      ],
    };
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    cutout: '75%',
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        enabled: false,
      },
    },
  };

  const totalUsers = 1000000;
  const activeUsers = 800000;
  const inactiveUsers = 200000;

  return (
    <div
      className="border rounded-lg p-4 mb-4"
      style={{
        backgroundImage: `url(${Background})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
      }}
    >
      <h2 className="text-base text-[#4F4F4F] font-semibold mb-4">Platform users</h2>
      <div className="flex flex-wrap justify-between">
        <UserCard
          label="Users"
          value={totalUsers}
          color="#0466C8"
          data={createData(totalUsers, totalUsers, '#0466C8')}
          options={options}
        />
        <UserCard
          label="Active users"
          value={activeUsers}
          color="#108975"
          data={createData(activeUsers, totalUsers, '#108975')}
          options={options}
        />
        <UserCard
          label="Inactive users"
          value={inactiveUsers}
          color="#C03744"
          data={createData(inactiveUsers, totalUsers, '#C03744')}
          options={options}
        />
      </div>
    </div>
  );
}

function UserCard({ label, value, color, data, options }) {
  return (
    <div className="text-center w-full sm:w-1/3 mb-4 sm:mb-0">
      <div className="relative w-48 h-48 mx-auto">
        <Doughnut data={data} options={options} />
        <div className="absolute inset-0 flex flex-col items-center justify-center">
          <div className="text-base">{label}</div>
          <div className="text-2xl font-bold" style={{ color: color }}>
            {value.toLocaleString()}
          </div>

        </div>
      </div>
    </div>
  );
}

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Filler, Legend);

function FinancialSummary() {
  const data = {
    labels: ['$5', '$10k', '$15', '$20k', '$25', '$30k', '$35', '$40k', '$45', '$50k', '$55', '$60'],
    datasets: [
      {
        fill: true,
        label: 'Financial Data',
        data: [0, 3, 4, 13, 18, 5, 19, 68, 19, 20, 30, 3, 4, 10, 44, 22, 8, 20, 20],
        borderColor: '#0466C8',
        backgroundColor: 'rgba(53, 162, 235, 0.5)',
      },
    ],
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false,
      },
    },
    scales: {
      y: {
        beginAtZero: true,
        max: 100,
        ticks: {
          callback: function (value) {
            return value + '%';
          },
        },
      },
    },
  };

  return (
    <div className="bg-white h-[361px] border rounded-lg p-4 mb-4">
      <div className="flex justify-between items-center mb-4">
        <h2 className="text-xl text-[#4F4F4F] font-medium">Financial summary</h2>
        <select className="border rounded p-1">
          <option value="January">January</option>
          <option value="February">February</option>
          <option value="March">March</option>
          <option value="April">April</option>
          <option value="May">May</option>
          <option value="June">June</option>
          <option value="July">July</option>
          <option value="August">August</option>
          <option value="September">September</option>
          <option value="October">October</option>
          <option value="November">November</option>
          <option value="December">December</option>
        </select>
      </div>
      <div className="h-[calc(100%-4rem)]">
        <Line data={data} options={options} />
      </div>
    </div>
  );
}

function Reservations() {
  const reservations = [
    { id: 1, name: 'Allan Maweu', hotel: 'Hilton hotel', date: '08/10/2024' },
    { id: 2, name: 'Allan Maweu', hotel: 'Hilton hotel', date: '08/10/2024' },
    { id: 3, name: 'Allan Maweu', hotel: 'Hilton hotel', date: '08/10/2024' },
    { id: 4, name: 'Allan Maweu', hotel: 'Hilton hotel', date: '08/10/2024' },
    { id: 5, name: 'Allan Maweu', hotel: 'Hilton hotel', date: '08/10/2024' },
    { id: 6, name: 'Allan Maweu', hotel: 'Hilton hotel', date: '08/10/2024' },
  ];

  return (
    <div className="bg-white h-[632px] w-full max-w-[446px] px-4 py-1.5 border rounded-lg shadow">
      <div className="flex justify-between items-center mb-4">
        <h2 className="text-base font-semibold">Reservations</h2>
        <select className="border rounded p-1 bg-[#ECECEE] w-full max-w-[91px]">
          <option value="today">Today</option>
          <option value="yesterday">Yesterday</option>
          <option value="this_week">This Week</option>
          <option value="last_week">Last Week</option>
          <option value="this_month">This Month</option>
          <option value="last_month">Last Month</option>
          <option value="last_30_days">Last 30 Days</option>
          <option value="last_90_days">Last 90 Days</option>
          <option value="this_year">This Year</option>
          <option value="last_year">Last Year</option>
        </select>
      </div>
      <div className="overflow-y-auto h-[549px]">
        <ul className="w-[357px]">
          {reservations.map((reservation) => (
            <li
              key={reservation.id}
              className="flex items-center h-[84px] py-4 gap-4 border-b border-gray-200"
            >
              <div className="w-12 h-12 rounded-full bg-gray- flex-shrink-0 flex items-center justify-center overflow-hidden ">
                <img src={profileuser} alt="profile" className="w-full h-full object-cover rounded-full" />
              </div>

              <div className="flex-grow flex justify-between items-center">
                <div>
                  <div className='font-medium text-base'> {reservation.name} - {reservation.hotel}</div>
                  <div className="text-sm text-[#888888] font-medium mt-2">{reservation.date}</div>
                </div>
                <button className="text-black ">
                  <div className="flex flex-col items-center justify-center space-y-0.5">
                    <div className="w-1 h-1 bg-black rounded-full"></div>
                    <div className="w-1 h-1 bg-black rounded-full"></div>
                    <div className="w-1 h-1 bg-black rounded-full"></div>
                  </div>
                </button>

              </div>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
}

function TaskList() {
  const tasks = [
    { id: 1, user: 'Hilton Hotel', task: 'Verification Request', date: '10/16/2024', status: 'Completed' },
    { id: 2, user: 'Serena Hotels', task: 'Open Support Ticket', date: '10/16/2024', status: 'Completed' },
    { id: 3, user: 'Hilton', task: 'Verification Request', date: '10/16/2024', status: 'Completed' },
    { id: 4, user: 'Serena', task: 'Verification Request', date: '10/16/2024', status: 'Overdue' },
    { id: 5, user: 'Grandview Inn', task: 'Room Upgrade Request', date: '10/17/2024', status: 'Pending' },
    { id: 6, user: 'Bay Area Suites', task: 'Check-In Request', date: '10/17/2024', status: 'Completed' },
    { id: 7, user: 'Oceanfront Resort', task: 'Cancellation Request', date: '10/18/2024', status: 'Pending' },
  ];

  const [currentPage, setCurrentPage] = useState(1);
  const [openDropdown, setOpenDropdown] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [editingTask, setEditingTask] = useState(null);
  const [deletingTask, setDeletingTask] = useState(null);
  const tasksPerPage = 4;

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const indexOfLastTask = currentPage * tasksPerPage;
  const indexOfFirstTask = indexOfLastTask - tasksPerPage;
  const currentTasks = tasks.slice(indexOfFirstTask, indexOfLastTask);

  const pageNumbers = [];
  for (let i = 1; i <= Math.ceil(tasks.length / tasksPerPage); i++) {
    pageNumbers.push(i);
  }

  const toggleDropdown = (taskId) => {
    setOpenDropdown(openDropdown === taskId ? null : taskId);
  };

  const handleEdit = (task) => {
    setEditingTask(task);
    setIsModalOpen(true);
  };

  const handleDelete = (task) => {
    setDeletingTask(task);
    setIsDeleteModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setEditingTask(null);
  };

  const handleCloseDeleteModal = () => {
    setIsDeleteModalOpen(false);
    setDeletingTask(null);
  };

  const handleConfirmDelete = () => {
    // Logic to delete the task
    setTasks(tasks.filter(task => task.id !== deletingTask.id));
    handleCloseDeleteModal();
  };

  return (
    <div className="bg-white h-[401px] p-3 rounded-lg  shadow overflow-hidden">
      <div className="overflow-x-auto h-[calc(100%-6rem)]">
        <table className="w-full">
          <thead>
            <tr className="text-left font-semibold text-sm text-[#818181] bg-[#F9FAFB]">
              <th className='p-3'></th>
              <th className="p-3">User Name</th>
              <th className="p-3">Task name</th>
              <th className="p-3">Date</th>
              <th className="p-3">Status</th>
              <th className="p-3">More Actions</th>
            </tr>
          </thead>
          <tbody>
            {currentTasks.map((task) => (
              <tr key={task.id} className="border-t font-medium text-sm">
                <td className='p-3'>{task.id}</td>
                <td className="p-3">{task.user}</td>
                <td className="p-3">{task.task}</td>
                <td className="p-3">{task.date}</td>
                <td className="p-3">
                  <span className={`px-2 py-1 rounded-full ${task.status === 'Completed' ? 'border border-[#108975] text-[#108975]' : 'border border-[#C03744] text-[#C03744]'}`}>
                    {task.status}
                  </span>
                </td>
                <td className="p-3 relative">
                  <button className="border rounded px-2 py-1" onClick={() => toggleDropdown(task.id)}>Action</button>
                  {openDropdown === task.id && (
                    <div className="absolute right-0 mt-2 w-38 bg-white rounded-md overflow-hidden shadow-xl z-10">
                      <button
                        className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 w-full text-left"
                        onClick={() => {
                          handleEdit(task);
                          toggleDropdown(task.id);
                        }}
                      >
                        <Edit size={16} className="inline mr-2" /> Edit Task
                      </button>
                      <button className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 w-full text-left">
                        <Move size={16} className="inline mr-2" /> Move Task
                      </button>
                      <button
                        className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 w-full text-left"
                        onClick={() => {
                          handleDelete(task);
                          toggleDropdown(task.id);
                        }}
                      >
                        <Trash2 size={16} className="inline mr-2" /> Delete Task
                      </button>
                    </div>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <div className="flex justify-end p-4 ">
        <div className="border rounded-lg font-semibold px-2 py-1.5">
          <button
            className={`px-3 py-1 rounded mr-2 ${currentPage === 1 ? 'text-gray-500' : 'text-black'}`}
            onClick={() => handlePageChange(currentPage - 1)}
            disabled={currentPage === 1}
          >
            &lt;
          </button>
          {pageNumbers.map((number) => (
            <button
              key={number}
              className={`px-3 py-1 mr-2 ${currentPage === number ? 'bg-[#108975] text-white' : 'text-black'} rounded`}
              onClick={() => handlePageChange(number)}
            >
              {number}
            </button>
          ))}
          <button
            className="px-3 py-1 mr-2 text-black rounded"
            onClick={() => handlePageChange(currentPage + 1)}
            disabled={currentPage === pageNumbers.length}
          >
            &gt;
          </button>
        </div>
      </div>
      <EditTaskModal isOpen={isModalOpen} onClose={handleCloseModal} task={editingTask} onSave={setEditingTask} />
      <DeleteTaskModal isOpen={isDeleteModalOpen} onClose={handleCloseDeleteModal} onConfirm={handleConfirmDelete} />
    </div>
  );
}
function Dashboard() {
  return (
    <div className="container mx-auto min-h-screen ">
      <div className="flex flex-col lg:flex-row gap-4">
        <div className="w-full lg:w-7/12 flex flex-col">
          <PlatformUsers />
          <FinancialSummary />
        </div>
        <div className="w-full lg:w-5/12 flex flex-col">
          <Reservations />
        </div>
      </div>
      <div className="mt-4">
        <TaskList />
      </div>
    </div>
  );
}
export default Dashboard;