// eslint-disable-next-line no-unused-vars
import React, { useState, useEffect } from "react";
import { Link, useParams, useNavigate, useLocation } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLessThan } from "@fortawesome/free-solid-svg-icons";
import { getPropertyById, updatePropertyAddress, getToken } from "@/api"; // Adjust the import path if needed
import "./editproperty.css";

const EditPropertyLocation = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const location = useLocation();

  const [addressData, setAddressData] = useState({
    country: "",
    county: "",
    city: "",
    town: "",
    street: "",
    physicalAddress: "",
    postCode: "",
    apartment: "",
    latitude: "",
    longitude: "",
  });

  useEffect(() => {
    const fetchPropertyDetailsData = async () => {
      try {
        const token = getToken();
        const property = await getPropertyById(id, token);
        const { address } = property.property;

        setAddressData({
          country: address.country || "",
          county: address.county || "",
          city: address.city || "",
          town: address.town || "",
          street: address.street || "",
          physicalAddress: address.physicalAddress || "",
          postCode: address.postCode || "",
          apartment: address.apartment || "",
        });
      } catch (error) {
        console.error("Error fetching property details:", error);
      }
    };

    fetchPropertyDetailsData();
  }, [id]);

  useEffect(() => {
    if (location.state) {
      const { lat, lng } = location.state;
      setAddressData((prevData) => ({
        ...prevData,
        latitude: lat,
        longitude: lng,
      }));
    }
  }, [location.state]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const token = getToken();
      await updatePropertyAddress(id, { address: addressData }, token);
      console.log("Address updated successfully");
      navigate(`/view-property/${id}`);
    } catch (error) {
      console.error("Failed to update address:", error);
    }
  };

  const handleChangeLocationPin = () => {
    navigate(`/location-picker/${id}`);
  };

  return (
    <div className="p-8 bg-base-white flex">
      <div className="flex-1">
        <section className="top h-16 flex items-center p-2 gap-3">
          <div className="flex items-center gap-4 text-lg text-gray-600 w-[700px]">
            <Link to={`/view-property/${id}`}>
              <FontAwesomeIcon icon={faLessThan} className="text-base" />
            </Link>
            <h3>Property Location</h3>
          </div>
        </section>
        <section className="flex w-full max-w-[1264px] gap-5">
          <form
            className="flex flex-col p-2 max-w-[840px] w-full h-full m-4"
            onSubmit={handleSubmit}
          >
            <h3>Where is the property you are listing?</h3>
            <div className="flex flex-col gap-4 mt-5 text-gray-700 text-[14px]">
              <div className="flex flex-col gap-4 p-5 max-w-[840px] w-full justify-center items-center bg-laara-secondary/35 border-[0.2px] border-custom-gray-300 rounded-lg m0-0">
                <div className="flex flex-col gap-4 max-w-[700px] w-full p-2">
                  <div className="flex flex-col gap-4">
                    <div className="flex flex-col w-full">
                      <label htmlFor="country" className="text-[14px]">
                        Region/Country
                      </label>
                      <input
                        type="text"
                        id="country"
                        value={addressData.country}
                        className="border py-2 px-3 rounded-lg block outline-none bg-transparent text-sm"
                        onChange={(e) =>
                          setAddressData({
                            ...addressData,
                            country: e.target.value,
                          })
                        }
                      />
                    </div>
                    <div className="flex flex-col w-full">
                      <label htmlFor="county" className="text-[14px]">
                        County
                      </label>
                      <input
                        type="text"
                        id="county"
                        value={addressData.county}
                        className="border py-2 px-3 rounded-lg block outline-none bg-transparent text-sm"
                        onChange={(e) =>
                          setAddressData({
                            ...addressData,
                            county: e.target.value,
                          })
                        }
                      />
                    </div>
                  </div>
                  <div className="flex flex-col gap-4">
                    <div className="flex flex-col w-full">
                      <label htmlFor="city" className="text-[14px]">
                        City
                      </label>
                      <input
                        type="text"
                        id="city"
                        value={addressData.city}
                        className="border py-2 px-3 rounded-lg block outline-none bg-transparent text-sm"
                        onChange={(e) =>
                          setAddressData({
                            ...addressData,
                            city: e.target.value,
                          })
                        }
                      />
                    </div>
                    <div className="flex flex-col w-full">
                      <label htmlFor="town" className="text-[14px]">
                        Town
                      </label>
                      <input
                        type="text"
                        id="town"
                        value={addressData.town}
                        className="border py-2 px-3 rounded-lg block outline-none bg-transparent text-sm"
                        onChange={(e) =>
                          setAddressData({
                            ...addressData,
                            town: e.target.value,
                          })
                        }
                      />
                    </div>
                  </div>
                  <div className="flex flex-col gap-4">
                    <div className="flex flex-col w-full">
                      <label htmlFor="street" className="text-[14px]">
                        Street
                      </label>
                      <input
                        type="text"
                        id="street"
                        value={addressData.street}
                        className="border py-2 px-3 rounded-lg block outline-none bg-transparent text-sm"
                        onChange={(e) =>
                          setAddressData({
                            ...addressData,
                            street: e.target.value,
                          })
                        }
                      />
                    </div>
                    <div className="flex flex-col w-full">
                      <label htmlFor="physicalAddress" className="text-[14px]">
                        Physical Address
                      </label>
                      <input
                        type="text"
                        id="physicalAddress"
                        value={addressData.physicalAddress}
                        className="border py-2 px-3 rounded-lg block outline-none bg-transparent text-sm"
                        onChange={(e) =>
                          setAddressData({
                            ...addressData,
                            physicalAddress: e.target.value,
                          })
                        }
                      />
                    </div>
                  </div>
                  <div className="flex flex-col gap-4">
                    <div className="flex flex-col w-full">
                      <label htmlFor="postCode" className="text-[14px]">
                        Postal Code
                      </label>
                      <input
                        type="text"
                        id="postCode"
                        value={addressData.postCode}
                        className="border py-2 px-3 rounded-lg block outline-none bg-transparent text-sm"
                        onChange={(e) =>
                          setAddressData({
                            ...addressData,
                            postCode: e.target.value,
                          })
                        }
                      />
                    </div>
                    <div className="flex flex-col w-full">
                      <label htmlFor="apartment" className="text-[14px]">
                        Apartment
                      </label>
                      <input
                        type="text"
                        id="apartment"
                        value={addressData.apartment}
                        className="border py-2 px-3 rounded-lg block outline-none bg-transparent text-sm"
                        onChange={(e) =>
                          setAddressData({
                            ...addressData,
                            apartment: e.target.value,
                          })
                        }
                      />
                    </div>
                  </div>
                </div>
                <div className="flex justify-between gap-10">
                  <button
                    type="button"
                    onClick={handleChangeLocationPin}
                    className="border border-laara-primary text-laara-primary py-2 px-4 rounded-[36px] w-[195px]"
                  >
                    Change Location Pin
                  </button>
                  <button
                    type="submit"
                    className="bg-laara-primary text-white py-2 px-4 rounded-[36px] w-[195px]"
                  >
                    Save Changes
                  </button>
                </div>
              </div>
            </div>
          </form>
        </section>
      </div>
    </div>
  );
};

export default EditPropertyLocation;
