import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import arrowleft from "@/assets/shared/arrowleft.svg";
import { MdOutlineQrCodeScanner } from "react-icons/md";
import { baseURL, headers } from "../../constants/apiconfig";
import { useSelector } from "react-redux";
import QrReader from "react-qr-scanner";  // Importing from react-qr-scanner

const ScanBookingQRCode = () => {
  const { ref } = useParams(); 
  const [qrCode, setQrCode] = useState(null);
  const [apiResponse, setApiResponse] = useState(null);
  const [scanning, setScanning] = useState(false);
  const [isSending, setIsSending] = useState(false);
  const navigate = useNavigate();
  const { currentUser, accessToken } = useSelector((state) => state.user);
  const token = currentUser?.stsTokenManager?.accessToken || accessToken;

  const handleScan = async (data) => {
    if (data && !isSending) {
      setQrCode(data);
      setIsSending(true);

      try {
        const response = await fetch(`${baseURL}/booking/check-in/${ref}`, {
          method: 'POST',
          headers: headers(token),
        });

        if (response.ok) {
          const resData = await response.json();
          setApiResponse("QR Code scanned and sent successfully: " + resData.message);
        } else {
          setApiResponse("Failed to send QR Code");
        }
      } catch (error) {
        setApiResponse("Error sending QR Code: " + error.message);
      } finally {
        setIsSending(false);
      }
    }
  };

  const handleError = (err) => {
    setApiResponse("Error scanning QR Code: " + err.message);
  };

  const previewStyle = {
    height: 240,
    width: 320,
  };

  return (
    <section className="w-full py-5 bg-[#FAFAFA]">
      <section className="2xl:max-w-[1440px] xl:max-w-[1280px] w-full mx-auto px-5 md:mb-10 lg:px-10 xl:px-7 2xl:px-10">
        <section className="xl:max-w-[1264px] xl:px-2 w-full space-y-5">
          {/* TOP SECTION */}
          <div className="flex items-center gap-2 md:gap-4">
            <img
              src={arrowleft}
              alt="arrow-left icon"
              onClick={() => navigate(-1)}
              className="size-6 cursor-pointer"
            />
            <h4 className="text-[20px] md:text-[24px] leading-[36px] font-medium text-[#626262]">
              Scan QR Code
            </h4>
          </div>

          {!scanning && (
            <div className="w-full h-[500px] xl:w-[500px] bg-[#000000]/20 mx-auto flex flex-col items-center justify-center">
              <button
                onClick={() => setScanning(true)}
                className="bg-white rounded-full border px-8 py-2 w-fit shadow-lg cursor-pointer flex items-center justify-center"
              >
                <MdOutlineQrCodeScanner className="text-black" size={30} />
                <p className="text-[#383838] font-medium text-base ml-2">
                  Start Scanning
                </p>
              </button>
            </div>
          )}

          {scanning && (
            <div className="w-full h-[500px] xl:w-[500px] bg-[#000000]/20 mx-auto flex flex-col items-center justify-center">
              <QrReader
                delay={300}
                style={previewStyle}
                onError={handleError}
                onScan={handleScan}  // Note: uses onScan instead of onResult
              />
              <button
                onClick={() => setScanning(false)}
                className="bg-white rounded-full border px-8 py-2 w-fit shadow-lg cursor-pointer flex items-center justify-center mt-4"
              >
                Stop Scanning
              </button>
            </div>
          )}

          {qrCode && (
            <p className="text-center mt-4">Scanned QR Code: {qrCode}</p>
          )}

          {apiResponse && (
            <p className="text-center mt-4 font-bold">{apiResponse}</p>
          )}
        </section>
      </section>
    </section>
  );
};

export default ScanBookingQRCode;
