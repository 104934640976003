import React , {useState} from "react";

export const ReportModal = ({ isOpen, onClose }) => {
    const [formData, setFormData] = useState({
      riskLevel: '',
      guestName: '',
      roomNumber: '',
      roomType: '',
      reportCategory: '',
      report: '',
      date: ''
    });
  
    const handleChange = (e) => {
      const { name, value } = e.target;
      setFormData(prevState => ({
        ...prevState,
        [name]: value
      }));
    };
  
    const handleSubmit = (e) => {
      e.preventDefault();
      onClose();
    };
  
    if (!isOpen) return null;
  
    return (
      <div className="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full flex justify-center items-center">
        <div className="bg-white p-5 rounded-lg shadow-lg w-[749px]">
          <div className="flex justify-between items-center mb-4">
            <h2 className="text-xl font-bold">Report</h2>
            <button onClick={onClose} className="text-gray-500 hover:text-gray-700">×</button>
          </div>
          <form onSubmit={handleSubmit}>
            <div className="mb-4">
              <label className="block mb-2">Risk level</label>
              <div className="flex items-center">
                <input
                  type="radio"
                  id="active"
                  name="riskLevel"
                  value="active"
                  checked={formData.riskLevel === 'active'}
                  onChange={handleChange}
                  className="mr-2 "
                />
                <label htmlFor="active" className="mr-4">Active</label>
                <input
                  type="radio"
                  id="inactive"
                  name="riskLevel"
                  value="inactive"
                  checked={formData.riskLevel === 'inactive'}
                  onChange={handleChange}
                  className="mr-2"
                />
                <label htmlFor="inactive">Inactive</label>
              </div>
            </div>
            <div className="mb-4">
              <label className="block mb-2">Guest name</label>
              <input
                type="text"
                name="guestName"
                value={formData.guestName}
                onChange={handleChange}
                className="w-full p-2 border rounded"
              />
            </div>
            <div className="flex mb-4 space-x-2">
              <div className="flex-1">
                <label className="block mb-2">Room Number</label>
                <input
                  type="text"
                  name="roomNumber"
                  value={formData.roomNumber}
                  onChange={handleChange}
                  className="w-full p-2 border rounded"
                />
              </div>
              <div className="flex-1">
                <label className="block mb-2">Room Type</label>
                <input
                  type="text"
                  name="roomType"
                  value={formData.roomType}
                  onChange={handleChange}
                  className="w-full p-2 border rounded"
                />
              </div>
              <div className="flex-1">
                <label className="block mb-2">Report category</label>
                <input
                  type="text"
                  name="reportCategory"
                  value={formData.reportCategory}
                  onChange={handleChange}
                  className="w-full p-2 border rounded"
                />
              </div>
            </div>
            <div className="mb-4">
              <label className="block mb-2">Report</label>
              <textarea
                name="report"
                value={formData.report}
                onChange={handleChange}
                className="w-full p-2 border rounded h-24"
              ></textarea>
            </div>
            <div className="mb-4">
              <label className="block mb-2">Date</label>
              <input
                type="date"
                name="date"
                value={formData.date}
                onChange={handleChange}
                className="p-2 border rounded"
              />
            </div>
            <button type="submit" className="w-[202px] bg-[#0466C8] text-white p-2 rounded-full float-right">
              Save
            </button>
          </form>
        </div>
      </div>
    );
  };