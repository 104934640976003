import { useState, useEffect } from "react";
import arrowleft from "@/assets/shared/arrowleft.svg";
import { Link, useNavigate } from "react-router-dom";
import BookingsCard from "@/components/BookingsCard/BookingsCard";
import BookingsCarousel from "../../components/BookingsCard/carousels";
import SearchProperty from "@/components/BookingsCard/SearchProperty";
import scanbarcode from "@/assets/shared/scan-barcode.svg";
import { baseURL, headers } from "../../constants/apiconfig";
import { useSelector } from "react-redux";

const Bookings = () => {
  const navigate = useNavigate();
  const [filteredBookings, setFilteredBookings] = useState([]);
  const [activeButton, setActiveButton] = useState("overview");
  const [allBookings, setAllBookings] = useState([]); // Holds all bookings
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { currentUser, accessToken } = useSelector((state) => state.user);  
  const token = currentUser?.stsTokenManager?.accessToken || accessToken;
  const [query, setQuery] = useState("");

  const status = [
    "pending payment",
    "pending full payment",
    "upcoming",
    "checked in",
    "awaiting review",
    "completed",
  ];
  
  useEffect(() => {
    const fetchBookings = async () => {
      setLoading(true);
      try {
        if (!token) {
          throw new Error("No authentication token found");
        }
        const response = await fetch(`${baseURL}/users/reservations/`, {
          headers: headers(token),
        });
        
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        
        const data = await response.json();
  
        if (data.status) {
          setAllBookings(data.reservations);
          setFilteredBookings(data.reservations);
        } else {
          throw new Error(data.message || "Failed to fetch bookings");
        }
      } catch (err) {
        if (err.message.includes('Network error')) {
          setError('Network error occurred. Please check your internet connection.');
        } else if (err.message.includes('HTTP error')) {
          setError(`API error: ${err.message}`);
        } else {
          setError(`Unexpected error: ${err.message}`);
        }
      } finally {
        setTimeout(() => {
          setLoading(false);
        }, 1500);
      }
    };
  
    fetchBookings();
  }, [token, baseURL]);

  const filterBookings = (status) => {
    setActiveButton(status);

    if (status === "overview") {
      setFilteredBookings(allBookings); // Show all bookings
    } else {
      const selectedBookings = allBookings.filter((item) => {
        const normalizedStatus = item.status.toLowerCase().replace('_', ' ');
        const normalizedFilter = status.toLowerCase();
        return normalizedStatus === normalizedFilter;
      });

      setFilteredBookings(selectedBookings);
    }
  };

  useEffect(() => {
    const performSearch = () => {
      if (query === "" || query.trim() === "") {
        filterBookings(activeButton);
      } else {
        const updateFilter = allBookings.filter(
          (booking) =>
            (booking.rooms?.properties?.name?.toLowerCase() || '').includes(query.toLowerCase()) ||
            (booking.rooms?.properties?.address?.city?.toLowerCase() || '').includes(query.toLowerCase()) ||
            (booking.rooms?.properties?.address?.country?.toLowerCase() || '').includes(query.toLowerCase()) ||
            (booking.ref?.toLowerCase() || '').includes(query.toLowerCase())
        );
        setFilteredBookings(updateFilter);
        if (updateFilter.length > 0) {
          const resultStatus = updateFilter[0].status.toLowerCase().replace('_', ' ');
          const allSimilarStatus = updateFilter.every(
            (booking) => booking.status.toLowerCase().replace('_', ' ') === resultStatus
          );
          setActiveButton(allSimilarStatus ? resultStatus : "overview");
        } else {
          setActiveButton("overview");
        }
      }
    };

    performSearch();
  }, [query, allBookings]);

  const handleSearch = (searchQuery) => {
    setQuery(searchQuery);
  };

  const handleActiveBtn = (status) => {
    return activeButton === status.toLowerCase()
      ? "text-laara-primary underline underline-offset-8 text-base text-[#3F3F3F]"
      : "text-[#3F3F3F]";
  };

  if (loading) {
    return (
      <div className="flex justify-center items-center h-screen">
        <div className="w-16 h-16 border-4 border-t-4 border-gray-200 border-t-blue-500 border-solid rounded-full animate-spin"></div>
      </div>
    );
  }
  
  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <section className="w-full py-5 bg-[#FAFAFA]">
      <section className="2xl:max-w-[1440px] xl:max-w-[1280px] w-full mx-auto px-5 md:mb-10 lg:px-10 xl:px-7 2xl:px-10">
        <section className="xl:max-w-[1264px] xl:px-2 w-full mx-auto space-y-5">
          {/* TOP SECTION */}
          <div className="flex items-center justify-between xl:px-2">
            <div className="flex items-center gap-2">
              <img
                src={arrowleft}
                alt="arrow-left icon"
                onClick={() => navigate(-1)}
                className="size-6 cursor-pointer"
              />
              <h4 className="text-xl lg:text-lg font-medium text-[#626262]">
                Manage Bookings
              </h4>
            </div>
            <div className="flex items-center gap-2">
            <SearchProperty query={query} setQuery={handleSearch} />              {/* Dynamic Link based on booking ref */}
              {filteredBookings.length > 0 && (
                <Link
                  to={`/scan-booking/${filteredBookings[0].ref}`}
                  className="flex items-center gap-2 text-white text-base font-medium bg-laara-primary rounded-full p-2"
                >
                  <img
                    src={scanbarcode}
                    alt="scan icon"
                    loading="lazy"
                    className="size-[22px]"
                  />
                  Check In
                </Link>
              )}
            </div>
          </div>

          {/* CAROUSEL SECTION */}
          <section className="hidden xl:block max-w-[1248px] p-2 w-full h-[210px]">
            <BookingsCarousel />
          </section>

          {/* MOBILE */}
          <div className="w-full space-y-2 md:hidden">
            <p>Current Bookings</p>
            <select
              name="dropdown"
              id="dropdown"
              placeholder="Filter"
              className="py-1 px-2 rounded-lg"
              onChange={(e) => filterBookings(e.target.value)}
            >
              <option value="overview">Overview</option>
              {status.map((item) => (
                <option key={item} value={item}>
                  {item}
                </option>
              ))}
            </select>
          </div>

          {/* BOOKINGS SECTION */}
          <section className="xl:max-w-[1248px] w-full px-2 h-[876px] flex flex-col gap-4">
            {/* BUTTONS */}
            <div className="hidden md:flex w-full py-2 items-center gap-4">
              <button
                className={`${handleActiveBtn(
                  "overview"
                )} capitalize hover:cursor-pointer hover:underline hover:underline-offset-8 hover:text-laara-primary`}
                onClick={() => filterBookings("overview")}
              >
                Overview
              </button>

              {status.map((item) => (
                <button
                  key={item}
                  className={`${handleActiveBtn(
                    item
                  )} capitalize hover:cursor-pointer hover:underline-offset-8 hover:text-laara-primary`}
                  onClick={() => filterBookings(item)}
                >
                  {item}
                </button>
              ))}
            </div>

            {/* BOOKINGS INFO CARDS */}
            <section className="xl:max-w-[78rem] 2xls:max-w-[1014px] w-full h-[850px] overflow-y-auto">
              <div className="xl:max-w-[77rem] w-full flex flex-col md:flex-row lg:items-start lg:justify-between items-center justify-between flex-wrap gap-x-2 gap-y-4">
                {filteredBookings.map((item) => (
                  <BookingsCard key={item.ref} props={item} />
                ))}
              </div>
            </section>
          </section>
        </section>
      </section>
    </section>
  );
};

export default Bookings;
