import { useState, useEffect } from 'react';
import { Link, useParams, useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLessThan } from '@fortawesome/free-solid-svg-icons';
import { getPropertyById, getToken, updateMealPlans } from '@/api'; // Adjust the import path if needed
import './editproperty.css';

// Define meal plan options (with correct formatting)
const mealPlansOptions = [
  "All Inclusive",
  "Bed and Breakfast",
  "Half Board",
  "Full Board",
  "Bed Only"
];

const MealPlans = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [selectedMealPlans, setSelectedMealPlans] = useState([]);

  useEffect(() => {
  const fetchPropertyData = async () => {
    try {
      const token = getToken();
      const property = await getPropertyById(id, token);
      
      // Ensure mealPlans exists and handle missing data gracefully
      const mealOptions = property?.property?.mealPlans?.map(option => option.name) || [];
      setSelectedMealPlans(mealOptions);
    } catch (error) {
      console.error('Error fetching property data:', error);
    }
  };

  fetchPropertyData();
}, [id]);

  // Handle checkbox changes
  const handleCheckboxChange = (item) => {
    setSelectedMealPlans(prev => {
      if (prev.includes(item)) {
        return prev.filter(i => i !== item);
      } else {
        return [...prev, item];
      }
    });
  };

  // Handle form submission
  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      const token = getToken();
      const mealPlansData = {
        mealPlans: selectedMealPlans.map(name => ({ name })) // Correct format for the API
      };
      await updateMealPlans(id, mealPlansData, token);
      navigate(`/view-property/${id}`);
    } catch (error) {
      console.error('Error updating meal plans:', error);
    }
  };

  return (
    <div className="flex flex-col min-h-screen p-8 bg-base-white">
      <div className="flex-1">
        <section className='top h-16 flex items-center p-2 gap-3'>
          <div className='flex items-center gap-4 text-lg text-gray-600 w-[700px]'>
            <Link to={`/view-property/${id}`}>
              <FontAwesomeIcon icon={faLessThan} className="text-base" />
            </Link>
            <h3>Meal Plans</h3>
          </div>
        </section>
        <section className="flex w-full max-w-[1264px] gap-5 h-max">
          <form className="flex flex-col p-2 w-full max-h-[700px] h-full" onSubmit={handleSubmit}>
            <div className="flex flex-col gap-4 mt-5 text-gray-700 text-[14px] overflow-y-auto">
              <div className="flex flex-col gap-4 p-5 w-full justify-center items-center">
                <div className="flex flex-col gap-4 w-full p-2">
                  <h2 className="text-xl mb-4">What meal plans are available at your place?</h2>
                  <div className="flex flex-col w-full p-4 rounded-lg border border-custom-gray-300">
                    {mealPlansOptions.map((item) => (
                      <label key={item} className="flex items-center gap-4 mb-3">
                        <input
                          type="checkbox"
                          checked={selectedMealPlans.includes(item)}
                          onChange={() => handleCheckboxChange(item)}
                          className="size-5 custom-checkbox appearance-none border border-gray-300 rounded-md checked:bg-blue-500 checked:border-transparent focus:outline-none"
                        />
                        {item}
                      </label>
                    ))}
                  </div>
                </div>
              </div>
              <div className="w-full flex justify-end gap-5 items-center mt-5">
                <button
                  type="submit"
                  className="py-2 px-4 bg-blue-500 text-white rounded-[36px] w-[216px] h-[55px]"
                >
                  Save Changes
                </button>
              </div>
            </div>
          </form>
        </section>
      </div>
    </div>
  );
};

export default MealPlans;
