import React from "react";
import { setFormData } from "@/redux/Listing/listingSlice";
import { useSelector, useDispatch } from "react-redux";
import { Checkbox } from "@/components/ui/checkbox";

const Language = () => {
  const dispatch = useDispatch();

  // Ensure the selector matches the state shape
  const languagess = useSelector(
    (state) => state.hostListing.formData.languages
  );

  const avalableLanguages = [
    "english",
    "spanish",
    "french",
    "german",
    "swahili",
  ];

  const handleChange = (isChecked, name) => {
    const updatedLanguages = isChecked
      ? [...languagess, { name }]
      : languagess.filter((language) => language.name !== name);

    dispatch(setFormData({ languages: updatedLanguages }));
  };

  return (
    <div>
      <h4 className="font-medium text-xl">
        Please select language spoken by staff
      </h4>
      <div className="mt-5 space-y-3 lg:max-w-[727px] w-full h-[360px] border border-[#A3A3A3] py-8 px-6 rounded-[6px]">
        {avalableLanguages.map((item) => {
          const isChecked = languagess?.some(
            (language) => language.name === item
          );
          return (
            <div key={item} className="flex items-center gap-3">
              <Checkbox
                id={item}
                name={item}
                className="size-6 rounded-lg"
                checked={languagess?.some((language) => language.name === item)}
                onCheckedChange={(isChecked) => handleChange(isChecked, item)}
              />
              <label
                htmlFor={item}
                className={`capitalize text-base ${
                  isChecked ? "text-laara-primary" : "text-black"
                }`}
              >
                {item}
              </label>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default Language;
