import { useState, useEffect } from "react";
import axios from "axios";
import { z } from "zod";
import { useNavigate } from "react-router-dom";
import { IoCloseSharp } from "react-icons/io5";
import GoogleLogo from "../../assets/LoginPage/google-color-svgrepo-com (1).svg";
import FacebookLogo from "../../assets/LoginPage/facebook-svgrepo-com.svg";
import ImageLogo from "../../assets/LoginPage/GroupImage.svg";
import logo from "@/assets/shared/Group.svg";

// Import necessary Firebase auth and other modules
import { createUserWithEmailAndPassword, signInWithPopup } from "firebase/auth";
import { auth, provider } from "../../firebase.config"; // Adjust the path as needed
import { signInSuccess, setToken } from "@/redux/user/userSlice";
import { useDispatch } from "react-redux";
import { baseURL, headers } from "@/constants/apiconfig";
// Update with actual path to Facebook logo

const registrationSchema = z
  .object({
    firstName: z
      .string()
      .min(1, "First name is required")
      .min(2, "First name must be at least 2 characters long"),
    lastName: z
      .string()
      .min(1, "Last name is required")
      .min(2, "Last name must be at least 2 characters long"),
    email: z.string().min(1, "Email is required").email("Invalid email format"),
    phone: z
      .string()
      .min(1, "Phone number is required")
      .min(10, "Phone number must be at least 10 characters long"),
    password: z
      .string()
      .min(1, "Password is required")
      .min(6, "Password must be at least 6 characters long"),
    confirmPassword: z
      .string()
      .min(1, "Confirm password is required")
      .min(6, "Confirm password must be at least 6 characters long"),
  })
  .refine((data) => data.password === data.confirmPassword, {
    message: "Passwords do not match",
    path: ["confirmPassword"],
  });

const Register = () => {
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    password: "",
    confirmPassword: "",
  });
  const [errors, setErrors] = useState({});
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  const role = "USER";
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formattedErrors = {};
    setIsLoading(true);

    try {
      // Check required fields
      for (const [key, value] of Object.entries(formData)) {
        if (!value) {
          formattedErrors[key] = "Please fill out this field";
        }
      }

      if (Object.keys(formattedErrors).length > 0) {
        setErrors(formattedErrors);
        return;
      }

      registrationSchema.parse(formData); // Validate user input

      // Firebase registration
      const userCredential = await createUserWithEmailAndPassword(
        auth,
        formData.email,
        formData.password
      );

      const token = await userCredential.user.getIdToken();

      const { firstName, lastName, email, phone } = formData;
      const userData = {
        firstName,
        lastName,
        emailAddress: email,
        phoneNumber: phone,
        role,
      };

      const response = await axios.post(`${baseURL}/users`, userData, {
        headers: headers(token),
      });

      if (response.data.status) {
        setIsLoading(false);
        navigate("/login");
      }
    } catch (error) {
      setIsLoading(false);
      if (error instanceof z.ZodError) {
        error.errors.forEach((err) => {
          formattedErrors[err.path[0]] = err.message;
        });
        setErrors(formattedErrors);
      } else if (error) {
        setIsLoading(false);
        // Firebase error

        setErrors({ firebase: error.message });
      } else {
        setIsLoading(false);
        setErrors({ general: "An error occurred during registration" });
        throw new Error("Error registering:", error);
      }
    }
  };

  // const handleGoogleSignUp = async () => {
  //   try {
  //     const result = await signInWithPopup(auth, provider);
  //     const user = result.user;
  //     const token = await user.getIdToken();

  //     const fullName = user.displayName || "";
  //     const nameParts = fullName.split(" ");
  //     const firstName = nameParts[0] || "";
  //     const lastName = nameParts.slice(1).join(" ") || "";
  //     const phoneNumber = user.phoneNumber || ""; // Add phone number if available

  //     const data = {
  //       emailAddress: user.email,
  //       firstName,
  //       lastName,
  //       phoneNumber, // Include phone number in the data
  //       role,
  //     };

  //     // Send data to the endpoint
  //     // const response = await axios.post(`${baseURL}/users`, data, {
  //     //   headers: headers(token),
  //     // });
  //     const response = await fetch(`${baseURL}/users`, {
  //       method: "POST",
  //       headers: {
  //         ...headers(token),
  //         "Content-Type": "application/json",
  //       },
  //       body: JSON.stringify(data),
  //     });

  //     console.log(response);

  //     if (!response.ok) {
  //       const errorText = await response.message();
  //       setErrors(errorText);
  //     }
  //     setIsLoading(false);
  //     navigate("/login");
  //   } catch (error) {
  //     setIsLoading(false);
  //     alert(`An error occurred: ${error.message}`);
  //     setErrors({
  //       google: `An error occurred during Google sign-up: ${error}`,
  //     });
  //     throw new Error("Error with Google sign-up:", error);
  //   }
  // };

  const handleGoogleSignUp = async () => {
    try {
      // Google sign-in using Firebase
      const result = await signInWithPopup(auth, provider);
      const user = result.user;

      // Get user information from Google
      const token = await user.getIdToken();
      const { displayName, email, phoneNumber } = user;

      // Parse the name into first and last name (if needed)
      const [firstName, lastName] = displayName.split(" ");

      // Prepare the user data, including the role
      const userData = {
        firstName,
        lastName,
        emailAddress: email,
        phoneNumber,
        role, // Use the same role variable as in handleSubmit
      };

      // Send the user data to your backend to register the user
      const response = await axios.post(`${baseURL}/users`, userData, {
        headers: headers(token),
      });

      if (response.data.status) {
        // Dispatch sign-in success and navigate to the desired page

        dispatch(setToken(token));
        dispatch(
          signInSuccess({
            email: user.email,
            displayName: user.displayName,
            lastSeen: user.metadata.lastSignInTime,
            avatar: user.photoURL,
            uid: user.uid,
          })
        );
        setIsLoading(false);
        navigate("/");
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      setErrors({ google: "An error occurred during Google sign-up" });
      throw new Error("Error with Google sign-up:", error);
    }
  };

  console.log(errors);

  return (
    <div className="min-h-full flex flex-wrap">
      {/* Left column for the image */}
      <div className="lg:bg-[hsla(185,100%,98%,1)] w-full lg:w-[34.4375rem] lgs:h-[63.625rem]  lg:p-[5.8125rem 4.475625rem 5.875rem 4.4375rem] relative">
        <span className="absolute top-[28px] left-[90.34px] block lg:hidden text-[hsla(0,0%,16%,1)] transition-all duration-300 ease-in-out transform hover:scale-150">
          <IoCloseSharp />
        </span>
        <div className="bg-lightCyan py-6 w-full h-[4.125rem] lg:absolute top-[5.813rem] left-[9.5rem] lg:w-[15.36875rem] lg:h-[5rem] mb-4 mx-auto lg:mt-5">
          <img
            src={logo}
            alt=""
            className="mx-auto w-[5rem] h-[1.625rem] lg:w-[15.36875rem] lg:h-[5rem]"
          />
        </div>
        <div className="mx-auto">
          <img
            src={ImageLogo}
            alt=""
            className="mx-auto mt-8 w-[8.898rem] h-[5.758rem] lg:absolute lg:top-[25.063rem] lg:left-[4.438rem] lg:w-[25.52375rem] lg:h-[16.414rem]"
          />
        </div>
        <div className="w-full text-center lg:w-[17.875rem] lg:h-[1.875rem]">
          <p className="hidden lg:absolute -bottom-16 tops-[45.875rem] left-[8.25rem] lg:block font-poppins text-xl font-normal leading-[1.875rem]">
            Comfort meets convenience
          </p>
        </div>
      </div>

      {/* Right column for the form */}
      <div className="w-full lg:w-[50%] mt-12 bg-white flex flex-col">
        <div className="w-full p-4 lg:mt-[15%]">
          {isMobile ? null : (
            <button className="ml-[719px] mb-0 text-gray-500 transition-all duration-300 ease-in-out transform hover:scale-150">
              <IoCloseSharp />
            </button>
          )}
          <h4
            className={`text-black font-bold text-2xl mb-4 ${
              isMobile ? "text-center" : "text-left"
            }`}
          >
            {isMobile ? "REGISTER" : "CREATE ACCOUNT"}
          </h4>
          <p className="pb-3 text-xs mb-3 text-gray-400 text-left">
            Already have an account?{" "}
            <a href={"login"} className="text-blue-600 hover:text-blue-500">
              Sign in here
            </a>
          </p>
          <form onSubmit={handleSubmit}>
            <div className="w-full flex flex-col grid grid-cols-1 lg:grid-cols-2 gap-4">
              <label className="relative">
                <input
                  type="text"
                  name="firstName"
                  className={`px-4 py-2 w-full h-10 text-sm outline-none bg-white ${
                    errors.firstName ? "border-red-500" : "border-black"
                  } border-[1px] border-gray-400 rounded hover:border-gray-600 duration-200 peer focus:border-blue-400 bg-inherit`}
                  value={formData.firstName}
                  onChange={handleChange}
                  required
                />
                <span className="absolute left-0 top-2 px-1 text-sm tracking-wide peer-focus:text-blue-400 pointer-events-none duration-200 peer-focus:text-sm peer-focus:-translate-y-5 bg-white ml-2 peer-valid:text-sm peer-valid:-translate-y-5">
                  First Name
                </span>
                {errors.firstName && (
                  <p className="text-red-500 mt-1">{errors.firstName}</p>
                )}
              </label>
              <label className="relative">
                <input
                  type="text"
                  name="lastName"
                  className={`px-4 py-2 w-full h-10 text-sm outline-none bg-white ${
                    errors.lastName ? "border-red-500" : "border-black"
                  } border-[1px] border-gray-400 rounded hover:border-gray-600 duration-200 peer focus:border-blue-400 bg-inherit`}
                  value={formData.lastName}
                  onChange={handleChange}
                  required
                />
                <span className="absolute left-0 top-2 px-1 text-sm tracking-wide peer-focus:text-blue-400 pointer-events-none duration-200 peer-focus:text-sm peer-focus:-translate-y-5 bg-white ml-2 peer-valid:text-sm peer-valid:-translate-y-5">
                  Last Name
                </span>
                {errors.lastName && (
                  <p className="text-red-500 mt-1">{errors.lastName}</p>
                )}
              </label>
              <label className="relative">
                <input
                  type="text"
                  name="email"
                  className={`px-4 py-2 w-full h-10 text-sm outline-none bg-white ${
                    errors.email ? "border-red-500" : "border-black"
                  } border-[1px] border-gray-400 rounded hover:border-gray-600 duration-200 peer focus:border-blue-400 bg-inherit`}
                  value={formData.email}
                  onChange={handleChange}
                  required
                />
                <span className="absolute left-0 top-2 px-1 text-sm tracking-wide peer-focus:text-blue-400 pointer-events-none duration-200 peer-focus:text-sm peer-focus:-translate-y-5 bg-white ml-2 peer-valid:text-sm peer-valid:-translate-y-5">
                  Email
                </span>
                {errors.email && (
                  <p className="text-red-500 mt-1">{errors.email}</p>
                )}
              </label>
              <label className="relative">
                <input
                  type="text"
                  name="phone"
                  className={`px-4 py-2 w-full h-10 text-sm outline-none bg-white ${
                    errors.phone ? "border-red-500" : "border-black"
                  } border-[1px] border-gray-400 rounded hover:border-gray-600 duration-200 peer focus:border-blue-400 bg-inherit`}
                  value={formData.phone}
                  onChange={handleChange}
                  required
                />
                <span className="absolute left-0 top-2 px-1 text-sm tracking-wide peer-focus:text-blue-400 pointer-events-none duration-200 peer-focus:text-sm peer-focus:-translate-y-5 bg-white ml-2 peer-valid:text-sm peer-valid:-translate-y-5">
                  Phone Number
                </span>
                {errors.phone && (
                  <p className="text-red-500 mt-1">{errors.phone}</p>
                )}
              </label>
              <label className="relative lg:col-span-2">
                <input
                  type="password"
                  name="password"
                  className={`px-4 py-2 w-full h-10 text-sm outline-none bg-white ${
                    errors.password ? "border-red-500" : "border-black"
                  } border-[1px] border-gray-400 rounded hover:border-gray-600 duration-200 peer focus:border-blue-400`}
                  value={formData.password}
                  onChange={handleChange}
                  required
                />
                <span className="absolute left-0 top-2 px-1 text-sm tracking-wide peer-focus:text-blue-400 pointer-events-none duration-200 peer-focus:text-sm peer-focus:-translate-y-5 bg-white ml-2 peer-valid:text-sm peer-valid:-translate-y-5">
                  Password
                </span>
                {errors.password && (
                  <p className="text-red-500 mt-1">{errors.password}</p>
                )}
              </label>
              <label className="relative lg:col-span-2">
                <input
                  type="password"
                  name="confirmPassword"
                  className={`px-4 py-2 w-full h-10 text-sm outline-none bg-white ${
                    errors.confirmPassword ? "border-red-500" : "border-black"
                  } border-[1px] border-gray-400 rounded hover:border-gray-600 duration-200 peer focus:border-blue-400`}
                  value={formData.confirmPassword}
                  onChange={handleChange}
                  required
                />
                <span className="absolute left-0 top-2 px-1 text-sm tracking-wide peer-focus:text-blue-400 pointer-events-none duration-200 peer-focus:text-sm peer-focus:-translate-y-5 bg-white ml-2 peer-valid:text-sm peer-valid:-translate-y-5">
                  Confirm Password
                </span>
                {errors.confirmPassword && (
                  <p className="text-red-500 mt-1">{errors.confirmPassword}</p>
                )}
              </label>
            </div>
            <div className="text-xs text-gray-600 mb-4 text-left mt-4">
              {isMobile ? (
                <p>
                  <input
                    type="checkbox"
                    id="Agree1"
                    name="Agree1"
                    value="Agree"
                  />
                  <label htmlFor="Agree1" className="ml-2">
                    I agree to all the terms and privacy policy
                  </label>
                </p>
              ) : (
                <p>
                  <input
                    type="checkbox"
                    id="Agree2"
                    name="Agree2"
                    value="Agree"
                  />
                  <label htmlFor="Agree2" className="ml-2">
                    By clicking create account, I agree that I have read and
                    accepted all the{" "}
                    <a
                      href={"terms"}
                      className="text-blue-600 hover:text-blue-500"
                    >
                      Terms of Use
                    </a>{" "}
                    and{" "}
                    <a
                      href={"privacy"}
                      className="text-blue-600 hover:text-blue-500"
                    >
                      Privacy Policy
                    </a>
                  </label>
                </p>
              )}
            </div>
            {/* <div className="flex justify-center"> */}
            <button
              type="submit"
              className={`block w-full h-10 rounded-md bg-blue-500 text-white text-sm font-medium shadow-md transition duration-150 ease-in-out hover:bg-blue-600 focus:bg-blue-400 active:bg-blue-700`}
              disabled={isLoading}
            >
              {isLoading ? "Creating Account ..." : "Create Account"}
            </button>
            {/* </div> */}
            <div className="my-4 flex items-center justify-center">
              <div className="flex-1 border-t border-gray-300"></div>
              <p className="mx-4 mb-0 text-center font-semibold">OR</p>
              <div className="flex-1 border-t border-gray-300"></div>
            </div>
            {/* Social login buttons */}
            {isMobile ? (
              <div className="flex flex-col items-center gap-3">
                <h5 className="text-bold">Register with</h5>
                <div className="flex flex-col-2 items-center mt-2 ">
                  <img
                    src={GoogleLogo}
                    className="w-[42px] h-[42px] p-1 mr-3 border border-gray-300 "
                    alt="Google Logo"
                  />
                  <img
                    src={FacebookLogo}
                    className="w-[42px] h-[42px] p-1 mr-3 border border-gray-300 "
                    alt="Facebook Logo"
                  />
                </div>
              </div>
            ) : (
              <div className="flex flex-col md:flex-row gap-3">
                <button
                  onClick={handleGoogleSignUp}
                  className="flex w-full md:w-1/2 h-10 items-center justify-center rounded bg-white border border-gray-300 px-4 py-2 text-sm font-medium text-gray-700 shadow transition duration-150 ease-in-out hover:bg-gray-100"
                >
                  <img
                    src={GoogleLogo}
                    className="w-6 h-6 mr-3"
                    alt="Google Logo"
                  />
                  Sign Up with Google
                </button>
              </div>
            )}
            {errors.google && (
              <p className="text-red-500 mt-1">{errors.google}</p>
            )}
            {errors.firebase && (
              <p className="text-red-500 mt-1">{errors.firebase}</p>
            )}
          </form>
        </div>
      </div>
    </div>
  );
};

export default Register;
