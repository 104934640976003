import React, { useState, useEffect, useCallback } from 'react';
import { useDropzone } from 'react-dropzone';
import { useDispatch, useSelector } from 'react-redux';
import tick from '../../../assets/ListingProcess/tick.svg';
import hand from '../../../assets/ListingProcess/hand.svg';
import cancel from '../../../assets/ListingProcess/cancel.svg';
import camera from '../../../assets/ListingProcess/camera.svg';
import photo from '../../../assets/ListingProcess/photo.svg';
import { setPhotos, removePhoto } from '@/redux/Listing/listingSlice';

const StaffPhotos = () => {
  const dispatch = useDispatch();
  const photosFromRedux = useSelector((state) => state.hostListing.formData.photos);
  const [photos, setPhotosState] = useState([]);
  const [submitStatus, setSubmitStatus] = useState(null);

  useEffect(() => {
    setPhotosState(photosFromRedux || []);
  }, [photosFromRedux]);

  const onDrop = useCallback((acceptedFiles) => {
    const validFiles = acceptedFiles.filter(file => file instanceof File);

    const updatedPhotos = validFiles.map(file => ({
      file,
      path: file.name,
      preview: URL.createObjectURL(file)
    }));

    setPhotosState((prevPhotos) => [...prevPhotos, ...updatedPhotos]);
    dispatch(setPhotos([...photos, ...updatedPhotos]));
  }, [photos, dispatch]);

  const handleRemovePhoto = (photoPath) => {
    setPhotosState((prevPhotos) => prevPhotos.filter(photo => photo.path !== photoPath));
    dispatch(removePhoto(photoPath));
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: {
      'image/jpeg': ['.jpg', '.jpeg'],
      'image/png': ['.png'],
    },
    maxSize: 40 * 1024 * 1024, // 40MB
  });

  return (
    <div className="flex flex-col pb-20 bg-white">
      <div className="flex flex-col self-center mt-5 w-full max-md:mt-10 max-md:max-w-full">
        <div className="flex flex-col mt-5 w-full max-w-[1077px] max-md:mt-10 max-md:max-w-full">
          <section className="mt-14 text-3xl font-semibold text-zinc-800 max-md:mt-10 max-md:max-w-full">
            What does the Staff look like?
          </section>
          <section className="mt-6 text-3xl text-neutral-500 max-md:max-w-full">
            (Optional)
          </section>
          <div className="mt-5 max-md:max-w-full">
            <div className="flex gap-5 max-md:flex-col">
              <div className="flex flex-col w-[69%] max-md:ml-0 max-md:w-full">
                <div className="flex flex-col grow px-12 pt-10 pb-2.5 w-full text-lg font-medium rounded-md bg-slate-50 bg-opacity-80 border border-neutral- max-md:px-5 max-md:mt-5 max-md:max-w-full">
                  <p className="text-black max-md:max-w-full">
                    Upload at least 5 photos of the Staff offered.
                    <span className="text-neutral-500"> Images of dishes, meals, </span>
                    <br />
                    <span className="text-neutral-500">and culinary offerings available at the property, enticing guests with </span>
                    <br />
                    <span className="text-neutral-500">visual representations of dining options</span>
                  </p>
                  <div {...getRootProps()} className="flex flex-col items-center px-20 py-5 mt-1.5 text-base border border-black border-dashed max-md:px-5 max-md:max-w-full">
                    <input {...getInputProps()} />
                    <img loading="lazy" src={photo} className="w-28 max-w-full aspect-square" alt="Upload" />
                    <div className="mt-4 text-center text-black">Drag and drop or</div>
                    <label
                      htmlFor="file-input"
                      className="flex gap-5 px-7 py-4 mt-4 text-center text-sky-600 border border-sky-600 border-solid rounded-[36px] max-md:px-5 cursor-pointer"
                    >
                      <img loading="lazy" src={camera} className="shrink-0 w-6 aspect-square" alt="Camera icon" />
                      <div className="flex-auto">Upload photos</div>
                    </label>
                    <div className="mt-4 text-neutral-500">JPG/JPEG or PNG. maximum 40MB each</div>
                  </div>
                  <div className="self-center mt-3.5 text-black">Upload a maximum of 200 photos</div>
                  <div className="mt-4">
                    {photos.length > 0 && (
                      <div>
                        <h3 className="text-lg font-semibold">Uploaded Photos:</h3>
                        <div className="flex flex-wrap gap-4">
                          {photos.map((photo, index) => (
                            <div key={index} className="relative">
                              <img
                                src={photo.preview}
                                alt={`Property Photo ${index + 1}`}
                                className="w-32 h-32 object-cover rounded-lg"
                              />
                              <button
                                type="button"
                                onClick={() => handleRemovePhoto(photo.path)}
                                className="absolute top-1 right-1 bg-red-500 text-white rounded-full p-0.5 text-xs"
                              >
                                <svg width="12" height="12" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M6 18L18 6M6 6L18 18" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/></svg>
                              </button>
                            </div>
                          ))}
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className="flex flex-col ml-5 w-[31%] max-md:ml-0 max-md:w-full">
                <div className="flex flex-col px-4 py-5 mx-auto w-full text-sm text-black bg-slate-50 bg-opacity-80 border border-neutral-200 rounded-md max-md:mt-5">
                  <div className="flex gap-5 justify-between w-full text-base font-medium">
                    <div className="flex gap-4">
                      <img loading="lazy" src={hand} className="shrink-0 self-start aspect-square w-[30px]" alt="Hand icon" />
                      <div>Here are some tips on taking great photos of your property</div>
                    </div>
                    <img loading="lazy" src={cancel} className="shrink-0 self-start w-2 aspect-square" alt="Cancel icon" />
                  </div>
                  <div className="flex gap-3 self-center mt-3 ml-12">
                    <img loading="lazy" src={tick} className="shrink-0 my-auto aspect-[1.45] w-[13px]" alt="Tick icon" />
                    <div>Hosts should ensure high-quality, clear, and representative photos.</div>
                  </div>
                  <div className="flex gap-1.5 self-end mt-7 max-md:mr-2.5 ml-12">
                    <img loading="lazy" src={tick} className="shrink-0 my-auto aspect-[1.45] w-[13px]" alt="Tick icon" />
                    <div>Photos should accurately depict the property, staff, and food offerings to attract and inform potential guests effectively</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {submitStatus && (
            <div className={`mt-4 p-4 rounded-md ${submitStatus === 'success' ? 'bg-green-200 text-green-800' : 'bg-red-200 text-red-800'}`}>
              {submitStatus === 'success' ? 'Photos submitted successfully!' : 'Failed to submit photos.'}
            </div>
          )}
          
        </div>
      </div>
    </div>
  );
};

export default StaffPhotos;
