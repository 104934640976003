import search from "@/assets/shared/search-normal.svg";

const SearchProperty = ({ query, setQuery }) => {
  return (
    <div className="hidden md:flex items-center justify-between relative">
      <input
        type="text"
        placeholder="Search property "
        value={query}
        onChange={(e) => setQuery(e.target.value)}
        className="py-3 px-4 rounded-[8px] border border-[#C5C5C580]/50 lg:w-[25rem] h-[42px] outline-none "
      />
      <img src={search} alt="search icon" className="size-4 absolute right-4" />
    </div>
  );
};

export default SearchProperty;
