import { useState } from 'react';
import { useParams} from 'react-router-dom';
import { reviewsData } from '@/constants/data';
import arrowleft from "../../assets/shared/arrowleft.svg"
import profilepic from "../../assets/Reviews/profilepic.svg"
import smiley from "../../assets/Reviews/smiley.svg"
import attachment from "../../assets/Reviews/attachment.svg"
import send from "../../assets/Reviews/send.svg"

import tick from "../../assets/Reviews/tick.svg"
import star from "../../assets/shared/star.svg"

import { useNavigate } from "react-router-dom";

export default function ReviewDetail() {
  const navigate = useNavigate();
  const [showMore, setShowMore] = useState(false);
  const [messagePublic, setMessagePublic] = useState('');
  const [messagePrivate, setMessagePrivate] = useState('');

  const handleToggleShowMore = () => {
    setShowMore(!showMore);
  };

  const [showPublic, setShowPublic] = useState(false);
  const toggleDetailsPublic = () => {
    setShowPublic(!showPublic);
  };
  
  const [showPrivate, setShowPrivate] = useState(false);
  const toggleDetailsPrivate = () => {
    setShowPrivate(!showPrivate);
  };

  const handleSendPublic = () => {
    setMessagePublic('');
    setShowPublic(false);
  };
  const handleSendPrivate = () => {
    setMessagePrivate('');
    setShowPrivate(false);
  };

  const { id } = useParams();
  const review = reviewsData.find((review) => review.id === parseInt(id));
  if (!review) return <div>Review not found</div>;

  return (
    <div className="flex flex-col py-2 max-md:px-5 pl-28 mb-32">
    <div className="flex gap-4 py-4 text-2xl tracking-wide leading-7 text-center text-neutral-700 max-md:flex-wrap">
        <button>
        <img
          loading="lazy"
          src={arrowleft}
          className="shrink-0 my-auto w-6 aspect-square"
          onClick={() => navigate(-1)}
        />
        </button>
        <div className="max-md:max-w-full text-lg font-medium"> Reviews</div>
      </div>
    <div className="py-2 mt-8 max-md:mr-2 max-md:max-w-full">
      <div className="flex max-md:flex-col max-md:gap-0 ">
        <div className="flex flex-col w-full max-md:ml-0 max-md:w-full max-w-[500px]">
          <div className="grow border-r border-solid border-zinc-500 border-opacity-50 max-md:mt-10 max-md:max-w-full">
            <div className="flex max-md:flex-col max-md:gap-0">
              <div className="flex flex-col w-full max-w-[100px] max-md:ml-0 max-md:w-full">
                <div className="flex flex-col grow justify-center items-start max-md:mt-10">
                  <img
                    loading="lazy"
                    srcSet={profilepic}
                    className="rounded-full aspect-square w-[60px]"
                  />
                </div>
              </div>
              <div className="flex flex-col ml-5 w-full max-w-[390px] max-md:ml-0 max-md:w-full h-[80px]">
                <div className="flex flex-col justify-center self-stretch my-auto text-sm text-neutral-600 max-md:mt-10 max-md:max-w-full">
                  <div className="flex flex-col max-md:max-w-full">
                    <div className="max-md:max-w-full">
                      Eric’s review of your place
                    </div>
                    <div className="mt-1 max-md:max-w-full">
                      Mawira Continental Suite
                    </div>
                    <div className="mt-1 max-md:max-w-full">June 3, 2023</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Desktop */}
        <div className="hidden md:flex flex-col ml-5 w-full max-w-[170px] max-md:ml-0 max-md:w-full">
          <div className="grow max-md:mt-10 flex items-center">
            <div className="flex gap-5 max-md:flex-col max-md:gap-0">
              <div className="flex flex-col w-[30%] max-md:ml-0 max-md:w-full">
                <div className="flex flex-col justify-center items-center px-4 mx-auto bg-white rounded-xl border shadow-md border-neutral-600 h-[60px] w-[60px] max-md:mt-3">
                  <div className="flex items-center justify-center">
                    <div className="self-center text-sm font-normal text-neutral-600">
                      4.8
                    </div>
                  </div>
                </div>
              </div>
              <div className="flex flex-col md:max-lg:ml-10 w-[70%] max-md:w-full max">
                <div className="self-stretch my-auto text-sm font-normal text-sky-600 max-md:mt-10">
                  Overall rating
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Mobile */}
          <div className="flex md:hidden gap-3 items-center mt-5">
            <div className="flex flex-col justify-center items-center self-stretch px-3.5 text-xl font-semibold whitespace-nowrap bg-white rounded-xl border border-solid shadow-sm border-zinc-500 h-[60px] text-zinc-500 w-[60px]">
              <div className="justify-center">4.8</div>
            </div>
            <div className="self-stretch my-auto text-base font-semibold text-sky-600">
              Overall rating
            </div>
          </div>
      </div>
    </div>
    <div className="px-2 pb-4 mt-8 max-md:mr-2 max-md:max-w-full">
      <div className="flex gap-5 max-md:flex-col max-md:gap-0">
        <div className="flex flex-col w-[27%] max-md:ml-0 max-md:w-full">
        <div className="flex flex-col p-2 max-md:mt-8">
      <div className="text-base font-normal text-zinc-600">Detailed Feedback</div>

      {/* Cleanliness Section */}
      <div className="flex flex-col p-2 mt-6">
        <div className="flex flex-col justify-center">
          <div className="text-base font-normal text-zinc-600">Cleanliness</div>
          <div className="flex gap-1 mt-1">
            <div className="flex gap-px my-auto">
              {[...Array(5)].map((_, i) => (
                <img
                  key={i}
                  loading="lazy"
                  src={star}
                  className="shrink-0 w-4 aspect-square fill-yellow-500"
                />
              ))}
            </div>
            <div className="text-sm font-normal text-zinc-600">5.0</div>
          </div>
        </div>
        <div className="flex flex-col mt-2 text-zinc-600">
          <div className="flex gap-2">
            <img
              loading="lazy"
              src={tick}
              className="shrink-0 my-auto w-6 aspect-square"
            />
            <div className="flex-1 text-sm">Spotless furniture and linen</div>
          </div>
          <div className="flex gap-2 mt-2">
            <img
              loading="lazy"
              src={tick}
              className="shrink-0 my-auto w-6 aspect-square"
            />
            <div className="flex-1 text-sm">Free clutter</div>
          </div>
        </div>
      </div>

      {/* Location Section */}
      <div className="flex flex-col p-2 mt-6">
        <div className="flex flex-col justify-center">
          <div className="text-base font-normal text-zinc-600">Location</div>
          <div className="flex gap-1 mt-1">
            <div className="flex gap-px my-auto">
              {[...Array(5)].map((_, i) => (
                <img
                  key={i}
                  loading="lazy"
                  src={star}
                  className="shrink-0 w-4 aspect-square fill-yellow-500"
                />
              ))}
            </div>
            <div className="text-sm font-medium text-zinc-600">5.0</div>
          </div>
        </div>
        <div className="flex flex-col mt-2 text-zinc-600">
          <div className="flex gap-2 whitespace-nowrap">
            <img
              loading="lazy"
              src={tick}
              className="shrink-0 my-auto w-6 aspect-square"
            />
            <div className="text-sm font-normal">Peaceful</div>
          </div>
          <div className="flex gap-2 mt-2">
            <img
              loading="lazy"
              src={tick}
              className="shrink-0 my-auto w-6 aspect-square"
            />
            <div className="text-sm font-normal">Beautiful surrounding</div>
          </div>
        </div>
      </div>

      {/* Toggle button */}
      <div
        onClick={handleToggleShowMore}
        className="self-start mt-7 text-base font-medium leading-6 text-center text-sky-600 underline cursor-pointer"
      >
        {showMore ? 'See less' : 'See more'}
      </div>

      {/* Additional Items */}
      {showMore && (
        <div className="flex flex-col p-2 mt-6">
          <div className="flex flex-col justify-center">
            <div className="text-base font-normal text-zinc-600">Comfort</div>
            <div className="flex gap-1 mt-1">
              <div className="flex gap-px my-auto">
                {[...Array(5)].map((_, i) => (
                  <img
                    key={i}
                    loading="lazy"
                    src={star}
                    className="shrink-0 w-4 aspect-square fill-yellow-500"
                  />
                ))}
              </div>
              <div className="text-sm font-normal text-zinc-600">5.0</div>
            </div>
          </div>
          <div className="flex flex-col mt-2 text-sm font-normal text-zinc-600">
            <div className="flex gap-2">
              <img
                loading="lazy"
                src={tick}
                className="shrink-0 my-auto w-6 aspect-square"
              />
              <div className="flex-1">Comfortable beds</div>
            </div>
            <div className="flex gap-2 mt-2">
              <img
                loading="lazy"
                src={tick}
                className="shrink-0 my-auto w-6 aspect-square"
              />
              <div className="flex-1">Quiet at night</div>
            </div>
          </div>
        </div>
      )}
    </div>
        </div>
        <div className="flex flex-col ml-5 w-[73%] max-md:ml-0 max-md:w-full">
          <div className="flex flex-col grow self-stretch p-2 max-md:mt-8 max-md:max-w-full">
            <div className="flex flex-col max-md:max-w-full">
              <span className="text-base font-normal text-neutral-600 max-md:max-w-full">
                Public Feedback
              </span>
              <div className="flex flex-col mt-3 max-md:max-w-full">
                <span className="text-sm text-neutral-600 max-md:max-w-full">
                  I would like highly recommend this place to anyone looking
                  for great place to send a holiday. Juma was helpful and
                  responsive to any question we had
                </span>
                <div className="flex flex-col justify-center items-end px-16 mt-3 text-sm font-normal text-center text-neutral-600 text-opacity-50 max-md:pl-5 max-md:max-w-full">
                    {!showPrivate && (
                      <button
                        className="justify-center px-4 py-2 bg-white rounded-xl border border-solid border-neutral-600 border-opacity-50"
                        onClick={toggleDetailsPrivate}
                      >
                        Reply to Eric
                      </button>
                    )}
                    {showPrivate && (
                  <div className="mt-6 relative max-sm:w-72 max-sm:ml-56 w-full flex items-center justify-center">
                  <img
                    src={smiley}
                    alt="Profile"
                    className="absolute left-4 top-1/2 transform -translate-y-1/2 w-[28px] h-[28px] rounded-full"
                  />
                  <textarea
                    className="w-full  pl-14 pr-24 py-4 border rounded-full border-neutral-300 focus:outline-none text-black resize-none"
                    placeholder="Enter your message..."
                    value={messagePrivate}
                    onChange={(e) => setMessagePrivate(e.target.value)}
                    style={{ height: '56px' }}
                  />
                  <img
                    src={attachment}
                    alt="Image"
                    className="absolute right-16 top-1/2 transform -translate-y-1/2 w-[28px] h-[28px] border-2 border-neutral-600 rounded-full cursor-pointer"
                  />
                  <img
                    src={send}
                    alt="Send"
                    className="absolute right-4 top-1/2 transform -translate-y-1/2 w-10 h-10 rounded-full cursor-pointer"
                    onClick={handleSendPrivate}
                  />
                  </div> 
                     )}
                </div>
              </div>
            </div>
            <div className="flex flex-col mt-6 max-md:max-w-full">
              <span className="text-base font-normal tracking-wide text-neutral-600 max-md:max-w-full">
                Private note from Eric
              </span>
              <div className="flex flex-col mt-3 max-md:max-w-full">
                <span className="text-sm text-neutral-600 max-md:max-w-full">
                  Thank you Juma for hosting us in your place. We enjoyed
                  staying at your wonderful suite. It was very cozy during the
                  snow storm. Thank you for everything
                </span>
                  <div className="flex flex-col justify-center items-end px-16 mt-3 text-sm font-normal text-center text-neutral-600 text-opacity-50 max-md:pl-5 max-md:max-w-full">
                    {!showPublic && (
                      <button
                        className="justify-center px-4 py-2 bg-white rounded-xl border border-solid border-neutral-600 border-opacity-50"
                        onClick={toggleDetailsPublic}
                      >
                        Reply to Eric
                      </button>
                    )}
                    {showPublic && (
                  <div className="mt-6 relative max-sm:w-72 max-sm:ml-56 w-full flex items-center justify-center">
                  <img
                    src={smiley}
                    alt="Profile"
                    className="absolute left-4 top-1/2 transform -translate-y-1/2 w-[28px] h-[28px] rounded-full"
                  />
                  <textarea
                    className="w-full  pl-14 pr-24 py-4 border rounded-full border-neutral-300 focus:outline-none text-black resize-none"
                    placeholder="Enter your message..."
                    value={messagePrivate}
                    onChange={(e) => setMessagePublic(e.target.value)}
                    style={{ height: '56px' }}
                  />
                  <img
                    src={attachment}
                    alt="Image"
                    className="absolute right-16 top-1/2 transform -translate-y-1/2 w-[28px] h-[28px] border-2 border-neutral-600 rounded-full cursor-pointer"
                  />
                  <img
                    src={send}
                    alt="Send"
                    className="absolute right-4 top-1/2 transform -translate-y-1/2 w-10 h-10 rounded-full cursor-pointer"
                    onClick={handleSendPrivate}
                  />
                  </div> 
                     )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  )
}
