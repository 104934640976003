import ke from "../assets/settings/keflag.svg";
import us from "../assets/settings/usflag.svg";
import chn from "../assets/settings/chnflag.svg";
import hilton from "@/assets/searchresults/hilton.png";
import booking from "@/assets/bookings/bookingimg1.jpg";
import image2 from "@/assets/bookings/image 2.png";
import image3 from "@/assets/bookings/image 3.png";
import image4 from "@/assets/bookings/image 4.png";
import avatar from "@/assets/shared/avatar.png";
import destinationImage1 from "@/assets/Destinations/d-1.png"
import destinationImage2 from "@/assets/Destinations/d-2.png"
import destinationImage3 from "@/assets/Destinations/d-3.png"
import destinationImage4 from "@/assets/Destinations/d-4.png"

export const legal = [
  { name: "Terms of Service", link: "/termsofservice" },
  { name: "Privacy Policy", link: "/privacypolicy" },
  { name: "Booking Policy", link: "/bookingpolicy" },
  { name: "Refund Policy", link: "/refundpolicy" },
  { name: "Communities Guidelines", link: "/communityguidelines" },
];

export const hosting = [
  { name: "List your home", link: "/listyourhome" },
  { name: "Useful listing resources", link: "/listingresources" },
  { name: "Community Forum", link: "/communityforum" },
  { name: "Join Laara Specials", link: "/laaraspecials" },
  { name: "Join a Free Listing Class", link: "/freeListingclass" },
];

export const support = [
  { name: "Help Centre", link: "/helpcentre" },
  { name: "Report Concern", link: "/reportconcern" },
  { name: "Cancellation Policy", link: "/cancellationpolicy" },
  { name: "Disability Support", link: "/disabilitysupport" },
];

export const company = [
  { name: "About Us", link: "/aboutus" },
  { name: "Investors", link: "/investors" },
  { name: "Partner", link: "/partner" },
  { name: "Forum", link: "/forum" },
  { name: "Blog", link: "/blog" },
];
export const roomImage =
  "https://images.unsplash.com/photo-1584132967334-10e028bd69f7?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D";

const reservation =
  "https://images.unsplash.com/photo-1600596542815-ffad4c1539a9?q=80&w=1750&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D";

export const rooms = [
  {
    roomId: 0,
    title: "Deluxe Palace",
    destinationtitle: "Mediterranean Sea",
    price: "$300-$500",
    location: "Nyeri, Kenya",
    destinationlocation:"Turkey",
    rating: 4.5,
    image: roomImage, // Replace with your image path
    destinationImage: destinationImage1,
    facilities: [
      "Executive single room",
      "3 Guests max",
      "200 sq.ft",
      "Large double bed",
      "Pets allowed",
      "No smoking",
    ],
    generalfacilities: ["Air Conditioning", "Flat Screen TV", "Workspace Desk"],
    outdoorfacilities: ["Ocean View", "Nature View"],
    foodfacilities: [
      "Dining Area",
      "Dining Table",
      "Microwave",
      "Refrigerator",
    ],
    bedroomfacilities: ["Large Bed", "Wardrobe"],
    bathroomfacilities: ["Toilet Paper", "Shower", "Slippers"],
    safetyfacilities: [
      "Carbon Monoxide Detectors",
      "Smoke Detectors",
      "Fire Extinguishers",
      "First Aid Kits",
      "CCTV",
    ],
  },
  {
    roomId: 1,
    title: "Deluxe Palace",
    destinationtitle: "Beach",
    price: "$300-$500",
    location: "Nyeri, Kenya",
    destinationlocation:"Bali",
    rating: 4.5,
    image: reservation, // Replace with your image path
    destinationImage: destinationImage2,
    facilities: [
      "Executive single room",
      "3 Guests max",
      "200 sq.ft",
      "Large double bed",
      "Pets allowed",
      "No smoking",
    ],
    generalfacilities: ["Air Conditioning", "Flat Screen TV", "Workspace Desk"],
    outdoorfacilities: ["Ocean View", "Nature View"],
    foodfacilities: [
      "Dining Area",
      "Dining Table",
      "Microwave",
      "Refrigerator",
    ],
    bedroomfacilities: ["Large Bed", "Wardrobe"],
    bathroomfacilities: ["Toilet Paper", "Shower", "Slippers"],
    safetyfacilities: [
      "Carbon Monoxide Detectors",
      "Smoke Detectors",
      "Fire Extinguishers",
      "First Aid Kits",
      "CCTV",
    ],
  },
  {
    roomId: 2,
    title: "Deluxe Palace",
    destinationtitle: "Athen's architecture",
    price: "$300-$500",
    location: "Nyeri, Kenya",
    destinationlocation:"Greece",
    rating: 4.5,
    image: roomImage, // Replace with your image path
    destinationImage: destinationImage3,
    facilities: [
      "Executive single room",
      "3 Guests max",
      "200 sq.ft",
      "Large double bed",
      "Pets allowed",
      "No smoking",
    ],
    generalfacilities: ["Air Conditioning", "Flat Screen TV", "Workspace Desk"],
    outdoorfacilities: ["Ocean View", "Nature View"],
    foodfacilities: [
      "Dining Area",
      "Dining Table",
      "Microwave",
      "Refrigerator",
    ],
    bedroomfacilities: ["Large Bed", "Wardrobe"],
    bathroomfacilities: ["Toilet Paper", "Shower", "Slippers"],
    safetyfacilities: [
      "Carbon Monoxide Detectors",
      "Smoke Detectors",
      "Fire Extinguishers",
      "First Aid Kits",
      "CCTV",
    ],
  },
  {
    roomId: 3,
    title: "Deluxe Palace",
    destinationtitle: "Rome",
    price: "$300-$500",
    location: "Nyeri, Kenya",
    destinationlocation:"Italy",
    rating: 4.5,
    image: reservation, // Replace with your image path
    destinationImage: destinationImage4,
    facilities: [
      "Executive single room",
      "3 Guests max",
      "200 sq.ft",
      "Large double bed",
      "Pets allowed",
      "No smoking",
    ],
    generalfacilities: ["Air Conditioning", "Flat Screen TV", "Workspace Desk"],
    outdoorfacilities: ["Ocean View", "Nature View"],
    foodfacilities: [
      "Dining Area",
      "Dining Table",
      "Microwave",
      "Refrigerator",
    ],
    bedroomfacilities: ["Large Bed", "Wardrobe"],
    bathroomfacilities: ["Toilet Paper", "Shower", "Slippers"],
    safetyfacilities: [
      "Carbon Monoxide Detectors",
      "Smoke Detectors",
      "Fire Extinguishers",
      "First Aid Kits",
      "CCTV",
    ],
  },
  {
    roomId: 4,
    title: "Deluxe Palace",
    destinationtitle: "Mediterranean Sea",
    price: "$300-$500",
    location: "Nyeri, Kenya",
    destinationlocation:"Turkey",
    rating: 4.5,
    image: roomImage, // Replace with your image
    destinationImage: destinationImage1,
    facilities: [
      "Executive single room",
      "3 Guests max",
      "200 sq.ft",
      "Large double bed",
      "Pets allowed",
      "No smoking",
    ],
    generalfacilities: ["Air Conditioning", "Flat Screen TV", "Workspace Desk"],
    outdoorfacilities: ["Ocean View", "Nature View"],
    foodfacilities: [
      "Dining Area",
      "Dining Table",
      "Microwave",
      "Refrigerator",
    ],
    bedroomfacilities: ["Large Bed", "Wardrobe"],
    bathroomfacilities: ["Toilet Paper", "Shower", "Slippers"],
    safetyfacilities: [
      "Carbon Monoxide Detectors",
      "Smoke Detectors",
      "Fire Extinguishers",
      "First Aid Kits",
      "CCTV",
    ],
  },
  {
    roomId: 5,
    title: "Deluxe Palace",
    destinationtitle: "Beach",
    price: "$300-$500",
    location: "Nyeri, Kenya",
    destinationlocation:"Bali",
    rating: 4.5,
    image: reservation, // Replace with your image path
    destinationImage: destinationImage2,
    facilities: [
      "Executive single room",
      "3 Guests max",
      "200 sq.ft",
      "Large double bed",
      "Pets allowed",
      "No smoking",
    ],
    generalfacilities: ["Air Conditioning", "Flat Screen TV", "Workspace Desk"],
    outdoorfacilities: ["Ocean View", "Nature View"],
    foodfacilities: [
      "Dining Area",
      "Dining Table",
      "Microwave",
      "Refrigerator",
    ],
    bedroomfacilities: ["Large Bed", "Wardrobe"],
    bathroomfacilities: ["Toilet Paper", "Shower", "Slippers"],
    safetyfacilities: [
      "Carbon Monoxide Detectors",
      "Smoke Detectors",
      "Fire Extinguishers",
      "First Aid Kits",
      "CCTV",
    ],
  },
  {
    roomId: 6,
    title: "Deluxe Palace",
    destinationtitle: "Athen's architecture",
    price: "$300-$500",
    location: "Nyeri, Kenya",
    destinationlocation:"Greece",
    rating: 4.5,
    image: roomImage, // Replace with your image path
    destinationImage: destinationImage3,
    facilities: [
      "Executive single room",
      "3 Guests max",
      "200 sq.ft",
      "Large double bed",
      "Pets allowed",
      "No smoking",
    ],
    generalfacilities: ["Air Conditioning", "Flat Screen TV", "Workspace Desk"],
    outdoorfacilities: ["Ocean View", "Nature View"],
    foodfacilities: [
      "Dining Area",
      "Dining Table",
      "Microwave",
      "Refrigerator",
    ],
    bedroomfacilities: ["Large Bed", "Wardrobe"],
    bathroomfacilities: ["Toilet Paper", "Shower", "Slippers"],
    safetyfacilities: [
      "Carbon Monoxide Detectors",
      "Smoke Detectors",
      "Fire Extinguishers",
      "First Aid Kits",
      "CCTV",
    ],
  },
  {
    roomId: 7,
    title: "Deluxe Palace",
    destinationtitle: "Rome",
    price: "$300-$500",
    location: "Nyeri, Kenya",
    destinationlocation:"Italy",
    rating: 4.5,
    image: reservation, // Replace with your image path
    destinationImage: destinationImage4,
    facilities: [
      "Executive single room",
      "3 Guests max",
      "200 sq.ft",
      "Large double bed",
      "Pets allowed",
      "No smoking",
    ],
    generalfacilities: ["Air Conditioning", "Flat Screen TV", "Workspace Desk"],
    outdoorfacilities: ["Ocean View", "Nature View"],
    foodfacilities: [
      "Dining Area",
      "Dining Table",
      "Microwave",
      "Refrigerator",
    ],
    bedroomfacilities: ["Large Bed", "Wardrobe"],
    bathroomfacilities: ["Toilet Paper", "Shower", "Slippers"],
    safetyfacilities: [
      "Carbon Monoxide Detectors",
      "Smoke Detectors",
      "Fire Extinguishers",
      "First Aid Kits",
      "CCTV",
    ],
  },
];

export const data = [
  {
    expense: "Deluxe Villa - 2 Nights - 2 G ...",
    business: "Stays",
    status: "Pending",
    date: "24 June, 2024",
    amount: "$250.00",
  },
  {
    expense: "Deluxe Villa - 2 Nights - 2 G ...",
    business: "Munch",
    status: "Pending",
    date: "24 June, 2024",
    amount: "$250.00",
  },
  {
    expense: "Deluxe Villa - 2 Nights - 2 G ...",
    business: "Munch",
    status: "Pending",
    date: "24 June, 2024",
    amount: "$250.00",
  },
  {
    expense: "Deluxe Villa - 2 Nights - 2 G ...",
    business: "Cabs",
    status: "Pending",
    date: "24 June, 2024",
    amount: "$250.00",
  },
  {
    expense: "Deluxe Villa - 2 Nights - 2 G ...",
    business: "Stays",
    status: "Pending",
    date: "24 June, 2024",
    amount: "$250.00",
  },
  {
    expense: "Deluxe Villa - 2 Nights - 2 G ...",
    business: "Cabs",
    status: "Pending",
    date: "24 June, 2024",
    amount: "$250.00",
  },
  {
    expense: "Deluxe Villa - 2 Nights - 2 G ...",
    business: "Stays",
    status: "Pending",
    date: "24 June, 2024",
    amount: "$250.00",
  },
  {
    expense: "Deluxe Villa - 2 Nights - 2 G ...",
    business: "Stays",
    status: "Pending",
    date: "24 June, 2024",
    amount: "$250.00",
  },
  {
    expense: "Deluxe Villa - 2 Nights - 2 G ...",
    business: "Stays",
    status: "Pending",
    date: "24 June, 2024",
    amount: "$250.00",
  },
  {
    expense: "Deluxe Villa - 2 Nights - 2 G ...",
    business: "Stays",
    status: "Pending",
    date: "24 June, 2024",
    amount: "$250.00",
  },
  {
    expense: "Deluxe Villa - 2 Nights - 2 G ...",
    business: "Stays",
    status: "Pending",
    date: "24 June, 2024",
    amount: "$250.00",
  },
];

export const updates = [
  {
    propertyId: 1,
    updates: [
      {
        updateId: 1,
        userName: "John Doe",
        timePosted: "2 h",
        text: "Enjoying the beautiful ocean view from our villa!",
        likes: 15,
        comments: [
          {
            userName: "Jane Smith",
            timePosted: "1 hour ago",
            text: "Looks amazing! Wish I could be there too.",
            profilePic: "https://via.placeholder.com/150",
            likes: 10,
          },
          {
            userName: "Chris Johnson",
            timePosted: "30 minutes ago",
            text: "Absolutely stunning! Can't wait to visit.",
            profilePic: "https://via.placeholder.com/150",
            likes: 10,
          },
        ],
      },
      {
        updateId: 2,
        userName: "Alice Johnson",
        timePosted: "1 d",
        text: "Had a fantastic time exploring Malibu today!",
        likes: 10,
        comments: [
          {
            userName: "David Lee",
            timePosted: "23 hours ago",
            text: "Malibu is gorgeous! Hope you had a great time.",
            profilePic: "https://via.placeholder.com/150",
            likes: 10,
          },
        ],
      },
      {
        updateId: 3,
        userName: "Sophia Miller",
        timePosted: "3 d",
        text: "Celebrating our anniversary at this amazing resort!",
        likes: 25,
        comments: [],
      },
    ],
  },
  {
    propertyId: 2,
    updates: [
      {
        updateId: 4,
        userName: "Emily Brown",
        timePosted: "5 h",
        text: "Just moved into my new luxury loft!",
        likes: 20,
        comments: [
          {
            userName: "Jack Wilson",
            timePosted: "4 h",
            text: "Congratulations! It looks stunning.",
            profilePic: "https://via.placeholder.com/150",
            likes: 10,
          },
          {
            userName: "Emma Taylor",
            timePosted: "3 h",
            text: "Wow! The interior design is incredible.",
            profilePic: "https://via.placeholder.com/150",
            likes: 10,
          },
        ],
      },
      {
        updateId: 5,
        userName: "Oliver Harris",
        timePosted: "2 d",
        text: "Enjoying the city lights from my new apartment!",
        likes: 18,
        comments: [],
      },
    ],
  },
  {
    propertyId: 3,
    updates: [
      {
        updateId: 6,
        userName: "Michael Davis",
        timePosted: "5 h",
        text: "Enjoying the peaceful surroundings of our cottage.",
        likes: 12,
        comments: [
          {
            userName: "Ava Martinez",
            timePosted: "4 h",
            text: "Looks so cozy and relaxing!",
            profilePic: "https://via.placeholder.com/150",
            likes: 10,
          },
        ],
      },
      {
        updateId: 7,
        userName: "Sophie Clark",
        timePosted: "1 d",
        text: "Exploring the nearby hiking trails!",
        likes: 14,
        comments: [],
      },
      {
        updateId: 8,
        userName: "Noah White",
        timePosted: "2 d",
        text: "Spent the day fishing by the lake.",
        likes: 8,
        comments: [],
      },
    ],
  },
  {
    propertyId: 4,
    updates: [
      {
        updateId: 1,
        userName: "John Doe",
        timePosted: "2 h",
        text: "Enjoying the beautiful ocean view from our villa in Pretoria, wish all of you were here.",
        likes: 15,
        image: "https://placehold.co/900x400",
        comments: [
          {
            userName: "Jane Smith",
            timePosted: "1 h",
            text: "Looks amazing! Wish I could be there too.",
            profilePic: "https://placehold.co/600x400",
          },
          {
            userName: "Chris Johnson",
            timePosted: "30 m",
            text: "Absolutely stunning! Can't wait to visit.",
            profilePic: "https://placehold.co/600x400",
          },
        ],
      },
      {
        updateId: 2,
        userName: "Alice Johnson",
        timePosted: "1 d",
        text: "Had a fantastic time exploring Malibu today!",
        likes: 10,
        comments: [
          {
            userName: "David Lee",
            timePosted: "23 h",
            text: "Malibu is gorgeous! Hope you had a great time.",
            profilePic: "https://placehold.co/600x400",
          },
        ],
      },
      {
        updateId: 3,
        userName: "Sophia Miller",
        timePosted: "3 d",
        text: "Celebrating our anniversary at this amazing resort!",
        likes: 25,
        image: "https://placehold.co/600x400",
        comments: [],
      },
      {
        updateId: 4,
        userName: "Mark Thompson",
        timePosted: "5 d",
        text: "Spotted dolphins swimming by our beach house!",
        likes: 30,
        image: "https://placehold.co/600x400",
        comments: [],
      },
    ],
  },
  {
    propertyId: 5,
    updates: [
      {
        updateId: 5,
        userName: "Emily Brown",
        timePosted: "5 h",
        text: "Just moved into my new luxury loft!",
        likes: 20,
        image: "https://placehold.co/600x400",
        comments: [
          {
            userName: "Jack Wilson",
            timePosted: "4 h",
            text: "Congratulations! It looks stunning.",
            profilePic: "https://placehold.co/600x400",
          },
          {
            userName: "Emma Taylor",
            timePosted: "3 h",
            text: "Wow! The interior design is incredible.",
            profilePic: "https://placehold.co/600x400",
          },
        ],
      },
      {
        updateId: 6,
        userName: "Oliver Harris",
        timePosted: "2 d",
        text: "Enjoying the city lights from my new apartment!",
        likes: 18,
        comments: [],
      },
      {
        updateId: 7,
        userName: "Isabella Martinez",
        timePosted: "4 d",
        text: "Visited the rooftop garden with an amazing view!",
        likes: 22,
        image: "https://placehold.co/600x400",
        comments: [],
      },
    ],
  },
  {
    propertyId: 6,
    updates: [
      {
        updateId: 8,
        userName: "Michael Davis",
        timePosted: "5 h",
        text: "Enjoying the peaceful surroundings of our cottage.",
        likes: 12,
        image: "https://placehold.co/600x400",
        comments: [
          {
            userName: "Ava Martinez",
            timePosted: "4 h",
            text: "Looks so cozy and relaxing!",
            profilePic: "https://placehold.co/600x400",
          },
        ],
      },
      {
        updateId: 9,
        userName: "Sophie Clark",
        timePosted: "1 d",
        text: "Exploring the nearby hiking trails!",
        likes: 14,
        comments: [],
      },
      {
        updateId: 10,
        userName: "Noah White",
        timePosted: "2 d",
        text: "Spent the day fishing by the lake.",
        likes: 8,
        image: "https://placehold.co/600x400",
        comments: [],
      },
      {
        updateId: 11,
        userName: "Ella Green",
        timePosted: "6 d",
        text: "Our stay at the hotel was nothing short of exceptional, from the warm and welcoming atmosphere in the lobby to the impeccably clean and comfortable rooms with breathtaking views, every detail was meticulously attended to, ensuring a truly memorable experience that exceeded our expectations in every way.",
        likes: 35,
        image: "https://placehold.co/600x400",
        comments: [],
      },
    ],
  },
];

export const currencies = [
  { name: "Dollar", flag: us },
  { name: "KES", flag: ke },
  { name: "Yen", flag: chn },
];
export const languages = [
  { name: "ENG", flag: us },
  { name: "KEN", flag: ke },
  { name: "CHN", flag: chn },
];

export const allwishes = [
  {
    image: reservation,
    location: "Nyeri, Kenya",
    star: "",
    rating: "4.5",
    price: "$300",
    like: false,
  },
  {
    image: roomImage,
    location: "Nyeri, Kenya",
    rating: "4.5",
    price: "$300",
    like: true,
  },
  {
    image: reservation,
    location: "Nyeri, Kenya",
    rating: "4.5",
    price: "$300",
    like: false,
  },
  {
    image: roomImage,
    location: "Nyeri, Kenya",
    rating: "4.5",
    price: "$300",
    like: true,
  },
  {
    image: reservation,
    location: "Nyeri, Kenya",
    rating: "4.5",
    price: "$300",
    like: true,
  },
  {
    image: roomImage,
    location: "Nyeri, Kenya",
    rating: "4.5",
    price: "$300",
    like: true,
  },
  {
    image: reservation,
    location: "Nyeri, Kenya",
    rating: "4.5",
    price: "$300",
    like: false,
  },
];
export const durations = [
  {
    id: 1,
    duration: "2-3 nights",
  },
  {
    id: 2,
    duration: "4-5 nights",
  },
  {
    id: 3,
    duration: "1 week",
  },
  {
    id: 4,
    duration: "2 weeks",
  },
  {
    id: 5,
    duration: "3 weeks",
  },
];

export const months = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];
export const profileData = {
  name: "Alex Otieno",
  hostId: "1",
  location: "Nyeri, Kenya",
  stats: [
    { label: "Reviews", value: 6 },
    { label: "Properties Followed", value: 25 },
    { label: "Reservations", value: 10 },
  ],
  stats2: [
    { label: "Tier", value: " Super" },
    { label: "Listed Properties", value: 8 },
    { label: "Bookings", value: 100 },
  ],
  email: "alexotieno90@gmail.com",
  phone: "-",
  bio: "An enthusiastic gymnast who loves to travel. ".repeat(5),
  reviews: Array.from({ length: 4 }, (_, i) => ({
    id: i + 1,
    author: "Eric Mutugi",
    rating: "5.0",
    time: "12:00",
    date: "June 3, 2023",
    content:
      "He is an excellent guest who I would love to host again. He is an excellent guest who I would love to host again He is an excellent guest who I would love to host again",
  })),
  review: Array.from({ length: 6 }, (_, i) => ({
    id: i + 1,
    author: "Eric Mutugi",
    rating: "5.0",
    time: "12:00",
    date: "June 3, 2023",
    content:
      "He is an excellent guest who I would love to host again. He is an excellent guest who I would love to host again He is an excellent guest who I would love to host again",
  })),
  properties: Array.from({ length: 4 }, (_, i) => ({
    id: i + 1,
    name: "Deluxe Palace",
    rating: "4.5",
    location: "Nyeri, Kenya",
  })),

  formFields: [
    {
      id: "name",
      label: "Name",
      type: "text",
      value: "Alex Otieno",
      required: true,
    },
    {
      id: "email",
      label: "Email",
      type: "email",
      value: "alexotieno90@gmail.com",
      required: true,
    },
    {
      id: "phone",
      label: "Phone Number",
      type: "tel",
      value: "+234 00000000",
      required: true,
    },
    {
      id: "country",
      label: "Country of Residence",
      type: "text",
      value: "Kenya",
      required: true,
    },
    {
      id: "city",
      label: "City & State of Residence",
      type: "text",
      value: "Kilimani,Nairobi",
      required: true,
    },
  ],
};

export const settings = [
  {
    key: "airplaneMode",
    label:
      "I want to get reminders to leave a review or provide feedback after a stay",
  },
  {
    key: "wifi",
    label:
      "I want to receive reminders for upcoming check-in and check-out times",
  },
  {
    key: "bluetooth",
    label:
      "I want to get notified about any changes or cancellations to my reservations.",
  },
  {
    key: "mobileData",
    label:
      "I want to receive notifications about special offers, discounts, and promotions",
  },
  {
    key: "hotspot",
    label: "I want to get reminders for upcoming or due payments",
  },
];

export const properties = [
  {
    hostId: "1",
    propertyId: 1,
    propertyImage: hilton,
    images: [hilton, hilton, hilton],
    propertyName: "Hilton Hotel",
    location: "Capetown, CBD",
    pricing: "KSH 3300",
    language: "English",
    checkIn: "8.00 pm",
    checkOut: "10.00 am",
    propertyType: "deluxe room",
    coordinates: {
      latitude: -33.9323,
      longitude: 18.45704,
    },
    rating: 5,
    reviews: "1K",
    comfort: 10,
    duration: 5,
    guests: 2,
    price: 100000,
    followerCount: 223,
    published: true,
    beds: ["King", "Queen", "Twin"],
    parking: true,
    wifi: true,
    status: "published",
    category: "hotels",
    description:
      "This is  a private villa with free car parking, It’s Strategically located in Kiambu, Kenya, this property puts guest close to interesting attractions and restaurant options. It is close to the  Sacred Monkey Forest Sanctuary. The 4-star property has an outdoor swimming pool to make your stay more enjoyable and memorable.",
    communication:
      "Accessible communication features for guests with hearing impairments",
    bedroom: "Wide doorway to guest bedroom",
    commonareas: ["Accessible-height bed", "Lift access"],
    bathroom: ["Accessible-height toilet", "Wide doorway to bathroom"],
    amenities: [
      { name: "Breakfast", available: true },
      { name: "WiFi", available: true },
      { name: "Parking", available: false },
      { name: "Pets", available: true },
      { name: "Smoking", available: false },
      { name: "Laundry", available: true },
      { name: "Exercise", available: true },
    ],
  },
  {
    hostId: "1",
    propertyId: 2,
    propertyImage: hilton,
    images: [hilton, hilton, hilton],
    propertyName: "Marriott Hotel",
    location: "Johannesburg, Sandton",
    pricing: "KSH 3000",
    language: "Swahili",
    checkIn: "8.00 pm",
    checkOut: "10.00 am",
    propertyType: "executive suite",
    coordinates: {
      latitude: -26.1076,
      longitude: 28.0567,
    },
    rating: 4.8,
    reviews: "800",
    comfort: 9.5,
    duration: 4,
    guests: 3,
    price: 120000,
    followerCount: 222323,
    published: true,
    beds: ["King", "Queen", "Twin"],
    parking: true,
    wifi: false,
    status: "published",
    category: "hotels",
    description:
      "This is  a private villa with free car parking, It’s Strategically located in Kiambu, Kenya, this property puts guest close to interesting attractions and restaurant options. It is close to the  Sacred Monkey Forest Sanctuary. The 4-star property has an outdoor swimming pool to make your stay more enjoyable and memorable.",
    communication:
      "Accessible communication features for guests with hearing impairments",
    bedroom: "Wide doorway to guest bedroom",
    commonareas: ["Accessible-height bed", "Lift access"],
    bathroom: ["Accessible-height toilet", "Wide doorway to bathroom"],
    amenities: [
      { name: "Breakfast", available: true },
      { name: "WiFi", available: true },
      { name: "Parking", available: true },
      { name: "Pets", available: true },
      { name: "Smoking", available: false },
      { name: "Laundry", available: true },
      { name: "Exercise", available: true },
    ],
  },
  {
    hostId: "1",
    propertyId: 3,
    propertyImage: hilton,
    images: [hilton, hilton, hilton],
    propertyName: "Radisson Blu",
    location: "Durban, Beachfront",
    pricing: "KSH 3000",
    language: "Swahili",
    checkIn: "8.00 pm",
    checkOut: "10.00 am",
    propertyType: "premium room",
    coordinates: {
      latitude: -29.8579,
      longitude: 31.0292,
    },
    rating: 4.5,
    reviews: "500",
    comfort: 9,
    duration: 3,
    guests: 2,
    price: 85000,
    followerCount: 23233,
    published: true,
    beds: ["King", "Queen", "Twin"],
    parking: true,
    wifi: true,
    status: "pending",
    category: "hotels",
    description:
      "This is  a private villa with free car parking, It’s Strategically located in Kiambu, Kenya, this property puts guest close to interesting attractions and restaurant options. It is close to the  Sacred Monkey Forest Sanctuary. The 4-star property has an outdoor swimming pool to make your stay more enjoyable and memorable.",
    communication:
      "Accessible communication features for guests with hearing impairments",
    bedroom: "Wide doorway to guest bedroom",
    commonareas: ["Accessible-height bed", "Lift access"],
    bathroom: ["Accessible-height toilet", "Wide doorway to bathroom"],
    amenities: [
      { name: "Breakfast", available: true },
      { name: "WiFi", available: true },
      { name: "Parking", available: true },
      { name: "Pets", available: true },
      { name: "Smoking", available: false },
      { name: "Laundry", available: true },
      { name: "Exercise", available: true },
    ],
  },
  {
    hostId: "1",
    propertyId: 4,
    propertyImage: hilton,
    images: [hilton, hilton, hilton],
    propertyName: "Sheraton Hotel",
    location: "Pretoria, City Center",
    pricing: "KSH 3000",
    language: "Swahili",
    checkIn: "8.00 pm",
    checkOut: "10.00 am",
    propertyType: "deluxe suite",
    coordinates: {
      latitude: -25.7461,
      longitude: 28.1881,
    },
    rating: 4.7,
    reviews: "650",
    comfort: 9.8,
    duration: 5,
    guests: 2,
    price: 110000,
    followerCount: 50000,
    published: true,
    beds: ["King", "Queen", "Twin"],
    parking: true,
    wifi: true,
    status: "draft",
    category: "hotels",
    description:
      "This is  a private villa with free car parking, It’s Strategically located in Kiambu, Kenya, this property puts guest close to interesting attractions and restaurant options. It is close to the  Sacred Monkey Forest Sanctuary. The 4-star property has an outdoor swimming pool to make your stay more enjoyable and memorable.",
    communication:
      "Accessible communication features for guests with hearing impairments",
    bedroom: "Wide doorway to guest bedroom",
    commonareas: ["Accessible-height bed", "Lift access"],
    bathroom: ["Accessible-height toilet", "Wide doorway to bathroom"],
    amenities: [
      { name: "Breakfast", available: true },
      { name: "WiFi", available: true },
      { name: "Parking", available: true },
      { name: "Pets", available: true },
      { name: "Smoking", available: false },
      { name: "Laundry", available: true },
      { name: "Exercise", available: true },
    ],
  },
  {
    hostId: "1",
    propertyId: 5,
    propertyImage: hilton,
    images: [hilton, hilton, hilton],
    propertyName: "Intercontinental Hotel",
    location: "Cape Town, Waterfront",
    pricing: "KSH 3000",
    language: "Swahili",
    checkIn: "8.00 pm",
    checkOut: "10.00 am",
    propertyType: "luxury room",
    coordinates: {
      latitude: -33.9076,
      longitude: 18.4194,
    },
    rating: 4.9,
    reviews: "1.2K",
    comfort: 10,
    duration: 4,
    guests: 2,
    price: 130000,
    followerCount: 4053,
    published: true,
    beds: ["King", "Twin"],
    parking: true,
    wifi: true,
    status: "pending",
    category: "wedding Venues",
    description:
      "This is  a private villa with free car parking, It’s Strategically located in Kiambu, Kenya, this property puts guest close to interesting attractions and restaurant options. It is close to the  Sacred Monkey Forest Sanctuary. The 4-star property has an outdoor swimming pool to make your stay more enjoyable and memorable.",
    communication:
      "Accessible communication features for guests with hearing impairments",
    bedroom: "Wide doorway to guest bedroom",
    commonareas: ["Accessible-height bed", "Lift access"],
    bathroom: ["Accessible-height toilet", "Wide doorway to bathroom"],
    amenities: [
      { name: "Breakfast", available: true },
      { name: "WiFi", available: true },
      { name: "Parking", available: true },
      { name: "Pets", available: true },
      { name: "Smoking", available: false },
      { name: "Laundry", available: true },
      { name: "Exercise", available: true },
    ],
  },
  {
    hostId: "1",
    propertyId: 6,
    propertyImage: hilton,
    images: [hilton, hilton, hilton],
    propertyName: "Hyatt Regency",
    location: "Port Elizabeth, City Bowl",
    pricing: "KSH 3000",
    language: "Swahili",
    checkIn: "8.00 pm",
    checkOut: "10.00 am",
    propertyType: "standard room",
    coordinates: {
      latitude: -33.9581,
      longitude: 25.6005,
    },
    rating: 4.3,
    reviews: "700",
    comfort: 8.5,
    duration: 3,
    guests: 2,
    price: 90000,
    followerCount: 400,
    published: false,
    beds: ["King"],
    parking: false,
    wifi: true,
    status: "published",
    category: "vacation Rentals",
    description:
      "This is  a private villa with free car parking, It’s Strategically located in Kiambu, Kenya, this property puts guest close to interesting attractions and restaurant options. It is close to the  Sacred Monkey Forest Sanctuary. The 4-star property has an outdoor swimming pool to make your stay more enjoyable and memorable.",
    communication:
      "Accessible communication features for guests with hearing impairments",
    bedroom: "Wide doorway to guest bedroom",
    commonareas: ["Accessible-height bed", "Lift access"],
    bathroom: ["Accessible-height toilet", "Wide doorway to bathroom"],
    amenities: [
      { name: "Breakfast", available: true },
      { name: "WiFi", available: true },
      { name: "Parking", available: true },
      { name: "Pets", available: true },
      { name: "Smoking", available: false },
      { name: "Laundry", available: true },
      { name: "Exercise", available: true },
    ],
  },
  {
    hostId: "2",
    propertyId: 7,
    propertyImage: hilton,
    images: [hilton, hilton, hilton],
    propertyName: "Four Seasons",
    location: "Stellenbosch, Winelands",
    pricing: "KSH 3000",
    language: "Swahili",
    checkIn: "8.00 pm",
    checkOut: "10.00 am",
    propertyType: "junior suite",
    coordinates: {
      latitude: -33.9323,
      longitude: 18.8602,
    },
    rating: 4.6,
    reviews: "400",
    comfort: 9.2,
    duration: 6,
    guests: 2,
    price: 140000,
    followerCount: 23,
    published: true,
    beds: ["King", "Queen", "Twin"],
    parking: true,
    wifi: true,
    status: "published",
    category: "hotels",
    description:
      "This is  a private villa with free car parking, It’s Strategically located in Kiambu, Kenya, this property puts guest close to interesting attractions and restaurant options. It is close to the  Sacred Monkey Forest Sanctuary. The 4-star property has an outdoor swimming pool to make your stay more enjoyable and memorable.",
    communication:
      "Accessible communication features for guests with hearing impairments",
    bedroom: "Wide doorway to guest bedroom",
    commonareas: ["Accessible-height bed", "Lift access"],
    bathroom: ["Accessible-height toilet", "Wide doorway to bathroom"],
    amenities: [
      { name: "Breakfast", available: true },
      { name: "WiFi", available: false },
      { name: "Parking", available: true },
      { name: "Pets", available: true },
      { name: "Smoking", available: false },
      { name: "Laundry", available: true },
      { name: "Exercise", available: true },
    ],
  },
];

export const propertyImages = {
  1: {
    facilities: [
      "https://placehold.co/600x400",
      "https://placehold.co/600x400",
      "https://placehold.co/600x400",
      "https://placehold.co/600x400",
      "https://placehold.co/600x400",
      "https://placehold.co/600x400",
    ],
    staff: [
      hilton,
      "https://placehold.co/600x400",
      "https://placehold.co/600x400",
      "https://placehold.co/600x400",
      hilton,
      "https://placehold.co/600x400",
      "https://placehold.co/600x400",
    ],
    food: [
      "https://placehold.co/600x400",
      "https://placehold.co/600x400",
      "https://placehold.co/600x400",
    ],
  },
  2: {
    facilities: [
      "https://placehold.co/600x400",
      "https://placehold.co/600x400",
      "https://placehold.co/600x400",
    ],
    staff: [
      "https://placehold.co/600x400",
      "https://placehold.co/600x400",
      "https://placehold.co/600x400",
    ],
    food: [
      "https://placehold.co/600x400",
      "https://placehold.co/600x400",
      "https://placehold.co/600x400",
    ],
  },
  3: {
    facilities: [
      "https://placehold.co/600x400",
      "https://placehold.co/600x400",
      "https://placehold.co/600x400",
      "https://placehold.co/600x400",
      "https://placehold.co/600x400",
      "https://placehold.co/600x400",
    ],
    staff: [
      "https://placehold.co/600x400",
      "https://placehold.co/600x400",
      "https://placehold.co/600x400",
    ],
    food: [
      "https://placehold.co/600x400",
      "https://placehold.co/600x400",
      "https://placehold.co/600x400",
    ],
  },
  4: {
    facilities: [
      "https://placehold.co/600x400",
      "https://placehold.co/600x400",
      "https://placehold.co/600x400",
      "https://placehold.co/600x400",
      "https://placehold.co/600x400",
      "https://placehold.co/600x400",
    ],
    staff: [
      "https://placehold.co/600x400",
      "https://placehold.co/600x400",
      "https://placehold.co/600x400",
    ],
    food: [
      "https://placehold.co/600x400",
      "https://placehold.co/600x400",
      "https://placehold.co/600x400",
    ],
  },
  5: {
    facilities: [
      "https://placehold.co/600x400",
      "https://placehold.co/600x400",
      "https://placehold.co/600x400",
      "https://placehold.co/600x400",
      "https://placehold.co/600x400",
      "https://placehold.co/600x400",
    ],
    staff: [
      "https://placehold.co/600x400",
      "https://placehold.co/600x400",
      "https://placehold.co/600x400",
    ],
    food: [
      "https://placehold.co/600x400",
      "https://placehold.co/600x400",
      "https://placehold.co/600x400",
    ],
  },
  6: {
    facilities: [
      "https://placehold.co/600x400",
      "https://placehold.co/600x400",
      "https://placehold.co/600x400",
      "https://placehold.co/600x400",
      "https://placehold.co/600x400",
      "https://placehold.co/600x400",
    ],
    staff: [
      "https://placehold.co/600x400",
      "https://placehold.co/600x400",
      "https://placehold.co/600x400",
    ],
    food: [
      "https://placehold.co/600x400",
      "https://placehold.co/600x400",
      "https://placehold.co/600x400",
    ],
  },
  7: {
    facilities: [
      "https://placehold.co/600x400",
      "https://placehold.co/600x400",
      "https://placehold.co/600x400",
      "https://placehold.co/600x400",
      "https://placehold.co/600x400",
      "https://placehold.co/600x400",
    ],
    staff: [
      "https://placehold.co/600x400",
      "https://placehold.co/600x400",
      "https://placehold.co/600x400",
    ],
    food: [
      "https://placehold.co/600x400",
      "https://placehold.co/600x400",
      "https://placehold.co/600x400",
    ],
  },
};

export const PropertyWish = [
  {
    image: reservation,
    heading: "Properties Near You",
    text: "see a list of followed properties",
    subtext: "100+ properties",
    link: "view more",
  },
  {
    image: roomImage,
    heading: "Properties You Follow",
    text: "see a list of followed properties",
    subtext: "100+ properties",
    link: "view more",
  },
  {
    image: roomImage,
    heading: "Properties Near You",
    text: "see a list of followed properties",
    price: "100+ properties",
    link: "view more",
  },
];

export const reviewsData = [
  {
    id: 1,
    avatarSrc:
      "https://cdn.builder.io/api/v1/image/assets/TEMP/6d1e2ebaebf751ce30b965e4c8e0ee76cb48984b22954624b9992ae9f11e2ff3?apiKey=39797f691da94f489f399c076bea1d31&width=200",
    name: "Eric David",
    rating: 5,
    date: "June 3, 2023",
    text: "He is an excellent guest who I would love to host again. He is an excellent guest who I would love to host again. He is an excellent guest who I would love to host again.",
  },
  {
    id: 2,
    avatarSrc:
      "https://cdn.builder.io/api/v1/image/assets/TEMP/6d1e2ebaebf751ce30b965e4c8e0ee76cb48984b22954624b9992ae9f11e2ff3?apiKey=39797f691da94f489f399c076bea1d31&width=200",
    name: "Eric Jones",
    rating: 5,
    date: "June 3, 2023",
    text: "He is an excellent guest who I would love to host again. He is an excellent guest who I would love to host again. He is an excellent guest who I would love to host again.",
  },
  {
    id: 3,
    avatarSrc:
      "https://cdn.builder.io/api/v1/image/assets/TEMP/6d1e2ebaebf751ce30b965e4c8e0ee76cb48984b22954624b9992ae9f11e2ff3?apiKey=39797f691da94f489f399c076bea1d31&width=200",
    name: "Eric Mutugi",
    rating: 5,
    date: "June 3, 2023",
    text: "He is an excellent guest who I would love to host again. He is an excellent guest who I would love to host again. He is an excellent guest who I would love to host again.",
  },
  // Add more reviews as needed
];

export const supportcategories = [
  {
    title: "Getting Started with Iaara Stay",
    description: "Set up your account on Iaara.",
    link: "/gettingstarted",
  },
  {
    title: "Searching for Properties",
    description: "Search for the hottest properties today.",
    link: "/searchproperties",
  },
  {
    title: "Payments and Pricing",
    description: "Pay swiftly and safely on Iaara.",
    link: "/paymentspricing",
  },
  {
    title: "Booking a property",
    description: "Get your booking on the way.",
    link: "/bookingproperty",
  },
  {
    title: "User Safety and Security",
    description: "Secure and protect your Iaara account.",
    link: "/safetysecurity",
  },
  {
    title: "Account Management",
    description: "Manage your account details.",
    link: "/accountmanagement",
  },
  {
    title: "Additional Resources",
    description: "All other services provided here.",
    link: "/additionalresources",
  },
  {
    title: "Customer Support",
    description: "Speak with our customer service.",
    link: "/customersupport",
  },
];

import userIcon from "../assets/support/user-account.svg";
import vendorIcon from "../assets/support/bicycle.svg";
import driverIcon from "../assets/support/car-01.svg";
import hotelIcon from "@/assets/support/hotel-01.svg";

export const accountTypes = [
  { type: "User Account", icon: userIcon },
  { type: "Vendors Account", icon: vendorIcon },
  { type: "Drivers Account", icon: driverIcon },
  { type: "Reception Account", icon: hotelIcon },
];

export const sections = [
  {
    title: "How to Search for a Property",
    items: [
      {
        text: "Using the search bar and filters",
        link: "/search/how-to-use-search-bar-and-filters",
      },
      {
        text: "Viewing property details",
        link: "/search/viewing-property-details",
      },
      {
        text: "Saving favorite properties",
        link: "/search/saving-favorite-properties",
      },
    ],
  },

  {
    title: "Understanding Search Results",
    items: [
      {
        text: "Sorting and filtering results",
        link: "/search-results/sorting-and-filtering",
      },
      {
        text: "Property ratings and reviews",
        link: "/search-results/ratings-and-reviews",
      },
      {
        text: "Property types and categories",
        link: "/search-results/types-and-categories",
      },
    ],
  },
];

export const messagecategories = [
  "Category 1",
  "Category 2",
  "Category 3",
  "Category 4",
];

export const bookings = [
  {
    id: 173826,
    status: "completed",
    name: "Golden Star Hotel",
    location: "Riverside Mall",
    checkIn: "Sun, 07 Jan",
    guests: 2,
    bookingImg: booking,
  },
  {
    id: 173827,
    status: "pending",
    name: "Blue Lagoon Resort",
    location: "Ocean Drive",
    checkIn: "Mon, 15 Feb",
    guests: 4,
    bookingImg: booking,
  },
  {
    id: 173828,
    status: "upcoming",
    name: "Mountain View Inn",
    location: "Hillside Park",
    checkIn: "Tue, 22 Mar",
    guests: 1,
    bookingImg: booking,
  },
  {
    id: 173829,
    status: "checked in",
    name: "City Center Hotel",
    location: "Downtown Avenue",
    checkIn: "Wed, 10 Apr",
    guests: 3,
    bookingImg: booking,
  },
  {
    id: 173830,
    status: "awaiting review",
    name: "Sunset Beach Resort",
    location: "Coastal Road",
    checkIn: "Thu, 18 May",
    guests: 2,
    bookingImg: booking,
  },
  {
    id: 173831,
    status: "completed",
    name: "Forest Retreat Lodge",
    location: "Pine Forest Trail",
    checkIn: "Fri, 25 Jun",
    guests: 5,
    bookingImg: booking,
  },
  {
    id: 173832,
    status: "upcoming",
    name: "Lakeview Hotel",
    location: "Lakeview Drive",
    checkIn: "Sat, 03 Jul",
    guests: 3,
    bookingImg: booking,
  },
  {
    id: 173833,
    status: "pending",
    name: "Desert Oasis Hotel",
    location: "Sand Dunes Blvd",
    checkIn: "Sun, 15 Aug",
    guests: 2,
    bookingImg: booking,
  },
  {
    id: 173834,
    status: "upcoming",
    name: "Royal Palace Hotel",
    location: "King's Way",
    checkIn: "Mon, 23 Sep",
    guests: 4,
    bookingImg: booking,
  },
  {
    id: 173835,
    status: "checked in",
    name: "Riverfront Hotel",
    location: "River Street",
    checkIn: "Tue, 07 Oct",
    guests: 1,
    bookingImg: booking,
  },
  {
    id: 173836,
    status: "awaiting review",
    name: "Skyline Hotel",
    location: "Skyline Boulevard",
    checkIn: "Wed, 12 Nov",
    guests: 3,
    bookingImg: booking,
  },
  {
    id: 173837,
    status: "completed",
    name: "Island Paradise Resort",
    location: "Tropical Island",
    checkIn: "Thu, 24 Dec",
    guests: 5,
    bookingImg: booking,
  },
];

export const bookingDetails = [
  {
    id: 173826,
    status: "completed",
    name: "Golden Star Hotel",
    description: "modern hotel at riverside mall",
    location: [
      "600 m to city center",
      "13 km to wilson airport",
      "2 km to uhuru park",
      "0 m to nairobi central station",
    ],
    checkIn: "07 January 2024",
    checkOut: "09 January 2024",
    checkInTime: "7:00PM",
    guests: [2, 3, 0],
    duration: 3,
    specialRequests: [
      "rooms with a balcony",
      "rooms with a city view",
      "rooms close to each other",
    ],
    images: [booking, image2, image3, image4],
    additionalServices: ["three course meal", "cab booking"],
    amenities: [
      { breakfast: true },
      { wifi: true },
      { parking: true },
      { pets: true },
      { smoking: false },
      { laundry: true },
      { exercise: true },
    ],
    roomType: "deluxe palace",
    roomDetails: [
      { size: 18 },
      { location: "city center" },
      { landMark: "next to forest" },
      { bathroom: true },
      { tv: true },
    ],
    avatar: avatar,
    email: "easyset254@gmail.com",
    phone: "+25478888888",
    fullName: "alex omondi",
  },
  {
    id: 173827,
    status: "pending",
    name: "Blue Lagoon Resort",
    description: "modern hotel at ocean drive",
    location: [
      "500 m to city center",
      "14 km to wilson airport",
      "3 km to uhuru park",
      "1 m to nairobi central station",
    ],
    checkIn: "15 February 2024",
    checkOut: "17 February 2024",
    checkInTime: "8:00PM",
    guests: [4, 2, 1],
    duration: 2,
    specialRequests: [
      "rooms with a sea view",
      "rooms with a balcony",
      "rooms close to each other",
    ],
    images: [booking, image2, image3, image4],
    additionalServices: ["spa treatment", "cab booking"],
    amenities: [
      { breakfast: true },
      { wifi: true },
      { parking: true },
      { pets: false },
      { smoking: false },
      { laundry: true },
      { exercise: true },
    ],
    roomType: "ocean suite",
    roomDetails: [
      { size: 22 },
      { location: "beachfront" },
      { landMark: "next to ocean" },
      { bathroom: true },
      { tv: true },
    ],
    avatar: avatar,
    email: "easyset254@gmail.com",
    phone: "+25478888888",
    fullName: "alex omondi",
  },
  {
    id: 173828,
    status: "upcoming",
    name: "Mountain View Inn",
    description: "cozy inn at hillside park",
    location: [
      "800 m to city center",
      "15 km to wilson airport",
      "4 km to uhuru park",
      "2 m to nairobi central station",
    ],
    checkIn: "22 March 2024",
    checkOut: "24 March 2024",
    checkInTime: "6:00PM",
    guests: [1, 0, 0],
    duration: 2,
    specialRequests: [
      "rooms with a mountain view",
      "quiet rooms",
      "rooms on upper floor",
    ],
    images: [booking, image2, image3, image4],
    additionalServices: ["guided hike", "cab booking"],
    amenities: [
      { breakfast: true },
      { wifi: true },
      { parking: true },
      { pets: false },
      { smoking: false },
      { laundry: true },
      { exercise: false },
    ],
    roomType: "standard room",
    roomDetails: [
      { size: 16 },
      { location: "hillside" },
      { landMark: "next to park" },
      { bathroom: true },
      { tv: false },
    ],
    avatar: avatar,
    email: "easyset254@gmail.com",
    phone: "+25478888888",
    fullName: "alex omondi",
  },
  {
    id: 173829,
    status: "checked in",
    name: "City Center Hotel",
    description: "luxury hotel at downtown avenue",
    location: [
      "400 m to city center",
      "10 km to wilson airport",
      "1 km to uhuru park",
      "0 m to nairobi central station",
    ],
    checkIn: "10 April 2024",
    checkOut: "12 April 2024",
    checkInTime: "5:00PM",
    guests: [3, 1, 0],
    duration: 2,
    specialRequests: [
      "rooms with a city view",
      "rooms on upper floor",
      "rooms close to elevator",
    ],
    images: [booking, image2, image3, image4],
    additionalServices: ["fitness center", "cab booking"],
    amenities: [
      { breakfast: true },
      { wifi: true },
      { parking: true },
      { pets: false },
      { smoking: false },
      { laundry: true },
      { exercise: true },
    ],
    roomType: "executive suite",
    roomDetails: [
      { size: 25 },
      { location: "city center" },
      { landMark: "next to mall" },
      { bathroom: true },
      { tv: true },
    ],
    avatar: avatar,
    email: "easyset254@gmail.com",
    phone: "+25478888888",
    fullName: "alex omondi",
  },
  {
    id: 173830,
    status: "awaiting review",
    name: "Sunset Beach Resort",
    description: "beach resort at coastal road",
    location: [
      "300 m to beach",
      "20 km to wilson airport",
      "5 km to uhuru park",
      "1 m to nairobi central station",
    ],
    checkIn: "18 May 2024",
    checkOut: "20 May 2024",
    checkInTime: "4:00PM",
    guests: [2, 2, 1],
    duration: 2,
    specialRequests: [
      "rooms with a beach view",
      "rooms on ground floor",
      "rooms near pool",
    ],
    images: [booking, image2, image3, image4],
    additionalServices: ["snorkeling", "cab booking"],
    amenities: [
      { breakfast: true },
      { wifi: true },
      { parking: true },
      { pets: true },
      { smoking: false },
      { laundry: true },
      { exercise: false },
    ],
    roomType: "beach villa",
    roomDetails: [
      { size: 30 },
      { location: "beachfront" },
      { landMark: "next to pier" },
      { bathroom: true },
      { tv: true },
    ],
    avatar: avatar,
    email: "easyset254@gmail.com",
    phone: "+25478888888",
    fullName: "alex omondi",
  },
  {
    id: 173831,
    status: "completed",
    name: "Forest Retreat Lodge",
    description: "lodge at pine forest trail",
    location: [
      "700 m to forest",
      "18 km to wilson airport",
      "6 km to uhuru park",
      "3 m to nairobi central station",
    ],
    checkIn: "25 June 2024",
    checkOut: "27 June 2024",
    checkInTime: "3:00PM",
    guests: [5, 3, 2],
    duration: 2,
    specialRequests: [
      "rooms with a forest view",
      "quiet rooms",
      "rooms on upper floor",
    ],
    images: [booking, image2, image3, image4],
    additionalServices: ["nature walk", "cab booking"],
    amenities: [
      { breakfast: true },
      { wifi: true },
      { parking: true },
      { pets: true },
      { smoking: false },
      { laundry: true },
      { exercise: false },
    ],
    roomType: "forest suite",
    roomDetails: [
      { size: 20 },
      { location: "forest" },
      { landMark: "next to trail" },
      { bathroom: true },
      { tv: false },
    ],
    avatar: avatar,
    email: "easyset254@gmail.com",
    phone: "+25478888888",
    fullName: "alex omondi",
  },
  {
    id: 173832,
    status: "upcoming",
    name: "Lakeview Hotel",
    description: "hotel at lakeview drive",
    location: [
      "400 m to lake",
      "16 km to wilson airport",
      "4 km to uhuru park",
      "2 m to nairobi central station",
    ],
    checkIn: "03 July 2024",
    checkOut: "05 July 2024",
    checkInTime: "9:00PM",
    guests: [3, 2, 1],
    duration: 2,
    specialRequests: [
      "rooms with a lake view",
      "rooms on ground floor",
      "rooms near pool",
    ],
    images: [booking, image2, image3, image4],
    additionalServices: ["boat ride", "cab booking"],
    amenities: [
      { breakfast: true },
      { wifi: true },
      { parking: true },
      { pets: true },
      { smoking: false },
      { laundry: true },
      { exercise: true },
    ],
    roomType: "lakeview room",
    roomDetails: [
      { size: 22 },
      { location: "lakefront" },
      { landMark: "next to pier" },
      { bathroom: true },
      { tv: true },
    ],
    avatar: avatar,
    email: "easyset254@gmail.com",
    phone: "+25478888888",
    fullName: "alex omondi",
  },
  {
    id: 173833,
    status: "pending",
    name: "Desert Oasis Hotel",
    description: "hotel at sand dunes blvd",
    location: [
      "500 m to desert",
      "22 km to wilson airport",
      "7 km to uhuru park",
      "3 m to nairobi central station",
    ],
    checkIn: "15 August 2024",
    checkOut: "17 August 2024",
    checkInTime: "8:00PM",
    guests: [2, 1, 0],
    duration: 2,
    specialRequests: [
      "rooms with a desert view",
      "rooms on upper floor",
      "rooms close to elevator",
    ],
    images: [booking, image2, image3, image4],
    additionalServices: ["camel ride", "cab booking"],
    amenities: [
      { breakfast: true },
      { wifi: true },
      { parking: true },
      { pets: false },
      { smoking: false },
      { laundry: true },
      { exercise: true },
    ],
    roomType: "desert suite",
    roomDetails: [
      { size: 26 },
      { location: "desert" },
      { landMark: "next to dunes" },
      { bathroom: true },
      { tv: true },
    ],
    avatar: avatar,
    email: "easyset254@gmail.com",
    phone: "+25478888888",
    fullName: "alex omondi",
  },
  {
    id: 173834,
    status: "upcoming",
    name: "Royal Palace Hotel",
    description: "luxury hotel at king's way",
    location: [
      "800 m to city center",
      "14 km to wilson airport",
      "3 km to uhuru park",
      "2 m to nairobi central station",
    ],
    checkIn: "23 September 2024",
    checkOut: "25 September 2024",
    checkInTime: "7:00PM",
    guests: [4, 2, 1],
    duration: 2,
    specialRequests: [
      "rooms with a palace view",
      "quiet rooms",
      "rooms on upper floor",
    ],
    images: [booking, image2, image3, image4],
    additionalServices: ["spa treatment", "cab booking"],
    amenities: [
      { breakfast: true },
      { wifi: true },
      { parking: true },
      { pets: false },
      { smoking: false },
      { laundry: true },
      { exercise: true },
    ],
    roomType: "royal suite",
    roomDetails: [
      { size: 30 },
      { location: "city center" },
      { landMark: "next to palace" },
      { bathroom: true },
      { tv: true },
    ],
    avatar: avatar,
    email: "easyset254@gmail.com",
    phone: "+25478888888",
    fullName: "alex omondi",
  },
  {
    id: 173835,
    status: "checked in",
    name: "Riverfront Hotel",
    description: "hotel at river street",
    location: [
      "300 m to river",
      "10 km to wilson airport",
      "1 km to uhuru park",
      "0 m to nairobi central station",
    ],
    checkIn: "07 October 2024",
    checkOut: "09 October 2024",
    checkInTime: "6:00PM",
    guests: [1, 0, 0],
    duration: 2,
    specialRequests: [
      "rooms with a river view",
      "rooms on ground floor",
      "rooms near pool",
    ],
    images: [booking, image2, image3, image4],
    additionalServices: ["river cruise", "cab booking"],
    amenities: [
      { breakfast: true },
      { wifi: true },
      { parking: true },
      { pets: true },
      { smoking: false },
      { laundry: true },
      { exercise: true },
    ],
    roomType: "river view room",
    roomDetails: [
      { size: 18 },
      { location: "riverfront" },
      { landMark: "next to pier" },
      { bathroom: true },
      { tv: true },
    ],
    avatar: avatar,
    email: "easyset254@gmail.com",
    phone: "+25478888888",
    fullName: "alex omondi",
  },
  {
    id: 173836,
    status: "awaiting review",
    name: "Skyline Hotel",
    description: "hotel at skyline boulevard",
    location: [
      "500 m to city center",
      "12 km to wilson airport",
      "2 km to uhuru park",
      "1 m to nairobi central station",
    ],
    checkIn: "12 November 2024",
    checkOut: "14 November 2024",
    checkInTime: "8:00PM",
    guests: [3, 1, 0],
    duration: 2,
    specialRequests: [
      "rooms with a skyline view",
      "rooms on upper floor",
      "rooms close to elevator",
    ],
    images: [booking, image2, image3, image4],
    additionalServices: ["gym access", "cab booking"],
    amenities: [
      { breakfast: true },
      { wifi: true },
      { parking: true },
      { pets: false },
      { smoking: false },
      { laundry: true },
      { exercise: true },
    ],
    roomType: "skyline suite",
    roomDetails: [
      { size: 24 },
      { location: "city center" },
      { landMark: "next to boulevard" },
      { bathroom: true },
      { tv: true },
    ],
    avatar: avatar,
    email: "easyset254@gmail.com",
    phone: "+25478888888",
    fullName: "alex omondi",
  },
  {
    id: 173837,
    status: "completed",
    name: "Island Paradise Resort",
    description: "resort at tropical island",
    location: [
      "200 m to beach",
      "20 km to wilson airport",
      "5 km to uhuru park",
      "1 m to nairobi central station",
    ],
    checkIn: "24 December 2024",
    checkOut: "26 December 2024",
    checkInTime: "9:00PM",
    guests: [5, 3, 2],
    duration: 2,
    specialRequests: [
      "rooms with a beach view",
      "rooms on ground floor",
      "rooms near pool",
    ],
    images: [booking, image2, image3, image4],
    additionalServices: ["snorkeling", "cab booking"],
    amenities: [
      { breakfast: true },
      { wifi: true },
      { parking: true },
      { pets: true },
      { smoking: false },
      { laundry: true },
      { exercise: false },
    ],
    roomType: "beach villa",
    roomDetails: [
      { size: 28 },
      { location: "beachfront" },
      { landMark: "next to pier" },
      { bathroom: true },
      { tv: true },
    ],
    avatar: avatar,
    email: "easyset254@gmail.com",
    phone: "+25478888888",
    fullName: "alex omondi",
  },
];
