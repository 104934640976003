import ImageLogo from "../../assets/LoginPage/GroupImage.svg";
import logo from "@/assets/shared/Group.svg";

function NewPassword() {
  return (
    <>
    <div className="flex flex-col lg:flex-row  lg:w-[90rem] lg:h-[64rem]">
      <div className="lg:bg-[hsla(185,100%,98%,1)]  w-full lg:w-[34.4375rem] lg:h-[63.625rem]  lg:p-[5.8125rem 4.475625rem 5.875rem 4.4375rem]">
        <div className="bg-lightCyan  py-6 w-full h-[4.125rem] lg:absolute top-[5.813rem] left-[9.5rem] lg:w-[15.36875rem] lg:h-[5rem] mb-4 mx-auto lg:mt-5">
          <img src={logo} alt="" className="mx-auto w-[5rem] h-[1.625rem] lg:w-[15.36875rem] lg:h-[5rem]" />
        </div>
        <div className="mx-auto">
          <img src={ImageLogo} alt="" className="mx-auto mt-8 w-[8.898rem] h-[5.758rem] lg:absolute lg:top-[25.063rem] lg:left-[4.438rem] lg:w-[25.52375rem] lg:h-[16.414rem] mt-10" />
        </div>
        <div className="w-full text-center lg:w-[17.875rem] lg:h-[1.875rem]">
          <p className="hidden lg:absolute top-[55.875rem] left-[8.25rem]  lg:block font-poppins text-xl font-normal leading-[1.875rem]">Comfort meets convenience</p>
        </div>
      </div>

      {/* The left column */}
      <div className="flex flex-col justify-center items-center w-fit px-6 lg:w-[55.5625rem] lg:h-[63.8125rem] lg:py-[21.875rem] lg:px-[17rem]">
        <div className="mx-auto w-full lg:absolute top-[21.875rem] lg:w-[22.125rem] lg:h-[19.6875rem] flex flex-col  justify-center items-center mt-6">
          <p className="font-poppins text-lg lg:text-2xl font-medium leading-[1.125rem] lg:absolute top-[2px] left-[-1px] text-center lg:text-left ">CREATE NEW PASSWORD</p>
          <div className="lg:mt-10 p-3 lg:pl-0 lg:mr-9 lg:pr-8">
            <p className="w-full flex font-poppins text-sm text-center justify-center lg:text-start lg:items-start lg:justify-start lg:text-xs font-normal  leading-[1.125rem] text-[hsla(0,0%,67%,1)] mt-4">
              Create a strong password of at least 8 characters, including Uppercase, Lowercase, Numbers & symbols.
            </p>
          </div>
          <form action="#" className="w-full">
          <label className="relative py-2 ">
            <input
              type="password"
              name="NewPassword"
              placeholder=" "
              className="px-4 py-2 mt-4 w-full h-[3.125rem] text-sm border border-[hsla(0,0%,35%,1)] rounded focus:border-blue-400 focus:outline-none peer"
              required
            />
            <span className="absolute left-0 top-1 px-1 text-sm leading-[21px] tracking-wide text-gray-500 duration-200 peer-focus:text-blue-400 pointer-events-none bg-white ml-2 peer-focus:text-[10px] peer-focus:-translate-y-5 peer-valid:text-[10px] peer-valid:-translate-y-5">
              New Password
            </span>
          </label>
          <label className="relative py-2 ">
            <input
              type="password"
              name="confirmPassword"
              placeholder=" "
              className="px-4 py-2 mt-4 w-full h-[3.125rem] text-sm border border-[hsla(0,0%,35%,1)] rounded focus:border-blue-400 focus:outline-none peer"
              required
            />
            <span className="absolute left-0 top-1 px-1 text-sm leading-[21px] tracking-wide text-gray-500 duration-200 peer-focus:text-blue-400 pointer-events-none bg-white ml-2 peer-focus:text-[10px] peer-focus:-translate-y-5 peer-valid:text-[10px] peer-valid:-translate-y-5">
              Confirm New Password
            </span>
          </label>
            <button className="w-full h-[3.5rem] lg:w-full lg:h-[3.625rem] p-[1.25rem] gap-0 rounded-[2.25rem] bg-[hsla(210,96%,33%,1)] hover:bg-blue-500 duration-300 ease-in-out mt-6 py-2">
              <span className="font-poppins text-base lg:text-xl text-[hsla(0,0%,100%,1)] font-normal leading-[1.125rem] text-center">Reset Password</span>
            </button>
          </form>
          <div className="w-full lg:w-[22.125rem] lg:h-[1.125rem] mt-4 flex justify-center lg:justify-start items-center ">
            <a href="#" className="text-[hsla(210,96%,33%,1)] lg:leading-[1.125rem] underline text-xs hover:text-blue-400 transition-all duration-300 ease-in-out transition-transform hover:scale-105">Back to Sign in</a>
          </div>
        </div>
      </div>
    </div>
    </>
  );
}

export default NewPassword;
