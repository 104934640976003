import { useState, useRef } from 'react';

// eslint-disable-next-line react/prop-types
const CreatePostModal = ({ isOpen, onClose, onSubmit }) => {
    const [postContent, setPostContent] = useState("");
    const [images, setImages] = useState([]);
    const fileInputRef = useRef(null);

    const handleFileChange = (event) => {
        const files = Array.from(event.target.files);
        setImages((prevImages) => [...prevImages, ...files]);
    };

    const handleRemoveImage = (index) => {
        setImages((prevImages) => prevImages.filter((_, i) => i !== index));
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        onSubmit({ content: postContent, images });
        setPostContent("");
        setImages([]);
    };

    if (!isOpen) return null;

    return (
        <div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50">
            <div className="bg-white p-6 rounded-lg w-[500px]">
                <h2 className="text-2xl mb-4 font-semibold text-center">Create a Post</h2>
                <form onSubmit={handleSubmit}>
                    <textarea
                        value={postContent}
                        onChange={(e) => setPostContent(e.target.value)}
                        className="w-full p-2 border border-gray-300 rounded outline-none text-[13px]"
                        rows="4"
                        placeholder="What's on your mind?"
                    />
                    <input
                        type="file"
                        multiple
                        ref={fileInputRef}
                        onChange={handleFileChange}
                        className="hidden"
                    />
                    <div className="flex flex-wrap mt-2">
                        {images.map((image, index) => (
                            <div key={index} className="relative w-24 h-24 m-1">
                                <img
                                    src={URL.createObjectURL(image)}
                                    alt={`Upload Preview ${index}`}
                                    className="w-full h-full object-cover rounded"
                                />
                                <button
                                    type="button"
                                    onClick={() => handleRemoveImage(index)}
                                    className="absolute top-0 right-0 bg-red-500 text-white rounded-full w-6 h-6 flex items-center justify-center"
                                >
                                    &times;
                                </button>
                            </div>
                        ))}
                    </div>
                    <div className="flex justify-between mt-4">
                        <button
                            type="button"
                            onClick={() => fileInputRef.current.click()}
                            className="px-4 py-2 bg-gray-300 rounded-3xl hover:bg-gray-400 text-[14px]"
                        >
                            Add Image
                        </button>
                        <div className="flex">
                            <button
                                type="button"
                                onClick={onClose}
                                className="mr-2 px-4 py-2 bg-gray-300 rounded-3xl hover:bg-gray-400 text-[14px]"
                            >
                                Cancel
                            </button>
                            <button
                                type="submit"
                                className="px-4 py-2 bg-blue-500 text-white rounded-3xl hover:bg-blue-600 text-[14px]"
                            >
                                Post
                            </button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default CreatePostModal;
