import React from 'react';
import { Link } from "react-router-dom";
import property from '@/assets/newListYourProperty/property.jfif';
import CustomBtn from '@/components/ui/CustomBtn';

const NewListYourProperty = () => {
  return (
    <div className="xl:mx-auto w-full xl:px-[88px] px-4 mt-20">
      <div className="flex flex-col lg:flex-row items-center gap-8 mb-10">
        <div className="w-full lg:w-1/2">
          <h3 className="text-xl text-[#3C3C3C] font-semibold">List Your Property</h3>
          <p className="my-6 text-[#4F4F4F] text-base font-normal">
            Buzz, buzz! Can you hear that? It's the sound of potential buyers and renters buzzing with excitement about your property. You set the terms; we provide the expertise. List with Laara Group and watch your property shine.
          </p>
          <Link to="/landingpage">
            <CustomBtn text="Become A Host" />
          </Link>
        </div>
        <div className="w-full lg:w-1/2">
          <img src={property} alt="property" className="h-auto lg:h-[256px] rounded-3xl w-full" />
        </div>
      </div>
    </div>
  );
};

export default NewListYourProperty;
