import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import arrowleft from "@/assets/shared/arrowleft.svg";
import { Rating } from "@smastrom/react-rating";
import { baseURL, headers } from "@/constants/apiconfig";
import { useSelector } from "react-redux";
import axios from "axios";

const ReviewReservation = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const initialData = {
    publicMessage: "",
    privateMessage: "",
    publicRating: 3,
    publicHoveredRating: 3,
    privateRating: 3,
    privateHoveredRating: 3,
    reviewType: "'public",
  };
  const [reviewData, setReviewData] = useState(initialData);

  const token = useSelector((state) => state.user.accessToken);

  const {
    publicMessage,
    publicRating,
    privateMessage,
    privateRating,
    reviewType,
  } = reviewData;

  const publicReview = {
    message: publicMessage,
    rating: publicRating,
    bookingRef: id,
    reviewType,
  };

  const privateReview = {
    message: privateMessage,
    rating: privateRating,
    bookingRef: id,
    reviewType,
  };
  let data = {};
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (reviewData.reviewType === "private") {
      data = privateReview;
    } else {
      data = publicReview;
    }

    try {
      const res = await axios.post(`${baseURL}/reviews/property`, data, {
        headers: headers(token),
      });
      setReviewData(initialData);

      return res.data;
    } catch (error) {
      throw new Error(error);
    }
  };

  const getRating = (rating) => {
    switch (rating) {
      case 1:
        return 1;
      case 2:
        return 2;
      case 3:
        return 3;
      case 4:
        return 4;
      case 5:
        return 5;
      default:
        return 0;
    }
  };

  const rate = { 1: "bad", 2: "okay", 3: "good", 4: "awesome", 5: "superb" };

  return (
    <section className="w-full mx-auto p-5 md:px-10 bg-[#FAFAFA] ">
      <section className="max-w-[1264px] w-full mx-auto">
        <section className="w-full space-y-5 ">
          {/* TOP SECTION */}
          <div className="flex items-center gap-2  ">
            <img
              src={arrowleft}
              alt="arrow-left icon"
              onClick={() => navigate(-1)}
              className="size-6 cursor-pointer"
            />
            <h4 className="text-xl lg:text-lg font-medium text-[#626262] ">
              Review
            </h4>
          </div>

          <form
            onSubmit={handleSubmit}
            className=" bg-white rounded-[12px] p-3 xl:p-8 shadow-xl w-full "
          >
            {/* FORM HEADER */}
            <div className="flex items-center justify-between">
              <p className="text-[#626262] text-lg ">Rating & Feedback Form</p>
            </div>

            {/* LINE */}
            <div className="h-[1px] w-full bg-[#C5C5C580]/50 my-4 " />

            {/* MAIN PUBLIC REVIEW */}
            <div className=" space-y-6  ">
              <div className="p-2 space-y-3 border border-[#62626280/20] rounded-[10px]">
                <label
                  htmlFor="public-review"
                  className="flex items-center gap-2"
                >
                  <input
                    type="radio"
                    id="public-review"
                    name="reviewType"
                    value="public"
                    className="size-5"
                    checked={reviewData.reviewType === "public"}
                    onChange={() =>
                      setReviewData({ ...reviewData, reviewType: "public" })
                    }
                  />
                  <span className="text-[#3C3C3C] text-base font-medium ">
                    Public Review
                  </span>
                </label>

                <p className="text-[#626262] text-base ">
                  My experience was:{" "}
                  {rate[reviewData.publicHoveredRating] ||
                    rate[reviewData.publicRating]}
                </p>

                {/* RATING */}
                <div>
                  <div>
                    <Rating
                      style={{ maxWidth: 200 }}
                      value={reviewData.publicRating}
                      onChange={(value) =>
                        setReviewData({ ...reviewData, publicRating: value })
                      }
                      onHoverChange={(value) =>
                        setReviewData({
                          ...reviewData,
                          publicHoveredRating: value,
                        })
                      }
                    />
                    <div>
                      <p className="text-[12px] text-[#626262]">{`Selected: ${
                        rate[getRating(reviewData.publicRating)]
                      }`}</p>
                    </div>
                  </div>
                </div>
                {/*  TEXTAREA */}
                <div className="flex flex-col gap-2 ">
                  <textarea
                    placeholder="write here..."
                    rows={5}
                    disabled={reviewData.reviewType !== "public"}
                    className="border border-[#C5C5C580] rounded-[12px] p-2 outline-none "
                    value={reviewData.publicMessage}
                    onChange={(e) =>
                      setReviewData({
                        ...reviewData,
                        publicMessage: e.target.value,
                      })
                    }
                  ></textarea>
                </div>
              </div>

              {/* MAIN PRIVATE REVIEW */}
              <div className="p-2 space-y-3 border border-[#62626280/20] rounded-[10px]">
                <label
                  htmlFor="private-review"
                  className="flex items-center gap-2"
                >
                  <input
                    id="private-review"
                    type="radio"
                    name="reviewType"
                    value="private"
                    className="size-5"
                    checked={reviewData.reviewType === "private"}
                    onChange={() =>
                      setReviewData({ ...reviewData, reviewType: "private" })
                    }
                  />
                  <span className="text-[#3C3C3C] text-base font-medium ">
                    Private Review
                  </span>
                </label>

                <p className="text-[#626262] text-base ">
                  My experience was:{" "}
                  {rate[reviewData.privateHoveredRating] ||
                    rate[reviewData.privateRating]}
                </p>

                {/* RATING */}
                <div>
                  <div>
                    <Rating
                      style={{ maxWidth: 200 }}
                      value={reviewData.privateRating}
                      onChange={(value) =>
                        setReviewData({ ...reviewData, privateRating: value })
                      }
                      onHoverChange={(value) =>
                        setReviewData({
                          ...reviewData,
                          privateHoveredRating: value,
                        })
                      }
                    />
                    <div>
                      <p className="text-[12px] text-[#626262]">{`Selected: ${
                        rate[getRating(reviewData.privateRating)]
                      }`}</p>
                    </div>
                  </div>
                </div>
                {/*  TEXTAREA */}
                <div className="flex flex-col gap-2 ">
                  <textarea
                    placeholder="write here..."
                    rows={5}
                    disabled={reviewData.reviewType !== "private"}
                    className="border border-[#C5C5C580] rounded-[12px] p-2 outline-none "
                    value={reviewData.privateMessage}
                    onChange={(e) =>
                      setReviewData({
                        ...reviewData,
                        privateMessage: e.target.value,
                      })
                    }
                  ></textarea>
                </div>
              </div>

              <div className="text-right">
                <button
                  type="submit"
                  className="bg-laara-primary text-white text-base font-medium rounded-[36px] py-3 px-[14px] "
                >
                  Post Review
                </button>
              </div>
            </div>
          </form>
        </section>
      </section>
    </section>
  );
};

export default ReviewReservation;
