const durations = [
  {
    id: 1,
    duration: "2-3 nights",
  },
  {
    id: 2,
    duration: "4-5 nights",
  },
  {
    id: 3,
    duration: "1 week",
  },
  {
    id: 4,
    duration: "2 weeks",
  },
  {
    id: 5,
    duration: "3 weeks",
  },
];

const months = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

import { useState } from "react";
import MobileMonthSwiper from "./MobileMonthSwiper";

const MobileFlexible = () => {
  const [selectedDuration, setSelectedDuration] = useState("2-3 nights");
  const [selectedWeekend, setSelectedWeekend] = useState(false);

  const [selectedMonth, setSelectedMonth] = useState(0);

  return (
    <div className="bg-white">
      <h2 className="font-semibold text-[#292929] my-3">
        How long do you you want to stay?
      </h2>
      <div className="flex gap-[12px] flex-wrap">
        {durations.map((duration) => (
          <span
            onClick={() => setSelectedDuration(duration.duration)}
            key={duration.id}
            className={`rounded-[32px] border  p-2.5 m-2 cursor-pointer  ${
              selectedDuration === duration.duration
                ? "text-[#0466C8] border-[#0466C8]"
                : "text-[#7B7B7B] border-[#7B7B7B]"
            }`}
          >
            <p>{duration.duration}</p>
          </span>
        ))}
      </div>
      <span className="flex gap-5 my-4 text-sm">
        <input
          value={selectedWeekend}
          onChange={(e) => setSelectedWeekend(e.target.checked)}
          type="checkbox"
          name="weekend"
          id="weekend"
        />
        <p className="text-[#292929]">Includes weekend</p>
      </span>

      <hr className="my-6" />

      {/* slider for months */}

      <MobileMonthSwiper setSelectedMonth={setSelectedMonth} months={months} />
    </div>
  );
};

export default MobileFlexible;