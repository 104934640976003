import React, { useState } from "react";

export const FinalVerification = () => {
  const [listingType, setListingType] = useState("individual");
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: "",
    countryCode: "+254",
    country: "",
    addressLine1: "",
    addressLine2: "",
    city: "",
    zipCode: "",
    agreeTerms: false,
  });

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: type === "checkbox" ? checked : value,
    }));
  };
  return (
    <div className="min-h-screen flex justify-start">
      <div className="w-full max-w-4xl px-4 py-8 sm:px-6 lg:px-8">
        <div className="mb-6 p-4">
          <div className="w-full max-w-[728px] mx-auto p-4 bg-laara-secondary border rounded-lg relative">
            <div className="w-full p-4 text-base sm:text-lg font-normal leading-6 sm:leading-7 text-left">
              <h2 className="text-lg font-semibold mb-2">
                Are you listing the property as a business or an individual?
              </h2>
              <div className="flex space-x-4">
                <label className="flex items-center">
                  <input
                    type="radio"
                    className="form-radio w-4 h-4"
                    name="listingType"
                    value="individual"
                    checked={listingType === "individual"}
                    onChange={() => setListingType("individual")}
                    required
                  />
                  <span className="ml-2">Individual</span>
                </label>
                <label className="flex items-center">
                  <input
                    type="radio"
                    className="form-radio w-4 h-4"
                    name="listingType"
                    value="business"
                    checked={listingType === "business"}
                    onChange={() => setListingType("business")}
                    required
                  />
                  <span className="ml-2">Business</span>
                </label>
              </div>
            </div>
          </div>
        </div>
        <div className="w-full max-w-[728px] mx-auto p-4 bg-laara-secondary border rounded-lg relative">
          <div className="w-full p-4 text-base sm:text-lg font-normal leading-6 sm:leading-7 text-left">
            <h3 className="text-lg font-semibold p-4">Personal Information</h3>

            <form className="space-y-4 p-4">
              <div>
                <label className="block text-left text-base md:text-lg font-normal text-gray-700 leading-[1.5] md:leading-[27px]">
                  Your first name as stated on ID
                  <span className="text-red-500">*</span>
                </label>
                <input
                  type="text"
                  name="firstName"
                  className="mt-1 block w-full border-gray-300 rounded-lg shadow-sm h-10 border focus:outline-none"
                  onChange={handleInputChange}
                  value={formData.firstName}
                  required
                />
              </div>

              <div>
                <label className="block text-left text-base md:text-lg font-normal text-gray-700 leading-[1.5] md:leading-[27px]">
                  Your last name as stated on ID
                  <span className="text-red-500">*</span>
                </label>
                <input
                  type="text"
                  name="lastName"
                  className="mt-1 block w-full border-gray-300 rounded-lg shadow-sm h-10 border focus:outline-none"
                  onChange={handleInputChange}
                  value={formData.lastName}
                  required
                />
              </div>

              <div>
                <label className="block text-left text-base md:text-lg  font-normal text-gray-700 leading-[1.5] md:leading-[27px]">
                  Email<span className="text-red-500">*</span>
                </label>
                <input
                  type="email"
                  name="email"
                  className="mt-1 block w-full border-gray-300 rounded-lg shadow-sm h-10 border focus:outline-none"
                  onChange={handleInputChange}
                  value={formData.email}
                  required
                />
              </div>

              <div>
                <label className="block text-left text-base md:text-lg  font-normal text-gray-700 leading-[1.5] md:leading-[27px]">
                  Phone number<span className="text-red-500">*</span>
                </label>
                <div className="mt-1 flex">
                  <select
                    name="countryCode"
                    className="block w-20 border-gray-300 rounded-lg shadow-sm h-10 border focus:outline-none"
                    onChange={handleInputChange}
                    value={formData.countryCode}
                    required
                  >
                    <option value="+254">+254</option>
                    <option value="">+255</option>
                    <option value="">+93</option>
                    <option value="">+244</option>
                    <option value="">+1242</option>
                    <option value="">+880</option>
                    <option value="">+376</option>
                    <option value="">+253</option>
                    <option value="">+218</option>
                    <option value="">+211</option>
                    <option value="">+256</option>
                    <option value="">+971</option>
                    {/* Add more country codes as needed */}
                  </select>
                  <input
                    type="tel"
                    name="phoneNumber"
                    className="ml-2 block w-full border-gray-300 rounded-lg shadow-sm h-10 border focus:outline-none"
                    onChange={handleInputChange}
                    value={formData.phoneNumber}
                    required
                  />
                </div>
              </div>

              <h4 className="font-medium">Primary residence</h4>

              <div>
                <label className="block text-left text-base md:text-lg  font-normal text-gray-700 leading-[1.5] md:leading-[27px]">
                  Country/Region<span className="text-red-500">*</span>
                </label>
                <select
                  name="country"
                  className="mt-1 block w-full border-gray-300 rounded-lg shadow-sm h-10 border focus:outline-none"
                  onChange={handleInputChange}
                  value={formData.country}
                  required
                >
                  <option value="">Select</option>
                  <option value="Kenya">Kenya</option>
                  <option value="Uganda">Uganda</option>
                  <option value="Tanzania">Tanzania</option>
                  <option value="Rwanda">Rwanda</option>
                  <option value="Ethiopia">Ethiopia</option>
                  <option value="Zanzibar">Zanzibar</option>
                  <option value="Nigeria">Nigeria</option>
                  <option value="Congo">Congo</option>
                  <option value="United States">US</option>
                  <option value="Namibia">Namibia</option>
                  <option value="Madagascar">Madagascar</option>
                  {/* Add country options */}
                </select>
              </div>

              <div>
                <label className="block text-left text-base md:text-lg  font-normal text-gray-700 leading-[1.5] md:leading-[27px]">
                  Address line 1<span className="text-red-500">*</span>
                </label>
                <input
                  type="text"
                  name="addressLine1"
                  className="mt-1 block w-full border-gray-300 rounded-lg shadow-sm h-10 border focus:outline-none"
                  onChange={handleInputChange}
                  value={formData.addressLine1}
                  required
                />
              </div>

              <div>
                <label className="block text-left text-base md:text-lg  font-normal text-gray-700 leading-[1.5] md:leading-[27px]">
                  Address line 2
                </label>
                <input
                  type="text"
                  name="addressLine2"
                  className="mt-1 block w-full border-gray-300 rounded-lg shadow-sm h-10 border focus:outline-none"
                  onChange={handleInputChange}
                  value={formData.addressLine2}
                />
              </div>

              <div className="flex flex-col sm:flex-row sm:space-x-4">
                <div className="flex-1 mb-4 sm:mb-0">
                  <label className="block text-left text-base font-normal text-gray-700 leading-6">
                    City<span className="text-red-500">*</span>
                  </label>
                  <input
                    type="text"
                    name="city"
                    className="mt-1 block w-full border-gray-300 rounded-lg shadow-sm h-10 border focus:outline-none"
                    onChange={handleInputChange}
                    value={formData.city}
                    required
                  />
                </div>
                <div className="flex-1">
                  <label className="block text-left text-base font-normal text-gray-700 leading-6">
                    Zip code<span className="text-red-500">*</span>
                  </label>
                  <input
                    type="text"
                    name="zipCode"
                    className="mt-1 block w-full border-gray-300 rounded-lg shadow-sm h-10 border focus:outline-none"
                    onChange={handleInputChange}
                    value={formData.zipCode}
                    required
                  />
                </div>
              </div>
              <div className="flex items-center">
                <input
                  type="checkbox"
                  name="agreeTerms"
                  className="form-checkbox"
                  onChange={handleInputChange}
                  checked={formData.agreeTerms}
                />
                <label className="ml-2 block text-left text-base md:text-lg font-normal text-gray-700 leading-[1.5] md:leading-[27px]">
                  I accept and agree with Iaara terms & conditions
                </label>
              </div>
            </form>
          </div>
        </div>
        <div className="w-full max-w-4xl px-4 py-8 sm:px-6 lg:px-8">
          <div className="flex flex-col sm:flex-row justify-between p-4 ">
            <button
              type="button"
              className="w-full sm:max-w-[180.71px] h-[55px] px-8 border border-gray-300 rounded-full text-sm font-medium text-laara-primary text-center"
            >
              I'm not ready
            </button>

            <button
              type="submit"
              className="w-full sm:w-[525.7px] h-[55px] px-4  rounded-full text-sm font-medium text-white bg-laara-primary "
            >
              Open for bookings
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
