import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setBathroomAmenity, setBathroomPrivacy } from "@/redux/Listing/listingSlice";
import Header from "./globalrooms/Header";
import { updateRoomBathroom } from "@/api";
import { useParams } from "react-router-dom";
import useFetchRoomDetails from './globalrooms/useFetchRoomDetails'; 
import './globalrooms/rooms.css';

export const EditBathroom = () => {
  const dispatch = useDispatch();
  const { id: propertyId } = useParams(); // Extract propertyId from URL

  const { rooms, loading, error } = useFetchRoomDetails(propertyId);
  
  const selectedAmenity = useSelector(
    (state) => state.hostListing.formData.roomType.bathroom.amenities || []
  );
  const bathroomPrivacy = useSelector(
    (state) => state.hostListing.formData.roomType.bathroom.isPrivate
  );

  const [typeId, setTypeId] = useState(null);

  useEffect(() => {
    if (rooms.length > 0) {
      const firstRoom = rooms[0]; // Assuming you want to use the first room or adjust as necessary
      setTypeId(firstRoom.roomTypes.id); // Adjust this line based on your data structure
    }
  }, [rooms]);

  const handleCheckboxChange = (item) => {
    const isSelected = selectedAmenity.some((a) => a.name === item);
    const updatedAmenities = isSelected
      ? selectedAmenity.filter((a) => a.name !== item)
      : [
          ...selectedAmenity,
          { 
            name: item, 
            description: `${item} available`,
            category: "Bathroom", // Default category
            icon: "" // Set default or handle icons if needed
          }
        ];
    dispatch(setBathroomAmenity(updatedAmenities));
  };

  const handleChange = (e) => {
    dispatch(setBathroomPrivacy(e.target.value === "yes"));
  };

  const bathroomItems = [
    "Toilet paper",
    "Shower",
    "Toilet",
    "Hair dryer",
    "Bath",
    "Free Toiletries",
    "Bidet",
    "Slippers",
    "Bathrobe",
    "Spa bath",
  ];

  const renderCheckboxes = (category, items) => (
    <div key={category} className="space-y-2">
      <div className="flex flex-col gap-2">
        {items.map((item) => (
          <div key={item} className="flex items-center gap-4">
            <input
              id={`${category}-${item}`}
              type="checkbox"
              className="size-6 custom-checkbox1"
              checked={selectedAmenity.some(
                (a) => a.name === item
              )}
              onChange={() => handleCheckboxChange(item)}
            />
            <label
              htmlFor={`${category}-${item}`}
              className={`capitalize text-[15px] p-1 ${
                selectedAmenity.some((a) => a.name === item) && "text-laara-primary"
              }`}
            >
              {item}
            </label>
          </div>
        ))}
      </div>
    </div>
  );

  const handleSubmit = async (event) => {
    event.preventDefault();

    const formattedData = {
      bathroom: {
        isPrivate: bathroomPrivacy,
        amenities: selectedAmenity.map((item) => ({
          category: "Bathroom",
          name: item.name
        }))
      }
    };

    try {
      const response = await updateRoomBathroom(propertyId, typeId, formattedData);
      console.log("Successfully updated bathroom details:", response);
    } catch (error) {
      console.error("Error updating bathroom details:", error.message);
    }
  };

  console.log("Property ID:", propertyId); 
    console.log("Type ID:", typeId); 

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error: {error}</p>;

  return (
    <form className="bg-base-white p-8" onSubmit={handleSubmit}>
        <Header title="Bathroom"/>
      <div className="w-full max-w-[733px] p-4 border bg-laara-secondary rounded-lg mb-10">
        <h2 className="text-lg font-semibold mb-3">Is the bathroom private?</h2>
        <div className="mb-6 flex flex-col space-y-2">
          <label className="inline-flex items-center">
            <input
              type="radio"
              className="form-radio w-5 h-5 accent-laara-primary"
              name="bathroomPrivacy"
              value="yes"
              checked={bathroomPrivacy}
              onChange={handleChange}
            />
            <span className="text-sm font-normal leading-relaxed ml-2 cursor-pointer">
              Yes
            </span>
          </label>
          <label className="inline-flex items-center">
            <input
              type="radio"
              className="form-radio w-5 h-5 accent-laara-primary"
              name="bathroomPrivacy"
              value="no"
              checked={!bathroomPrivacy}
              onChange={handleChange}
            />
            <span className="text-sm font-normal leading-relaxed ml-2 cursor-pointer">
              No, It is shared
            </span>
          </label>
        </div>
      </div>

      <div className="w-full max-w-[733px] p-8 border rounded-lg">
        <h2 className="text-lg font-semibold mb-3">
          Which bathroom items are available in this room?
        </h2>
        <div className="grid grid-cols-1 gap-4">
          {renderCheckboxes("Bathroom", bathroomItems)}
        </div>
      </div>
      
      <button type="submit" className="mt-6 bg-laara-primary text-white px-4 py-2 rounded-[36px] w-[216px] h-12">
        Save Changes
      </button>
    </form>
  );
};

export default EditBathroom;
