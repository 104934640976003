import { useState } from "react";

import ArrowLeft from "@/assets/hero/arrow-left.svg";
import { Search } from "lucide-react";
import MobileCalendar from "./MobileCalendar";
import MobileFlexible from "./MobileFlexible";

import MobileTravellerOverlay from "./MobileTravellerOverlay";

const MobileOverlay = ({
  toggleModal,
  checkin,
  setCheckIn,
  checkout,
  setCheckout,
  travellers,
  setTravellers,
  location,
  setLocation,
}) => {
  const [accordion, setAccordion] = useState("");
  const [activeTab, setActiveTab] = useState("calendar");

  const toggleTab = (tab) => {
    setActiveTab(tab);
  };

  // take only date, month, year in format DD/MM/YYYY

  const formatDate = (date) => {
    const d = new Date(date);
    return `${d.getDate()}/${d.getMonth() + 1}/${d.getFullYear()}`;
  };

  // check whether travellers are selected or not
  const isTravellerSelected = () => {
    return travellers.adult + travellers.child + travellers.infant > 0;
  };

  return (
    <div className="absolute w-full top-0 bg-white  left-0 right-0 overflow-y-auto h-auto bottom-0 p-8 text-[#292929] z-50">
      <button onClick={toggleModal} className="my-14">
        <img src={ArrowLeft} alt="close modal" />
      </button>

      {/* location accordion */}
      <div className="rounded-[24px] border px-5 py-[26px]">
        {accordion === "where" ? (
          <div>
            <h2 className="text-xl font-medium mb-5">
              Where are you going to?
            </h2>
            <span className="relative ">
              <Search
                size={20}
                color="#0466C8"
                className="absolute top-0 left-2"
              />
              <input
                value={location}
                onChange={(e) => setLocation(e.target.value)}
                type="text"
                placeholder="Search destinations"
                className="w-full border border-[#9B9B9B] px-8 py-5 rounded-lg text-sm focus:outline-none"
              />
            </span>
          </div>
        ) : (
          <span className="flex justify-between">
            <h1 className="font-medium text-[17px]">Where</h1>
            <p
              onClick={() => setAccordion("where")}
              className="text-sm text-[#757575]"
            >
              {location ? location : " Choose destinations"}
            </p>
          </span>
        )}
      </div>

      {/* date accordion */}
      <div className="rounded-[24px] border px-5 py-[26px] mt-6 bg-white ">
        {accordion === "when" ? (
          <div>
            <h2 className="text-xl font-medium mb-5">When are you going?</h2>
            <span className="relative ">
              <div className="w-full flex">
                <h1
                  className={` border-b-2 font-medium text-center w-full ${
                    activeTab === "calendar"
                      ? "text-[#0466C8] border-b-2 pb-3 border-[#0466C8]"
                      : "text-[#A3A3A3]"
                  } cursor-pointer `}
                  onClick={() => toggleTab("calendar")}
                >
                  Calendar
                </h1>
                <h1
                  className={`font-medium  border-b-2 text-center w-full ${
                    activeTab === "flexible"
                      ? "text-[#0466C8]  pb-3 border-[#0466C8]"
                      : "text-[#A3A3A3]"
                  } cursor-pointer`}
                  onClick={() => toggleTab("flexible")}
                >
                  I'm Flexible
                </h1>
              </div>
              {activeTab === "calendar" && (
                <MobileCalendar
                  checking={checkin}
                  checkout={checkout}
                  setChecking={setCheckIn}
                  setCheckout={setCheckout}
                />
              )}
              {activeTab === "flexible" && <MobileFlexible />}
            </span>
          </div>
        ) : (
          <span className="flex justify-between">
            <h1 className="font-medium text-[17px]">When</h1>
            <p
              onClick={() => setAccordion("when")}
              className="text-sm text-[#757575]"
            >
              {checkin && checkout
                ? `${formatDate(checkin)} - ${formatDate(checkout)}`
                : "Add dates"}
            </p>
          </span>
        )}
      </div>

      {/* travellers accordion */}
      <div className="rounded-[24px] border px-5 py-[26px] mt-6">
        {accordion === "who" ? (
          <div>
            <h2 className="text-xl font-medium mb-5">Who’s Coming?</h2>
            <MobileTravellerOverlay
              travellers={travellers}
              setTravellers={setTravellers}
            />
          </div>
        ) : (
          <span className="flex justify-between">
            <h1 className=" font-medium text-[17px]">Who</h1>
            <p
              onClick={() => setAccordion("who")}
              className="text-sm text-[#757575]"
            >
              {isTravellerSelected()
                ? `${
                    travellers.adult + travellers.child + travellers.infant
                  } guests`
                : "Add travellers"}
            </p>
          </span>
        )}
      </div>
    </div>
  );
};

export default MobileOverlay;