import { useState, useRef } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faEllipsisH, faHeart, faComment, faShare, faEdit, faPaperclip, faSmile, faPaperPlane, faUsers, faBuilding, faUtensils, faUser } from '@fortawesome/free-solid-svg-icons';
import { Link, useParams } from "react-router-dom";
import { properties, propertyImages, updates } from '@/constants/data';
import locationIcon from "@/assets/shared/location.png";
import starIcon from "@/assets/shared/star.png";
import profile from "@/assets/profile/Profile.svg";
import './updates.css'; 
import Picker from '@emoji-mart/react';
import data from '@emoji-mart/data';
import CreatePostModal from '@/components/Updates/CreatePostModal';

const Updatesproperty = () => {
    const { propertyId } = useParams();
    const property = properties.find(p => p.propertyId === parseInt(propertyId));
    const propertyUpdates = updates.find(u => u.propertyId === parseInt(propertyId))?.updates || [];
    const [activeTab, setActiveTab] = useState('posts');
    const [activeGalleryTab, setActiveGalleryTab] = useState('facilities');
    const [showFullText, setShowFullText] = useState({});
    const [commentsVisible, setCommentsVisible] = useState({});
    const [comment, setComment] = useState("");
    const [showPicker, setShowPicker] = useState(false);
    const [fileUrl, setFileUrl] = useState(null);
    const [isModalOpen, setIsModalOpen] = useState(false);

    const goToReservation = () => {
        window.location.href = `/bookings`;
    };

    const toggleText = (id) => {
        setShowFullText(prevState => ({ ...prevState, [id]: !prevState[id] }));
    };

    const toggleComments = (id) => {
        setCommentsVisible(prevState => ({ ...prevState, [id]: !prevState[id] }));
    };

    const handleSendComment = () => {
        // endpoint to send comment
        console.log("New comment:", comment);
        setComment("");
        setFileUrl(null);
    };

    const fileInputRef = useRef(null);

    const handleButtonClick = () => {
        fileInputRef.current.click();
    };

    const handleEmojiSelect = (emoji) => {
        setComment(comment + emoji.native);
        setShowPicker(false);
    };

    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            event.preventDefault(); 
            handleSendComment();
        }
    };

    const handleFileChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            const url = URL.createObjectURL(file);
            setFileUrl(url);
        }
    };

    const renderImages = (images) => {
        return (
            <div className="gallery-images flex flex-wrap gap-4 w-[740px]">
                {images.map((image, index) => (
                    <img key={index} src={image} alt={`Image ${index}`} className="w-[350px] h-[250px] object-cover" />
                ))}
            </div>
        );
    };
    
    const images = propertyImages[propertyId] || { facilities: [], staff: [], food: [] };

    const handleCreatePost = ({ content, images }) => {
        console.log("New post content:", content);
        console.log("New post images:", images);
        setIsModalOpen(false);
        // logic to save post using api endpoints
    };


    if (!property) {
        return <div>Property not found</div>;
    }

    return (
        <div className="updates-container">
            {/* title */}
            <div className="updates-container-title">
                <div className="updates-container-title-info flex items-center w-full h-[36px] gap-3">
                    <Link to="/updates"><FontAwesomeIcon icon={faArrowLeft} className="text-xl"/></Link>
                    <h3 className="text-2xl">Updates</h3>
                </div>
            </div>
            {/* propertydetails */}
            <div className="property-details w-full flex justify-between">
                <div className="property-image-container relative flex w-[1000px] gap-10">
                    <img src={property.propertyImage} alt={property.propertyName} className="w-[305px] h-[133.31px] rounded-[16px]" />
                    <div className="protruding-image-wrapper absolute bottom-[-10px] left-[15px] w-[86.16px] h-[77.96px] bg-white rounded-[12px] border-2 border-gray-200">
                        <img src={property.propertyImage} alt={property.propertyName} className="w-full h-full rounded-[12px]" />
                    </div>
                    <div className="property-info-content w-full">
                        <h3 className="text-2xl font-semibold leading-10 tracking-tight text-left">{property.propertyName}</h3>
                        <div className="property-location flex gap-2">
                            <img src={locationIcon} alt="Location Icon" className="location-icon w-[20px] h-[20px]" />
                            <p className="location-text text-base font-normal leading-6 text-left text-gray-600">{property.location}</p>
                            <div className="property-rating flex gap-1 ml-2">
                                <img src={starIcon} alt="Star Icon" className="star-icon w-[20px] h-[20px]" />
                                <p className="text-gray-600">{property.rating}</p>
                            </div>
                        </div>
                        <div className="text-base font-normal leading-6 text-left flex items-center gap-5 text-gray-600">
                        <FontAwesomeIcon icon={faUser} /><p>{property.followerCount} Followers</p>
                        </div>
                        <div className="property-btns">
                            <button
                            onClick={goToReservation}
                            className="book-now-btn w-[125px] h-[50px] px-[18px] py-[14px] gap-[14px] rounded-[25px] bg-blue-500 hover:bg-blue-600 text-white mt-3">Book Now</button>
                            <button className="ml-3 text-center w-[125px] h-[50px] px-[18px] py-[14px] gap-[14px] rounded-[25px] text-blue-500 border border-blue-500 mt-3 hover:bg-red-400 hover:text-white transition-all duration-500 ease-in-out">Unfollow</button>
                        </div>
                    </div>
                    
                </div>
                
                {/* profileinfo */}
                <div className="profile-info w-[250px]">
                    <div className="profile-image-container w-[50px] h-[50px] ml-[70%]">
                        <img src={profile} className="profile-image" alt="Profile" />
                    </div>
                    <div>
                    <button
                            onClick={() => setIsModalOpen(true)}
                            className="create-a-post-btn text-center mt-[4rem] border border-blue-500 w-full h-[50px] px-[18px] py-[14px] gap-[14px] rounded-[25px] border-t-[1px] text-blue-500 hover:bg-blue-500 hover:text-white transition-all duration-500 ease-in"
                        >
                            <p><FontAwesomeIcon icon={faEdit} /> Create a Post</p>
                        </button>
                    </div>
                </div>
            </div>
            <div className="property-btns-mobile">
                <button
                onClick={goToReservation}
                className="book-now-btn w-[125px] h-[50px] px-[18px] py-[14px] gap-[14px] rounded-[25px] bg-blue-500 hover:bg-blue-600 text-white mt-3">Book Now</button>
                <button className="ml-3 text-center w-[125px] h-[50px] px-[18px] py-[14px] gap-[14px] rounded-[25px] text-blue-500 border border-blue-500 mt-3 hover:bg-red-400 hover:text-white transition-all duration-500 ease-in-out">Unfollow</button>
            </div>
            {/* updates-section */}
            <div className="updates-full-container mt-10 flex justify-between">
                <div className="updates-section mt-10 ">
                    <div className="updates-section-titlebtns w-[750px] h-[40px] text-[18px] font-medium leading-[36px] text-left flex gap-5">
                        <button 
                            className={`focus:outline-none ${activeTab === 'posts' ? 'text-blue-600 border-b-2 border-blue-600' : 'text-gray-500'}`} 
                            onClick={() => setActiveTab('posts')}
                        >
                            Posts
                        </button>
                        <button 
                            className={`focus:outline-none ${activeTab === 'gallery' ? 'text-blue-600 border-b-2 border-blue-600' : 'text-gray-500'}`} 
                            onClick={() => setActiveTab('gallery')}
                        >
                            Gallery
                        </button>
                    </div>
                    {activeTab === 'posts' ? (
                        <ul className="updates-list mt-5 w-full max-w-[750px] h-auto py-4 gap-2">
                            {propertyUpdates.map(update => (
                                <li key={update.updateId} className="bg-white border rounded-3xl p-4 mb-4">
                                    <div className="flex items-center">
                                        <img src={profile} alt="Profile" className="w-12 h-12 rounded-full mr-4" />
                                        <div>
                                            <div className="flex items-center">
                                                <span className="font-semibold text-lg">{update.userName}</span>
                                                <span className="text-gray-500 text-sm ml-2">{update.timePosted}</span>
                                            </div>
                                        </div>
                                        <FontAwesomeIcon icon={faEllipsisH} className="ml-auto text-gray-400 hover:text-gray-600 cursor-pointer" />
                                    </div>
                                    <div className="mt-2 text-gray-800">
                                        {showFullText[update.updateId] ? update.text : `${update.text.substring(0, 100)}...`}
                                        {update.text.length > 100 && (
                                            <span onClick={() => toggleText(update.updateId)} className="text-blue-500 hover:underline cursor-pointer">
                                                {showFullText[update.updateId] ? 'See less' : 'See more'}
                                            </span>
                                        )}
                                    </div>
                                    {update.image && <img src={update.image} alt="Update" className="post-image mt-4 rounded-[20px] w-full max-w-[888px] h-[250px]" />}
                                    <div className="controls flex items-center mt-4 text-sm">
                                        <div className="flex items-center text-gray-400 p-2 rounded-full hover:text-blue-500 mr-1 cursor-pointer">
                                            <FontAwesomeIcon icon={faHeart} className="mr-[2px] fa-light" />
                                            <span>{update.likes}</span>
                                        </div>
                                        <div className="flex items-center text-gray-400 p-2 rounded-full hover:text-blue-500 mr-1 cursor-pointer" onClick={() => toggleComments(update.updateId)}>
                                            <FontAwesomeIcon icon={faComment} className="mr-[2px]" />
                                            <span>{update.comments.length}</span>
                                        </div>
                                        <div className="controls-share flex items-center text-gray-400 p-2 rounded-full hover:text-blue-500 cursor-pointer">
                                            <FontAwesomeIcon icon={faShare} className="mr-[2px]" />
                                            <span>Share</span>
                                        </div>
                                    </div>
                                    {commentsVisible[update.updateId] && (
                                        <div className="comments-section mt-4">
                                            <h3 className="text-center font-bold text-gray-500 p-2">Comments</h3>
                                            {update.comments.map(comment => (
                                                <div key={comment.commentId} className="comment-input-container m-2">
                                                    <img src={profile} alt="Profile" className="w-8 h-8 rounded-full mr-2" />
                                                    <div className="bg-gray-100 p-2 rounded-2xl flex-1">
                                                        <div className="flex items-center">
                                                            <span className="font-semibold">{comment.userName}</span>
                                                            <span className="text-gray-500 text-xs ml-2">{comment.timePosted}</span>
                                                        </div>
                                                        <div className="mt-1">{comment.text}</div>
                                                    </div>
                                                </div>
                                            ))}
                                            <div className="flex items-center mt-4">
                                                <img src={profile} alt="Profile" className="w-6 h-6 rounded-full mr-2" />
                                                <div className="relative flex-1">
                                                    <input
                                                        type="text"
                                                        className="w-full border p-2 text-[12px] outline-none rounded-3xl"
                                                        placeholder=""
                                                        value={comment}
                                                        onChange={(e) => setComment(e.target.value)}
                                                        onKeyDown={handleKeyDown}
                                                    />
                                                    <FontAwesomeIcon
                                                        icon={faPaperclip}
                                                        className="absolute top-1/2 right-10 transform -translate-y-1/2 text-gray-400 cursor-pointer"
                                                        onClick={handleButtonClick}
                                                    />
                                                    <input
                                                        type="file"
                                                        ref={fileInputRef}
                                                        className="hidden"
                                                        onChange={handleFileChange}
                                                    />
                                                    <FontAwesomeIcon
                                                        icon={faSmile}
                                                        className="absolute top-1/2 right-4 transform -translate-y-1/2 text-gray-400 cursor-pointer"
                                                        onClick={() => setShowPicker(!showPicker)}
                                                    />
                                                    {showPicker && (
                                                        <div className="absolute bottom-10 right-0 z-10">
                                                            <Picker data={data} onEmojiSelect={handleEmojiSelect} />
                                                        </div>
                                                    )}
                                                </div>
                                                <FontAwesomeIcon
                                                    icon={faPaperPlane}
                                                    className="ml-2 text-white p-2 cursor-pointer bg-blue-500 rounded-full w-4 h-4 hover:bg-blue-400"
                                                    onClick={handleSendComment}
                                                />
                                                {fileUrl && (
                                                    <div className="file-preview mt-2">
                                                        {fileUrl.endsWith('.mp4') ? (
                                                            <video controls src={fileUrl} className="max-w-[150] mt-2" />
                                                        ) : (
                                                            <img src={fileUrl} alt="Preview" className="max-w-[150] mt-2" />
                                                        )}
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    )}
                                </li>
                            ))}
                        </ul>
                    ) : (
                        <div className="gallery mt-5">
                            <div className="gallery-header w-[750px] h-[40px] text-[16px] font-medium text-left flex gap-5 mb-2 ">
                                <button 
                                    className={`focus:outline-none ${activeGalleryTab === 'facilities' ? 'text-blue-600 border-b-2 border-blue-600' : 'text-gray-500'}`} 
                                    onClick={() => setActiveGalleryTab('facilities')}
                                >
                                    <FontAwesomeIcon icon={faBuilding} 
                                    className="mr-2"/>
                                    Facilities
                                </button>
                                <button 
                                    className={`focus:outline-none ${activeGalleryTab === 'staff' ? 'text-blue-600 border-b-2 border-blue-600' : 'text-gray-500'}`} 
                                    onClick={() => setActiveGalleryTab('staff')}
                                >
                                    <FontAwesomeIcon icon={faUsers} 
                                    className="mr-2" />
                                    Staff
                                </button>
                                <button 
                                     className={`focus:outline-none ${activeGalleryTab === 'food' ? 'text-blue-600 border-b-2 border-blue-600' : 'text-gray-500'}`} 
                                     onClick={() => setActiveGalleryTab('food')}
                                >
                                    <FontAwesomeIcon icon={faUtensils} 
                                    className='mr-2'/>
                                    Food
                                </button>
                            </div>
                            <div className="mt-4 rounded-[16px] w-[750px]">
                                {activeGalleryTab === 'facilities' && renderImages(images.facilities)}
                                {activeGalleryTab === 'staff' && renderImages(images.staff)}
                                {activeGalleryTab === 'food' && renderImages(images.food)}
                            </div>

                        </div>
                    )}
                </div>
                <div className="updates-container-rightside">
                    <img src={property.propertyImage} className="h-[350px] w-[full] max-w-[350px] rounded-[16px]"></img>
                    <div className="w-[350px]">
                        <h3 className="text-2xl font-semibold leading-[36px] tracking-[0.005em] text-left mt-4">Similar Continental Hotel Suites.</h3>
                        <div className="hotel w-full">
                            <div className="w-full flex pt-[12px] pl-[16px] pr-[16px] pb-[12px] gap-4 rounded-[16px] rounded-br-[0px] rounded-bl-[0px] border-b-[1px]">
                                <img src={property.propertyImage} className="w-[50px] h-[50px] rounded-[12px]"/>
                                <div className="w-[200px] flex-col">
                                    {/* hotelname */}
                                    <h4 className="text-[22px] font-medium leading-[32px] tracking-[0.005em] text-left">{property.propertyName}</h4>
                                    {/* locationicon, location and rating */}
                                    <div className="w-full flex items-center text-[11px] font-medium">
                                        <img src={locationIcon} alt="Location Icon" className="location-icon w-[20px] h-[20px]" />
                                        <p className="text-[11px] text-base font-normal text-left">{property.location}</p>
                                        <div className="property-rating flex items-center ml-5">
                                            <img src={starIcon} alt="Star Icon" className="star-icon w-[20px] h-[20px]" />
                                            <p>{property.rating}</p>
                                        </div>
                                    </div>
                                    {/* viewbtn */}
                                    <button className="w-full h-[40px] gap-[14px] rounded-[25px] bg-blue-500 hover:bg-blue-600 text-white mt-3">View</button>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* properties you follow */}
                    <div className="w-[350px]">
                        <h3 className="text-2xl font-semibold leading-[36px] tracking-[0.005em] text-left mt-4">Other Properties you Follow</h3>
                        <div className="hotel w-full pt-[12px] pl-[16px] pr-[16px] pb-[12px] gap-[4px] rounded-[16px] rounded-br-[0px] rounded-bl-[0px] border-b-[1px]">
                            <div className="w-full flex gap-4">
                                <img src={property.propertyImage} className="w-[60px] h-[60px] rounded-[12px]"/>
                                <div className="w-[200px] flex-col">
                                    {/* hotelname */}
                                    <h4 className="text-[22px] font-medium leading-[32px] tracking-[0.005em] text-left">{property.propertyName}</h4>
                                    {/* locationicon, location and rating */}
                                    <div className="w-full flex items-center text-[11px] font-medium ">
                                        <img src={locationIcon} alt="Location Icon" className="location-icon w-[20px] h-[20px]" />
                                        <p className="text-[11px] text-base font-normal text-left">{property.location}</p>
                                        <div className="property-rating flex items-center ml-5">
                                            <img src={starIcon} alt="Star Icon" className="star-icon w-[20px] h-[20px]" />
                                            <p>{property.rating}</p>
                                        </div>
                                    </div>
                                    {/* viewbtn */}
                                    <button className="w-full h-[40px] gap-[14px] rounded-[25px] bg-blue-500 hover:bg-blue-600 text-white mt-3">View</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {isModalOpen && (
                <CreatePostModal
                    isOpen={isModalOpen}
                    onClose={() => setIsModalOpen(false)}
                    onSubmit={handleCreatePost}
                />
            )}
        </div>
    );
};

export default Updatesproperty;
