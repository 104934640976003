import { FaFacebook, FaInstagram, FaTwitter, FaLinkedin } from "react-icons/fa";
import { Link } from "react-router-dom";
import Logo from "@/assets/shared/Fav1.svg";
import { legal, hosting, support, company } from "@/constants/data";

const Footer = () => {
  return (
    <section id="footer">
      <div className="footer relative w-full bg-laara-primary text-white">
        <div className="w-full px-5 py-12 md:p-10 mx-auto xl:px-[88px]">
          <div className="flex flex-col gap-9 xl:flex-row">
            {/* Logo Section */}
            <div className="footer-logo">
              <img src={Logo} alt="Laara Logo" className="w-[60px] mr-10" />
            </div>

            {/* Grid Section */}
            <div className="grid grid-cols-2 gap-6 xl:gap-20 md:grid-cols-5 xl:flex xl:items-start xl:justify-between xl:w-full">

              {/* Legal Column */}
              <div className="footer-column md:col-span-2">
                <h5 className="footer-head">Legal</h5>
                <ul className="space-y-4">
                  {legal.map((legalLink, index) => (
                    <li key={index}>
                      <Link
                        to={legalLink.link}
                        className="hover:none text-laara-secondary text-[14px] leading-[21px] md:text-lg xl:text-base xl:leading-[26px]"
                      >
                        {legalLink.name}
                      </Link>
                    </li>
                  ))}
                </ul>
              </div>

              {/* Hosting Column */}
              <div className="footer-column md:col-span-3">
                <h5 className="footer-head">Hosting</h5>
                <ul className="space-y-4">
                  {hosting.map((hostingLink, index) => (
                    <li key={index}>
                      <Link
                        to={hostingLink.link}
                        className="hover:none text-laara-secondary text-[14px] leading-[21px] md:text-lg xl:text-base xl:leading-[26px]"
                      >
                        {hostingLink.name}
                      </Link>
                    </li>
                  ))}
                </ul>
              </div>

              {/* Support Column */}
              <div className="footer-column md:col-span-2">
                <h5 className="footer-head">Support</h5>
                <ul className="space-y-4">
                  {support.map((supportLink, index) => (
                    <li key={index}>
                      <Link
                        to={supportLink.link}
                        className="hover:none text-laara-secondary text-[14px] leading-[21px] md:text-lg xl:text-base xl:leading-[26px]"
                      >
                        {supportLink.name}
                      </Link>
                    </li>
                  ))}
                </ul>
              </div>

              {/* Company Column */}
              <div className="footer-column md:col-span-1">
                <h5 className="footer-head">Company</h5>
                <ul className="space-y-4">
                  {company.map((companyLink, index) => (
                    <li key={index}>
                      <Link
                        to={companyLink.link}
                        className="hover:none text-laara-secondary text-[14px] leading-[21px] md:text-lg xl:text-base xl:leading-[26px]"
                      >
                        {companyLink.name}
                      </Link>
                    </li>
                  ))}
                </ul>
              </div>

              {/* Social Media Column */}
              <div className="footer-column md:col-span-2 md:justify-self-end">
                <h5 className="footer-head">Get in Touch</h5>
                <ul className="text-white space-x-4 flex items-center">
                  <li>
                    <a href="https://ke.linkedin.com/company/laara-group" className="flex items-center hover:none" target="_blank" rel="noopener noreferrer">
                      <FaLinkedin className="h-7 w-[30px] fill-current" />
                    </a>
                  </li>
                  <li>
                    <a href="#" className="flex items-center hover:none">
                      <FaFacebook className="h-7 w-[30px] fill-current" />
                    </a>
                  </li>
                  <li>
                    <a href="#" className="flex items-center hover:none">
                      <FaInstagram className="h-7 w-[30px] fill-current" />
                    </a>
                  </li>
                  <li>
                    <a href="#" className="flex items-center hover:none">
                      <FaTwitter className="h-7 w-[30px] fill-current" />
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>

          {/* Divider */}
          <div className="w-full h-[1px] bg-laara-secondary my-9"></div>

          {/* Footer Bottom */}
          <div className="flex flex-col justify-center w-full md:flex-row md:justify-between">
            <h1 className="block mb-5 font-poppins font-medium text-base text-[#F4FEFF] text-left leading-7 md:mb-1">
              © 2024 Laara Group Inc.
            </h1>
            <div className="flex gap-4">
              <h1 className="block mb-5 font-poppins font-medium text-base text-[#F4FEFF] text-left leading-7 md:mb-1">
                All rights Reserved
              </h1>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Footer;
