
import { BsBuilding } from 'react-icons/bs';


// NearbyAttractions Component
const NearbyAttractions = ({ attractions }) => {
  return (
    <div className="p-4">

      {/* Responsive Grid for Attractions */}
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6p-3">
        {attractions.map((attraction, index) => (
          <div
            key={index}
            className="flex items-center p-4 rounded-lg "
          >
            {/* Icon for location */}
            <BsBuilding className="text-blue-400 text-xl mr-2" />

            {/* Location and Distance in a row */}
            <div className="flex flex-1 justify-between">
              <h3 className="text-md font-medium text-gray-800">
                {attraction.name}
              </h3>
              <p className="text-sm text-gray-600">
                {attraction.distance} km
              </p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default NearbyAttractions;
