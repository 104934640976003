import { useNavigate } from 'react-router-dom';
import { data } from "@/constants/data"
import card from "../../assets/settings/card.svg"
import add from "../../assets/settings/add.svg"
import back from "../../assets/settings/back.svg";
export default function FinanceSettings() {
  const navigate = useNavigate();
  const handleBackClick = () => {
    navigate(-1);  
  };
  return (
    <div className="flex flex-col pb-10 ">
      <div className="flex flex-col self-center mt-0 w-full max-w-[1440px] max-md:mt-10 max-md:max-w-full">
        <div className="flex flex-col px-20 mt-16 w-full leading-[120%] max-md:px-5 max-md:mt-10 max-md:max-w-full">
          <div className="flex flex-col max-md:max-w-full">
            <div className="flex flex-col leading-[120%] max-md:max-w-full">
            <button 
         onClick={handleBackClick}
        className="flex gap-3 self-start pr-5 text-4xl font-bold whitespace-nowrap text-neutral-600 max-md:ml-2">
          <img
            loading="lazy"
            src={back}
            className="shrink-0 my-auto w-9 aspect-square"
            alt="Settings Icon"
          />
          <span>Settings</span>
        </button>
              <div className="text-2xl mt-10 font-medium text-neutral-600 max-md:max-w-full">
                Billing Information
              </div>
              <div className="mt-2.5 text-lg text-zinc-500 max-md:max-w-full">
                Update your billing details
              </div>
            </div>
            <div className="flex gap-5 justify-between mt-5 w-full max-md:flex-wrap max-md:max-w-full">
              <button className="flex gap-2 justify-center p-5 my-auto text-lg font-medium leading-5 text-center text-white bg-sky-600 rounded-[36px]">
                <img
                  loading="lazy"
                  src={add}
                  className="shrink-0 w-6 aspect-square"
                />
                <span>Add a new card</span>
              </button>
              <div className="flex flex-col justify-center max-md:max-w-full">
                <img
                  loading="lazy"
                  src={card}
                  className="w-full aspect-[2.08] max-md:max-w-full"
                />
              </div>
            </div>
          </div>
          <div className="flex flex-col mt-10 leading-[120%] max-md:mt-10 max-md:max-w-full">
            <div className="flex flex-col max-md:max-w-full">
              <div className="text-2xl font-medium text-neutral-600 max-md:max-w-full">
                Recent Transactions & Payments
              </div>
              <div className="mt-2.5 text-lg text-zinc-500 max-md:max-w-full">
                Detailed history of all transactions on the platform
              </div>
            </div>
            <div className="flex flex-col justify-center mt-8 text-sm bg-white text-zinc-800 max-md:max-w-full overflow-x-auto rounded-xl">
  <table className="min-w-full bg-white">
    <thead>
      <tr className="text-base text-white whitespace-nowrap bg-sky-600 rounded-md border-b border-solid border-neutral-200">
        <th className="px-3.5 py-4 text-center">Expense</th>
        <th className="px-3.5 py-4 text-center">Business</th>
        <th className="px-3.5 py-4 text-center">Status</th>
        <th className="px-3.5 py-4 text-center">Date</th>
        <th className="px-3.5 py-4 text-right">Amount</th>
      </tr>
    </thead>
    <tbody>
      {data.map((item, index) => (
        <tr key={index} className="border-b border-neutral-300">
          <td className="p-3.5 text-base font-medium text-sky-600 text-center">{item.expense}</td>
          <td className="p-3.5 text-center">
            <span className={`px-2.5 py-1.5 rounded-full text-white ${item.business === 'Stays' ? 'bg-blue-500' : item.business === 'Munch' ? 'bg-orange-400' : 'bg-cyan-300'}`}>
              {item.business}
            </span>
          </td>
          <td className="p-3.5 text-center">{item.status}</td>
          <td className="p-3.5 text-center">{item.date}</td>
          <td className="p-3.5 text-right">{item.amount}</td>
        </tr>
      ))}
    </tbody>
  </table>
</div>

          </div>
        </div>
      </div>
    </div>
  )
}
