import React from 'react';
import { FaCheck } from 'react-icons/fa'; // Import the check icon

const ProgressBar = ({ currentStep }) => {
  // Define the total number of steps
  const totalSteps = 3;

  return (
    <div className="w-full flex items-center justify-between mb-8 relative ">
      {/* Background line (gray) */}
      <div className="absolute left-0 right-0 top-1/2 transform -translate-y-1/2 h-0.5 bg-gray-300 w-full">
        {/* Dynamic progress bar filled up to the current step */}
        <div
          className="absolute left-0 top-0 h-0.5 bg-blue-600 transition-width duration-300 ease-in-out"
          style={{
            width: `${((currentStep - 1) / (totalSteps - 1)) * 100}%`,
          }}
        ></div>
      </div>

      {/* Progress Steps */}
      {[...Array(totalSteps)].map((_, index) => (
        <div key={index} className="relative flex items-center">
          {/* Step Indicator */}
          <div
            className={`w-8 h-8 flex items-center justify-center rounded-full font-semibold z-10 border ${
              index + 1 < currentStep
                ? 'border-blue-600 text-blue-600 bg-white' // Completed steps
                : index + 1 === currentStep
                ? 'border-blue-600 text-white bg-blue-600' // Current step
                : 'border-blue-600 text-blue-600 bg-white' // Future steps
            }`}
          >
            {index + 1 < currentStep ? (
              <FaCheck className="text-blue-600" /> // Render checkmark for completed steps
            ) : (
              <span className="text-base">{index + 1}</span> // Step number for current or future steps
            )}
          </div>
        </div>
      ))}
    </div>
  );
};

export default ProgressBar;
