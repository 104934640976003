import React from 'react'
import rating from "@/assets/profile/Star 1.png";
import { profileData } from "@/constants/data";
import Profile3 from "@/assets/profile/Profile3.png"

export const Public = () => {
  return (
    <div className="max-w-[1440px] mx-auto px-4">
      <div className="max-w-[1088px] mx-auto mt-[50px] left-[88px] flex flex-col gap-[52px] mb-[200px]">
        <div className="flex flex-col lg:flex-row justify-between gap-8 lg:gap-16">
          {/* Side */}
          <div className="w-full lg:w-[294px] flex flex-col justify-between">
            <div className="w-full h-full flex flex-col justify-between">
              <div>
                {profileData.stats.map(({ label, value }) => (
                  <div key={label} className="w-full flex ml-8 pr-4 mb-10">
                    <div className="w-[89px]">
                      <h2 className="font-bold text-laara-primary text-[36px] leading-[46px] tracking-[0.005em]">{value}</h2>
                      <span className="text-sm sm:text-base text-[#626262] leading-tight tracking-tight">{label}</span>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>

          {/* Main */}
          <div className="flex-1">
            {['Email address', 'Phone Number', 'Bio'].map((field) => (
              <div key={field} className={`mb-4 p-2 ${field === 'Bio' ? 'min-h-[132px]' : 'min-h-[76px]'} overflow-hidden`}>
                <div className="text-[#4F4F4F] text-lg sm:text-xl font-medium leading-tight mb-2">
                  {field}
                </div>
                <div className={`text-[#626262] text-sm sm:text-base font-normal leading-relaxed break-words ${field === 'Phone Number' ? 'font-semibold' :
                  field === 'Bio' ? 'font-normal' :
                    'font-medium'
                  }`}>
                  {field === 'Email address' && profileData.email}
                  {field === 'Phone Number' && profileData.phone}
                  {field === 'Bio' && profileData.bio}
                </div>
              </div>
            ))}
          </div>
        </div>

        <div className="grid grid-cols-1 lg:grid-cols-2 gap-4">
          {profileData.review.map((review, index) => (
            <div
              key={index}
              className="bg-white rounded-[30px] border border-gray-100 p-3 sm:p-4 w-full max-w-[616px] h-full max-h-[231px]"
            >
              <div className="relative flex flex-col sm:flex-row">
                <img src={Profile3} alt="Eric" className="w-16 h-16 sm:w-20 sm:h-20 rounded-full mb-2 sm:mb-0 sm:mr-4" />
                <div className="flex-grow pr-6">
                  <div className="flex justify-between items-start mb-2">
                    <div>
                      <h3 className="font-semibold text-base sm:text-lg text-laara-primary truncate">
                        {review.author}
                      </h3>
                      <div className="flex items-center mt-1">
                        {[...Array(5)].map((_, i) => (
                          <img key={i} src={rating} alt="Rating" className="w-3 h-3 sm:w-4 sm:h-4 rounded-full" />
                        ))}
                        <span className="ml-1 text-xs sm:text-sm text-gray-600">{review.rating}</span>
                      </div>
                    </div>
                    <div className="text-right text-xs sm:text-sm">
                      <span className="block text-[#888888]">{review.time}</span>
                      <span className="block text-[#4F4F4F]">{review.date}</span>
                    </div>
                  </div>
                  <p className="text-[#4F4F4F] text-sm sm:text-base italic font-normal leading-snug mt-2 line-clamp-3 sm:line-clamp-none">
                    {review.text || "He is an excellent guest who I would love to host again, He is an excellent guest who I would love to host again He is an excellent guest who I would love to host again"}
                  </p>
                </div>
                <button className="absolute top-[-1px] right-[-15px] p-2">
                  <div className="flex flex-col items-center justify-between w-4 h-4 sm:w-5 sm:h-5">
                    <div className="w-1 h-1 bg-black rounded-full"></div>
                    <div className="w-1 h-1 bg-black rounded-full"></div>
                    <div className="w-1 h-1 bg-black rounded-full"></div>
                  </div>
                </button>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}