import React, { useState } from "react";
import Consider from "@/components/Listings/Consider";
import { setFormData } from "@/redux/Listing/listingSlice";
import { useDispatch, useSelector } from "react-redux";

const BRCapacity = () => {
  const dispatch = useDispatch();
  const name = useSelector((state) => state.hostListing.formData.name);

  const handleChange = (e) => {
    dispatch(setFormData({ name: e.target.value }));
  };

  return (
    <form className="flex flex-col">
      <h4 className="font-medium text-xl">
        What's the name of your boardroom?
      </h4>
      <div className="flex items-start gap-4 mt-5">
        <div className="p-4 flex flex-col gap-2 bg-laara-secondary/35 max-w-[728px] w-full h-[400px]">
          <label htmlFor="name" className="text-base">
            Boardroom Capacity
          </label>
          <input
            type="text"
            id="name"
            name="propertyName"
            placeholder="name"
            value={name}
            className="w-[20rem] border py-2 px-3 rounded-lg block"
            onChange={handleChange}
          />
        </div>

        <Consider
          title="What should I consider when choosing a name"
          data={["keep it short"]}
        />
      </div>
    </form>
  );
};

export default BRCapacity;
