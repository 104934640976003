import React from 'react';
import bed1 from "@/assets/ListingProcess/bed1.png"
import property1 from "@/assets/ListingProcess/property1.svg"
import tick from "@/assets/ListingProcess/tick-circle.svg"
import house from "@/assets/ListingProcess/house-2.svg"
import Group from "@/assets/ListingProcess/Group.svg"
import Final from "@/assets/ListingProcess/Final.svg"

export const NextStep2 = () => {
    const steps = [
        {
            number: 1,
            title: 'Boardroom details',
            description: 'The basics, add your property name, address amenities and more',
            icon: tick,
            status: 'completed',
            action: 'Edit'
        },
        {
            number: 2,
            title: 'Photos',
            description: 'Share some photos of your place for guests to know what to expect',
            icon: tick,
            status: 'completed',
            photos: {property: [bed1], property2: [property1]}
        },
        {
            number: 3,
            title: 'Pricing',
            description: 'Share the prices you would want guests to pay for your property',
            icon: Group,
            status: 'upcoming'
        },
        {
            number: 4,
            title: 'Rooms',
            description: 'Tell us about your first room, once you have set one up you can add more',
            icon: house,
            status: 'upcoming',
            action: 'Add room'
        },
        {
            number: 5,
            title: 'Final steps',
            description: 'Set up payment and invoicing before you open for bookings',
            icon: Final,
            status: 'upcoming',
            action: 'add final details'
        },
    ];

    const PhotoSection = ({ photos, title, description }) => (
        <div className="mb-4">
          <div className="flex p-2">
            <div className="flex-shrink-0">
              {photos.map((photo, index) => (
                <div key={index} className="relative mb-2 last:mb-0">
                  <img src={photo} alt={`Photo ${index + 1}`} className="w-44 h-34 object-cover rounded-lg" />
                </div>
              ))}
            </div>
            <div className="ml-4 flex-grow">
              <h2 className="text-lg font-semibold mb-2">{title}</h2>
              <p className="text-lg font-normal text-[#888888]">{description}</p>
            </div>

            <div className="flex justify-end space-x-3 mt-2">
            <button className="text-[#C03744]">Delete</button>
            <button className="text-[#0466C8]">Edit</button>
          </div>
          </div>
         
        </div>
    );

    const Step = ({ number, title, description, status, icon, action, rooms, photos }) => {
        const getStatusColor = () => {
            switch (status) {
                case 'completed':
                    return 'text-white bg-[#108975]';
                case 'current':
                    return 'text-[#0466C8] ';
                default:
                    return 'text-gray-500';
            }
        };

        const getActionButton = () => {
            if (!action) return null;

            let buttonClass = 'w-full py-2 px-6 rounded-full ';

            switch (status) {
                case 'completed':
                    buttonClass += 'text-[#0466C8]';
                    break;
                case 'current':
                    buttonClass += 'bg-[#0466C8] text-white';
                    break;
                case 'upcoming':
                    if (number === 4) {
                        buttonClass += 'text-[#0466C8] border border-[#0466C8]';
                    } else if (number === 5) {
                        buttonClass += 'bg-[#A3A3A3] text-[#918E8E] border border-[#A3A3A3]';
                    }
                    break;
            }

            return <button className={buttonClass}>{action}</button>;
        };

        return (
            <div className="bg-[#F4FEFF] border p-4 rounded-lg mb-4">
                <div className="flex items-center justify-between">
                    <div className="flex items-center">
                        <div className={`${getStatusColor()} rounded-full p-1 mr-3`}>
                            <img src={icon} alt="" className="w-6 h-6 sm:w-8 sm:h-8" />
                        </div>
                        <div>
                            <h3 className="font-normal text-sm sm:text-base leading-5 sm:leading-6 text-[#626262]">Step {number}</h3>
                            <h3 className="text-xl sm:text-lg font-medium leading-6 sm:leading-7 text-left text-[#000000]">{title}</h3>
                            <p className="text-sm sm:text-sm font-normal leading-4 sm:leading-5 text-left text-[#6D6D6D] mt-1">{description}</p>
                        </div>
                    </div>
                    <div>
                        {getActionButton()}
                    </div>
                </div>
                {photos && (
                    <div className="mt-4 ml-20">
                        <PhotoSection 
                            photos={photos.property} 
                            title="Boardroom1" 
                            description="Capacity: 500"
                        />
                        <PhotoSection 
                            photos={photos.property2} 
                            title="Boardroom2" 
                            description="Capacity: 500"
                        />
                        <div className="flex justify-end mt-4">
                            <button className="py-2 px-3 text-[#0466C8] border border-[#0466C8] rounded-full">Add another room</button>
                        </div>
                    </div>
                )}
            </div>
        );
    };

    return (
        <div className="max-w-3xl mx-auto p-4">
            {steps.map((step) => (
                <Step key={step.number} {...step} />
            ))}
        </div>
    );
};