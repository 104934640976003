import React, { useState } from "react";
import debitcard from "@/assets/shared/credit-card.svg";
import mobilemoney from "@/assets/shared/smart-phone-01.svg";
import paypal from "@/assets/shared/image 94.svg";
import checked from "@/assets/shared/checkmark-circle-02.svg";
import debitcards from "@/assets/shared/image 95.svg";
import mtn from "@/assets/shared/mtn.svg";
import mpesa from "@/assets/shared/mpesa.svg";
import airtel from "@/assets/shared/airtel.svg";
import mastercard from "@/assets/shared/mastercard.svg";
import paypalProvider from "@/assets/shared/paypalprovider.svg";
import paypalbutton from "@/assets/shared/paypalbutton.svg";
import { useDispatch } from "react-redux";
import {
  closePayModal,
  closeCompleteReservation,
  launchCompleteReservation,
} from "@/redux/paynow/PayNowSlice";

const Pay = ({ price, checkIn, checkOut }) => {
  const [selectedOption, setSelectedOption] = useState("debit card");
  const [selectedProvider, setSelectedProvider] = useState(null);
  const dispatch = useDispatch();

  const payOptions = [
    { name: "debit card", image: debitcard },
    { name: "paypal", image: paypal },
    { name: "mobile money", image: mobilemoney },
  ];

  //SELECT A PAY OPTION
  const handleCardClick = (name) => {
    setSelectedOption(name);
  };

  // CLOSE THE FORM
  const handleCloseModal = () => {
    dispatch(closePayModal());
  };

  const PayOptionCard = ({ payOption }) => {
    const isSelected = selectedOption === payOption.name;
    return (
      <div
        onClick={() => handleCardClick(payOption.name)}
        className="space-y-2.5"
      >
        <div
          className={`w-full min-w-[7rem] h-[7.4rem] lg:w-[10.5rem] rounded-[9.5px] border ${
            isSelected ? "border-laara-primary" : "border-[#C0C1C1]"
          } flex items-center justify-center shadow-lg relative cursur-pointer `}
        >
          <img
            src={payOption.image}
            alt={payOption.name}
            className="object-cover"
          />
          <div className="size-6 absolute top-3 left-3">
            {isSelected ? (
              <img
                src={checked}
                alt="checked"
                className="size-6 object-cover"
              />
            ) : (
              <div className="border-[1.78px] border-[#C0C1C1] rounded-full size-6 "></div>
            )}
          </div>
        </div>
        <p className="text-base font-medium capitalize text-center">
          {payOption.name}
        </p>
      </div>
    );
  };

  // selected provider in lowecase
  const selectedprovider = selectedProvider?.toLowerCase();

  const PaymentProvider = ({ providerImg, providerName, providerValue }) => {
    const uniqueId = `payment-provider-${providerValue}`;
    const providername = providerName.toLowerCase();
    return (
      <div
        onClick={() => setSelectedProvider(providerValue)}
        className="max-w-[35.065rem] h-[5.25rem] bg-white p-2 xl:p-4 flex items-center border border-laara-primary rounded-[8px] "
      >
        <div className="max-h-[3.2rem] h-full w-full flex items-center gap-4 ">
          <div className="bg-white h-[3.19rem] w-[3.19rem] flex items-center justify-center rounded-[7.36px] shadow-lg  ">
            <img
              src={providerImg}
              alt="provider icon"
              className="h-[2.4375rem] w-[2.4375rem] rounded-[4px] "
            />
          </div>
          <div className="h-[3.2rem] w-full md:max-w-[28.875rem] flex flex-1 items-center justify-between">
            <label htmlFor={uniqueId} className="flex flex-col justify-between">
              <p
                className={`text-base font-medium ${
                  providername === selectedprovider
                    ? "text-laara-primary"
                    : "text-[#292929]"
                } `}
              >
                {providerName}
              </p>
              <p className="text-base font-medium ">**** **** **** 1234</p>
            </label>

            <input
              type="radio"
              id={uniqueId}
              name="payment-provider"
              COmpleteReservation
              checked={selectedProvider === providerValue}
              value={providerValue}
              onChange={() => setSelectedProvider(providerValue)}
            />
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="w-full md:w-[600px] lg:w-[844px] md:p-4 rounded-[12px] borders borders-[#000000/12] ">
      <div className="space-y-5">
        <p className="text-xl font-semibold text-[#292929] ">Pay with</p>

        {/* PAYMENT METHODS */}
        <div className="flex items-center justify-start md:justify-start gap-2 md:gap-8 ">
          {payOptions.map((payOption) => (
            <PayOptionCard key={payOption.name} payOption={payOption} />
          ))}
        </div>

        <form className="flex flex-col gap-4">
          {/* DEBIT CARD */}
          {selectedOption === "debit card" && (
            <div className="space-y-4">
              <PaymentProvider
                providerImg={mastercard}
                providerName={"Mastercard"}
                providerValue={"mastercard"}
              />
              <p className="text-base font-semibold text-[#292929] ">
                Debit Card
              </p>
              <img src={debitcards} alt="debit cards" className="w-[9.65rem]" />

              <p className="text-base font-semibold text-[#292929] ">
                Add new debit card
              </p>
              <div>
                <input
                  type="text"
                  placeholder="Card holder's name"
                  className="w-full md:w-[34.75rem] p-2 md:p-4 rounded-[12px] border border-[#000000/15] outline-none "
                />
              </div>
              <div>
                <input
                  type="text"
                  placeholder="1234 5678 9012 3456"
                  maxLength="19"
                  className="w-full md:w-[34.75rem] p-2 md:p-4 rounded-[12px] border border-[#000000/15] outline-none "
                />
              </div>
              <div className="flex items-center justify-between gap-2 max-w-[34.75rem]">
                <input
                  type="text"
                  placeholder="MM/YY"
                  className="w-full md:w-[16.75rem] p-2 md:p-4 rounded-[12px] border border-[#000000/15] outline-none "
                />
                <input
                  type="text"
                  placeholder="..."
                  className="w-full md:w-[16.75rem] p-2 md:p-4 rounded-[12px] border border-[#000000/15] outline-none "
                />
              </div>
            </div>
          )}

          {/* PAYPAL */}
          {selectedOption === "paypal" && (
            <div className="space-y-4">
              <PaymentProvider
                providerImg={paypal}
                providerName={"Paypal"}
                providerValue={"paypal"}
              />

              <p className="text-base font-semibold text-[#292929] ">
                Add new mobile money number
              </p>

              <p className="text-base font-normal text-[#292929] ">
                Link your paypal account with the button below
              </p>

              <button className="w-[14.0625rem] h-[2.625] rounded-[4px] ">
                <img
                  src={paypalbutton}
                  alt="paypal button"
                  className="object-cover "
                />
              </button>
            </div>
          )}

          {/* MOBILE MONEY */}
          {selectedOption === "mobile money" && (
            <div>
              <div className=""></div>
              <div className="space-y-4">
                <PaymentProvider
                  providerImg={mtn}
                  providerName={"MTN"}
                  providerValue={"mtn"}
                />
                <PaymentProvider
                  providerImg={mpesa}
                  providerName={"Mpesa"}
                  providerValue={"mpesa"}
                />
                <PaymentProvider
                  providerImg={airtel}
                  providerName={"Airtel Money"}
                  providerValue={"airtel money"}
                />
                <p className="text-base font-medium text-[#292929] ">
                  Add new mobile money number
                </p>
                <div>
                  <input
                    type="text"
                    placeholder="Select network provider"
                    className="w-full md:w-[34.75rem] p-2 md:p-4 rounded-[12px] border border-[#000000/15] outline-none "
                  />
                </div>
                <div>
                  <input
                    type="tel"
                    placeholder="2547123456789"
                    maxLength="12"
                    className="w-full md:w-[34.75rem] p-2 md:p-4 rounded-[12px] border border-[#000000/15] outline-none "
                  />
                </div>
              </div>
            </div>
          )}

          {/* PRICE */}
          <div className="space-y-2">
            <p className="text-laara-primary font-semibold text-2xl">
              KSH {price?.toLocaleString()}
            </p>
            <p className="text-base lg:text-xl text-[#888888] ">
              +US $70.80 taxes and charges
            </p>
            <p className="text-base lg:text-xl text-[#888888] ">
              {checkIn} - {checkOut}
            </p>
          </div>
          {/* MAKE / CANCEL PAY */}
          <div className="flex items-center justify-between gap-2 max-w-[34.75rem]">
            <button
              onClick={() => dispatch(launchCompleteReservation())}
              className="bg-laara-primary rounded-[36px] text-white font-medium py-2 lg:py-4 w-full max-w-[16.25rem] "
            >
              Confirm & pay
            </button>
            <button
              onClick={handleCloseModal}
              className=" text-[#E4626F] font-medium py-2 lg:py-4 w-full max-w-[16.25rem] rounded-[36px] border border-[#E4626F] "
            >
              cancel payment
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Pay;
