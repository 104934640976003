import { useState, useEffect } from "react";
import { IoIosArrowRoundBack } from "react-icons/io";
import { MdCircleNotifications } from "react-icons/md";
import { AiOutlineClockCircle } from "react-icons/ai"; // For loading state
import Profile from "../../assets/Notifications/Notification Logo.svg"

// Default data for notifications with profile photo, title, message, timestamp, and read status
const defaultNotifications = [
  {
    profilePhoto: `${Profile}`,
    title: "Hotel listing update 🏨",
    message: "Check out our new uploaded hotel and listing in the Neyri locations",
    timestamp: "Today",
    isRead: false,
  },
  {
    profilePhoto: `${Profile}`,
    title: "New app update 🚀",
    message: "Check out our new uploaded hotel and airbnb listing in the Neyri locations",
    timestamp: "3 Jul 2024",
    isRead: false,
  },
  {
    profilePhoto: `${Profile}`,
    title: "Refer people today and get reward packages 🎁",
    message: "Check out our new uploaded hotel and airbnb listing in the Neyri locations",
    timestamp: "2 Nov 2024",
    isRead: true,
  },
  // Add more notifications with `isRead` field
];

const Notifications = () => {
  const [notifications, setNotifications] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Simulate fetching data from an API
    setTimeout(() => {
      setNotifications(defaultNotifications);
      setLoading(false);
    }, 1000);
  }, []);

  const handleNotificationClick = (index) => {
    setNotifications(prevNotifications =>
      prevNotifications.map((notif, i) =>
        i === index ? { ...notif, isRead: true } : notif
      )
    );
    // You can add navigation or additional logic here if needed
  };

  return (
    <div className="p-1 h-auto flex flex-col w-[90vw] mx-auto">
      <div className="flex items-center p-2 rounded-md mb-2">
        <a href="#" className="flex items-center ">
          <IoIosArrowRoundBack size={24} className="mr-2 transition-transform duration-300 ease-in-out transform hover:scale-150 " />
          <span className="font-poppins text-xl lg:text-[28px] font-semibold leading-[42px] tracking-[0.005em] text-left ">Notifications</span>
        </a>
      </div>
      {loading ? (
        <div className="flex flex-col items-center justify-center flex-grow text-gray-500">
          <AiOutlineClockCircle size={48} className="mb-4 text-gray-600" />
          <span className="text-lg text-center px-4">Loading Notifications...</span>
        </div>
      ) : notifications.length === 0 ? (
        <div className="flex flex-col h-auto items-center justify-center flex-grow text-gray-500">
          <MdCircleNotifications size={90} color='grey' className="mb-4" />
          <span className="lg:w-[200px] text-base lg:text-lg font-poppins font-semibold text-[hsla(0,0%,16%,1)]">No New Notifications</span>
          <span className="w-[170px] lg:w-[200px] text-center h-[48px] align-center text-xs lg:text-sm text-[hsla(0,0%,53%,1)] font-normal font-poppins">New notifications will be displayed here</span>
        </div>
      ) : (
        <div className="flex flex-col flex-grow">
          {notifications.map((notification, index) => (
            <div
              key={index}
              className={`relative flex flex-col bg-white pb-1 mb-0 w-full ${notification.isRead ? 'bg-gray-100' : 'bg-white'}`} // Change background based on read/unread status
              onClick={() => handleNotificationClick(index)} // Mark as read on click
            >
              <div className="flex items-start p-2 hover:shadow-2xl active:bg-gray-200">
                <img
                  src={notification.profilePhoto}
                  alt="Profile"
                  className="w-12 h-12 rounded-full text-center mr-4 flex-shrink-0"
                />
                <div className="flex-1">
                  <div className="flex justify-between items-center mb-1">
                    <h3 className={`mt-1 font-poppins text-base lg:text-[18px] font-medium leading-[36px] tracking-[0.005em] text-left ${notification.isRead ? 'text-gray-600' : 'text-black'}`}>{notification.title}</h3>
                    {/* Dot for unread notifications */}
                    {!notification.isRead && (
                      <div className="absolute top-1 right-1 w-3 h-3 bg-[hsla(185,100%,47%,1)] rounded-full"></div>
                    )}
                  </div>
                  <p className={`mb-2 font-poppins text-sm lg:text-[16px] font-normal leading-[26px] tracking-[0.0025em] text-left ${notification.isRead ? 'text-gray-500' : 'text-gray-800'}`}>{notification.message}</p>
                  <span className="font-poppins text-sm lg:text-[16px] font-normal leading-[26px] tracking-[0.0025em] text-left text-[hsla(0,0%,53%,1)]">{notification.timestamp}</span>
                </div>
              </div>
              {index < notifications.length - 1 && (
                <hr className="border-gray-300 my-0" />
              )}
            </div>
          ))}
        </div>
      )}
    </div>
  );
}

export default Notifications;
