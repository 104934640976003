import React, { useCallback, useEffect, useState } from "react";
import {
  GoogleMap,
  useJsApiLoader,
  Autocomplete,
  InfoWindowF,
} from "@react-google-maps/api";
import { useSelector, useDispatch } from "react-redux";
import { setCoordinates } from "@/redux/Listing/listingSlice";

const libraries = ["places"];

const GMaps = ({
  containerStyle, // width & height, width: 100%, height: pixels value
  placesAutoComplete, // places auto complete api: true or false
  interactive, // pin location on map: true or false value
  pin, // coordinates to set marker on map
  openMap, // custom props
  setOpenMap, // custom props
}) => {
  const { isLoaded, loadError } = useJsApiLoader({
    googleMapsApiKey: import.meta.env.VITE_GOOGLE_MAPS_API_KEY,
    libraries: libraries,
  });

  const coordinates = useSelector(
    (state) => state.hostListing.formData.address,
  );
  const dispatch = useDispatch();

  const inputStyle = {
    boxSizing: "border-box",
    border: "1px solid transparent",
    width: "240px",
    height: "38px",
    padding: "0 12px",
    borderRadius: "32px",
    boxShadow: "0 2px 6px rgba(0, 0, 0, 0.3)",
    fontSize: "14px",
    outline: "none",
    textOverflow: "ellipsis",
    position: "absolute",
    left: "50%",
    marginLeft: "-120px",
    marginTop: "10px",
  };

  const center = {
    lat: -1.2915285875091675,
    lng: 36.8271134558112,
  };

  const [markerPosition, setMarkerPosition] = useState({
    lat: pin?.coordinates?.latitude || -1.2915285875091675,
    lng: pin?.coordinates?.longitude || 36.8271134558112,
  });
  const [map, setMap] = useState(null);
  const [autocomplete, setAutocomplete] = useState(null);

  const [infoWindowVisible, setInfoWindowVisible] = useState(false);

  const onLoad = useCallback(function callback(map) {
    const bounds = new window.google.maps.LatLngBounds(center);
    map.fitBounds(bounds);

    setMap(map);
  }, []);

  const onUnmount = useCallback(function callback(map) {
    setMap(null);
  }, []);

  const onMapClick = useCallback(
    (event) => {
      if (!interactive) return; // Ensure onMapClick only triggers if interactive is true

      const newPosition = {
        lat: event.latLng.lat(),
        lng: event.latLng.lng(),
      };
      setMarkerPosition(newPosition);
      console.log(newPosition);
      dispatch(setCoordinates(newPosition));
      setInfoWindowVisible(false);
    },
    [dispatch, interactive],
  );

  useEffect(() => {
    if (
      pin?.coordinates &&
      pin?.coordinates?.latitude &&
      pin?.coordinates?.longitude
    ) {
      setMarkerPosition({
        lat: pin.coordinates.latitude,
        lng: pin.coordinates.longitude,
      });
    }
  }, [pin]);

  const onMarkerClick = () => {
    setInfoWindowVisible(!infoWindowVisible);
  };

  const onAutocompleteLoad = (autocompleteInstance) => {
    setAutocomplete(autocompleteInstance);
  };

  const onPlaceChanged = () => {
    if (autocomplete !== null) {
      const place = autocomplete.getPlace();
      const location = place.geometry?.location;

      if (location) {
        const newPosition = {
          lat: location.lat(),
          lng: location.lng(),
        };

        setMarkerPosition(newPosition);
        if (map) {
          map.panTo(location);
        }
      }
    } else {
      console.log("Autocomplete is not loaded yet!");
    }
  };

  if (loadError) {
    return <div>Error loading maps</div>;
  }

  if (!isLoaded) {
    return <div>Loading maps...</div>;
  }

  return (
    <div>
      {isLoaded ? (
        <GoogleMap
          mapContainerStyle={containerStyle}
          center={markerPosition}
          zoom={10}
          options={{
            zoomControl: true,
            mapTypeControl: false,
            streetViewControl: false,
            fullscreenControl: true,
          }}
          onLoad={onLoad}
          onUnmount={onUnmount}
          onClick={interactive && onMapClick}
        >
          {placesAutoComplete && (
            <Autocomplete
              onLoad={onAutocompleteLoad}
              onPlaceChanged={onPlaceChanged}
            >
              <input
                type="text"
                placeholder="Search for a location"
                style={inputStyle}
              />
            </Autocomplete>
          )}
          {markerPosition && (
            <React.Fragment>
              <div
                className="map-marker"
                style={{
                  position: "absolute",
                  transform: "translate(-50%, -100%)",
                  background: "red",
                  borderRadius: "50%",
                  width: "20px",
                  height: "20px",
                  cursor: "pointer",
                }}
                onClick={onMarkerClick}
              ></div>
              {infoWindowVisible && (
                <InfoWindowF
                  position={markerPosition}
                  onCloseClick={() => setInfoWindowVisible(false)}
                >
                  <div>
                    <p>
                      <br />
                      Latitude: {markerPosition.lat.toFixed(6)}
                      <br />
                      Longitude: {markerPosition.lng.toFixed(6)}
                    </p>
                    {!openMap && (
                      <div
                        className="hidden xl:flex hover:underline hover:cursor-pointer text-laara-primary font-medium ml-3"
                        onClick={() => {
                          setOpenMap(true);
                        }}
                      >
                        Show on map
                      </div>
                    )}
                  </div>
                </InfoWindowF>
              )}
            </React.Fragment>
          )}
        </GoogleMap>
      ) : (
        <div className="flex justify-center items-center h-screen">
          <div className="w-16 h-16 border-4 border-t-4 border-gray-200 border-t-blue-500 border-solid rounded-full animate-spin"></div>
        </div>
      )}
    </div>
  );
};

export default GMaps;
