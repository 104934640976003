import axios from "axios";
import { useEffect, useState } from "react";
import { MultiStepForm, Step } from "react-multi-form-custom-fix";

import PropertyName from "./PropertyDetails/PropertyName";
import Language from "./PropertyDetails/Language";
import PropertyDetails from "./PropertyDetails/PropertyDetails";
import PropertyLocation from "@/pages/Listings/PropertyDetails/PropertyLocation";
import HouseRules from "./PropertyDetails/HouseRules";
import PinLocation from "./PropertyDetails/PinLocation";
import Amenities from "./PropertyDetails/Amenities";
import Parking from "./PropertyDetails/Parking";
import SafetyFeatures from "./PropertyDetails/SafetyFeatures";
import Verification from "./PropertyDetails/Verification";
import MealPlans from "./PropertyDetails/MealPlans";
import AccessibilityFeatures from "./PropertyDetails/AccessibilityFeatures";
import RoomDetails from "./RoomDetails/RoomDetails";
import RoomAmmenities from "./RoomDetails/RoomAmmenities";
import Bathroom from "./RoomDetails/Bathroom";
import RoomPhoto from "./RoomDetails/RoomPhoto";
import { Availability } from "./FinalDetails/Availability";
import { Payments } from "./FinalDetails/Payments";
import { Next } from "./FinalDetails/Next";
import { FinalVerification } from "./FinalDetails/FinalVerification";
import Pricing from "./Pricing/Pricing";
import PropertyPhotos from "./Photos/PropertyPhotos";
import Cancellation from "@/pages/Listings/Pricing/Cancellation";
import WeeklyRates from "./Pricing/WeeklyRates";
import WeeklyPlan from "./Pricing/WeeklyPlan";
import {
  nextSubStep,
  prevSubStep,
  setPropertyId,
  setRoomTypeId,
  clearListing,
  setListingType,
} from "@/redux/Listing/listingSlice";
import { useSelector, useDispatch } from "react-redux";
import { baseURL, headers } from "../../constants/apiconfig";
import RoomDetails3 from "@/components/Listings/RoomDetails3";
import Pricing2 from "@/components/Listings/Pricing2";
import { Next2 } from "@/components/Listings/Next2";
import BedroomPhotos from "@/components/Listings/BedroomPhotos";
import { useLocation } from "react-router-dom";
import StaffPhotos from "./Photos/StaffPhotos";
import BRPropertyName from "@/components/Listings/BRPropertyName";
import BRCapacity from "@/components/Listings/BRCapacity";
import BRPropertyDetails from "@/components/Listings/BRPropertyDetails";
import BRPropertyLocation from "@/components/Listings/BRPropertyLocation";
import BRPinLocation from "@/components/Listings/BRPinLocation";
import BRAmenities from "@/components/Listings/BRAmmenities,";
import BRAccessibilityFeatures from "@/components/Listings/BRAccessibilityFeatures";
import { FreeAmenities } from "@/components/Listings/FreeAmmenities";
import { PaidAmenities } from "@/components/Listings/PaidAmmenities";
import BRPhotos from "@/components/Listings/BRPhotos";
import { NextStep1 } from "@/components/Listings/NextStep1";
import { NextStep2 } from "@/components/Listings/NextStep2";
import BRPricing from "@/components/Listings/BRPricing";
import { NextStep3 } from "@/components/Listings/NextStep3";
import { NextStep4 } from "@/components/Listings/NextStep4";
import { NextStep5 } from "@/components/Listings/NextStep5";

const Listing = () => {
  const location = useLocation();
  const { type } = location.state;
  const dispatch = useDispatch();
  const { accessToken } = useSelector((state) => state.user);
  const { mainStep, subStep, formData, propertyId } = useSelector(
    (state) => state.hostListing
  );
  console.log(formData);

  const listingType = formData.type;
  console.log(type, listingType);

  useEffect(() => {
    dispatch(setListingType(type));
  }, [type]);

  const { roomTypeId } = formData.roomType;

  const token = accessToken;

  const [category, setCategory] = useState(type);
  const [info, setInfo] = useState([]);

  console.log(propertyId, roomTypeId);

  const handlePageTitle = () => {
    switch (mainStep) {
      case 1:
        return "Property Details";
      case 2:
        return "Room Details";
      case 3:
        return "Pricing ";
      case 4:
        return "Add Photos";
      case 5:
        return "Final Details";
      default:
        return "Property Details";
    }
  };

  const postProperty = async () => {
    try {
      const response = await axios.post(
        `${baseURL}/properties`,
        {
          name: formData.name,
          description: "",
          type: formData.type,
        },
        {
          headers: {
            ...headers(token),
            "Content-Type": "application/json",
          },
        }
      );
      setInfo(response.data);
      dispatch(setPropertyId(response.data.property.id));
    } catch (error) {
      console.error("Error creating property:", error);
      return;
    }
  };

  const updateDescription = async () => {
    try {
      const dataToUpdate = { description: formData.description };
      await axios.put(`${baseURL}/properties/${propertyId}`, dataToUpdate, {
        headers: headers(token),
      });
    } catch (error) {
      console.error(`Error updating property description:`, error);
      return;
    }
  };

  const postRoomTypeData = async () => {
    try {
      let dataToSend = {};
      dataToSend = {
        roomType: {
          name: formData.roomType.name,
          numRooms: parseInt(formData.roomType.numRooms, 10) || 0, // Default to 0 if NaN
          description: formData.roomType.description,
          bedTypes: formData.roomType.bedTypes,
          maxGuests: formData.roomType.maxGuests,
          numBathrooms: parseInt(formData.roomType.numberOfBathrooms, 10) || 0,
          policies: formData.roomType.policies,
          amenities: formData.roomType.amenities,
          typeSize: parseInt(formData.roomType.typeSize, 10) || 0, // Default to 0 if NaN
        },
      };

      // Conditionally add numBathrooms if type is "VACATION_RENTAL"
      // if (type === "VACATION_RENTAL") {
      //   dataToSend.roomType.numBathrooms =
      //     parseInt(formData.roomType.numberOfBathrooms, 10) || 0; // Default to 0 if NaN
      // }

      console.log(dataToSend);
      const response = await axios.put(
        `${baseURL}/properties/${propertyId}/rooms`,
        dataToSend,
        {
          headers: {
            ...headers(token),
            "Content-Type": "application/json",
          },
        }
      );
      console.log(response.data);
      dispatch(setRoomTypeId(response.data.roomTypeId));
    } catch (error) {
      console.error(`Error creating room on step ${subStep}:`, error);
      return;
    }
  };

  const postRoomPhotos = async () => {
    try {
      // Check if formData.photos is an array
      if (!Array.isArray(formData.roomType.roomphotos)) {
        throw new Error("formData.roomType.roomphotos should be an array");
      }

      // Create a FormData instance
      const formDataToSend = new FormData();

      // Append each file in the photos array to FormData
      formData.roomType.roomphotos.forEach((photo) => {
        if (photo instanceof File) {
          formDataToSend.append("images", photo, photo.name);
        } else {
          console.error(`Item ${photo} is not a File`);
        }
      });

      // Check if there are files to send
      if (formDataToSend.getAll("images").length === 0) {
        console.warn("No valid photo files to upload");
        // Optionally show a message to the user
        alert("No valid photo files to upload.");
        return;
      }

      // Perform the PUT request
      const response = await axios.put(
        `${baseURL}/properties/${propertyId}/room-type/${roomTypeId}/images`,
        formDataToSend,
        {
          headers: {
            ...headers(token),
            "Content-Type": "multipart/form-data",
          },
        }
      );

      console.log("Photos uploaded successfully:", response.data);
    } catch (error) {
      console.error(`Error updating photos on step ${subStep}:`, error);
      // Optionally handle or display error to user
      return;
    }
  };

  const addPropertyPhotos = async () => {
    try {
      // Check if formData.photos is an array
      if (!Array.isArray(formData.photos)) {
        throw new Error("formData.photos should be an array");
      }

      // Create a FormData instance
      const formDataToSend = new FormData();

      // Append each file in the photos array to FormData
      formData.photos.forEach((photo) => {
        // If `photo` is an object with a `file` property, use that
        const file = photo.file instanceof File ? photo.file : null;

        if (file) {
          formDataToSend.append("images", file, file.name);
        } else {
          console.error(`Item ${photo} is not a valid File object`);
        }
      });

      // Check if there are files to send
      if (formDataToSend.getAll("images").length === 0) {
        console.warn("No valid photo files to upload");
        // Optionally show a message to the user
        alert("No valid photo files to upload.");
        return;
      }

      // Perform the PUT request
      const response = await axios.put(
        `${baseURL}/properties/${propertyId}/images`,
        formDataToSend,
        {
          headers: {
            ...headers(token), // Call the headers function with the token
            "Content-Type": "multipart/form-data", // Add the Content-Type for file uploads
          },
        }
      );

      console.log("Photos uploaded successfully:", response.data);
    } catch (error) {
      console.error(`Error updating photos on step ${subStep}:`, error);
      // Optionally handle or display error to user
      return;
    }
  };

  const addPropertyPhotos2 = async () => {
    try {
      // Check if formData.photos is an array
      if (!Array.isArray(formData.photos)) {
        throw new Error("formData.photos should be an array");
      }

      // Create a FormData instance
      const formDataToSend = new FormData();

      // Log formData.photos to check its contents
      console.log("formData.photos:", formData.photos);

      // Append each file in the photos array to FormData
      formData.photos.forEach((photo) => {
        // If `photo` is an object with a `file` property, use that
        const file = photo.file instanceof File ? photo.file : null;

        if (file) {
          formDataToSend.append("images", file, file.name);
        } else {
          console.error(`Item ${photo} is not a valid File object`);
        }
      });

      // Log FormData entries to verify contents
      for (let [key, value] of formDataToSend.entries()) {
        console.log(`${key}:`, value);
      }

      // Check if there are files to send
      if (formDataToSend.getAll("images").length === 0) {
        console.warn("No valid photo files to upload");
        // Optionally show a message to the user
        alert("No valid photo files to upload.");
        return;
      }

      // Perform the PUT request
      const response = await axios.put(
        `${baseURL}/properties/${propertyId}/images`,
        formDataToSend,
        {
          headers: {
            ...headers(token), // Call the headers function with the token
            "Content-Type": "multipart/form-data", // Add the Content-Type for file uploads
          },
        }
      );

      console.log("Photos uploaded successfully:", response.data);
    } catch (error) {
      console.error(`Error updating photos:`, error);
      // Optionally handle or display error to user
      return;
    }
  };

  const handleVacationRentalNextSubStep = async () => {
    // Define endpoints for different main steps
    const endpointMap = {
      1: {
        1: `${baseURL}/properties`,
        2: "/languages",
        3: "/policies",
        4: `/description`, // Note this will be handled differently
        6: "/address",
        8: `/room-type/${roomTypeId}/bathroom`,
        11: "/amenities",
        12: "/accessibility-features",
      },
      2: {
        // 3: `/room-type/${roomTypeId}/pricing`,
      },
      3: {
        // 1: `/properties/${propertyId}/images`,
        // 2: `/properties/${propertyId}/images`,
        // 3: `/properties/${propertyId}/images`,
      },
      // 4: {},
    };

    const endpoint = endpointMap[mainStep]?.[subStep];

    if (mainStep === 1 && subStep === 1) {
      await postProperty();
    } else if (mainStep === 1 && subStep === 4) {
      await updateDescription();
    } else if (mainStep === 1 && subStep === 7) {
      await postRoomTypeData();
    } else if (mainStep === 3 && subStep === 3) {
      await addPropertyPhotos2();
    } else if (endpoint) {
      // Handle other updates
      let dataToUpdate = {};
      console.log(dataToUpdate);
      switch (mainStep) {
        case 1:
          switch (subStep) {
            case 2:
              dataToUpdate = { languages: formData.languages };
              break;
            case 3:
              dataToUpdate = { policies: formData.policies };
              break;
            case 4:
              dataToUpdate = { description: formData.description };
              break;
            case 6:
              dataToUpdate = { address: formData.address };
              break;
            case 8:
              dataToUpdate = { bathroom: formData.roomType.bathroom };
              break;
            case 11:
              dataToUpdate = {
                amenities: formData.amenities,
              };
              break;
            case 12:
              dataToUpdate = {
                accessibilityFeatures: formData.accessibilityFeatures,
              };
              break;
            default:
              break;
          }
          break;

        case 2:
          switch (subStep) {
            case 3:
              dataToUpdate = {
                pricing: formData.pricing,
              };
              break;
            case 4:
              dataToUpdate = {
                bathroom: {
                  isPrivate: formData.roomType.bathroom.isPrivate,
                  amenities: formData.roomType.bathroom.amenities,
                },
              };
              break;
            default:
              break;
          }
          break;

        case 3:
          // Handle updates for mainStep 3
          switch (subStep) {
            case 1:
              dataToUpdate = { pricing: formData.pricing };
              break;

            default:
              break;
          }
          break;

        case 4:
          // Handle updates for mainStep 4
          switch (subStep) {
            case 1:
              dataToUpdate = { photos: formData.photos };
              break;

            default:
              break;
          }
          break;
      }

      try {
        console.log(dataToUpdate);
        await axios.put(
          `${baseURL}/properties/${propertyId}${endpoint}`,
          dataToUpdate,
          {
            headers: {
              ...headers(token),
              "Content-Type": "application/json",
            },
          }
        );
      } catch (error) {
        console.error(`Error updating property on step ${subStep}:`, error);
        return;
      }
    }

    dispatch(nextSubStep());
  };

  const handleNextSubStep = async () => {
    // Define endpoints for different main steps
    const endpointMap = {
      1: {
        1: `${baseURL}/properties`,
        2: "/languages",
        3: "/policies",
        4: `/description`, // Note this will be handled differently
        6: "/address",
        9: "/amenities",
        10: "/guest-verification",
        11: "/meal-plans",
        12: "/accessibility-features",
      },
      2: {
        4: `/room-type/${roomTypeId}/bathroom`,
      },
      3: {
        1: `/properties/${propertyId}/room-type/${roomTypeId}/pricing`,
      },
      4: {
        1: `/properties/${propertyId}/images`,
      },
      // 5: {
      //   // 1: "/availability",
      //   2: "/payments",
      //   3: "/next",
      //   4: "/final-Final",
      // },
    };
    const endpoint = endpointMap[mainStep]?.[subStep];

    if (mainStep === 1 && subStep === 1) {
      await postProperty();
    } else if (mainStep === 1 && subStep === 4) {
      await updateDescription();
    } else if (mainStep === 2 && subStep === 2) {
      await postRoomTypeData();
    } else if (mainStep === 2 && subStep === 3) {
      await postRoomPhotos();
    } else if (mainStep === 3 && subStep === 1) {
      try {
        const dataToUpdate = {
          pricing: formData.pricing,
        };

        console.log(
          "Data to be sent to the API:",
          JSON.stringify(dataToUpdate, null, 2)
        );
        console.log("propertyId:", propertyId);
        console.log("roomTypeId:", roomTypeId);

        const response = await axios.put(
          `${baseURL}/properties/${propertyId}/room-type/${roomTypeId}/pricing`,
          dataToUpdate,
          {
            headers: {
              ...headers(token),
              "Content-Type": "application/json",
            },
          }
        );

        if (response.status === 200) {
          console.log("Pricing data update request successful.");

          if (response.data && response.data.status) {
            console.log("Message from API:", response.data.message);
          } else {
            console.log("Unexpected response structure:", response.data);
          }
        } else {
          console.warn(
            "Unexpected status code. Check API response for details."
          );
        }
      } catch (error) {
        console.error("Error in pricing update:", error);
        if (error.response) {
          console.error("Error response data:", error.response.data);
          console.error("Error response status:", error.response.status);
          console.error("Error response headers:", error.response.headers);
        } else if (error.request) {
          console.error("No response received:", error.request);
        } else {
          console.error("Error in setup:", error.message);
        }
      }
    } else if (mainStep === 3 && subStep === 4) {
      try {
        // Prepare the data for the cancellation policies update
        const dataToUpdate = {
          policies: formData.cancellationPolicies,
        };

        console.log(
          "Data to be sent to the API:",
          JSON.stringify(dataToUpdate, null, 2)
        );
        console.log("propertyId:", propertyId);
        console.log("roomTypeId:", roomTypeId);

        const response = await axios.put(
          `${baseURL}/properties/${propertyId}/policies`,
          dataToUpdate,
          {
            headers: {
              ...headers(token),
            },
          }
        );

        if (response.status === 200) {
          console.log("Cancellation policies update request successful.");

          // Check the response for status and message
          if (response.data && response.data.status) {
            console.log("Message from API:", response.data.message);
          } else {
            console.log("Unexpected response structure:", response.data);
          }
        } else {
          console.warn(
            "Unexpected status code. Check API response for details."
          );
        }
      } catch (error) {
        console.error("Error in cancellation policies update:", error);
        if (error.response) {
          console.error("Error response data:", error.response.data);
          console.error("Error response status:", error.response.status);
          console.error("Error response headers:", error.response.headers);
        } else if (error.request) {
          console.error("No response received:", error.request);
        } else {
          console.error("Error in setup:", error.message);
        }
      }
    } else if (mainStep === 4 && subStep === 1) {
      try {
        if (!Array.isArray(formData.photos)) {
          throw new Error("formData.photos should be an array");
        }

        // Create a FormData instance
        const formDataToSend = new FormData();

        formData.photos.forEach((photo) => {
          const file = photo.file instanceof File ? photo.file : null;

          if (file) {
            formDataToSend.append("images", file, file.name);
          } else {
            console.error(`Item ${photo} is not a valid File object`);
          }
        });

        if (formDataToSend.getAll("images").length === 0) {
          console.warn("No valid photo files to upload");
          alert("No valid photo files to upload.");
          return;
        }

        const response = await axios.put(
          `${baseURL}/properties/${propertyId}/images`,
          formDataToSend,
          {
            headers: {
              ...headers(token),
              "Content-Type": "multipart/form-data",
            },
          }
        );

        console.log("Photos uploaded successfully:", response.data);
      } catch (error) {
        console.error(`Error updating photos on step ${subStep}:`, error);
      }
    } else if (endpoint) {
      // Handle other updates
      let dataToUpdate = {};

      switch (mainStep) {
        case 1:
          switch (subStep) {
            case 2:
              dataToUpdate = { languages: formData.languages };
              break;
            case 3:
              dataToUpdate = { policies: formData.policies };
              break;
            case 6:
              dataToUpdate = { address: formData.address };
              break;
            case 9:
              dataToUpdate = { amenities: formData.amenities };
              break;
            case 10:
              dataToUpdate = {
                guestVerificationMethod: formData.guestVerificationMethod,
              };
              break;
            case 11:
              dataToUpdate = { mealPlans: formData.mealPlans };
              break;
            case 12:
              dataToUpdate = {
                accessibilityFeatures: formData.accessibilityFeatures,
              };
              break;
            default:
              break;
          }
          break;

        case 2:
          switch (subStep) {
            case 3:
              dataToUpdate = {
                roomPhotos: formData.roomType.roomphotos,
              };
              break;
            case 4:
              dataToUpdate = {
                bathroom: {
                  isPrivate: formData.roomType.bathroom.isPrivate,
                  amenities: formData.roomType.bathroom.amenities,
                },
              };
              break;
            default:
              break;
          }
          break;

        case 3:
          // Handle updates for mainStep 3
          switch (subStep) {
            case 1:
              dataToUpdate = { pricing: formData.pricing };
              break;

            default:
              break;
          }
          break;

        case 4:
          // Handle updates for mainStep 4
          switch (subStep) {
            case 1:
              dataToUpdate = { photos: formData.photos };
              break;

            default:
              break;
          }
          break;

        case 5:
          // Handle updates for mainStep 5
          switch (subStep) {
            case 1:
              dataToUpdate = { availability: formData.availability };
              break;
            case 2:
              dataToUpdate = { payments: formData.payments };
              break;
            case 3:
              dataToUpdate = { next: formData.next };
              break;
            case 4:
              dataToUpdate = { finalVerification: formData.finalVerification };
              break;
            default:
              break;
          }
          break;

        default:
          break;
      }

      // Send PUT request to the specific endpoint
      try {
        console.log(dataToUpdate);
        await axios.put(
          `${baseURL}/properties/${propertyId}${endpoint}`,
          dataToUpdate,
          {
            headers: {
              ...headers(token),
              "Content-Type": "application/json",
            },
          }
        );
      } catch (error) {
        console.error(`Error updating property on step ${subStep}:`, error);
        return;
      }
    }

    dispatch(nextSubStep());
  };

  const renderFormComponent = () => {
    switch (category) {
      case "HOTEL":
        return renderHotelCategory();
      case "VACATION_RENTAL":
        return renderVacationRentalCategory();
        case "BOARD_ROOM":
        return renderBoardroomCategory();
      default:
        return renderHotelCategory();
    }
  };

  const renderHotelCategory = () => {
    switch (mainStep) {
      case 1:
        switch (subStep) {
          case 1:
            return <PropertyName />;
          case 2:
            return <Language />;
          case 3:
            return <HouseRules />;
          case 4:
            return <PropertyDetails />;
          case 5:
            return <PropertyLocation />;
          case 6:
            return <PinLocation />;
          case 7:
            return <Amenities />;
          case 8:
            return <Parking />;
          case 9:
            return <SafetyFeatures />;
          case 10:
            return <Verification />;
          case 11:
            return <MealPlans />;
          case 12:
            return <AccessibilityFeatures />;
          default:
            return null;
        }
      // Add cases for other main steps as needed
      case 2:
        switch (subStep) {
          case 1:
            return <RoomDetails />;
          case 2:
            return <RoomAmmenities />;
          case 3:
            return <RoomPhoto />;
          case 4:
            return <Bathroom />;
          default:
            return null;
        }
      // Add cases for other main steps as needed

      case 3:
        switch (subStep) {
          case 1:
            return <Pricing />;
          case 2:
            return <WeeklyRates />;
          case 3:
            return <WeeklyPlan />;
          case 4:
            return <Cancellation />;
          default:
            return null;
        }
      // Add cases for other main steps as needed

      case 4:
        switch (subStep) {
          case 1:
            return <PropertyPhotos />;
          default:
            return null;
        }
      // Add cases for other main steps as needed

      case 5:
        switch (subStep) {
          case 1:
            return <Availability />;
          case 2:
            return <Payments />;
          case 3:
            return <Next />;
          case 4:
            return <FinalVerification />;
          default:
            return null;
        }
      // Add cases for other main steps as needed
      default:
        return null;
    }
  };

  const renderVacationRentalCategory = () => {
    switch (mainStep) {
      case 1:
        switch (subStep) {
          case 1:
            return <PropertyName />;
          case 2:
            return <Language />;
          case 3:
            return <HouseRules />;
          case 4:
            return <PropertyDetails />;
          case 5:
            return <PropertyLocation />;
          case 6:
            return <PinLocation />;
          case 7:
            return <RoomDetails3 category={type} />;
          case 8:
            return <Bathroom />;
          case 9:
            return <Amenities />;
          case 10:
            return <Parking />;
          case 11:
            return <SafetyFeatures />;
          case 12:
            return <AccessibilityFeatures />;
          default:
            return null;
        }
      // Add cases for other main steps as needed
      case 2:
        switch (subStep) {
          case 1:
            return <Pricing2 />;
          case 2:
            return <WeeklyRates />;
          case 3:
            return <WeeklyPlan />;
          case 4:
            return <Cancellation />;
          default:
            return null;
        }
      // Add cases for other main steps as needed

      case 3:
        switch (subStep) {
          case 1:
            return <BedroomPhotos />;
          case 2:
            return <PropertyPhotos />;
          case 3:
            return <StaffPhotos />;
          default:
            return null;
        }
      // Add cases for other main steps as needed
      case 4:
        switch (subStep) {
          case 1:
            return <Payments />;
          case 2:
            return <Next2 />;
          case 3:
            return <FinalVerification />;
          default:
            return null;
        }
      // Add cases for other main steps as needed
      default:
        return null;
    }
  };

  const renderBoardroomCategory = () => {
    switch (mainStep) {
      case 1:
        switch (subStep) {
          case 1:
            return <BRPropertyName />;
          case 2:
            return <BRCapacity />;
          case 3:
            return <Language />;
          case 4:
            return <BRPropertyDetails />;
          case 5:
            return <BRPropertyLocation />;
          case 6:
            return <BRPinLocation />;
          case 7:
            return <BRAmenities/>;
          case 8:
            return <Parking />;
          case 9:
            return <SafetyFeatures />;
          case 10:
            return <Verification />;
          case 11:
            return <BRAccessibilityFeatures />;
          case 12:
            return <NextStep1 />;
          default:
            return null;
        }
      // Add cases for other main steps as needed
      case 2:
        switch (subStep) {
          case 1:
            return <FreeAmenities />
          case 2:
            return <PaidAmenities />
          case 3:
            return <BRPhotos />
          case 4:
            return <NextStep2 />
          default:
            return null;
        }
      // Add cases for other main steps as needed

      case 3:
        switch (subStep) {
          case 1:
            return <BRPricing />
          case 2:
            return <Cancellation />
          case 3:
            return <NextStep3 />
          default:
            return null;
        }
      // Add cases for other main steps as needed

      case 4:
        switch (subStep) {
          case 1:
            return <PropertyPhotos />;
          case 2:
            return <NextStep4 />;
          default:
            return null;
        }
      // Add cases for other main steps as needed

      case 5:
        switch (subStep) {
          case 1:
            return <Payments />;
          case 2:
            return <NextStep5 />;
          default:
            return null;
        }
      // Add cases for other main steps as needed

      case 6:
        switch (subStep) {
          case 1:
            return <FinalVerification />;
          default:
            return null;
        }
      // Add cases for other main steps as needed
      default:
        return null;
    }
  };

  return (
    <section className="pt-5">
      <p className="px-5 md:px-10 text-xl font-medium">
        {handlePageTitle(mainStep)}
      </p>
      <div className="w-full h-[1px] bg-[#888888] my-5"></div>
      <div className="xl:max-w-[880px] px-5 md:px-10 py-5">
        {formData.type === "VACATION_RENTAL" ? (
          <MultiStepForm
            className="w-10"
            activeStep={mainStep}
            accentColor={"#0466C8"}
          >
            <Step label="Property details">{renderFormComponent()}</Step>
            <Step label="Pricing">{renderFormComponent()}</Step>
            <Step label="Add photos">{renderFormComponent()}</Step>
            <Step label="Final details">{renderFormComponent()}</Step>
          </MultiStepForm>
        ) : (
          <MultiStepForm
            className="w-10"
            activeStep={mainStep}
            accentColor={"#0466C8"}
          >
            <Step label="Property details">{renderFormComponent()}</Step>
            <Step label="Room details">{renderFormComponent()}</Step>
            <Step label="Pricing">{renderFormComponent()}</Step>
            <Step label="Add photos">{renderFormComponent()}</Step>
            <Step label="Final details">{renderFormComponent()}</Step>
          </MultiStepForm>
        )}

        <div className="mt-10 flex items-center gap-7 w-full max-w-[733px]">
          {(mainStep !== 1 || subStep !== 1) && (
            <button
              className="flex h-12 flex-col justify-center px-16 py-5 my-auto text-base text-laara-primary border border-laara-primary border-solid rounded-[36px] max-md:px-5"
              onClick={() => dispatch(prevSubStep())}
            >
              Previous
            </button>
          )}
          {(mainStep !== 5 || subStep !== 4) && (
            <button
              className="grow h-12 px-3.5 justify-items-center text-lg font-medium text-white bg-laara-primary rounded-[36px] max-md:px-5"
              onClick={
                type === "HOTEL"
                  ? handleNextSubStep
                  : type === "VACATION_RENTAL"
                  ? handleVacationRentalNextSubStep
                  : handleNextSubStep
              }
            >
              Next
            </button>
          )}

          {mainStep === 5 && subStep === 4 && (
            <button
              onClick={() => {
                handleNextSubStep();
                dispatch(clearListing());
              }}
              className="grow h-12 px-3.5 justify-items-center text-lg font-medium text-white bg-laara-primary rounded-[36px] max-md:px-5"
            >
              Submit
            </button>
          )}
        </div>
      </div>
    </section>
  );
};

export default Listing;
