import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { setMealPlans } from "@/redux/Listing/listingSlice";
import { Checkbox } from "@/components/ui/checkbox";

const MealPlans = () => {
  const dispatch = useDispatch();
  const selectedMealPlans = useSelector(
    (state) => state.hostListing.formData?.mealPlans || []
  );

  const mealPlans = [
    "all inclusive",
    "bed & breakfast",
    "half board",
    "full board",
    "bed only",
  ];

  const handleToggleMealPlans = (isChecked, name) => {
    // const { name } = e.target;
    const existingPlan = selectedMealPlans.find((plan) => plan.name === name);

    let updatedMealPlans;

    if (existingPlan) {
      // Remove the meal plan if it's already selected
      updatedMealPlans = selectedMealPlans.filter((plan) => plan.name !== name);
    } else {
      // Add the new meal plan
      updatedMealPlans = [
        ...selectedMealPlans,
        { name: name, description: name }, // You can set a default description or customize it
      ];
    }

    dispatch(setMealPlans(updatedMealPlans));
  };

  return (
    <form>
      <div>
        <h4 className=" font-medium text-xl ">
          Please select the meal plans you will have for your guest
        </h4>
      </div>

      <div className="mt-5 border border-[#A3A3A3] rounded-[6px] max-w-[733px] w-full py-5 px-4 space-y-3 ">
        {mealPlans.map((item, index) => {
          const isChecked = selectedMealPlans?.some(
            (meal) => meal.name === item
          );
          return (
            <div key={index} className="flex items-center gap-2">
              <Checkbox
                id={item}
                name={item}
                className="size-6 rounded-lg"
                checked={selectedMealPlans?.some((meal) => meal.name === item)}
                onCheckedChange={(isChecked) =>
                  handleToggleMealPlans(isChecked, item)
                }
              />
              <label
                htmlFor={item}
                className={`capitalize text-base ${
                  isChecked && "text-laara-primary"
                }`}
              >
                {item}
              </label>
            </div>
          );
        })}
      </div>
    </form>
  );
};

export default MealPlans;
