import React, { useState } from 'react';
import { MessageList } from './MessageList';
import { MessageInput } from './MessageInput';
import Person1 from "@/assets/message/Person1.png";
import Person2 from "@/assets/message/Person2.png";
import Profile1 from "@/assets/message/Profile1.svg";
import PhoneIcon from "@/assets/message/Phone.svg";
import VideoIcon from "@/assets/message/Video.svg";

export const ChatArea = ({ selectedUser, messages, onSendMessage }) => {
  const [newMessage, setNewMessage] = useState('');

  if (!selectedUser) {
    return <div className="flex-1 flex items-center justify-center">Select a user to start chatting</div>;
  }

  const userImage = selectedUser.includes('Alex') ? Person1 : Person2;

  // Handle sending a message
  const handleSendMessage = () => {
    if (newMessage.trim()) {
      onSendMessage(newMessage);
      setNewMessage('');
    }
  };

  return (
    <div className="flex-1 flex flex-col w-16 md:w-32 lg:w-48">
      <div className="flex items-center justify-between p-4 border-b">
        <div className="flex items-center overflow-hidden">
          <div className="relative w-10 h-10 mr-3 flex-shrink-0">
            <img src={userImage} alt={selectedUser} className="w-10 h-10 rounded-full" />
            <span className="absolute bottom-0 right-0 block w-3 h-3 bg-blue-500 rounded-full border-2 border-white"></span>
          </div>

          <div className="overflow-hidden">
            <h2 className="font-normal text-lg truncate">{selectedUser}</h2>
            <p className="text-sm text-gray-600 flex items-center truncate">
              <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 mr-1 flex-shrink-0" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 2C8.686 2 6 4.686 6 8c0 4.934 6 12 6 12s6-7.066 6-12c0-3.314-2.686-6-6-6zm0 8.5a2.5 2.5 0 110-5 2.5 2.5 0 010 5z" />
              </svg>
              <span className="text-[14px] truncate">Kilimani</span>
            </p>
          </div>
        </div>

        <div className="flex space-x-7 flex-shrink-0">
          <div className="flex items-center justify-center h-8 w-8 rounded-full">
            <button>
              <img src={PhoneIcon} alt="Call" />
            </button>
          </div>
          <div className="flex items-center justify-center h-8 w-8 rounded-full">
            <button>
              <img src={VideoIcon} alt="Video" />
            </button>
          </div>
        </div>
      </div>

      <div className="flex-1 overflow-hidden">
        <div className="h-full overflow-y-auto p-4">
          <div className="w-full max-w-[900px] px-4">
            {messages.map((msg, index) => (
              <MessageList
                key={index}
                sender={msg.name === 'Me' ? 'me' : 'them'}
                content={msg.message}
                className="text-base font-normal"
                senderImage={msg.name === 'Me' ? Profile1 : userImage}
              />
            ))}
          </div>
        </div>
      </div>

      <div className="flex-shrink-0">
        <MessageInput
          value={newMessage}
          onChange={(e) => setNewMessage(e.target.value)}
          onSend={handleSendMessage}
        />
      </div>
    </div>
  );
};
