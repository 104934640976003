import { Link } from "react-router-dom";
import error404 from "@/assets/error/error404.svg";

const Error404 = () => {
  return (
    <div className="flex flex-col items-center justify-center h-[70dvh] w-full max-w-[1264px] px-2 mx-auto text-center">
      <img src={error404} alt="error 404 icon" className="size-[200px] " />

      <h3 className="text-2xl font-medium mt-4 text-[#292929] ">
        We are sorry.
      </h3>
      <p className="text-base text-[#888888]  ">
        We cant find the page you are looking for.
      </p>
      <Link
        to="/"
        className=" bg-laara-primary text-white text-base py-2 px-8 mt-4 rounded-full"
      >
        Return back to home page
      </Link>
    </div>
  );
};

export default Error404;
