import { useState, useEffect, useRef } from "react";
import { format } from 'date-fns';
import DateOverlay from "./DateOverlay";
import GuestOverlay from "./GuestOverlay";
import arrowleft from '../../assets/shared/arrowleft.svg';
import Wishes from "./Wishes";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { baseURL, headers } from "@/constants/apiconfig";
import { useSelector } from "react-redux";

export default function AllWishes() {
  const [showDateOverlay, setShowDateOverlay] = useState(false);
  const [showGuestOverlay, setShowGuestOverlay] = useState(false);
  const DateoverlayRef = useRef(null);
  const GuestoverlayRef = useRef(null);
  const navigate = useNavigate();
  const { name } = useParams(); // Get wishlist name from route params
  const [date, setDate] = useState(null);
  const [totalGuests, setTotalGuests] = useState(3);
  const { currentUser, accessToken } = useSelector((state) => state.user);  
  const token = currentUser?.stsTokenManager?.accessToken || accessToken;
  const [wishlists, setWishlists] = useState([]);
  
  const handleButtonClick = () => {
    setShowDateOverlay((prevShowDateOverlay) => !prevShowDateOverlay);
  };

  const handleGuestClick = () => {
    setShowGuestOverlay((prevShowGuestOverlay) => !prevShowGuestOverlay);
  };

  const handleClickOutside = (event) => {
    if (DateoverlayRef.current && !DateoverlayRef.current.contains(event.target)) {
      setShowDateOverlay(false);    
    }
    if (GuestoverlayRef.current && !GuestoverlayRef.current.contains(event.target)) {
      setShowGuestOverlay(false);
    }
  };

  const updateTotalGuests = (adults, children, infants) => {
    const total = adults + children + infants;
    setTotalGuests(total); // Update total guests displayed on the button
  };

  // Fetch specific wishlist details by name
  const fetchWishlistByName = async () => {
    try {
      const response = await axios.get(`${baseURL}/users/wishlist/named`, {
        headers: headers(token),
      });
      console.log(response.data); // Log the response data
      setWishlists(response.data.wishlist); // Ensure this is the correct path
    } catch (error) {
      console.error("Error fetching wishlist by name", error);
    }
  };
  

  useEffect(() => {
      fetchWishlistByName();
  }, []);

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <section className="mx-auto max-w[1440px]">
      <section className="md:max-w-[1264px] w-full mx-auto space-y-7 px-5 py-[60px] ">
        <div className="flex flex-col mt-10 max-w-full">
          <div className="flex gap-5 w-full md:w-[250px]">
            <button className="flex justify-center items-center" onClick={() => navigate(-1)}>
              <img
                loading="lazy"
                src={arrowleft}
                className="w-8 aspect-square"
              />
            </button>
            <span className="flex-auto text-3xl font-semibold leading-10 text-zinc-600">
              {name ? `Wishlist: ${name}` : 'All Wishes'}
            </span>
          </div>
          <div className="flex gap-5 items-start mt-7 max-w-full leading-[120%] w-full max-md:flex-wrap">
            <div className="flex flex-col grow shrink-0 justify-start mt-2.5 basis-0 w-fit">
              <div className="text-2xl sm:text-xl md:text-2xl font-medium text-sky-600">
                {name ? name : "Wishlist"}
              </div>
              <div className="mt-1 text-lg text-neutral-400">Saved stays</div>
            </div>
            <div className="flex flex-auto gap-5 justify-center text-xl text-zinc-500">
              <div className="flex flex-col grow shrink-0 basis-0 md:w-1" ref={DateoverlayRef}>
                <button onClick={handleButtonClick} className="w-full justify-center px-4 py-3.5 rounded-3xl border border-solid border-zinc-500">
                  {date?.from ? (
                    date.to ? (
                      <>
                        {format(date.from, "LLL dd, y")} -{" "}
                        {format(date.to, "LLL dd, y")}
                      </>
                    ) : (
                      format(date.from, "LLL dd, y")
                    )
                  ) : (
                    <span>Pick a date</span>
                  )}
                </button>
                <div>
                  {showDateOverlay && <DateOverlay date={date} setDate={setDate} />}
                </div>
              </div>
              <div className="flex flex-col justify-center w-36 md:w-40" ref={GuestoverlayRef}>
                <button onClick={handleGuestClick} className="justify-end px-4 py-3.5 rounded-3xl border border-solid border-zinc-500">
                  {totalGuests} Guests
                </button>
                <div>
                  {showGuestOverlay && <GuestOverlay updateTotalGuests={updateTotalGuests} />}
                </div>             
              </div>
            </div>
          </div>
          <div className="mt-9 max-md:mr-2 max-md:max-w-full">
            <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-4 gap-5">
              {wishlists.map((wish, index) => (
                <Wishes key={index} wishes={wish} />
              ))}
            </div>
          </div>
        </div>
      </section>
    </section>
  );
}
