import { useState, useEffect } from "react";
import { auth, provider } from "@/firebase.config";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { logOutUserSuccess, signInSuccess } from "@/redux/user/userSlice";
// import logo from "@/assets/navbar/logo.png";
import logo from "@/assets/shared/Group.svg";
import Drawer from "./Drawer";
import mobilemenu from "@/assets/navbar/mobilemenu.jpg";
import MobileDrawer from "./MobileDrawer";
import Authenticated from "./Authenticated";
import Unauthenticated from "./Unauthenticated";

const Navbar = () => {
  const dispatch = useDispatch();
  const [openDrawer, setOpenDrawer] = useState(false);
  const [openMobileDrawer, setOpenMobileDrawer] = useState(false);
  const isLoggedIn = useSelector((state) => state.user.isAuthenticated);

  // Listen to auth state changes
  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((currentUser) => {
      if (currentUser) {
        // Dispatch sign-in success action with the  curernt user data
        dispatch(signInSuccess());
      } else {
        // Dispatch logout action
        dispatch(logOutUserSuccess());
      }
    });
    // Clean up subscription
    return () => unsubscribe();
  }, [dispatch]);

  return (
    <header className="w-full max-w-[1440px] mx-auto p-5 md:px-10 ">
      <nav className="max-w-[1264px] w-full flex items-center justify-between gap-2.5 mx-auto relative  ">
        <Link to="/landingpage">
          <img src={logo} alt="logo" className="w-[80px] " />
        </Link>

        {/* MOBILE SCREENS */}
        <div
          onClick={() => setOpenMobileDrawer(true)}
          className="xl:hidden cursor-pointer"
        >
          <img src={mobilemenu} alt="menu" className="size-9 md:size-[45px]" />
        </div>

        {/* LARGE SCREENS */}

        <div className="hidden xl:flex">
          {isLoggedIn ? (
            <Authenticated
              isLoggedIn={isLoggedIn}
              setOpenDrawer={setOpenDrawer}
              openDrawer={openDrawer}
            />
          ) : (
            <Unauthenticated />
          )}
        </div>

        {/*Large screen navbar drawer  */}
        {openDrawer && (
          <div className="absolute top-[70px] right-0 z-[90] ">
            <Drawer setOpenDrawer={setOpenDrawer} />
          </div>
        )}
      </nav>

      {/* background overlay */}
      {openDrawer && (
        <div
          onClick={() => setOpenDrawer(false)}
          className="hidden xl:block absolute top-0 bottom-0 left-0 right-0 xl:z-[70] "
        ></div>
      )}

      {/* Mobile Navbar */}
      {openMobileDrawer && (
        <div className=" bg-white absolute top-0 right-0 bottom-0 left-0 h-full w-full z-20 ">
          <MobileDrawer
            auth={isLoggedIn}
            setOpenMobileDrawer={setOpenMobileDrawer}
            setOpenDrawer={setOpenDrawer}
            openDrawer={openDrawer}
          />
        </div>
      )}
    </header>
  );
};

export default Navbar;
