import { Link, useNavigate } from "react-router-dom";
import { auth } from "@/firebase.config";
import { useDispatch, useSelector } from "react-redux";
import {
  logOutUserFailed,
  logOutUserStart,
  logOutUserSuccess,
} from "@/redux/user/userSlice";
import { Avatar, AvatarFallback, AvatarImage } from "@/components/ui/avatar";

const Drawer = ({ setOpenDrawer }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const currentUser = useSelector((state) => state.user);

  const guestLinks = [
    {
      label: "profile",
      href: "/profile",
    },
    {
      label: "explore",
      href: "/explore",
    },

    {
      label: "ruby",
      href: "/ruby",
    },

    {
      label: "wishlists",
      href: "/wishlist",
    },
    {
      label: "reservations",
      href: "/reservations",
    },
    {
      label: "updates",
      href: "/updates",
    },
  ];

  const hostLinks = [
    {
      label: "profile",
      href: "/profile",
    },
    {
      label: "messages",
      href: "/messages",
    },

    {
      label: "my properties",
      href: "/my-properties",
    },

    {
      label: "manage bookings",
      href: "/bookings",
    },
    {
      label: "my clanedar",
      href: "/calendar",
    },
    {
      label: "updates",
      href: "/updates",
    },
    {
      label: "ruby",
      href: "/ruby",
    },
  ];

  const commonLinks = [
    {
      label: "preferences",
      href: "/preferences",
    },
    {
      label: "settings",
      href: "/settings",
    },
    {
      label: "help & support",
      href: "/support",
    },
  ];

  const handleLogout = async () => {
    try {
      setOpenDrawer(false);
      // start logout process
      dispatch(logOutUserStart());
      // signout the user with firebase
      await auth.signOut();
      dispatch(logOutUserSuccess());
      navigate("/landingpage");
    } catch (error) {
      // Dispatch logout failure action with the error
      dispatch(logOutUserFailed(error));
    }
  };

  return (
    <>
      {/* DESKTOP */}
      <div className="hidden bg-white w-full xl:w-[300px] xl:border xl:rounded-[20px] xl:flex flex-col items-start gap-3 p-5 ">
        <div className="flex items-center gap-3 ">
          <Avatar className="cursor-pointer size-[48px]">
            <AvatarImage src={currentUser?.avatar} alt="avatar" />
            <AvatarFallback>CN</AvatarFallback>
          </Avatar>
          <div>
            <p className="text-[#0A0A0B] text-sm">{currentUser?.userEmail}</p>
            <p className="text-[#9B9B9B] text-sm">Host</p>
          </div>
        </div>
        {hostLinks.map((link) => (
          <Link
            key={link.label}
            to={link.href}
            onClick={() => setOpenDrawer(false)}
            className={`text-[#0A0A0B] text-base capitalize  `}
          >
            {link.label}
          </Link>
        ))}
        {/* divider */}
        <div className="w-full h-[1px] bg-[#9B9B9B]  "></div>
        {commonLinks.map((link) => (
          <Link
            key={link.label}
            to={link.href}
            onClick={() => setOpenDrawer(false)}
            className={`text-[#0A0A0B] text-base capitalize  `}
          >
            {link.label}
          </Link>
        ))}

        <button
          onClick={() => handleLogout()}
          className="text-base text-[#8C1823] "
        >
          Log Out
        </button>
      </div>
      {/* MOBILE */}
      <div className="xl:hidden bg-white flex flex-col items-start gap-3">
        <div className="flex items-center gap-3 ">
          <Avatar className="cursor-pointer size-[48px]">
            <AvatarImage src={currentUser?.avatar} alt="avatar" />
            <AvatarFallback>CN</AvatarFallback>
          </Avatar>
          <div>
            <p className="text-[#0A0A0B] text-sm">{currentUser?.userEmail}</p>
            <p className="text-[#9B9B9B] text-sm">Host</p>
          </div>
        </div>
        {guestLinks.map((link) => (
          <Link
            key={link.label}
            to={link.href}
            onClick={() => setOpenDrawer(false)}
            className={`text-[#888888] capitalize flex items-center gap-7 xl:gap-2 hover:text-laara-primary  ease-out duration-300 `}
          >
            {link.label}
          </Link>
        ))}

        {/* divider */}
        <div className="w-full h-[1px] bg-[#9B9B9B]  "></div>
        {commonLinks.map((link) => (
          <Link
            key={link.label}
            to={link.href}
            onClick={() => setOpenDrawer(false)}
            className={`text-[#888888] text-sm capitalize flex items-center gap-7 xl:gap-2 hover:text-laara-primary ease-out duration-300 `}
          >
            {link.label}
          </Link>
        ))}
        <button
          onClick={() => handleLogout()}
          className="text-base text-[#8C1823]"
        >
          Log Out
        </button>
      </div>
    </>
  );
};

export default Drawer;
