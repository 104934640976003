import { useState, useEffect } from "react";
import { useNavigate } from 'react-router-dom';
import DropdownButton from "@/components/Settings/Dropdown"
import CurrencyChanger from "./Currency";
import LanguageChanger from "./Language";
import back from "../../assets/settings/back.svg";
const Account = () => {
  const [activeTab, setActiveTab] = useState('Account');
  const navigate = useNavigate();

  const handleBackClick = () => {
    navigate(-1);  
  };
  useEffect(() => {
    if (activeTab === 'Financial Information') {
      navigate('/financesettings');
    }
    if (activeTab === 'Privacy & Security') {
      navigate('/privacy');
    }
    if (activeTab === 'User Preferences') {
      navigate('/preference');
    }
  }, [activeTab, navigate]);
  const renderContent = () => {
    switch (activeTab) {
      case 'Account':
        return (
          <div className="flex flex-col pb-20 bg-white">
          <div className="flex flex-col self-center mt-0 w-full max-w-[1440px] max-md:mt-10 max-md:max-w-full">
            <div className="flex flex-col mt-11 max-md:mt-0 max-md:mr-2 max-md:max-w-full">
              <div className="flex flex-col justify-center leading-[120%] max-md:max-w-full">
                <div className="flex gap-5 max-md:flex-wrap">
                  <div className="flex flex-col flex-1 max-md:max-w-full">
                    <div className="text-2xl font-medium text-neutral-600 max-md:max-w-full">
                      Edit Profile
                    </div>
                    <div className="mt-2.5 text-lg text-zinc-500 max-md:max-w-full">
                    Make edits and updates to your account details
                    </div>
                  </div>
                  <DropdownButton/>
                </div>
              </div>
              <CurrencyChanger/>
              <LanguageChanger/>
            </div>
          </div>
        </div>
        );
      case 'Financial Information':
        return ;
      case 'Privacy & Security':
        return ;
      case 'User Preferences':
        return  ;
      default:
        return null;
    }
  };

  return (
    <div className="flex flex-col pb-10 ">
      <div className="flex flex-col self-center mt-0 w-full max-w-[1440px] max-md:mt-10 max-md:max-w-full">
        <div className="flex flex-col px-20 mt-16 w-full leading-[120%] max-md:px-5 max-md:mt-10 max-md:max-w-full">
          <div className="flex flex-col max-md:max-w-full">
            <div className="flex flex-col leading-[120%] max-md:max-w-full">
        <button 
         onClick={handleBackClick}
        className="flex gap-3 self-start pr-5 text-4xl font-bold whitespace-nowrap text-neutral-600 max-md:ml-2">
          <img
            loading="lazy"
            src={back}
            className="shrink-0 my-auto w-9 aspect-square"
            alt="Settings Icon"
          />
          <span>Settings</span>
        </button>
        <div className="mt-5 flex flex-col w-full">
          <div className="flex gap-2 text-xl rounded-xl text-zinc-500 max-md:flex-wrap max-md:mr-2 max-md:max-w-full">
            {['Account', 'Financial Information', 'Privacy & Security', 'User Preferences'].map((tab) => (
              <div
                key={tab}
                onClick={() => setActiveTab(tab)}
                className={`justify-center items-center p-5 whitespace-nowrap max-md:px-5 cursor-pointer w-full ${
                  activeTab === tab ? 'font-medium text-sky-600 border-b border-sky-600 border-solid' : 'bg-neutral-200 bg-opacity-40'
                }`}
              >
                {tab}
              </div>
            ))}
          </div>
          <div className="flex-grow mt-5 pb-5 w-full">
            {renderContent()}
          </div>
        </div>
      </div>
      </div>
      </div>
      </div>
      </div>
    
  );
};

export default Account;
