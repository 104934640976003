import { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { fetchHostProperties, getToken } from "@/api";
import PropertyCard from "@/components/HostProperties/PropertyCard";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLessThan, faSearch } from "@fortawesome/free-solid-svg-icons";
import "./HostProperties.css";
import { getHostId } from "@/api";
const HostProperties = () => {
  const [selectedStatus, setSelectedStatus] = useState(true);
  const [selectedType, setSelectedType] = useState("ALL");
  const [propertyList, setPropertyList] = useState([]);
  const [hasProperties, setHasProperties] = useState(true);
  // eslint-disable-next-line no-unused-vars
  const [showDrafts, setShowDrafts] = useState(false);
  const [publishedCount, setPublishedCount] = useState(0);
  const [draftCount, setDraftCount] = useState(0);
  const navigate = useNavigate();
  const userId = getHostId();
  const token = getToken();

  useEffect(() => {
    const fetchProperties = async () => {
      try {
        const data = await fetchHostProperties(userId, token);

        const fetchedProperties = data.map((item) => item.property);

        setHasProperties(fetchedProperties.length > 0);

        // Count published and draft properties
        const publishedProperties = fetchedProperties.filter(
          (property) => property.verified
        ).length;
        const draftProperties = fetchedProperties.filter(
          (property) => !property.verified
        ).length;

        setPublishedCount(publishedProperties);
        setDraftCount(draftProperties);

        const hasPublishedProperties = fetchedProperties.some(
          (property) => property.verified
        );

        if (!hasPublishedProperties) {
          setSelectedStatus(false);
          setShowDrafts(true);
        } else {
          setSelectedStatus(true);
          setShowDrafts(false);
        }

        setPropertyList(fetchedProperties);

        // Log the parsed property data
      } catch (error) {
        setHasProperties(false);
        throw new Error("Error fetching properties:", error);
      }
    };

    fetchProperties();
  }, [userId, token]);

  const filteredProperties = propertyList.filter((property) => {
    const matchesStatus = property.verified === selectedStatus;
    const matchesType =
      selectedType === "ALL" || property.type === selectedType;

    return matchesStatus && matchesType;
  });

  const goToProperty = (propertyId) => {
    navigate(`/property/${propertyId}`);
  };

  const goToListings = () => {
    navigate("/listproperty");
  };

  const goToPublished = () => {
    setSelectedStatus(true);
    setShowDrafts(false);
  };

  const goToDrafts = () => {
    setSelectedStatus(false);
    setShowDrafts(true);
  };

  const goToPromotions = () => {
    navigate("/Promo");
  };

  const handleTypeChange = (type) => {
    setSelectedType(type);
  };

  return (
    <div className="bg-base-white p-8">
      <section className="top h-16 flex items-center p-2 gap-3">
        <div className="text-lg text-gray-600 w-[700px]">
          <Link
            to={`/host-profile/${userId}`}
            className="flex items-center gap-4 "
          >
            <FontAwesomeIcon icon={faLessThan} className="text-base" />
            <h3>My Properties</h3>
          </Link>
        </div>
        <div className="h-12 flex items-center gap-2 w-[300px] p-1 bg-base-white border border-borderColor-custom-gray rounded-lg">
          <FontAwesomeIcon
            icon={faSearch}
            className="text-base text-gray-600 ml-2"
          />
          <input
            type="search"
            placeholder="Search Properties"
            className="outline-none p-2 w-full bg-base-white text-[13px]"
          />
        </div>
        <div className="w-[195px] text-center h-12 items-center flex justify-center">
          <button className="text-laara-primary border border-laara-primary w-full h-10 rounded-[36px]">
            My Promotions
          </button>
        </div>
      </section>

    {/* Verification Status Tabs */}
<section className="verification-tabs mt-3">
  <div className="flex items-center h-[72px] gap-12 p-2">
    <button
      className={`py-2 text-base font-medium flex items-center ${
        selectedStatus === true
          ? "text-blue-500 border-b-2 border-blue-500"
          : "text-gray-600 hover:text-blue-500"
      }`}
      onClick={goToPublished}
    >
      Published 
      <span
        className={`ml-2 ${
          selectedStatus === true
            ? "bg-blue-500 text-white"
            : "bg-neutral-400 text-white"
        } rounded-full w-6 h-6 flex items-center justify-center text-xs`}
      >
        {publishedCount}
      </span>
    </button>
    
    <button
      className={`py-2 text-base font-medium flex items-center ${
        selectedStatus === false
          ? "text-blue-500 border-b-2 border-blue-500"
          : "text-neutral-400 hover:text-blue-500"
      }`}
      onClick={goToDrafts}
    >
      Drafts
      <span
        className={`ml-2 ${
          selectedStatus === false
            ? "bg-blue-500 text-white"
            : "bg-neutral-400 text-white"
        } rounded-full w-6 h-6 flex items-center justify-center text-xs`}
      >
        {draftCount}
      </span>
    </button>
  </div>
</section>



      {/* Promotions CTA */}
      {hasProperties && (
        <section className="promotions-cta h-[270px] bg-gray-300 mt-4 mb-4 flex justify-center items-center rounded-[16px]">
          <div className="cta-info w-[909px] pt-2 flex flex-col items-center bg-custom-gray-300 h-[200px] justify-between p-2">
            <h3 className="font-bold text-[34px] text-white h-[50px] w-[880px]">
              Lets Double your Guests by giving them Discounts
            </h3>
            <p className="w-full max-w-[877px] h-[54px] text-center text-white text-[16px] font-medium -mt-3">
              This festive season lets double your guests by giving discounts
              and offers. Set promotions on your properties and see the magic
              happen
            </p>
            <div className="w-full flex justify-center">
              <button
                onClick={goToPromotions}
                className="w-[192px] h-[50px] rounded-[24px] p-2 bg-laara-primary text-white font-medium text-[16px]"
              >
                Create Promotions
              </button>
            </div>
          </div>
        </section>
      )}

      {/* Property Type Filters */}
      <section className="property-type-filter mt-3">
        <div className="flex items-center h-[72px] gap-12 p-2">
          <button
            className={`py-2 text-base font-medium ${selectedType === "ALL" ? "text-blue-500 border-b-2 border-blue-500" : "text-gray-600 hover:text-blue-500"}`}
            onClick={() => handleTypeChange("ALL")}
          >
            All
          </button>
          <button
            className={`py-2 text-base font-medium ${selectedType === "HOTEL" ? "text-blue-500 border-b-2 border-blue-500" : "text-gray-600 hover:text-blue-500"}`}
            onClick={() => handleTypeChange("HOTEL")}
          >
            Hotel
          </button>
          <button
            className={`py-2 text-base font-medium ${selectedType === "VACATION_RENTAL" ? "text-blue-500 border-b-2 border-blue-500" : "text-gray-600 hover:text-blue-500"}`}
            onClick={() => handleTypeChange("VACATION_RENTAL")}
          >
            Vacation Rental
          </button>
          <button
            className={`py-2 text-base font-medium ${selectedType === "BOARD_ROOM" ? "text-blue-500 border-b-2 border-blue-500" : "text-gray-600 hover:text-blue-500"}`}
            onClick={() => handleTypeChange("BOARD_ROOM")}
          >
            Board Room
          </button>
          <button
            className={`py-2 text-base font-medium ${selectedType === "WEDDING_RECEPTION" ? "text-blue-500 border-b-2 border-blue-500" : "text-gray-600 hover:text-blue-500"}`}
            onClick={() => handleTypeChange("WEDDING_RECEPTION")}
          >
            Wedding Reception
          </button>
          <button
            className={`py-2 text-base font-medium ${selectedType === "EXHIBITION_EXPO_SPACE" ? "text-blue-500 border-b-2 border-blue-500" : "text-gray-600 hover:text-blue-500"}`}
            onClick={() => handleTypeChange("EXHIBITION_EXPO_SPACE")}
          >
            Exhibition Expo Space
          </button>
          <button
            className={`py-2 text-base font-medium ${selectedType === "PARTY_VENUES" ? "text-blue-500 border-b-2 border-blue-500" : "text-gray-600 hover:text-blue-500"}`}
            onClick={() => handleTypeChange("PARTY_VENUES")}
          >
            Party Venues
          </button>
        </div>
      </section>

      {/* Properties Listing */}
      <div className="host-properties flex gap-4 flex-wrap bg-base-white justify-start p-4">
        {hasProperties ? (
          filteredProperties.length > 0 ? (
            filteredProperties.map((property) => (
              <PropertyCard
                key={property.id}
                property={property}
                onClick={() => goToProperty(property.id)}
              />
            ))
          ) : (
            <div className="flex justify-center w-full">
              <section className="no-properties mt-4 flex flex-col items-center">
                <div className="no-propertiesimg w-[384px] h-[222px] bg-gray-300 flex items-center justify-center">
                  <img src="./promotions.png" alt="" />
                </div>
                <div className="text-center">
                  <p className="mt-2 text-base font-medium">
                    No Properties Found
                  </p>
                </div>
                <div className="w-[400px] text-center h-12 items-center flex justify-center">
                  <button
                    onClick={goToListings}
                    className="text-laara-primary border border-laara-primary w-full h-10 rounded-[36px] hover:bg-laara-primary hover:text-white transition-all duration-300 ease-in"
                  >
                    List a New Property
                  </button>
                </div>
              </section>
            </div>
          )
        ) : (
          <div className="flex justify-center w-full">
            <section className="no-properties mt-4 flex flex-col items-center">
              <div className="no-propertiesimg w-[384px] h-[222px] bg-gray-300 flex items-center justify-center">
                <img src="./no-data.avif" alt="" />
              </div>
              <div className="text-center">
                <p className="mt-2 text-base font-medium">
                  No Properties Available
                </p>
              </div>
              <div className="w-[400px] text-center h-12 items-center flex justify-center">
                <button
                  onClick={goToListings}
                  className="text-laara-primary border border-laara-primary w-full h-10 rounded-[36px] hover:bg-laara-primary hover:text-white transition-all duration-300 ease-in"
                >
                  List a New Property
                </button>
              </div>
            </section>
          </div>
        )}
      </div>
    </div>
  );
};

export default HostProperties;
