import React, { useState } from 'react';
import { Doughnut } from 'react-chartjs-2';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import Icon from "@/assets/AdminDash/icon.svg"
import Icon2 from "@/assets/AdminDash/icon2.svg"
import Icon3 from "@/assets/AdminDash/icon3.svg"
import Placeholder1 from "@/assets/AdminDash/placeholder.svg";

function RubyMembers() {
  return (
    <div className="bg-white p-4 rounded-lg shadow border h-full">
      <h2 className="text-xl font-semibold mb-4">Ruby Members</h2>
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-2 gap-4">
        <MemberCard title="New members" count={100} color="text-[#108975]" icon={Icon} />
        <MemberCard title="Active members" count={90} color="text-[#0466C8]" icon={Icon2} />
        <MemberCard title="Inactive members" count={10} color="text-[#C03744]" icon={Icon3} />
      </div>
    </div>
  );
}

function MemberCard({ title, count, color, icon }) {
  return (
    <div className="text-start border rounded-lg shadow-md p-4 bg-white">
      <div className="flex justify-between items-center mb-4">
        <h3 className="text-[#212B36] text-sm font-medium">{title}</h3>
        <img src={icon} alt={title} className="w-12 h-12 object-cover mt-3" />
      </div>
      <p className={`text-3xl font-semibold ${color}`}>{count}</p>
    </div>
  );
}

ChartJS.register(ArcElement, Tooltip, Legend);

function RubySubscriptionAnalytics() {
  const data = {
    labels: ['New Members', 'Active Members', 'Inactive Members'],
    datasets: [
      {
        data: [50, 40, 10],
        backgroundColor: ['#117E6C', '#0466C8', '#AF343F'],
      },
    ],
  };

  const options = {
    plugins: {
      legend: {
        display: false,
      },
    },
    elements: {
      arc: {
        borderWidth: 0,
      },
    },
    cutout: '75%',
    responsive: true,
    maintainAspectRatio: false,
  };

  return (
    <div className="bg-white p-4 rounded-lg shadow h-full">
      <h2 className="text-xl font-semibold mb-4 text-left">Ruby Subscription Analytics</h2>
      <div className="flex flex-col md:flex-row items-center justify-between">
        <div className="w-full md:w-2/3 h-64 md:h-80">
          <Doughnut data={data} options={options} />
        </div>
        <div className="flex flex-col justify-start mt-4 md:mt-0 md:ml-4 space-y-4 font-medium text-sm text-[#4F4F4F]">
          <div className="flex items-center">
            <div className="w-3 h-3 bg-[#117E6C] rounded-full mr-2"></div>
            <p >New Members</p>
          </div>
          <div className="flex items-center">
            <div className="w-3 h-3 bg-[#0466C8] rounded-full mr-2"></div>
            <p >Active Members</p>
          </div>
          <div className="flex items-center">
            <div className="w-3 h-3 bg-[#AF343F] rounded-full mr-2"></div>
            <p >Inactive Members</p>
          </div>
        </div>
      </div>
    </div>
  );
}

function MembersTable() {
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 7;
  const members = [
    { name: 'Martin Maweu', paymentMethod: 'Mobile Money', propertyType: 'Hotel Resort', registrationDate: '08/05/2024', expiryDate: '10/12/2024', status: 'Active' },
    { name: 'Martin Maweu', paymentMethod: 'Mobile Money', propertyType: 'Villa', registrationDate: '08/05/2024', expiryDate: '10/12/2024', status: 'Inactive' },
    { name: 'Martin Maweu', paymentMethod: 'Mobile Money', propertyType: 'Resort', registrationDate: '08/05/2024', expiryDate: '10/12/2024', status: 'Inactive' },
    { name: 'Martin Maweu', paymentMethod: 'Mobile Money', propertyType: 'Campsite', registrationDate: '08/05/2024', expiryDate: '10/12/2024', status: 'Active' },
    { name: 'Martin Maweu', paymentMethod: 'Mobile Money', propertyType: 'Guest Suite', registrationDate: '08/05/2024', expiryDate: '10/12/2024', status: 'Active' },
    { name: 'Martin Maweu', paymentMethod: 'Mobile Money', propertyType: 'Hotel Resort', registrationDate: '08/05/2024', expiryDate: '10/12/2024', status: 'Active' },
    { name: 'Martin Maweu', paymentMethod: 'Mobile Money', propertyType: 'Villa', registrationDate: '08/05/2024', expiryDate: '10/12/2024', status: 'Inactive' },
    { name: 'Martin Maweu', paymentMethod: 'Mobile Money', propertyType: 'Resort', registrationDate: '08/05/2024', expiryDate: '10/12/2024', status: 'Inactive' },
    { name: 'Martin Maweu', paymentMethod: 'Mobile Money', propertyType: 'Campsite', registrationDate: '08/05/2024', expiryDate: '10/12/2024', status: 'Active' },
    { name: 'Martin Maweu', paymentMethod: 'Mobile Money', propertyType: 'Guest Suite', registrationDate: '08/05/2024', expiryDate: '10/12/2024', status: 'Active' },
    { name: 'Martin Maweu', paymentMethod: 'Credit Card', propertyType: 'Lodge', registrationDate: '07/15/2024', expiryDate: '12/30/2024', status: 'Inactive' },
    { name: 'Martin Maweu', paymentMethod: 'Bank Transfer', propertyType: 'Cabin', registrationDate: '07/20/2024', expiryDate: '11/15/2024', status: 'Active' },
    { name: 'Martin Maweu', paymentMethod: 'Mobile Money', propertyType: 'Motel', registrationDate: '06/25/2024', expiryDate: '09/10/2024', status: 'Active' },
    { name: 'Martin Maweu', paymentMethod: 'Mobile Money', propertyType: 'Hotel Resort', registrationDate: '05/30/2024', expiryDate: '12/25/2024', status: 'Inactive' },
    { name: 'Martin Maweu', paymentMethod: 'Credit Card', propertyType: 'Guest House', registrationDate: '08/01/2024', expiryDate: '10/12/2024', status: 'Inactive' },
    { name: 'Martin Maweu', paymentMethod: 'Bank Transfer', propertyType: 'Villa', registrationDate: '08/05/2024', expiryDate: '11/20/2024', status: 'Active' },
    { name: 'Martin Maweu', paymentMethod: 'Credit Card', propertyType: 'Resort', registrationDate: '06/15/2024', expiryDate: '09/30/2024', status: 'Inactive' },
  ];

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = members.slice(indexOfFirstItem, indexOfLastItem);

  const pageNumbers = [];
  for (let i = 1; i <= Math.ceil(members.length / itemsPerPage); i++) {
    pageNumbers.push(i);
  }

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  return (
    <>
      <div className="bg-white p-4 rounded-lg shadow overflow-x-auto">
        <div className="flex items-center gap-3 mb-4">
          <h2 className="text-xl font-medium">Members</h2>
          <span className="text-[#0466C8] mt-2 sm:mt-0 sm:ml-4 bg-[#B4D9FE] px-3 py-1 rounded font-semibold text-xs bg-opacity-20">{members.length} Members</span>
        </div>
        <p className="text-[#626262] font-normal text-base mb-4">These are all the Ruby members</p>
        <table className="w-full min-w-max">
          <thead className="bg-[#F5F7F9] text-[#4F4F4F] font-semibold text-sm">
            <tr>
              <th className="p-3 text-left w-8 "></th>
              <th className="p-3 text-left ">Name</th>
              <th className="p-3 text-left ">Payment Method</th>
              <th className="p-3 text-left ">Property Type</th>
              <th className="p-3 text-left ">Date Transacted</th>
              <th className="p-3 text-left ">Expiry Date</th>
              <th className="p-3 text-left ">Status</th>
            </tr>
          </thead>
          <tbody>
            {currentItems.map((member, index) => (
              <tr key={index} className='text-[#434343] font-medium text-sm'>
                <td className="p-3"></td>
                <td className="p-3 flex items-center">
                  <input type="checkbox" className="rounded w-4 h-4 mr-3" />
                  <img className="w-8 h-8 rounded-full mr-2" src={Placeholder1} alt="User" />
                  <span>{member.name}</span>
                </td>
                <td className="p-3 ">{member.paymentMethod}</td>
                <td className="p-3 ">{member.propertyType}</td>
                <td className="p-3 ">{member.registrationDate}</td>
                <td className="p-3 ">{member.expiryDate}</td>
                <td className="p-3 ">
                  <span className={` w-full max-w-[77px] px-3 py-1 inline-flex text-sm leading-5 font-medium rounded  ${member.status === 'Active' ? 'border border-[#108975] text-[#108975]' : 'border border-[#C03744] text-[#C03744]'}`}>
                    {member.status}
                  </span>
                </td>
              </tr>
            ))}
          </tbody>
        </table>

      </div>
      <div className="flex justify-end mt-2 ">
        <div className="border rounded-lg font-semibold px-2 py-1.5">
          <button
            className={`px-3 py-1 rounded mr-2 ${currentPage === 1 ? 'text-gray-500' : 'text-black'}`}
            onClick={() => handlePageChange(currentPage - 1)}
            disabled={currentPage === 1}
          >
            &lt;
          </button>
          {pageNumbers.map((number) => (
            <button
              key={number}
              className={`px-3 py-1 mr-2 ${currentPage === number ? 'bg-[#108975] text-white' : 'text-black'} rounded`}
              onClick={() => handlePageChange(number)}
            >
              {number}
            </button>
          ))}
          <button
            className="px-3 py-1 mr-2 text-black rounded"
            onClick={() => handlePageChange(currentPage + 1)}
            disabled={currentPage === pageNumbers.length}
          >
            &gt;
          </button>
        </div>
      </div>

    </>
  );
}

const Ruby = () => {
  return (
    <div className="container mx-auto p-4">
      <div className="grid grid-cols-1 lg:grid-cols-2 gap-4 mb-4">
        <RubyMembers />
        <RubySubscriptionAnalytics />
      </div>
      <MembersTable />
    </div>
  );
};

export default Ruby;
