import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import {
  signInWithPopup,
  signInWithEmailAndPassword,
  GoogleAuthProvider,
  onIdTokenChanged
} from 'firebase/auth';
import { auth } from '../../../firebase/firebase'; // Adjust the path as necessary
import {
  signInStart,
  signInSuccess,
  signInFailure,
  setToken,
} from '@/redux/user/userSlice'; // Adjust the path as necessary
import googleIcon from '../../../assets/ListingProcess/google.svg';
import facebookIcon from '../../../assets/ListingProcess/facebook.svg';
import Navbar from './HostNavbar/Navbar';
const getErrorMessage = (errorCode) => {
  switch (errorCode) {
    case 'auth/invalid-email':
      return 'Invalid email address.';
    case 'auth/user-disabled':
      return 'This user has been disabled.';
    case 'auth/user-not-found':
      return 'User not found. Please check your email and try again.';
    case 'auth/wrong-password':
      return 'Incorrect password. Please try again.';
    case 'auth/popup-closed-by-user':
      return 'The popup was closed before completing the sign in. Please try again.';
    case 'auth/cancelled-popup-request':
      return 'Multiple popups were opened, one of them was closed. Please try again.';
    default:
      return 'An unexpected error occurred. Please try again later.';
  }
};

export default function HostLogin() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    // Listen for token changes and refresh
    const unsubscribe = onIdTokenChanged(auth, async (user) => {
      if (user) {
        const token = await user.getIdToken();
        dispatch(setToken(token)); // Update token in Redux state when it changes
      }
    });

    // Cleanup the listener on component unmount
    return () => unsubscribe();
  }, [dispatch]);

  const handleGoogleSignIn = async () => {
    dispatch(signInStart());
    try {
      const provider = new GoogleAuthProvider();
      const result = await signInWithPopup(auth, provider);
      const user = result.user;
      const token = await user.getIdToken();

      dispatch(setToken(token)); // Update token in Redux state

      const [firstName, ...lastNameParts] = (user.displayName || '').split(' ');
      const lastName = lastNameParts.join(' ');
      const photoURL = user.photoURL;


      dispatch(signInSuccess({ email: user.email, firstName, lastName, uid: user.uid, photoURL  }));

      dispatch(signInSuccess({ email: user.email, firstName, lastName, uid: user.uid, photoURL  }));

      navigate("/")
    } catch (error) {
      dispatch(signInFailure(error.message));
      setError(getErrorMessage(error.code));
    }
  };

  const handleEmailSignIn = async (event) => {
    event.preventDefault();
    dispatch(signInStart());
    try {
      const result = await signInWithEmailAndPassword(auth, email, password);
      const user = result.user;
      const token = await user.getIdToken();

      dispatch(setToken(token)); // Update token in Redux state
      dispatch(signInSuccess(user));

      navigate("/listproperty")
    } catch (error) {
      dispatch(signInFailure(error.message));
      setError(getErrorMessage(error.code));
    }
  };

  

  return (
    <div className="flex flex-col pb-20 bg-white">
      <Navbar/>
      <div className="flex flex-col justify-center self-center mt-40 max-w-full w-[459px] max-md:mt-10">
        <div className="flex flex-col justify-center px-5 max-md:max-w-full">
          <h2 className="self-center text-xl tracking-normal leading-8 text-center text-sky-600">
            Sign in to list your property
          </h2>
          <form onSubmit={handleEmailSignIn} className="flex flex-col mt-8 text-sm text-zinc-800 max-md:max-w-full">
            <div className="flex flex-col flex-1 max-md:w-full">
              <label className="relative text-sm text-zinc-600">
                <input
                  type="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  className="peer px-2.5 py-3 border border-zinc-600 rounded-md bg-white mt-1 focus:outline-none focus:border-blue-500 w-full"
                  placeholder=" "
                  required
                />
                <span className="absolute left-2.5 top-1/2 -translate-y-1/2 bg-white px-1 text-xs text-zinc-700 transition-all duration-200 peer-placeholder-shown:top-1/2 peer-placeholder-shown:text-base peer-placeholder-shown:text-zinc-600 peer-focus:top-0 peer-focus:text-xs peer-focus:text-blue-500 peer-valid:top-0 peer-valid:text-xs peer-valid:text-blue-500">
                  Email
                </span>
              </label>
            </div>
            <div className="flex flex-col justify-center mt-9">
              <label className="relative flex flex-col justify-center text-sm text-zinc-600">
                <input
                  type="password"
                  className="peer px-2.5 py-3 border border-zinc-600 rounded-md bg-white mt-1 focus:outline-none focus:border-blue-500"
                  placeholder=" "
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  required
                />
                <span className="absolute left-2.5 top-1/2 -translate-y-1/2 bg-white px-1 text-xs text-zinc-700 transition-all duration-200 peer-placeholder-shown:top-1/2 peer-placeholder-shown:text-base peer-placeholder-shown:text-zinc-600 peer-focus:top-0 peer-focus:text-xs peer-focus:text-blue-500">
                  Password
                </span>
              </label>
            </div>
            <Link to="/forgot-password" className="mt-6 text-xs text-center text-sky-700 underline max-md:max-w-full">
              Forgot Password?
            </Link>
            {error && (
              <div className="mt-4 text-center text-red-500 text-sm">
                {error}
              </div>
            )}
            <button
              type="submit"
              className="px-3.5 py-5 mt-8 text-lg font-medium tracking-normal leading-5 text-center text-white whitespace-nowrap bg-sky-600 rounded-[36px] max-md:px-5 max-md:max-w-full"
            >
              Sign in
            </button>
          </form>
          <div className="flex flex-col items-center mt-8 max-md:max-w-full">
            <div className="px-16 text-center max-w-full text-xs whitespace-nowrap text-neutral-400 w-[307px] max-md:px-5">
              OR
            </div>
            <div className="self-stretch mt-6 text-xs text-center text-neutral-500 max-md:max-w-full">
              Sign in with
            </div>
            <div className="flex gap-5 justify-center mt-6">
              <button
                className="flex justify-center items-center p-3 border border-solid border-neutral-400 rounded-[32px] cursor-pointer"
                onClick={handleGoogleSignIn}
                type="button"
              >
                <img
                  loading="lazy"
                  src={googleIcon}
                  className="aspect-square w-[21px]"
                  alt="Google Sign-In"
                />
              </button>
              <button 
                className="flex justify-center items-center p-3 border border-solid border-neutral-400 rounded-[32px]"
                type="button"
                onClick={() => alert('Facebook login not implemented')}
              >
                <img
                  loading="lazy"
                  src={facebookIcon}
                  className="aspect-square w-[21px]"
                  alt="Facebook Sign-In"
                />
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}