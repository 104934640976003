import Ruby from "@/components/HomePage/Ruby/Ruby";
import TopPicks from "@/components/HomePage/TopPicks/TopPicks";
import NewHero from "@/components/HomePage/newHero/NewHero";
import NewSpecialOffers from "@/components/HomePage/newSpecialOffers/NewSpecialOffers";
import ChooseUs from "@/components/HomePage/chooseUs/ChooseUs";
import NewListYourProperty from "@/components/HomePage/newSpecialProperty/NewListYourProperty";
import Picker from "@/components/HomePage/hero/Picker";
import Testimonials from "@/components/HomePage/Testimonials/Testimonials";
import { getUserProfile } from "@/api";
import { useEffect, useState } from "react";

const Home = () => {
  const [isHost, setIsHost] = useState(() => {
    // Retrieve host status from localStorage (if available) when the component mounts
    return JSON.parse(localStorage.getItem("isHost")) || false;
  });

  useEffect(() => {
   const fetchUserData = async () => {
    try{
      let response = await getUserProfile();
      // Extract roles from the user object
      const roles = response?.user?.roles || [];

      // Check if the user has the HOST role
      const hasHostRole = roles.some(role => role?.role?.name === 'HOST');
      
      if (hasHostRole) {
        setIsHost(true);
        localStorage.setItem("isHost", JSON.stringify(hasHostRole)); 
      } else {
        setIsHost(false); // User is not a host
      }

    } catch(error){
      console.error(error)
    }
   }
   fetchUserData();
  })
  return (
    <>
      <main className="w-full max-w-[1920px] mx-auto">
        <NewHero />
        <Picker />
        <TopPicks pageTitle={"Popular Destinations"} />
        <TopPicks pageTitle={"Top Picks"} />
        <NewSpecialOffers />
        <ChooseUs />
        <NewListYourProperty />
        <Testimonials />
        <Ruby />
      </main>
    </>
  );
};

export default Home;
