import React, { useCallback, useEffect } from "react";
import { useState } from "react";
import close from "@/assets/searchresults/close-square.png";
import arrowdown from "@/assets/searchresults/arrow-down.png";
import { Slider } from "@/components/ui/slider";
import { CgCloseR } from "react-icons/cg";
import { Checkbox } from "@/components/ui/checkbox";

const Filter = ({ setOpenFilter, filters, setFilters }) => {
  const [showAllFacilities, setShowAllFacilities] = useState(false);

  const propertyTypes = [
    { id: 1, label: "all", value: "ALL", results: 87 },
    { id: 2, label: "hotel", value: "HOTEL", results: 45 },
    { id: 3, label: "vacation rental", value: "VACATION_RENTAL", results: 90 },
    { id: 4, label: "board room", value: "BOARD_ROOM", results: 100 },
    {
      id: 5,
      label: "wedding reception",
      value: "WEDDING_RECEPTION",
      results: 123,
    },
    { id: 6, label: "party venue", value: "PARTY_VENUE", results: 100 },
    {
      id: 7,
      label: "exhibition expo space",
      value: "EXHIBITION_EXPO_SPACE",
      results: 56,
    },
  ];

  const facilities = [
    { id: 1, label: "sea view", value: "sea view", results: 87 },
    { id: 2, label: "balcony", value: "balcony", results: 100 },
    {
      id: 3,
      label: "air conditioning",
      value: "air conditioning",
      results: 67,
    },
    { id: 4, label: "private pool", value: "private pool", results: 56 },
    {
      id: 5,
      label: "private bathroom",
      value: "private bathroom",
      results: 45,
    },
  ];

  const meals = [
    { id: 1, label: "bed & breakfast", value: "bed & breakfast", results: 87 },

    { id: 2, label: "half board", value: "half board", results: 67 },
    { id: 3, label: "full board", value: "full board", results: 56 },
    {
      id: 4,
      label: "all inclusive",
      value: "all inclusive",
      results: 45,
    },
    { id: 5, label: "room only", value: "room only", results: 100 },
  ];

  const ratings = [
    { id: 1, label: "5", value: "5", results: 87 },
    { id: 2, label: "4", value: "4", results: 150 },
    { id: 3, label: "3", value: "3", results: 88 },
    { id: 4, label: "2", value: "2", results: 90 },
    { id: 5, label: "1", value: "1", results: 45 },
  ];

  const handleMaxSizeChange = (e) => {
    const value = parseInt(e.target.value);
    if (value >= filters.minSize) {
      setFilters((prevFilters) => ({
        ...filters,
        maxSize: value,
      }));
    } else if (value < filters.minSize) {
      setFilters.minSize(value);
      setFilters.maxSize(filters.minSize);
    }
  };

  const handleMinSizeChange = (e) => {
    const value = parseInt(e.target.value);
    if (value <= filters.maxSize) {
      setFilters.minSize(value);
    } else if (value > filters.maxSize) {
      setFilters.minSize(filters.maxSize);
      setFilters.maxSize(value);
    }
  };

  const handleAmenities = (e) => {
    const { value } = e.target;

    if (!filters.amenities.includes(value)) {
      setFilters((prevFilters) => ({
        ...filters,
        amenities: [...filters.amenities, value],
      }));
    } else {
      setFilters((prevFilters) => ({
        ...filters,
        amenities: filters.amenities.filter((amenity) => amenity !== value),
      }));
    }
    // console.log(filters.amenities);
  };

  const handleChange = (e) => {
    const { value, name } = e.target;
    setFilters((prevFilters) => ({
      ...filters,
      [name]: value,
    }));
  };

  return (
    <section className="w-full xl:max-w-[420px] xl:w-full px-8 space-y-2.5 xl:border xl:border-[#626262] xl:rounded-[14px] py-8 h-fit ">
      <div className="xl:hidden flex justify-end pt-[20px] pb-0 ">
        <CgCloseR
          onClick={() => setOpenFilter(false)}
          className="size-6 text-[#626262] "
        />
      </div>

      <section className="flex flex-col space-y-4 ">
        <h4 className="font-medium text-[24px] leading-[36px] text-[#292929] ">
          Filter By
        </h4>

        <div className="h-[0.1px] w-full bg-[#9B9B9B]" />
        {/* PROPERTY TYPES */}
        <div className="space-y-6 ">
          <h4 className="text-lg text-[#292929] font-medium  ">
            Property Type
          </h4>

          <div className="flex flex-col gap-5 ">
            {propertyTypes.map((item) => (
              <div key={item.id} className="flex items-center justify-between ">
                <div className="flex items-center gap-2.5">
                  <input
                    type="radio"
                    value={item.value}
                    name="propertyType"
                    id={item.value}
                    onChange={handleChange}
                    className="size-5 text-[#626262] "
                  />
                  <label
                    htmlFor={item.value}
                    className="capitalize text-[#626262]"
                  >
                    {item.label}
                  </label>
                </div>
                <p className="text-[#626262] text-[14px] leading-6">
                  {item.results}
                </p>
              </div>
            ))}
          </div>
        </div>

        <div className="h-[0.1px] w-full bg-[#9B9B9B]" />
        {/* PRICE RANGE */}
        <div className="space-y-[13px]">
          <h4 className="text-lg font-medium text-[#292929]  ">Price Range</h4>
          <div className="flex flex-col items-center">
            <Slider
              value={filters.range}
              onValueChange={(range) =>
                setFilters((prevFilters) => ({ ...prevFilters, range }))
              }
              name="range"
              max={10000}
              step={1}
              className={"w-[356px]"}
            />
            <div className="text-[#AEAEAE] text-[14px] mt-[13px]">
              <span className="mr-2">Range:</span>
              <span className=" ">${filters.range[0]}</span> -{" "}
              <span>${filters.range[1]}</span>
            </div>
          </div>
        </div>

        <div className="h-[0.1px] w-full bg-[#9B9B9B]" />
        {/* SIZE */}
        <div className="space-y-6">
          <h4 className="text-lg font-medium text-[#292929]">Size</h4>
          <div className="relative">
            <input
              id="hideWheel"
              type="number"
              value={filters.maxSize}
              onChange={handleMaxSizeChange}
              placeholder="Max"
              className="text-[#AEAEAE] p-4 border border-[#AEAEAE] rounded-[8px] outline-none"
            />
            <span className="text-[#626262] absolute top-4 right-5">sq ft</span>
          </div>
          <div className="relative">
            <input
              id="hideWheel"
              type="number"
              value={filters.minSize}
              onChange={handleMinSizeChange}
              placeholder="Min"
              className="text-[#AEAEAE] p-4 border border-[#AEAEAE] rounded-[8px] outline-none "
            />
            <span className="text-[#626262] absolute top-4 right-5">sq ft</span>
          </div>
        </div>

        <div className="h-[0.1px]  w-full bg-[#9B9B9B]" />
        {/* FACILITIES */}
        <div className="space-y-6">
          <h4 className="text-lg font-medium text-[#292929] ">Facilities</h4>

          <div className="space-y-5">
            {facilities.map((facility) => (
              <div
                key={facility.id}
                className="flex facilitys-center justify-between "
              >
                <div className="flex facilitys-center gap-2.5">
                  <input
                    type="checkbox"
                    value={facility.value}
                    id={facility.value}
                    onChange={handleAmenities}
                    className="size-5 text-[#626262] "
                  />
                  <label
                    htmlFor={facility.value}
                    className="capitalize text-[#626262]"
                  >
                    {facility.label}
                  </label>
                </div>
                <p className="text-[#626262] text-[14px] leading-6">
                  {facility.results}
                </p>
              </div>
            ))}
          </div>
          {/* <button className="text-laara-primary flex items-center gap-2.5">
            Show More
            <img
              src={arrowdown}
              alt="icon"
              className={`size-[18px] ${showAllFacilities && "rotate-180"}`}
            />
          </button> */}
        </div>

        <div className="h-[0.1px] w-full bg-[#9B9B9B]" />
        {/* MEAL PLANS */}
        <div className="space-y-6">
          <h4 className="text-lg font-medium text-[#292929] ">Meal Plans</h4>
          <div className="space-y-5">
            {meals.map((meal) => (
              <div key={meal.id} className="flex items-center justify-between ">
                <div className="flex items-center gap-2.5">
                  <input
                    type="checkbox"
                    value={meal.value}
                    id={meal.value}
                    className="size-5 text-[#626262] "
                  />
                  <label
                    htmlFor={meal.value}
                    className="capitalize text-[#626262]"
                  >
                    {meal.label}
                  </label>
                </div>
                <p className="text-[#626262] text-[14px] leading-6">
                  {meal.results}
                </p>
              </div>
            ))}
          </div>
        </div>

        <div className="h-[0.1px] w-full bg-[#9B9B9B]" />
        {/* RATINGS */}
        <div className="space-y-6">
          <h4 className="text-lg font-medium text-[#292929] ">Ratings</h4>
          <div className="space-y-5">
            {ratings.map((rating) => (
              <div
                key={rating.id}
                className="flex items-center justify-between "
              >
                <div className="flex items-center  gap-2.5">
                  <input
                    type="radio"
                    value={rating.value}
                    name="rating"
                    id={rating.value}
                    className="size-5 text-[#626262] "
                    onChange={handleChange}
                  />
                  <label
                    htmlFor={rating.value}
                    className="capitalize text-[#626262] text-base "
                  >
                    {rating.label}
                  </label>
                </div>
                <p className="text-[#626262] text-sm ">{rating.results}</p>
              </div>
            ))}
          </div>
        </div>

        {/* FILTER BUTTON */}
        <div className="text-center">
          <button
            onClick={() => handleFilter()}
            className="text-white font-medium text-[14px] py-2.5 px-4 rounded-[32px] bg-laara-primary min-w-[192px]"
          >
            Filter
          </button>
        </div>
      </section>
    </section>
  );
};

export default Filter;
