import { useDispatch, useSelector } from "react-redux";
import { setAmenities } from "@/redux/Listing/listingSlice"; // Update the path to your Redux slice
import { Checkbox } from "@/components/ui/checkbox";

const Amenities = () => {
  const dispatch = useDispatch();
  const selectedAmenities = useSelector(
    (state) => state.hostListing.formData?.amenities || []
  );

  const amenitiesCategories = {
    General: [
      "free wifi",
      "air conditioning",
      "heating",
      "non-smoking room",
      "smoking rooms",
      "family rooms",
      "pet-friendly accommodations",
      "24 hour front desk",
      "concierge service",
      "room service",
    ],
    Bedroom: ["comfortable beds", "wardrobe/closet", "ironing facilities"],
    Kitchen: [
      "kitchenette / full kitchen",
      "refrigerator",
      "microwave",
      "coffee / tea maker",
      "dishwasher",
      "stove",
      "oven",
    ],
    "Media and Technology": [
      "flat-screen TV",
      "cable / satellite channels",
      "streaming services (e.g Netflix)",
      "DVD player",
    ],
    "Food and Beverage": [
      "on-site restaurant / bar",
      "breakfast options",
      "minibar",
    ],
    "Outdoor and Views": [
      "balcony / terrace",
      "garden",
      "outdoor furniture",
      "bbq facilities",
      "sea view/mountain view / city view",
    ],
    Services: [
      "laundry services",
      "dry cleaning",
      "ironing services",
      "shoe shine",
      "trouser press",
      "daily housekeeping",
      "wake-up services",
      "fax /photocopying",
      "meeting / banquet facilities",
    ],
    Business: ["laptop-friendly workspace", "ethernet connection"],
    Wellness: [
      "swimming pool",
      "fitness center",
      "spa & wellness center",
      "sauna",
      "hot tub",
    ],
  };

  const handleToggleAmenity = (isChecked, category, amenity) => {
    // Toggle the selection of the amenity
    const updatedAmenities = selectedAmenities?.some(
      (a) => a.name === amenity && a.category === category
    )
      ? selectedAmenities.filter(
          (a) => !(a.name === amenity && a.category === category)
        )
      : [
          ...selectedAmenities,
          {
            name: amenity,
            description: `${amenity} available`, // Customize description
            category,
          },
        ];

    dispatch(setAmenities(updatedAmenities));
  };

  const renderCheckboxes = (category, items) => (
    <div key={category} className="space-y-4">
      <p className="text-lg font-medium capitalize">{category}</p>
      <div className="flex flex-col gap-2">
        {items.map((item) => (
          <div key={item} className="flex items-center gap-3">
            <Checkbox
              id={`${category}-${item}`}
              name={item}
              className="size-6 rounded-lg"
              checked={selectedAmenities?.some(
                (a) => a.name === item && a.category === category
              )}
              onCheckedChange={(isChecked) =>
                handleToggleAmenity(isChecked, category, item)
              }
            />
            <label
              htmlFor={`${category}-${item}`}
              className={`capitalize text-base ${
                selectedAmenities?.some(
                  (a) => a.name === item && a.category === category
                ) && "text-laara-primary"
              }`}
            >
              {item}
            </label>
          </div>
        ))}
      </div>
    </div>
  );

  return (
    <form className="flex flex-col">
      <h4 className="font-medium text-xl">
        What can your guests use at your place?
      </h4>
      <div className="border border-[#A3A3A3] rounded-[6px] mt-8 p-8 max-w-[723px] w-full ">
        <div className="flex items-start gap-4 mt-5">
          <div className="space-y-4">
            {Object.entries(amenitiesCategories).map(([category, items]) =>
              renderCheckboxes(category, items)
            )}
          </div>
        </div>
      </div>
    </form>
  );
};

export default Amenities;
