import React from 'react'
import Profile4 from "@/assets/profile/Profile4.png"
import eye from "@/assets/profile/eye2.png";
import { profileData } from "@/constants/data";
import FlagN from "@/assets/profile/flag-N.png"
import leftArrow from "@/assets/reservations/arrow-left.jpg"
import { useNavigate } from 'react-router-dom';


export const Edit = () => {
  const navigate = useNavigate();
  const { formFields } = profileData;

  const handleChange = (event) => {

    console.log(event.target.id, event.target.value);

  };

  const gotoProfilePage= () =>{
    navigate ('/profile')
  }

  return (
    <>
      <div className="max-w-[1440px] mx-auto px-4">
        <div className="max-w-[1088px] mx-auto mt-[40px] left-[88px] flex flex-col">
          <header className="flex  items-center">
            <h1 className="text-[26px] text-[#4F4F4F] font-bold">Alex Otieno</h1>
            <img src={leftArrow} alt="" onClick={gotoProfilePage} className="w-6 h-6 mr-2 ml-2" />
            <button className="text-[#4F4F4F] text-[18px] font-normal">Edit Profile Details</button>
          </header>

          <div className="">
            <div className="w-full flex flex-col sm:flex-row items-center">
              <img src={Profile4} alt="Profile" className="h-24 sm:h-40 mb-2 sm:mb-0 sm:ml-[-20px] sm:mr-[-30px]" />
              <div className="flex flex-col sm:flex-row w-full sm:w-auto sm:ml-4 items-center mb-9">
                <button className="bg-laara-primary text-white px-3 py-2 text-sm rounded-xl mb-2 sm:mb-0 flex items-center justify-center w-full sm:w-auto">
                  <img src={eye} alt="" className="w-3 h-3 mr-1" />
                  Edit Photo
                </button>
                <button className="text-red-500 px-3 py-2 text-sm border border-red-500 rounded-xl sm:ml-2 w-full sm:w-auto">
                  Remove Photo
                </button>
              </div>
            </div>

            <div className="w-full max-w-screen-xl h-auto min-h-[942px] flex flex-col gap-9">
              <form className="flex flex-col gap-9 ml-6">
                {formFields.map(field => (
                  <div key={field.id} className="flex flex-col">
                    <label htmlFor={field.id} className="mb-2">
                      {field.label}<span className="text-red-500">*</span>
                    </label>
                    {field.id !== 'phone' && field.id !== 'country' && (
                      <input
                        type={field.type}
                        id={field.id}
                        name={field.id}
                        value={field.value}
                        onChange={handleChange}
                        className="p-2 border text-[#626262] text-[16px] font-normal border-gray-300 rounded focus:bg-white focus:border-gray-300 focus:outline-none required"
                      />
                    )}
                    {field.id === 'phone' && (
                      <div className="relative flex items-center">
                        <span className="absolute left-3 flex items-center">
                          <img src={FlagN} alt="Flag" className="h-4" />
                        </span>
                        <input
                          type={field.type}
                          id={field.id}
                          name={field.id}
                          value={field.value}
                          onChange={handleChange}
                          className="pl-10 p-2 text-[#626262] text-[16px] font-normal border border-gray-300 rounded focus:bg-white focus:border-gray-300 focus:outline-none required w-full"
                          style={{ boxSizing: 'border-box' }}
                        />
                      </div>
                    )}

                    {field.id === 'country' && (
                      <div className="relative flex items-center">
                        <span className="absolute left-3 flex items-center">
                          <img src={FlagN} alt="Flag" className="h-4" />
                        </span>
                        <input
                          type={field.type}
                          id={field.id}
                          name={field.id}
                          value={field.value}
                          onChange={handleChange}
                          className="pl-10 p-2 text-[#626262] text-[16px] font-normal border border-gray-300 rounded focus:bg-white focus:border-gray-300 focus:outline-none required w-full"
                          style={{ boxSizing: 'border-box' }}
                        />
                      </div>
                    )}
                  </div>
                ))}
                <div className="flex flex-col">
                  <label htmlFor="message" className="mb-2">Bio<span className="text-red-500">*</span></label>
                  <textarea id="message" name="message" className="p-2 h-40 border border-gray-300 rounded focus:bg-white focus:border-gray-300  focus:outline-none required"></textarea>
                </div>
              </form>
            </div>

          </div>
        </div>
      </div>
    </>
  )
}
