import { useState } from "react";
import MonthSwiper from "./MonthSwiper";
import { durations } from "@/constants/data";
import {months} from "@/constants/data"
const MyFlexible = () => {
  const [selectedDuration, setSelectedDuration] = useState("2-3 nights");
  const [selectedWeekend, setSelectedWeekend] = useState(false);
  const [selectedMonth, setSelectedMonth] = useState(0);
  return (
    <div className="bg-white p-6 lg:max-w-4xl lg:mx-auto">
      <h2 className="font-semibold text-[#292929] my-3 text-lg lg:text-xl">
        How long do you want to stay?
      </h2>
      <div className="flex flex-wrap gap-2 lg:gap-4">
        {durations.map((duration) => (
          <button
            onClick={() => setSelectedDuration(duration.duration)}
            key={duration.id}
            className={`rounded-full border p-2.5 m-2 cursor-pointer ${
              selectedDuration === duration.duration
                ? "text-[#0466C8] border-[#0466C8]"
                : "text-[#7B7B7B] border-[#7B7B7B]"
            }`}
          >
            <p className="text-sm lg:text-base">{duration.duration}</p>
          </button>
        ))}
      </div>
      <div className="flex items-center gap-2 my-4 text-sm lg:text-base">
        <input
          type="checkbox"
          id="weekend"
          name="weekend"
          checked={selectedWeekend}
          onChange={(e) => setSelectedWeekend(e.target.checked)}
          className="cursor-pointer"
        />
        <label htmlFor="weekend" className="text-[#292929] cursor-pointer">
          Includes weekend
        </label>
      </div>
      <hr className="my-6" />
      <MonthSwiper setSelectedMonth={setSelectedMonth} months={months} />
    </div>
  );
};
export default MyFlexible;
