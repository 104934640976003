import { useState } from 'react';
import { settings } from '@/constants/data';
import arrowdown from "../../assets/settings/arrowdown.svg";
import arrowup from "../../assets/settings/arrowup.svg";
const Dropdown = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [toggledStates, setToggledStates] = useState(
    settings.reduce((acc, setting) => ({ ...acc, [setting.key]: false }), {})
  );
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
    setIsDropdownOpen(!isDropdownOpen);
  };

  const toggleButton = (key) => {
    setToggledStates((prevState) => ({
      ...prevState,
      [key]: !prevState[key]
    }));
  };

  return (
    <div className="dropdown">
      <button
        className="flex gap-2.5 justify-center p-5 text-lg font-medium text-center text-sky-600 whitespace-nowrap border border-sky-600 border-solid rounded-[36px]"
        onClick={toggleDropdown}
      >
        <div>Manage</div>
        <img
          loading="lazy"
          src={isDropdownOpen ? arrowup : arrowdown}
          className="shrink-0 w-6 aspect-square"
        />
      </button>
      {isOpen && (
        <div className="dropdown-content">
          {settings.map((setting) => (
            <div key={setting.key} className="flex gap-2.5 mt-5 max-md:flex-wrap max-md:max-w-full">
              <div
                className={`flex items-center w-12 h-6 px-1 bg-sky-600 rounded-full cursor-pointer transition-colors duration-300 ${
                  toggledStates[setting.key] ? 'bg-sky-600' : 'bg-zinc-700'
                }`}
                onClick={() => toggleButton(setting.key)}
              >
                <div
                  className={`w-5 h-5 bg-white rounded-full shadow-lg transform transition-transform duration-500 ${
                    toggledStates[setting.key] ? 'translate-x-6' : 'translate-x-0'
                  }`}
                />
              </div>
              <div className="flex-1 my-auto text-sm font-semibold leading-4 text-neutral-600 max-md:max-w-full">
                {setting.label}
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default Dropdown;
