import { useState } from 'react';
import { setCancellationPolicies } from '@/redux/Listing/listingSlice'; // Adjust this path to match your Redux slice
import tick from '../../../assets/ListingProcess/tick.svg';
import hand from '../../../assets/ListingProcess/hand.svg';
import cancel from '../../../assets/ListingProcess/cancel.svg';
import left from '../../../assets/ListingProcess/left.svg';
import { useDispatch } from 'react-redux';

export default function Cancellation() {
  const dispatch = useDispatch();
  const [selectedOption, setSelectedOption] = useState('');
  const [cancellationFee, setCancellationFee] = useState('');

  const updatePolicies = (type, fee) => {
    const policies = [
      {
        type: type,
        description: generateDescription(type, fee),
        isMandatory: true,
        penalty: fee === 'firstNight' ? 1 : fee === '50percent' ? 50 : 100,
        penaltyType: 'PERCENTAGE',
      },
    ];

    // Dispatch the action to update Redux store
    dispatch(setCancellationPolicies(policies));

    // Optionally, send the data to the server here
    // savePoliciesToServer(policies);
  };

  const handleOptionChange = (event) => {
    const newOption = event.target.value;
    setSelectedOption(newOption);
    updatePolicies(newOption, cancellationFee);
  };

  const handleCancellationFeeChange = (event) => {
    const newFee = event.target.value;
    setCancellationFee(newFee);
    updatePolicies(selectedOption, newFee);
  };

  const generateDescription = (type, fee) => {
    let description = '';
    if (type === 'before18') {
      description = 'Guests can cancel for free before 18:00 on the day of arrival.';
    } else if (type === '24hours') {
      description = 'Guests can cancel for free 24 hours before arrival.';
    } else if (type === '48hours') {
      description = 'Guests can cancel for free 48 hours before arrival.';
    }
    return description;
  };

  return (
    <div className="flex flex-col self-center mt-5 w-full max-w-[1095px] max-md:max-w-full">
      <div className="flex gap-2 self-start mt-24 ml-8 max-md:flex-wrap max-md:mt-10">
        <div className="flex gap-5 justify-between my-auto">
          <div className="shrink-0 w-3.5 h-3.5 bg-white rounded-full" />
          <div className="shrink-0 w-3.5 h-3.5 bg-white rounded-full" />
        </div>
      </div>
      <div className="flex gap-5 mt-5 text-xl text-gray-800 max-md:flex-wrap" />
      <div className="text-3xl font-semibold tracking-normal leading-10 text-zinc-800 max-md:mt-10 max-md:max-w-full">
        Cancellation policies
      </div>
      <div className="mt-12 max-md:mt-10 max-md:max-w-full">
        <div className="flex gap-5 max-md:flex-col">
          <div className="flex flex-col w-[69%] max-md:ml-0 max-md:w-full bg-white bg-opacity-40 rounded-md border border-neutral-200">
            <div className="flex flex-col grow px-10 py-16 w-full text-xl text-black rounded-md bg-slate-50 bg-opacity-40 border-neutral-400 max-md:px-5 max-md:mt-8 max-md:max-w-full">
              <div className="font-medium max-md:max-w-full">
                When can the guests cancel their bookings for free?
              </div>
              <div className="flex gap-5 px-3.5 py-3.5 mt-3 rounded-lg border border-solid border-neutral-500 max-md:flex-wrap max-md:max-w-full">
                <div className="flex-auto">
                  <select
                    className="w-full text-black bg-transparent border-none outline-none text-lg"
                    value={selectedOption}
                    onChange={handleOptionChange}
                  >
                    <option value="" disabled>Select an option</option>
                    <option value="before18">Before 18:00 on the day of arrival</option>
                    <option value="24hours">24 hours before arrival</option>
                    <option value="48hours">48 hours before arrival</option>
                  </select>
                </div>
              </div>
            </div>
          </div>
          <div className="flex flex-col ml-5 w-[31%] max-md:ml-0 max-md:w-full">
            <div className="flex flex-col px-5 py-6 mx-auto w-full text-black bg-white bg-opacity-40 rounded-md border border-neutral-200 max-md:mt-8">
              <img
                loading="lazy"
                src={cancel}
                className="self-end w-2 aspect-[1.14]"
              />
              <div className="flex gap-5 justify-between items-start mt-1">
                <img
                  loading="lazy"
                  src={hand}
                  className="shrink-0 aspect-square w-[30px]"
                />
                <div className="flex flex-col mt-1">
                  <div className="text-base font-medium">
                    Which policies should I choose?
                  </div>
                  <div className="flex gap-3 mt-3 text-sm">
                    <img
                      loading="lazy"
                      src={tick}
                      className="shrink-0 self-start mt-1.5 w-3.5 aspect-[1.75]"
                    />
                    <div>
                      Don't worry you can update your policies when you are ready
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="flex relative flex-col items-start px-10 py-8 mt-11 max-w-full bg-white bg-opacity-40 rounded-md border border-neutral-200 min-h-[228px] max-sm:w-full w-[66%] max-md:px-5 max-md:mt-10">
        <div className="relative self-stretch text-xl font-medium text-black max-md:max-w-full">
          How much are your guests charged if they cancel after the free cancellation window?
        </div>
        <div className="flex relative gap-5 mt-8">
          <label className="flex items-center gap-2">
            <input
              type="radio"
              name="cancellationFee"
              value="firstNight"
              checked={cancellationFee === 'firstNight'}
              onChange={handleCancellationFeeChange}
              className="h-6 w-6 text-sky-600 checked:bg-sky-600 focus:ring-sky-600"
            />
            <span className="text-lg text-neutral-600">Cost of the first night</span>
          </label>
        </div>
        <div className="flex relative gap-5 mt-2.5 text-lg text-neutral-600">
          <label className="flex items-center gap-2">
            <input
              type="radio"
              name="cancellationFee"
              value="50percent"
              checked={cancellationFee === '50percent'}
              onChange={handleCancellationFeeChange}
              className="h-6 w-6 text-sky-600 checked:bg-sky-600 focus:ring-sky-600"
            />
            <span>50% of the total price</span>
          </label>
        </div>
        <div className="flex relative gap-5 mt-2.5 text-lg text-neutral-600">
          <label className="flex items-center gap-2">
            <input
              type="radio"
              name="cancellationFee"
              value="100percent"
              checked={cancellationFee === '100percent'}
              onChange={handleCancellationFeeChange}
              className="h-6 w-6 text-sky-600 checked:bg-sky-600 focus:ring-sky-600"
            />
            <span>100% of the total price</span>
          </label>
        </div>
      </div>
   
    </div>
  );
}
