import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  searchLocation: "",
  searchStartDate: null,
  searchEndDate: null,
  searchGuests: 0,
};

const searchBarSlice = createSlice({
  name: "searchBar",
  initialState,
  reducers: {
    setSearchLocation: (state, action) => {
      state.searchLocation = action.payload;
      // console.log(action.payload);
    },
    setstartDate: (state, action) => {
      state.searchStartDate = action.payload;
    },
    setendDate: (state, action) => {
      state.searchEndDate = action.payload;
    },
    setguests: (state, action) => {
      state.searchGuests = action.payload;
      // console.log(action.payload);
    },
  },
});

export const { setSearchLocation, setendDate, setstartDate, setguests } =
  searchBarSlice.actions;
export default searchBarSlice.reducer;
