import { IoIosArrowRoundBack } from "react-icons/io";
import { MdCircleNotifications } from "react-icons/md";

const Nonotifications = () => {
  return (
    <div className="p-4 h-screen">
      <div className="flex items-center p-2 rounded-md mb-4">
        <a href="#" className="flex items-center">
          <IoIosArrowRoundBack size={24} className="mr-2 transition-transform duration-300 ease-in-out transform hover:scale-150" />
          <span className="font-poppins text-xl lg:text-[28px] font-semibold leading-[42px] tracking-[0.005em] text-left">Notifications</span>
        </a>
      </div>
      <div className="flex flex-col items-center justify-center text-center align-center h-full ">
        <MdCircleNotifications size={90} color='grey' className="mb-4" />
        <span className="lg:w-[200px] text-base lg:text-lg  font-poppins font-semibold text-[hsla(0,0%,16%,1)]">No New Notifications</span>
        <span className="w-[170px] lg:w-[200px] h-[48px]  text-xs lg:text-sm text-[hsla(0,0%,53%,1)] font-normal font-poppins">New notifications will be displayed here</span>
      </div>
    </div>
  );
}

export default Nonotifications;
