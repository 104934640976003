import { Minus, Plus } from "lucide-react";
import { useState, useEffect } from "react";

const MobileTravellerOverlay = ({ travellers, setTravellers, closePopup }) => {
  const [adults, setAdults] = useState(travellers.adult);
  const [children, setChildren] = useState(travellers.child);
  const [infants, setInfants] = useState(travellers.infant);
  const [pets, setPets] = useState(travellers.pets);

  useEffect(() => {
    setTravellers({ adult: adults, child: children, infant: infants, pets });
  }, [adults, children, infants, pets]);

  return (
    <div
      onMouseLeave={closePopup}
      className="  rounded-[12px] p-5 bg-white  top-28 text-[#292929] w-full max-w-[302px] right-5 lg:right-auto"
    >
      <div className="flex flex-col gap-5">
        <div className="flex justify-between items-center">
          <h1>Adults</h1>
          <span className="flex gap-4 items-center">
            <button
              disabled={adults === 0}
              onClick={() => setAdults(adults - 1)}
              className="border rounded-[50%] p-2.5"
            >
              <Minus color="#7B7B7B" size={16} />
            </button>
            <span className="text-[#0466C8]">{adults}</span>
            <button className="border rounded-[50%] p-2.5">
              <Plus
                onClick={() => setAdults(adults + 1)}
                color="#7B7B7B"
                size={16}
              />
            </button>
          </span>
        </div>
        <div>
          <div className="flex justify-between items-center">
            <h1>Children</h1>
            <span className="flex gap-4 items-center">
              <button
                disabled={children === 0}
                onClick={() => setChildren(children - 1)}
                className="border rounded-[50%] p-2.5"
              >
                <Minus color="#7B7B7B" size={16} />
              </button>
              <span className="text-[#0466C8]">{children}</span>
              <button
                onClick={() => setChildren(children + 1)}
                className="border rounded-[50%] p-2.5"
              >
                <Plus color="#7B7B7B" size={16} />
              </button>
            </span>
          </div>
          <p className="text-sm text-[#7B7B7B] opacity-75">Ages 3 to 16</p>
        </div>
        <div>
          <div className="flex justify-between items-center">
            <h1>Infants</h1>
            <span className="flex gap-4 items-center">
              <button
                disabled={infants === 0}
                onClick={() => setInfants(infants - 1)}
                className="border rounded-[50%] p-2.5"
              >
                <Minus color="#7B7B7B" size={16} />
              </button>
              <span className="text-[#0466C8]">{infants}</span>
              <button className="border rounded-[50%] p-2.5">
                <Plus
                  onClick={() => setInfants(infants + 1)}
                  color="#7B7B7B"
                  size={16}
                />
              </button>
            </span>
          </div>
          <p className="text-sm text-[#7B7B7B] opacity-75">Below 2</p>
        </div>
        <div className="text-sm ">
          <span className="flex items-center  gap-3">
            <input
              onChange={() => setPets(!pets)}
              checked={pets}
              type="checkbox"
              id="pets"
              className="w-2.5 h-2.5"
            />

            <p>I am travelling with pets</p>
          </span>

          <p className="text-[8px] text-[#7B7B7B] ml-6">
            Only stays that that allows pets will be will be provided
          </p>
        </div>
      </div>
    </div>
  );
};

export default MobileTravellerOverlay;