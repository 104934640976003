import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { IoCloseSharp } from "react-icons/io5";
import Logo from "../../../assets/forgortPassword/Main logo.svg";
import ImageRight from "../../../assets/forgortPassword/CheckMail.svg";

const CheckMail = () => {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  const navigate = useNavigate();

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <section className="min-h-screen flex flex-col lg:flex-row lg:p-6 mx-auto">
      {/* Left column for the image */}
      <div className="relative lg:w-[27%] flex flex-col items-center justify-center lg:items-start">
        <button
          className="absolute top-5 left-10 lg:hidden text-[hsla(0,0%,16%,1)] transition-all duration-300 ease-in-out hover:scale-150"
          onClick={() => navigate(-1)}
        >
          <IoCloseSharp size={20} />
        </button>
        <div className="lg:bg-white bg-[hsla(185,100%,98%,1)] py-4 w-full h-[4.125rem] mx-auto lg:w-[15.36875rem] lg:h-[5rem] mx-auto lg:mb-12 lg:absolute lg:top-[20px] lg:left-[300px]">
          <img src={Logo} alt="Logo" className="mx-auto w-[5rem] h-[1.625rem] lg:w-[15.36875rem] lg:h-[5rem]" />
        </div>
      </div>

      {/* Right column for the message */}
      <div className="flex-1 flex items-center justify-start p-2 lg:p-4 lg:pl-4 bg-white">
        <div className="w-full max-w-md lg:max-w-[615px] lg:p-12 ">
        <img
          src={ImageRight}
          alt="Image banner Right"
          className="mx-auto lg:pl-6 w-[133px] lg:mt-6 h-[128] lg:w-[429px] lg:h-[415px]"
        />
          <h3 className="text-lg lg:text-2xl font-medium text-center font-poppins leading-[1.875rem] text-black mb-2 lg:mb-2">
            Check Your Email
          </h3>
          <p className="lg:text-xs text-[10px] font-poppins text-center leading-[18px] text-[hsla(210,96%,33%,1)] mb-3 lg:mb-3">
            We have sent a password reset link to your email address. Please check your inbox and follow the instructions to reset your password.
          </p>
          <button
            className="absolute hidden lg:block top-[90px] right-[400px] text-[hsla(0,0%,64%,1)] transition-all duration-300 ease-in-out hover:scale-150"
            onClick={() => navigate(-1)}
          >
            <IoCloseSharp size={24} />
          </button>
        </div>
      </div>
    </section>
  );
};

export default CheckMail;
