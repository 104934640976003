import Picker from "@/components/HomePage/hero/Picker";
import { useState } from "react";
import { properties } from "@/constants/data";
import Filter from "@/components/SearchResults/Filter";
import PickerSmallDevice from "@/components/HomePage/hero/PickerSmallDevice";
import filter from "@/assets/searchresults/filter.png";
import map from "@/assets/searchresults/map.png";
import Map from "@/components/SearchResults/Map";
import { Search, X } from "lucide-react";
import Property from "./Property";
import { PropertyWish } from "@/constants/data";
const SearchResults = () => {
    const [openFilter, setOpenFilter] = useState(false);
    const [openMap, setOpenMap] = useState(false);
    const [pin, setPin] = useState(properties[0]);
  
  
    return (
      <section className="w-full xl:max-w-[1246px] mx-auto p-4 mb-[4rem] relative ">
        {/* OPEN FILTER */}
        {openFilter && (
          <div className=" bg-white fixed border z-40 top-0 bottom-0 right-0 left-0 overflow-auto  ">
            <Filter setOpenFilter={setOpenFilter} />
          </div>
        )}
  
        {/*  DATE PICKER */}
        <section
          className={`flex flex-col gap-4 w-full md:max-w-[688px] xl:max-w-[1246px] mx-auto `}>
          <div className="hidden xl:block">
            <Picker />
          </div>
  
          {/* MOBILE DATE PICKER */}
          <div className="xl:hidden space-y-[18px]">
            <PickerSmallDevice />
            <div className="flex items-center justify-between w-full md:w-[592px] md:mx-auto">
              <div
                onClick={() => setOpenFilter(true)}
                className="flex items-center gap-3 cursor-pointer">
                <img
                  src={filter}
                  alt="filter icon"
                  className="w-4 h-[18px] object-cover"
                />
                <span className="text-laara-primary">Filter</span>
              </div>
              <div
                onClick={() => setOpenMap(!openMap)}
                className="flex items-center gap-3 cursor-pinter">
                <img src={map} alt="map icon" className="size-5 object-cover" />
                <span className="text-laara-primary">Map</span>
              </div>
            </div>
          </div>
  
          {/* LAUNCH MAP */}
          {openMap && (
            <div className="w-full h-auto mx-auto xl:w-[1259px] relative ">
              {/* SEARCH INPUT */}
              <div className="absolute top-5 left-1/2 transform -translate-x-1/2  md:w-[420px] mx-auto z-10">
                <div className="relative">
                  <input
                    type="text"
                    placeholder="Search on map..."
                    className="border outline-none rounded-[32px] py-2.5 px-4 w-[320px] md:w-[420px] shadow-lg"
                  />
                  <Search className="text-laara-primary size-5 absolute top-1/2 transform -translate-y-1/2 right-4 z-10" />
                </div>
              </div>
  
              <div
                onClick={() => setOpenMap(false)}
                className="hidden xl:absolute top-5 left-16 z-10 xl:flex items-center justify-center size-[46px] rounded-full bg-laara-primary cursor-pointer">
                <X className="text-white " />
              </div>
  
              {/* MAP */}
              <div
                // onClick={() => setOpenMap(true)}
                className="w-[375px] h-[607px] xl:w-[1259px] xl:h-[607px] md:h-[607px] md:w-[688px] mx-auto   ">
                <Map
                  width={"375px"}
                  height={"607px"}
                  mdWidth={"688px"}
                  mdHeight={"607px"}
                  xlWidth={"1259px"}
                  xlHeight={"607px"}
                  pin={pin}
                  setOpenMap={setOpenMap}
                  openMap={openMap}
                />
              </div>
            </div>
          )}
  
          <section className="flex gap-[34px] w-full ">
            <aside className="hidden xl:space-y-8 xl:flex xl:flex-col xl:max-w-[420px] xl:w-full">
              {/* MAP */}
              <div className="xl:max-w-[420px] w-full xl:h-[226.38px] rounded-[14px] ">
                {!openMap && (
                  <Map
                    width={"420px"}
                    height={"226.38px"}
                    xlWidth={"420px"}
                    xlHeight={"226.38px"}
                    pin={pin}
                    setOpenMap={setOpenMap}
                    openMap={openMap}
                  />
                )}
              </div>
              {!openMap && <Filter />}
            </aside>
  
            {/* RESULT LISTINGS */}
            {!openMap && (
              <section className="w-full xl:max-w-[792px] xl:w-full md:space-y-[41px]">
                    <h3 className="block md:hidden text-black font-medium text-[32px] leading-[48px] max-md:mb-5 ">
                    Suggestions
                    </h3>
                    {/* Rest of the content for small screens */}
                <div className="space-y-8 md:space-y-10 xl:space-y-8">
                {PropertyWish.map((property, index) => (
                  <Property key={index} property={property} />
                ))}
                </div>
              </section>
            )}
          </section>
        </section>
      </section>
    );
  };
  
  export default SearchResults;