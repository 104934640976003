import Picker from "@/components/HomePage/hero/Picker";
import { useCallback, useEffect, useState } from "react";
import { properties } from "@/constants/data";
import PropertyCard from "@/components/SearchResults/PropertyCard";
import Filter from "@/components/SearchResults/Filter";
import filter from "@/assets/searchresults/filter.png";
import map from "@/assets/searchresults/map.png";
import { Search, X } from "lucide-react";
import GMaps from "@/components/shared/Maps/GMaps";

import axios from "axios";
import { headers, baseURL } from "@/constants/apiconfig";
import {
  keepPreviousData,
  useQuery,
  useQueryClient,
} from "@tanstack/react-query";
import queryClient from "@/components/SearchResults/queryClient";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";

const SearchResults = () => {
  const [openFilter, setOpenFilter] = useState(false);
  const [openMap, setOpenMap] = useState(false);
  const [pin, setPin] = useState(properties[0]);
  const [filteredData, setFilteredData] = useState([]);
  const [hasMore, setHasMore] = useState(true);
  const [page, setPage] = useState(0);
  const [filters, setFilters] = useState({
    propertyType: "ALL",
    range: [1000, 100000],
    minSize: 0,
    maxSize: 0,
    amenities: [],
    rating: 0,
  });
  const token = useSelector((state) => state.user.acessToken);
  const location = useLocation();
  const region = useSelector((state) => state.searchBar.searchLocation);
  const queryClient = useQueryClient();

  const mapsContainer = {
    width: "100%",
    height: "226px",
  };
  const buildQueryParams = (page, propertyType, rating, amenities) => {
    const params = new URLSearchParams();
    if (page !== undefined) params.set("offset", page * 10);
    params.set("limit", 10);
    if (propertyType && propertyType !== "ALL")
      params.set("type", propertyType);
    if (rating && rating > 1) params.set("rating", rating);
    if (amenities && amenities?.length > 0)
      params.set("amenities", amenities?.join(","));
    return params.toString();
  };

  const fetchFilteredStays = useCallback(
    async ({ queryKey }) => {
      const [_key, { page, propertyType, amenities, rating }] = queryKey;
      const queryString = buildQueryParams(
        page,
        propertyType,
        rating,
        amenities
      );

      const { data } = await axios.get(
        `${baseURL}/search/stays/filtered?${queryString}`,
        { headers: headers(token) }
      );
      return data;
    },
    [token]
  );

  const { data, isLoading, isError, isFetching, error } = useQuery({
    queryKey: ["filteredStays", { page, ...filters }],
    queryFn: fetchFilteredStays,
    keepPreviousData: true,
    staleTime: 5 * 60 * 1000,
    retries: 0,
  });

  useEffect(() => {
    if (data) {
      setFilteredData(data.data);
      setHasMore(data?.data?.length === 10);
    }
  }, [data]);

  useEffect(() => {
    if (hasMore) {
      const nextPage = page + 1;
      queryClient.prefetchQuery(
        ["filteredStays", { page: nextPage, ...filters }],
        fetchFilteredStays
      );
    }
  }, [page, filters, fetchFilteredStays, hasMore, queryClient]);

  const handleNextPage = () => {
    if (hasMore) setPage((prevPage) => prevPage + 1);
  };

  const handlePreviousPage = () => {
    if (page > 0) setPage((prevPage) => prevPage - 1);
  };

  // ... (rest of the component code remains the same)

  return (
    <section className="w-full xl:max-w-[1440px] mx-auto p-4 md:px-10 xl:px-[88px] mb-[4rem] relative ">
      {/* OPEN FILTER */}
      {openFilter && (
        <div className=" bg-white fixed border z-40 top-0 bottom-0 right-0 left-0 overflow-auto  ">
          <Filter
            setOpenFilter={setOpenFilter}
            filters={filters}
            setFilters={setFilters}
          />
        </div>
      )}

      {/*  DATE PICKER */}
      <section
        className={`flex flex-col gap-4 w-full md:max-w-[688px] xl:max-w-[1264px] mx-auto `}
      >
        <div className="blocks">
          <Picker setFilteredData={setFilteredData} />
        </div>

        {/* MOBILE DATE PICKER */}
        <div className="xl:hidden space-y-[18px]">
          <div className="flex items-center justify-between w-full md:w-[592px] md:mx-auto">
            <div
              onClick={() => setOpenFilter(true)}
              className="flex items-center gap-3 cursor-pointer"
            >
              <img
                src={filter}
                alt="filter icon"
                className="w-4 h-[18px] object-cover"
              />
              <span className="text-laara-primary">Filter</span>
            </div>
            <div
              onClick={() => setOpenMap(!openMap)}
              className="flex items-center gap-3 cursor-pointer"
            >
              <img src={map} alt="map icon" className="size-5 object-cover" />
              <span className="text-laara-primary">Map</span>
            </div>
          </div>
        </div>

        {/* LAUNCH MAP */}
        {openMap && (
          <div className="w-full h-auto mx-auto xl:w-[1259px] relative ">
            <div
              onClick={() => setOpenMap(false)}
              className="hidden xl:absolute top-5 left-16 z-10 xl:flex items-center justify-center size-[46px] rounded-full bg-laara-primary cursor-pointer"
            >
              <X className="text-white " />
            </div>

            <div className="w-[375px] h-[607px] xl:w-[1259px] xl:h-[607px] md:h-[607px] md:w-[688px] mx-auto   ">
              <GMaps
                containerStyle={{
                  width: openMap ? "100%" : "420px",
                  height: openMap ? "607px" : "226px",
                }}
                placesAutoComplete={true}
                interactive={false}
                pin={pin}
                openMap={openMap}
                setOpenMap={setOpenMap}
              />
            </div>
          </div>
        )}

        {/* DESKTOP */}
        <section className="flex gap-[34px] w-full ">
          <aside className="hidden xl:space-y-8 xl:flex xl:flex-col xl:max-w-[420px] xl:w-full">
            <div className="xl:max-w-[420px] w-full xl:h-[226.38px] rounded-[14px] ">
              {!openMap && (
                <GMaps
                  containerStyle={mapsContainer}
                  placesAutoComplete={false}
                  interactive={false}
                  pin={pin}
                  openMap={openMap}
                  setOpenMap={setOpenMap}
                />
              )}
            </div>
            {!openMap && <Filter filters={filters} setFilters={setFilters} />}
          </aside>

          <section className="w-full xl:maxs-w-[792px] xl:w-full md:space-y-[41px]">
            <h3 className="hidden md:block text-black font-medium text-xl ">
              Properties found in {region}
            </h3>
            <div className="space-y-8 md:space-y-10 xl:space-y-8">
              {filteredData?.length < 1 && (
                <div className="min-h-[40vh] flex flex-col items-center justify-center">
                  <h1 className="text-xl text-black">No results</h1>
                </div>
              )}
              {filteredData?.map((property) => (
                <PropertyCard
                  key={property.id}
                  property={property}
                  setPin={setPin}
                  setOpenMap={setOpenMap}
                />
              ))}
            </div>
            {/* PAGINATION CONTROL */}
            <div className="my-6 flex items-center justify-between">
              <button
                onClick={handlePreviousPage}
                disabled={page === 0 || isLoading}
                className={`text-white font-medium text-sm py-2.5 px-4 rounded-[32px] bg-laara-primary w-[92px] ${
                  page === 0 ? "invisible" : "visible"
                }`}
              >
                Previous
              </button>

              <p className="text-sm text-center">Page {page + 1}</p>

              <button
                onClick={handleNextPage}
                disabled={!hasMore || isLoading}
                className={`text-white font-medium text-sm py-2.5 px-4 rounded-[32px] bg-laara-primary w-[92px] ${
                  !hasMore ? "invisible" : "visible"
                }`}
              >
                Next
              </button>
            </div>
          </section>
        </section>
      </section>
    </section>
  );
};
export default SearchResults;
