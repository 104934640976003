import React, { useState } from 'react'
import { Plus, Minus } from 'lucide-react';

export const PaidAmenities = () => {
    const [checkedItems, setCheckedItems] = useState({});
    const [itemQuantities, setItemQuantities] = useState({});

    const handleCheckboxChange = (item) => {
        setCheckedItems(prev => ({ ...prev, [item]: !prev[item] }));
    };

    const handleQuantityChange = (item, change) => {
        setItemQuantities(prev => ({
            ...prev,
            [item]: Math.max(0, (prev[item] || 0) + change)
        }));
    };

    const PaidItems = ['Chairs', 'Pen', 'Notebook', 'Tables', 'Projector', 'Bottled Water', 'Microphones', 'Catering Service', 'AV equipment', 'Decoration Services', 'Built-in Speakers', 'Security Personnel', 'Workspace desk'];

    return (
        <form className='relative space-y-4 sm:space-y-6'>
            <div>
                <h4 className="font-medium text-xl">What Can the guests use in this room?</h4>

                <div className="w-full max-w-full sm:max-w-[433px] p-3 sm:p-4 border bg-[#FFFFFF] rounded-lg">
                    <h2 className="text-xl sm:text-xl font-medium mb-1 sm:mb-2">Paid Amenities</h2>
                    <p className='text-[#9F9C9C] font-normal text-xs sm:text-sm mb-2 sm:mb-3'>Selected items comes at an extra cost to guests</p>
                    <div className="grid grid-cols-1 gap-4">
                        {PaidItems.map((item) => (
                            <div key={item} className="flex items-center justify-between">
                                <label className="inline-flex items-center flex-grow">
                                    <input
                                        type="checkbox"
                                        className="mr-3 w-5 h-5 accent-[#9F9C9C]"
                                        name={item.toLowerCase().replace(' ', '-')}
                                        checked={checkedItems[item] || false}
                                        onChange={() => handleCheckboxChange(item)}
                                    />
                                    <span className={`text-base font-normal leading-relaxed cursor-pointer ${checkedItems[item] ? 'text-[#9F9C9C]' : 'text-black'}`}>
                                        {item}
                                    </span>
                                </label>
                                <div className="flex items-center space-x-2 ml-2">
                                    <button
                                        type="button"
                                        className="w-8 h-8 flex items-center justify-center text-[#9F9C9C] text-xl font-medium rounded-full border border-[#9B9B9B]"
                                        onClick={() => handleQuantityChange(item, -1)}
                                    >
                                        <Minus className="w-4 h-4" />
                                    </button>
                                    <span className="w-8 text-center text-[#0466C8] text-lg font-semibold">
                                        {itemQuantities[item] || 0}
                                    </span>
                                    <button
                                        type="button"
                                        className="w-8 h-8 flex items-center justify-center text-[#9F9C9C] text-xl font-medium rounded-full border border-[#9B9B9B]"
                                        onClick={() => handleQuantityChange(item, 1)}
                                    >
                                        <Plus className="w-4 h-4" />
                                    </button>
                                </div>


                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </form>
    )
}