import React, { useState } from 'react';
import Vector from "@/assets/AdminDash/Vector.svg";
import User from "@/assets/AdminDash/profile-2user.svg";
import House from "@/assets/AdminDash/house.svg";
import Verify from "@/assets/AdminDash/verify.svg";
import Wallet from "@/assets/AdminDash/empty-wallet-change.svg";
import FavChart from "@/assets/AdminDash/favorite-chart.svg";
import Command from "@/assets/AdminDash/command.svg";
import Setting2 from "@/assets/AdminDash/setting-2.svg";
import logo from "@/assets/shared/Group.svg";
import profileuser2 from "@/assets/AdminDash/Avatar.svg";
import arrow from "@/assets/AdminDash/Arrow.svg";
import LogoutIcon from "@/assets/AdminDash/logout.svg";

const Sidebar = ({ setActiveModule, isOpen, setIsOpen }) => {
    const [selectedModule, setSelectedModule] = useState('Dashboard');
    const [userManagementOpen, setUserManagementOpen] = useState(false);
    const [systemManagementOpen, setSystemManagementOpen] = useState(false);

    const modules = [
        { name: 'Dashboard', icon: Vector },
        {
            name: 'User Management',
            icon: User,
            subModules: ['Guests', 'Hosts']
        },
        { name: 'Property Management', icon: House },
        { name: 'Booking Management', icon: Verify },
        { name: 'Financial Management', icon: Wallet },
        { name: 'Ruby', icon: FavChart },
        {
            name: 'System Management',
            icon: Command,
            subModules: ['Overall', 'Frontdesk', 'Housekeeping', 'Security']
        },
        { name: 'Settings', icon: Setting2 },
    ];

    const logoutModule = { name: 'Logout', icon: LogoutIcon };

    const isSelected = (moduleName) => {
        if (moduleName === 'User Management') {
            return userManagementOpen || selectedModule === 'Guests' || selectedModule === 'Hosts';
        }
        if (moduleName === 'System Management') {
            return systemManagementOpen || ['Overall', 'Frontdesk', 'Housekeeping', 'Security'].includes(selectedModule);
        }
        return selectedModule === moduleName;
    };

    const renderModule = (module, isLogout = false) => (
        <div key={module.name}>
            <a href="#"
                className={`flex items-center py-2.5 px-4 rounded ${
                    isLogout 
                    ? 'text-[#C03744]' 
                    : isSelected(module.name) 
                        ? 'text-[#0466c8]' 
                        : 'text-[#615D5D]'
                }`}
                onClick={() => {
                    if (module.name === 'User Management') {
                        setUserManagementOpen(!userManagementOpen);
                        setSelectedModule(module.name);
                    } else if (module.name === 'System Management') {
                        setSystemManagementOpen(!systemManagementOpen);
                        setSelectedModule(module.name);
                    } else {
                        setUserManagementOpen(false);
                        setSystemManagementOpen(false);
                        setActiveModule(module.name);
                        setSelectedModule(module.name);
                        setIsOpen(false);
                    }
                }}
            >
                <img
                    src={module.icon}
                    alt={`${module.name} icon`}
                    className={`w-6 h-6 mr-3 ${isLogout
                        ? '[filter:invert(32%)_sepia(93%)_saturate(1352%)_hue-rotate(323deg)_brightness(84%)_contrast(88%)]'
                        : isSelected(module.name)
                            ? '[filter:invert(46%)_sepia(65%)_saturate(2760%)_hue-rotate(201deg)_brightness(99%)_contrast(96%)]'
                            : 'filter grayscale'
                        }`}
                    style={isSelected(module.name) ? { filter: 'invert(20%) sepia(90%) saturate(6000%) hue-rotate(220deg) brightness(90%) contrast(90%)' } : {}}
                />
                <span className={`text-normal font-medium leading-6  ${
                    isLogout 
                    ? 'text-[#C03744]' 
                    : isSelected(module.name) 
                        ? 'text-[#0466c8]' 
                        : 'text-[#615D5D]'
                } sm:leading-6 md:leading-6 lg:leading-6 xl:leading-6`}>
                    {module.name}
                </span>
                {module.subModules && (
                    <svg
                        className={`ml-auto h-5 w-5 transform ${(module.name === 'User Management' ? userManagementOpen : systemManagementOpen) ? 'rotate-180' : ''}`}
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                        aria-hidden="true"
                    >
                        <path fillRule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clipRule="evenodd" />
                    </svg>
                )}
            </a>
            {module.subModules && ((module.name === 'User Management' && userManagementOpen) || (module.name === 'System Management' && systemManagementOpen)) && (
                <div className="flex justify-center">
                    <ul className="list-disc pl-5">
                        {module.subModules.map((subModule) => (
                            <li key={subModule}>
                                <a
                                    href="#"
                                    className={`block px-4 text-sm ${selectedModule === subModule ? 'text-[#0466c8]' : 'text-[#615D5D]'}`}
                                    onClick={() => {
                                        setActiveModule(subModule);
                                        setSelectedModule(subModule);
                                        setIsOpen(false);
                                    }}
                                >
                                    {subModule}
                                </a>
                            </li>
                        ))}
                    </ul>
                </div>
            )}
        </div>
    );

    return (
        <>
            <div className={`bg-white text-black w-full max-w-[280px] h-[1209px] fixed inset-y-0 left-0 transform ${isOpen ? 'translate-x-0' : '-translate-x-full'} md:relative md:translate-x-0 z-20 flex flex-col overflow-y-auto`}>
                <div className="relative w-[163px] h-[52.96px] mt-2 mb-8 sm:w-full sm:max-w-[163px] sm:h-auto sm:aspect-[163/52.96]">
                    <img src={logo} alt="Logo" className="w-full h-full object-contain"/>
                </div>
                <div className="flex items-center w-[213px] h-[43px] ml-[14px] mb-6 gap-[21px] sm:w-full sm:max-w-[213px] sm:h-auto sm:min-h-[43px] sm:flex-wrap">
                    <div className="flex">
                        <div className="flex items-center space-x-3">
                            <div className="w-12 h-12 rounded-full flex-shrink-0 flex items-center justify-center overflow-hidden">
                                <img src={profileuser2} alt="profile" className="w-full h-full object-cover rounded-full" />
                            </div>
                            <div>
                                <h3 className="font-semibold text-gray-800">Noah Matheka</h3>
                                <p className="text-sm text-gray-500">Laara Admin</p>
                            </div>
                        </div>
                        <img src={arrow} alt="arrow" className='ml-7' />
                    </div>
                </div>

                <div className="flex-grow w-full w-[243px] ">
                    <div className="w-full mx-auto flex flex-col ">
                        {modules.map(module => renderModule(module))}
                    </div>
                </div>

                <div className="absolute left-0 w-full" style={{ top: '874px' }}>
                    {renderModule(logoutModule, true)}
                </div>
            </div>
            {isOpen && (
                <div className="fixed inset-0 bg-gray-600 bg-opacity-50 z-10 md:hidden" onClick={() => setIsOpen(false)}></div>
            )}
        </>
    );
};

export default Sidebar;