import { combineReducers, configureStore } from "@reduxjs/toolkit";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage"; // defaults to localStorage for web
import userReducer from "./user/userSlice";
import hostListingReducer from "./Listing/listingSlice";
import payNowModalReducer from "./paynow/PayNowSlice";
// import searchBarReducer from "./searchBar/searchBarSlice";
import searchBarReducer from "./searchBar/searchbar";

const rootReducer = combineReducers({
  user: userReducer,
  hostListing: hostListingReducer,
  payNowModal: payNowModalReducer,
  searchBar: searchBarReducer,
});

const persistConfig = {
  key: "root",
  storage,
  // blacklist: ["hostListing"], // Specify the slice to not persist
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) => {
    return getDefaultMiddleware({
      serializableCheck: false,
    });
  },
});

export const persistor = persistStore(store);
