import { useState } from "react";

import { Search } from "lucide-react";
import MobileOverlay from "./MobileOverlay";

const PickerSmallDevice = () => {
  const [modalOpen, setModalOpen] = useState(false);
  const [location, setLocation] = useState("");
  const [checkin, setCheckIn] = useState("");
  const [checkOut, setCheckOut] = useState("");
  const [travellers, setTravellers] = useState({
    adult: 0,
    child: 0,
    infant: 0,
    pets: false,
  });

  const toggleModal = () => {
    setModalOpen(!modalOpen);
  };

  // take only date, month, year in format DD/MM/YYYY

  const formatDate = (date) => {
    const d = new Date(date);
    return `${d.getDate()}/${d.getMonth() + 1}/${d.getFullYear()}`;
  };

  // check whether travellers are selected or not
  const isTravellerSelected = () => {
    return travellers.adult + travellers.child + travellers.infant > 0;
  };

  // handle search
  const handleSearch = () => {
    console.log(location, checkin, checkOut, travellers);
  };

  return (
    <div className="md:hidden rounded-[30px] bg-[#F4FEFF] flex items-center justify-start py-[12px] px-[8px] gap-4">
      <button
        onClick={handleSearch}
        className="flex items-center justify-center   w-fit"
      >
        <Search size={20} color="#0466C8" />
      </button>

      <div className="flex flex-col gap-[8px] w-full justify-start items-start">
        <h2 className="text-lg text-[#292929] font-medium">Browse Stays</h2>
        <span className="divide-x text-[12px] text-[#9B9B9B] flex gap-2  text-left  w-full">
          <button onClick={() => setModalOpen(true)} className="">
            {location ? location : "Pick a location"}
          </button>
          <button onClick={() => setModalOpen(true)} className="pl-1 ">
            {checkin
              ? `${formatDate(checkin)} - ${formatDate(checkOut)}`
              : "Choose date"}
          </button>
          <button onClick={() => setModalOpen(true)} className=" pl-1 ">
            {isTravellerSelected()
              ? `${
                  travellers.adult + travellers.child + travellers.infant
                } guests`
              : "Add travellers"}
          </button>
        </span>
      </div>
      {modalOpen && (
        <MobileOverlay
          location={location}
          setLocation={setLocation}
          checkin={checkin}
          setCheckIn={setCheckIn}
          checkout={checkOut}
          setCheckout={setCheckOut}
          travellers={travellers}
          setTravellers={setTravellers}
          toggleModal={toggleModal}
        />
      )}
    </div>
  );
};

export default PickerSmallDevice;