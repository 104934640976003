import PropTypes from 'prop-types';
import { Link, useParams } from 'react-router-dom';

const EditRoomOverlay = ({ closeOverlay, roomNumber }) => {
    const { id: propertyId } = useParams(); 

    return (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
            <div className="bg-white p-5 rounded-lg relative w-3/4 max-w-lg max-h-[90vh] overflow-y-auto">
                <button 
                    onClick={closeOverlay} 
                    className="absolute top-3 right-3 w-6 h-6 border border-gray-700 rounded-full flex items-center justify-center text-gray-700 hover:bg-gray-700 hover:text-white transition-all"
                >
                    X
                </button>
                <h2 className="text-lg mb-4 font-medium">Edit Room Details</h2>
                <ul className="w-full p-2 flex flex-col items-center text-sm gap-3">
                    <Link to={`/edit-property-accessibility-features/${propertyId}`} className="w-full">
                        <li className="hover:bg-gray-200 w-full h-8 rounded-full flex items-center justify-center transition-all">Accessibility Features</li>
                    </Link>
                    <Link to={`/edit-property-availability/${propertyId}`} className="w-full">
                        <li className="hover:bg-gray-200 w-full h-8 rounded-full flex items-center justify-center transition-all">Availability</li>
                    </Link>
                    <Link to={`/property/${propertyId}/rooms/${roomNumber}/edit-bathrooms`} className="w-full">
                        <li className="hover:bg-gray-200 w-full h-8 rounded-full flex items-center justify-center transition-all">Bathrooms</li>
                    </Link>
                    <Link to={`/edit-property-general-facilities/${propertyId}`} className="w-full">
                        <li className="hover:bg-gray-200 w-full h-8 rounded-full flex items-center justify-center transition-all">General Facilities</li>
                    </Link>
                    <Link to={`/edit-property-meals/${propertyId}`} className="w-full">
                        <li className="hover:bg-gray-200 w-full h-8 rounded-full flex items-center justify-center transition-all">Meal Plans</li>
                    </Link>
                    <Link to={`/property/${propertyId}/rooms/${roomNumber}/edit-payments`} className="w-full">
                        <li className="hover:bg-gray-200 w-full h-8 rounded-full flex items-center justify-center transition-all">Payments</li>
                    </Link>
                    <Link to={`/property/${propertyId}/rooms/${roomNumber}/edit-room-pricing`} className="w-full">
                        <li className="hover:bg-gray-200 w-full h-8 rounded-full flex items-center justify-center transition-all">Pricings</li>
                    </Link>
                    <Link to={`/property/${propertyId}/rooms/${roomNumber}/edit-room-amenities`} className="w-full">
                        <li className="hover:bg-gray-200 w-full h-8 rounded-full flex items-center justify-center transition-all">Room Amenities</li>
                    </Link>
                    <Link to={`/property/${propertyId}/rooms/${roomNumber}/edit-room-description`} className="w-full">
                        <li className="hover:bg-gray-200 w-full h-8 rounded-full flex items-center justify-center transition-all">Room Description</li>
                    </Link>
                    <Link to={`/property/${propertyId}/rooms/${roomNumber}/edit-room-details`} className="w-full">
                        <li className="hover:bg-gray-200 w-full h-8 rounded-full flex items-center justify-center transition-all">Room Details</li>
                    </Link>
                    <Link to={`/property/${propertyId}/rooms/${roomNumber}/edit-room-photos`} className="w-full">
                        <li className="hover:bg-gray-200 w-full h-8 rounded-full flex items-center justify-center transition-all">Room Photos</li>
                    </Link>
                    <Link to={`/edit-property-safety-facilities/${propertyId}`} className="w-full">
                        <li className="hover:bg-gray-200 w-full h-8 rounded-full flex items-center justify-center transition-all">Safety Facilities</li>
                    </Link>
                </ul>
            </div>
        </div>
    );
};

EditRoomOverlay.propTypes = {
    closeOverlay: PropTypes.func.isRequired,
    roomNumber: PropTypes.string.isRequired 
};

export default EditRoomOverlay;
