import React, { useState } from 'react'
import "./Support.css"
import { supportcategories, accountTypes } from '@/constants/data';
import sendarrow from "@/assets/support/arrow-down-01.svg"
import search from "@/assets/support/search-01.svg"
import { Link } from 'react-router-dom';

export const Support = () => {
    const [activeTab, setActiveTab] = useState(0);

    const renderTabContent = () => {
        switch (activeTab) {
            case 0:
                return (
                    <div className='w-full max-w-[784px] mx-auto'>
                        <div className="grid grid-cols-2 sm:grid-cols-2 lg:grid-cols-4 gap-4 mt-8">
                            {supportcategories.map((category, index) => (
                                <Link key={index} to={category.link} className="text-inherit no-underline" >
                                    <div className="flex flex-col h-[130px] md:h-[120px] lg:h-[150px] p-3 sm:p-4 border rounded-2xl cursor-pointer">
                                        <h3 className="text-base sm:text-lg font-medium leading-6 text-[#292929] text-left">{category.title}</h3>
                                        <p className="text-sm sm:text-base font-normal leading-5 text-left text-[#888888]">{category.description}</p>

                                    </div>
                                </Link>
                            ))}
                        </div>
                    </div>
                );
            case 1:
                return (
                    <div className='w-full max-w-[784px] mx-auto'>
                        <div className="grid grid-cols-2 sm:grid-cols-2 lg:grid-cols-4 gap-4 mt-8">
                            {supportcategories.map((category, index) => (
                                <Link key={index} to={category.link} className="text-inherit no-underline" >
                                    <div className="flex flex-col h-[130px] md:h-[120px] lg:h-[150px] p-3 sm:p-4 border rounded-2xl cursor-pointer">
                                        <h3 className="text-base sm:text-lg font-medium leading-6 text-[#292929] text-left">{category.title}</h3>
                                        <p className="text-sm sm:text-base font-normal leading-5 text-left text-[#888888]">{category.description}</p>

                                    </div>
                                </Link>
                            ))}
                        </div>
                    </div>
                );
            case 2:
                return (
                    <div className='w-full max-w-[784px] mx-auto'>
                        <div className="grid grid-cols-2 sm:grid-cols-2 lg:grid-cols-4 gap-4 mt-8">
                            {supportcategories.map((category, index) => (
                                <Link key={index} to={category.link} className="text-inherit no-underline" >
                                    <div className="flex flex-col h-[130px] md:h-[120px] lg:h-[150px] p-3 sm:p-4 border rounded-2xl cursor-pointer">
                                        <h3 className="text-base sm:text-lg font-medium leading-6 text-[#292929] text-left">{category.title}</h3>
                                        <p className="text-sm sm:text-base font-normal leading-5 text-left text-[#888888]">{category.description}</p>

                                    </div>
                                </Link>
                            ))}
                        </div>
                    </div>
                );
            case 3:
                return (
                    <div className='w-full max-w-[784px] mx-auto'>
                        <div className="grid grid-cols-2 sm:grid-cols-2 lg:grid-cols-4 gap-4 mt-8">
                            {supportcategories.map((category, index) => (
                                <Link key={index} to={category.link} className="text-inherit no-underline" >
                                    <div className="flex flex-col h-[130px] md:h-[120px] lg:h-[150px] p-3 sm:p-4 border rounded-2xl cursor-pointer">
                                        <h3 className="text-base sm:text-lg font-medium leading-6 text-[#292929] text-left">{category.title}</h3>
                                        <p className="text-sm sm:text-base font-normal leading-5 text-left text-[#888888]">{category.description}</p>

                                    </div>
                                </Link>
                            ))}
                        </div>
                    </div>
                );
            default:
                return null;
        }
    };

    return (
        <div className="max-w-[1440px] mx-auto px-4 sm:px-6 lg:px-8">
            <main className="w-full mt-16 sm:mt-10 lg:mt-14 flex flex-col items-center mb-[200px]">
                <div className="w-full max-w-3xl text-laara-primary">
                    <h1 className="text-xl sm:text-2xl md:text-[28px] font-semibold leading-tight sm:leading-normal md:leading-[42px] text-center">Hello Francis,</h1>
                    <p className="text-center mt-2 text-base sm:text-lg md:text-2xl font-normal leading-snug sm:leading-relaxed md:leading-9">How can we help you?</p>

                    <div className="mt-6 relative w-full">
                        <img
                            src={search}
                            alt=""
                            className="absolute left-4 top-1/2 transform -translate-y-1/2 z-10 w-5 h-5"
                        />
                        <input
                            type="text"
                            placeholder="Search topics"
                            className="w-full h-10 sm:h-12 pl-10 pr-12 border rounded-full border-laara-primary focus:outline-none text-black"
                        />

                    </div>

                    <div className="flex justify-center mt-6 border-b">
                        {accountTypes.map((account, index) => (
                            <button
                                key={index}
                                className={`px-2 sm:px-4 py-2 text-xs sm:text-sm md:text-base flex items-center ${activeTab === index
                                        ? 'border-b-2 border-laara-primary text-laara-primary'
                                        : 'text-gray-500'
                                    }`}
                                onClick={() => setActiveTab(index)}
                            >
                                <img
                                    src={account.icon}
                                    alt={`${account.type} icon`}
                                    className={`w-4 h-4 sm:w-5 sm:h-5 mr-1 sm:mr-2 ${activeTab === index ? 'filter-laara-primary' : 'filter-gray'
                                        }`}
                                />
                                {account.type}
                            </button>
                        ))}
                    </div>

                    {renderTabContent()}

                    <Link to="/message" className="text-inherit no-underline">
                        <div className="mt-8 w-full max-w-[772px] h-auto p-4 border rounded-lg flex flex-col-2 sm:flex-row justify-between items-start sm:items-center gap-4 sm:gap-8 cursor-pointer hover:bg-gray-50 transition-colors">
                            <div className="flex flex-col">
                                <span className="text-xl sm:text-2xl text-[#292929] font-medium leading-9 text-left">Send us a message</span>
                                <span className="text-xs sm:text-sm text-[#888888] mt-1">Get a response within 24hrs</span>
                            </div>
                            <img src={sendarrow} alt="" className="w-6 h-6 cursor-pointer" />
                        </div>
                    </Link>

                </div>
            </main>
        </div>
    )
}
