import { useState } from 'react';
import { GoHeart, GoHeartFill } from "react-icons/go";
import location from "@/assets/shared/location.png";
import star from "@/assets/shared/star.png";
import axios from 'axios';
import { baseURL, headers } from '@/constants/apiconfig';
import { useSelector } from "react-redux";
export default function Wishes({ wishes }) {
  const [liked, setLiked] = useState(true);
  const { currentUser, accessToken } = useSelector((state) => state.user);  
  const token = currentUser?.stsTokenManager?.accessToken || accessToken;
  const handleLikeClick = async () => {
    try {
      // Using property name and first image ID for removal
      const propertyName = wishes.name;
      const firstImageId = wishes.propertyImages[0].images.id;
      
      // Construct the endpoint using property name and image ID
      const endpoint = `${baseURL}/users/wishlist/remove-property/${propertyName}/${firstImageId}`;
      
      if (liked) {
        // Remove from wishlist
        await axios.delete(endpoint, {
          headers: headers(token),
        });
      } else {
        // Add to wishlist logic here if needed
      }
      setLiked((prevLiked) => !prevLiked);
    } catch (error) {
      console.error('Error removing property from wishlist:', error);
    }
  };

  const firstImage = wishes.propertyImages.length > 0 ? wishes.propertyImages[0].images.url : '';
  const price = wishes.rooms[0]?.roomTypes.pricings[0]?.price ?? 'N/A';
  const rating = wishes.propertyRatings?.avgRating ?? 'No rating';

  return (
    <div className="flex gap-5 max-md:flex-col max-md:gap-0">
      <div className="flex flex-col w-full max-md:ml-0 max-md:w-full sm:w-full">
        <div className="flex flex-col grow rounded-3xl max-md:mt-5">
          <div className="flex overflow-hidden relative flex-col justify-end items-end px-16 pt-4 pb-20 w-full rounded-3xl aspect-[1.2] max-md:pl-5">
            <img
              loading="lazy"
              src={firstImage}
              alt={wishes.name}
              className="object-cover absolute inset-0 w-full h-full"
            />
            <div className="absolute top-4 right-4 cursor-pointer w-8 h-8" onClick={handleLikeClick}>
              {liked ? (
                <GoHeartFill className="text-red-500 w-full h-full" />
              ) : (
                <GoHeart className="text-white w-full h-full" />
              )}
            </div>
          </div>
          <div className="flex flex-col justify-center px-3.5 pb-2.5 mt-2.5">
            <div className="flex flex-col justify-center">
              <div className="text-2xl font-medium leading-7 text-zinc-600">
                {wishes.name}
              </div>
              <div className="flex gap-0 justify-between mt-2.5 text-lg">
                <div className="flex flex-1 gap-2.5 text-zinc-800">
                  <img
                    loading="lazy"
                    src={location}
                    className="shrink-0 my-auto aspect-square w-[17px]"
                  />
                  <div>{`${wishes.address.town}, ${wishes.address.country}`}</div>
                </div>
                <div className="flex gap-1 justify-center whitespace-nowrap text-neutral-500">
                  <img
                    loading="lazy"
                    src={star}
                    className="shrink-0 my-auto aspect-square w-[18px]"
                  />
                  <div>{rating}</div>
                </div>
              </div>
            </div>
            <div className="justify-center mt-10 text-2xl font-semibold leading-7 text-right text-sky-600 whitespace-nowrap">
              {`$${price}`}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
