import React, { useState } from 'react';
import { Link } from "react-router-dom";
import { Card, CardContent } from "@/components/ui/card";
import {
  Carousel,
  CarouselContent,
  CarouselItem,
  CarouselNext,
  CarouselPrevious,
} from "@/components/ui/carousel";
import { GoHeart, GoHeartFill } from "react-icons/go";
import star from "@/assets/shared/star.svg";
import location from "@/assets/shared/location.svg";
import { rooms } from "@/constants/data";

export default function TopPicks({ pageTitle }) {
  const [likedRooms, setLikedRooms] = useState({});

  const toggleLike = (index) => {
    setLikedRooms(prev => ({
      ...prev,
      [index]: !prev[index]
    }));
  };

  const showLikeButton = pageTitle === "Top Picks";

  return (
    <section className="top-picks-padding xl:px-[88px] w-full relative">
      <h2 className="text-[#3C3C3C] text-center md:text-start font-semibold text-xl leading-[32px] md:leading-[34px] xl:text-[20px] xl:leading-[39px]">
        {pageTitle}
      </h2>
      <Carousel
        opts={{
          align: "start",
        }}
        className=" w-full mx-auto  mt-5">
        <CarouselContent>
          {rooms.map((room, index) => (
            <CarouselItem
              key={index}
              className="md:basis-2/3 lg:basis-1/2 xl:basis-1/4">
              <div className="relative">
                <Card className="rounded-[18px] w-full">
                  <div className="relative">
                    <img
                      src={pageTitle === "Top Picks" ? room.image : room.destinationImage}
                      alt={room.title}
                      className="w-full h-auto object-cover rounded-t-[18px]"
                    />
                    {showLikeButton && (
                      <button
                        onClick={() => toggleLike(index)}
                        className="absolute top-2 right-2 p-2 bg-opacity-70 rounded-full"
                      >
                        {likedRooms[index] ? (
                          <GoHeartFill className="text-red-500 text-2xl" />
                        ) : (
                          <GoHeart className="text-white text-2xl" />
                        )}
                      </button>
                    )}
                  </div>
                  <CardContent className="px-[14px] pb-[18px] pt-[14px] flex flex-col gap-6">
                    <div className="flex flex-col gap-2.5">
                      <div>
                        <h2 className="text-lg font-medium">{pageTitle === "Top Picks" ? room.title : room.destinationtitle}</h2>
                        {pageTitle !== "Popular Destinations" && (
                          <p className="text-[#6D6D6D] leading-[26px]">
                            From{" "}
                            <span className="text-black font-normal">
                              {room.price}
                            </span>{" "}
                            per night
                          </p>
                        )}
                      </div>
                      <div className="flex items-center justify-between">
                        <div className="flex items-center gap-1">
                          <img src={location} alt="location" loading="lazy" />
                          <p className="text-[#7B7B7B]">{pageTitle === "Top Picks" ? room.location : room.destinationlocation}</p>
                        </div>
                        {pageTitle !== "Popular Destinations" && (
                          <div className="flex items-center gap-1">
                            <img src={star} className="size-[17px]" alt="star" />
                            <p className="text-[#7B7B7B] pt-[2px]">{room.rating}</p>
                          </div>
                        )}
                      </div>
                    </div>
                    <Link
                      to={pageTitle !== "Popular Destinations" ? "/updates" : "/searchresults"}
                    >
                      <button className="px-[30px] py-2 text-[14px] font-semibold md:text-base bg-laara-primary xl:font-normal text-white w-full rounded-[32px]">
                        {pageTitle !== "Popular Destinations" ? "Follow" : "See More"}
                      </button>
                    </Link>
                  </CardContent>
                </Card>
              </div>
            </CarouselItem>
          ))}
        </CarouselContent>

        <CarouselPrevious className="carousel-btn left-0" />
        <CarouselNext className="carousel-btn right-0" />

        <div className="mobile-carousel-btn-container">
          <CarouselPrevious className="mobile-carousel-btn" />
          <CarouselNext className="mobile-carousel-btn" />
        </div>
      </Carousel>
    </section>
  );
}
