import { useState, useEffect } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLessThan } from "@fortawesome/free-solid-svg-icons";
import { getPropertyById, updatePropertyAccessibilityFeatures } from "@/api"; // Import your API functions

const EditPropertyAccessibility = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  const [accessibilityFeatures, setAccessibilityFeatures] = useState({});
  const [property, setProperty] = useState(null); // State to store the complete property data

  useEffect(() => {
    const fetchPropertyDetails = async () => {
      try {
        const response = await getPropertyById(id);
        console.log("API response:", response);

        // Store the complete property data
        setProperty(response?.property || {});

        const features = response?.property?.accessibilityFeatures || [];
        console.log("Fetched features:", features);

        // Initialize state based on fetched data, trimming feature names
        const featuresState = {};
        features.forEach((feature) => {
          const trimmedFeatureName = feature.features.feature.trim(); // Trim extra spaces
          featuresState[trimmedFeatureName] = true;
        });
        console.log("Features state:", featuresState);
        setAccessibilityFeatures(featuresState);
      } catch (error) {
        console.error("Error fetching property details:", error.message);
      }
    };

    fetchPropertyDetails();
  }, [id]);

  const handleCheckboxChange = (e) => {
    const { name, checked } = e.target;
    setAccessibilityFeatures((prevFeatures) => ({
      ...prevFeatures,
      [name]: checked,
    }));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    // Prepare data in the required format
    const updatedFeatures = Object.keys(accessibilityFeatures)
      .filter((key) => accessibilityFeatures[key]) // Only include selected features
      .map((key) => ({
        feature: key,
        category: determineCategory(key), // Fetch the category dynamically
      }));

    try {
      await updatePropertyAccessibilityFeatures(id, {
        accessibilityFeatures: updatedFeatures,
      });
      navigate(`/view-property/${id}`); // Redirect to the view property page after saving
    } catch (error) {
      console.error("Error updating accessibility features:", error.message);
    }
  };

  // Function to determine category based on feature name
  const determineCategory = (feature) => {
    const featureCategoryMap = {
      "Entire unit located on the ground floor": "General",
      wheelchairAccessible: "General",
      upperFloorElevator: "General",
      "Disabled parking spot": "Parking",
      "Step-free guest entrance": "Entrance",
      "Wide guest entrance": "Entrance",
      "Roll-in shower with chair": "Bathroom",
      "Bathtub with shower chair": "Bathroom",
      "Wide doorway to guest bedroom": "Bedroom",
      "Lift access": "Common Areas",
      "Visual and audible alarms": "Communication",
      "Step-free kitchen access": "Kitchen",
    };

    return featureCategoryMap[feature] || "Unknown"; // Return 'Unknown' if the category is not found
  };

  return (
    <div className="p-8 bg-base-white">
      <section className="top h-16 flex items-center p-2 gap-3">
        <div className="flex items-center gap-4 text-lg text-gray-600 w-[700px]">
          <Link to={`/view-property/${id}`}>
            <FontAwesomeIcon icon={faLessThan} className="text-base" />
          </Link>
          <h3>Accessibility Features</h3>
        </div>
      </section>
      <form className="flex flex-col p-2" onSubmit={handleSubmit}>
        <h4 className="text-[18px] font-medium leading-[28px] tracking-[0.005em] text-left">
          What accessibility features are available at your property?
        </h4>
        <section>
          <h3 className="text-[18px] font-normal leading-[28px] tracking-[0.005em] text-left m-5 ml-0">
            Please select accessibility features available at your property to
            accommodate individuals with disabilities.
          </h3>

          {[
            {
              category: "General",
              features: [
                {
                  name: "Entire unit located on the ground floor",
                  label: "Entire unit located on the ground floor",
                },
                {
                  name: "wheelchairAccessible",
                  label: "Entire unit wheelchair accessible",
                },
                {
                  name: "upperFloorElevator",
                  label: "Upper floors accessible by elevator",
                },
              ],
            },
            {
              category: "Parking",
              features: [
                {
                  name: "Disabled parking spot",
                  label: "Disabled parking spot",
                },
              ],
            },
            {
              category: "Entrance",
              features: [
                {
                  name: "Step-free guest entrance",
                  label: "Step-free guest entrance",
                },
                { name: "Wide guest entrance", label: "Wide guest entrance" },
              ],
            },
            {
              category: "Bathroom",
              features: [
                {
                  name: "Roll-in shower with chair",
                  label: "Roll-in shower with chair",
                },
                {
                  name: "Bathtub with shower chair",
                  label: "Bathtub with shower chair",
                },
              ],
            },
            {
              category: "Bedroom",
              features: [
                {
                  name: "Wide doorway to guest bedroom",
                  label: "Wide doorway to guest bedroom",
                },
              ],
            },
            {
              category: "Common Areas",
              features: [{ name: "Lift access", label: "Lift access" }],
            },
            {
              category: "Communication",
              features: [
                {
                  name: "Visual and audible alarms",
                  label: "Visual and audible alarms",
                },
              ],
            },
            {
              category: "Kitchen",
              features: [
                {
                  name: "Step-free kitchen access",
                  label: "Step-free kitchen access",
                },
              ],
            },
          ].map((section) => (
            <div
              key={section.category.toLowerCase().replace(" ", "-")}
              className="flex flex-col gap-3 mb-5 mt-5"
            >
              <h3 className="text-[18px] font-normal leading-[28px] tracking-[0.005em] text-left ml-0">
                {section.category}
              </h3>
              {section.features.map((feature) => (
                <label
                  key={feature.name}
                  className="flex items-center space-x-3"
                >
                  <input
                    type="checkbox"
                    name={feature.name}
                    checked={accessibilityFeatures[feature.name] || false}
                    onChange={handleCheckboxChange}
                    className="custom-checkbox"
                  />
                  <span className="text-[16px] font-normal leading-[26px] tracking-[0.0025em] text-left">
                    {feature.label}
                  </span>
                </label>
              ))}
            </div>
          ))}
        </section>
        <div className="flex justify-end mt-4">
          <button
            type="submit"
            className="px-4 py-2 bg-blue-600 text-white rounded-[36px] w-[216px] h-12 hover:bg-blue-500"
          >
            Save Changes
          </button>
        </div>
      </form>
    </div>
  );
};

export default EditPropertyAccessibility;
