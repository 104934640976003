import ReactDOM from "react-dom";
import { FiX } from "react-icons/fi";
import PropTypes from "prop-types";

const Modal = ({ isOpen, closeModal, images }) => {
  if (!isOpen) return null;

  return ReactDOM.createPortal(
    <div className="fixed inset-0 bg-black bg-opacity-50 z-50 overflow-y-auto flex justify-center">
      <div className="bg-base-white rounded-lg shadow-lg max-w-6xl w-full p-4">
        <p className="p-4">Images</p>
        <button
          onClick={closeModal}
          className="absolute right-1 top-1 text-white bg-laara-primary rounded-full p-2"
        >
          <FiX size={24} />
        </button>

        {/* Image Grid Section */}
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4">
          {/* Map through the images array and display each image */}
          {images.map((image, index) => (
            <img
              key={index}
              src={image}
              alt={`Room photo ${index + 1}`}
              className="w-full h-48 object-cover rounded-lg"
            />
          ))}
        </div>
      </div>
    </div>,
    document.body,
  );
};

Modal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  images: PropTypes.array.isRequired, // Ensure images are passed in as an array
};

export default Modal;
