import { useState } from "react";
import { createUserWithEmailAndPassword, signInWithPopup } from "firebase/auth";
import { Link, useNavigate } from "react-router-dom";
import google from "../../../assets/ListingProcess/google.svg";
import facebook from "../../../assets/ListingProcess/facebook.svg";
import { auth, provider } from "../../../firebase/firebase"; // Ensure this path is correct
import { baseURL, headers } from "../../../constants/apiconfig";

export default function HostRegister() {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [error, setError] = useState("");
  const navigate = useNavigate();
  const role = "HOST";

  // Handle email/password registration
  const handleEmailRegister = async (event) => {
    event.preventDefault();
    setError("");

    if (password !== confirmPassword) {
      setError("Passwords do not match");
      return;
    }

    try {
      // Firebase email/password registration
      const result = await createUserWithEmailAndPassword(
        auth,
        email,
        password
      );
      const user = result.user;
      const token = await user.getIdToken();

      // Prepare data to send to the API
      const data = {
        firstName,
        lastName,
        emailAddress: user.email,
        phoneNumber: phone, 
        role
      };


      // Send a request to the endpoint
      const response = await fetch(`${baseURL}/users`, {
        method: "POST",
        headers: {
          ...headers(token),
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      });

      if (!response.ok) {
        const errorText = await response.text();
        throw new Error(
          `Server responded with ${response.status}: ${errorText}`
        );
      }

      navigate("/hostlogin");
    } catch (error) {
      setError(error.message);
    }
  };

  // Handle Google sign-in
  const handleGoogleSignIn = async () => {
    try {
      const result = await signInWithPopup(auth, provider);
      const user = result.user;
      const token = await user.getIdToken();

      // Extract user data
      const fullName = user.displayName || "";
      const nameParts = fullName.split(" ");
      const firstName = nameParts[0] || "";
      const lastName = nameParts.slice(1).join(" ") || "";
      const phoneNumber = user.phoneNumber || ""; // Add phone number if available

      const data = {
        emailAddress: user.email,
        firstName,
        lastName,
        phoneNumber, // Include phone number in the data
        role,
      };

      // Send data to the endpoint
      const response = await fetch(`${baseURL}/users`, {
        method: "POST",
        headers: {
          ...headers(token),
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      });

      if (!response.ok) {
        const errorText = await response.text();
        throw new Error(
          `Server responded with ${response.status}: ${errorText}`
        );
      }

      navigate("/hostlogin");
    } catch (error) {
      alert(`An error occurred: ${error.message}`);
    }
  };

  // Handle Facebook sign-in
  const handleFacebookSignIn = async () => {
    try {
      const result = await signInWithPopup(auth);
      const user = result.user;
      const token = await user.getIdToken();

      // Extract user data
      const fullName = user.displayName || "";
      const nameParts = fullName.split(" ");
      const firstName = nameParts[0] || "";
      const lastName = nameParts.slice(1).join(" ") || "";

      const data = {
        emailAddress: user.email,
        firstName,
        lastName,
      };

      // Send data to the endpoint
      const response = await fetch(`${baseURL}/users`, {
        method: "POST",
        headers: {
          ...headers(token),
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      });

      if (!response.ok) {
        const errorText = await response.text();
        throw new Error(
          `Server responded with ${response.status}: ${errorText}`
        );
      }

      navigate("/listproperty");
    } catch (error) {
      alert(`An error occurred: ${error.message}`);
    }
  };
  return (
    <div className="flex flex-col pb-20 bg-white">
      <div className="flex flex-col self-center px-5 mt-16 max-w-full w-[459px] max-md:mt-10">
        <div className="text-2xl font-medium tracking-wider leading-5 text-center text-black uppercase max-md:max-w-full">
          Create account
        </div>
        <div className="flex gap-2 self-center mt-9 text-xs">
          <div className="text-neutral-400">Already have an account? </div>
          <Link
            to="/hostlogin"
            className="font-semibold text-sky-700 underline"
          >
            Sign in here
          </Link>
        </div>
        <form
          onSubmit={handleEmailRegister}
          className="mt-9 flex flex-col gap-5"
        >
          <div className="flex gap-5 flex-wrap max-md:flex-col max-md:gap-4 max-md:w-full">
            <div className="flex flex-col flex-1 max-md:w-full">
              <label className="relative text-sm text-zinc-600">
                <input
                  type="text"
                  value={firstName}
                  onChange={(e) => setFirstName(e.target.value)}
                  className="peer px-2.5 py-3 border border-zinc-600 rounded-md bg-white mt-1 focus:outline-none focus:border-blue-500 w-full"
                  placeholder=" "
                  required
                />
                <span className="absolute left-2.5 top-1/2 -translate-y-1/2 bg-white px-1 text-xs text-zinc-700 transition-all duration-200 peer-placeholder-shown:top-1/2 peer-placeholder-shown:text-base peer-placeholder-shown:text-zinc-600 peer-focus:top-0 peer-focus:text-xs peer-focus:text-blue-500 peer-valid:top-0 peer-valid:text-xs peer-valid:text-blue-500">
                  First Name
                </span>
              </label>
            </div>
            <div className="flex flex-col flex-1 max-md:w-full">
              <label className="relative text-sm text-zinc-600">
                <input
                  type="text"
                  value={lastName}
                  onChange={(e) => setLastName(e.target.value)}
                  className="peer px-2.5 py-3 border border-zinc-600 rounded-md bg-white mt-1 focus:outline-none focus:border-blue-500 w-full"
                  placeholder=" "
                  required
                />
                <span className="absolute left-2.5 top-1/2 -translate-y-1/2 bg-white px-1 text-xs text-zinc-700 transition-all duration-200 peer-placeholder-shown:top-1/2 peer-placeholder-shown:text-base peer-placeholder-shown:text-zinc-600 peer-focus:top-0 peer-focus:text-xs peer-focus:text-blue-500 peer-valid:top-0 peer-valid:text-xs peer-valid:text-blue-500">
                  Second Name
                </span>
              </label>
            </div>
          </div>
          <div className="flex gap-5 justify-between mt-9 flex-wrap max-md:flex-col max-md:gap-4 max-md:w-full">
            <div className="flex flex-col flex-1 max-md:w-full">
              <label className="relative text-sm text-zinc-600">
                <input
                  type="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  className="peer px-2.5 py-3 border border-zinc-600 rounded-md bg-white mt-1 focus:outline-none focus:border-blue-500 w-full"
                  placeholder=" "
                  required
                />
                <span className="absolute left-2.5 top-1/2 -translate-y-1/2 bg-white px-1 text-xs text-zinc-700 transition-all duration-200 peer-placeholder-shown:top-1/2 peer-placeholder-shown:text-base peer-placeholder-shown:text-zinc-600 peer-focus:top-0 peer-focus:text-xs peer-focus:text-blue-500 peer-valid:top-0 peer-valid:text-xs peer-valid:text-blue-500">
                  Email
                </span>
              </label>
            </div>
            <div className="flex flex-col flex-1 max-md:w-full">
              <label className="relative text-sm text-neutral-400">
                <input
                  type="tel"
                  value={phone}
                  onChange={(e) => setPhone(e.target.value)}
                  className="peer px-2.5 py-3 border border-zinc-600 rounded-md bg-white mt-1 focus:outline-none focus:border-blue-500 w-full"
                  placeholder=" "
                  required
                />
                <span className="absolute left-2.5 top-1/2 -translate-y-1/2 bg-white px-1 text-xs text-zinc-700 transition-all duration-200 peer-placeholder-shown:top-1/2 peer-placeholder-shown:text-base peer-placeholder-shown:text-neutral-400 peer-focus:top-0 peer-focus:text-xs peer-focus:text-blue-500 peer-valid:top-0 peer-valid:text-xs peer-valid:text-blue-500">
                  Phone number
                </span>
              </label>
            </div>
          </div>
          <div className="flex flex-col justify-center mt-9">
            <label className="relative flex flex-col justify-center text-sm text-zinc-600">
              <input
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                className="peer px-2.5 py-3 border border-zinc-600 rounded-md bg-white mt-1 focus:outline-none focus:border-blue-500"
                placeholder=" "
                required
              />
              <span className="absolute left-2.5 top-1/2 -translate-y-1/2 bg-white px-1 text-xs text-zinc-700 transition-all duration-200 peer-placeholder-shown:top-1/2 peer-placeholder-shown:text-base peer-placeholder-shown:text-zinc-600 peer-focus:top-0 peer-focus:text-xs peer-focus:text-blue-500 peer-valid:top-0 peer-valid:text-xs peer-valid:text-blue-500">
                Password
              </span>
            </label>
          </div>
          <div className="flex flex-col justify-center py-1 mt-9">
            <label className="relative flex flex-col justify-center text-sm text-zinc-600">
              <input
                type="password"
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
                className="peer px-2.5 py-3 border border-zinc-600 rounded-md bg-white mt-1 focus:outline-none focus:border-blue-500"
                placeholder=" "
                required
              />
              <span className="absolute left-2.5 top-1/2 -translate-y-1/2 bg-white px-1 text-xs text-zinc-700 transition-all duration-200 peer-placeholder-shown:top-1/2 peer-placeholder-shown:text-base peer-placeholder-shown:text-zinc-600 peer-focus:top-0 peer-focus:text-xs peer-focus:text-blue-500 peer-valid:top-0 peer-valid:text-xs peer-valid:text-blue-500">
                Confirm Password
              </span>
            </label>
          </div>
          <div className="flex flex-col justify-center text-sm text-red-500 mt-1">
            {error && <div>{error}</div>}
          </div>
          <button
            type="submit"
            className="bg-blue-500 text-white py-2 rounded-md mt-5"
          >
            Register
          </button>
        </form>
        <div className="relative mt-5 text-sm text-center text-neutral-400">
          <div className="absolute inset-0 flex items-center">
            <div className="w-full border-t border-neutral-300"></div>
          </div>
          <div className="relative inline-block bg-white px-4">or</div>
        </div>
        <div className="mt-9 text-xs text-center text-neutral-500 max-md:max-w-full">
          Sign up with
        </div>
        <div className="flex gap-5 justify-center self-center mt-9">
          <button
            onClick={handleGoogleSignIn}
            className="flex justify-center items-center p-3 border border-solid border-neutral-400 rounded-[32px]"
          >
            <img
              loading="lazy"
              src={google}
              className="aspect-square w-[21px]"
              alt="Google logo"
            />
          </button>
          <button
            onClick={handleFacebookSignIn}
            className="flex justify-center items-center p-3 border border-solid border-neutral-400 rounded-[32px]"
          >
            <img
              loading="lazy"
              src={facebook}
              className="aspect-square w-[21px]"
              alt="Facebook logo"
            />
          </button>
        </div>
      </div>
    </div>
  );
}
