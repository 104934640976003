import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  mainStep: 1,
  subStep: 1,
  propertyId: null,
  formData: {
    name: "",
    type: "",
    description: "Welcome to our hotel",
    capacity: 0,
    guestVerificationMethod: "",
    languages: [],
    address: {
      country: "",
      county: "",
      city: "",
      town: "",
      street: "",
      physicalAddress: "",
      postCode: "",
      apartment: "",
      latitude: 0,
      longitude: 0,
    },
    amenities: [],
    accessibilityFeatures: [],
    policies: [],
    mealPlans: [],
    roomType: {
      name: "",
      description: "",
      maxGuests: 0,
      numRooms: 0,
      typeSize: 0,
      bedTypes: [
        {
          name: "Single",
          description: "single bed: 90cm - 130cm",
          quantity: 0,
        },
        {
          name: "Double",
          description: "double bed: 131cm - 150cm",
          quantity: 0,
        },
        { name: "Large", description: "Large bed: 151cm - 180cm", quantity: 0 },
        {
          name: "ExtraLarge",
          description: "extra large bed: 181cm - 210cm",
          quantity: 0,
        },
      ],
      amenities: [],
      policies: [],
      roomTypeId: null,
      roomphotos: [],
      bathroom: {
        isPrivate: true,
        amenities: [],
      },
      // VACATION RENTALS BATHROOMS
      numberOfBathrooms: 1,
    },
    pricing: [
      {
        price: 0,
        pricingOption: "",
        deductionRate: 10,
        mealPlan: {
          name: "",
          description: "",
        },
        pricingMode: "",
      },
    ],
    photos: [],
    cancellationPolicies: [],
    vacationRentalPricing: [],
  },
};

const listingSlice = createSlice({
  name: "hostListing",
  initialState,
  reducers: {
    clearPricing: (state) => {
      state.formData.vacationRentalPricing =
        initialState.formData.vacationRentalPricing;
    },
    clearListing: (state) => {
      return initialState;
    },
    setListingType: (state, action) => {
      state.formData.type = action.payload;
    },
    setCoordinates: (state, action) => {
      (state.formData.address.latitude = action.payload.lat),
        (state.formData.address.longitude = action.payload.lng);
    },
    setAmenities: (state, action) => {
      state.formData.amenities = action.payload;
    },
    setRoomAmenities: (state, action) => {
      state.formData.roomType.amenities = action.payload;
    },
    setMealPlans: (state, action) => {
      state.formData.mealPlans = action.payload;
    },
    setAccessibility: (state, action) => {
      state.formData.accessibilityFeatures = action.payload;
    },
    setBathroomAmenity: (state, action) => {
      state.formData.roomType.bathroom.amenities = action.payload.map(
        (amenity) => ({
          ...amenity,
          category: "Bathroom", // Ensure each amenity has the default category
        })
      );
    },
    setBathroomPrivacy: (state, action) => {
      state.formData.roomType.bathroom.isPrivate = action.payload;
    },
    setFormData: (state, action) => {
      state.formData = { ...state.formData, ...action.payload };
    },
    setRoomPhotos: (state, action) => {
      state.formData.roomType.roomphotos = action.payload;
    },
    updateBedTypes: (state, action) => {
      state.formData.roomType.bedTypes = action.payload;
    },
    setPricing: (state, action) => {
      const roomTypeName = state.formData.roomType.name;

      const determinePricingMode = (roomTypeName) => {
          switch (roomTypeName) {
            case "standard-single":
              return "PER_PERSON_SHARING";
            case "executive-single":
              return "PER_COUPLE_SHARING";
            case "deluxe-single":
              return "PER_ROOM";
            case "standard-double":
              return "PER_ROOM";
            case "executive-double":
              return "PER_ROOM";
            case "deluxe-double":
              return "PER_ROOM";
            case "standard-twin":
              return "PER_ROOM";
            case "executive-twin":
              return "PER_NIGHT";
            case "deluxe-twin":
              return "PER_NIGHT";
            case "standard-family":
              return "PER_NIGHT";
            case "executive-family":
              return "PER_NIGHT";
            case "deluxe-family":
              return "PER_NIGHT";
            default:
              return "";
          }
        };

        state.formData.pricing = action.payload.map((item) => ({
          ...item,
          pricingMode: determinePricingMode(roomTypeName),
        }));
      },
   
    updateDeductionRate: (state, action) => {
      const { deductionRate } = action.payload;
      state.formData.pricing = state.formData.pricing.map((item) => ({
        ...item,
        deductionRate,
      }));
    },
    setPhotos: (state, action) => {
      state.formData.photos = action.payload;
    },
    removePhoto(state, action) {
      state.formData.photos = state.formData.photos.filter(
        (photo) => photo.path !== action.payload
      );
    },

    setCancellationPolicies: (state, action) => {
      state.formData.cancellationPolicies = action.payload;
    },

    nextSubStep: (state, action) => {
      const subStepsPerMainStep = {
        1: 12,
        2: 4,
        3: 4,
        4: 1,
        5: 4,
      };
      const maxSubStep = subStepsPerMainStep[state.mainStep];

      if (state.subStep < maxSubStep) {
        state.subStep += 1;
      } else if (state.mainStep < 5) {
        state.subStep = 1;
        state.mainStep += 1;
      }
    },
    prevSubStep: (state) => {
      if (state.mainStep === 1 && state.subStep === 1) return;

      if (state.subStep > 1) {
        state.subStep -= 1;
      } else {
        if (state.mainStep > 1) {
          state.mainStep -= 1;
          const subStepsPerMainStep = {
            1: 12,
            2: 4,
            3: 4,
            4: 1,
            5: 4,
          };
          state.subStep = subStepsPerMainStep[state.mainStep]; // Ensure it fetches correctly here
        }
      }
    },
    setPropertyId: (state, action) => {
      state.propertyId = action.payload;
    },
    setRoomTypeId: (state, action) => {
      state.formData.roomType.roomTypeId = action.payload;
    },
    updatePricing: (state, action) => {
      const { index, pricingData } = action.payload;

      if (Array.isArray(pricingData) && pricingData.length > 0) {
        if (state.formData.vacationRentalPricing[index]) {
          state.formData.vacationRentalPricing[index] = pricingData[0];
        } else {
          state.formData.vacationRentalPricing.push(pricingData[0]);
        }
      }
    },
    setPricingRate: (state, action) => {
      state.formData.pricing[0].deductionRate = action.payload;
    },

  },
});

export const {
  clearPricing,
  updatePricing,
  setListingType,
  setAccessibility,
  setCoordinates,
  setMealPlans,
  setFormData,
  nextSubStep,
  prevSubStep,
  setPropertyId,
  setAmenities,
  clearListing,
  setRoomAmenities,
  updateBedTypes,
  setRoomTypeId,
  setRoomPhotos,
  setBathroomAmenity,
  setBathroomPrivacy,
  setPricing,
  setPhotos,
  removePhoto,
  setCancellationPolicies,
  updateDeductionRate,
} = listingSlice.actions;

export default listingSlice.reducer;
