import { useState } from "react";
import Consider from "@/components/Listings/Consider";
import { ChevronDown, Plus, X, Check } from 'lucide-react';

export default function BRPricing() {
    const [priceOneGuest, setPriceOneGuest] = useState(0);
    const [selectedOption, setSelectedOption] = useState('');
    const [amenityItems, setAmenityItems] = useState([{ id: 1 }]);

    const handleInputChange = (event) => {
        const inputValue = parseFloat(event.target.value) || 0;
        setPriceOneGuestPrivateRoom(inputValue);
    };

    const handleRadioChange = (event) => {
        setSelectedOption(event.target.value);
    }

    const earnings = Math.max(0, priceOneGuest - (priceOneGuest * 0.10));

    const addAmenityItem = () => {
        const newId = amenityItems.length > 0 ? Math.max(...amenityItems.map(item => item.id)) + 1 : 1;
        setAmenityItems([...amenityItems, { id: newId }]);
    };

    const removeAmenityItem = (id) => {
        setAmenityItems(amenityItems.filter(item => item.id !== id));
    };

    const AmenityItem = ({ onRemove }) => {
        const [isDropdownOpen, setIsDropdownOpen] = useState(false);
        const [selectedAmenity, setSelectedAmenity] = useState('Select amenity');

        const toggleDropdown = () => {
            setIsDropdownOpen(!isDropdownOpen);
        };

        const handleAmenitySelect = (amenity) => {
            setSelectedAmenity(amenity);
            setIsDropdownOpen(false);
        };

        return (
            <div className="flex justify-between items-center gap-4">
                <div className="flex-1 relative">
                    <div
                        className="w-full px-3 py-2 bg-white border border-gray-300 rounded-md appearance-none text-gray-700 pr-8 outline-none"
                        onClick={toggleDropdown}
                    >
                        {selectedAmenity}
                        <ChevronDown className="absolute right-3 top-1/2 transform -translate-y-1/2 text-gray-400" size={20} />
                    </div>

                    {isDropdownOpen && (
                        <div className="absolute z-10 mt-1 w-full bg-white border border-gray-300 rounded-md shadow-lg">
                            <ul className="py-1">
                                <li
                                    className="px-4 py-2 hover:bg-gray-100 cursor-pointer"
                                    onClick={() => handleAmenitySelect('Amenity 1')}
                                >
                                    Amenity 1
                                </li>
                                <li
                                    className="px-4 py-2 hover:bg-gray-100 cursor-pointer"
                                    onClick={() => handleAmenitySelect('Amenity 2')}
                                >
                                    Amenity 2
                                </li>
                                <li
                                    className="px-4 py-2 hover:bg-gray-100 cursor-pointer"
                                    onClick={() => handleAmenitySelect('Amenity 3')}
                                >
                                    Amenity 3
                                </li>
                            </ul>
                        </div>
                    )}
                </div>
                <div className="flex-1 relative">
                    <span className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400">US$</span>
                    <input type="text" className="w-full px-3 py-2 pl-10 bg-white border border-gray-300 rounded-md text-gray-700 outline-none" placeholder="" />
                </div>
                {onRemove && (
                    <button onClick={onRemove} className="text-gray-400 hover:text-gray-600">
                        <X size={20} />
                    </button>
                )}
            </div>
        );
    };

    return (
        <form>
            <div className="mb-5 text-2xl font-semibold tracking-normal leading-10 text-zinc-800 max-md:mt-10 max-md:max-w-full">
                Price
            </div>

            <div className="flex-wrap lg:flex-nowrap gap-20 ">
                <div className="w-full max-w-[728px] flex flex-col justify-center px-8 py-9 rounded-md bg-[#F4FEFF] bg-opacity-35 border border-neutral-200 max-md:px-5">

                    <div className="flex flex-col pb-1.5 ">
                        <div className="flex flex-col gap-5 pt-1.5">
                            <h2 className="text-xl font-semibold">Mode of Pricing</h2>
                            <div className="flex flex-col space-y-2">
                                <label className="inline-flex items-center">
                                    <input
                                        type="radio"
                                        className="form-radio w-5 h-5 accent-[#0466C8]"
                                        name="pricingOption"
                                        value="option1"
                                        checked={selectedOption === 'option1'}
                                        onChange={handleRadioChange}
                                    />
                                    <span className="ml-2 text-base font-normal">Per hour</span>
                                </label>
                                <label className="inline-flex items-center">
                                    <input
                                        type="radio"
                                        className="form-radio w-5 h-5 accent-[#0466C8]"
                                        name="pricingOption"
                                        value="option2"
                                        checked={selectedOption === 'option2'}
                                        onChange={handleRadioChange}
                                    />
                                    <span className="ml-2 text-base font-normal">Per half day</span>
                                </label>
                                <label className="inline-flex items-center">
                                    <input
                                        type="radio"
                                        className="form-radio w-5 h-5 accent-[#0466C8]"
                                        name="pricingOption"
                                        value="option3"
                                        checked={selectedOption === 'option3'}
                                        onChange={handleRadioChange}
                                    />
                                    <span className="ml-2 text-base font-normal">Per day</span>
                                </label>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="w-full max-w-[728px] flex flex-col justify-center px-8 py-9 rounded-md bg-[#F4FEFF] bg-opacity-35 border border-neutral-200 max-md:px-5 mt-9">

                    <div className="flex flex-col pb-1.5">
                        <div className="flex flex-col gap-5 pt-1.5 ">
                            <div className="flex flex-col md:flex-row gap-5">
                                <div className="flex flex-col flex-1 text-black">
                                    <div className="text-base font-medium">
                                        Price per guest
                                    </div>
                                    <div className="flex items-center py-3 mt-3 text-sm whitespace-nowrap rounded-lg border border-gray-300 transition-colors duration-300">
                                        <div className="self-stretch my-auto px-2">US$</div>
                                        <div className="shrink-0 self-start w-px h-7 border border-solid bg-neutral-500" />
                                        <input
                                            type="number"
                                            placeholder="10001"
                                            value={priceOneGuest || ''}
                                            onChange={handleInputChange}
                                            className="flex-auto self-stretch my-auto px-2 py-1 border-none outline-none text-black bg-transparent placeholder-black"
                                            style={{ borderColor: 'transparent' }}
                                            onFocus={(e) => (e.target.style.borderColor = 'blue')}
                                            onBlur={(e) => (e.target.style.borderColor = 'transparent')}
                                        />
                                    </div>
                                </div>
                                <div className="flex flex-col flex-1 text-neutral-600">
                                    <div className="text-base font-medium">
                                        Earning after Laara commission
                                    </div>
                                    <div className="flex items-center py-3 mt-3 text-sm whitespace-nowrap rounded-lg border border-gray-300">
                                        <div className="self-stretch my-auto px-2">US$</div>
                                        <div className="shrink-0 self-start w-px h-7 border border-solid bg-neutral-500" />
                                        <div className="flex-auto px-2 self-stretch my-auto">
                                            {earnings.toFixed(2)}
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="self-start text-base text-neutral-500 max-md:mt-10 flex items-center">
                                <Check className="text-[#108975] mr-2 mt-1" size={20} />
                                Including taxes, commissions and fees
                            </div>
                            <div className="self-start mt-2 text-base text-neutral-500 flex items-center">
                                <Check className="text-[#108975] mr-2 mt-1" size={20} />
                                10% Laara commission
                            </div>
                        </div>
                    </div>
                </div>

                <div className="w-full max-w-[728px] flex flex-col justify-center px-8 py-9 rounded-md bg-[#F4FEFF] bg-opacity-35 border border-neutral-200 max-md:px-5 mt-9">
                    <div className="flex flex-col pb-1.5">
                        <div className="flex flex-col gap-5 pt-1.5">
                            <h2 className="text-lg font-medium">Set amenity price</h2>
                            <p className="text-base font-normal text-[#888888]">Add a price for each extra amenity. You will not be charged commission for these items.</p>

                            <div className="flex flex-col gap-2">
                                <div className="flex justify-between items-center">
                                    <span className="text-base font-medium flex-1">Item</span>
                                    <span className="text-base font-medium flex-1 ml-4">Price <span className="text-base font-medium text-[#9B9B9B]">(per item)</span></span>
                                </div>

                                {amenityItems.map((item, index) => (
                                    <AmenityItem
                                        key={item.id}
                                        onRemove={amenityItems.length > 1 ? () => removeAmenityItem(item.id) : undefined}
                                    />
                                ))}
                            </div>

                            <button
                                className="text-blue-600 text-sm font-medium mt-2 flex items-center"
                                onClick={addAmenityItem}
                                type="button"
                            >
                                <Plus className="inline-block mr-1 h-4 w-4" /> Add another item
                            </button>
                        </div>
                    </div>
                </div>

                <div className="w-full lg:w-1/2 p-4 ">
                <Consider
                    title={"Which policies should I choose?"}
                    data={["Dont worry you can set a price when you are ready"]}
                />
            </div>
            </div>
        </form>
    );
}
