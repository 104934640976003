import { useState } from "react";
import tick from "../../../assets/ListingProcess/tick.svg";
import hand from "../../../assets/ListingProcess/hand.svg";
import cancel from "../../../assets/ListingProcess/cancel.svg";
import left from "../../../assets/ListingProcess/left.svg";
import Consider from "@/components/Listings/Consider";

export default function WeeklyRates() {
  return (
    <>
      <div className="flex flex-col bg-white">
        <div className="flex flex-col self-center mt-5 w-full max-md:mt-10 max-md:max-w-full">
          <div className="mt-14 text-3xl font-semibold tracking-normal leading-10 text-zinc-800 max-md:mt-10 max-md:max-w-full">
            Weekly rate plan
          </div>
          <div className="mt-14 max-md:mt-10 max-md:max-w-full">
            <div className="flex gap-5 max-md:flex-col">
              <div className="flex flex-col w-[69%] max-md:ml-0 max-md:w-full">
                <div className="bg-laara-secondary bg-opacity-40 border border-neutral-200p-4 rounded-lg shadow-sm flex relative flex-col grow py-11 text-base min-h-[441px] max-md:mt- max-md:max-w-full">
                  <div className="flex relative z-10 flex-col justify-center self-end px-8 py-5 mr-12 max-w-full text-center text-sky-600 whitespace-nowrap border border-sky-600 border-solid rounded-[36px] w-[103px] h-10 max-md:px-5 max-md:mr-2.5">
                    <div className="px-1.5 rounded-none">Edit</div>
                  </div>
                  <div className="flex relative flex-col px-12 pt-2 mt-0 text-black max-md:px-5 max-md:max-w-full">
                    <div className="self-start mt-2 text-xl font-medium">
                      Percentage Deduction
                    </div>
                    <div className="flex gap-2 self-start py-1 mt-4 text-zinc-800">
                      <img
                        loading="lazy"
                        src={tick}
                        className="shrink-0 my-auto w-3.5 aspect-[1.56]"
                      />
                      <div className="flex-auto">
                        Weekly rate deduction = 10%
                      </div>
                    </div>
                    <div className="flex gap-1.5 py-1 mt-3 mr-5 text-zinc-800 max-md:flex-wrap max-md:mr-2.5">
                      <img
                        loading="lazy"
                        src={tick}
                        className="shrink-0 my-auto w-3.5 aspect-[1.56]"
                      />
                      <div className="flex-auto max-md:max-w-full">
                        Calculation: Weekly rate = \$70 USD - (10% of \$70 USD)
                        = \$63 USD per night
                      </div>
                    </div>
                    <div className="flex gap-2.5 py-1 mt-3 mr-5 text-teal-700 max-md:flex-wrap max-md:mr-2.5">
                      <img
                        loading="lazy"
                        src={tick}
                        className="shrink-0 my-auto w-3.5 aspect-[1.56]"
                      />
                      <div className="flex-auto max-md:max-w-full">
                        Note:{" "}
                        <span className="text-zinc-800">
                          Guests will also be charged a 3% commission by the
                          platform, which will affect the final amount paid.
                        </span>
                      </div>
                    </div>
                    <div className="mt-16 text-xl font-medium max-md:mt-10 max-md:max-w-full">
                      Application Across Combinations
                    </div>
                    <div className="mt-3 max-md:max-w-full">
                      The weekly rate will apply uniformly across all meal plan
                      and room type combinations, encouraging longer stays with
                      a discounted rate structure
                    </div>
                  </div>
                </div>
              </div>

              <Consider
                title={"You are unsure about your price?"}
                data={["Dont worry you can set your price when you're ready"]}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
