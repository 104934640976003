import React from 'react'
import { Link } from 'react-router-dom'
import tick from '@/assets/support/Check.svg'

export const MessageSent = () => {
  return (
    <div className="w-full max-w-[1440px] h-auto min-h-screen p-4 sm:p-6 md:p-8 lg:px-22 lg:py-14 gap-4 sm:gap-6 lg:gap-8 mt-[100px] ">
      <div className=" max-w-[1264px] mx-auto ">
        <div className="text-center">
          <div className="mb-4">
            <img src={tick} alt='' className='w-16 h-16 mx-auto' />
          </div>
          <h2 className="text-2xl font-bold mb-2">Message sent</h2>
          <p className="text-gray-600 mb-6">
            Thank you for your message.<br />
            We'll be in touch within 24 hours.
          </p>

          <Link to="/support" className="inline-block w-full sm:w-[382px] text-center">
            <button className=" w-full bg-blue-600 text-white font-semibold py-2 px-4 rounded-full max-w-[382px]">
              Done
            </button>
          </Link>
        </div>
      </div>
    </div>
  )
}
