

import ReactCountryFlag from "react-country-flag"; // Import the flag component
import { FaStar, FaStarHalfAlt } from "react-icons/fa";


// ReviewCard Component
const ReviewCard = ({ profileImage, name, country, rating, reviewText }) => {
  // Function to render star icons based on the rating
  const renderStars = (rating) => {
    const stars = [];
    for (let i = 1; i <= 5; i++) {
      if (i <= rating) {
        stars.push(<FaStar key={i} className="text-yellow-500" />); // Filled star
      } else if (i - 0.5 === rating) {
        stars.push(<FaStarHalfAlt key={i} className="text-yellow-500" />); // Half star
      } else {
        stars.push(<FaStar key={i} className="text-yellow-500" />); // Empty star
      }
    }
    return stars;
  };

  return (
    <div className="max-w-sm flex-wrap  mb-8 mr-8 ">
      {/* Top Section */}
      <div className="flex justify-between items-center mb-3">
        {/* Profile Image and Country Flag */}
        <div className="flex items-center">
          <img
            className="w-12 h-12 rounded-full object-cover"
            src={profileImage}
            alt="Profile"
          />
          <div className="ml-2">
            <span className="block text-sm font-semibold text-gray-800">{name}</span>
            {/* Display Country Flag */}
            <ReactCountryFlag
              countryCode={country}  // The 'country' should be the country code like 'US', 'CA', etc.
              svg
              style={{
                width: '1.5em',
                height: '1.5em',
              }}
              title={country}
            />
          </div>
        </div>

        {/* Rating Number and Star Rating */}
        <div className="flex items-center">
          <span className="text-gray-800 font-semibold mr-2">{rating.toFixed(1)}</span> {/* Display rating number */}
          {renderStars(rating)}
        </div>
      </div>

      {/* Review Text */}
      <div className="mt-4">
        <p className="text-gray-700 text-base">{reviewText}</p>
      </div>
    </div>
  );
};

// ReviewDetails Component
const ReviewDetails = () => {
  // Ratings data
  const ratings = [
    { category: 'Cleanliness', rating: 4.6 },
    { category: 'Comfort', rating: 4.0 },
    { category: 'Facilities', rating: 4.7 },
    { category: 'Value for money', rating: 4.9 },
    { category: 'Location', rating: 3.9 },
    { category: 'Staff', rating: 3.1 },
  ];

  // Sample reviews data
  const reviews = [
    {
      profileImage: 'https://via.placeholder.com/150',
      name: 'Jane Smith',
      country: 'KE', // Country code for Canada
      rating: 4.0,
      reviewText: '“Staying at this charming house was an absolute delight; the hosts were incredibly hospitable, the space was immaculately clean and beautifully decorated, and the location was perfect for exploring the city’s attractions.”',
    },
    {
      profileImage: 'https://via.placeholder.com/150',
      name: 'Jane Smith',
      country: 'KE', // Country code for Canada
      rating: 4.0,
      reviewText: '“Staying at this charming house was an absolute delight; the hosts were incredibly hospitable, the space was immaculately clean and beautifully decorated, and the location was perfect for exploring the city’s attractions.”',
    },
    {
      profileImage: 'https://via.placeholder.com/150',
      name: 'Jane Smith',
      country: 'KE', // Country code for Canada
      rating: 4.0,
      reviewText: '“Staying at this charming house was an absolute delight; the hosts were incredibly hospitable, the space was immaculately clean and beautifully decorated, and the location was perfect for exploring the city’s attractions.”',
    },
    {
      profileImage: 'https://via.placeholder.com/150',
      name: 'Jane Smith',
      country: 'KE', // Country code for Canada
      rating: 4.0,
      reviewText: '“Staying at this charming house was an absolute delight; the hosts were incredibly hospitable, the space was immaculately clean and beautifully decorated, and the location was perfect for exploring the city’s attractions.”',
    },
    {
      profileImage: 'https://via.placeholder.com/150',
      name: 'Jane Smith',
      country: 'TZ', // Country code for Canada
      rating: 4.0,
      reviewText: '“Staying at this charming house was an absolute delight; the hosts were incredibly hospitable, the space was immaculately clean and beautifully decorated, and the location was perfect for exploring the city’s attractions.”',
    },
    {
      profileImage: 'https://via.placeholder.com/150',
      name: 'Jane Smith',
      country: 'KE', // Country code for Canada
      rating: 4.5,
      reviewText: '“Staying at this charming house was an absolute delight; the hosts were incredibly hospitable, the space was immaculately clean and beautifully decorated, and the location was perfect for exploring the city’s attractions.”',
    },
    {
      profileImage: 'https://via.placeholder.com/150',
      name: 'Jane Smith',
      country: 'KE', // Country code for Canada
      rating: 5.0,
      reviewText: '“Staying at this charming house was an absolute delight; the hosts were incredibly hospitable, the space was immaculately clean and beautifully decorated, and the location was perfect for exploring the city’s attractions.”',
    },
    {
      profileImage: 'https://via.placeholder.com/150',
      name: 'Jane Smith',
      country: 'KE', // Country code for Canada
      rating: 4.0,
      reviewText: '“Staying at this charming house was an absolute delight; the hosts were incredibly hospitable, the space was immaculately clean and beautifully decorated, and the location was perfect for exploring the city’s attractions.”',
    },
    {
      profileImage: 'https://via.placeholder.com/150',
      name: 'Jane Smith',
      country: 'KE', // Country code for Canada
      rating: 4.0,
      reviewText: '“Staying at this charming house was an absolute delight; the hosts were incredibly hospitable, the space was immaculately clean and beautifully decorated, and the location was perfect for exploring the city’s attractions.”',
    },
  ];

  // Convert rating to a percentage (assuming rating scale is 1-5)
  const getBarWidth = (rating) => `${(rating / 5) * 100}%`;

  return (
    <div className="p-5">
      {/* Detailed Ratings */}
      <div className="grid gap-4 sm:grid-cols-3 lg:grid-cols-3">
        {ratings.map((rating, index) => (
          <div key={index} className="mb-4">
            <div className="flex items-center justify-between mb-1">
              <span className="text-sm font-medium text-gray-500">{rating.category}</span>
              <span className="text-sm font-medium text-gray-500">{rating.rating.toFixed(1)}</span>
            </div>
            <div className="w-full bg-gray-200 rounded h-2.5">
              <div
                className="bg-blue-600 h-2.5 rounded"
                style={{ width: getBarWidth(rating.rating) }}
              ></div>
            </div>
          </div>
        ))}
      </div>

      {/* Reviews Section */}
      <div className="mt-6 p-3 lg:p-0 w-full ">
        <h2 className="text-lg font-semibold mb-4">What Previous Guests Said</h2>
        <span className="block text-gray-500 mb-6">23 reviews</span>

        {/* Render each review in a responsive grid */}
        <div className=" flex flex-wrap gap-4 max-w-full space-x-4">
          {reviews.map((review, index) => (
            <ReviewCard
              key={index}
              profileImage={review.profileImage}
              name={review.name}
              country={review.country}
              rating={review.rating}
              reviewText={review.reviewText}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default ReviewDetails;
