import React, { useState } from 'react';
import { X } from 'lucide-react';

const AddAdminModal = ({ isOpen, onClose }) => {
    const [fullName, setFullName] = useState('');
    const [email, setEmail] = useState('');
    const [adminRole, setAdminRole] = useState('');
    const [property, setProperty] = useState('');
    const [permissions, setPermissions] = useState({
        bookingManagement: [],
        property: [],
        transaction: [],
    });
    const [selectAll, setSelectAll] = useState(false);

    const bookingOptions = Array.from({ length: 7 }, (_, index) => `Booking option ${index + 1}`);
    const propertyOptions = Array.from({ length: 7 }, (_, index) => `Property option ${index + 1}`);
    const transactionOptions = Array.from({ length: 3 }, (_, index) => `Transaction option ${index + 1}`);

    const handleSelectAll = () => {
        if (selectAll) {
            setPermissions({
                bookingManagement: [],
                property: [],
                transaction: [],
            });
        } else {
            setPermissions({
                bookingManagement: bookingOptions,
                property: propertyOptions,
                transaction: transactionOptions,
            });
        }
        setSelectAll(!selectAll);
    };

    const handlePermissionChange = (category, option) => {
        setPermissions((prevPermissions) => {
            const updatedPermissions = { ...prevPermissions };
            if (updatedPermissions[category].includes(option)) {
                updatedPermissions[category] = updatedPermissions[category].filter(item => item !== option);
            } else {
                updatedPermissions[category] = [...updatedPermissions[category], option];
            }

            const allOptions = {
                bookingManagement: bookingOptions,
                property: propertyOptions,
                transaction: transactionOptions,
            };
            const isAllSelected = Object.keys(allOptions).every(
                cat => updatedPermissions[cat].length === allOptions[cat].length
            );

            setSelectAll(isAllSelected);
            return updatedPermissions;
        });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        onClose();
    };

    if (!isOpen) return null;

    return (
        <div className="fixed inset-0 bg-gray-600 bg-opacity-50 flex justify-center items-center p-4">
            <div className="bg-white rounded-lg w-full max-w-[730px] overflow-y-auto max-h-[90vh]">
                <div className="w-full max-w-[680px] ml-5">

                <div className="p-6">
                    <div className="flex justify-between items-center mb-4">
                        <h2 className="text-base font-bold">Add Administrator</h2>
                        <button onClick={onClose} className="text-gray-400">
                            <X size={14} />
                        </button>
                    </div>
                    <div className="border-t mt-4">
                        <h2 className='font-semibold text-base text-[#4F4F4F] mb-2'>Administrator</h2>
                        <p className='font-normal text-sm text-[#626262] mb-4'>Give administrator access by sending them an invitation.</p>
                    </div>

                    <form onSubmit={handleSubmit} className="space-y-4">
                        <input
                            type="text"
                            placeholder="Full name"
                            id='text'
                            className="w-full p-2 pl-6 border border-[#787878] rounded"
                            value={fullName}
                            onChange={(e) => setFullName(e.target.value)}
                            required
                        />
                        <input
                            type="email"
                            placeholder="Enter email"
                            id='email'
                            className="w-full p-2 pl-6 border border-[#787878] rounded"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            required
                        />

                        <div>
                            <h3 className="font-semibold mb-2">Administrator Accounts</h3>
                            <p className="font-normal text-sm text-[#626262] mb-4">
                                An account provides access to predefined menus and features so that depending on the assigned
                                account, (Super admin, Manager, Accountant) an administrator can have access to what he needs.
                            </p>
                            <div className="flex space-x-2 mb-2">
                                <button
                                    type="button"
                                    className={`px-4 py-2 w-full max-w-[154px] rounded flex justify-between items-center bg-[#0466C8] text-white`}
                                    onClick={() => setAdminRole('Manager')}
                                >
                                    <span>Manager</span>
                                    <div className="w-4 h-4 rounded-full bg-[#0466C8] border-2 border-white flex items-center justify-center relative">
                                        {adminRole === 'Manager' && (
                                            <svg
                                                className="w-3 h-3 text-white absolute"
                                                xmlns="http://www.w3.org/2000/svg"
                                                viewBox="0 0 24 24"
                                                fill="none"
                                                stroke="currentColor"
                                                strokeWidth="2"
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                            >
                                                <path d="M20 6L9 17l-5-5" />
                                            </svg>
                                        )}
                                    </div>
                                </button>
                                <button
                                    type="button"
                                    className={`px-4 py-2 w-full max-w-[154px] rounded flex justify-between items-center bg-[#0466C8] text-white`}
                                    onClick={() => setAdminRole('Reception')}
                                >
                                    <span>Reception</span>
                                    <div className="w-4 h-4 rounded-full bg-[#0466C8] border-2 border-white flex items-center justify-center relative">
                                        {adminRole === 'Reception' && (
                                            <svg
                                                className="w-3 h-3 text-white absolute"
                                                xmlns="http://www.w3.org/2000/svg"
                                                viewBox="0 0 24 24"
                                                fill="none"
                                                stroke="currentColor"
                                                strokeWidth="2"
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                            >
                                                <path d="M20 6L9 17l-5-5" />
                                            </svg>
                                        )}
                                    </div>
                                </button>
                            </div>
                        </div>

                        <input
                            type="text"
                            placeholder="Name of property"
                            id='property'
                            className="w-full p-2 border border-gray-300 rounded"
                            value={property}
                            onChange={(e) => setProperty(e.target.value)}
                            required
                        />

                        <div>
                            <h3 className="font-semibold text-base text-[#4F4F4F] mb-2">Permissions</h3>
                            <p className="font-normal text-sm mb-3">This administrator will have following permission</p>
                            <label className="flex items-center mb-2">
                                <input
                                    type="checkbox"
                                    className="mr-2  h-4 w-4 border border-gray-300 rounded-md "
                                    checked={selectAll}
                                    onChange={handleSelectAll}
                                />
                                <span className={`transition-colors ${selectAll ? 'text-[#0466C8]' : 'text-gray-700'}`}>
                                    {selectAll ? 'Selected' : 'Select All'}
                                </span>
                            </label>


                            <div className="grid grid-cols-3 gap-4">
                                {Object.keys(permissions).map((category) => (
                                    <div key={category} className="ml-4">
                                        <h4 className="font-medium text-base text-[#4F4F4F] mb-3 capitalize">{category.replace(/([A-Z])/g, ' $1')}</h4>
                                        <div className="flex flex-col">
                                            {(category === 'bookingManagement' ? bookingOptions :
                                                category === 'property' ? propertyOptions :
                                                    transactionOptions).map((option, index) => (
                                                        <label key={index} className="flex items-center mb-2 font-normal text-sm">
                                                            <input
                                                                type="checkbox"
                                                                checked={permissions[category].includes(option)}
                                                                onChange={() => handlePermissionChange(category, option)}
                                                                className="mr-2 w-4 h-4"
                                                            />
                                                            {option}
                                                        </label>
                                                    ))}
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>

                        <button
                            type="submit"
                            className="w-full max-w-[160px] float-right bg-[#0466C8] text-white py-2 rounded-full mt-6"
                        >
                            Send
                        </button>
                    </form>
                </div>
                </div>
            </div>
        </div>
    );
};

export default AddAdminModal;