import building from "@/assets/bookings/building.svg";
import location from "@/assets/bookings/location.svg";
import calendar from "@/assets/bookings/calendar.svg";
import sms from "@/assets/bookings/sms.svg";
import user from "@/assets/bookings/user.svg";
import { Link } from "react-router-dom";
import dayjs from 'dayjs';

const BookingsCard = ({ props }) => {
  const formattedDate = dayjs(props.startDate).format('ddd, DD MMM');
  const statusColor =
    props.status === "UPCOMING"
      ? "text-laara-primary"
      : props.status === "PENDING_PAYMENT"
      ? "text-[#F5BD02]"
      : props.status === "CHECKED_IN"
      ? "text-[#14C804]"
      : props.status === "COMPLETED"
      ? "text-[#008080]"
      : props.status === "AWAITING_REVIEW"
      ? "text-[#449FFB]"
      : "text-black";
  return (
    <div>
      <article className="bg-white p-6 lg:p-2 lg:border lg:border-[#626262/20] flex flex-col lg:flex-row gap-4 rounded-[10px] lg:max-w-[570px] 2xl:max-w-[600px] w-full">
        <div className="w-full md:w-auto h-auto xl:max-w-[17.87rem]">
          <img
            src={props.rooms.roomTypes.roomTypeImages[0].images.url}
            alt="booking image"
            className="w-full md:w-auto h-full lg:max-w-[14.5rem] xl:max-w-[17.87rem] 2xl:h-[13.5rem] rounded-[12px] object-cover"
          />
        </div>

        <div className="flex flex-col gap-2 lg:gap-2 xl:w-[812px] w-full ">
          <div className="flex flex-col-reverse gap-2 xl:justify-between ">
            {/* NAME */}
            <div className="flex items-center gap-2 ">
              <img
                src={building}
                alt="building icon"
                className="size-5 object-cover"
              />
              <p className="text-sm text-[#626262]">{props.rooms.properties.name}</p>
            </div>

            {/* ID $ STATUS */}
            <div className="flex items-center gap-2">
              <p
                className={`text-sm font-medium leading-[19.2px] ${statusColor} capitalize`}
              >
                {props.status}
              </p>
              <p className="text-sm leading-[24px] text-[#202020] font-medium">
                ID {props.ref}
              </p>
            </div>
          </div>

          <div className="flex flex-col gap-2  ">
            {/* LOCATION */}
            <div className="flex items-center gap-2 ">
              <img
                src={location}
                alt="location icon"
                className="size-5 object-cover"
              />
              <p className="text-sm  xl:text- text-[#626262]">
                {props.rooms.properties.address.city}
              </p>
            </div>

            <div className="flex items-center gap-2 ">
              <img
                src={user}
                alt="location icon"
                className="size-5 object-cover"
              />
              <p className="text-sm  xl:text- text-[#626262]">Laara Group</p>
            </div>

            {/* SEND MESSAGE */}
            <div className="flex items-center gap-2 ">
              <img
                src={sms}
                alt="location icon"
                className="size-5 object-cover"
              />
              <Link to="/chats" className="text-sm text-laara-primary">
                Send Message
              </Link>
            </div>
          </div>

          <div className="flex flex-col gap-2 justify-between ">
            <div className="flex items-center gap-2">
              <img src={calendar} alt="icon" className="size-5 object-cover" />
              <p className="text-sm text-[#626262]">
                {formattedDate} for {props.occupants} Guests
              </p>
            </div>
            <Link
              to={`/bookings/${props.ref}`}
              state={{ status: props.status, ...props }}
              className="text-laara-primary text-sm ml-auto"
            >
              View Details
            </Link>
          </div>
        </div>
      </article>
    </div>
  );
};

export default BookingsCard;
