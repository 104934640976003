import React from 'react';

export const Availability = () => {
  return (
    <>
          <form className="relative space-y-6">
            {/* <div className="p-4">
              <div className="w-full max-w-[733px]">
                <h2 className="text-xl sm:text-2xl md:text-3xl lg:text-[28px] font-medium leading-[36px] sm:leading-[42px] md:leading-[48px] text-left">
                  Availability
                </h2>
              </div>
            </div> */}

            <div className="w-full mx-auto">
              <h3 className="text-lg sm:text-xl md:text-2xl font-semibold">
                Availability
              </h3>
            </div>

            <div className="w-full max-w-[728px] p-4 bg-laara-secondary border rounded-lg">
              <div className="w-full text-[18px] font-normal leading-[30px] text-left">
                <span>Your property will be</span>
                <span className="font-medium"> automatically available for the next 18 months excluding -</span>
                <span> days that will manually be marked as unavailable.</span>
              </div>
            </div>
          </form>
       
    </>
  );
}
