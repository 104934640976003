import { makeBooking } from "@/api";
import { useState } from "react";
import { TfiAngleLeft } from "react-icons/tfi";
import PhoneInput from "react-phone-input-2";
import 'react-phone-input-2/lib/style.css';


const BookingPage = () => {
  const [selectedOption, setSelectedOption] = useState("debit-card");
  const [phone, setPhone] = useState("");
  const [cardHolder, setCardHolder] = useState("");
  const [cardNumber, setCardNumber] = useState("");
  const [expiryDate, setExpiryDate] = useState("");
  const [cvc, setCvc] = useState("");
  const [paypalEmail, setPaypalEmail] = useState("");
  const [error, setError] = useState("");

  const handleSelection = (option) => {
    setSelectedOption(option);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    let paymentData = {};
    if (selectedOption === "debit-card") {
      paymentData = {
        method: "debit-card",
        cardHolder,
        cardNumber,
        expiryDate,
        cvc,
      };
    } else if (selectedOption === "paypal") {
      paymentData = {
        method: "paypal",
        paypalEmail,
      };
    } else if (selectedOption === "mobile-money") {
      paymentData = {
        method: "mobile-money",
        phone,
      };
    }

    const bookingData = {
      hotel: "Golden Star Hotel",
      checkIn: "Wed 7 Aug 2024",
      checkOut: "Wed 7 Aug 2024",
      nights: 2,
      room: "1 room for 2 Adults",
      payment: paymentData,
    };

    try {
      await makeBooking(bookingData);
      // Handle successful booking (e.g., show a confirmation message or redirect)
      console.log('Booking successful');
    } catch (error) {
      // Handle errors
      setError('Booking failed. Please try again.');
    }
  };

  return (
    <div className="flex flex-col justify-between p-4 sm:p-6">
      {/* Header */}
      <div className='flex mb-6 ml-4 sm:ml-14 items-center text-[hsla(0,0%,38%,1)] gap-1'>
        <TfiAngleLeft  className="hover:scale-125 duration-300 font-bold cursor-pointer" size={18} />
        <h3 className='font-semibold'>Booking</h3>
      </div>

      {/* Content */}
      <form onSubmit={handleSubmit} className="flex flex-col lg:flex-row p-4 sm:p-5 justify-between ml-4 sm:ml-14 rounded-lg shadow-md gap-6">
        {/* Left Section */}
        <div className="w-full lg:w-1/3 p-4">
          {/* Hotel Information */}
          <div className="mb-4 border p-4 rounded-lg">
            <h3 className="text-lg font-semibold">Hotel</h3>
            <p className="text-gray-700">Golden Star Hotel</p>
            <p className="text-gray-500">Kiambu 00232, Kenya</p>
            <div className="flex flex-wrap gap-4 mt-2 text-gray-600">
              <span>📺 Flatscreen TV</span>
              <span>❄️ Air Conditioning</span>
              <span>🌐 Free WiFi</span>
              <span>🕒 24hrs Front Desk</span>
              <span>🅿️ Parking Area</span>
            </div>
          </div>

          {/* Booking Details */}
          <div className="mb-4 border p-4 rounded-lg">
            <h3 className="text-lg font-semibold">Your booking details</h3>
            <div className="flex justify-between text-gray-700">
              <div>
                <p>Check in</p>
                <p className="text-sm">Wed 7 Aug 2024</p>
                <p className="text-sm">13:00 - 20:00</p>
              </div>
              <div className='border-l-2 border-gray-400'></div>
              <div>
                <p>Check out</p>
                <p className="text-sm">Wed 7 Aug 2024</p>
                <p className="text-sm">13:00 - 20:00</p>
              </div>
            </div>
            <div className="text-gray-700 mt-2 mb-2 font-semibold flex">
              <span className='w-full'>Number of nights</span>
              <span className='flex justify-end w-full'>2 Nights</span>
            </div>
            <hr />
            <div className='mt-3 mb-2'>
              <h3 className='font-semibold'>Your Selection</h3>
              <span>1 room for 2 Adults</span>
            </div>
            <p className="text-blue-600 mt-2 underline">Change your selection</p>
          </div>

          {/* Price Details */}
          <div className="border rounded-lg p-2">
            <h4 className="text-md font-semibold">Prices</h4>
            <p className="text-xl font-bold">US$124</p>
            <p>+US$70.80 taxes and charges</p>
          </div>
        </div>

        {/* Right Section */}
        <div className="w-full lg:w-2/3 p-4">
          {/* Payment Methods */}
          <div className="mb-6">
            <h4 className="text-md font-semibold">Pay with</h4>
            <div className="flex flex-col sm:flex-row gap-4 mb-4">
              {/* Debit Card Option */}
              <div
                onClick={() => handleSelection("debit-card")}
                className={`relative p-4 cursor-pointer rounded-lg border-2 ${
                  selectedOption === "debit-card" ? "border-blue-500" : "border-gray-300"
                } flex flex-col items-center`}
              >
                <input
                  type="radio"
                  checked={selectedOption === "debit-card"}
                  onChange={() => handleSelection("debit-card")}
                  className="absolute top-2 left-2 form-radio h-4 w-4 text-blue-500"
                />
                <div className="w-8 h-8 mb-2 bg-gray-200 rounded-full border flex items-center justify-center">
                  <span role="img" aria-label="debit-card">💳</span>
                </div>
                <span className="mt-2">Debit card</span>
              </div>

              {/* PayPal Option */}
              <div
                onClick={() => handleSelection("paypal")}
                className={`relative p-4 cursor-pointer rounded-lg border-2 ${
                  selectedOption === "paypal" ? "border-blue-500" : "border-gray-300"
                } flex flex-col items-center`}
              >
                <input
                  type="radio"
                  checked={selectedOption === "paypal"}
                  onChange={() => handleSelection("paypal")}
                  className="absolute top-2 left-2 form-radio h-4 w-4 text-blue-500"
                />
                <div className="w-8 h-8 mb-2 bg-gray-200 rounded-full flex items-center justify-center">
                  <span role="img" aria-label="paypal">🅿️</span>
                </div>
                <span className="mt-2">PayPal</span>
              </div>

              {/* Mobile Money Option */}
              <div
                onClick={() => handleSelection("mobile-money")}
                className={`relative p-4 cursor-pointer rounded-lg border-2 ${
                  selectedOption === "mobile-money" ? "border-blue-500" : "border-gray-300"
                } flex flex-col items-center`}
              >
                <input
                  type="radio"
                  checked={selectedOption === "mobile-money"}
                  onChange={() => handleSelection("mobile-money")}
                  className="absolute top-2 left-2 form-radio h-4 w-4 text-blue-500"
                />
                <div className="w-8 h-8 mb-2 bg-gray-200 rounded-full flex items-center justify-center">
                  <span role="img" aria-label="mobile-money">📱</span>
                </div>
                <span className="mt-2">Mobile Money</span>
              </div>
            </div>
          </div>

          {/* Payment Forms */}
          {selectedOption === "debit-card" && (
            <div className="mb-6">
              <h4 className="text-md font-semibold">Debit Card</h4>
              <div>
                <input
                  type="text"
                  value={cardHolder}
                  onChange={(e) => setCardHolder(e.target.value)}
                  className="w-full p-2 border rounded-lg mb-4"
                  placeholder="Card holder’s name"
                />
                <input
                  type="text"
                  value={cardNumber}
                  onChange={(e) => setCardNumber(e.target.value)}
                  className="w-full p-2 border rounded-lg mb-4"
                  placeholder="Card number"
                />
                <div className="flex space-x-4">
                  <input
                    type="text"
                    value={expiryDate}
                    onChange={(e) => setExpiryDate(e.target.value)}
                    className="w-1/2 p-2 border rounded-lg"
                    placeholder="MM/YY"
                  />
                  <input
                    type="text"
                    value={cvc}
                    onChange={(e) => setCvc(e.target.value)}
                    className="w-1/2 p-2 border rounded-lg"
                    placeholder="CVC"
                  />
                </div>
                <div className="mt-4">
                  <label>
                    <input type="checkbox" /> Save card
                  </label>
                </div>
              </div>
            </div>
          )}

          {selectedOption === "paypal" && (
            <div className="mb-6">
              <h4 className="text-md font-semibold">PayPal</h4>
              <div>
                <input
                  type="email"
                  value={paypalEmail}
                  onChange={(e) => setPaypalEmail(e.target.value)}
                  className="w-full p-2 border rounded-lg mb-4"
                  placeholder="PayPal Email"
                />
              </div>
            </div>
          )}

          {selectedOption === "mobile-money" && (
            <div className="mb-6">
              <label htmlFor="phone" className="font-semibold mb-2 block">Phone Number</label>
              <div className="flex items-center space-x-4 bg-white p-2 border rounded-lg">
                <PhoneInput
                  country={'ke'} // Default country code
                  value={phone}
                  onChange={setPhone}
                  inputProps={{
                    name: 'phone',
                    required: true,
                  }}
                  inputStyle={{
                    width: '100%',
                    border: 'none',
                    backgroundColor: 'transparent',
                    outline: 'none',
                  }}
                  buttonStyle={{
                    borderRadius: '8px 0 0 8px',
                  }}
                />
              </div>
            </div>
          )}

          {/* Confirm and Pay Button */}
          <div className="text-right">
            <button
              type="submit"
              className="px-6 py-2 bg-blue-500 text-white border-b-2 border rounded-full hover:scale-110 duration-300 hover:shadow-xl"
            >
              Confirm & pay
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default BookingPage;
