import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch, faSlidersH } from '@fortawesome/free-solid-svg-icons';
import { ChatArea } from './ChatArea';
import Person1 from "@/assets/message/Person1.png";
import Person2 from "@/assets/message/Person2.png";

export const Chat = () => {
  const initialMessages = [
    { id: 1, name: 'Alex Omondi1', message: 'Alright thanks', date: '5/11/2023' },
    { id: 2, name: 'Eric Omondi2', message: 'Alright thanks', date: '5/11/2023' },
    { id: 3, name: 'Eric Omondi3', message: 'Alright thanks', date: '5/11/2023' },
    { id: 4, name: 'Alex Omondi4', message: 'Alright thanks', date: '5/11/2023' },
    { id: 5, name: 'Eric Omondi8', message: 'Alright thanks', date: '5/11/2023' },
    { id: 6, name: 'Alex Omondi7', message: 'Alright thanks', date: '5/11/2023' },
  ];

  const [messages, setMessages] = useState(initialMessages);
  const [selectedUser, setSelectedUser] = useState(null);

  // Handle selecting a user from the sidebar
  const handleSelectUser = (name) => {
    setSelectedUser(name);
  };

  // Handle sending a new message
  const handleSendMessage = (message) => {
    const newMessage = {
      id: messages.length + 1,
      name: 'Me', // This indicates it's a message from the current user
      message,
      date: new Date().toLocaleDateString(),
    };
    setMessages((prevMessages) => [...prevMessages, newMessage]);
  };

  // Filter messages to show conversation with the selected user, including your messages
  const filteredMessages = messages.filter((msg) => msg.name === selectedUser || msg.name === 'Me');

  return (
    <div className="h-screen flex overflow-hidden w-full">
      {/* Sidebar */}
      <aside className="flex-shrink-0 bg-[#F8FAF8] overflow-y-auto w-[362px]">
        <div className="sticky top-0 z-10 p-4">
          <h2 className="text-2xl md:text-[30px] font-medium mb-4 ml-4">Messages</h2>
          <div className="flex items-center mb-4 ml-4">
            <div className="relative flex-grow">
              <input
                type="text"
                placeholder="Search"
                className="w-full h-10 pl-10 pr-4 py-2 border border-gray-300 rounded-full text-sm focus:border-gray-400 focus:outline-none"
              />
              <FontAwesomeIcon icon={faSearch} className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" />
            </div>
            <button className="ml-2 p-2 text-gray-600 hover:bg-gray-100 rounded-full">
              <FontAwesomeIcon icon={faSlidersH} />
            </button>
          </div>
        </div>

        {initialMessages.map((msg) => (
          <div key={msg.id} onClick={() => handleSelectUser(msg.name)}>
            <div className="flex items-center p-4 hover:bg-[#449FFB] cursor-pointer ml-3 mr-3 border-b rounded-lg">
              <div className="relative">
                <img
                  src={msg.name.includes('Alex') ? Person1 : Person2}
                  alt={msg.name}
                  className="w-12 h-12 rounded-full mr-3"
                />
                <span className="absolute bottom-0 right-3 block w-3 h-3 bg-blue-500 border-2 border-white rounded-full"></span>
              </div>
              <div className="flex-grow min-w-0 ml-1">
                <h3 className="font-semibold text-sm md:text-base text-[#292929] truncate">{msg.name}</h3>
                <p className="font-light text-[15px] text-[#393939] truncate">{msg.message}</p>
              </div>
              <div className="flex flex-col items-end ml-2">
                <span className="text-xs text-gray-500 mb-1">{msg.date}</span>
              </div>
            </div>
          </div>
        ))}
      </aside>

      {/* Chat Area */}
      {selectedUser ? (
        <ChatArea
          selectedUser={selectedUser}
          messages={filteredMessages}
          onSendMessage={handleSendMessage}
        />
      ) : (
        <div className="flex-1 flex items-center justify-center">Select a user to start chatting</div>
      )}
    </div>
  );
};
