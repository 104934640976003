import React, { useState } from "react";
import { setFormData } from "@/redux/Listing/listingSlice";
import { useSelector, useDispatch } from "react-redux";

const Verification = () => {
  const dispatch = useDispatch();
  const guestVerification = useSelector(
    (state) => state.hostListing.formData.guestVerificationMethod
  );

  // const [verificationFormData, setVerificationFormData] = useState({
  //   verification: "",
  // });

  const handleChange = (e) => {
    // const { name, value } = e.target;
    // setVerificationFormData((prev) => ({
    //   ...prev,
    //   [name]: value,
    // }));

    dispatch(setFormData({ guestVerificationMethod: e.target.value }));
  };
  return (
    <form>
      <div>
        <h4 className=" font-medium text-xl ">Verification method</h4>
      </div>

      <div className="mt-5 border border-[#A3A3A3] bg-laara-secondary rounded-[6px] py-6 px-4 md:px-[30px] space-y-2.5 max-w-[733px] w-full ">
        <p className="text-lg ">
          How would you want your guests to verify their ID?
        </p>

        <div className="space-y-3">
          <div className="flex  items-start md:items-center gap-2">
            <input
              type="radio"
              id="basic"
              name="verification"
              className="size-5"
              value="BASIC"
              checked={guestVerification === "BASIC"}
              onChange={handleChange}
            />
            <label htmlFor="basic" className="text-base">
              Basic verification{" "}
              <span className="text-[#626262]">
                (e.g: email and mobile verification)
              </span>
            </label>
          </div>

          <div className="flex items-start md:items-center gap-2">
            <input
              type="radio"
              id="advanced"
              name="verification"
              className="size-5"
              value="ADVANCED"
              checked={guestVerification === "ADVANCED"}
              onChange={handleChange}
            />
            <label htmlFor="advanced" className="text-base">
              Advanced verification{" "}
              <span className="text-[#626262]">
                (e.g: ID and face ID verification)
              </span>
            </label>
          </div>
        </div>
      </div>
    </form>
  );
};

export default Verification;
