import React, { useState } from 'react';
import Sort from '@/assets/AdminDash/Sort.svg'
import { Edit, Move, Trash2 } from 'lucide-react';
import PropertyEditModal from '../../Modals/PropertyEditModal';
import DeleteTaskModal from '../../Modals/DeleteTaskModal';

function PropertyHeader() {
  const stats = [
    { title: 'Hotels', value: 1590 },
    { title: 'Boardroom', value: 1590 },
    { title: 'Expo', value: 1590 },
    { title: 'Hotels', value: 1590 },
    { title: 'Hotels', value: 1590 },
    { title: 'Vacation rentals', value: 1590 },
  ];

  return (
    <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4">
      {stats.map((stat, index) => (
        <div key={index} className="bg-[#DAECFE] p-4 rounded">
          <h2 className="text-sm font-normal text-[#888888] mb-3">{stat.title}</h2>
          <p className="text-2xl font-semibold">{stat.value}</p>
        </div>
      ))}
    </div>
  );
}

function PropertyList() {
  const [pageEntries, setPageEntries] = useState(10);
  const [editingProperty, setEditingProperty] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [propertyToDelete, setPropertyToDelete] = useState(null);

  const properties = Array(30).fill({
    name: 'Brooklyn Simmons',
    host: 'user@gmail.com',
    type: 'Hotel',
  });

  const handlePageEntriesChange = (e) => {
    const value = Math.max(10, Math.round(parseInt(e.target.value, 10) / 10) * 10);
    setPageEntries(value);
  };

  const handleEdit = (property) => {
    setEditingProperty(property);
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setEditingProperty(null);
  };

  const handleSave = (updatedProperty) => {
    // Logic to save the updated property
    setEditingProperty(updatedProperty);
    setIsModalOpen(false);
  };

  const handleDelete = (property) => {
    setPropertyToDelete(property);
    setIsDeleteModalOpen(true);
  };

  const handleCloseDeleteModal = () => {
    setIsDeleteModalOpen(false);
    setPropertyToDelete(null);
  };

  const handleConfirmDelete = () => {
    setIsDeleteModalOpen(false);
    setPropertyToDelete(null);
  };

  return (
    <div className="mt-4">
      <div className="flex justify-between items-center mb-4">
        <div className="flex items-center">
          <span className="mr-2 text-sm font-normal text-[#8C8C8C]">Page Entries</span>
          <input
            type="number"
            className="w-16 border rounded p-1"
            value={pageEntries}
            onChange={handlePageEntriesChange}
            min="10"
            step="10"
          />
        </div>
        <button className="bg-[#DAECFE] text-[#0466C8] px-4 py-2 rounded flex items-center">
          <img src={Sort} alt="Sort Icon" className="mr-2" />
          Sort
        </button>
      </div>
      <div className="overflow-x-auto">
        <table className="w-full">
          <thead>
            <tr className="border-b border-t bg-[#EEEEEE] font-bold text-sm">
              <th className="text-left p-3">Name of Property</th>
              <th className="text-left p-3">Host</th>
              <th className="text-left p-3">Property Type</th>
              <th className="text-left p-3">Action</th>
            </tr>
          </thead>
          <tbody>
            {properties.slice(0, pageEntries).map((property, index) => (
              <PropertyItem key={index} property={property} onEdit={handleEdit} onDelete={handleDelete} />
            ))}
          </tbody>
        </table>
      </div>
      <PropertyEditModal isOpen={isModalOpen} onClose={handleCloseModal} property={editingProperty} onSave={handleSave} />
      <DeleteTaskModal isOpen={isDeleteModalOpen} onClose={handleCloseDeleteModal} onConfirm={handleConfirmDelete} />
    </div>
  );
}

function PropertyItem({ property, onEdit, onDelete }) {
  const [openDropdown, setOpenDropdown] = useState(false);

  const toggleDropdown = () => {
    setOpenDropdown(!openDropdown);
  };

  const handleEdit = () => {
    onEdit(property);
    toggleDropdown();
  };

  const handleMove = () => {
    toggleDropdown();
  };

  const handleDelete = () => {
    onDelete(property)
    toggleDropdown();
  };

  return (
    <tr className="border-b font-normal text-sm text-[#262626]">
      <td className="p-3 items-center text-[#434343]">
        <input type="checkbox" className="mr-2 w-4 h-4 p-3" />
        {property.name}
      </td>
      <td className="p-3">{property.host}</td>
      <td className="p-3">{property.type}</td>
      <td className="p-3 relative">
      <button className="text-black w-full h-full flex " onClick={toggleDropdown}>
        <div className="flex flex-col items-center justify-center space-y-0.5">
          <div className="w-1 h-1 bg-black rounded-full"></div>
          <div className="w-1 h-1 bg-black rounded-full"></div>
          <div className="w-1 h-1 bg-black rounded-full"></div>
        </div>
      </button>
      {openDropdown && (
        <div className="absolute right-0 mt-2 w-40 bg-white rounded-md overflow-hidden shadow-xl z-10">
          <button
            className="block px-4 py-2 text-sm w-full text-left hover:bg-gray-100"
            onClick={handleEdit}
          >
            <Edit size={16} className="inline mr-2" /> Edit
          </button>
          <button 
            className="block px-4 py-2 text-sm w-full text-left hover:bg-gray-100" 
            onClick={handleMove}
          >
            <Move size={16} className="inline mr-2" /> Move
          </button>
          <button
            className="block px-4 py-2 text-sm w-full text-left hover:bg-gray-100"
            onClick={handleDelete}
          >
            <Trash2 size={16} className="inline mr-2" /> Delete
          </button>
        </div>
      )}
    </td>
    </tr>
  );
}


function RequestsList() {
  const requests = Array(7).fill('Purity Ngigi listed a property');

  return (
    <div className="w-full">
      <div className="bg-white border border-gray-100 p-2 rounded shadow-md">
        <div className="flex justify-between items-center mb-4 ">
          <h2 className="font-normal text-lg ">Requests</h2>
          <a href="#" className="text-[#0466C8] text-sm font-normal">See all</a>
        </div>
        <p className="text-sm font-normal text-[#AEAEAE] mb-2 border-t">Today</p>
        {requests.map((request, index) => (
          <div key={index} className="flex justify-between items-center mb-2">
            <div className="flex items-center bg-[#DAECFE] rounded py-1 px-3 w-full">
              <span className="text-sm font-normal text-black">{request}</span>
              <a href="#" className="text-[#0466C8] font-normal text-sm ml-20">
                View
              </a>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

const PropertyManagement = () => {
  return (
    <div className="container mx-auto p-4 flex flex-col md:flex-row">
      <div className="w-full md:w-[717px] mr-10">
        <PropertyHeader />
        <PropertyList />
      </div>
      <div className="w-full md:w-[348px] mt-4 md:mt-0">
        <RequestsList />
      </div>
    </div>
  );
};

export default PropertyManagement;
