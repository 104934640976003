import React, { useState } from 'react';
import { Search } from 'lucide-react';

function Header({ toggleSidebar }) {
  const [searchTerm, setSearchTerm] = useState('');

  return (
    <header className="bg-[#DAECFE] shadow-md h-[76px] px-4 flex items-center justify-between">
      <button onClick={toggleSidebar} className="md:hidden">
        <svg
          className="w-6 h-6"
          fill="none"
          stroke="currentColor"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 6h16M4 12h16M4 18h16" />
        </svg>
      </button>
      <div className="relative" style={{ width: '300px' }}>
        <input
          type="text"
          placeholder="Search users and more"
          className="w-full pl-10 pr-4 py-2 text-sm font-normal text-[#626262] border rounded-full focus:outline-none"
          style={{ backgroundColor: 'rgba(255, 255, 255, 0.59)' }}
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
        <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
          <Search className="h-4 w-4 sm:h-5 sm:w-5 text-gray-400" />
        </div>
      </div>
    </header>
  );
}

export default Header;
