import { useState } from 'react';
import { Calendar } from "@/components/ui/calendar";

const MyCalendar = ({ date, setDate }) => {
  return (
    <div className="text-[#292929] flex flex-col lg:flex-row lg:gap-8">
      <div className="p-3 md:p-6 lg:w-1/2">
        <p className="text-[10px] text-[#7B7B7B] my-3">Check In Date</p>
        <Calendar
          initialFocus
          mode="range"
          defaultMonth={date?.from}
          selected={date}
          onSelect={setDate}
          numberOfMonths={2} 
          className="w-full"
        />
      </div>
      <div className="p-3 md:p-6 lg:w-1/2">
        <p className="text-[10px] text-[#7B7B7B] ">Check Out Date</p>
      </div>
    </div>
  );
};

export default MyCalendar;
