import React from 'react';

function PropertyEditModal({ isOpen, onClose, property, onSave }) {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-gray-600 bg-opacity-50 flex justify-center items-center">
      <div className="bg-white p-6 rounded-lg shadow-lg w-full max-w-[821px]">
        <h2 className="text-lg font-semibold mb-4 text-[#0466C8]">Edit Property</h2>
        {property && (
          <form>
            <div className="mb-4">
              <label className="block text-sm font-medium text-gray-700">Name</label>
              <input
                type="text"
                value={property.name}
                onChange={(e) => onSave({ ...property, name: e.target.value })}
                className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3"
              />
            </div>
            <div className="mb-4">
              <label className="block text-sm font-medium text-gray-700">Host</label>
              <input
                type="text"
                value={property.host}
                onChange={(e) => onSave({ ...property, host: e.target.value })}
                className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3"
              />
            </div>
            <div className="mb-4">
              <label className="block text-sm font-medium text-gray-700">Type</label>
              <input
                type="text"
                value={property.type}
                onChange={(e) => onSave({ ...property, type: e.target.value })}
                className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3"
              />
            </div>

            <div className="flex justify-end space-x-4">
              <button className="px-4 py-2 border border-[#0466C8] text-[#0466C8] rounded" onClick={onClose}>
                Cancel
              </button>
              <button className="px-4 py-2 bg-[#0466C8] text-white rounded" onClick={onClose}>
                Done
              </button>
            </div>
          </form>
        )}
      </div>
    </div>
  );
}

export default PropertyEditModal;
