import { useState, useEffect } from "react";
import axios from "axios";
import Profile4 from "@/assets/profile/Profile4.png";
import eye from "@/assets/profile/eye2.png";
import leftArrow from "@/assets/reservations/arrow-left.jpg";
import { useNavigate, useParams } from "react-router-dom";
import { getHostProfile, getUserProfile, getToken } from "@/api";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

export const HostEdit = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  const api = axios.create({
    baseURL: "https://dev.api.laaragroup.com",
    headers: {
      accept: "application/json",
    },
  });

  const [formValues, setFormValues] = useState({});
  const [formFields, setFormFields] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [phoneNumber, setPhoneNumber] = useState("");

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [hostProfileResponse, userProfileResponse] = await Promise.all([
          getHostProfile(),
          getUserProfile(),
        ]);

        const mergedData = {
          firstName: userProfileResponse.user.firstName || "",
          lastName: userProfileResponse.user.lastName || "",
          emailAddress: userProfileResponse.user.emailAddress || "",
          phoneNumber: userProfileResponse.user.phoneNumber || "",
          country: userProfileResponse.user.address?.country || "",
          city: userProfileResponse.user.address?.city || "",
          bio: hostProfileResponse.user.bio || "",
          profileImageUrl: userProfileResponse.user.profileImageUrl || Profile4, // Add this line
        };

        setFormValues(mergedData);
        setPhoneNumber(mergedData.phoneNumber);
        setFormFields([
          {
            id: "firstName",
            label: "First Name",
            type: "text",
            value: mergedData.firstName,
          },
          {
            id: "lastName",
            label: "Last Name",
            type: "text",
            value: mergedData.lastName,
          },
          {
            id: "emailAddress",
            label: "Email",
            type: "email",
            value: mergedData.emailAddress,
          },
          {
            id: "phoneNumber",
            label: "Phone",
            type: "tel",
            value: mergedData.phoneNumber,
          },
          {
            id: "country",
            label: "Country",
            type: "text",
            value: mergedData.country,
          },
          { id: "city", label: "City", type: "text", value: mergedData.city },
          { id: "bio", label: "Bio", type: "textarea", value: mergedData.bio },
        ]);
        setLoading(false);
      } catch (error) {
        setError("Error fetching data");
        setLoading(false);
      }
    };

    fetchData();
  }, [id]);

  const handleChange = (event) => {
    const { id, value } = event.target;
    setFormValues((prevValues) => ({
      ...prevValues,
      [id]: value,
    }));
  };

  const handlePhoneChange = (value) => {
    setPhoneNumber(value);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    const formattedData = {
      bio: formValues.bio || "",
      deviceToken: "",
      phoneNumber: phoneNumber || "",
      address: {
        country: formValues.country || "",
        city: formValues.city || "",
      },
    };

    try {
      await api.put("/users", formattedData, {
        headers: {
          Authorization: `Bearer ${getToken()}`,
        },
      });
      navigate(`/host-profile/${id}`);
    } catch (error) {
      console.error("Error updating data:", error);
      setError("Error updating data");
    }
  };

  const gotoProfilePage = () => {
    navigate(`/host-profile/${id}`);
  };

  if (loading) {
    return (
      <div className="flex justify-center items-center h-screen">
        <div className="w-16 h-16 border-4 border-t-4 border-gray-200 border-t-blue-500 border-solid rounded-full animate-spin"></div>
      </div>
    );
  }

  if (error) {
    return <div>{error}</div>;
  }

  return (
    <div className="max-w-[1440px] mx-auto px-4">
      <div className="max-w-[1088px] mx-auto mt-[40px] flex flex-col">
        <header className="flex items-center">
          <h1 className="text-[26px] text-[#4F4F4F] font-bold">
            {formValues.firstName} {formValues.lastName}
          </h1>
          <img src={leftArrow} alt="Back Arrow" className="w-6 h-6 mr-2 ml-2" />
          <button
            className="text-[#4F4F4F] text-[18px] font-normal"
            onClick={gotoProfilePage}
          >
            Edit Profile Details
          </button>
        </header>

        <div className="w-full flex flex-col sm:flex-row items-center">
          <img
            src={formValues.profileImageUrl || Profile4}
            alt="Profile"
            className="h-24 sm:h-40 mb-2 sm:mb-0 sm:ml-[-20px] sm:mr-[-30px]"
          />

          <div className="flex flex-col sm:flex-row w-full sm:w-auto sm:ml-4 items-center mb-9">
            <button className="bg-laara-primary text-white px-3 py-2 text-sm rounded-xl mb-2 sm:mb-0 flex items-center justify-center w-full sm:w-auto">
              <img src={eye} alt="Edit Photo" className="w-3 h-3 mr-1" />
              Edit Photo
            </button>
            <button className="text-red-500 px-3 py-2 text-sm border border-red-500 rounded-xl sm:ml-2 w-full sm:w-auto">
              Remove Photo
            </button>
          </div>
        </div>

        <div className="w-full max-w-screen-xl h-auto min-h-[942px] flex flex-col gap-9">
          <form className="flex flex-col gap-9 ml-6" onSubmit={handleSubmit}>
            {formFields.map((field) => (
              <div key={field.id} className="flex flex-col">
                {/* Conditionally render label */}
                {field.id !== "phoneNumber" && (
                  <label htmlFor={field.id} className="mb-2">
                    {field.label}
                    <span className="text-red-500">*</span>
                  </label>
                )}

                {field.type === "textarea" ? (
                  <textarea
                    id={field.id}
                    name={field.id}
                    value={formValues[field.id] || ""}
                    onChange={handleChange}
                    className="p-2 h-40 border border-gray-300 rounded focus:bg-white focus:border-gray-300 focus:outline-none required"
                  ></textarea>
                ) : field.id === "phoneNumber" ? (
                  <div className="relative flex flex-col w-full">
                    <label htmlFor="phoneNumber" className="mb-2">
                      Phone
                      <span className="text-red-500">*</span>
                    </label>

                    <div className="relative flex items-center w-full">
                      {/* PhoneInput component */}
                      <PhoneInput
                        country={"us"}
                        value={phoneNumber}
                        onChange={handlePhoneChange}
                        inputProps={{
                          name: "phone",
                          required: true,
                          autoFocus: false,
                        }}
                        containerClass="!w-full"
                        inputClass="!pl-24 !text-[16px] !rounded-[13px] !h-16 !w-full"
                        buttonClass="!ml-5 !border-0 !bg-transparent"
                        countryClass="!w-[32px] !h-[20px]"
                      />

                      {/* Vertical separator */}
                      <div className="absolute inset-y-0 left-20 border-l border-gray-300 h-10 top-3"></div>
                    </div>
                  </div>
                ) : (
                  <input
                    id={field.id}
                    name={field.id}
                    type={field.type}
                    value={formValues[field.id] || ""}
                    onChange={handleChange}
                    className="p-6 border border-gray-300 rounded-[13px] h-16 focus:bg-white focus:border-gray-300 focus:outline-none required"
                  />
                )}
              </div>
            ))}

            <div className="flex justify-between mb-3">
              <button
                type="button"
                onClick={() => navigate(`/host-profile/${id}`)}
                className="border border-[#ff3737] text-[#ff2f2f] px-4 py-2 text-[16px] font-normal rounded-[36px] w-[216px] h-12"
              >
                Cancel
              </button>
              <button
                type="submit"
                className="bg-laara-primary text-white px-4 py-2 text-[16px] font-normal rounded-[36px] w-[216px] h-12 hover:bg-laara-primary/90"
              >
                Save Changes
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};
