import  { useState } from 'react';
import PropTypes from 'prop-types';

import MTN from '@/assets/Guest-Properties/MTN.svg'
import Airtel from '@/assets/Guest-Properties/Airtel.svg'
import saf from '@/assets/Guest-Properties/Safaricom.svg'
import { useNavigate } from 'react-router-dom';
import { TfiAngleLeft } from 'react-icons/tfi';



// MobileNetworkForm Component
const MobileNetworkForm = ({ network, onNetworkChange }) => {
  const [phoneNumber, setPhoneNumber] = useState('');
  const [save, setSave] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();
    // Handle form submission logic here
    console.log({ network, phoneNumber, save });
  };

  return (
    <form onSubmit={handleSubmit} className="space-y-4 max-w-md">
      <div className="mb-4 relative">
        <label htmlFor="network" className="block text-sm font-medium text-gray-700">
          Select Network Provider
        </label>
        <select
          id="network"
          value={network}
          onChange={(e) => onNetworkChange(e.target.value)}
          className="mt-1 block w-full px-3 py-2 border bg-white border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
        >
          <option value="safaricom">Safaricom</option>
          <option value="airtel">Airtel</option>
          <option value="mtn">MTN</option>
        </select>
      </div>
      
      {/* Phone number input */}
      <div className="mb-4">
        <label htmlFor="phone" className="block text-sm font-medium text-gray-700">
          Phone Number
        </label>
        <input
          type="text"
          id="phone"
          value={phoneNumber}
          onChange={(e) => setPhoneNumber(e.target.value)}
          className="mt-1 block w-full px-3 py-2 border bg-white border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
          placeholder="Phone number"
        />
      </div>
      
      {/* Save number checkbox */}
      <div className="flex items-center mb-4">
        <input
          type="checkbox"
          id="save"
          checked={save}
          onChange={() => setSave(!save)}
          className="h-4 w-4 text-blue-500 focus:ring-blue-500 border-gray-300 rounded"
        />
        <label htmlFor="save" className="ml-2 text-sm font-medium text-gray-700">
          Save Number
        </label>
      </div>
    </form>
  );
};

MobileNetworkForm.propTypes = {
  network: PropTypes.string.isRequired,
  onNetworkChange: PropTypes.func.isRequired,
};

// Booking Component
const Booking = () => {
  const [network, setNetwork] = useState('');

  const handleNetworkChange = (value) => {
    setNetwork(value);
  };

  const navigate = useNavigate();

  return (
    <section className='max-w-full min-h-screen p-4 xl:px-20'>
      <div className='flex items-center gap-1 mb-4'>
        <TfiAngleLeft size={25}
        onClick={() => navigate(-1)}
         className='text-gray-400 hover:scale-150 transition-transform duration-300 cursor-pointer' />
        <h2 className='font-semibold text-neutral'>Booking</h2>
      </div>

      <div className='flex flex-col lg:flex-row p-3 gap-4'>
        <div className='lg:w-[43%]'>
          <div className="mb-4 border p-4 rounded-lg">
            <h3 className="text-lg font-semibold mb-2">Hotel</h3>
            <p className="text-gray-700 mb-1">Golden Star Hotel</p>
            <p className="text-gray-500 mb-2">Kiambu 00232, Kenya</p>
            <div className="flex flex-wrap gap-4 text-gray-600">
              <span>📺 Flatscreen TV</span>
              <span>❄️ Air Conditioning</span>
              <span>🌐 Free WiFi</span>
              <span>🕒 24hrs Front Desk</span>
              <span>🅿️ Parking Area</span>
            </div>
          </div>

          <div className="mb-4 border p-4 rounded-lg">
            <h3 className="text-lg font-semibold mb-4">Your Booking Details</h3>
            <div className="flex justify-between text-gray-700">
              <div className='text-[hsla(0,0%,31%,1)]'>
                <p>Check in</p>
                <p className="text-sm">Wed 7 Aug 2024</p>
                <p className="text-sm">13:00 - 20:00</p>
              </div>
              <div className='border-l-2 border-gray-400'></div>
              <div className='text-[hsla(0,0%,31%,1)]'>
                <p>Check out</p>
                <p className="text-sm">Wed 7 Aug 2024</p>
                <p className="text-sm">13:00 - 20:00</p>
              </div>
            </div>
            <div className="text-gray-700 mt-2 mb-2 font-semibold flex">
              <span className='w-full'>Number of nights</span>
              <span className='flex justify-end w-full'>2 Nights</span>
            </div>
            <hr />
            <div className='mt-3 mb-2'>
              <h3 className='font-semibold'>Your Selection</h3>
              <span>1 room for 2 Adults</span>
            </div>
            <p className="text-blue-600 mt-2 underline">Change your selection</p>
          </div>

          <div className="border rounded-lg p-4">
            <h4 className="text-md font-semibold mb-4">Prices</h4>
            <div className='text-sm text-[hsla(0,0%,43%,1)]'>
              <p className="text-xl text-blue-500 mb-2">US$124</p>
              <p className='mb-2'>Price for 3 nights (17 Jun - 20 Jun)</p>
              <p className='mb-2'>+US$70.80 taxes and charges</p>
            </div>
          </div>
        </div>

        <div className='lg:w-[57%] '>
            <div className='border rounded-lg p-4'>
              <div className='mb-2 '>
                <div className='p-2 border-2 shadow-lg hover:border-blue-400 mb-2 w-[166px] h-[118px] rounded-lg'>
                  <input
                    type='radio'
                    checked={true}
                    onChange={() => handleNetworkChange('mtn')}
                  />
                  <div className='flex justify-center mx-auto'>
                    <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M8.33594 14.9987C8.33594 9.49898 8.33594 6.74911 10.0445 5.04058C11.753 3.33203 14.5029 3.33203 20.0026 3.33203C25.5023 3.33203 28.2521 3.33203 29.9608 5.04058C31.6693 6.74911 31.6693 9.49898 31.6693 14.9987V24.9987C31.6693 30.4984 31.6693 33.2482 29.9608 34.9569C28.2521 36.6654 25.5023 36.6654 20.0026 36.6654C14.5029 36.6654 11.753 36.6654 10.0445 34.9569C8.33594 33.2482 8.33594 30.4984 8.33594 24.9987V14.9987Z" stroke="#0466C8" strokeWidth="2.43909" strokeLinecap="round"/>
                      <path d="M18.3359 31.668H21.6693" stroke="#0466C8" strokeWidth="2.43909" strokeLinecap="round" strokeLinejoin="round"/>
                      <path d="M15 3.33203L15.1483 4.22206C15.4698 6.15085 15.6305 7.11525 16.292 7.7021C16.982 8.31426 17.9602 8.33203 20 8.33203C22.0398 8.33203 23.018 8.31426 23.708 7.7021C24.3695 7.11525 24.5302 6.15085 24.8517 4.22206L25 3.33203" stroke="#0466C8" strokeWidth="2.43909" strokeLinejoin="round"/>
                    </svg>
                  </div>
                </div>
                <span className='ml-6 text-[hsla(0,0%,43%,1)]'>Mobile Money</span>
              </div>

              <div className='space-y-4 max-w-md'>
                <div className='flex items-center justify-between p-2 border rounded-lg'>
                  <div className='flex items-center space-x-2'>
                    <div className='border p-2'>
                      <span>{MTN}</span> {/* You can replace this with the actual logo */}
                    </div>
                    <div className='flex flex-col'>
                      <span> Mobile Money</span>
                      <span>**** *9865</span>
                    </div>
                  </div>
                  <input
                    type="radio"
                    checked={network === 'mtn'}
                    onChange={() => handleNetworkChange('mtn')}
                    className='ml-4'
                  />
                </div>
                <div className='flex items-center justify-between p-2 border rounded-lg'>
                  <div className='flex items-center space-x-2'>
                    <div className='border p-2'>
                      <span>{saf}</span> {/* You can replace this with the actual logo */}
                    </div>
                    <div className='flex flex-col'>
                      <span>Mobile Money</span>
                      <span>**** *9865</span>
                    </div>
                  </div>
                  <input
                    type="radio"
                    checked={network === 'safaricom'}
                    onChange={() => handleNetworkChange('safaricom')}
                    className='ml-4'
                  />
                </div>
                <div className='flex items-center justify-between p-2 border rounded-lg'>
                  <div className='flex items-center space-x-2'>
                    <div className='border p-2'>
                      <span>{Airtel}</span> {/* You can replace this with the actual logo */}
                    </div>
                    <div className='flex flex-col'>
                      <span>Mobile Money</span>
                      <span>**** *9865</span>
                    </div>
                  </div>
                  <input
                    type="radio"
                    checked={network === 'airtel'}
                    onChange={() => handleNetworkChange('airtel')}
                    className='ml-4'
                  />
                </div>

                <h3 className='font-semibold'>Add New Mobile Number</h3>
                <MobileNetworkForm network={network} onNetworkChange={handleNetworkChange} />
              </div>
            </div>
            <div className='flex flex-col lg:flex-row mt-3 p-2 w-full '>
              {/* Left side: Pricing and Dates */}
              <div className='text-sm lg:w-1/2'>
                <h4 className='text-[hsla(210,96%,40%,1)]'>US$ 0</h4>
                <p className='text-[hsla(0,0%,53%,1)]'>+US$70.80 taxes and charges</p>
                <p className='text-[hsla(0,0%,53%,1)]'>Jun 17 - Jun 20</p>
              </div>
              
              {/* Right side: Confirm & Pay Button */}
              <div className=' w-full lg:w-1/2 flex lg:justify-end lg:items-center mt-2 lg:mt-0'>
                <button className='border rounded-full bg-[hsla(210,96%,40%,1)] text-white px-4 py-2 lg:ml-auto lg:mr-0 hover:shadow-2xl hover:scale-105 duration-300'>Confirm & Pay</button>
              </div>
            </div>

        </div>
      </div>
    </section>
  );
};

export default Booking;
