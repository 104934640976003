import account from '../../assets/settings/account.svg';
import finance from '../../assets/settings/finance.svg';
import privacy from '../../assets/settings/privacy.svg';
import preference from '../../assets/settings/preference.svg';
import { Link } from 'react-router-dom';

export default function Settings() {
  return (
    <section className="max-w-[1440px] mx-auto">
      <div className="flex flex-col pb-10 ">
        <div className="flex flex-col p-24 max-md:p-5 py-5 self-center mt-0 w-full  max-md:mt-10 max-md:max-w-full">
          <div className="flex flex-col  mt-16 w-full leading-[120%] max-md:px-5 max-md:mt-10 max-md:max-w-full">
            <div className="text-4xl font-bold text-neutral-600 max-md:max-w-full">
              <h1>Settings</h1>
            </div>
            <div className="flex gap-0 justify-between mt-5 max-md:flex-wrap">
              <div className="flex-1 text-2xl text-neutral-600 max-md:max-w-full">
                Manage and adjust your experience
              </div>
            </div>
          </div>
          <div className="flex flex-col mt-14 max-md:mt-10 max-md:max-w-full">
            <div className="justify-center px-8 max-md:px-5 max-md:max-w-full">
              <div className="flex gap-5 max-md:flex-col max-md:gap-5">
                <div className="flex flex-col w-6/12 max-md:w-full">
                  <div className="hover:bg-[#F4FEFF] transition-colors duration-300 flex grow gap-5 justify-center px-7 py-8 w-full bg-white rounded-2xl border border-solid shadow-2xl border-stone-300 max-md:flex-wrap max-md:px-5 max-md:mt-5">
                    <img
                      loading="lazy"
                      src={account}
                      className="shrink-0 self-start aspect-square w-[38px]"
                    />
                    <Link to='/account' className="flex flex-col flex-1 max-md:max-w-full">
                      <div className="text-3xl font-semibold leading-9 text-sky-600 max-md:max-w-full">
                        Account Settings
                      </div>
                      <div className="mt-2.5 text-xl leading-8 text-neutral-600 max-md:max-w-full">
                        Update your account details, change currency and choose a
                        preferred language.
                      </div>
                    </Link>
                  </div>
                </div>
                <div className="flex flex-col w-6/12 max-md:w-full">
                  <Link to='/financesettings' className="hover:bg-[#F4FEFF] transition-colors duration-300 flex grow gap-5 justify-center px-7 py-8 w-full bg-white rounded-2xl border border-solid shadow-2xl border-stone-300 max-md:flex-wrap max-md:px-5 max-md:mt-5">
                    <img
                      loading="lazy"
                      src={finance}
                      className="shrink-0 self-start aspect-square w-[38px]"
                    />
                    <div className="flex flex-col flex-1 max-md:max-w-full">
                      <div className="text-3xl font-semibold leading-9 text-sky-600 max-md:max-w-full">
                        Financial Information
                      </div>
                      <div className="mt-2.5 text-xl leading-8 text-neutral-600 max-md:max-w-full">
                        View your billing details, your coupons & history of all
                        transactions
                      </div>
                    </div>
                  </Link>
                </div>
              </div>
            </div>
            <div className="justify-center px-8 mt-14 max-md:px-5 max-md:mt-10 max-md:max-w-full">
              <div className="flex gap-5 max-md:flex-col max-md:gap-5">
                <div className="flex flex-col w-6/12 max-md:w-full">
                  <Link to='/privacy' className="hover:bg-[#F4FEFF] transition-colors duration-300 flex grow gap-5 justify-center px-7 py-8 w-full bg-white rounded-2xl border border-solid shadow-2xl border-stone-300 max-md:flex-wrap max-md:px-5 max-md:mt-5">
                    <img
                      loading="lazy"
                      src={privacy}
                      className="shrink-0 self-start aspect-square w-[38px]"
                    />
                    <div className="flex flex-col flex-1 max-md:max-w-full">
                      <div className="text-3xl font-semibold leading-9 text-sky-600 max-md:max-w-full">
                        Privacy & Security
                      </div>
                      <div className="mt-2.5 text-xl leading-8 text-neutral-600 max-md:max-w-full">
                        Manage your account and data and account password.
                      </div>
                    </div>
                  </Link>
                </div>
                <div className="flex flex-col w-6/12 max-md:w-full">
                  <Link to='/preference' className="hover:bg-[#F4FEFF] transition-colors duration-300 flex grow gap-5 justify-center px-7 py-8 w-full bg-white rounded-2xl border border-solid shadow-2xl border-stone-300 max-md:flex-wrap max-md:px-5 max-md:mt-5">
                    <img
                      loading="lazy"
                      src={preference}
                      className="shrink-0 self-start aspect-square w-[38px]"
                    />
                    <div className="flex flex-col flex-1 max-md:max-w-full">
                      <div className="text-3xl font-semibold leading-9 text-sky-600 max-md:max-w-full">
                        User Preferences{" "}
                      </div>
                      <div className="mt-2.5 text-xl leading-8 text-neutral-600 max-md:max-w-full">
                        Select your preferred language and customize your
                        experience on the platform.
                      </div>
                    </div>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div> 
      </div>
    </section>
  )
}
