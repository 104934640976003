import React from 'react';
import bed1 from "@/assets/ListingProcess/bed1.png"
import bed2 from "@/assets/ListingProcess/bed2.png"
import property1 from "@/assets/ListingProcess/property1.svg"
import property2 from "@/assets/ListingProcess/property2.svg"
import room1 from "@/assets/ListingProcess/Room1.svg"


export const Next2 = () => {
    const steps = [
        {
            number: 1,
            title: 'Property details',
            description: 'The basics, add your property name, address amenities and more',
            status: 'completed'
        },
        {
            number: 2,
            title: 'Pricing',
            description: 'Share the prices you would want guests to pay for your property',
            status: 'completed'
        },
       
        {
            number: 3,
            title: 'Photos',
            description: 'Share some photos of your place for guests to know what to expect',
            status: 'completed',
            photos: {
                property: [bed1, bed2, bed2, bed2, bed2],
                food: [property1,  property2, property2, property2, property2],
            }
        },
        {
            number: 4,
            title: 'Final steps',
            description: 'Set up payment and invoicing before you open for bookings',
            status: 'completed'
        },
    ];

    const Room = ({ type, guests, beds, bathroom, count }) => (
        <div className="flex items-center justify-between p-4 mb-2 rounded-lg">
            <div className="flex items-center">
                <img src={room1} alt={type} className="w-16 h-16 object-cover rounded-lg mr-4" />
                <div className='ml-2'>
                    <h3 className="font-semibold">{type}</h3>
                    <div className="grid grid-cols-4 gap-x-1 mt-1 ">
                        <div className="text-sm text-gray-600 border-r pr-1">Guests</div>
                        <div className="text-sm text-gray-600 border-r pr-1">Beds</div>
                        <div className="text-sm text-gray-600 border-r pr-1">Bathroom</div>
                        <div className="text-sm text-gray-600">Rooms of this type</div>
                        <div className="text-sm text-gray-800 border-r pr-1">{guests}</div>
                        <div className="text-sm text-gray-800 border-r pr-1">{beds}</div>
                        <div className="text-sm text-gray-800 border-r pr-1">{bathroom}</div>
                        <div className="text-sm text-gray-800">{count}</div>
                    </div>
                </div>
            </div>
            <div>
                <button className="text-red-500 mr-5">Delete</button>
                <button className="text-laara-primary">Edit</button>
            </div>
        </div>
    );

    const PhotoSection = ({ title, photos }) => (
        <div className="mb-4">
            <h3 className="font-semibold mb-2">{title}</h3>
            <div className="flex space-x-2 overflow-x-auto">
                {photos.map((photo, index) => (
                    <img key={index} src={photo} alt={`${title} ${index + 1}`} className="w-24 h-24 object-cover rounded-lg" />
                ))}
            </div>
        </div>
    );

    const Step = ({ number, title, description, status, rooms, photos }) => (
        <div className="bg-laara-secondary p-4 rounded-lg mb-4">
            <div className="flex items-center justify-between">
                <div className="flex items-center">
                    <div className="bg-[#108975] rounded-full p-1 mr-3">
                        <svg className="w-4 h-4 text-white" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7"></path>
                        </svg>
                    </div>
                    <div>
                        <h3 className="text-sm text-gray-600">Step {number}</h3>
                        <h2 className="text-lg font-semibold">{title}</h2>
                        <p className="text-sm text-gray-600">{description}</p>
                    </div>
                </div>
                {number !== 2 && number !== 4 && (
                    <div className="text-laara-primary">
                        Edit
                    </div>
                )}
            </div>


            {rooms && (
                <div className="mt-4">
                    {rooms.map((room, index) => (
                        <Room key={index} {...room} />
                    ))}
                    <div className="flex justify-end mt-4">
                        <button className="w-full max-w-[170px] text-laara-primary border border-laara-primary rounded-full py-2">Add another room</button>
                    </div>
                </div>
            )}
            {photos && (
                <div className="mt-4">
                    <PhotoSection title="Property" photos={photos.property} />
                    <PhotoSection title="Food" photos={photos.food} />
                    <div className="flex justify-end mt-4">
                        <button className="w-full max-w-[110px] text-laara-primary border border-laara-primary rounded-full py-2">Add photos</button>
                    </div>
                </div>
            )}
        </div>
    );

    return (
        <div className="max-w-3xl mx-auto p-4">
            {steps.map((step) => (
                <Step key={step.number} {...step} />
            ))}
            <button className="w-full bg-laara-primary text-white py-2 rounded-full">Complete registration</button>
        </div>
    );
};
