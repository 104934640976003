import React from 'react';
import { IoMdRefresh } from "react-icons/io";
import img from '@/assets/choose-us/illustrasi.png';
import payment from '@/assets/choose-us/icon/payment.png';

const ChooseUs = () => {
  return (
    <div className="w-full py-8 px-4 sm:px-8 lg:px-[88px]">
      <div className="mx-auto flex flex-col lg:flex-row items-center justify-between h-full space-y-8 lg:space-y-0">
        {/* Image Section */}
        <div className="w-full lg:w-1/2 mb-4 lg:mb-0 flex items-center justify-center">
          <img src={img} alt="Travels" className="w-full h-auto max-h-[512px] object-contain" />
        </div>

        {/* Content Section */}
        <div className="w-full lg:w-1/2 lg:pl-8 flex flex-col justify-center">
          <h3 className="text-xl sm:text-xl font-semibold text-[#3C3C3C] mb-4">
            Why Choose Us
          </h3>
          <p className="text-[#4F4F4F] text-base sm:text-lg mb-8">
            Enjoy different experiences in every place you visit and discover new and affordable adventures.
          </p>

          <div className="space-y-6 sm:space-y-7">
            {/* Competitive Prices */}
            <FeatureItem
              icon={<IoMdRefresh size={26} />}
              title="Competitive Prices"
              description="Enjoy top-quality stays without stretching your budget. We offer exceptional value, balancing affordability with luxury."
            />

            {/* Seamless Experience */}
            <FeatureItem
              icon={<IoMdRefresh size={26} />}
              title="Seamless Experience"
              description="Booking with us is hassle-free from start to finish. Our platform is user-friendly, and our support team is always ready to help."
            />

            {/* Payment Methods */}
            <FeatureItem
              icon={<img src={payment} alt="Payment method" className="w-6 h-6 object-contain border-none" />}
              title="Payment Methods"
              description="Choose from a range of secure payment options that fit your needs. We make paying easy and worry-free."
            />
          </div>
        </div>
      </div>
    </div>
  );
};

const FeatureItem = ({ icon, title, description }) => (
  <div className="flex items-start gap-3 sm:gap-4">
    <span className="border p-2 border-laara-primary rounded-lg text-laara-primary shadow-lg flex-shrink-0">
      {icon}
    </span>
    <div>
      <h3 className="text-base sm:text-lg font-medium text-[#3C3C3C] mb-1 sm:mb-2">{title}</h3>
      <p className="text-[#4F4F4F] text-sm sm:text-base font-normal">
        {description}
      </p>
    </div>
  </div>
);

export default ChooseUs;
