import { useState, useEffect } from 'react';
import { getPropertyById, updatePropertyAddress } from '@/api'; 
import GMaps from '@/components/shared/Maps/GMaps'; 
import { useParams, Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLessThan } from '@fortawesome/free-solid-svg-icons';

const EditPropertyLocation = () => {
  const { id } = useParams(); 
  const [property, setProperty] = useState(null);
  const [locationData, setLocationData] = useState({});
  const [openMap, setOpenMap] = useState(false);

  useEffect(() => {
    // Fetch property details by ID
    const fetchPropertyDetails = async () => {
      try {
        const propertyData = await getPropertyById(id);
        setProperty(propertyData);
        setLocationData({
          coordinates: {
            latitude: propertyData.property.address?.latitude || -1.2915285875091675,
            longitude: propertyData.property.address?.longitude || 36.8271134558112,
          }
        });
        console.log("Property", propertyData);
      } catch (error) {
        console.error('Error fetching property details:', error);
      }
    };

    fetchPropertyDetails();
  }, [id]);

  const handleSaveChanges = async () => {
    try {
      const updatedAddress = {
        ...property.address,
        coordinates: {
          latitude: locationData.latitude,
          longitude: locationData.longitude,
        },
      };

      await updatePropertyAddress(id, updatedAddress);
      alert('Location updated successfully!');
    } catch (error) {
      console.error('Error updating property address:', error);
      alert('Failed to update location');
    }
  };

  return (
    <div className="edit-property-location p-8 bg-base-white flex flex-col">
       <section className='top h-16 flex items-center p-2 gap-3'>
        <div className='flex items-center gap-4 text-lg text-gray-600 w-[700px]'>
          <Link to={`/view-property/${id}`}>
            <FontAwesomeIcon icon={faLessThan} className="text-base" />
          </Link>
          <h3>Location Info</h3>
        </div>
      </section>
      <h2 className="text-xl mb-4">Pin your property location</h2>

          {/* GMaps Component for editing the location pin */}
          <div className="gmaps-container max-w-[800px]">
            <GMaps
              containerStyle={{ height: '400px', width: '100%' }}
              placesAutoComplete={true}
              interactive={true}
              pin={locationData} // Pass current location data
              openMap={openMap}
              setOpenMap={setOpenMap}
            />
          </div>

          <button
        onClick={handleSaveChanges}
        className="mt-4 py-2 px-4 bg-blue-500 text-white rounded w-[215px]"
      >
        Save Changes
      </button>    </div>
  );
};

export default EditPropertyLocation;
