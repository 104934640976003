import React, { useState, useEffect } from "react";
import WeeklyRates from "./WeeklyRates";
import { TiTick } from "react-icons/ti";
import logo from "../../../assets/ListingProcess/logo.svg";
import help from "../../../assets/ListingProcess/help.svg";
import messages from "../../../assets/ListingProcess/messages.svg";
import notifications from "../../../assets/ListingProcess/notifications.svg";
import nav from "../../../assets/ListingProcess/nav.svg";
import ke from "../../../assets/settings/keflag.svg";
import tick from '../../../assets/ListingProcess/tick.svg';
import hand from '../../../assets/ListingProcess/hand.svg';
import cancel from '../../../assets/ListingProcess/cancel.svg';
import left from '../../../assets/ListingProcess/left.svg';
import { useDispatch,useSelector  } from 'react-redux';
import { setPricing } from '@/redux/Listing/listingSlice';

export default function Pricing() {
  const dispatch = useDispatch();
  const { pricing } = useSelector(state => state.hostListing.formData);
  const [state, setState] = useState({
    priceOneGuestHalfBoardDouble: '',
    priceTwoGuestsHalfBoardDouble: '',
    priceOneGuestFullBoardDouble: '',
    priceTwoGuestsFullBoardDouble: '',
    priceOneGuestHalfBoardSingle: '',
    priceTwoGuestsHalfBoardSingle: '',
    priceOneGuestFullBoardSingle: '',
    priceTwoGuestsFullBoardSingle: '',
  });

  const [showSection, setShowSection] = useState(false);
  const [currentStep, setCurrentStep] = useState(3);
  const [complete, setComplete] = useState(false);
  const [error, setError] = useState('');


  const handleInputChange = (event, key) => {
    const value = event.target.value;
    setState(prevState => {
      const newState = {
        ...prevState,
        [key]: value
      };

      return newState; // Return the new state
    });
  };

  useEffect(() => {
    const pricingData = [
      {
        price: parseFloat(state.priceOneGuestHalfBoardDouble) || 0,
        pricingOption: 'half-board',
        deductionRate: pricing[0]?.deductionRate || 10,
      },
      {
        price: parseFloat(state.priceTwoGuestsHalfBoardDouble) || 0,
        pricingOption: 'half-board',
        deductionRate: pricing[1]?.deductionRate || 10,
      },
      {
        price: parseFloat(state.priceOneGuestFullBoardDouble) || 0,
        pricingOption: 'full-board',
        deductionRate: pricing[2]?.deductionRate || 10,
      },
      {
        price: parseFloat(state.priceTwoGuestsFullBoardDouble) || 0,
        pricingOption: 'full-board',
        deductionRate: pricing[3]?.deductionRate || 10,
      },
      {
        price: parseFloat(state.priceOneGuestHalfBoardSingle) || 0,
        pricingOption: 'half-board',
        deductionRate: pricing[4]?.deductionRate || 10,
      },
      {
        price: parseFloat(state.priceTwoGuestsHalfBoardSingle) || 0,
        pricingOption: 'half-board',
        deductionRate: pricing[5]?.deductionRate || 10,
      },
      {
        price: parseFloat(state.priceOneGuestFullBoardSingle) || 0,
        pricingOption: 'full-board',
        deductionRate: pricing[6]?.deductionRate || 10,
      },
      {
        price: parseFloat(state.priceTwoGuestsFullBoardSingle) || 0,
        pricingOption: 'full-board',
        deductionRate: pricing[7]?.deductionRate || 10,
      }
    ];

    // Check if pricingData has changed
    const hasPricingChanged = !pricingData.every((item, index) => {
      return item.price === (pricing[index]?.price || 0) &&
             item.deductionRate === (pricing[index]?.deductionRate || 10);
    });

    if (hasPricingChanged) {
      dispatch(setPricing(pricingData));
    }
    
    validatePrices(); 
  }, [state, dispatch]);

  
  

  const calculateEarnings = (price) => {
    const numericPrice = parseFloat(price);
    return isNaN(numericPrice) ? 0 : Math.max(0, numericPrice - (numericPrice * 0.10));
  };

  const validatePrices = () => {
    const priceFields = [
      'priceOneGuestHalfBoardDouble',
      'priceTwoGuestsHalfBoardDouble',
      'priceOneGuestFullBoardDouble',
      'priceTwoGuestsFullBoardDouble',
      'priceOneGuestHalfBoardSingle',
      'priceTwoGuestsHalfBoardSingle',
      'priceOneGuestFullBoardSingle',
      'priceTwoGuestsFullBoardSingle'
    ];

    for (const field of priceFields) {
      if (state[field] === '' || isNaN(parseFloat(state[field])) || parseFloat(state[field]) <= 0) {
        setError(`Please enter a valid price for ${field.replace(/([A-Z])/g, ' $1').toLowerCase()}.`);
        return false;
      }
    }
    setError('');
    return true;
  };



  const renderPricingSection = (title, priceKey1, priceKey2, style) => (
    <div className="mt-16 max-md:mt-10 max-md:max-w-full w-[1077px]">
      <div className="flex flex-col md:flex-row gap-5">
        <div className="flex flex-col w-full md:w-[66%]">
          <div className="flex flex-col grow justify-center max-md:mt-6">
            <div className={`flex flex-col justify-center px-8 py-9 rounded-md ${style} border border-neutral-200 max-md:px-5`}>
              <div className="flex flex-col pb-1.5">
                <div className="text-2xl font-medium text-black">{title}</div>
                <div className="flex flex-col gap-5 pt-1.5 mt-7">
                  {[priceKey1, priceKey2].map((key, index) => (
                    <div key={index} className="flex flex-col md:flex-row gap-5">
                      <div className="flex flex-col flex-1 text-black">
                        <div className="text-base font-medium">
                          Price for {index === 0 ? 'one guest' : 'two guests'}
                        </div>
                        <div className="flex items-center py-3 mt-3 text-sm whitespace-nowrap rounded-lg border border-blue-400 transition-colors duration-300">
                          <div className="self-stretch my-auto px-2">US$</div>
                          <div className="shrink-0 self-start w-px h-7 border border-solid bg-neutral-500" />
                          <input
                            type="number"
                            placeholder="Enter price"
                            value={state[key]}
                            onChange={(e) => handleInputChange(e, key)}
                            className="flex-auto self-stretch my-auto px-2 py-1 border-none outline-none text-black bg-transparent placeholder-black"
                          />
                        </div>
                      </div>
                      <div className="flex flex-col flex-1 text-neutral-600">
                        <div className="text-base font-medium">
                          Earning after Laara commission
                        </div>
                        <div className="flex items-center py-3 mt-3 text-sm whitespace-nowrap rounded-lg border border-neutral-600">
                          <div className="self-stretch my-auto px-2">US$</div>
                          <div className="shrink-0 self-start w-px h-7 border border-solid bg-neutral-500" />
                          <div className="flex-auto self-stretch my-auto px-2">
                            {calculateEarnings(state[key]).toFixed(2)}
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                  <div className="self-start mt-16 text-base text-neutral-500 max-md:mt-10">
                    Including taxes, commissions and fees
                  </div>
                  <div className="self-start mt-6 text-base text-neutral-500">
                    10% Laara commission
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );

  return (
    <div className="flex flex-col pb-20 bg-white">
      {/* Header section */}
      <div className="flex gap-5 justify-between  py-8 w-full text-base bg-white max-md:flex-wrap max-md:px-5 max-md:max-w-full">
        {/* ... (header content) ... */}
      </div>

      <div className="flex flex-col self-center mt-5 py-7 w-full  max-sm:p-5 max-md:mt-10 max-md:max-w-full">
        <div className="text-3xl font-medium text-zinc-800 max-md:max-w-full">
          Pricing
        </div>

      
          <div>
            <div className="mt-16 text-3xl font-semibold tracking-normal leading-10 text-zinc-800 max-md:mt-10 max-md:max-w-full">
              Price per night
            </div>

            {renderPricingSection(
              "Half board - standard double room",
              "priceOneGuestHalfBoardDouble",
              "priceTwoGuestsHalfBoardDouble",
              "bg-laara-secondary bg-opacity-40"
            )}

            {renderPricingSection(
              "Full board - standard double room",
              "priceOneGuestFullBoardDouble",
              "priceTwoGuestsFullBoardDouble",
              "bg-slate-50 bg-opacity-50"
            )}

            {renderPricingSection(
              "Half board - standard Single room",
              "priceOneGuestHalfBoardSingle",
              "priceTwoGuestsHalfBoardSingle",
              "bg-slate-50 bg-opacity-600"
            )}

            {renderPricingSection(
              "Full board - standard single room",
              "priceOneGuestFullBoardSingle",
              "priceTwoGuestsFullBoardSingle",
              "bg-slate-50 bg-opacity-80"
            )}

            {error && <div className="text-red-500 mt-4">{error}</div>}

            {/* <div className="flex gap-5 max-sm:w-full mt-16 max-w-full text-center whitespace-nowrap w-[66%] max-md:flex-wrap max-md:mt-10">
              <button className="flex h-12 flex-col justify-center px-16 py-5 my-auto text-base text-sky-600 border border-sky-600 border-solid rounded-[36px] max-md:px-5">
                <div className="flex gap-3 max-md:mx-0.5">
                  <img
                    loading="lazy"
                    src={left}
                    className="shrink-0 w-2 aspect-[0.53] stroke-[1.5px] stroke-sky-600"
                  />
                  <span>Back</span>
                </div>
              </button>
              <button
                onClick={() => {
                  setShowSection(true); // Change the section visibility
                }}
                className="grow h-12 px-3.5 justify-items-center text-lg font-medium text-white bg-sky-600 rounded-[36px] w-fit max-md:px-5 max-md:max-w-full"
              >
                Continue
              </button>

            </div> */}
          </div>
      </div>
    </div>
  );
}