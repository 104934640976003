import React, { useState } from 'react';
import Placeholder1 from "@/assets/AdminDash/placeholder.svg";
import Placeholder2 from "@/assets/AdminDash/placeholder2.svg";
import DeclineModal from '@/Modals/DeclineModal';

const guestData = Array(20).fill({ name: 'Martin Blue', email: 's.cowel@gmail.com', propertyType: 'Hotel Resort', registrationDate: '08/06/2024' });
const hostData = Array(20).fill({ name: 'John Well', email: 'j.well@gmail.com', propertyType: 'Hotel Resort', registrationDate: '08/06/2024' });

const UserManagement = ({ profileType, onBackToList }) => {
  const isGuestProfile = profileType === 'Guests';
  const data = isGuestProfile ? guestData : hostData;

  const [currentPage, setCurrentPage] = useState(1);
  const [showVerificationPage, setShowVerificationPage] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const [showDeclineModal, setShowDeclineModal] = useState(false);
  const [declineReason, setDeclineReason] = useState('');
  const itemsPerPage = 12;

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = data.slice(indexOfFirstItem, indexOfLastItem);

  const pageNumbers = [];
  for (let i = 1; i <= Math.ceil(data.length / itemsPerPage); i++) {
    pageNumbers.push(i);
  }

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const handleVerifyClick = (user) => {
    setSelectedUser(user);
    setShowVerificationPage(true);
  };

  const handleAccept = () => {
    setShowVerificationPage(false);
    onBackToList(profileType);
  };

  const handleDecline = () => {
    setShowDeclineModal(true);
  };

  const handleDeclineSubmit = () => {
    setShowDeclineModal(false);
    setShowVerificationPage(false);
    onBackToList(profileType);
  };

  const ActionButtons = ({ onAccept, onDecline }) => (
    <div className="flex flex-col sm:flex-row justify-center items-center gap-4 mt-6">
      <button
        className="w-full sm:w-[236px] h-[53px] px-[49px] bg-[#0466C8] text-white rounded-full"
        onClick={onAccept}
      >
        Accept verification
      </button>
      <button
        className="w-full sm:w-[236px] h-[53px] px-[49px] bg-[#C03744] text-white rounded-full"
        onClick={onDecline}
      >
        Decline Verification
      </button>
    </div>
  );

  const DocumentSides = ({ title }) => (
    <div className="mb-6 w-full">
      <h2 className="text-lg font-medium mb-2 text-center text-[#292929]">{title}</h2>
      <div className="flex flex-col sm:flex-row justify-between gap-4">
        <div className="w-full sm:w-1/2">
          <p className="text-base mb-1 font-normal text-[#4F4F4F]">Front side</p>
          <div className="w-full h-52 bg-gray-300 rounded"></div>
        </div>
        <div className="w-full sm:w-1/2">
          <p className="text-base mb-1 font-normal text-[#4F4F4F]">Back side</p>
          <div className="w-full h-52 bg-gray-300 rounded"></div>
        </div>
      </div>
    </div>
  );

  const SelfieSection = () => (
    <div className="mb-6 w-full">
      <h2 className="text-lg font-medium mb-2 text-center text-[#292929]">Selfie Photo</h2>
      <div className="w-48 h-48 bg-gray-300 mx-auto rounded"></div>
    </div>
  );

  if (showVerificationPage) {
    return (
      <div className="container mx-auto min-h-screen px-4">
        <div className="bg-white rounded-lg mb-6 p-4 sm:p-6">
          <div className="w-full max-w-[669px] p-4">
            <div className="flex flex-col items-center w-full">
              <h1 className="text-xl font-medium mb-4 w-full max-w-[497px]">Uploaded documents</h1>
              <div className="bg-[#F4FEFF] w-full max-w-[497px]">
                <SelfieSection />
                <DocumentSides title="Passport /National ID" />
                <DocumentSides title="Driver's License" />
                <ActionButtons onAccept={handleAccept} onDecline={handleDecline} />
              </div>
            </div>
          </div>
        </div>
        <DeclineModal
          isOpen={showDeclineModal}
          onClose={() => setShowDeclineModal(false)}
          onSubmit={handleDeclineSubmit}
          declineReason={declineReason}
          setDeclineReason={setDeclineReason}
        />
      </div>
    );
  }

  return (
    <div className="container mx-auto min-h-screen px-4">
      <div className="bg-white rounded-lg mb-6">
        <h2 className="text-2xl font-semibold mb-5">{profileType}</h2>
        <div className="border rounded-lg">
          <div className="flex flex-col sm:flex-row items-center p-4">
            <h2 className="text-xl font-semibold">Pending Verifications</h2>
            <span className="text-[#0466C8] mt-2 sm:mt-0 sm:ml-4 bg-[#B4D9FE] px-3 py-1 rounded font-semibold text-xs bg-opacity-20">{data.length} Verifications</span>
          </div>

          <p className="p-4 text-[#626262] font-normal text-base">
            These are all the {isGuestProfile ? 'guests' : 'hosts'} waiting to be verified
          </p>
          <div className="overflow-x-auto">
            <table className="w-full ">
              <thead className=''>
                <tr className="bg-[#F5F7F9] font-semibold text-sm text-[#4F4F4F] ">
                  <th className="p-3 text-left w-8"></th>
                  <th className="p-3 text-left ">Name</th>
                  <th className="p-3 text-left ">Email</th>
                  <th className="p-3 text-left ">Property Type</th>
                  <th className="p-3 text-left ">Registration Date</th>
                  <th className="p-3 text-left ">Action</th>
                </tr>
              </thead>
              <tbody>
                {currentItems.map((item, index) => (
                  <tr key={indexOfFirstItem + index} className='font-medium text-sm text-[#434343]'>
                    <td className="p-3"></td>
                    <td className="p-3 flex items-center text-[#434343]">
                      <input type="checkbox" className="rounded w-4 h-4 mr-3" />
                      <img
                        src={isGuestProfile ? Placeholder1 : Placeholder2}
                        alt="User"
                        className="w-8 h-8 rounded-full mr-2"
                      />
                      {item.name}
                    </td>
                    <td className="p-3 text-[#434343]">{item.email}</td>
                    <td className="p-3 text-[#434343]">{item.propertyType}</td>
                    <td className="p-3 text-[#434343]">{item.registrationDate}</td>
                    <td className="p-3 text-[#434343]">
                      <button
                        className="text-[#0466C8] px-5 py-1 rounded border border-[#0466C6] font-medium text-sm"
                        onClick={() => handleVerifyClick(item)}
                      >
                        Verify
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div className="flex justify-end p-4 ">
        <div className="border rounded-lg font-semibold px-2 py-1.5">
          <button
            className={`px-3 py-1 rounded mr-2 ${currentPage === 1 ? 'text-gray-500' : 'text-black'}`}
            onClick={() => handlePageChange(currentPage - 1)}
            disabled={currentPage === 1}
          >
            &lt;
          </button>
          {pageNumbers.map((number) => (
            <button
              key={number}
              className={`px-3 py-1 mr-2 ${currentPage === number ? 'bg-[#108975] text-white' : 'text-black'} rounded`}
              onClick={() => handlePageChange(number)}
            >
              {number}
            </button>
          ))}
          <button
            className="px-3 py-1 mr-2 text-black rounded"
            onClick={() => handlePageChange(currentPage + 1)}
            disabled={currentPage === pageNumbers.length}
          >
            &gt;
          </button>
        </div>
      </div>
    </div>
  );
};

export default UserManagement;
