import React from "react";
import { Link } from "react-router-dom";

const Banner3 = () => {
  return (
    <section className="max-w-[1248px] max-h-[210px] h-full w-full ">
      <section className="bg-banner3 bg-no-repeat bg-cover bg-bottom max-w-[77rem] w-full h-[12.125rem] rounded-[1rem] ">
        <article className="w-full text-laara-secondary text-center space-y-3">
          <h4 className="text-lg font-bold capitalize pt-4">
            Lets make the check In Process Simple
          </h4>

          <p className="text-base">
            Using a QR Code you can make it easy for the guest to check in and
            check out
          </p>

          <div className="flex justify-center items-center">
            <Link to="/qrcode" className="ruby-register-btn ">
              Get Your QR Code
            </Link>
          </div>
        </article>
      </section>
    </section>
  );
};

export default Banner3;
