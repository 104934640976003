import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setAmenities } from "@/redux/Listing/listingSlice";

const Parking = () => {
  const dispatch = useDispatch();
  const selectedAmenities = useSelector(
    (state) => state.hostListing.formData?.amenities || []
  );

  const [parkingFormData, setParkingFormData] = useState({
    parking: "",
    parkingCost: "",
    parkingRate: "",
    reservation: "",
    location: "",
    parkingType: "",
  });

  useEffect(() => {
    const {
      parking,
      parkingCost,
      parkingRate,
      reservation,
      location,
      parkingType,
    } = parkingFormData;

    if (parking === "NO PARKING") {
      const updatedAmenities = selectedAmenities.filter(
        (amenity) => amenity.category !== "Parking"
      );
      dispatch(setAmenities(updatedAmenities));
    } else {
      // Ensure form is complete for dispatch
      if (
        parking &&
        ((parking === "PAID" && parkingCost && parkingRate) ||
          parking === "FREE") &&
        reservation &&
        location &&
        parkingType
      ) {
        // Description based on parking type
        const description =
          parking === "FREE" ? "Free Parking" : `${parkingCost} ${parkingRate}`;

        const newAmenity = {
          name: parking,
          description: description,
          category: "Parking",
          // location: location,
          // reservation: reservation,
          // parkingType: parkingType,
        };

        const filteredAmenities = selectedAmenities.filter(
          (amenity) => amenity.category !== "Parking"
        );

        const updatedAmenities = [...filteredAmenities, newAmenity];

        dispatch(setAmenities(updatedAmenities));
      }
    }
  }, [parkingFormData]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setParkingFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  return (
    <form>
      <div>
        <h4 className=" font-medium text-xl ">
          Tell us about the parking <br /> situation at your hotel
        </h4>

        <div className="mt-5 border border-[#A3A3A3] rounded-[6px] max-w-[733px] w-full py-5 px-4 space-y-3 ">
          <p className="text-lg">Is parking available to guests?</p>

          <div className="space-y-2 text-[#6D6D6D] ">
            <div className="flex items-center gap-2">
              <input
                type="radio"
                id="free"
                name="parking"
                className="size-5"
                value="FREE"
                checked={parkingFormData.parking === "FREE"}
                onChange={handleChange}
                // onChange={handleParkingAmenity()}
              />
              <label
                htmlFor="free"
                className={`${
                  parkingFormData.parking === "FREE" && "text-laara-primary"
                }`}
              >
                Yes, free
              </label>
            </div>
            <div className="flex items-center gap-2  ">
              <input
                type="radio"
                id="paid"
                name="parking"
                className="size-5"
                value="PAID"
                checked={parkingFormData.parking === "PAID"}
                onChange={handleChange}
              />
              <label
                htmlFor="paid"
                className={`${
                  parkingFormData.parking === "PAID" && "text-laara-primary"
                }`}
              >
                Yes, paid
              </label>
            </div>
            <div className="flex items-center gap-2">
              <input
                type="radio"
                id="no"
                name="parking"
                className="size-5"
                value="NO PARKING"
                checked={parkingFormData.parking === "NO PARKING"}
                onChange={handleChange}
              />
              <label
                htmlFor="no"
                className={`${
                  parkingFormData.parking === "NO PARKING" &&
                  "text-laara-primary"
                }`}
              >
                No
              </label>
            </div>
          </div>

          {/* PAID PARKING */}
          {(parkingFormData.parking === "PAID" ||
            parkingFormData.parking === "FREE") && (
            <>
              <div className="max-w-[658px] w-full h-[1px] bg-[#6D6D6D] " />
              <div>
                <div className="space-y-4">
                  {/* PARKING COST */}
                  {parkingFormData.parking === "PAID" && (
                    <div className="space-y-2">
                      <p className="text-lg">How much does parking cost?</p>
                      <input
                        type="number"
                        value={parkingFormData.parkingCost}
                        name="parkingCost"
                        onChange={handleChange}
                        className="px-2 py-1 border border-[#6D6D6D] rounded-[8px] max-w-[186px] w-full outline-none"
                      />
                      <select
                        name="parkingRate"
                        id="parking"
                        onChange={handleChange}
                        defaultValue={"per hour"}
                        className="px-2 py-1 border border-[#6D6D6D] rounded-[8px] "
                      >
                        <option value="per hour">Per hour</option>
                        <option value="per day">Per day</option>
                        <option value="per stay">Per stay</option>
                      </select>
                    </div>
                  )}

                  {/* RESERVE PARKING */}
                  <div className="space-y-1">
                    <p className="text-lg">
                      Do you need to reserve a parking spot?
                    </p>
                    <div className="space-y-3">
                      <div className="flex items-center gap-2">
                        <input
                          type="radio"
                          id="needed"
                          name="reservation"
                          className="size-5"
                          value="needed"
                          checked={parkingFormData.reservation === "needed"}
                          onChange={handleChange}
                        />
                        <label htmlFor="needed" className="text-[#626262]">
                          Reservation needed
                        </label>
                      </div>

                      <div className="flex items-center gap-2">
                        <input
                          type="radio"
                          id="not-needed"
                          name="reservation"
                          className="size-5"
                          value="not-needed"
                          checked={parkingFormData.reservation === "not-needed"}
                          onChange={handleChange}
                        />
                        <label htmlFor="not-needed" className="text-[#626262]">
                          No reservation needed
                        </label>
                      </div>
                    </div>
                  </div>

                  {/* PARKING LOCATION */}
                  <div className="space-y-1">
                    <p className="text-lg">
                      Where is the parking spot located?
                    </p>
                    <div className="space-y-3">
                      <div className="flex items-center gap-2">
                        <input
                          type="radio"
                          id="on-site"
                          name="location"
                          className="size-5"
                          value="on site"
                          checked={parkingFormData.location === "on site"}
                          onChange={handleChange}
                        />
                        <label htmlFor="on-site" className="text-[#626262]">
                          On site
                        </label>
                      </div>

                      <div className="flex items-center gap-2">
                        <input
                          type="radio"
                          id="off-site"
                          name="location"
                          className="size-5"
                          value="off site"
                          checked={parkingFormData.location === "off site"}
                          onChange={handleChange}
                        />
                        <label htmlFor="off-site" className="text-[#626262]">
                          Off site
                        </label>
                      </div>
                    </div>
                  </div>

                  {/* PARKING TYPE */}
                  <div className="space-y-1">
                    <p className="text-lg">What type of parking is it?</p>
                    <div className="space-y-3">
                      <div className="flex items-center gap-2">
                        <input
                          type="radio"
                          id="private"
                          name="parkingType"
                          className="size-5"
                          value="private"
                          checked={parkingFormData.parkingType === "private"}
                          onChange={handleChange}
                        />
                        <label htmlFor="private" className="text-[#626262]">
                          Private
                        </label>
                      </div>

                      <div className="flex items-center gap-2">
                        <input
                          type="radio"
                          id="public"
                          name="parkingType"
                          className="size-5"
                          value="public"
                          checked={parkingFormData.parkingType === "public"}
                          onChange={handleChange}
                        />
                        <label htmlFor="public" className="text-[#626262]">
                          Public
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </form>
  );
};

export default Parking;
