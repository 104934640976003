import React from 'react';
import { FaLessThan } from 'react-icons/fa6';

const images = [
  'https://via.placeholder.com/300x200?text=Image+1',
  'https://via.placeholder.com/300x200?text=Image+2',
  'https://via.placeholder.com/300x200?text=Image+3',
  'https://via.placeholder.com/300x200?text=Image+4',
  'https://via.placeholder.com/300x200?text=Image+5',
  'https://via.placeholder.com/300x200?text=Image+6',
  'https://via.placeholder.com/300x200?text=Image+7'
];
  // Dummy data for amenities with sub-lists
  const GeneralAmenities = [
    {
      title: "Entertainment",
      items: [
        "Flat Screen TV",
        "Cable Channels",
        "Streaming Services",
        "Free Wifi",
        "Mini Bar",
        "Coffee Maker"
      ]
    },
  ];

  const CommonAmenities = [
    {
      title: "Leisure",
      items: [
        "Swimming Pool",
        "Spa",
        "Sauna",
        "Hot Tub",
        "Outdoor Lounge",
        "Jacuzzi",
        "Beach Access",
        "Fire Pit",
        "Game Room",
        "Movie Room"
      ]
    },
    {
      title: "Fitness",
      items: [
        "Gym",
        "Yoga Room",
        "Personal Trainer",
        "Pilates Studio",
        "Swimming Lessons",
        "Fitness Classes",
        "Cycling Studio",
        "Free Weights",
        "Cardio Equipment",
        "Tennis Courts"
      ]
    },
    {
      title: "Parking",
      items: [
        "On-site Parking",
        "Valet Parking",
        "Electric Vehicle Charging",
        "Covered Parking",
        "Secure Parking",
        "Parking Garage",
        "Street Parking",
        "Reserved Spaces",
        "Handicap Accessible Parking",
        "Bicycle Racks"
      ]
    },
    {
      title: "Services",
      items: [
        "24/7 Reception",
        "Concierge Service",
        "Room Service",
        "Daily Housekeeping",
        "Laundry Service",
        "Dry Cleaning",
        "Grocery Delivery",
        "Airport Shuttle",
        "Tour Desk",
        "Pet Services"
      ]
    }
  ];

const handleViewImages = () => {
  // Functionality to view images
};

function PropertiesAmenities() {


  return (
    <div className='p-7'>
      {/* Header with icon and title */}
      <p className='flex items-center gap-2'>
        <FaLessThan size={17} />
        Properties Amenities
      </p>

      {/* Main content container */}
      <div className='flex flex-col lg:flex-row w-full'>
        {/* Image and Description Column */}
        <div className='flex flex-col gap-6 w-[70%]'>
          {/* Image Carousel */}
          <div className="flex flex-col gap-2 w-[827px]">
            <div className="grid grid-cols-2 gap-2">
              {/* Top Row */}
              <div className="grid grid-rows-1 gap-2">
                {images.slice(0, 2).map((image, index) => (
                  <img
                    key={index}
                    src={image}
                    alt={`Image ${index + 1}`}
                    onClick={handleViewImages}
                    className="w-full object-cover shadow-lg rounded-lg transition-opacity duration-300 hover:opacity-90"
                  />
                ))}
              </div>

              {/* Middle Row */}
              <div className="flex flex-col gap-2">
                <div className="flex flex-grow">
                  {images.slice(2, 3).map((image, index) => (
                    <img
                      key={index}
                      src={image}
                      alt={`Image ${index + 1}`}
                      onClick={handleViewImages}
                      className="flex-grow w-full rounded-lg object-cover shadow-lg transition-opacity duration-300 hover:opacity-90"
                    />
                  ))}
                </div>
              </div>
            </div>

            {/* Bottom Row */}
            <div className="grid grid-cols-4 gap-2">
              {images.slice(3, 7).map((image, index) => (
                <img
                  key={index}
                  src={image}
                  alt={`Image ${index + 1}`}
                  onClick={handleViewImages}
                  className="w-full object-cover shadow-lg rounded-lg transition-opacity duration-300 hover:opacity-90"
                />
              ))}
            </div>
          </div>

          {/* Description Section */}
          <div>
            <p className='text-lg text-gray-700'>
              This property offers a range of amenities designed to enhance your stay, from luxurious leisure facilities to comprehensive fitness options. Enjoy a variety of services and convenient features to make your experience memorable and comfortable.
            </p>
          </div>
          <div className='mb-6'>
              <h2 className='text-xl font-semibold mb-4'>General Amenities</h2>
              <div className='flex flex-col gap-6'>
                {GeneralAmenities.map((category, index) => (
                  <div key={index}>
                    <h3 className='text-lg font-semibold mb-2'>{category.title}</h3>
                    <div className='flex flex-wrap gap-4'>
                      {category.items.map((item, subIndex) => (
                        <span key={subIndex} className='bg-gray-200 px-3 py-1 rounded-full text-gray-700'>
                          {item}
                        </span>
                      ))}
                    </div>
                  </div>
                ))}
              </div>
            </div>          
        </div>

        {/* Amenities Column */}
        <div className='flex flex-col gap-6 w-[30%]'> 
          {/* Right section: Continuation of Common Amenities */}
          <div className='bg-slate-200 p-4 rounded-lg shadow-md '>
            {CommonAmenities.slice(2).map((category, index) => (
              <div key={index} className='mb-6'>
                <h3 className='text-lg font-semibold mb-2'>{category.title}</h3>
                <ul className='list-disc list-inside space-y-2'>
                  {category.items.map((item, subIndex) => (
                    <li key={subIndex} className='text-gray-700'>{item}</li>
                  ))}
                </ul>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

export default PropertiesAmenities;
