import React, { useState } from 'react'
import { Link } from 'react-router-dom';
import arrowright from "@/assets/shared/arrow-right.svg"
import addfile from "@/assets/support/Upload.svg"
import { messagecategories } from '@/constants/data';

export const Message = () => {

    const [selectedCategory, setSelectedCategory] = useState('');
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);


    const handleCategorySelect = (category) => {
        setSelectedCategory(category);
        setIsDropdownOpen(false);
    };


    return (
        <div className="w-full max-w-[1440px] h-auto min-h-screen lg:h-[988px] p-4 sm:p-6 md:p-8 lg:px-22 lg:py-14 gap-4 sm:gap-6 lg:gap-8 mx-auto ">
            <div className=" max-w-[1264px] mx-auto flex flex-col gap-[20px]">

                <div className="w-full max-w-[1212px]  mx-auto px-4 sm:px-6 lg:px-8">
                    <div className="w-full max-w-[275px] px-4 sm:px-0 mb-[20px] flex flex-col-2 sm:flex-row justify-between items-center py-2 sm:py-0">
                        <Link to="/support" className="relative sm:mb-0 mb-2 inline-block">
                            <img src={arrowright} alt="Go to destination page" className="w-6 h-6 cursor-pointer hover:opacity-80 transition-opacity"
                            />
                        </Link>
                        <span className="text-sm sm:text-base ml-2 font-bold">Send a Message</span>
                    </div>
                </div>

                <div className="w-full max-w-[1212px] min-h-[220px] mx-auto px-4 sm:px-6 lg:px-8 flex flex-col">
                    <p className="text-base sm:text-lg lg:text-xl font-normal leading-6 sm:leading-7 lg:leading-8 text-left mb-5">
                        Get in touch with us and we'll respond promptly to your inquiries
                    </p>

                    <div className="w-full max-w-[1116px] gap-6">
                        <form className="w-full flex flex-col gap-6">

                            <div className="flex flex-col sm:flex-row gap-4">
                                <div className="flex flex-col w-full">
                                    <label
                                        htmlFor="email"
                                        className="mb-2 font-poppins text-base font-normal leading-6 text-left"
                                    >
                                        Email
                                    </label>

                                    <input
                                        id="email"
                                        type="email"
                                        className="w-full px-4 py-3 border border-gray-300 rounded-lg focus:outline-none focus:border-blue-500"
                                        placeholder="Enter your email"
                                    />
                                </div>
                            </div>
                            <div className="flex flex-col sm:flex-row gap-4">
                                <div className="flex flex-col w-full relative">
                                    <label
                                        htmlFor="category"
                                        className="mb-2 font-poppins text-base font-normal leading-6 text-left"
                                    >
                                        Category
                                    </label>
                                    <div
                                        onClick={() => setIsDropdownOpen(!isDropdownOpen)}
                                        className="w-full px-4 py-3 border border-gray-300 rounded-lg focus:outline-none focus:border-blue-500 cursor-pointer flex justify-between items-center"
                                    >
                                        <span>{selectedCategory || 'Select a category'}</span>
                                        <svg className="w-5 h-5 text-gray-400" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 9l-7 7-7-7"></path>
                                        </svg>
                                    </div>
                                    {isDropdownOpen && (
                                        <ul className="absolute z-10 w-full mt-1 bg-white border border-gray-300 rounded-lg shadow-lg">
                                            {messagecategories.map((category, index) => (
                                                <li
                                                    key={index}
                                                    className="px-4 py-2 hover:bg-gray-100 cursor-pointer"
                                                    onClick={() => handleCategorySelect(category)}
                                                >
                                                    {category}
                                                </li>
                                            ))}
                                        </ul>
                                    )}
                                </div>
                            </div>

                            <div className="flex flex-col sm:flex-row gap-4">
                                <div className="flex flex-col w-full">
                                    <label
                                        htmlFor="name"
                                        className="mb-2 font-poppins text-base font-normal leading-6 text-left"
                                    >
                                        Message
                                    </label>

                                    <textarea
                                        id="message"
                                        className="w-full px-4 py-3 border border-gray-300 rounded-lg focus:outline-none focus:border-blue-500 resize-none"
                                        rows="4"
                                        placeholder="Enter your message"
                                    ></textarea>
                                </div>
                            </div>
                            <div className="w-full max-w-[1116px] h-[56px] flex items-center justify-center gap-2 rounded-lg border-[2px] border-dashed border-gray-300 cursor-pointer">
                                <input
                                    type="file"
                                    id="file-upload"
                                    className="hidden"
                                    multiple
                                />
                                <label
                                    htmlFor="file-upload"
                                    className="flex items-center justify-center w-full h-full text-[#292929] text-sm font-normal"
                                >
                                    <img src={addfile} alt='icon' className='w=6 h=6' />
                                    Add up to 5 files
                                </label>
                            </div>

                            <Link to="/messagesent" className="inline-block w-full sm:w-[382px] text-center">
                                <button type="submit" className="w-full sm:w-[382px] h-[56px] px-0 py-4 bg-blue-600 text-white rounded-full">
                                    Send
                                </button>
                            </Link>
                        </form>
                    </div>

                </div>
            </div>
        </div>

    )
}
