import React, { useState } from 'react';
import Sidebar from '@/components/AdDash/Sidebar';
import Dashboard from '@/components/AdDash/Dashboard';
import UserManagement from '@/components/AdDash/UserManagement';
import PropertyManagement from '@/components/AdDash/PropertyManagement';
import BookingManagement from '@/components/AdDash/BookingManagement';
import FinancialManagement from '@/components/AdDash/FinancialManagement';
import Ruby from '@/components/AdDash/Ruby';
import SystemManagement from '@/components/AdDash/SystemManagement';
import Settings from '@/components/AdDash/Settings';
import Header from '@/components/AdDash/Header';

function AdminDashboard() {
  const [activeModule, setActiveModule] = useState('Dashboard');
  const [sidebarOpen, setSidebarOpen] = useState(false);

  const renderModule = () => {
    switch (activeModule) {
      case 'Dashboard':
        return <Dashboard />;
      case 'Guests':
      case 'Hosts':
        return <UserManagement profileType={activeModule} />;
      case 'Property Management':
        return <PropertyManagement />;
      case 'Booking Management':
        return <BookingManagement />;
      case 'Financial Management':
        return <FinancialManagement />;
      case 'Ruby':
        return <Ruby />;
      case 'Overall':
      case 'Frontdesk':
      case 'Housekeeping':
      case 'Security':
        return <SystemManagement subModule={activeModule} />;
      case 'Settings':
        return <Settings />;
      default:
        return <Dashboard />;
    }
  };


  return (
    <div className="flex h-[1209px] bg-gray-100 ">
      <Sidebar setActiveModule={setActiveModule} isOpen={sidebarOpen} setIsOpen={setSidebarOpen} />
      <div className="flex-1 flex flex-col overflow-hidden">
        <Header toggleSidebar={() => setSidebarOpen(!sidebarOpen)} />
        <main className="flex-1 overflow-x-hidden overflow-y-auto bg-white p-4">
          {renderModule()}
        </main>
      </div>
    </div>
  );
}

export default AdminDashboard;