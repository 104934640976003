import React from 'react'
import { Link } from 'react-router-dom'
import arrowright from "@/assets/shared/arrow-right.svg"
import thumbsup from "@/assets/support/thumbup.svg"
import thumbsdown from "@/assets/support/thumb down.svg"

export const Creatingaccount = () => {
    return (
        <div className="w-full max-w-[1440px] h-auto min-h-screen lg:h-[988px] p-4 sm:p-6 md:p-8 lg:px-22 lg:py-14 gap-4 sm:gap-6 lg:gap-8 mx-auto ">
            <div className=" max-w-[1264px] mx-auto flex flex-col gap-[20px]">

                <div className="w-full mx-auto px-4 sm:px-6 lg:px-8">
                    <div className="w-full max-w-[616px]  px-4 sm:px-0 mb-[20px] flex flex-col-2 sm:flex-row justify-between items-center py-2 sm:py-0">
                        <Link to="/gettingstarted" className="relative sm:mb-0 mb-2 inline-block">
                            <img src={arrowright} alt="Go to destination page" className="w-6 h-6 cursor-pointer hover:opacity-80 transition-opacity"
                            />
                        </Link>
                        <span className="text-sm sm:text-base ml-2 font-bold">FAQs</span>
                    </div>


                </div>

                <div className="w-full max-w-[1212px] min-h-[220px] mx-auto px-4 sm:px-6 lg:px-8 flex flex-col">
                    <p className="text-lg sm:text-xl lg:text-2xl font-semibold leading-9 text-left mb-[20px]">
                        Step by step guide on creating an account
                    </p>
                    <p>Lorem ipsum dolor sit amet consectetur. Lectus lorem egestas donec lectus massa commodo arcu. Nibh quam elementum vestibulum urna. Semper fermentum vitae quis ornare eget ut. Amet pretium arcu rhoncus curabitur volutpat erat. Maecenas amet amet sit ipsum tincidunt id curabitur facilisi. Consequat odio ipsum nibh leo. Dolor in tellus cursus sem erat ac <br />
                        <br />
                        hac.Lorem ipsum dolor sit amet consectetur. Lectus lorem egestas donec lectus massa commodo arcu. Nibh quam elementum vestibulum urna. Semper fermentum vitae quis ornare eget ut. Amet pretium arcu rhoncus curabitur volutpat erat. Maecenas amet amet sit ipsum tincidunt id curabitur facilisi. Consequat odio ipsum nibh leo. Dolor in tellus cursus sem erat ac hac.</p><br />

                    <div className="w-[220px] h-[101px] sm:w-[280px] sm:h-[120px] gap-4 sm:gap-6 lg:gap-8 ">
                        <h2 className="text-l font-semibold mb-2">Was this article useful?</h2>
                        <div className="flex justify-center mr-20 gap-4">
                            <img
                                src={thumbsup}
                                alt="Thumbs up"
                                className="w-10 h-10 cursor-pointer"
                            />
                            <img
                                src={thumbsdown}
                                alt="Thumbs down"
                                className="w-10 h-10 cursor-pointer"
                            />
                        </div>
                    </div>

                </div>
            </div>
        </div>
    )
}
