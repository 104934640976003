import { useDispatch, useSelector } from "react-redux";
import { setAmenities } from "@/redux/Listing/listingSlice"; // Update the path to your Redux slice

const BRAmenities = () => {
  const dispatch = useDispatch();
  const selectedAmenities = useSelector(
    (state) => state.hostListing.formData?.amenities || []
  );

  const amenitiesCategories = {
    General: [
      "free wifi",
      "air conditioning",
      "heating",
      "non-smoking room",
      "smoking rooms",
      "pet-friendly accommodations",
      "24 hour front desk",
      "concierge service",
    ],
    Kitchen: [
      "kitchenette / full kitchen",
      "refrigerator",
      "microwave",
      "coffee / tea maker",
      "dishwasher",
      "stove",
      "oven",
    ],
    "Media and Technology": [
      "flat-screen TV",
      "cable / satellite channels",
      "streaming services (e.g Netflix)",
      "DVD player",
    ],
    Business: ["laptop-friendly workspace", "ethernet connection"],
  };

  const handleToggleAmenity = (category, amenity) => {
    // Toggle the selection of the amenity
    const updatedAmenities = selectedAmenities?.some(
      (a) => a.name === amenity && a.category === category
    )
      ? selectedAmenities.filter(
          (a) => !(a.name === amenity && a.category === category)
        )
      : [
          ...selectedAmenities,
          {
            name: amenity,
            description: `${amenity} available`, // Customize description
            category,
          },
        ];

    dispatch(setAmenities(updatedAmenities));
  };

  const renderCheckboxes = (category, items) => (
    <div key={category} className="space-y-4">
      <p className="font-medium text-lg capitalize">{category}</p>
      <div className="flex flex-col gap-2">
        {items.map((item) => (
          <div key={item} className="flex items-center gap-3">
            <input
              id={`${category}-${item}`}
              type="checkbox"
              className="size-5"
              checked={selectedAmenities?.some(
                (a) => a.name === item && a.category === category
              )}
              onChange={() => handleToggleAmenity(category, item)}
            />
            <label
              htmlFor={`${category}-${item}`}
              className={`capitalize text-lg ${
                selectedAmenities?.some(
                  (a) => a.name === item && a.category === category
                ) && "text-laara-primary"
              }`}
            >
              {item}
            </label>
          </div>
        ))}
      </div>
    </div>
  );

  return (
    <form className="flex flex-col">
      <h4 className="ffont-medium text-xl]">
        What can your guests use at your place?
      </h4>
      <div className="border border-[#A3A3A3] rounded-[6px] mt-8 p-8 max-w-[723px] w-full ">
        <div className="flex items-start gap-4 mt-5">
          <div className="space-y-4">
            {Object.entries(amenitiesCategories).map(([category, items]) =>
              renderCheckboxes(category, items)
            )}
          </div>
        </div>
      </div>
    </form>
  );
};

export default BRAmenities;
