// eslint-disable-next-line no-unused-vars
import React from 'react';
import Header from '../EditRoom/globalrooms/Header';
import Consider from '@/components/Listings/Consider';

export const Availability = () => {
  return (
    <>
          <form className="bg-base-white p-8">
            <Header title="Availability" />
            <h3 className='text-[16px] p-4 pl-0 pt-0'>Availability</h3>
            <div className='flex gap-8'>
                <div className="w-full max-w-[728px] p-8 bg-laara-secondary border rounded-lg">
                    <div className="w-full text-[14px] font-normal leading-[30px] text-left">
                        <span>Your property will be</span>
                        <span className="font-medium"> automatically available for the next 18 months excluding -</span>
                        <span> days that will manually be marked as unavailable.</span>
                    </div>
                </div>
                <Consider
                title={"You are unsure about your price?"}
                data={["Don't worry, you can set it when ready"]}/>
            </div>
            {/* Save Changes Button */}
                    <div className="mt-8 mr-52 flex justify-end">
                        <button
                            type="button"
                            className="py-2 px-4 bg-laara-primary text-white font-normal rounded-[36px] hover:bg-blue-600 w-[216px] h-12"
                        >
                            Save Changes
                        </button>
                    </div>
          </form>
       
    </>
  );
}
