import React, { useState } from 'react';
import Placeholder1 from "@/assets/AdminDash/placeholder.svg";
import { Search, User } from 'lucide-react';
import AddAdminModal from '@/Modals/AddAdmin';

function SettingsHeader({ activeTab, setActiveTab }) {
  return (
    <div className="flex flex-col sm:flex-row mb-6 overflow-x-auto">
      <button className={`text-[#4F4F4F] font-semibold hover:text-[#0466C8] text-sm pb-2 px-4 border-b-2 whitespace-nowrap ${activeTab === 'general' ? 'border-[#0466C8]' : 'hover:text-[#0466C8]'}`}
        onClick={() => setActiveTab('general')}
      >
        General Settings
      </button>
      <button className={`text-[#4F4F4F] font-semibold text-sm pb-2 px-4 border-b-2 whitespace-nowrap ${activeTab === 'permissions' ? 'border-[#0466C8]' : 'hover:text-[#0466C8]'}`}
        onClick={() => setActiveTab('permissions')}
      >
        User permission
      </button>
      <button className={`text-[#4F4F4F] font-semibold text-sm pb-2 px-4 border-b-2 whitespace-nowrap ${activeTab === 'preferences' ? 'border-[#0466C8]' : 'hover:text-[#0466C8]'}`}
        onClick={() => setActiveTab('preferences')}
      >
        Preferences
      </button>
    </div>
  );
}

function ProfileSection() {
  return (
    <div className="flex flex-col sm:flex-row items-center mb-6">
      <img src={Placeholder1} alt="Profile" className="w-20 h-20 rounded-full mb-3 sm:mb-0 sm:mr-3" />
      <div className="text-center sm:text-left">
        <h2 className="text-sm font-medium">Noah Matheka</h2>
        <p className="text-[#6D6D6D] text-sm">Laara Admin</p>
      </div>
      <button className="mt-3 sm:mt-0 sm:ml-auto text-[#0466C8]">Edit</button>
    </div>
  );
}

function AdminInformation() {
  return (
    <div className="mb-6 border-t">
      <div className="flex flex-col sm:flex-row items-center mt-4">
        <h3 className="text-base font-semibold mt-4 mb-4">Admin Information</h3>
        <button className="mt-2 sm:mt-0 sm:ml-auto text-[#0466C8]">Edit</button>
      </div>
      <div className="grid grid-cols-1 sm:grid-cols-2 gap-5">
        <InfoItem label="First name" value="Noah" />
        <InfoItem label="Last name" value="Mathias" />
        <InfoItem label="Email address" value="Mnoah@itaxigroup.com" />
        <InfoItem label="Phone number" value="+254 721 872302" />
        <InfoItem label="Admin Status" value="Super Admin" colSpan={true} />
      </div>
    </div>
  );
}

function InfoItem({ label, value, colSpan }) {
  return (
    <div className={colSpan ? 'col-span-1 sm:col-span-2' : ''}>
      <p className="text-sm">{label}</p>
      <p className="text-lg font-medium">{value}</p>
    </div>
  );
}

function Address() {
  return (
    <div className="mb-6 border-t">
      <div className="flex flex-col sm:flex-row items-center mt-4">
        <h3 className="text-base font-semibold mb-4">Address</h3>
        <button className="mt-2 sm:mt-0 sm:ml-auto text-[#0466C8]">Edit</button>
      </div>
      <div className="grid grid-cols-1 sm:grid-cols-2 gap-5">
        <InfoItem2 label="Country" value="Kenya" />
        <InfoItem2 label="City/State" value="Nyanza" />
      </div>
    </div>
  );
}

function InfoItem2({ label, value }) {
  return (
    <div>
      <p className="text-sm">{label}</p>
      <p className="text-lg font-medium">{value}</p>
    </div>
  );
}

function Notifications() {
  return (
    <div className="mb-6 border-t">
      <h3 className="text-base font-semibold mt-4 mb-4">Notifications</h3>
      <div className="space-y-4 bg-white shadow border rounded-lg p-4 sm:p-6 w-full">
        <ToggleSwitch label="Reservation confirmation" value="You will be notified when customer makes a reservation" />
        <ToggleSwitch label="Reservation status change" value="You will be notified when make changes to the reservation" />
        <ToggleSwitch label="Reservation approved" value="You will be notified when reservation has been confirmed" />
        <ToggleSwitch label="Email notification" value="Get updates through email" />
      </div>
    </div>
  );
}

function ToggleSwitch({ label, value }) {
  const [isOn, setIsOn] = useState(false);

  const handleToggle = () => {
    setIsOn(!isOn);
  };

  return (
    <div className="flex flex-col sm:flex-row sm:items-center sm:justify-between">
      <div className="flex flex-col mb-2 sm:mb-0">
        <span className="font-medium text-base">{label}</span>
        <p className="text-xs">{value}</p>
      </div>
      <button
        onClick={handleToggle}
        className={`w-12 h-6 rounded-full p-1 flex items-center ${isOn ? 'bg-[#0466C8]' : 'bg-gray-200'}`}
      >
        <div
          className={`w-4 h-4 bg-white rounded-full shadow-md transform transition-transform ${
            isOn ? 'translate-x-6' : 'translate-x-0'
          }`}
        ></div>
      </button>
    </div>
  );
}

function PasswordSection() {
  return (
    <div className="mb-6 border-t">
      <h3 className="text-lg font-semibold mt-4 mb-4">Password</h3>
      <form className="space-y-4 bg-white shadow border rounded-lg p-4 sm:p-6 w-full">
        <div>
          <label htmlFor="currentPassword" className="block text-xs font-normal text-[#727272]">Current password</label>
          <input id="currentPassword" type="password" className="w-full p-2 border rounded-lg outline-none mt-1" />
        </div>
        <div>
          <label htmlFor="newPassword" className="block text-xs font-normal text-[#727272]">New password</label>
          <input id="newPassword" type="password" className="w-full p-2 border rounded-lg outline-none mt-1" />
        </div>
        <div>
          <label htmlFor="confirmPassword" className="block text-xs font-normal text-[#727272]">Confirm password</label>
          <input id="confirmPassword" type="password" className="w-full p-2 border rounded-lg outline-none mt-1" />
        </div>
        <button className="w-full sm:max-w-[175px] bg-[#0466C8] text-white px-4 py-2 rounded-full">Update</button>
      </form>
    </div>
  );
}

function DeleteAccount() {
  return (
    <div className="mt-8 border-t">
      <h3 className="text-lg font-semibold mt-4 mb-10">Delete your account</h3>
      <div className="flex flex-col">
        <label className="flex items-center mb-5">
          <input type="checkbox" className="mr-2" />
          <span className="text-sm">I understand that deleting my account is irreversible</span>
        </label>
        <button className="w-full sm:max-w-[175px] bg-[#C03744] text-white px-4 py-2 rounded-full">Delete</button>
      </div>
    </div>
  );
}

function AdministrativeRoles() {
  return (
    <div className="flex justify-center items-center bg-white p-4 sm:p-6 md:p-10">
      <div className='w-full max-w-3xl'>
        <h2 className="text-base text-[#4F4F4F] font-semibold mb-2">Administrative roles</h2>
        <p className="text-[#626262] text-sm mb-4">A role provides access to predefined menus and features so that depending on the assigned role (Super Admin, Manager, Accountant) an administrator can have access to what he needs.</p>
        <div className="flex flex-col sm:flex-row space-y-4 sm:space-y-0 sm:space-x-4">
          <RoleCard title="Manager" accounts={3} color="#0466C8" />
          <RoleCard title="Reception" accounts={6} color="#81C3F3" />
        </div>
      </div>
    </div>
  );
}

function RoleCard({ title, accounts, color }) {
  return (
    <div className="rounded-lg flex-1 border border-gray-200">
      <div className="flex items-center justify-between p-4 rounded" style={{ backgroundColor: color }}>
        <span className="font-semibold text-white">{title}</span>
        <User className="w-6 h-6 text-white" />
      </div>
      <div className="p-4">
        <div className="flex flex-col">
          <div className="flex items-center justify-between">
            <span className="text-sm">{accounts} Accounts</span>
            <div className="flex -space-x-2">
              {[...Array(3)].map((_, i) => (
                <img
                  key={i}
                  className="w-8 h-8 sm:w-10 sm:h-10 rounded-full border-2 border-white"
                  src={Placeholder1}
                  alt="User"
                />
              ))}
            </div>
          </div>
          <button className="text-[#0466C8] text-sm font-normal mt-2 self-start ">
            Learn more
          </button>
        </div>
      </div>
    </div>
  );
}

function Administrators() {
  const [searchQuery, setSearchQuery] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);

  const admins = [
    { email: 'PFetus@iaora.com', status: 'Pending', account: 'Super Admin', property: 'Ibis', dateAdded: 'July 25 2024' },
    { email: 'GHenry@iaora.com', status: 'Active', account: 'Accountant', property: 'Raddison', dateAdded: 'July 20 2024' },
    { email: 'GHenry@iaora.com', status: 'Inactive', account: 'Accountant', property: 'Hilton', dateAdded: 'June 23 2024' },
    { email: 'GHenry@iaora.com', status: 'Active', account: 'Super Admin', property: 'Intercontinental', dateAdded: 'June 9 2024' },
    { email: 'GHenry@iaora.com', status: 'Inactive', account: 'Accountant', property: 'Signature', dateAdded: 'May 14 2024' },
    { email: 'GHenry@iaora.com', status: 'Inactive', account: 'Manager', property: 'Airbnb', dateAdded: 'May 2 2024' },
  ];

  const filteredAdmins = admins.filter(admin =>
    admin.email.toLowerCase().includes(searchQuery.toLowerCase()) ||
    admin.property.toLowerCase().includes(searchQuery.toLowerCase())
  );

  return (
    <div className="bg-white p-4 sm:p-6 rounded-lg shadow">
    <div className="flex flex-col sm:flex-row sm:items-center mb-4">
      <h2 className="text-base font-semibold">Administrators</h2>
      <span className="text-[#0466C8] mt-2 sm:mt-0 sm:ml-4 bg-[#B4D9FE] px-3 py-1 rounded font-semibold text-xs bg-opacity-20">
        {filteredAdmins.length} Administrators
      </span>
    </div>

    <div className="flex flex-col sm:flex-row justify-between items-start sm:items-center space-y-4 sm:space-y-0 sm:space-x-4 mb-4">
      <div className="relative w-full sm:w-auto">
        <input
          type="text"
          placeholder="Search Admin or Property"
          className="w-full sm:w-[317px] pl-10 pr-4 py-2 text-sm font-normal text-[#626262] border border-[#818181] rounded-full focus:outline-none"
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
        />
        <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 h-4 w-4 sm:h-5 sm:w-5 text-gray-400" />
      </div>
      <button
        className="w-full sm:w-auto bg-[#0466C8] text-white px-4 py-2 rounded-full"
        onClick={() => setIsModalOpen(true)}
      >
        Add admin
      </button>
    </div>

      <div className="overflow-x-auto">
        <table className="w-full min-w-[640px]">
          <thead>
            <tr className="text-left text-sm font-medium bg-[#F5F7F9] text-[#4F4F4F] border-t">
              <th className="p-3">Email</th>
              <th className="p-3">Status</th>
              <th className="p-3">Admin account</th>
              <th className="p-3">Property</th>
              <th className="p-3">Date Added</th>
            </tr>
          </thead>
          <tbody>
            {filteredAdmins.map((admin, index) => (
              <tr key={index} className="border-t text-[#434343]">
                <td className="p-3 text-sm">{admin.email}</td>
                <td className="p-3 text-sm">
                  <span className={`flex items-center`}>
                    {admin.status}
                    {admin.status === 'Pending' && (
                      <span className="ml-2 text-xs text-[#7B7B7B] cursor-pointer">(Resend invite)</span>
                    )}
                  </span>
                </td>
                <td className="p-3 text-sm">{admin.account}</td>
                <td className="p-3 text-sm">{admin.property}</td>
                <td className="p-3 text-sm">{admin.dateAdded}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      <AddAdminModal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)}/>
    </div>
  );
}

const AppearanceSettings = () => {
  const [theme, setTheme] = useState('light');
  const [fontSize, setFontSize] = useState('16px');
  const [zoomDisplay, setZoomDisplay] = useState('100 (normal)');
  const [language, setLanguage] = useState('English (UK)');

  return (
    <div className=" p-6"> 
      <h1 className="text-2xl font-semibold text-base mb-3">Appearance</h1>
      <p className="text-[#727272] text-sm font-normal mb-3">Customize according to your preferences</p>

      <ThemeSelector theme={theme} setTheme={setTheme} />

      <div className="grid grid-cols-2 gap-4 mt-6 w-full max-w-[678px] ">
        <DropdownSelect
          label="Font size"
          value={fontSize}
          onChange={setFontSize}
          options={['12px', '14px', '16px', '18px', '20px']}
        />
        <DropdownSelect
          label="Zoom display"
          value={zoomDisplay}
          onChange={setZoomDisplay}
          options={['90', '100 (normal)', '110', '120']}
        />
      </div>

      <div className="mt-6 w-full max-w-[328px]">
        <DropdownSelect
          label="Language"
          subLabel="Default language for your public dashboard"
          value={language}
          onChange={setLanguage}
          options={['English (UK)', 'English (US)', 'Spanish', 'French']}
        />
      </div>

      <button className="w-full max-w-[142px] mt-8 bg-[#0466C8] text-white px-4 py-2 rounded-full float-right mt-[282px]">
        Send
      </button>
    </div>
    
  );
};

const ThemeSelector = ({ theme, setTheme }) => {
  return (
    <div className="border-t mb-3">
      <p className="font-semibold mb-2 mt-4">Select theme</p>
      <div className="flex flex-col sm:flex-row space-y-4 sm:space-y-0 sm:space-x-4">
        <div
          className={`border p-2 cursor-pointer w-full max-w-[200px] sm:flex-1 ${theme === 'light' ? 'border-[#0466C8]' : 'border-gray-300'}`}
          onClick={() => setTheme('light')}
        >
          <div className="w-full max-w-[200px] h-28 sm:h-20 bg-white border border-gray-300">
            <div className="h-1/5 bg-gray-200 m-2"></div>
            <div className="h-2/5 bg-teal-500 mx-2"></div>
          </div>
          <div className="flex items-center justify-between mt-2">
            <span>Light mode</span>
            <div
              className={`w-4 h-4 rounded-full ${theme === 'light' ? 'bg-[#0466C8]' : 'bg-gray-300'} flex items-center justify-center`}
            >
              {theme === 'light' && (
                <div className="w-2 h-2 rounded-full bg-white"></div>
              )}
            </div>
          </div>
        </div>
        <div
          className={`border p-2 cursor-pointer w-full max-w-[200px] sm:flex-1 ${theme === 'dark' ? 'border-[#0466C8]' : 'border-gray-300'}`}
          onClick={() => setTheme('dark')}
        >
          <div className="w-full max-w-[200px] h-28 sm:h-20 bg-gray-800 border border-gray-600">
            <div className="h-1/5 bg-gray-700 m-2"></div>
            <div className="h-2/5 bg-teal-500 mx-2"></div>
          </div>
          <div className="flex items-center justify-between mt-2">
            <span>Dark mode</span>
            <div
              className={`w-4 h-4 rounded-full ${theme === 'dark' ? 'bg-[#0466C8]' : 'bg-gray-300'} flex items-center justify-center`}
            >
              {theme === 'dark' && (
                <div className="w-2 h-2 rounded-full bg-white"></div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const DropdownSelect = ({ label, subLabel, value, onChange, options }) => {
  return (
    <div>
      <label className="font-semibold">{label}</label>
      {subLabel && <p className="text-sm text-gray-500">{subLabel}</p>}
      <select
        value={value}
        onChange={(e) => onChange(e.target.value)}
        className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none "
      >
        {options.map((option) => (
          <option key={option} value={option}>
            {option}
          </option>
        ))}
      </select>
    </div>
  );
}

const Settings = () => {
  const [activeTab, setActiveTab] = useState('general');

  return (
    <>
      <div className="container mx-auto max-w-[1031px]">
        <SettingsHeader activeTab={activeTab} setActiveTab={setActiveTab} />
        {activeTab === 'general' && (
          <div className="bg-white border rounded-lg shadow-md p-6 md:p-10">
          <div className="space-y-10">
            <ProfileSection />
            <AdminInformation />
            <Address />
            <Notifications />
            <PasswordSection />
            <DeleteAccount />
          </div>
        </div>
        )}
        {activeTab === 'permissions' && (
          <>
            <div className=" max-w-[879px] bg-white shadow rounded-lg border mb-10">
              <AdministrativeRoles />
            </div>
            <div className=" max-w-[879px] border rounded-lg shadow">
              <Administrators />
            </div>
          </>
        )}
        {activeTab === 'preferences' && (
          <div className=" border rounded-lg shadow-md p-6 min-h-[889px] ">
            <AppearanceSettings />
          </div>
        )}
      </div>
    </>
  );
};

export default Settings;
