import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { setFormData as setRdxFormData } from "@/redux/Listing/listingSlice";
import Consider from "../../../components/Listings/Consider";
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectLabel,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";

const HouseRules = () => {
  const selectedPolicies =
    useSelector((state) => state.hostListing.formData.policies) || [];
  const dispatch = useDispatch();

  const [formData, setFormData] = useState({
    checkInFrom: "",
    checkInUntil: "",
    checkOutFrom: "",
    checkOutUntil: "",
    allowChildren: "yes",
    allowPets: "no",
    additionalPetsDetails: "",
  });

  const times = [
    "12:00 A.M",
    "1:00 A.M",
    "2:00 A.M",
    "3:00 A.M",
    "4:00 A.M",
    "5:00 A.M",
    "6:00 A.M",
    "7:00 A.M",
    "8:00 A.M",
    "9:00 A.M",
    "10:00 A.M",
    "11:00 A.M",
    "12:00 P.M",
    "1:00 P.M",
    "2:00 P.M",
    "3:00 P.M",
    "4:00 P.M",
    "5:00 P.M",
    "6:00 P.M",
    "7:00 P.M",
    "8:00 P.M",
    "9:00 P.M",
    "10:00 P.M",
    "11:00 P.M",
  ];

  useEffect(() => {
    handleHouseRules();
  }, [formData]);

  const handleChange = (name, value) => {
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handlePolicyChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleHouseRules = () => {
    // e.preventDefault();
    const checkInPolicy = {
      type: "Check-In",
      description: `${formData.checkInFrom} - ${formData.checkInUntil}`,
      isMandatory: true,
    };

    const checkOutPolicy = {
      type: "Check-Out",
      description: `${formData.checkOutFrom} - ${formData.checkOutUntil}`,
      isMandatory: true,
    };

    const childrenPolicy = {
      type: "Children",
      description:
        formData.allowChildren === "yes"
          ? "Children Allowed"
          : "Children not allowed",
      isMandatory: true,
    };

    const petsPolicy = {
      type: "Pets",
      description:
        formData.allowPets === "yes" ? "Pets are allowed" : "Pets not allowed",
      isMandatory: true,
    };

    const updatedPolicies = [
      // ...selectedPolicies,
      checkInPolicy,
      checkOutPolicy,
      childrenPolicy,
      petsPolicy,
    ];

    dispatch(setRdxFormData({ policies: updatedPolicies }));
  };

  return (
    <form className="flex flex-col gap-5 md:gap-10">
      <h4 className="font-medium text-xl">
        Choose Check-In & Check-Out Times For Your Guests
      </h4>
      <div className="flex items-start gap-4 ">
        {/* CHECK IN/OUT */}
        <div className="p-4 flex flex-col gap-7 bg-laara-secondary/35 md:max-w-[679px] xl:max-w-[733px] w-full border border-[#A3A3A3] rounded-[6px]">
          {/* CHECK IN */}
          <div className="lg:max-w-[648px] w-full max-h-[256px]">
            <div>
              <h4 className="font-semibold text-lg">Check-in</h4>
              <div className="flex flex-col gap-4 md:flex-row items-center md:gap-2 text-[#6D6D6D]">
                <div className="flex flex-col gap-1 w-full md:w-[317px]">
                  <label htmlFor="checkInFrom">From</label>

                  <Select
                    name="checkInFrom"
                    onValueChange={(value) =>
                      handleChange("checkInFrom", value)
                    }
                    value={formData.checkInFrom}
                    className=""
                  >
                    <SelectTrigger className="p-2 rounded-[8px] bg-white border border-[#6D6D6D]">
                      <SelectValue placeholder="Select check in time" />
                    </SelectTrigger>
                    <SelectContent>
                      <SelectGroup>
                        <SelectLabel>Check in from</SelectLabel>
                        {times.map((item) => (
                          <SelectItem key={item} value={item}>
                            {item}
                          </SelectItem>
                        ))}
                      </SelectGroup>
                    </SelectContent>
                  </Select>
                </div>
                <div className="flex flex-col gap-1 w-full md:w-[317px] ">
                  <label htmlFor="checkInUntil">Until</label>

                  <Select
                    name="checkInUntil"
                    onValueChange={(value) =>
                      handleChange("checkInUntil", value)
                    }
                    value={formData.checkInUntil}
                    className=""
                  >
                    <SelectTrigger className="p-2 rounded-[8px] bg-white border border-[#6D6D6D]">
                      <SelectValue placeholder="Select check in time" />
                    </SelectTrigger>
                    <SelectContent>
                      <SelectGroup>
                        <SelectLabel>Check in until</SelectLabel>
                        {times.map((item) => (
                          <SelectItem key={item} value={item}>
                            {item}
                          </SelectItem>
                        ))}
                      </SelectGroup>
                    </SelectContent>
                  </Select>
                </div>
              </div>
            </div>
          </div>
          {/* CHECK OUT */}
          <div className="lg:max-w-[648px] w-full max-h-[256px]">
            <div>
              <h4 className="font-semibold text-lg">Check-Out</h4>
              <div className="flex flex-col gap-4 md:flex-row items-center md:gap-2 text-[#6D6D6D]">
                <div className="flex flex-col gap-1 w-full md:w-[317px]">
                  <label htmlFor="checkOutFrom">From</label>

                  <Select
                    name="checkOutFrom"
                    onValueChange={(value) =>
                      handleChange("checkOutFrom", value)
                    }
                    value={formData.checkOutFrom}
                    className=""
                  >
                    <SelectTrigger className="p-2 rounded-[8px] bg-white border border-[#6D6D6D]">
                      <SelectValue placeholder="Select check out time" />
                    </SelectTrigger>
                    <SelectContent>
                      <SelectGroup>
                        <SelectLabel>Check out from</SelectLabel>
                        {times.map((item) => (
                          <SelectItem key={item} value={item}>
                            {item}
                          </SelectItem>
                        ))}
                      </SelectGroup>
                    </SelectContent>
                  </Select>
                </div>
                <div className="flex flex-col gap-1 w-full md:w-[317px]">
                  <label htmlFor="checkOutUntil">Until</label>

                  <Select
                    name="checkOutUntil"
                    onValueChange={(value) =>
                      handleChange("checkOutUntil", value)
                    }
                    value={formData.checkOutUntil}
                    className=""
                  >
                    <SelectTrigger className="p-2 rounded-[8px] bg-white border border-[#6D6D6D] ">
                      <SelectValue placeholder="Select check out time" />
                    </SelectTrigger>
                    <SelectContent>
                      <SelectGroup>
                        <SelectLabel>Check out until</SelectLabel>
                        {times.map((item) => (
                          <SelectItem key={item} value={item}>
                            {item}
                          </SelectItem>
                        ))}
                      </SelectGroup>
                    </SelectContent>
                  </Select>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Consider
          title="What should I consider when choosing check-in & check-out times"
          data={["Select a time you are comfortable with"]}
        />
      </div>

      <div className="p-4 flex flex-col gap-7 bg-laara-secondary/35 md:max-w-[679px] xl:max-w-[733px] w-full border border-[#A3A3A3] rounded-[6px] ">
        {/* ALLOW CHILDREN */}
        <div className="">
          <p className="text-lg">Do you allow children?</p>
          <div className="space-y-3">
            <div className="flex items-center gap-3">
              <input
                id="children-yes"
                type="radio"
                value="yes"
                name="allowChildren"
                className="size-5"
                onChange={handlePolicyChange}
                checked={formData.allowChildren === "yes"}
              />
              <label className="text-base" htmlFor="children-yes">
                Yes
              </label>
            </div>
            <div className="flex items-center gap-3">
              <input
                id="children-no"
                type="radio"
                value="no"
                name="allowChildren"
                className="size-5"
                onChange={handlePolicyChange}
                checked={formData.allowChildren === "no"}
              />
              <label className="text-base" htmlFor="children-no">
                No
              </label>
            </div>
          </div>
        </div>
        {/* DO YOU ALLOW PETS */}
        <div>
          <p className="text-lg">Do you allow pets?</p>
          <small className="text-[#6D6D6D]">
            If you allow pets, please provide additional details if necessary.
          </small>
          <div className="space-y-3">
            <div className="flex items-center gap-3">
              <input
                id="pets-yes"
                type="radio"
                value="yes"
                name="allowPets"
                className="size-5"
                onChange={handlePolicyChange}
                checked={formData.allowPets === "yes"}
              />
              <label htmlFor="pets-yes">Yes</label>
            </div>
            <div className="flex items-center gap-3">
              <input
                id="pets-no"
                type="radio"
                value="no"
                name="allowPets"
                className="size-5"
                onChange={handlePolicyChange}
                checked={formData.allowPets === "no"}
              />
              <label htmlFor="pets-no">No</label>
            </div>
          </div>

          <div className="space-y-2">
            <p className="text-lg">Additional details (optional)</p>
            <input
              type="text"
              name="additionalPetsDetails"
              className="border border-[#6D6D6D] px-3 py-2 rounded-[8px] outline-none w-full"
              value={formData.additionalPetsDetails}
              onChange={handleChange}
            />
          </div>
        </div>
      </div>
    </form>
  );
};

export default HouseRules;
