import PropTypes from 'prop-types';

import  { useState} from 'react';
import { IoStar, } from 'react-icons/io5';
import { CiLocationOn, CiShare2 } from "react-icons/ci";
import { useNavigate } from 'react-router-dom';

import { BsHeartFill } from 'react-icons/bs';
import GMaps from '@/components/shared/Maps/GMaps';




function Overview({
  ratingValue,
  hotelName ,
  address,
  images,
  amenities,
  host = {},
  staff = [],
}) {
  if (!address) {
    return <p>Address not available</p>;
  }
  const groupedAmenities = amenities.reduce((acc, { amenities }) => {
    if (amenities.category === 'General') {
      if (!acc['General']) {
        acc['General'] = [];
      }
      acc['General'].push(amenities);
    }
    return acc;
  }, {});
  const navigate = useNavigate();
  const [rental, setRental] = useState(false);


    
  // Calculate the number of full, half, and empty stars
  const fullStars = Math.floor(ratingValue);
  const hasHalfStar = ratingValue % 1 !== 0;
  const emptyStars = 5 - Math.ceil(ratingValue);
  const [loved, setLoved] = useState(false);

  const handlelove = () => {
    setLoved(!loved);
  };

  const latitude = 40.73061; // New York City Latitude
  const longitude = -73.935242; 

  const handleViewImages = () => {
    navigate('/property-images', {state: {images} });
  }

  return (
    <div className="p-4 ">
      {/* Header Section: Social Sharing and Rating/Location */}
      <div className="flex flex-col lg:flex-row  justify-between mb-6 gap-4">
        {/* Rating and Location */}
        <div className="flex flex-col gap-4">
          {/* Rating */}
          <div className="flex items-center">
            <span className="text-lg font-base text-[hsla(0,0%,53%,1)] mr-2">
              {ratingValue.toFixed(1)}
            </span>
            <div className="flex text-yellow-500">
              {[...Array(fullStars)].map((_, index) => (
                <IoStar key={index} className="w-5 h-5" />
              ))}
              {hasHalfStar && (
                <IoStar
                  className="w-5 h-5"
                  style={{ clipPath: 'inset(0 50% 0 0)' }}
                />
              )}
              {[...Array(emptyStars)].map((_, index) => (
                <IoStar
                  key={index + fullStars + (hasHalfStar ? 1 : 0)}
                  className="w-5 h-5 text-gray-300"
                />
              ))}
            </div>
          </div>
          <div>
            <h1>{hotelName}</h1>
            <p className='flex text-[hsla(0,0%,1%,1)]'>
              <CiLocationOn className='text-blue-600' size={20} /> {`${address.physicalAddress || ''}, ${address.street || ''}, ${address.town || ''}, ${address.city || ''}, ${address.country || ''}, `}
            </p>
          </div>
        </div>

        {/* Social Sharing Options */}
        <div className="flex items-center gap-4">
          <button
            className="flex items-center text-gray-500 hover:text-blue-700 lg:hover:scale-125 duration-300"
            aria-label="Share"
          >
            <CiShare2 className="w-6 h-6 mr-2" />
          </button>
          <button
            className={`flex items-center text-gray-500 ${loved ? "text-red-700" : null} duration-300 lg:hover:scale-125`}
            aria-label="Love"
            onClick={handlelove}
          >
            <BsHeartFill className="w-6 h-6 mr-2" />
          </button>
          <button
            className="flex items-center text-blue-500 hover:text-white duration-300 hover:bg-blue-500 border rounded-full h-11 p-2"
            aria-label="Follow Property"
          >
            Follow Property
          </button>
        </div>
      </div>

      {/* Image and Map Section */}
      <div className="mb-6 flex flex-col  lg:flex-row  max-xl:flex-col gap-4 max-w-full ">
        {/* Image Carousel */}
        <div className="flex flex-col  gap-2 ">
          {/* Top and Middle Rows Side by Side */}
          <div className="flex gap-2">
            {/* Top Row */}
            <div className="flex flex-col gap-2">
              {images.slice(0, 2).map((image, index) => (
                <img
                  key={index}
                  src={image}
                  alt={`Image ${index + 1}`}
                  onClick={handleViewImages}
                  className="w-[305px] h-[159px] object-cover  shadow-lg rounded-lg transition-opacity duration-300 hover:opacity-90"
                />
              ))}
            </div>

            {/* Middle Row */}
            <div className="flex flex-col ">
              <div className="flex flex-grow">
                {images.slice(2, 3).map((image, index) => (
                  <img
                    key={index}
                    src={image}
                    alt={`Image ${index + 1}`}
                    onClick={handleViewImages}
                    className=" flex flex-grow   w-[559px] h-[336px]  rounded-lg object-cover shadow-lg rounded-2x transition-opacity duration-300 hover:opacity-90"
                  />
                ))}
              </div>
            </div>
          </div>          

          {/* Bottom Row */}
          <div className="grid grid-cols-4  gap-2 lg:w-[884px]lg:h-[161px]">
            {images.slice(3, 7).map((image, index) => (
              <img
                key={index}
                src={image}
                alt={`Image ${index + 1}`}
                onClick={handleViewImages}
                className="lg:w-[206px] lg:h-[161px]  object-cover shadow-lg rounded-lg transition-opacity duration-300 hover:opacity-90"
              />
            ))}
          </div>
        </div>       
        {/* Map and Amenities Section */}
        <div className="flex flex-col w-[340px] ">
          {/* Map Overview */}
          <div className="mb-6">
            <h2 className="text-xl font-semibold mb-2">Map Overview</h2>
            {/* Replace with actual map component */}
            <div className=" rounded-lg w-full h-auto p-2">
              <GMaps latitude={latitude} longitude={longitude} />
            </div>
          </div>

          {/* General Amenities */}
          <div className="p-2">
            <h2 className="text-xl font-semibold mb-4">General Amenities</h2>
            {Object.keys(groupedAmenities).map((category, index) => (
                <div key={index}>
                  <ul className="list-disc list-inside text-gray-700">
                    {groupedAmenities[category].map((amenity, i) => (
                      <li key={i} className="flex gap-1">
                        {amenity.icon} {amenity.name}
                      </li>
                    ))}
                  </ul>
                </div>
              ))}
            <a href="#" className="text-blue-500 hover:underline mt-4 block" >
              View More
            </a>
          </div>
        </div>
      </div>
      <hr className='my-6' />
      {/* Host Information */}
      <div className="p-4 rounded-lg">
        <div className="flex flex-col lg:flex-row justify-between">
          <div className="flex items-center mb-4">
            <img
              src={host.image}
              alt="Host"
              className="w-24 h-24 rounded-full border-2 border-green-500 mr-4"
            />
            <div>
              <span className="block text-lg font-semibold">
                Hosted By {host.name}
              </span>
              <span className="block text-gray-600">{host.description}</span>
            </div>
          </div>
          <div className="flex flex-col items-start">
            <span className="block text-gray-600">{host.languages.join(', ')}</span>
            <span className="block text-gray-600">{host.location}</span>
          </div>
        </div>
        <div className="flex flex-col lg:flex-row gap-2">
          <div className="block">
            <h3 className="text-lg font-semibold mb-2">Details About the Space</h3>
            <p className="text-gray-700 mb-4 mt-7">
              {host.details}
            </p>
            <span className="text-blue-600 font-semibold hover:underline">
              read more
            </span>
          </div>
          <div className="block w-[350px] lg:w-[400px]">
            <p className="text-lg font-semibold mb-2">Our Staff</p>
            <div className="grid grid-cols-2 gap-2 ">
              {staff.map((staffMember, index) => (
                <div key={index} className=" w-full">
                  <img
                    src={staffMember.image}
                    alt={`Staff ${index + 1}`}
                    className="w-[155px] h-[89px] rounded-lg"
                  />
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

Overview.propTypes = {
  ratingValue: PropTypes.number.isRequired,
  hotelName: PropTypes.string.isRequired,
  address: PropTypes.string.isRequired,
  images: PropTypes.arrayOf(PropTypes.string).isRequired, // URLs for images
  amenities: PropTypes.arrayOf(PropTypes.string).isRequired,
  host: PropTypes.shape({
    name: PropTypes.string.isRequired,                  // Host's name
    numberOfYears: PropTypes.number.isRequired,         // Number of years the host has been active
    details: PropTypes.string.isRequired                // Details about the space
  }).isRequired,
  staff: PropTypes.arrayOf(PropTypes.string).isRequired, // Array of URLs for staff images
  latitude: PropTypes.number,      // Latitude for the map center Also Remeber to make them isrequired
  longitude: PropTypes.number, 

}

export default Overview;
