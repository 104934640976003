import { useState } from "react";

import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";

const MobileCalendar = ({ checking, checkout, setChecking, setCheckout }) => {
  return (
    <div className="w-full bg-white">
      <div className="p3">
        <p className="text-[10px] text-[#7B7B7B] my-3">Check In Date</p>
        <Calendar
          showNeighboringMonth={false}
          view="month"
          minDate={new Date()}
          onChange={setChecking}
          value={checking}
        />
      </div>
      <br />
      <br />
      <div className="p3">
        <p className="text-[10px] text-[#7B7B7B] my-3">Check Out Date</p>
        <Calendar
          showNeighboringMonth={false}
          view="month"
          minDate={new Date()}
          onChange={setCheckout}
          value={checkout}
        />
      </div>
    </div>
  );
};

export default MobileCalendar;