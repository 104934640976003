import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import Header from './globalrooms/Header';
import useFetchRoomDetails from './globalrooms/useFetchRoomDetails'; 
import Consider from '@/components/Listings/Consider';

const EditRoomDescription = () => {
  const { id, number } = useParams(); // Get params from URL
  const { rooms, loading, error } = useFetchRoomDetails(id);
  const [description, setDescription] = useState("");
  const [roomType, setRoomType] = useState("");

  // Filter the room based on the room number
  useEffect(() => {
    if (rooms.length > 0) {
      const filteredRoom = rooms.find(room => room.number === number);
      if (filteredRoom) {
        setDescription(filteredRoom.roomTypes.description || "");
        setRoomType(filteredRoom.roomTypes.name || ""); 
        console.log("description:", filteredRoom);
      } else {
        console.log("No matching room found.");
      }
    }
  }, [rooms, number]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Uncomment and update the code below when ready to implement the API call
    // try {
    //   const updatedRoom = {
    //     ...filteredRoom,
    //     description,
    //   };

    //   await saveRoomDescription(id, number, updatedRoom);
    //   alert("Room description updated successfully!");
    // } catch (err) {
    //   console.error("Error updating room description:", err);
    //   alert("Failed to update room description.");
    // }
  };

  if (loading) {
    return (
      <div className="flex justify-center items-center h-screen">
        <div className="w-16 h-16 border-4 border-t-4 border-gray-200 border-t-blue-500 border-solid rounded-full animate-spin" aria-label="Loading"></div>
      </div>
    );
  }

  if (error) {
    return <p className="text-center text-red-500">{error}</p>;
  }

  return (
    <div className="p-8 bg-base-white">
      <Header linkTo={`/room-details/${id}/${roomType}/${number}`} title="Room Description" />
      <form className="flex flex-col p-2" onSubmit={handleSubmit}>
        <h4 className="font-semibold text-[32px] leading-[42px]">
          How would you describe your room?
        </h4>
        <div className="flex items-start gap-4 mt-5">
          <div className="p-4 flex flex-col gap-2 bg-laara-secondary/35 max-w-[728px] w-full h-[400px]">
            <label htmlFor="description" className="text-base m-4 ml-0">
              Room Description
            </label>
            <textarea
              id="description"
              placeholder="Type new description"
              value={description}
              className="w-full h-full border py-2 px-3 rounded-lg block outline-none bg-transparent mt-3"
              onChange={(e) => setDescription(e.target.value)} 
            />
          </div>

          <Consider
            title={"What should I consider when writing a description"}
            data={[
              "Highlight unique features",
              "Be honest and descriptive",
              "Include key amenities",
            ]}
          />
        </div>

        <div className='w-full flex justify-end'>
          <button
            type="submit"
            className="mt-5 py-2 px-4 bg-blue-500 text-white rounded-[36px] w-[216px] h-[55px]"
          >
            Save Changes
          </button>
        </div>
      </form>
    </div>
  );
};

export default EditRoomDescription;
