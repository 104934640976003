import { useState } from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLocationDot, faBed, faParking, faWifi } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router-dom';

const PropertyCard = ({ property }) => {
  const navigate = useNavigate();
  const [selectedImage, setSelectedImage] = useState(null);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  // Correctly extract the URLs from the nested 'images' property
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const images = property.propertyImages?.length > 0
    ? property.propertyImages.map((imageObj) => imageObj.images.url)
    : ['default-image.jpg']; // Fallback if no images are available

  // Count the number of rooms
  const roomCount = property.rooms ? property.rooms.length : 0;

  // Check if the Wi-Fi amenity is available by checking name for 'wifi'
  const wifiAmenity = property.propertyAmenities.find(
    (amenity) => amenity.amenities.name.toLowerCase().includes('wifi')
  );
  const wifiStatus = wifiAmenity ? 'Wi-Fi' : null;

  // Check if Parking is available by checking category or name for 'parking'
  const parkingAmenity = property.propertyAmenities.find(
    (amenity) =>
      amenity.amenities.category.toLowerCase() === 'parking' || 
      amenity.amenities.name.toLowerCase().includes('parking')
  );
  const parkingStatus = parkingAmenity ? 'Parking' : 'No Parking';

  const goToViewProperty = () => {
    navigate(`/view-property/${property.id}`);
  };

  const handleImageClick = (image, index) => {
    setSelectedImage(image);
    setCurrentImageIndex(index);
  };

  const handleClosePreview = () => {
    setSelectedImage(null);
    setCurrentImageIndex(0);
  };

  const handleNextImage = () => {
    setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);
    setSelectedImage(images[(currentImageIndex + 1) % images.length]);
  };

  const handlePrevImage = () => {
    setCurrentImageIndex((prevIndex) => (prevIndex - 1 + images.length) % images.length);
    setSelectedImage(images[(currentImageIndex - 1 + images.length) % images.length]);
  };

  return (
    <div className="flex flex-col max-w-[400px] max-h-[465px] w-full gap rounded-lg overflow-hidden mt-5 mb-5 border border-gray-200 bg-base-white">
      <section className="property-images w-full flex p-2 gap-2">
        <div className="relative w-2/3 max-h-[195px] bg-gray-200 h-full">
          <img
            src={images[0] || 'default-image.jpg'}
            alt="Property"
            className={`w-full h-full object-cover ${!images[0] ? 'bg-gray-200' : ''}`}
          />
          {selectedImage && (
            <div className="fixed inset-0 bg-black bg-opacity-80 flex items-center justify-center z-50">
              <button
                className="absolute left-4 text-white text-3xl bg-black bg-opacity-50 rounded-full p-2"
                onClick={handlePrevImage}
              >
                &lt;
              </button>
              <img
                src={selectedImage || 'default-image.jpg'}
                alt="Preview"
                className="max-w-[90%] max-h-[90%] border-2 border-white"
              />
              <button
                className="absolute right-4 text-white text-3xl bg-black bg-opacity-50 rounded-full p-2"
                onClick={handleNextImage}
              >
                &gt;
              </button>
              <button
                className="absolute top-4 right-4 text-white text-3xl bg-black bg-opacity-50 rounded-full p-2"
                onClick={handleClosePreview}
              >
                &times;
              </button>
            </div>
          )}
        </div>
        <div className="w-1/3 flex flex-col p-2 h-full gap-2">
          {images.slice(1, 4).map((image, index) => (
            <div key={index} className="w-full h-14 cursor-pointer rounded bg-gray-200 ">
              <img
                src={image || 'default-image.jpg'}
                alt={`Thumbnail ${index + 1}`}
                className="w-full h-[50px] object-cover rounded"
                onClick={() => handleImageClick(image, index)}
              />
            </div>
          ))}
        </div>
      </section>
      <div className="w-full p-4">
        <div>
          <h2 className="text-lg font-normal mb-2 text-gray-600">{property.name || 'No name provided'}</h2>
          <div className="flex items-center gap-3">
            <FontAwesomeIcon icon={faLocationDot} className="text-gray-600 mb-1" />
            <p className="text-gray-600 mb-1">{property.address?.city || 'No address provided'}, {property.address?.country}</p>
          </div>
        </div>
        <div className="flex flex-wrap gap-4 mb-4 mt-2">
          <div className="flex items-center gap-1">
            <FontAwesomeIcon icon={faBed} className="text-gray-600" />
            <span className="text-gray-600">{roomCount} {roomCount > 1 ? 'Rooms' : 'Room'}</span>
          </div>
          {parkingStatus && (
            <div className="flex items-center gap-1">
              <FontAwesomeIcon icon={faParking} className="text-gray-600" />
              <span className="text-gray-600">{parkingStatus}</span>
            </div>
          )}
          {wifiStatus && (
            <div className="flex items-center gap-1">
              <FontAwesomeIcon icon={faWifi} className="text-gray-600" />
              <span className="text-gray-600">{wifiStatus}</span>
            </div>
          )}
        </div>
        <div className="flex justify-end">
          <button 
            onClick={goToViewProperty}
            className="bg-laara-primary text-white text-[14px] w-32 h-8 rounded-[36px] hover:bg-blue-600 transition-all duration-300 ease-in mt-4"
          >
            View Details
          </button>
        </div>
      </div>
    </div>
  );
};

PropertyCard.propTypes = {
  property: PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string,
    address: PropTypes.shape({
      city: PropTypes.string,
      country: PropTypes.string,
    }),
    propertyImages: PropTypes.arrayOf(PropTypes.shape({
      images: PropTypes.shape({
        url: PropTypes.string,
      }),
    })),
    rooms: PropTypes.arrayOf(PropTypes.object), // Check for room data
    propertyAmenities: PropTypes.arrayOf(
      PropTypes.shape({
        amenities: PropTypes.shape({
          name: PropTypes.string,
          category: PropTypes.string, // Added category field
        }),
      })
    ),
  }).isRequired,
};

export default PropertyCard;
