import React, { useState, useEffect } from "react";
import { useParams, useNavigate, Link } from "react-router-dom";
import { getPropertyById, updatePropertyPolicies } from "@/api";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLessThan } from "@fortawesome/free-solid-svg-icons";
import Consider from "@/components/Listings/Consider";

// Function to convert 12-hour time format to 24-hour format
const convertTo24HourFormat = (time12h) => {
  const [time, modifier] = time12h.split(" ");
  let [hours, minutes] = time.split(":");
  if (modifier === "PM" && hours !== "12") {
    hours = parseInt(hours, 10) + 12;
  }
  if (modifier === "AM" && hours === "12") {
    hours = 0;
  }
  return `${String(hours).padStart(2, "0")}:${minutes}`;
};

const EditHouseRules = () => {
  const { id } = useParams(); // Retrieve the `id` parameter
  const navigate = useNavigate();

  const [property, setProperty] = useState(null);
  const [initialPolicies, setInitialPolicies] = useState({});
  
  const [checkInPolicyId, setCheckInPolicyId] = useState(null);
  const [checkInFrom, setCheckInFrom] = useState("");
  const [checkInUntil, setCheckInUntil] = useState("");
  
  const [checkOutPolicyId, setCheckOutPolicyId] = useState(null);
  const [checkOutFrom, setCheckOutFrom] = useState("");
  const [checkOutUntil, setCheckOutUntil] = useState("");
  
  const [childrenPolicyId, setChildrenPolicyId] = useState(null);
  const [allowChildren, setAllowChildren] = useState(null);
  
  const [petsPolicyId, setPetsPolicyId] = useState(null);
  const [allowPets, setAllowPets] = useState(null);
  const [petDetails, setPetDetails] = useState("");

  useEffect(() => {
    const fetchProperty = async () => {
      if (!id) {
        console.error("Property ID is not available.");
        return;
      }

      try {
        const data = await getPropertyById(id);
        setProperty(data);

        const policies = data.property.propertyPolicies || [];
        const policyMap = {};

        policies.forEach((policy) => {
          const policyType = policy.policies.type;
          policyMap[policyType] = {
            policyId: policy.policies.policyId,
            description: policy.policies.description,
          };
          switch (policyType) {
            case "Check-In":
              setCheckInPolicyId(policy.policies.id);
              setCheckInFrom(
                convertTo24HourFormat(
                  policy.policies.description.split(" - ")[0]
                )
              );
              setCheckInUntil(
                convertTo24HourFormat(
                  policy.policies.description.split(" - ")[1] || ""
                )
              );
              break;
            case "Check-Out":
              setCheckOutPolicyId(policy.policies.id);
              setCheckOutFrom(
                convertTo24HourFormat(
                  policy.policies.description.split(" - ")[0]
                )
              );
              setCheckOutUntil(
                convertTo24HourFormat(
                  policy.policies.description.split(" - ")[1] || ""
                )
              );
              break;
            case "Children":
              setChildrenPolicyId(policy.policies.id);
              setAllowChildren(
                policy.policies.description === "Children Allowed"
              );
              break;
            case "Pets":
              setPetsPolicyId(policy.policies.id);
              setAllowPets(policy.policies.description !== "Pets not allowed");
              setPetDetails(policy.policies.description || "");
              break;
            default:
              break;
          }
        });

        setInitialPolicies(policyMap); // Save the initial policies for comparison
      } catch (error) {
        console.error("Error fetching property details:", error);
      }
    };

    fetchProperty();
  }, [id]);

  const handleSubmit = async (event) => {
    event.preventDefault();

    const newPolicies = [];

    // Check for changes in each section before adding to the payload
    if (
      initialPolicies["Check-In"] &&
      (checkInFrom !== convertTo24HourFormat(initialPolicies["Check-In"].description.split(" - ")[0]) ||
        checkInUntil !== convertTo24HourFormat(initialPolicies["Check-In"].description.split(" - ")[1]))
    ) {
      newPolicies.push({
        policyId: checkInPolicyId,
        description: `${checkInFrom} - ${checkInUntil}`,
        type: "Check-In",
        isMandatory: true,
      });
    }

    if (
      initialPolicies["Check-Out"] &&
      (checkOutFrom !== convertTo24HourFormat(initialPolicies["Check-Out"].description.split(" - ")[0]) ||
        checkOutUntil !== convertTo24HourFormat(initialPolicies["Check-Out"].description.split(" - ")[1]))
    ) {
      newPolicies.push({
        policyId: checkOutPolicyId,
        description: `${checkOutFrom} - ${checkOutUntil}`,
        type: "Check-Out",
        isMandatory: true,
      });
    }

    if (
      initialPolicies["Children"] &&
      (allowChildren === true && initialPolicies["Children"].description !== "Children Allowed") ||
      (allowChildren === false && initialPolicies["Children"].description !== "No Children Allowed")
    ) {
      newPolicies.push({
        policyId: childrenPolicyId,
        description: allowChildren ? "Children Allowed" : "No Children Allowed",
        type: "Children",
        isMandatory: true,
      });
    }

    if (
      initialPolicies["Pets"] &&
      (allowPets === true && initialPolicies["Pets"].description !== petDetails) ||
      (allowPets === false && initialPolicies["Pets"].description !== "Pets not allowed")
    ) {
      newPolicies.push({
        policyId: petsPolicyId,
        description: allowPets ? petDetails : "Pets not allowed",
        type: "Pets",
        isMandatory: true,
      });
    }

    try {
      if (newPolicies.length > 0) {
        await updatePropertyPolicies(id, newPolicies);
      }
      navigate(`/view-property/${id}`);
    } catch (error) {
      console.error("Error updating house rules:", error);
    }
  };
  return (
    <div className="p-8 bg-base-white flex">
      <div className="flex-1">
        <section className="top h-16 flex items-center p-2 gap-3">
          <div className="flex items-center gap-4 text-lg text-gray-600 w-[700px]">
            <Link to={`/view-property/${id}`}>
              <FontAwesomeIcon icon={faLessThan} className="text-base" />
            </Link>
            <h3>House Rules</h3>
          </div>
        </section>
        <form className="flex flex-col p-2" onSubmit={handleSubmit}>
          <h4 className="font-normal text-[18px] leading-[42px]">
            Choose Check In & Check Out Times For Your Guests
          </h4>
          <div className="flex flex-col gap-4 mt-5 text-gray-700 text-[14px]">
            <div className="flex flex-col gap-2 p-4 max-w-[728px] w-full justify-center items-center">
              <div className="flex flex-col gap-4 max-w-[650px] w-full">
                <h3>Check-in</h3>
                <div className="flex gap-4">
                  <div className="flex flex-col max-w-[320px] w-full">
                    <label htmlFor="checkInFrom" className="text-[14px]">
                      From
                    </label>
                    <input
                      type="time"
                      id="checkInFrom"
                      value={checkInFrom}
                      className="border py-2 px-3 rounded-lg block outline-none bg-transparent text-sm"
                      onChange={(e) => setCheckInFrom(e.target.value)}
                    />
                  </div>
                  <div className="flex flex-col max-w-[320px] w-full">
                    <label htmlFor="checkInUntil" className="text-[14px]">
                      Until
                    </label>
                    <input
                      type="time"
                      id="checkInUntil"
                      value={checkInUntil}
                      className="border py-2 px-3 rounded-lg block outline-none bg-transparent text-sm"
                      onChange={(e) => setCheckInUntil(e.target.value)}
                    />
                  </div>
                </div>
              </div>
              <div className="flex flex-col gap-4 max-w-[650px] w-full mt-4">
                <h3>Check-Out</h3>
                <div className="flex gap-4">
                  <div className="flex flex-col max-w-[320px] w-full">
                    <label htmlFor="checkOutFrom" className="text-[14px]">
                      From
                    </label>
                    <input
                      type="time"
                      id="checkOutFrom"
                      value={checkOutFrom}
                      className="border py-2 px-3 rounded-lg block outline-none bg-transparent text-sm"
                      onChange={(e) => setCheckOutFrom(e.target.value)}
                    />
                  </div>
                  <div className="flex flex-col max-w-[320px] w-full">
                    <label htmlFor="checkOutUntil" className="text-[14px]">
                      Until
                    </label>
                    <input
                      type="time"
                      id="checkOutUntil"
                      value={checkOutUntil}
                      className="border py-2 px-3 rounded-lg block outline-none bg-transparent text-sm"
                      onChange={(e) => setCheckOutUntil(e.target.value)}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="flex flex-col gap-4 p-4 max-w-[728px] w-full justify-center items-center">
              <div className="flex flex-col gap-4 max-w-[650px] w-full">
                <h3>Allow Children</h3>
                <div className="flex gap-4">
                  <label
                    htmlFor="allowChildrenYes"
                    className="flex items-center cursor-pointer"
                  >
                    <input
                      type="checkbox"
                      id="allowChildrenYes"
                      checked={allowChildren === true}
                      className="custom-checkbox mr-2"
                      onChange={() => setAllowChildren(true)}
                    />
                    <span
                      className={allowChildren === true ? "text-blue-500" : ""}
                    >
                      Yes
                    </span>
                  </label>
                  <label
                    htmlFor="allowChildrenNo"
                    className="flex items-center cursor-pointer"
                  >
                    <input
                      type="checkbox"
                      id="allowChildrenNo"
                      checked={allowChildren === false}
                      className="custom-checkbox mr-2"
                      onChange={() => setAllowChildren(false)}
                    />
                    <span
                      className={allowChildren === false ? "text-blue-500" : ""}
                    >
                      No
                    </span>
                  </label>
                </div>
              </div>
              <div className="flex flex-col gap-4 max-w-[650px] w-full mt-4">
                <h3>Allow Pets</h3>
                <div className="flex gap-4">
                  <label
                    htmlFor="allowPetsYes"
                    className="flex items-center cursor-pointer"
                  >
                    <input
                      type="checkbox"
                      id="allowPetsYes"
                      checked={allowPets === true}
                      className="custom-checkbox mr-2"
                      onChange={() => setAllowPets(true)}
                    />
                    <span className={allowPets === true ? "text-blue-500" : ""}>
                      Yes
                    </span>
                  </label>
                  <label
                    htmlFor="allowPetsNo"
                    className="flex items-center cursor-pointer"
                  >
                    <input
                      type="checkbox"
                      id="allowPetsNo"
                      checked={allowPets === false}
                      className="custom-checkbox mr-2"
                      onChange={() => setAllowPets(false)}
                    />
                    <span
                      className={allowPets === false ? "text-blue-500" : ""}
                    >
                      No
                    </span>
                  </label>
                </div>
                {allowPets === true && (
                  <div className="flex flex-col gap-2">
                    <label htmlFor="petDetails" className="text-[14px]">
                      Pet Details
                    </label>
                    <textarea
                      id="petDetails"
                      value={petDetails}
                      className="border py-2 px-3 rounded-lg block outline-none bg-transparent text-sm"
                      onChange={(e) => setPetDetails(e.target.value)}
                      rows="4"
                    />
                  </div>
                )}
              </div>
            </div>
            <div className="p-2">
              <button
                type="submit"
                className="bg-blue-600 text-white py-2 px-4 rounded-[36px] w-[216px] h-12"
              >
                Save Changes
              </button>
            </div>
          </div>
        </form>
      </div>
      <Consider
        title={"What should I consider when setting house rules?"}
        data={[
          "Be clear and concise",
          "Ensure rules are reasonable",
          "Consider guest comfort",
        ]}
        className="ml-8"
      />
    </div>
  );
};

export default EditHouseRules;
