import { useState } from "react";

import { CalendarDays, ChevronLeft, ChevronRight } from "lucide-react";

const MobileMonthSwiper = ({ setSelectedMonth, months }) => {
  const [currentIndex, setCurrentIndex] = useState(0);

  const getMonthYear = (index) => {
    const currentDate = new Date();
    currentDate.setMonth(currentDate.getMonth() + index);
    const month = months[currentDate.getMonth()];
    const year = currentDate.getFullYear();
    return { month, year };
  };

  const handlePrev = () => {
    setCurrentIndex((prevIndex) => Math.max(prevIndex - 1, 0));
  };

  const handleNext = () => {
    setCurrentIndex((prevIndex) => prevIndex + 1);
  };

  const renderMonths = () => {
    const monthsArray = [];
    for (let i = currentIndex; i < currentIndex + 3; i++) {
      monthsArray.push(getMonthYear(i));
    }
    return monthsArray;
  };

  return (
    <div className="text-[#292929]">
      <div className="flex items-center">
        <button
          className={`bg-white rounded-[50%] border text-base mr-1 ${
            currentIndex === 0 ? "hidden" : "block"
          }`}
          onClick={handlePrev}
          disabled={currentIndex === 0}
        >
          <ChevronLeft color="#6D6D6D" />
        </button>
        <div className="flex overflow-hidden w-full justify-between gap-4">
          {renderMonths().map((monthYear, index) => (
            <div
              onClick={() => setSelectedMonth(index)}
              key={index}
              className="text-center w-full text-sm"
            >
              <span className="flex flex-col items-center justify-center border rounded-2xl p-2 gap-[5px]">
                <CalendarDays color="#292929" />
                <p className="font-bold text-[#0466C8]">{monthYear.month}</p>
                <p>{monthYear.year}</p>
              </span>
            </div>
          ))}
        </div>
        <button
          className={`bg-white rounded-[50%] border text-base ml-1`}
          onClick={handleNext}
        >
          <ChevronRight color="#6D6D6D" />
        </button>
      </div>
    </div>
  );
};

export default MobileMonthSwiper;