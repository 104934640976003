import React from "react";
import ruby from "@/assets/shared/rubyLogo.png";
import { Link } from "react-router-dom";

const Banner2 = () => {
  return (
    <section className="max-w-[1248px] max-h-[210px] h-full w-full ">
      <section className="bg-banner2 bg-no-repeat bg-cover bg-bottom max-w-[77rem] w-full h-[12.125rem] rounded-[1rem] ">
        <div className="px-[46px] py-2 relative ">
          <img
            src={ruby}
            alt="ruby"
            className="w-[100px] h-auto object-contain absolute top-4 right-[1rem] md:right-5 md:top-8 "
          />

          <div>
            <article className="w-full text-laara-secondary text-center space-y-3  ">
              <h4 className="text-lg font-bold capitalize pt-4">
                Accommodation Bookings & Hosted Properties
              </h4>

              <p className="text-base ">
                Register or Sign In and Subscribe to the Ruby Loyalty Program to
                access exclusive benefits
              </p>

              <div className="flex justify-center items-center gap-2">
                <Link to="/register" className="ruby-register-btn ">
                  Register
                </Link>
                <Link to="/login" className="ruby-sign-in-btn">
                  Sign In
                </Link>
              </div>
            </article>
          </div>
        </div>
      </section>
    </section>
  );
};

export default Banner2;
