import React, { useState, useEffect } from 'react';
import { Link, useParams, useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLessThan } from '@fortawesome/free-solid-svg-icons';
import { getPropertyById, updatePropertySafetyFacilities } from '@/api'; 
import './editproperty.css';

const safetyAmenities = [
  "CCTV in common areas",
  "CCTV outside property",
  "Fire extinguishers",
  "Smoke detectors",
  "Carbon monoxide detector",
  "First aid kit",
  "Security alarm",
  "24-hour security",
  "Emergency exit"
];

const SafetyAmenities = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [selectedSafetyAmenities, setSelectedSafetyAmenities] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchDetails = async () => {
      try {
        const data = await getPropertyById(id);
        console.log('Fetched data:', data); // Inspect the response structure

        if (data && data.property && data.property.propertyAmenities) {
          const amenities = data.property.propertyAmenities
            ?.filter(amenity => amenity?.amenities?.category === "Safety")
            ?.map(amenity => amenity?.amenities?.name) || [];

          setSelectedSafetyAmenities(amenities);
        } else {
          console.error('Invalid data structure or missing propertyAmenities');
          setError('Invalid data structure or missing propertyAmenities');
        }
      } catch (error) {
        console.error('Error fetching property details:', error);
        setError('Error fetching property details');
      } finally {
        setLoading(false);
      }
    };
    
    fetchDetails();
  }, [id]);

  const handleCheckboxChange = (item) => {
    setSelectedSafetyAmenities((prev) => {
      if (prev.includes(item)) {
        return prev.filter((i) => i !== item);
      } else {
        return [...prev, item];
      }
    });
  };

const handleSubmit = async (event) => {
  event.preventDefault();
  
  const updatedAmenities = selectedSafetyAmenities.map(item => ({
    name: item,
    category: "Safety",
  }));

  try {
    await updatePropertySafetyFacilities(id, { amenities: updatedAmenities });
    navigate(`/view-property/${id}`);
  } catch (error) {
    console.error('Error updating safety amenities:', error);
  }
};


  if (loading) return <p>Loading...</p>;
  if (error) return <p>{error}</p>;

  return (
    <div className="flex flex-col min-h-screen p-8 bg-base-white">
      <div className="flex-1">
        <section className='top h-16 flex items-center p-2 gap-3'>
          <div className='flex items-center gap-4 text-lg text-gray-600 w-[700px]'>
            <Link to={`/view-property/${id}`}>
              <FontAwesomeIcon icon={faLessThan} className="text-base" />
            </Link>
            <h3>Safety Amenities</h3>
          </div>
        </section>
        <section className="flex w-full max-w-[1264px] gap-5 h-max">
          <form className="flex flex-col p-2 w-full max-h-[700px] h-full" onSubmit={handleSubmit}>
            <div className="flex flex-col gap-4 mt-5 text-gray-700 text-[14px] overflow-y-auto">
              <div className="flex flex-col gap-4 p-5 w-full justify-center items-center">
                <div className="flex flex-col gap-4 w-full p-2">
                  <h2 className="text-xl mb-4">What safety amenities are available at your place?</h2>
                  <div className="flex flex-col w-full p-4 rounded-lg border border-custom-gray-300">
                    {safetyAmenities.map((item) => (
                      <label key={item} className="flex items-center gap-4 mb-3">
                        <input
                          type="checkbox"
                          checked={selectedSafetyAmenities.includes(item)} 
                          onChange={() => handleCheckboxChange(item)}
                          className="custom-checkbox appearance-none h-4 w-4 border border-gray-300 rounded-md checked:bg-blue-500 checked:border-transparent focus:outline-none"
                        />
                        {item}
                      </label>
                    ))}
                  </div>
                </div>
              </div>
              <div className="w-full flex justify-end gap-5 items-center mt-5">
                <button
                  type="submit"
                  className="py-2 px-4 bg-blue-500 text-white rounded-[36px] w-[216px] h-[55px]"
                >
                  Save Changes
                </button>
              </div>
            </div>
          </form>
        </section>
      </div>
    </div>
  );
};

export default SafetyAmenities;
