import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { updateDeductionRate } from "@/redux/Listing/listingSlice"; // Adjust path as needed
import tick from "../../../assets/ListingProcess/tick.svg";
import hand from "../../../assets/ListingProcess/hand.svg";
import cancel from "../../../assets/ListingProcess/cancel.svg";
import Consider from "@/components/Listings/Consider";

export default function WeeklyPlan() {
  const [percentage, setPercentage] = useState(10); // Default value
  const dispatch = useDispatch();

  const handleChange = (e) => {
    const value = e.target.value;
    if (value === "" || /^\d*$/.test(value)) {
      // Allow only numeric input or empty
      setPercentage(value);
      dispatch(updateDeductionRate({ deductionRate: parseInt(value, 10) })); // Update the deduction rate for all items
    }
  };

  const [isToggled, setIsToggled] = useState(false);

  const handleToggle = () => {
    setIsToggled((prev) => !prev);
  };

  return (
    <div className="flex flex-col pb-20 bg-white">
      <div className="flex flex-col self-center w-full max-md:mt-10 max-md:max-w-full">
        <div className="mt-16 max-md:mt-10 max-md:max-w-full">
          <div className="flex gap-5 max-md:flex-col">
            <div className="flex flex-col bg-laara-secondary bg-opacity-40 rounded-md border border-neutral w-[69%] max-md:ml-0 max-md:w-full">
              <div className="flex relative flex-col grow px-10 py-12 rounded-md min-h-[293px] max-md:px-5 max-md:mt-10 max-md:max-w-full">
                <div className="flex relative gap-2.5 max-w-full w-[302px]">
                  <div
                    onClick={handleToggle}
                    className={`flex flex-col w-11 max-sm:w-20 h-8 justify-center items-start px-1 py-1 rounded-2xl cursor-pointer transition-all duration-300 
        ${isToggled ? "bg-sky-600" : "bg-gray-300"} max-md:pl-5`}
                  >
                    <div
                      className={`shrink-0 bg-white rounded-full h-[18px] w-[18px] transition-transform duration-300
         ${isToggled ? "transform translate-x-4" : "transform translate-x-0"}`}
                    />
                  </div>
                  <div className="flex-auto text-xl text-neutral-500">
                    Set up a weekly rate plan
                  </div>
                </div>
                <div className="relative mt-16 text-xl text-black max-md:mt-10 max-md:mr-2.5 max-md:max-w-full">
                  How much cheaper than the standard rate do you want to make
                  this rate plan
                </div>
                <div className="flex relative gap-2 justify-between items-center p-5 mt-3.5 text-lg whitespace-nowrap rounded-lg border border-sky-600 border-solid max-md:flex-wrap max-md:max-w-full">
                  <input
                    type="number"
                    value={percentage}
                    onChange={handleChange}
                    min="0"
                    max="100"
                    className="flex-grow text-black bg-transparent border-none outline-none text-lg"
                    placeholder="10"
                  />
                  <div className="text-neutral-500">%</div>
                </div>
              </div>
            </div>
            <Consider
              title={"You are unsure about your price?"}
              data={["Dont worry you can set your price when you're ready"]}
            />
            {/* <div className="flex flex-col ml-5 w-[31%] max-md:ml-0 max-md:w-full">
              <div className="flex flex-col px-5 py-9 mx-auto w-full text-black bg-laara-secondary bg-opacity-40 rounded-md border border-neutral max-md:mt-10">
                <div className="flex gap-5 justify-between w-full text-base font-medium">
                  <div className="flex gap-3.5">
                    <img
                      loading="lazy"
                      src={hand}
                      className="shrink-0 self-start aspect-square w-[30px]"
                    />
                    <div>You are unsure about your price?</div>
                  </div>
                  <img
                    loading="lazy"
                    src={cancel}
                    className="shrink-0 self-start w-2 aspect-square"
                  />
                </div>
                <div className="flex gap-2 self-center mt-6 text-sm">
                  <img
                    loading="lazy"
                    src={tick}
                    className="shrink-0 my-auto w-3.5 aspect-[1.56] ml-12"
                  />
                  <div>Don’t worry, you can set your price when you are ready</div>
                </div>
              </div>
            </div> */}
          </div>
        </div>
      </div>
    </div>
  );
}
