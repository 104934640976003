import React from "react";
import check from "@/assets/reservations/rescomplete.svg";
import { useSelector, useDispatch } from "react-redux";
import { closeCompleteReservation } from "@/redux/paynow/PayNowSlice";
import dayjs from "dayjs";

const CompleteReservation = ({ propertyName, checkIn, checkOut }) => {
  const dispatch = useDispatch();
  checkIn = dayjs(checkIn).format("DD MMM");

  return (
    <div className="bg-white max-w-[413px] max-h-[414px] py-12 px-2 rounded-[12px] border border-[black/12] ">
      <div className=" flex flex-col gap-7 items-center justify-center ">
        {/* ICON */}
        <img
          src={check}
          alt="complete reservation icon"
          className="h-24 w-24"
        />
        {/* TITLE */}
        <div className="text-center space-y-2">
          <h4 className="text-xl font-semibold text-[#292929]  ">
            Reservation Complete
          </h4>
          <p className="text-base text-[#6D6D6D]">
            You have successfully reserved your stay at {propertyName} from{" "}
            {checkIn} - {checkOut}
          </p>
        </div>

        {/* BUTTON */}
        <button
          onClick={() => dispatch(closeCompleteReservation())}
          className="bg-laara-primary text-white text-base text-center font-medium py-4 w-full rounded-[36px]  "
        >
          Done
        </button>
      </div>
    </div>
  );
};

export default CompleteReservation;
