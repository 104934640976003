import React from 'react'
import { Link } from 'react-router-dom';
import arrowright from "@/assets/shared/arrow-right.svg"
import sendarrow from "@/assets/support/arrow-down-01.svg"

export const startedsections = [
  {
    title: "Creating an Account",
    items: [
      {
        text: "Step by step guide on creating an account",
        link: "/guide/create-account"
      },
      {
        text: "Verification process",
        link: "/guide/verification"
      },
      {
        text: "Setting up your profile",
        link: "/guide/setup-profile"
      }
    ]
  },
  {
    title: (
      <>
        Navigating the Iaara Stays
        <br />
        Dashboard
      </>
    ),
    items: [
      {
        text: "Overview of the dashboard features",
        link: "/guide/dashboard-overview"
      },
      {
        text: "Customizing your dashboard",
        link: "/guide/customize-dashboard"
      }
    ]
  }
];

export const Gettingstarted = () => {

  return (
    <div className="w-full max-w-[1440px] h-auto min-h-screen lg:h-[988px] p-4 sm:p-6 md:p-8 lg:px-22 lg:py-14 gap-4 sm:gap-6 lg:gap-8 mx-auto ">
      <div className=" max-w-[1264px] mx-auto flex flex-col gap-[20px]">

        <div className="w-full mx-auto px-4 sm:px-6 lg:px-8">
          <div className="w-full max-w-[616px]  px-4 sm:px-0 mb-[20px] flex flex-col-2 sm:flex-row justify-between items-center py-2 sm:py-0">
            <Link to="/support" className="relative sm:mb-0 mb-2 inline-block">
              <img src={arrowright} alt="Go to destination page" className="w-6 h-6 cursor-pointer hover:opacity-80 transition-opacity"
              />
            </Link>
            <span className="text-sm sm:text-base ml-2 font-bold">FAQs</span>
          </div>

          <p className="text-lg sm:text-xl lg:text-2xl font-semibold leading-9 text-left">
            Getting started with Laara Stays
          </p>
        </div>

        <div className="w-full max-w-[1264px] min-h-[439px] mx-auto px-4 sm:px-6 lg:px-8 flex flex-col">
          {startedsections.map((section, index) => (
            <div key={index} className="w-full min-h-[187px] flex flex-col gap-6">
              <div className="mb-8">
              <h2 className="text-lg sm:text-xl lg:text-2xl font-medium leading-7 sm:leading-8 lg:leading-[30px] text-left mb-2">{section.title}</h2>
                <ul className="w-full max-w-[1264px] h-auto flex flex-col gap-4">
                  {section.items.map((item, itemIndex) => (
                                        <li key={itemIndex} className="relative pb-2 h-[40px] flex items-center justify-between border-b border-gray-200 text-[16px] font-normal leading-6 text-[#292929] text-left">
                      <Link
                        to={item.link}
                        className="flex items-center justify-between w-full text-inherit no-underline hover:text-gray-700"
                      >
                        <span>{item.text}</span>
                        <img src={sendarrow} alt="" className="w-6 h-6 cursor-pointer" />
                      </Link>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>

  )

}
