//section 1, imports
import { useState, useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faLessThan,
  faSearch,
  faLocationDot,
} from "@fortawesome/free-solid-svg-icons";
import Overlay from "@/components/HostProperties/Overlay";
import RoomDetailsTab from "@/components/HostProperties/OverlayRoomDetails";
import PropertyImages from "@/components/HostProperties/PropertyImages";
import { getPropertyById, getToken } from "@/api";
//section2, main function
const ViewProperty = () => {
  //section3, function declaration
  const { id } = useParams();
  const [property, setProperty] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [showOverlay, setShowOverlay] = useState(false);
  const [showRoomDetailsTab, setShowRoomDetailsTab] = useState(false);
  const [showAllPolicies, setShowAllPolicies] = useState(false);
  const hostId = localStorage.getItem("uid");
  const isVerified = property?.verified ?? false;
  const navigate = useNavigate();
  const token = getToken();
  console.log("Authorization Token:", token);
  //section4, api function
  useEffect(() => {
    const fetchProperty = async () => {
      try {
        const data = await getPropertyById(id);
        

        if (data && typeof data === "object") {
          if (
            data.property.propertyImages &&
            Array.isArray(data.property.propertyImages)
          ) {
            console.log("Property Images Array:", data.property.propertyImages);
            data.property.propertyImages.forEach((imageObj, index) => {
              if (imageObj && imageObj.images && imageObj.images.url) {
                console.log(`Image ${index + 1} URL:`, imageObj.images.url);
              } else {
                console.log(
                  `No image URL found in imageObj ${index + 1}:`,
                  imageObj,
                );
              }
            });
          } else {
            console.log(
              "Property Images key is missing or not an array:",
              data,
            );
          }
        } else {
          console.log("Unexpected data format:", data);
        }

        setProperty(data);
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchProperty();
  }, [id]);

  if (loading) {
    return (
      <div className="flex justify-center items-center h-screen">
        <div className="w-16 h-16 border-4 border-t-4 border-gray-200 border-t-blue-500 border-solid rounded-full animate-spin"></div>
      </div>
    );
  }
  if (error) navigate("/*");

  const openOverlay = () => setShowOverlay(true);
  const closeOverlay = () => setShowOverlay(false);

  if (!property) {
    return <div>Property not found</div>;
  }
  //section5, important functions
  const mainImage =
    Array.isArray(property.property.propertyImages) &&
    property.property.propertyImages.length > 0
      ? property.property.propertyImages[0]?.images?.url || ""
      : "";

  const additionalImages = Array.isArray(property.property.propertyImages)
    ? property.property.propertyImages
        .map((img) => img.images?.url)
        .filter((url) => url)
    : [];

  const guestVerification = property.property.guestVerificationMethod
    ? `${property.property.guestVerificationMethod} verification`
    : "No guest verification method specified";

  const toggleShowPolicies = () => {
    setShowAllPolicies(!showAllPolicies);
  };

  const combinedPolicies = [
    ...(property?.property?.propertyPolicies
      ? property.property.propertyPolicies.map((policy) => ({
          type: policy.policies?.type,
          description: policy.policies?.description,
        }))
      : []),
    ...(property?.property?.rooms
      ? property.property.rooms.flatMap(
          (room) =>
            room.roomTypes?.roomTypePolicies?.map((policy) => ({
              type: `${policy.policies?.type}`,
              description: policy.policies?.description,
            })) || [],
        )
      : []),
  ];

  const groupedAmenities = (property.property.propertyAmenities || []).reduce(
    (acc, item) => {
      const { category, name } = item.amenities;

      if (!acc[category]) {
        acc[category] = [];
      }
      acc[category].push(name);
      return acc;
    },
    {},
  );

  const groupedFeatures = (
    property.property.accessibilityFeatures || []
  ).reduce((acc, item) => {
    const { category, feature } = item.features;

    if (!acc[category]) {
      acc[category] = [];
    }
    acc[category].push(feature);
    return acc;
  }, {});

  const featureArray = Object.entries(groupedFeatures);
  const featureArrayAmenities = Object.entries(groupedAmenities);

  const capitalizeFirstLetter = (str) => {
    if (!str) return str; // Return as is if the string is empty or undefined
    return str.charAt(0).toUpperCase() + str.slice(1);
  };

  const handleRoomDetailsClick = () => {
    setShowRoomDetailsTab(true);
  };

  const closeRoomDetailsTab = () => {
    setShowRoomDetailsTab(false);
  };


  //section6, function body
  return (
    <div className="bg-base-white p-8">
      {/* section7, show all property section */}
    {/* <div className="bg-base-white p-8">
        <h1 className="text-lg font-bold mb-4">Property Details</h1>
        <pre className="bg-gray-100 p-4 rounded-lg">
          {property
            ? JSON.stringify(property, null, 2)
            : "No property data available"}
        </pre>
      </div>   */}
      {/* section8, header section */}
      <section className="top h-16 flex items-center p-2 gap-3 justify-between">
        <div className="text-lg text-gray-600 w-[700px]">
          <Link
            to={`/host-properties/${hostId}`}
            className="flex items-center gap-4 "
          >
            <FontAwesomeIcon icon={faLessThan} className="text-base" />
            <h3>My Properties</h3>
          </Link>
        </div>
        <div className="h-12 flex items-center gap-2 w-[300px] p-1 bg-base-white border border-borderColor-custom-gray rounded-lg">
          <FontAwesomeIcon
            icon={faSearch}
            className="text-base text-gray-600 ml-2"
          />
          <input
            type="search"
            placeholder="Search Properties"
            className="outline-none p-2 w-full bg-base-white text-[13px]"
          />
        </div>
        {isVerified && (
          <div className="w-[195px] text-center h-12 items-center flex justify-center">
            <button className="text-laara-primary border border-laara-primary w-full h-10 rounded-[36px]">
              Set Availability
            </button>
          </div>
        )}
      </section>
      {/* section9, propertydetails, images, verification */}
      <section className="property-info w-full flex p-2 gap-5">
        <section className="left-side w-full max-w-[952px] p-2">
          <PropertyImages
            mainImage={mainImage}
            additionalImages={additionalImages}
          />
          <div className="title w-full h-[44px] mt-2 flex justify-between p-2">
            <h4 className="text-gray-600 text-[18px] font-normal">
              {property.property.name}
            </h4>
            <div className="w-[170px]">
              <div className="flex items-center space-x-3">
                <div className="relative">
                  <div
                    className={`w-10 h-6 flex items-center rounded-full p-1 cursor-pointer ${
                      property.property.verified ? "bg-blue-500" : "bg-gray-300"
                    }`}
                    role="status"
                    aria-checked={property.property.verified}
                  >
                    <div
                      className={`w-5 h-5 bg-white rounded-full shadow-md transform transition-transform ${
                        property.property.verified
                          ? "translate-x-5"
                          : "translate-x-0"
                      }`}
                    />
                  </div>
                </div>
                <span className="text-gray-600 text-[15px]">
                  {property.property.verified ? "Published" : "Unpublished"}
                </span>
              </div>
            </div>
          </div>
          {/* section10, location, language, policies */}
          <div className="location w-full flex gap-4 p-2">
            <div className="flex items-center gap-3">
              <FontAwesomeIcon
                icon={faLocationDot}
                className="text-gray-600 mb-1"
              />
              <p className="text-gray-600 mb-1">
                {property.property.address?.city || "City not available"},{" "}
                {property.property.address?.country || "Country not available"}
              </p>
            </div>

            <div>
              <p className="text-gray-600">
                Staff Language:{" "}
                {property.property.propertyLanguages
                  ?.map((lang) => {
                    const languageName =
                      lang.language.name.charAt(0).toUpperCase() +
                      lang.language.name.slice(1).toLowerCase();
                    return languageName;
                  })
                  .join(", ") || "No languages available"}
              </p>
            </div>

          </div>
          <div className="checkIn flex gap-5 mt-3 mb-3 p-2">
            <p className="text-gray-600">
              Check In:{" "}
              {property.property.propertyPolicies?.find(
                (policy) => policy.policies.type === "Check-In",
              )?.policies.description || "Not available"}
            </p>
            <p className="text-gray-600">
              Check Out:{" "}
              {property.property.propertyPolicies?.find(
                (policy) => policy.policies.type === "Check-Out",
              )?.policies.description || "Not available"}
            </p>
          </div>

          <div className="description w-full max-w-[920px] p-2 text-gray-600 mt-4 mb-2">
            <h3 className="text-gray-700 text-[18px] font-medium mb-2">
              Description
            </h3>
            <p className="text-[16px]">{property.property.description}</p>
          </div>
          {/* section11, amenities section */}
          <div className="GeneralFacilities mt-4 p-2">
            <h3 className="text-gray-700 text-[18px] font-medium mb-2">
              General Amenities
            </h3>
            <div className="flex flex-wrap gap-2 mt-2">
              {(property.property.propertyAmenities || [])
                .filter((amenity) => amenity.amenities.category === "General")
                .map((amenity, index) => (
                  <div
                    key={index}
                    className="text-gray-600 w-[190px] h-[45px] rounded-[10px] p-2 flex items-center justify-center transition-all duration-300 text-[14px]"
                  >
                    {amenity.amenities.name}
                  </div>
                ))}
            </div>
          </div>

          <div className="accessibilityfeatures w-full max-w-[920px] p-2 text-gray-600 mt-4 mb-2 flex flex-col gap-8">
            <h3 className="text-gray-700 text-[18px] font-semibold mb-2">
              Accessibility Features
            </h3>

            {/* Group by category */}
            {featureArray.map(([category, features], index) => (
              <div key={index} className="feature-category">
                <h3 className="text-gray-700 text-[18px] font-medium mb-2">
                  {category}
                </h3>
                <ul className="list-disc list-inside">
                  {features.map((feature, featureIndex) => (
                    <li key={featureIndex} className="text-[16px]">
                      {feature}
                    </li>
                  ))}
                </ul>
              </div>
            ))}
          </div>

          <div className="w-full max-w-[920px] p-2 text-gray-600 mt-4 mb-2 flex flex-col gap-8">
            <h3 className="text-gray-700 text-[18px] font-semibold mb-2">
              {property.property.name} Hosting Rules
            </h3>

            <div className="policies">
              {/* Display only the first policy if showAllPolicies is false */}
              {combinedPolicies
                .slice(0, showAllPolicies ? combinedPolicies.length : 1)
                .map((policy, index) => (
                  <div key={index} className="policy mb-3">
                    <h4 className="text-gray-700 text-[16px] font-medium mb-1">
                      {policy.type}
                    </h4>
                    <p className="text-gray-600">{policy.description}</p>
                  </div>
                ))}

              {/* View More or View Less button */}
              {combinedPolicies.length > 1 && (
                <button
                  className="text-blue-500 mt-2"
                  onClick={toggleShowPolicies}
                >
                  {showAllPolicies ? "View Less" : "View More"}
                </button>
              )}
            </div>
          </div>
          {/* section12, buttons */}
          <div className="flex gap-3">
            <button className="h-10 w-[195px] border border-error text-error rounded-[36px] p-2">
              Delete Property
            </button>
            {isVerified && (
              <button className="h-10 w-[195px] bg-blue-500 text-white rounded-[36px] p-2">
                Create Promotions
              </button>
            )}
            <button
              onClick={handleRoomDetailsClick}
              className="text-laara-primary border border-laara-primary w-[195px] h-10 rounded-[36px]"
            >
              Rooms Details
            </button>
            {showRoomDetailsTab && (
              <RoomDetailsTab
                rooms={property.property.rooms}
                closeRoomDetailsTab={closeRoomDetailsTab}
              />
            )}
          </div>
        </section>
        {/* section13, right side, edit property section */}
        <section className="right-side max-w-[380px] flex flex-col p-2 gap-4 w-full">
          <div className="p-4">
            <button
              className="h-12 w-full bg-laara-primary text-white rounded-[36px]"
              onClick={openOverlay}
            >
              Edit Property
            </button>
          </div>

          {showOverlay && <Overlay id={id} closeOverlay={closeOverlay} />}

          <div className="property-details w-full">
            <div className="w-full p-2 text-gray-600">
              <h3 className="text-gray-700 text-[18px] font-medium mb-2">
                Verification
              </h3>

              <ul className="list-disc list-inside w-full">
                <li className="text-[16px]">{guestVerification}</li>
              </ul>
            </div>

            {/* section14, extra Amenities Section */}
            <div className="w-full p-2 text-gray-600 mt-4 mb-2">
              <h3 className="text-gray-700 text-[18px] font-semibold mb-4">
                Other Property Amenities
              </h3>
              <div className="w-full p-2 text-gray-600 mt-4 mb-2">
                {/* Group by category */}
                {featureArrayAmenities.length > 0 ? (
                  featureArrayAmenities.map(([category, features], index) => (
                    <div key={index} className="feature-category mb-2">
                      <h3 className="text-gray-700 text-[18px] font-medium mb-3">
                        {category}
                      </h3>
                      <ul className="list-disc list-inside m-1">
                        {features.map((feature, featureIndex) => (
                          <li key={featureIndex} className="text-[16px] m-1">
                            {capitalizeFirstLetter(feature)}
                          </li>
                        ))}
                      </ul>
                    </div>
                  ))
                ) : (
                  <div>No amenities available</div>
                )}
              </div>
            </div>
          </div>
        </section>
      </section>
    </div>
  );
};

export default ViewProperty;
