import React, { useState } from 'react';
import { IoChevronDownOutline, IoChevronUpOutline } from "react-icons/io5";

// FAQItem Component
const FAQItem = ({ question, answer }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className="relative">
      <button
        onClick={() => setIsOpen(!isOpen)}
        className="flex items-center justify-between w-full p-4 text-left bg-gray-100 hover:bg-gray-200 focus:outline-none"
      >
        <span className="text-md font-medium text-gray-800">{question}</span>
        {isOpen ? (
          <IoChevronUpOutline className="w-5 h-5 text-gray-600" />
        ) : (
          <IoChevronDownOutline className="w-5 h-5 text-gray-600" />
        )}
      </button>
      {isOpen && (
        <ul className="pl-8 list-disc text-gray-600">
          {answer.split('\n').map((line, index) => (
            <li key={index} className="py-1">{line}</li>
          ))}
        </ul>
      )}
    </div>
  );
};

// FAQList Component
const FAQList = ({ faqs }) => {
  return (
    <div className="p-2">
      <h2 className="text-lg font-semibold mb-4">Frequently Asked Question On Deluxe Palace</h2>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
        {faqs.map((faq, index) => (
          <FAQItem key={index} question={faq.question} answer={faq.answer} />
        ))}
      </div>
    </div>
  );
};

export default FAQList;
