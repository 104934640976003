import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import arrowLeft from "@/assets/shared/arrowleft.svg";
import Reservation from "@/components/ReservationPage/Reservation";
import axios from "axios";
import { headers, baseURL } from "@/constants/apiconfig";
import { useSelector } from "react-redux";
import SkeletonCard from "@/components/ReservationPage/Skeleton/SkeletonCard";
import { useQuery } from "@tanstack/react-query";

const Reservations = () => {
  const navigate = useNavigate();
  const [filteredReservation, setFilteredReservation] = useState([]);
  const [activeButton, setActiveButton] = useState("all");

  const token = useSelector((state) => state?.user?.accessToken);

  const fetchUserReservations = async () => {
    const res = await axios.get(`${baseURL}/users/reservations`, {
      headers: headers(token),
    });
    return res.data;
  };

  const reservationsQuery = useQuery({
    queryKey: ["reservations"],
    queryFn: fetchUserReservations,
    retry: 0,
  });

  const { data, isLoading, isError, isSuccess, error } = reservationsQuery;

  useEffect(() => {
    if (data) {
      setFilteredReservation(data.reservations);
    }
  }, [data]);

  const filterReservation = (status) => {
    setActiveButton(status);
    if (status === "all") {
      setFilteredReservation(data.reservations);
    } else {
      status = status.toUpperCase();

      const selectedReservations = data?.reservations?.filter(
        (reservation) => reservation?.status === status
      );
      setFilteredReservation(selectedReservations);
    }
  };

  const handleActiveBtn = (status) => {
    return activeButton === status
      ? "hidden md:flex px-3.5 bg-laara-secondary border border-laara-primary text-laara-primary rounded-[35px]"
      : "hidden md:flex px-3.5  ";
  };

  return (
    <section className="xl:max-w-[1440px] w-full mx-auto px-5 py-5 md:px-10 ">
      <section className="max-w-[1264px] w-full mx-auto space-y-7 ">
        {/* TOP SECTION */}
        <div className="text-[#626262] flex-col xl:flex xl:flex-row xl:items-center gap-3   ">
          <h3 className="font-medium text-xl xl:text-2xl ">My Reservations</h3>

          <img
            src={arrowLeft}
            onClick={() => navigate(-1)}
            className="hidden xl:flex size-[30px] cursor-pointer"
          />

          {/* {mobile screens} */}
          <div className="flex items-center gap-4 mt-3.5 lg:hidden">
            <img
              src={arrowLeft}
              onClick={() => navigate(-1)}
              className=" xl:hidden size-[30px]"
            />
            <select
              className="lg:hidden flex text-center py-1 text-laara-primary rounded-3xl border border-laara-primary bg-laara-secondary outline-none"
              onChange={(e) => filterReservation(e.target.value)}
            >
              <option value="all">All</option>
              <option value="pending_payment">Pending</option>
              <option value="upcoming">Upcoming</option>
              <option value="checked_in">Checked In</option>
              <option value="awaiting_review">Awaiting Review</option>
              <option value="completed">Completed</option>
            </select>
          </div>
          <div className="hidden lg:mt-3.5 lg:flex lg:items-center lg:gap-1 xl:mt-0">
            <button
              className={`${handleActiveBtn("all")} cursor-pointer py-2 px-7`}
              onClick={() => filterReservation("all")}
            >
              All
            </button>
            <button
              className={`${handleActiveBtn(
                "pending_payment"
              )} cursor-pointer py-2`}
              onClick={() => filterReservation("pending_payment")}
            >
              Pending
            </button>
            <button
              className={`${handleActiveBtn("upcoming")} cursor-pointer py-2`}
              onClick={() => filterReservation("upcoming")}
            >
              Upcoming
            </button>
            <button
              className={`${handleActiveBtn("checked_in")} cursor-pointer py-2`}
              onClick={() => filterReservation("checked_in")}
            >
              Checked In
            </button>
            <button
              className={`${handleActiveBtn(
                "awaiting_review"
              )} cursor-pointer py-2`}
              onClick={() => filterReservation("awaiting_review")}
            >
              Awaiting Review
            </button>
            <button
              className={`${handleActiveBtn("completed")} cursor-pointer py-2`}
              onClick={() => filterReservation("completed")}
            >
              Completed
            </button>
          </div>
        </div>

        {/* LOADING SCREEN */}
        {isLoading && (
          <>
            <SkeletonCard />
            <SkeletonCard />
            <SkeletonCard />
          </>
        )}

        {/* BODY */}
        <section className="flex flex-col gap-10 md:gap-7 w-full lg:mx-auto xl:max-w-[1264px]  ">
          {data?.reservations?.length < 1 && (
            <div className="min-h-[50dvh] ">
              <h4>Reservations is empty</h4>
            </div>
          )}
          {filteredReservation?.map((reservation) => (
            <Reservation key={reservation.ref} reservations={reservation} />
          ))}
        </section>
      </section>
    </section>
  );
};

export default Reservations;
