import React, { useState } from 'react';
import { FaLessThan } from 'react-icons/fa';
import { useLocation } from 'react-router-dom';
import { FaShareAlt, FaHeart, } from 'react-icons/fa';

const PropertyImages = () => {
    const location = useLocation();
    const { images } = location.state || {}; // Extract images from state or default to an empty object
    const [loved, setLoved ] = useState();
    const handlelove = () => {
        setLoved(!loved);
      };
    // Check if images is undefined or empty
    if (!images || images.length === 0) {
        return <p>No images available to display.</p>;
    }

    return (
        <div className=''>
            <div className='flex items-center p-2 lg:p-16 mt-2 w-full'>
            <h3 className="lg:text-2xl text-base flex items-center font-normal gap-2 "> <FaLessThan /> Property Images</h3>
            <div className='flex flex-row justify-end  gap-4 p-4 flex-1'>
                <button
                    className="flex items-center text-gray-500 hover:text-blue-700 lg:hover:scale-125 duration-300"
                    aria-label="Share"
                >
                    <FaShareAlt className="w-6 h-6 mr-2" />
                </button>
                <button
                    className={`flex items-center text-gray-500 ${loved ? "text-red-700" : null} duration-300 lg:hover:scale-125`}
                    aria-label="Love"
                    onClick={handlelove}
                >
                    <FaHeart className="w-6 h-6 mr-2" />
                </button> 
            </div>        
            </div>
            <div className='p-4 flex flex-col items-center justify-center mx-auto gap-4 lg:w-4/5 w-full'>
                {/* Row 1 */}
                <div className="grid grid-cols-1 gap-2 w-full">
                    {images.slice(0, 1).map((image, index) => (
                        <img
                            key={index}
                            src={image}
                            alt={`Image ${index + 1}`}
                            className="w-full object-cover rounded-lg transition-opacity duration-300 hover:opacity-90"
                        />
                    ))}
                </div>
                
                {/* Row 2 */}
                <div className="grid grid-cols-2 gap-2 w-full">
                    {images.slice(1, 3).map((image, index) => (
                        <img
                            key={index}
                            src={image}
                            alt={`Image ${index + 2}`}
                            className="w-full object-cover rounded-lg transition-opacity duration-300 hover:opacity-90"
                        />
                    ))}
                </div>

                {/* Row 3 */}
                <div className="grid grid-cols-2 gap-2 w-full">
                    {images.slice(3, 5).map((image, index) => (
                        <img
                            key={index}
                            src={image}
                            alt={`Image ${index + 4}`}
                            className="w-full object-cover rounded-lg transition-opacity duration-300 hover:opacity-90"
                        />
                    ))}
                </div>

                {/* Row 4 (Special Case) */}
                <div className="grid grid-cols-2 gap-2 w-full">
                    <div className="grid grid-rows-1 gap-2 w-full">
                        {images.slice(5, 6).map((image, index) => (
                            <img
                                key={index}
                                src={image}
                                alt={`Image ${index + 6}`}
                                className="w-full object-cover shadow-lg rounded-lg transition-opacity duration-300 hover:opacity-90"
                            />
                        ))}
                    </div>
                    <div className="flex flex-col gap-2 w-full">
                        <div className="flex flex-grow">
                            {images.slice(6, 7).map((image, index) => (
                                <img
                                    key={index}
                                    src={image}
                                    alt={`Image ${index + 7}`}
                                    className="flex-grow w-full  object-cover rounded-lg shadow-lg transition-opacity duration-300 hover:opacity-90"
                                />
                            ))}
                        </div>
                    </div>
                </div>

                {/* Additional Rows */}
                <div className="grid grid-cols-2 gap-2 w-full">
                    {images.slice(7, 9).map((image, index) => (
                        <img
                            key={index}
                            src={image}
                            alt={`Image ${index + 8}`}
                            className="w-full object-cover rounded-lg transition-opacity duration-300 hover:opacity-90"
                        />
                    ))}
                </div>
                <div className="grid grid-cols-1 gap-2 w-full">
                    {images.slice(9, 10).map((image, index) => (
                        <img
                            key={index}
                            src={image}
                            alt={`Image ${index + 10}`}
                            className="w-full object-cover rounded-lg transition-opacity duration-300 hover:opacity-90"
                        />
                    ))}
                </div>
                <div className="grid grid-cols-2 gap-2 w-full">
                    {images.slice(10, 13).map((image, index) => (
                        <img
                            key={index}
                            src={image}
                            alt={`Image ${index + 13}`}
                            className="w-full object-cover rounded-lg transition-opacity duration-300 hover:opacity-90"
                        />
                    ))}
                </div>
            </div>
        </div>
    );
};

export default PropertyImages;
