import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { setAccessibility } from "@/redux/Listing/listingSlice";
import { Checkbox } from "@/components/ui/checkbox";

const AccessibilityFeatures = () => {
  const dispatch = useDispatch();
  const selectedAccessibility = useSelector(
    (state) => state.hostListing.formData?.accessibilityFeatures || []
  );

  const generalAccessibility = {
    general: [
      "Entire unit located on the ground floor ",
      "Entire unit wheelchair accessible",
      "Upper floors accesssible by elevator",
    ],
    parking: ["Disabled parking spot"],
    entrance: ["Step-free guest entrance", "Wide guest entrance"],
    bedroom: ["Step-free bedroom entrance", "Wide doorway to guest bedroom"],
    bathroom: [
      "Step-free bathroom access",
      "Wide doorway to guest bathroom",
      "Accessible-height toilet",
      "Roll-in shower with chair",
      "Bathtub with shower chair",
      "Fixed grab bars for shower, toilet, and bath",
    ],
    interior: ["Step-free access within the listing", "Wide hallways"],
    commonAreas: ["Accessible-height bed", "Lift access"],
    kitchen: ["Step-free kitchen access"],
    communication: [
      "Visual and audible alarms",
      "Accessible communication features for guests with hearing impairement",
    ],
  };

  const handleToggleAccessibility = (isChecked, category, accessibility) => {
    const isSelected = selectedAccessibility.some(
      (a) => a.feature === accessibility && a.category === category
    );

    const updatedAccessibilities = isSelected
      ? selectedAccessibility.filter(
          (a) => !(a.feature === accessibility && a.category === category)
        )
      : [
          ...selectedAccessibility,
          {
            feature: accessibility,
            category,
          },
        ];

    dispatch(setAccessibility(updatedAccessibilities));
  };

  const renderCheckboxes = (category, items) => (
    <div key={category} className="space-y-2">
      <p className="text-lg font-medium capitalize">{category}</p>
      <div className="flex flex-col gap-2">
        {items.map((item) => (
          <div key={item} className="flex items-center gap-3">
            <Checkbox
              id={`${category}-${item}`}
              name={item}
              className="size-6 rounded-lg"
              checked={selectedAccessibility?.some(
                (a) => a.feature === item && a.category === category
              )}
              onCheckedChange={(isChecked) =>
                handleToggleAccessibility(isChecked, category, item)
              }
            />
            <label
              htmlFor={`${category}-${item}`}
              className={`capitalize text-base ${
                selectedAccessibility?.some(
                  (a) => a.feature === item && a.category === category
                ) && "text-laara-primary"
              }`}
            >
              {item}
            </label>
          </div>
        ))}
      </div>
    </div>
  );

  return (
    <form>
      <div className="space-y-5">
        <h4 className=" font-medium text-xl ">
          What accessibility features are available at your property?
        </h4>
        <p className="text-lg text-[#626262] ">
          Please select accessibility features available at their property to
          accommodate individuals with disabilities.
        </p>
      </div>
      <div className="mt-5 border border-[#A3A3A3] rounded-[6px] py-6 px-4 md:px-[30px] max-w-[733px] w-full ">
        <div className="space-y-4">
          {renderCheckboxes("General", generalAccessibility.general)}
          {renderCheckboxes("Parking", generalAccessibility.parking)}
          {renderCheckboxes("Entrance", generalAccessibility.entrance)}
          {renderCheckboxes("Bedroom", generalAccessibility.bedroom)}
          {renderCheckboxes("Bathroom", generalAccessibility.bathroom)}
          {renderCheckboxes("Interior", generalAccessibility.interior)}
          {renderCheckboxes("Common Areas", generalAccessibility.commonAreas)}
          {renderCheckboxes("Kitchen", generalAccessibility.kitchen)}
          {renderCheckboxes(
            "Communication",
            generalAccessibility.communication
          )}
        </div>
      </div>
    </form>
  );
};

export default AccessibilityFeatures;
