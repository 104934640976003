
import { useLocation } from "react-router-dom";
import Navbar from "@/components/shared/Navbar/Navbar";
import Footer from "@/components/shared/Footer/Footer";

const Layout = ({ children }) => {
  const location = useLocation();
  const hideNavBarAndFooter = ["/login", "/register", "/resetPassword", "/newPassword", "/forgotPassword", "/OTPReset", "/reset","/landingpage","/hostlogin","/hostregister","/listproperty","/pricing","/addphotos","/admin"].includes(
    location.pathname
  );
  return (
    <>
      {!hideNavBarAndFooter && <Navbar />}
      <main>{children}</main>
      {!hideNavBarAndFooter && <Footer />}
    </>
  );
};

export default Layout;
