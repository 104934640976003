import React from 'react';
import LeftArrow from "@/assets/message/Left.svg"
import Star from "@/assets/message/Star.svg"
import EditButton from "@/assets/message/Edit.svg"
import PinButton from "@/assets/message/Pin.svg"

export const MessageList = ({ sender, content, senderImage }) => {
  return (
    <div className={`flex ${sender === 'me' ? 'justify-end' : 'justify-start'} mb-4`}>
      <div className="max-w-full flex">
        {sender === 'me' && (
          <div className="flex items-center space-x-3 mr-4 flex-shrink-0">
            <div className="w-2 h-2 bg-black rounded-full"></div>
            <div className="w-2 h-2 bg-black rounded-full"></div>
            <div className="w-2 h-2 bg-black rounded-full"></div>
          </div>
        )}

        <div className={`max-w-[652px] w-full ${sender === 'me' ? 'ml-auto' : 'mr-auto'}`}>
          <div className="flex justify-center">
            <div className="relative flex">
              <div className="absolute top-[-8px] left-0 flex space-x-3">
                <button className="w-5 h-5 bg-gray-200 rounded-full flex items-center justify-center text-blue-500 hover:bg-gray-300 transition-colors">
                  <img src={LeftArrow} alt="Back" className="h-3 w-3" />
                </button>
                <button className="w-5 h-5 bg-gray-200 rounded-full flex items-center justify-center text-blue-500 hover:bg-gray-300 transition-colors">
                  <img src={Star} alt="Star" className="h-3 w-3" />
                </button>
                <button className="w-5 h-5 bg-gray-200 rounded-full flex items-center justify-center text-blue-500 hover:bg-gray-300 transition-colors">
                  <img src={EditButton} alt="Edit" className="h-3 w-3" />
                </button>
                <button className="w-5 h-5 bg-gray-200 rounded-full flex items-center justify-center text-blue-500 hover:bg-gray-300 transition-colors">
                  <img src={PinButton} alt="Pin" className="h-3 w-3" />
                </button>
              </div>
            </div>
          </div>

          <div className={`p-3 ${sender === 'me' ? 'rounded-tr-lg rounded-bl-3xl rounded-br-lg bg-[#449FFB] text-white' : 'rounded-tl-lg rounded-bl-lg rounded-br-3xl bg-[#E8FDFF]'}`}>
            <p>{content}</p>
          </div>

          <div className="flex items-center mt-1">
            <img src={senderImage} className="w-[23px] h-[23px] rounded-full" alt="Profile" />
            <p className={`text-xs ml-2 ${sender === 'me' ? 'text-[#0466C8]' : ''}`}>
              {sender === 'me' ? 'Message Sent 2:30pm' : 'Message Seen 2:30pm'}
            </p>
          </div>
        </div>

        {sender !== 'me' && (
          <div className="flex items-center space-x-3 ml-4 flex-shrink-0">
            <div className="w-1 h-1 bg-black rounded-full"></div>
            <div className="w-1 h-1 bg-black rounded-full"></div>
            <div className="w-1 h-1 bg-black rounded-full"></div>
          </div>
        )}
      </div>
    </div>
  );
};