import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { setFormData } from "@/redux/Listing/listingSlice";
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectLabel,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import plus from "@/assets/shared/blue-plus.svg";
import minus from "@/assets/shared/blue-minus.svg";
import Bed from "@/assets/ListingProcess/Bed.svg";

const RoomDetails3 = ({ category }) => {
  const dispatch = useDispatch();
  const selectedRoomType = useSelector(
    (state) => state.hostListing.formData.roomType
  );
  const { maxGuests, bedTypes, numberOfBathrooms } = selectedRoomType;

  const [beds, setBeds] = useState([
    { name: "Single", description: "single bed: 90cm - 130cm", quantity: 0 },
    { name: "Double", description: "double bed: 131cm - 150cm", quantity: 0 },
    { name: "Large", description: "large bed: 151cm - 180cm", quantity: 0 },
    {
      name: "ExtraLarge",
      description: "extra large bed: 181cm - 210cm",
      quantity: 0,
    },
  ]);

  const roomTypes = [
    { value: "standard-single", label: "Standard Single Room" },
    { value: "executive-single", label: "Executive Single Room" },
    { value: "deluxe-single", label: "Deluxe Single Room" },
    { value: "standard-double", label: "Standard Double Room" },
    { value: "executive-double", label: "Executive Double Room" },
    { value: "deluxe-double", label: "Deluxe Double Room" },
    { value: "standard-twin", label: "Standard Twin Room" },
    { value: "executive-twin", label: "Executive Twin Room" },
    { value: "deluxe-twin", label: "Deluxe Twin Room" },
    { value: "standard-family", label: "Standard Family Room" },
    { value: "executive-family", label: "Executive Family Room" },
    { value: "deluxe-family", label: "Deluxe Family Room" },
  ];

  const vacatioinRoomTypes = [
    { value: "entire home", label: "Entire Home" },
    { value: "shared room", label: "Shared Room" },
    { value: "cabin", label: "Cabin" },
    { value: "chalet", label: "Chalet" },
    { value: "cottage", label: "Cottage" },
    { value: "guest suit", label: "Guest Suit" },
    { value: "hostel", label: "Hostel" },
    { value: "loft", label: "Loft" },
    { value: "resort", label: "Resort" },
    { value: "townhouse", label: "Townhouse" },
    { value: "villa", label: "Villa" },
    { value: "treehouse", label: "Treehouse" },
    { value: "yurt", label: "Yurt" },
    { value: "campsite", label: "Campsite" },
    { value: "tiny house", label: "Tiny House" },
  ];

  const handleRoomTypeChange = (name, value) => {
    console.log(name, value);
    dispatch(setFormData({ roomType: { ...selectedRoomType, [name]: value } }));
  };

  const handleMaxGuestsChange = (val) => {
    dispatch(
      setFormData({ roomType: { ...selectedRoomType, maxGuests: val } })
    );
  };

  const handleMaxBathroomsChange = (val) => {
    val = parseInt(val);
    dispatch(
      setFormData({ roomType: { ...selectedRoomType, numberOfBathrooms: val } })
    );
  };

  const handleBedTypes = (type, change) => {
    const updatedBeds = beds.map((bed) =>
      bed.name === type
        ? { ...bed, quantity: Math.max(0, bed.quantity + change) }
        : bed
    );
    setBeds(updatedBeds);

    const filteredBeds = updatedBeds.filter((bed) => bed.quantity > 0);
    dispatch(
      setFormData({ roomType: { ...selectedRoomType, bedTypes: filteredBeds } })
    );
  };

  const handleSmoking = (e) => {
    const { name, value, checked } = e.target;
    console.log(checked, name, value);

    const smokingPolicy = {
      type: "Smoking",
      description:
        name === "Smoking" && value === "yes"
          ? "Smoking is allowed in the property"
          : "Smoking is not allowed in the property",
      isMandatory: true,
    };

    const childrenPolicy = {
      type: "Children",
      description:
        name === "Children" && value === "yes"
          ? "Children are allowed in the property"
          : value === "no" && "Children are not allowed in the property",
      isMandatory: true,
    };

    const cribsPolicy = {
      type: "Cribs",
      description:
        name === "Cribs" && value === "yes"
          ? "Cribs are offered in the property"
          : "Cribs are not offered in the property",
      isMandatory: true,
    };

    // Start with the existing policies and update only the relevant one
    let updatedPolicies = selectedRoomType.policies || [];

    switch (name) {
      case "Smoking":
        updatedPolicies = updatedPolicies.filter(
          (policy) => policy.type !== "Smoking"
        );
        updatedPolicies.push(smokingPolicy);
        break;
      case "Children":
        updatedPolicies = updatedPolicies.filter(
          (policy) => policy.type !== "Children"
        );
        updatedPolicies.push(childrenPolicy);
        break;
      case "Cribs":
        updatedPolicies = updatedPolicies.filter(
          (policy) => policy.type !== "Cribs"
        );
        updatedPolicies.push(cribsPolicy);
        break;
      default:
        break;
    }

    dispatch(
      setFormData({
        roomType: {
          ...selectedRoomType,
          policies: updatedPolicies,
        },
      })
    );
  };

  return (
    <div>
      <form className="relative space-y-6">
        <div className="w-full max-w-[733px] p-4 bg-laara-secondary border rounded-lg">
          <div className="space-y-6 max-w-[653px]  ">
            <div className="space-y-2">
              <label htmlFor="roomType" className="block font-medium text-lg">
                Select room type
              </label>

              <Select
                id="roomType"
                name="roomType"
                value={selectedRoomType.name}
                // LEAVE NAME AS IT IS
                onValueChange={(value) => handleRoomTypeChange("name", value)}
              >
                <SelectTrigger className="p-2 rounded-[8px] bg-white border border-[#6D6D6D] md:max-w-[70%] ">
                  <SelectValue placeholder="Select room type" />
                </SelectTrigger>
                <SelectContent>
                  <SelectGroup>
                    <SelectLabel>Check in from</SelectLabel>
                    {category === "VACATION_RENTAL"
                      ? vacatioinRoomTypes.map((item) => (
                          <SelectItem key={item.value} value={item.value}>
                            {item.label}
                          </SelectItem>
                        ))
                      : roomTypes.map((item) => (
                          <SelectItem key={item.value} value={item.value}>
                            {item.label}
                          </SelectItem>
                        ))}
                  </SelectGroup>
                </SelectContent>
              </Select>
            </div>

            <div className="space-y-2">
              <label
                htmlFor="numberOfRooms"
                className="block font-medium text-base"
              >
                Number of rooms you have of this type
              </label>
              <input
                id="numberOfRooms"
                type="number"
                name="numRooms"
                value={selectedRoomType.numRooms}
                onChange={(e) =>
                  handleRoomTypeChange("numRooms", parseInt(e.target.value))
                }
                className="p-2 w-full md:max-w-[263px] text-base border border-gray-300 rounded-md focus:outline-none"
                min="1"
              />

              <label
                htmlFor="roomDescription"
                className="block font-medium text-[14px] leading-[20px] sm:text-[16px] sm:leading-[24px] md:text-[20px] md:leading-[30px]"
              >
                Room Description
              </label>
              <input
                id="roomDescription"
                type="text"
                name="description"
                value={selectedRoomType.description}
                onChange={handleRoomTypeChange}
                className=" p-2 text-base border border-gray-300 rounded-md focus:outline-none"
              />
            </div>
          </div>
        </div>

        {/* BEDS */}

        <div className="w-full max-w-[733px] p-4 bg-laara-secondary border  rounded-lg">
          <div className="space-y-4">
            <label className="block font-semibold text-base">
              Type of beds available in this room
            </label>
            {beds.map((bed) => (
              <div
                key={bed.name}
                className="flex md:flex-wrap items-start justify-start gap-3 md:gap-8 w-full max-w-[543px]"
              >
                {/* BED ICON */}
                <div className="flex items-start w-[162px] md:w-20 mb-2 sm:mb-0 ">
                  <img src={Bed} alt="bed icon" className="w-[62px] h-[46px]" />
                </div>

                {/* BED DESCRIPTION */}
                <div className="w-full md:w-[190px] mb-2 sm:mb-0  ">
                  <span
                    className={`text-sm sm:text-base ${
                      bed.quantity > 0 ? "font-bold" : ""
                    }`}
                  >
                    {bed.name} bed(s)
                  </span>
                  <br />
                  <span className="text-[12px] sm:text-sm text-[#6D6D6D] ">
                    {bed.description}
                  </span>
                </div>
                {/* BED BUTTONS */}
                <div className="flex items-center justify-between border border-[#6D6D6D] rounded-lg">
                  <button
                    type="button"
                    name="bedTypes"
                    onClick={() => {
                      handleBedTypes(bed.name, -1);
                    }}
                    className="w-10 h-10 flex items-center justify-center"
                  >
                    <img src={minus} alt="negative" className="" />
                  </button>
                  <span className="w-8 text-center text-base">
                    {bed.quantity}
                  </span>
                  <button
                    type="button"
                    name="bedTypes"
                    onClick={() => {
                      handleBedTypes(bed.name, 1);
                    }}
                    className="w-10 h-10 flex items-center justify-center"
                  >
                    <img
                      src={plus}
                      alt="negative"
                      className="w-[15px] h-[15px] "
                    />
                  </button>
                </div>
              </div>
            ))}
          </div>
        </div>

        {/* MAX GUESTS */}
        <div className="w-full max-w-[733px] p-4 bg-laara-secondary border rounded-lg ">
          <div>
            <label className="block mb-2 font-normal text-lg">
              Maximum number of guests who can stay in this room
            </label>

            <div className="flex justify-start">
              <div className="flex items-center justify-between  border border-[#6D6D6D] rounded-lg">
                <button
                  type="button"
                  className="size-10 flex items-center justify-center"
                  onClick={() =>
                    handleMaxGuestsChange(Math.max(1, maxGuests - 1))
                  }
                >
                  <img
                    src={minus}
                    alt="negative"
                    className="w-[15px] h-[3px] "
                  />
                </button>
                <span className="w-8 text-center">{maxGuests}</span>
                <button
                  type="button"
                  name="maxGuests"
                  className="size-10 flex items-center justify-center "
                  onClick={() => handleMaxGuestsChange(maxGuests + 1)}
                >
                  <img src={plus} alt="add" className="size-[15px] " />
                </button>
              </div>
            </div>
          </div>

          {/*  NUMBER OF BATHROOMS */}
          {category === "VACATION_RENTAL" && (
            <div className="mt-4">
              <label className="block mb-2 font-normal text-lg">
                How many bathrooms are in this property?
              </label>

              <div className="flex justify-start">
                <div className="flex items-center justify-between  border border-[#6D6D6D] rounded-lg">
                  <button
                    type="button"
                    className="size-10 flex items-center justify-center"
                    onClick={() =>
                      handleMaxBathroomsChange(
                        Math.max(1, numberOfBathrooms - 1)
                      )
                    }
                  >
                    <img
                      src={minus}
                      alt="negative"
                      className="w-[15px] h-[3px] "
                    />
                  </button>
                  <span className="w-8 text-center">{numberOfBathrooms}</span>
                  <button
                    type="button"
                    name="maxGuests"
                    className="size-10 flex items-center justify-center "
                    onClick={() =>
                      handleMaxBathroomsChange(numberOfBathrooms + 1)
                    }
                  >
                    <img src={plus} alt="add" className="size-[15px] " />
                  </button>
                </div>
              </div>
            </div>
          )}
        </div>

        {/* POLICIES */}
        <div className="w-full max-w-[733px] p-4 bg-laara-secondary border rounded-lg">
          <div>
            <label className="block mb-2 font-normal text-lg">
              Do you allow smoking in this room?
            </label>
            <div className="flex flex-col">
              <label className="inline-flex items-center mb-2">
                <input
                  type="radio"
                  name="Smoking"
                  value="yes"
                  onChange={handleSmoking}
                  className="form-radio"
                />
                <span className="ml-2 text-[14px] sm:text-[16px]">Yes</span>
              </label>
              <label className="inline-flex items-center mb-2">
                <input
                  type="radio"
                  name="Smoking"
                  value="no"
                  onChange={handleSmoking}
                  className="form-radio"
                />
                <span className="ml-2 text-[14px] sm:text-[16px]">No</span>
              </label>
            </div>
          </div>
        </div>

        {category === "VACATION_RENTAL" && (
          <div className="w-full max-w-[733px] p-4 bg-laara-secondary border rounded-lg flex">
            <div>
              <label className="block mb-2 font-normal text-base">
                Do you allow children?
              </label>
              <div className="flex items-center space-x-4 mb-8">
                <label className="inline-flex items-center">
                  <input
                    type="radio"
                    name="Children"
                    value="yes"
                    onChange={handleSmoking}
                    // checked={allowChildren}
                    // onChange={() => setAllowChildren(true)}
                    className="form-radio"
                  />
                  <span className="ml-2 text-[14px] sm:text-[16px]">Yes</span>
                </label>
                <label className="inline-flex items-center">
                  <input
                    type="radio"
                    name="Children"
                    value="no"
                    onChange={handleSmoking}
                    // checked={!allowChildren}
                    // onChange={() => setAllowChildren(false)}
                    className="form-radio"
                  />
                  <span className="ml-2 text-[14px] sm:text-[16px]">No</span>
                </label>
              </div>

              <label className="block mb-2 font-normal text-base">
                Do you offer cribs?
              </label>
              <p className="text-sm text-[#6D6D6D] ">
                Cribs sleep most infants 0-3 years old and are available
                <br className="" /> to guest on request
              </p>
              <div className="flex items-center space-x-4">
                <label className="inline-flex items-center mb-2">
                  <input
                    type="radio"
                    name="Cribs"
                    value="yes"
                    onChange={handleSmoking}
                    // checked={allowCribs}
                    // onChange={() => setAllowCribs(true)}
                    className="form-radio"
                  />
                  <span className="ml-2 text-[14px] sm:text-[16px]">Yes</span>
                </label>
                <label className="inline-flex items-center mb-2">
                  <input
                    type="radio"
                    name="Cribs"
                    value="no"
                    onChange={handleSmoking}
                    // checked={!allowCribs}
                    // onChange={() => setAllowCribs(false)}
                    className="form-radio"
                  />
                  <span className="ml-2 text-[14px] sm:text-[16px]">No</span>
                </label>
              </div>
            </div>
          </div>
        )}

        <div
          className={` ${
            category === "VACATION_RENTAL" ? "hidden" : ""
          } w-full max-w-[733px] p-4 bg-laara-secondary border rounded-lg`}
        >
          <div>
            <label className="block mb-2 font-normal text-lg">
              How big is the room?
            </label>
            <label className="block font-normal text-base">
              Room size - optional
            </label>

            <div className="relative w-full sm:w-[317px]">
              <input
                type="number"
                value={parseInt(selectedRoomType.typeSize)}
                name="typeSize"
                onChange={(e) =>
                  handleRoomTypeChange("typeSize", parseInt(e.target.value))
                }
                className="w-full p-2 border rounded-lg pl-4 text-[14px] sm:text-[16px] focus:outline-none"
              />
              <span className="absolute top-1/2 right-3 transform -translate-y-1/2 text-black text-[14px] sm:text-[16px]">
                Sq.ft
              </span>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};

export default RoomDetails3;
