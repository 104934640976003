import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { IoCloseSharp } from "react-icons/io5";
import { Link } from "react-router-dom";
import { signInWithEmailAndPassword, signInWithPopup } from "firebase/auth";

import * as z from "zod"; // Import Zod for schema validation
import ImageLogo from "../../assets/LoginPage/GroupImage.svg";
import logo from "@/assets/shared/Group.svg";
import GoogleLogo from "../../assets/LoginPage/google-color-svgrepo-com (1).svg";
import { auth, provider } from "@/firebase.config";
import { useSelector, useDispatch } from "react-redux";
import {
  signInFailure,
  signInStart,
  signInSuccess,
  setToken,
} from "@/redux/user/userSlice";

// Define the schema for form validation
const loginSchema = z.object({
  email: z.string().email("Invalid email address"),
  password: z.string().min(6, "Password must be at least 6 characters long"),
});

const Login = () => {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  const [formData, setFormData] = useState({ email: "", password: "" });
  const [errors, setErrors] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  // REDUX ERROR MESSAGE FOR LOGIN
  const rdxError = useSelector((state) => state.user.error);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    dispatch(signInStart());
    setIsLoading(true);

    try {
      loginSchema.parse(formData); // User Input Validation

      const response = await signInWithEmailAndPassword(
        auth,
        formData.email,
        formData.password
      );
      const token = await response.user.getIdToken();

      // Set the token in Redux
      dispatch(setToken(token));

      const data = response.user;
      // Dispatch sign-in success

      dispatch(
        signInSuccess({
          email: data.email,
          displayName: data.displayName,
          lastSeen: data.metadata.lastSignInTime,
          avatar: data.photoURL,
          uid: data.uid,
        })
      );
      setIsLoading(false);
      navigate("/");
    } catch (error) {
      setIsLoading(false);

      if (error instanceof z.ZodError) {
        const formattedErrors = {};
        error.errors.forEach((err) => {
          formattedErrors[err.path[0]] = err.message;
        });
        setErrors(formattedErrors);
      } else {
        dispatch(signInFailure(error.message || "Login failed"));
      }
    }
  };

  const handleGoogleSignIn = async () => {
    // const provider = new GoogleAuthProvider();
    try {
      const result = await signInWithPopup(auth, provider);
      const user = result.user;
      const token = await user.getIdToken();
      dispatch(setToken(token));
      dispatch(
        signInSuccess({
          email: user.email,
          displayName: user.displayName,
          lastSeen: user.metadata.lastSignInTime,
          avatar: user.photoURL,
          uid: user.uid,
        })
      );
      navigate("/");
    } catch (error) {
      if (
        error.response &&
        error.response.data &&
        error.response.data.message === "User already exists"
      ) {
        // Handle case where user already exists in your backend
        navigate("/dashboard");
      } else {
        throw new Error("Error signing in with Google", error);
      }
    }
  };

  return (
    <section className="min-h-screen flex-col flex lg:flex-row flex-wrap">
      {/* Left column for the image */}
      <div className="lg:bg-[hsla(185,100%,98%,1)] w-full lg:w-[34.4375rem] lgs:h-[63.625rem] lg:p-[5.8125rem 4.475625rem 5.875rem 4.4375rem]">
        <span className="absolute top-[28px] left-[90.34px] block lg:hidden text-[hsla(0,0%,16%,1)]transition-all duration-300 ease-in-out transition-transform hover:scale-150">
          <IoCloseSharp />
        </span>
        <div className="bg-lightCyan py-6 w-full h-[4.125rem] lg:absolute top-[5.813rem] left-[9.5rem] lg:w-[15.36875rem] lg:h-[5rem] mb-6 mx-auto lg:mt-5">
          <img
            src={logo}
            alt=""
            className="mx-auto w-[5rem] h-[1.625rem] lg:w-[15.36875rem] lg:h-[5rem]"
          />
        </div>
        <div className="mx-auto">
          <img
            src={ImageLogo}
            alt=""
            className="mx-auto mt-6 w-[8.898rem] h-[5.758rem] lg:absolute lg:top-[25.063rem] lg:left-[4.438rem] lg:w-[25.52375rem] lg:h-[16.414rem] mb-0"
          />
        </div>
        <div className="w-full text-center lg:w-[17.875rem] lg:h-[1.875rem]">
          <p className="hidden lg:absolute top-[45.875rem] left-[8.25rem] lg:block font-poppins text-xl font-normal leading-[1.875rem]">
            Comfort meets convenience
          </p>
        </div>
      </div>

      {/* Right column for the form */}
      <div className="w-full lg:w-[30%] lg:absolute lg:top-[12.25rem] lg:left-[35.625rem] h-auto bg-white flex flex-col">
        <div className="w-full h-auto p-2 mb-2 lg:mt-[25%]">
          <h4
            className={`flex mt-0 text-black font-bold text-2xl ${
              isMobile ? "justify-center" : "text-start"
            }`}
          >
            {isMobile ? "REGISTER" : "SIGN IN"}
          </h4>
          <div className="mb-2 flex justify-center lg:justify-start">
            {isMobile ? (
              <p className="text-sm lg:text-lg">
                Already have an account?{" "}
                <Link
                  to="/login"
                  className="text-sm lg:text-lg blue-600 underline text-blue-400 hover:text-blue-500 duration-300 ease-in-out transition-transform hover:scale-105"
                >
                  Sign in
                </Link>
              </p>
            ) : (
              <p className="pb-3 text-xs lg:text-lg text-gray-400 text-left">
                Don&#39;t have an account?{" "}
                <Link
                  to="/register"
                  className="text-blue-600 underline hover:text-blue-500 transition-all duration-300 ease-in-out  hover:scale-105"
                >
                  Sign up here
                </Link>
              </p>
            )}
          </div>
          <button className="hidden lg:block lg:absolute top-[140px] left-[968px]  duration-300 ease-in-out transition-transform hover:scale-150">
            <span className="text-[hsla(0,0%,64%,1)]">
              <IoCloseSharp />
            </span>
          </button>
          <form onSubmit={handleSubmit} action="">
            <div className="grid grid-cols-1 gap-4">
              <label className="relative">
                <input
                  type="email"
                  name="email"
                  className={`px-4 py-2 w-full h-10 text-sm outline-none bg-white ${
                    errors.email ? "border-red-500" : "border-black"
                  } border-[1px] border-gray-400 rounded hover:border-gray-600 duration-200 peer focus:border-blue-400`}
                  value={formData.email}
                  onChange={handleChange}
                  required
                />
                <span className="absolute left-0 top-2 px-1 text-sm tracking-wide peer-focus:text-blue-400 pointer-events-none duration-200 peer-focus:text-sm peer-focus:-translate-y-5 bg-white ml-2 peer-valid:text-sm peer-valid:-translate-y-5">
                  Email
                </span>
                {errors.email && (
                  <p className="text-red-500 mt-1">{errors.email}</p>
                )}
              </label>
              <label className="relative">
                <input
                  type="password"
                  name="password"
                  className={`px-4 py-2 w-full h-10 text-sm outline-none bg-white ${
                    errors.password ? "border-red-500" : "border-black"
                  } border-[1px] border-gray-400 rounded hover:border-gray-600 duration-200 peer focus:border-blue-400`}
                  value={formData.password}
                  onChange={handleChange}
                  required
                />
                <span className="absolute left-0 top-2 px-1 text-sm tracking-wide peer-focus:text-blue-400 pointer-events-none duration-200 peer-focus:text-sm peer-focus:-translate-y-5 bg-white ml-2 peer-valid:text-sm peer-valid:-translate-y-5">
                  Password
                </span>

                {errors.password && (
                  <p className="text-red-500 mt-1">{errors.password}</p>
                )}
              </label>
              <div className="mb-2 text-center lg:text-start ">
                <Link
                  to="/resetPassword"
                  className="hover:box-shadow-2xl hover:text-gray-500 text-sm text-[hsla(0,0%,67%,1)]"
                >
                  Forgort Password ?
                </Link>
              </div>
            </div>
            <button
              type="submit"
              className="inline-block w-full rounded bg-blue-500 px-7 pb-2.5 pt-3.5 text-sm font-medium uppercase leading-normal text-white transition duration-150 ease-in-out hover:bg-blue-600"
              disabled={isLoading}
            >
              {isLoading ? "SIGNING IN..." : "SIGN IN"}
            </button>

            <div className="my-4 flex items-center before:mt-0.5 before:flex-1 before:border-t before:border-neutral-300 after:mt-0.5 after:flex-1 after:border-t after:border-neutral-300 dark:before:border-neutral-500 dark:after:border-neutral-500">
              <p className="mx-4 mb-0 text-center font-semibold dark:text-neutral-200">
                OR
              </p>
            </div>
            {/* Grid container for social login buttons */}
            <div className="flex flex-col md:flex-row gap-3">
              {/* Google sign-in button */}
              <button
                onClick={handleGoogleSignIn}
                className="flex w-full md:w-1/2 items-center justify-center rounded bg-white border border-gray-300 px-7 pb-2.5 pt-3 text-center text-sm font-medium uppercase leading-normal text-black shadow-info-3 transition duration-150 ease-in-out hover:bg-gray-100 hover:shadow-info-2 focus:bg-gray-100 focus:shadow-info-2 focus:outline-none focus:ring-0 active:bg-gray-200 active:shadow-info-2 dark:shadow-black/30 dark:hover:shadow-dark-strong dark:focus:shadow-dark-strong dark:active:shadow-dark-strong"
                data-twe-ripple-init=""
                data-twe-ripple-color="light"
              >
                <span className="me-2 fill-white h-4.5 w-4.5">
                  <img
                    src={GoogleLogo}
                    className="w-6 h-6 mr-3"
                    alt="Google Logo"
                  />
                </span>
                Sign in with Google
              </button>
            </div>
            {/* DISPLAYS LOGIN ERRORS */}
            {rdxError && (
              <p className="text-red-500 mt-2">{rdxError.code || rdxError}</p>
            )}
          </form>
        </div>
      </div>
    </section>
  );
};

export default Login;
