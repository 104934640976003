import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import Header from './globalrooms/Header';
import useFetchRoomDetails from './globalrooms/useFetchRoomDetails'; 
import './globalrooms/rooms.css';

const roomTypeMap = {
  "standard-single": "Standard Single Room",
  "executive-single": "Executive Single Room",
  "deluxe-single": "Deluxe Single Room",
  "standard-double": "Standard Double Room",
  "executive-double": "Executive Double Room",
  "deluxe-double": "Deluxe Double Room",
  "standard-twin": "Standard Twin Room",
  "executive-twin": "Executive Twin Room",
  "deluxe-twin": "Deluxe Twin Room",
  "standard-family": "Standard Family Room",
  "executive-family": "Executive Family Room",
  "deluxe-family": "Deluxe Family Room",
};

const EditRoomDetails = () => {  
  const { id, roomNumber } = useParams();
  const { rooms, loading, error } = useFetchRoomDetails(id); 

  // State initialization
  const [selectedRoomType, setSelectedRoomType] = useState('');
  const [roomTypesOptions, setRoomTypesOptions] = useState([]);
  const [smokingAllowed, setSmokingAllowed] = useState(null); 
  const [maxGuestsAllowed, setMaxGuestsAllowed] = useState('');
  const [roomSize, setRoomSize] = useState('');
  const [existingRoomType, setExistingRoomType] = useState('');

  useEffect(() => {
    if (rooms && rooms.length > 0) {
      const options = Object.keys(roomTypeMap).map(key => ({
        value: key, 
        label: roomTypeMap[key], 
      }));
      setRoomTypesOptions(options);

      // Find the specific room to edit
      const room = rooms.find(r => r.number === roomNumber); 
      if (room) {
        setSelectedRoomType(room.roomTypes.name || '');
        setRoomSize(room.roomTypes.typeSize || '');
        setMaxGuestsAllowed(room.roomTypes.maxGuests || '');
        setExistingRoomType(room.roomTypes.name || '');
        
        // Set smoking policy based on roomTypePolicies
        const smokingPolicy = room.roomTypes.roomTypePolicies.find(
          policy => policy.policies.type === 'Smoking'
        );
        setSmokingAllowed(smokingPolicy ? (smokingPolicy.policies.isMandatory ? 'yes' : 'no') : null);
      }
    }
  }, [rooms, roomNumber]);

  const handleRoomTypeChange = (event) => {
    setSelectedRoomType(event.target.value);
  };

  const handleCheckboxChange = (value) => {
    setSmokingAllowed(value); 
  };

  const handleRoomSizeChange = (event) => {
    setRoomSize(event.target.value);
  };

  const handleMaxGuestsChange = (event) => {
    setMaxGuestsAllowed(event.target.value);
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    // Construct the payload with updated room details
    const updatedRoomDetails = {
      roomType: selectedRoomType,
      maxGuestsAllowed,
      smokingAllowed,
      roomSize,
    };

    // Call API or update function to save the updated room details
    console.log('Updated room details:', updatedRoomDetails);    
    console.log('Updated Guests Number:', maxGuestsAllowed);    

    // Add logic here to send the updatedRoomDetails to your backend
  };

  if (loading) {
    return (
      <div className="flex justify-center items-center h-screen">
        <div className="w-16 h-16 border-4 border-t-4 border-gray-200 border-t-blue-500 border-solid rounded-full animate-spin" aria-label="Loading"></div>
      </div>
    );
  }
  if (error) return <p>Error: {error}</p>;

  return (
    <div className='p-8 bg-base-white'>
      <Header linkTo={`/room-details/${id}/${existingRoomType}/${roomNumber}`} title="Room Details" />
      <form className="relative space-y-6" onSubmit={handleSubmit}>
        <div className="w-full max-w-[733px] p-8 bg-laara-secondary border rounded-lg">
          <div className="space-y-6">
            <div className="space-y-2">
              <label htmlFor="roomType" className="block font-medium text-[16px] leading-[24px]">
                Select room type
              </label>
              <select
                id="roomType"
                className="w-full h-[52px] px-2 border border-gray-300 rounded-md font-normal text-[14px] cursor-pointer focus:outline-none"
                value={selectedRoomType} 
                onChange={handleRoomTypeChange}
              >
                <option value="">Select a room type</option>
                {roomTypesOptions.map(option => (
                  <option key={option.value} value={option.value} className='cursor-pointer'>
                    {option.label}
                  </option>
                ))}
              </select>
            </div>

            <div className="space-y-2">
              <label htmlFor="maxGuests" className="block font-medium text-[16px] leading-[20px]">
                How many guests do you allow in the room?
              </label>
              <input
                id="maxGuests"
                type="number"
                name="maxGuests"
                className="h-[52px] px-2 text-sm border border-gray-300 rounded-md focus:outline-none"
                min="1"
                value={maxGuestsAllowed}
                onChange={handleMaxGuestsChange}
              />
            </div>
          </div>
        </div>

        <div className="w-full max-w-[733px] p-8 bg-laara-secondary border rounded-lg">
          <div>
            <label className="block mb-2 font-normal text-[16px] leading-[20px]">
              Do you allow smoking in this room?
            </label>
            <div className="flex flex-col">
              <label className="inline-flex items-center mb-2">
                <input
                  type="checkbox"
                  name="Smoking"
                  value="yes"
                  checked={smokingAllowed === 'yes'}
                  onChange={() => handleCheckboxChange('yes')}
                  className="custom-checkbox1"
                />
                <span className="ml-2 text-[14px]">Yes</span>
              </label>
              <label className="inline-flex items-center mb-2">
                <input
                  type="checkbox"
                  name="Smoking"
                  value="no"
                  checked={smokingAllowed === 'no'}
                  onChange={() => handleCheckboxChange('no')}
                  className="custom-checkbox1"
                />
                <span className="ml-2 text-[14px]">No</span>
              </label>
            </div>
          </div>
        </div>

        <div className="w-full max-w-[733px] p-8 bg-laara-secondary border rounded-lg">
          <div>
            <label className="block mb-2 font-normal text-[16px] leading-[20px]">
              How big is the room?
            </label>
            <label className="block font-normal text-[14px] leading-[18px]">Room size - optional</label>
            <div className="relative w-full sm:w-[317px]">
              <input
                type="number"
                name="typeSize"
                className="w-full p-2 border rounded-lg pl-4 text-[14px] focus:outline-none"
                value={roomSize}
                onChange={handleRoomSizeChange}
              />
              <span className="absolute top-1/2 right-3 transform -translate-y-1/2 text-black text-[14px]">
                Sq.ft
              </span>
            </div>
          </div>
        </div>

        <button type="submit" className="px-4 py-2 bg-blue-500 text-white rounded-[36px] w-[200px]">
          Save Changes
        </button>
      </form>
    </div>
  );
};

export default EditRoomDetails;
