import React from "react";
import thumbsup from "@/assets/shared/thumbsup.svg";
import check from "@/assets/shared/check.svg";
import { X } from "lucide-react";

const Consider = ({ title, data = [] }) => {
  // Ensure data is an array before calling map
  const items = Array.isArray(data) ? data : [];

  return (
    <div className="hidden lg:flex border border-[#A3A3A3] rounded-[6px] max-w-[329px] w-fit p-3  gap-2 items-start justify-between h-fit ">
      <img src={thumbsup} alt="icon" className="size-[30px]" />
      <div className="w-[191px]">
        <p className="text-sm font-medium ">{title}</p>
        <div>
          {items.length > 0 ? (
            items.map((item) => (
              <div key={item} className="mt-1 flex items-center gap-2">
                <img src={check} alt="icon" className="w-3" />
                <small className="text-xs normal-case">{item}</small>
              </div>
            ))
          ) : (
            <p className="text-xs text-gray-500">No items available</p>
          )}
        </div>
      </div>
      <div>
        <X className="size-4 text-[#6D6D6D] " />
      </div>
    </div>
  );
};

export default Consider;
