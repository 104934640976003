// import { useState } from "react";
// import { useSelector, useDispatch } from "react-redux";
// import { updatePricing } from "@/redux/Listing/listingSlice";
// import Consider from "@/components/Listings/Consider";

// export default function Pricing2() {
//   const dispatch = useDispatch();
//   const [state, setState] = useState({
//     priceOneGuestPrivateRoom: 0,
//     priceTwoGuestsPrivateRoom: 0,
//     priceThreeGuestsPrivateRoom: 0,
//     chargesDifferently: false,
//   });

//   const selectedRoom = useSelector(
//     (state) => state.hostListing.formData.roomType.name
//   );

//   const handleInputChange = (e, name) => {
//     console.log(e, name);
//     const inputValue = parseFloat(e.target.value) || 0;
//     const isChargesDifferentlyKey = name === "chargesDifferently";

//     setState((prevState) => {
//       const updatedState = {
//         ...prevState,
//         [name]: isChargesDifferentlyKey ? e.target.value === "yes" : inputValue,
//       };

//       // Dispatch the updatePricing action to update Redux state
//       //   dispatch(
//       //     updatePricing({
//       //       index: 0, // Assuming this is the first item, use appropriate index
//       //       pricingData: updatedState,
//       //     })
//       //   );
//       //   console.log(updatedState);
//       //   return updatedState;
//     });
//   };

//   return (
//     <form className="w-full xl:max-w-[1079px]">
//       <div className="mb-10 text-xl font-medium  max-md:mt-10 max-md:max-w-full w-full xl:max-w-[1079px]">
//         Price per night
//       </div>
//       <div className="flex flex-wrap lg:flex-nowrap gap-3">
//         <div className="w-full max-w-[728px] flex flex-col justify-center px-8 py-9 rounded-md bg-laara-secondary bg-opacity-40 border border-neutral-200 max-md:px-5">
//           <div className="flex flex-col">
//             <h4 className="text-lg font-medium capitalize text-black">
//               {selectedRoom}
//             </h4>

//             <div className="flex flex-col gap-5 pt-1.5 mt-7">
//               {/* MAP NNUMBER OF GUESTS */}
//               {["oneGuest", "twoGuests", "threeGuests"].map(
//                 (guestType, index) => {
//                   const priceKey = `price${
//                     guestType.charAt(0).toUpperCase() + guestType.slice(1)
//                   }PrivateRoom`;
//                   const price = Math.max(0, state[priceKey] || 0);
//                   const earnings = Math.max(0, price - price * 0.1);
//                   const shouldShowInput =
//                     state.chargesDifferently || guestType === "oneGuest";

//                   return (
//                     shouldShowInput && (
//                       <div
//                         key={index}
//                         className="flex flex-col md:flex-row md:items-center gap-5"
//                       >
//                         <div className="flex flex-col flex-1 text-black max-w-[290px]">
//                           <div className="text-base font-medium">
//                             Price for{" "}
//                             {guestType === "oneGuest"
//                               ? "one guest"
//                               : guestType === "twoGuests"
//                               ? "two guests"
//                               : "three guests"}
//                           </div>
//                           <div className="flex items-center py-3 mt-3 text-sm whitespace-nowrap rounded-lg border border-blue-400 transition-colors duration-300">
//                             <div className="self-stretch my-auto px-2">US$</div>
//                             <div className="shrink-0 self-start w-px h-7 border border-solid bg-neutral-500" />
//                             <input
//                               type="number"
//                               placeholder=""
//                               value={price || ""}
//                               onChange={(e) => handleInputChange(e, priceKey)}
//                               className="flex-auto self-stretch my-auto px-2 py-1 border-none outline-none text-black bg-transparent placeholder-black"
//                               style={{ borderColor: "transparent" }}
//                               onFocus={(e) =>
//                                 (e.target.style.borderColor = "blue")
//                               }
//                               onBlur={(e) =>
//                                 (e.target.style.borderColor = "transparent")
//                               }
//                             />
//                           </div>
//                         </div>

//                         <div className="flex flex-col flex-1 text-neutral-600">
//                           <div className="text-base font-medium">
//                             Earning after Laara commission
//                           </div>
//                           <div className="flex items-center py-3 mt-3 text-sm whitespace-nowrap rounded-lg border border-neutral-600">
//                             <div className="self-stretch my-auto px-2">US$</div>
//                             <div className="shrink-0 self-start w-px h-7 border border-solid bg-neutral-500" />
//                             <div className="flex-auto px-2 self-stretch my-auto">
//                               {earnings.toFixed(2)}
//                             </div>
//                           </div>
//                         </div>
//                       </div>
//                     )
//                   );
//                 }
//               )}
//               <h2 className="text-base font-normal">
//                 Do you charge differently for different amounts of guests?
//               </h2>
//               <div className="flex flex-col space-y-2">
//                 <label className="inline-flex items-center">
//                   <input
//                     type="radio"
//                     className="form-radio accent-laara-primary"
//                     name="guests"
//                     value="yes"
//                     checked={state.chargesDifferently}
//                     onChange={(e) =>
//                       handleInputChange(e.target.value, "chargesDifferently")
//                     }
//                   />
//                   <span className="text-base sm:text-lg md:text-[16px] font-normal leading-relaxed ml-2 cursor-pointer">
//                     Yes
//                   </span>
//                 </label>
//                 <label className="inline-flex items-center">
//                   <input
//                     type="radio"
//                     className="form-radio w-5 h-5 accent-laara-primary"
//                     name="guests"
//                     value="no"
//                     checked={state.chargesDifferently === false}
//                     onChange={setState((state.chargesDifferently: false))}
//                   />
//                   <span className="text-base sm:text-lg md:text-[16px] font-normal leading-relaxed ml-2 cursor-pointer">
//                     No
//                   </span>
//                 </label>
//               </div>

//               <p className="text-base text-neutral-500">
//                 Including taxes, commissions and fees
//               </p>
//               <p className="text-base text-neutral-500">10% Laara commission</p>
//             </div>
//           </div>
//         </div>

//         <div className="w-full lg:w-1/2 ">
//           <Consider
//             title={"You are unsure about your price?"}
//             data={["Don't worry you can set your price when you are ready"]}
//           />
//         </div>
//       </div>
//     </form>
//   );
// }

import { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { updatePricing, clearPricing } from "@/redux/Listing/listingSlice";
import Consider from "@/components/Listings/Consider";

export default function Pricing2() {
  const dispatch = useDispatch();
  const [state, setState] = useState({
    pricePerPerson: 0,
    pricePerCouple: 0,
    pricePerThreeGuests: 0,
    chargesDifferently: false,
  });

  const selectedRoom = useSelector(
    (state) => state.hostListing.formData.roomType.name
  );

  // const handleInputChange = (e, name) => {
  //   const inputValue = parseFloat(e.target.value) || 0;
  //   const isChargesDifferentlyKey = name === "chargesDifferently";

  //   setState((prevState) => {
  //     const updatedState = {
  //       ...prevState,
  //       [name]: isChargesDifferentlyKey ? e.target.value === "yes" : inputValue,
  //     };

  //     // Dispatch the updatePricing action to update Redux state
  //     dispatch(
  //       updatePricing({
  //         pricingData: generatePricingData(updatedState),
  //       })
  //     );
  //     return updatedState;
  //   });
  // };

  // const generatePricingData = (state) => {
  //   const pricingData = [];

  //   if (state.chargesDifferently) {
  //     // Add pricing data for different numbers of guests
  //     pricingData.push(
  //       {
  //         price: state.pricePerThreeGuests,
  //         pricingMode: "PER_PERSON_SHARING",
  //         mealPlan: {},
  //       },
  //       {
  //         price: state.pricePerCouple,
  //         pricingMode: "PER_COUPLE",
  //         mealPlan: {},
  //       },
  //       {
  //         price: state.pricePerPerson,
  //         pricingMode: "PER_WEEK",
  //         deductionRate: 10, // Example deduction rate, adjust as needed
  //         mealPlan: {},
  //       }
  //     );
  //   } else {
  //     // If charges are the same for all guest types
  //     pricingData.push({
  //       price: state.pricePerPerson,
  //       pricingMode: "PER_PERSON_SHARING",
  //       mealPlan: {
  //         name: "FULL_BOARD",
  //         description: "All meals are included",
  //       },
  //     });
  //   }

  //   return pricingData;
  // };

  const handleInputChange = (e, name) => {
    const inputValue = parseFloat(e.target.value) || 0;
    const isChargesDifferentlyKey = name === "chargesDifferently";

    setState((prevState) => {
      const updatedState = {
        ...prevState,
        [name]: isChargesDifferentlyKey ? e.target.value === "yes" : inputValue,
      };

      const pricingData = generatePricingData(updatedState);

      dispatch(
        updatePricing({
          index: 0, // Assuming you want to update the first entry
          pricingData: pricingData,
        })
      );

      return updatedState;
    });
  };

  const generatePricingData = (state) => {
    let pricingData = [];

    if (state.chargesDifferently) {
      pricingData = [
        {
          price: state.pricePerThreeGuests || 0,
          pricingMode: "PER_PERSON_SHARING",
          mealPlan: {},
        },
        {
          price: state.pricePerCouple || 0,
          pricingMode: "PER_COUPLE",
          mealPlan: {},
        },
        {
          price: state.pricePerPerson || 0,
          pricingMode: "PER_WEEK",
          deductionRate: 10, // Example deduction rate, adjust as needed
          mealPlan: {},
        },
      ];
    } else {
      pricingData = [
        {
          price: state.pricePerPerson || 0,
          pricingMode: "PER_PERSON_SHARING",
          mealPlan: {
            name: "FULL_BOARD",
            description: "All meals are included",
          },
        },
      ];
    }

    return pricingData;
  };

  // const generatePricingData = (state) => {
  //   const baseData = {
  //     mealPlan: { name: "FULL_BOARD", description: "All meals are included" },
  //   };

  //   // Spread implementation to generate pricing data based on chargesDifferently
  //   const pricingData = state.chargesDifferently
  //     ? [
  //         {
  //           ...baseData,
  //           price: state.pricePerThreeGuests,
  //           pricingMode: "PER_PERSON_SHARING",
  //         },
  //         {
  //           ...baseData,
  //           price: state.pricePerCouple,
  //           pricingMode: "PER_COUPLE",
  //         },
  //         {
  //           ...baseData,
  //           price: state.pricePerPerson,
  //           pricingMode: "PER_WEEK",
  //           deductionRate: 10, // Example deduction rate
  //         },
  //       ]
  //     : [
  //         {
  //           ...baseData,
  //           price: state.pricePerPerson,
  //           pricingMode: "PER_PERSON_SHARING",
  //         },
  //       ];

  //   return pricingData;
  // };

  return (
    <form className="w-full xl:max-w-[1079px]">
      <div className="mb-10 text-xl font-medium  max-md:mt-10 max-md:max-w-full w-full xl:max-w-[1079px]">
        Price per night
      </div>
      <div className="flex flex-wrap lg:flex-nowrap gap-3">
        <div className="w-full max-w-[728px] flex flex-col justify-center px-8 py-9 rounded-md bg-laara-secondary bg-opacity-40 border border-neutral-200 max-md:px-5">
          <div className="flex flex-col">
            <h4 className="text-lg font-medium capitalize text-black">
              {selectedRoom}
            </h4>

            <div className="flex flex-col gap-5 pt-1.5 mt-7">
              {["oneGuest", "couple", "threeGuests"].map((guestType, index) => {
                const priceKey = `pricePer${
                  guestType.charAt(0).toUpperCase() + guestType.slice(1)
                }`;
                const price = state[priceKey] || 0;
                const earnings = Math.max(0, price - price * 0.1);
                const shouldShowInput =
                  state.chargesDifferently || guestType === "oneGuest";

                return (
                  shouldShowInput && (
                    <div
                      key={index}
                      className="flex flex-col md:flex-row md:items-center gap-5"
                    >
                      <div className="flex flex-col flex-1 text-black max-w-[290px]">
                        <div className="text-base font-medium">
                          Price for{" "}
                          {guestType === "oneGuest"
                            ? "one guest"
                            : guestType === "couple"
                            ? "two guests"
                            : "three guests"}
                        </div>
                        <div className="flex items-center py-3 mt-3 text-sm whitespace-nowrap rounded-lg border border-blue-400 transition-colors duration-300">
                          <div className="self-stretch my-auto px-2">US$</div>
                          <div className="shrink-0 self-start w-px h-7 border border-solid bg-neutral-500" />
                          <input
                            type="number"
                            placeholder=""
                            value={price || ""}
                            onChange={(e) => handleInputChange(e, priceKey)}
                            className="flex-auto self-stretch my-auto px-2 py-1 border-none outline-none text-black bg-transparent placeholder-black"
                            style={{ borderColor: "transparent" }}
                            onFocus={(e) =>
                              (e.target.style.borderColor = "blue")
                            }
                            onBlur={(e) =>
                              (e.target.style.borderColor = "transparent")
                            }
                          />
                        </div>
                      </div>

                      <div className="flex flex-col flex-1 text-neutral-600">
                        <div className="text-base font-medium">
                          Earning after Laara commission
                        </div>
                        <div className="flex items-center py-3 mt-3 text-sm whitespace-nowrap rounded-lg border border-neutral-600">
                          <div className="self-stretch my-auto px-2">US$</div>
                          <div className="shrink-0 self-start w-px h-7 border border-solid bg-neutral-500" />
                          <div className="flex-auto px-2 self-stretch my-auto">
                            {earnings.toFixed(2)}
                          </div>
                        </div>
                      </div>
                    </div>
                  )
                );
              })}

              <h2 className="text-base font-normal">
                Do you charge differently for different amounts of guests?
              </h2>
              <div className="flex flex-col space-y-2">
                <label className="inline-flex items-center">
                  <input
                    type="radio"
                    className="form-radio accent-laara-primary"
                    name="guests"
                    value="yes"
                    checked={state.chargesDifferently}
                    onChange={(e) => handleInputChange(e, "chargesDifferently")}
                  />
                  <span className="text-base sm:text-lg md:text-[16px] font-normal leading-relaxed ml-2 cursor-pointer">
                    Yes
                  </span>
                </label>
                <label className="inline-flex items-center">
                  <input
                    type="radio"
                    className="form-radio accent-laara-primary"
                    name="guests"
                    value="no"
                    checked={!state.chargesDifferently}
                    onChange={(e) => handleInputChange(e, "chargesDifferently")}
                  />
                  <span className="text-base sm:text-lg md:text-[16px] font-normal leading-relaxed ml-2 cursor-pointer">
                    No
                  </span>
                </label>
              </div>

              <p className="text-base text-neutral-500">
                Including taxes, commissions and fees
              </p>
              <p className="text-base text-neutral-500">10% Laara commission</p>
              <button onClick={() => dispatch(clearPricing())}>clear</button>
            </div>
          </div>
        </div>

        <Consider
          title={"You are unsure about your price?"}
          data={["Don't worry you can set your price when you are ready"]}
        />
      </div>
    </form>
  );
}
