import { Link } from "react-router-dom";
import { Avatar, AvatarFallback, AvatarImage } from "@/components/ui/avatar";
import help from "@/assets/navbar/help-square.png";
import messages from "@/assets/navbar/messages.jpg";
import notification from "@/assets/navbar/notification.jpg";
import { Menu } from "lucide-react";
import Drawer from "./Drawer";
import { useState } from "react";
import PopNotifications from "@/components/Notifications/NotificationsPopUp";
import { useSelector } from "react-redux";

const Authenticated = ({ isLoggedIn, setOpenDrawer, openDrawer }) => {
  const [isOverlayOpen, setIsOverlayOpen] = useState(false); // State to manage overlay visibility
  const { currentUser } = useSelector((state) => state.user);

  const toggleOverlay = () => {
    setIsOverlayOpen((prev) => !prev); // Toggle the overlay state
  };

  const closeOverlay = () => {
    setIsOverlayOpen(false); // Close the overlay
  };

  const handleOverlayClick = (e) => {
    e.stopPropagation(); // Prevent clicks inside the overlay from closing it
  };

  return (
    <div className="relative flex flex-col-reverse items-start gap-6 px-7 xl:px-0 xl:flex-row xl:items-center xl:gap-8">
      <Link
        to="/landingpage"
        className="bg-laara-primary text-white py-3.5 px-[30px] rounded-[32px] w-[294px] text-center xl:bg-white xl:w-fit xl:px-0 xl:py-0 xl:text-laara-primary xl:font-medium xl:text-xl"
      >
        List A Property
      </Link>
      <div className="hidden xl:flex xl:items-center xl:gap-4 xl:px-0 xl:py-0 relative">
        <Link to="/support" className="size-[30px]">
          <img src={help} alt="help" />
        </Link>
        <Link to="/chats" className="size-[30px]">
          <img src={messages} alt="support" />
        </Link>
        <Link
          to="#"
          className="size-[30px]"
          onClick={(e) => {
            e.preventDefault();
            toggleOverlay();
          }}
        >
          <img src={notification} alt="Notifications" />
        </Link>
        {/* Overlay */}
        {isOverlayOpen && (
          <div
            className="fixed inset-0 z-40"
            onClick={() => setIsOverlayOpen(false)} // Click outside the overlay to close
          >
            <div
              className="absolute top-16 right-8 mt-5  w-[500px] h-[389px]  p-4"
              onClick={handleOverlayClick} // Prevent closing when clicking inside
            >
              <PopNotifications closeOverlay={closeOverlay} />
            </div>
          </div>
        )}
      </div>
      <div
        onClick={() => setOpenDrawer(!openDrawer)}
        className="hidden xl:flex items-center gap-2 bg-laara-secondary py-1 px-2 rounded-[36px] border border-laara-primary"
      >
        <Menu className="text-laara-primary cursor-pointer size-[18px]" />

        <Avatar className="cursor-pointer size-[30px]">
          <AvatarImage
            src={currentUser ? currentUser.photoURL : null}
            alt="avatar"
          />
          <AvatarFallback>CN</AvatarFallback>
        </Avatar>
      </div>

      <div className="flex justify-start xl:hidden">
        <Drawer isLoggedIn={isLoggedIn} />
      </div>
    </div>
  );
};

export default Authenticated;
