import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  setBathroomAmenity,
  setBathroomPrivacy,
} from "@/redux/Listing/listingSlice";
import { Checkbox } from "@/components/ui/checkbox";

export const Bathroom = () => {
  const dispatch = useDispatch();
  const selectedAmenity = useSelector(
    (state) => state.hostListing.formData.roomType.bathroom.amenities || []
  );
  const bathroomPrivacy = useSelector(
    (state) => state.hostListing.formData.roomType.bathroom.isPrivate
  );

  const handleCheckboxChange = (isChecked, item) => {
    const isSelected = selectedAmenity.some((a) => a.name === item);
    const updatedAmenities = isSelected
      ? selectedAmenity.filter((a) => a.name !== item)
      : [
          ...selectedAmenity,
          {
            name: item,
            description: `${item} available`,
            category: "Bathroom", // Default category
          },
        ];
    dispatch(setBathroomAmenity(updatedAmenities));
  };

  const handleChange = (e) => {
    dispatch(setBathroomPrivacy(e.target.value === "yes"));
  };

  const bathroomItems = [
    "Toilet paper",
    "Shower",
    "Toilet",
    "Hair dryer",
    "Bath",
    "Free Toiletries",
    "Bidet",
    "Slippers",
    "Bathrobe",
    "Spa bath",
  ];

  const renderCheckboxes = (category, items) => (
    <div key={category} className="space-y-2">
      {/* <p className="text-lg font-medium">{category}</p> */}
      <div className="flex flex-col gap-2">
        {items.map((item) => (
          <div key={item} className="flex items-center gap-3">
            <Checkbox
              id={`${category}-${item}`}
              className="size-6 rounded-lg"
              checked={selectedAmenity.some((a) => a.name === item)}
              onCheckedChange={(isChecked) =>
                handleCheckboxChange(isChecked, item)
              }
            />
            <label
              htmlFor={`${category}-${item}`}
              className={`capitalize text-lg ${
                selectedAmenity.some((a) => a.name === item) &&
                "text-laara-primary"
              }`}
            >
              {item}
            </label>
          </div>
        ))}
      </div>
    </div>
  );

  return (
    <form className="relative space-y-6">
      <div className="w-full max-w-[733px] p-4 border bg-laara-secondary rounded-lg mb-10">
        <h2 className="text-lg font-medium mb-3">Is the bathroom private?</h2>
        <div className="mb-6 flex flex-col space-y-2">
          <label className="inline-flex items-center">
            <input
              type="radio"
              className="form-radio w-5 h-5 accent-laara-primary"
              name="bathroomPrivacy"
              value="yes"
              checked={bathroomPrivacy}
              onChange={handleChange}
            />
            <span className="text-base sm:text-lg md:text-[16px] font-normal leading-relaxed ml-2 cursor-pointer">
              Yes
            </span>
          </label>
          <label className="inline-flex items-center">
            <input
              type="radio"
              className="form-radio w-5 h-5 accent-laara-primary"
              name="bathroomPrivacy"
              value="no"
              checked={!bathroomPrivacy}
              onChange={handleChange}
            />
            <span className="text-base sm:text-lg md:text-[16px] font-normal leading-relaxed ml-2 cursor-pointer">
              No, It's shared
            </span>
          </label>
        </div>
      </div>

      <div className="w-full max-w-[733px] p-4 border bg-[#FFFFFF] rounded-lg">
        <h2 className="text-lg font-medium mb-3">
          Which bathroom items are available in this room?
        </h2>
        <div className="grid grid-cols-1 gap-4">
          {renderCheckboxes("Bathroom", bathroomItems)}
        </div>
      </div>
    </form>
  );
};

export default Bathroom;
