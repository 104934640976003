import { useEffect, useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import { getHostProfile } from "@/api";
import Verify from "@/assets/profile/verify.svg";
import Location from "@/assets/shared/location.png";
import eyeIcon from "@/assets/profile/eye.svg";
import editIcon from "@/assets/profile/edit-2.png";
import eye from "@/assets/profile/eye2.png";
import { HostPublic } from "./HostPublic";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLocationDot } from "@fortawesome/free-solid-svg-icons";
import { Avatar, AvatarFallback, AvatarImage } from "@/components/ui/avatar";
import { useSelector } from "react-redux";
import ReviewsSection from "../HostReview/ReviewsSection";

export const HostProfile = () => {
  const [isPublicView, setIsPublicView] = useState(false);
  const [profileData, setProfileData] = useState(null);
  const [properties, setProperties] = useState([]);
  const [propertyIds, setPropertyIds] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  const hostId = localStorage.getItem("uid");

  const currentUser = useSelector((state) => state.user);

  useEffect(() => {
      setLoading(true)
      const fetchHostProfile = async () => {
        try {
          const response = await getHostProfile();
          setProfileData(response); 
          const fetchedProperties = response.user?.properties || [];
          setProperties(fetchedProperties);

          // Map out and store the property IDs
        const ids = fetchedProperties.map(property => property.id);
        setPropertyIds(ids);  
        } catch (error) {
          console.error(error);
        }
      };
    
      fetchHostProfile();
      setLoading(false)
    }, []);  
    
    // Function to calculate the average rating
  const calculateAverageRating = (reviews) => {
    if (!reviews || reviews.length === 0) return 0; // No reviews, return 0 rating

    const totalRating = reviews.reduce((sum, review) => {
      return sum + (review.rating?.rating || 0); 
    }, 0);

    const averageRating = totalRating / reviews.length;
    return averageRating.toFixed(1); 
  };


  if (loading) {
    return (
      <div className="flex justify-center items-center h-screen">
        <div className="w-16 h-16 border-4 border-t-4 border-gray-200 border-t-blue-500 border-solid rounded-full animate-spin"></div>
      </div>
    );
  }

  if (error) {
    navigate("/*");
  }
 const goToProperty = (propertyId) => { navigate(`/view-property/${propertyId}`); };
 const goToReviews = () => {
  navigate('/hostreview', { state: { propertyIds } });
};

  //destructuring the data
  const user = profileData?.user || {};
const address = user.address || {}; 
const { firstName = "-", lastName = "-" } = user;
const { country = "-", city = "-" } = address;

  return(
    <>
    <div className="max-w-[1440px] mx-auto px-4">
      <div className="max-w-[1088px] mx-auto mt-[50px] flex flex-col gap-[52px]">
        <div className="flex flex-col md:flex-row justify-between items-center">
          {/* Section 1: Profile Information */}
          <div className="flex items-center gap-3">
            <div className="relative w-[100px] h-[100px] flex-shrink-0">
              <Avatar className="cursor-pointer w-full h-full">
                <AvatarImage src={currentUser?.avatar} alt="avatar" />
                <AvatarFallback>CN</AvatarFallback>
              </Avatar>
              <span className="absolute bottom-0 right-1 w-[20px] h-[20px] bg-[#17C1A6] rounded-full border-2 border-white"></span>
            </div>
            <div>
              <div className="flex items-center">
                <h2 className="text-[25px] font-semibold">
                  {firstName} {lastName}
                </h2>
                <img
                  src={Verify}
                  alt="Verified"
                  className="ml-1 w-[20px] h-[20px]"
                />
              </div>

                <div className="flex items-center mt-2">
                  <img
                    src={Location}
                    alt="Location"
                    className="w-[22px] h-[22px]"
                  />
                  <p className="ml-2 opacity-50 text-[15px]">
                    {city}, {country}
                  </p>
                </div>
              
            </div>
          </div>

          {/* Section 2: View/Edit Profile */}
          <div className="flex flex-col gap-3 mt-4 md:mt-0">
            <div className="flex flex-col sm:flex-row gap-3">
              <button
                onClick={() => setIsPublicView(!isPublicView)}
                className={`border border-blue-600 text-white px-3 py-2 text-sm rounded-xl mb-2 sm:mb-0 flex items-center justify-center w-full sm:w-auto
                                ${
                                  isPublicView ? "bg-laara-primary" : "bg-white"
                                }`}
              >
                <img
                  src={isPublicView ? eye : eyeIcon}
                  alt={`${
                    isPublicView ? "See Private View" : "See Public View"
                  } icon`}
                  className="w-3 h-3 mr-2"
                />
                <span
                  className="text-sm font-normal"
                  style={{ color: isPublicView ? "white" : "#0466c8" }}
                >
                  {isPublicView ? "See Private View" : "See Public View"}
                </span>
              </button>

              {!isPublicView && (
                <button
                  onClick={() => navigate(`/host-edit/${hostId}`)}
                  className={`relative bg-laara-primary text-white px-3 py-2 text-sm rounded-xl mb-2 sm:mb-0 flex items-center justify-center w-full sm:w-auto`}
                >
                  <img
                    src={editIcon}
                    alt={`Edit Profile icon`}
                    className={`w-3 h-3 mr-2`}
                  />
                  <span className="text-[16px] font-normal leading-[19.2px] tracking-[0.005em]">
                    Edit Profile
                  </span>
                </button>
              )}
            </div>

            {!isPublicView && (
              <div className="flex justify-end">
                <button
                  onClick={() => navigate("/invite-property-manager")}
                  className={`relative bg-laara-primary text-white px-3 py-2 text-sm rounded-xl mb-2 sm:mb-0 flex items-center justify-center w-[220px]`}
                >
                  <span className="text-[16px] font-normal leading-[19.2px] tracking-[0.005em]">
                    Invite Property Manager
                  </span>
                </button>
              </div>
            )}
          </div>
        </div>

        {/* Section 5: Profile Details */}
        {!isPublicView && (
          <div className="flex flex-col lg:flex-row justify-between gap-8 lg:gap-16">
            {/* Side Section */}
            <div className="w-full lg:w-[333px] flex flex-col justify-between">
              <div className="max-w-[333px] h-full flex flex-col">
                <div className="w-full flex">
                  <div className="w-full p-12 pt-2">
                    <span className="text-sm sm:text-base text-[#626262]">
                      Listed Properties
                    </span>
                    <h2 className="font-semibold text-laara-primary text-3xl lg:text-[26px] leading-tight p-3 pl-0">
                      {profileData?.user?.properties?.length || 0}{" "}
                      {/* Display property count */}
                    </h2>
                  </div>
                </div>
                <div className="w-full flex pr-4 mb-10">
                  <div className="w-full p-12 pt-2">
                    <span className="text-sm sm:text-base text-[#626262]">
                      Bookings
                    </span>
                    <h2 className="font-semibold text-laara-primary text-3xl lg:text-[26px] leading-tight p-3 pl-0">
                      {profileData?.user?.properties?.reduce((total, property) => {
                        return total + (property.rooms?.reduce((count, room) => count + (room._count?.bookings || 0), 0) || 0);
                      }, 0) || 0}
                      {/* Display booking count */}
                    </h2>
                  </div>
                </div>
              </div>
            </div>

            {/* vertical separator */}
            <div className="hidden lg:block h-auto w-px bg-gray-300"></div>

            {/* Main Section */}
            <div className="flex-1 -ml-10">
              {["Email Address", "Phone Number", "Bio"].map((field) => (
                <div
                  key={field}
                  className={`mb-4 p-2 ${field === "Bio" ? "min-h-[132px]" : "min-h-[76px]"} overflow-hidden`}
                >
                  <div className="text-[#4F4F4F] text-lg sm:text-xl font-medium leading-tight mb-2">
                    {field}
                  </div>
                  <div
                    className={`text-[#626262] text-sm sm:text-base ${
                      field === "Phone Number" ? "font-normal" : "font-normal"
                    }`}
                  >
                    {field === "Email Address" && 
                      (profileData?.user?.emailAddress || "Email not provided")}
                      
                    {field === "Phone Number" && 
                      (profileData?.user?.phoneNumber || "-")}
                      
                    {field === "Bio" && 
                      (profileData?.user?.bio || "Bio not provided")}
                  </div>
                </div>
              ))}
              <div className="w-full">
                <div className="w-full flex justify-between items-center">
                  <h2 className="text-[#4F4F4F] text-lg sm:text-xl font-medium leading-tight mb-2">
                      Your Reviews
                  </h2>
                  <button onClick={goToReviews} className="text-blue-500 text-base">
                    See All
                  </button>
                </div>
                <ReviewsSection properties={profileData?.user?.properties || []} />
              </div>


            </div>
            
          </div>
        )}

        

{!isPublicView && (
  <div className="w-full mb-20">
    <div className="flex justify-between items-center mb-4">
      <h3 className="text-[#4F4F4F] text-xl font-medium">
        Listed Properties
      </h3>
      <Link to={`/host-properties/${hostId}`} className="text-blue-500 text-base">
        See All
      </Link>
    </div>
    
    {/* Properties List */}
    <div className="flex flex-col md:flex-row justify-between space-x-0 md:space-x-4">
      {properties.slice(0, 4).map((property, index) => (
        <div
          key={index}
          className="w-full md:w-[350px] max-h-[450px] border rounded-lg overflow-hidden mb-4 md:mb-0 bg-white"
        >
          {/* Property Image */}
          {property.propertyImages?.length > 0 && property.propertyImages[0]?.images?.url ? (
            <img
              src={property.propertyImages[0].images.url}
              alt={property.name}
              className="w-full h-48 object-cover bg-gray-200"
            />
          ) : (
            <div className="w-full h-48 bg-gray-200 flex items-center justify-center">
              <span>No image available</span>
            </div>
          )}

          {/* Property Details */}
          <div className="p-4">
            <h3 className="text-[18px] font-medium leading-[32px] tracking-[0.005em] text-left mb-2">
              {property.name}
            </h3>

            {/* Location & Rating */}
            <div className="flex items-center mb-3 h-[50px]">
              <FontAwesomeIcon
                icon={faLocationDot}
                className="w-[15px] h-[15px] mr-2 text-gray-600"
              />
              <span className="text-sm text-gray-600">
                {property.address?.city ? `${property.address.city}, ` : ""}
                {property.address?.country || "Location not available"}
              </span>

              {/* Rating */}
<div className="ml-auto flex items-center">
  <svg
    className="w-4 h-4 text-yellow-400"
    fill="currentColor"
    viewBox="0 0 20 20"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
  </svg>
  <span className="text-sm text-gray-600 ml-1">
    {calculateAverageRating(property?.reviews) || "No rating"}
  </span>
</div>

            </div>

            {/* View Property Button */}
            <button
              onClick={() => goToProperty(property.id)}
              className="w-full h-[47px] py-2 px-4 gap-2 bg-laara-primary text-white rounded-3xl mb-4 transition duration-300"
            >
              View Property
            </button>

            {/* Unlist Property Link */}
            <Link
              to="#"
              className="block text-red-500 text-[16px] font-semibold leading-[19.2px] tracking-[0.005em] text-center py-2 px-4 underline hover:text-red-600 transition duration-300"
            >
              Unlist Property
            </Link>
          </div>
        </div>
      ))}
    </div>
  </div>
)}

      </div>
      {isPublicView && (
        <div className="mt-4">
          <HostPublic />
        </div>
      )}
    </div>
  );
    </>
  )
};