import React, { useState } from 'react';
import { Bar } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';
import Man1 from "@/assets/AdminDash/Gman1.svg";
import Man2 from "@/assets/AdminDash/Group2.svg";
import HutIcon from "@/assets/AdminDash/hut 1.svg";
import PrintIcon from "@/assets/AdminDash/print.svg";
import ExcelIcon from "@/assets/AdminDash/Excel.svg";
import CalendarIcon from "@/assets/AdminDash/calendar1.svg";
import FilterIcon from "@/assets/AdminDash/FilterB.svg";
import { Search, Plus } from 'lucide-react';
import { ReportModal } from "@/Modals/ReportModal"
import { ReservationModal } from '@/Modals/ReservationModal';

const StatCard = ({ icon: Icon, count, label, isDate = false }) => (
  <div className="bg-white p-4 rounded-lg shadow flex items-center space-x-3">
    <div className={`w-10 h-10 rounded-full flex items-center justify-center ${Icon === Man1 ? 'bg-[#1D80E4]' :
      Icon === Man2 ? 'bg-[#AF3440]' :
        Icon === HutIcon ? 'bg-[#163560]' :
          'bg-[#108975]'
      }`}>
      {typeof Icon === 'string' ? (
        <img src={Icon} alt={label} className="w-6 h-6" />
      ) : (
        <Icon className="w-6 h-6 text-white" />
      )}
    </div>
    <div>
      {isDate ? (
        <>
          <p className="text-base font-medium">Fri, July 26, 2024</p>
          <p className="text-xs text-black">Today</p>
        </>
      ) : (
        <>
          <p className="text-3xl font-medium">{count}</p>
          <p className="text-xs font-normal text-black">{label}</p>
        </>
      )}
    </div>
  </div>
);

function SystemHeader() {
  return (
    <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-4 mb-6">
      <StatCard icon={Man1} count={0} label="Arrivals" />
      <StatCard icon={Man2} count={0} label="Departures" />
      <StatCard icon={HutIcon} count={0} label="Total Guests" />
      <StatCard icon={CalendarIcon} isDate={true} label="Date" />
    </div>
  );
}

function Tabs({ activeTab, onTabChange }) {
  const tabs = ["Today's Arrivals", "Today's Departures", "Today's New Reservations", "Today's Cancellations", "Occupancy"];

  return (
    <div className="mb-2 overflow-x-auto">
      <ul className="flex flex-nowrap text-sm font-medium text-center text-black">
        {tabs.map((tab, index) => (
          <li key={index} className="mr-2 whitespace-nowrap">
            <a
              href="#"
              className={`inline-block p-4 ${activeTab === tab ? 'text-black font-semibold border-b-2 border-[#0466C8]' : 'hover:text-[#0466C8]'}`}
              onClick={() => onTabChange(tab)}
            >
              {tab}
            </a>
          </li>
        ))}
      </ul>
    </div>
  );
}

function ReservationTable({ subModule }) {
  const headers = ['Status', 'Room no.', 'Room Type', 'Guest name', 'Confirmation number', 'Checked-in date', 'Checked-out date', 'Quantity', 'Amount'];
  const [showForm, setShowForm] = useState(false);

  const handleOpenForm = () => setShowForm(true);
  const handleCloseForm = () => setShowForm(false);

  return (
    <div className="bg-white shadow rounded-lg overflow-hidden">
      <div className={`p-4 flex flex-wrap ${subModule === 'Housekeeping' ? 'justify-end' : 'justify-between'} items-center border-b`}>
        {subModule !== 'Housekeeping' && (
          <button className="bg-[#0466C8] text-white px-5 py-2 rounded mb-2 sm:mb-0 " onClick={handleOpenForm} >
            <Plus className="inline-block mr-1 h-4 w-4" /> New reservation</button>
        )}

        {showForm && <ReservationModal onClose={handleCloseForm} />}
        <div className="space-x-2">
          {subModule === 'Frontdesk' && (
            <button>
              <img src={FilterIcon} alt="filter" />
            </button>
          )}
          <button>
            <img src={PrintIcon} alt="print" />
          </button>
          <button >
            <img src={ExcelIcon} alt="excel" />
          </button>
        </div>
      </div>
      <div className="overflow-x-auto">
        <table className="w-full ">
          <thead>
            <tr className="bg-gray-100 ">
              {headers.map((header, index) => (
                <th key={index} className="px-2 py-3 text-left text-sm font-medium text-black border-r">
                  {header}
                </th>
              ))}
            </tr>
          </thead>
          <tbody className="bg-white divide-y divide-gray-200">
            <tr>
              <td colSpan={headers.length} className="px-6 py-4 text-center text-sm text-gray-500">
                No data
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
}

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

const OccupancyChart = () => {
  const [dateRange, setDateRange] = useState('Jul 28, 2024 - Aug 12, 2024');

  const chartData = {
    labels: ['28/07/2023', '28/07/2023', '28/07/2023', '28/07/2023', '28/07/2023', '28/07/2023', '28/07/2023', '28/07/2023'],
    datasets: [
      {
        label: 'Available',
        data: [0, 0, 0, 0, 0, 0, 0, 0],
        backgroundColor: '#3B82F6',
      },
      {
        label: 'Reservation',
        data: [0, 0, 0, 0, 0, 0, 0, 0],
        backgroundColor: '#10B981',
      },
      {
        label: 'Rooms',
        data: [0, 0, 0, 0, 0, 0, 0, 0],
        backgroundColor: '#F59E0B',
      },
    ],
  };

  const chartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      x: {
        stacked: true,
        beginAtZero: true,
        grid: {
          display: false,
        },
      },
      y: {
        stacked: true,
        beginAtZero: true,
        grid: {
          display: false,
        },
      },
    },
    plugins: {
      legend: {
        position: 'bottom',
      },
    },
  };

  const tableData = [
    { date: '28/07/2023', availability: 0, reservations: 0, occupancy: '0%', rooms: 0 },
    { date: '28/07/2023', availability: 0, reservations: 0, occupancy: '0%', rooms: 0 },
    { date: '28/07/2023', availability: 0, reservations: 0, occupancy: '0%', rooms: 0 },
    { date: '28/07/2023', availability: 0, reservations: 0, occupancy: '0%', rooms: 0 },
    { date: '28/07/2023', availability: 0, reservations: 0, occupancy: '0%', rooms: 0 },
    { date: '28/07/2023', availability: 0, reservations: 0, occupancy: '0%', rooms: 0 },
    { date: '28/07/2023', availability: 0, reservations: 0, occupancy: '0%', rooms: 0 },
    { date: '28/07/2023', availability: 0, reservations: 0, occupancy: '0%', rooms: 0 },
  ];

  return (
    <div className="bg-white p-6 rounded-lg shadow-md w-full max-w-[1049px]">
      <div className="flex justify-between items-center mb-4 w-full max-w-[800px] bg-white border border-black">
        <button className="text-black ml-6">{'<'}</button>
        <span className="text-xs font-medium ">{dateRange}</span>
        <button className="text-black mr-6">{'>'}</button>
      </div>
      <div className="mb-8 w-full max-w-[800px] h-[399px]">
        <Bar data={chartData} options={chartOptions} />
      </div>

      <div className="overflow-x-auto">
        <table className="w-full max-w-[900px] border-collapse">
          <thead>
            <tr>
              <th className="border p-2"></th>
              {tableData.map((row, index) => (
                <th key={index} className="border p-2 text-left font-normal text-sm">{row.date}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className="border p-2 text-sm font-medium">Availability</td>
              {tableData.map((row, index) => (
                <td key={index} className="border p-2">{row.availability}</td>
              ))}
            </tr>
            <tr>
              <td className="border p-2  text-sm font-medium">Number of reservations</td>
              {tableData.map((row, index) => (
                <td key={index} className="border p-2">{row.reservations}</td>
              ))}
            </tr>
            <tr>
              <td className="border p-2  text-sm font-medium">Occupancy</td>
              {tableData.map((row, index) => (
                <td key={index} className="border p-2">{row.occupancy}</td>
              ))}
            </tr>
            <tr>
              <td className="border p-2 text-sm font-medium">Rooms</td>
              {tableData.map((row, index) => (
                <td key={index} className="border p-2">{row.rooms}</td>
              ))}
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};

function Security() {
  const [selectedMonth, setSelectedMonth] = useState('July');

  return (
    <div className="container mx-auto p-4">
      <SecHeader />
      <div className="flex flex-col lg:flex-row mt-4">
        <div className="w-full lg:w-3/4 lg:pr-4">
          <div className="mb-4 float-right">
            <select
              value={selectedMonth}
              onChange={(e) => setSelectedMonth(e.target.value)}
              className="border p-2 rounded font-medium text-xs"
            >
              {['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'].map((month) => (
                <option key={month}>{month}</option>
              ))}
            </select>
          </div>
          <BarChart />
          <WeeklyReportOverview />
        </div>
        <div className="w-full lg:w-1/3 mt-4 lg:mt-0">
          <ReportHistory />
        </div>
      </div>
    </div>
  );
}

function SecHeader() {
  const [isModalOpen, setIsModalOpen] = useState(false);

  return (
    <>
      <header className="mb-4">
        <button onClick={() => setIsModalOpen(true)} className="bg-[#0466C8] text-white px-8 py-2 rounded">
          <Plus className="inline-block mr-1 h-4 w-4" /> New report
        </button>
      </header>

      <ReportModal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)} />
    </>
  );
}

function BarChart() {
  const data = {
    labels: ['24', '25', '26', '27', '28', '29', '30'],
    datasets: [
      {
        label: 'High',
        data: [335, 235, 280, 150, 225, 90, 140],
        backgroundColor: '#17C1A6',
        barPercentage: 0.5,
      },
      {
        label: 'Medium',
        data: [190, 135, 85, 280, 90, 185, 185],
        backgroundColor: '#EDA145',
        barPercentage: 0.5,
        categoryPercentage: 0.8,
      },
    ],
  };

  const options = {
    responsive: true,
    scales: {
      y: {
        beginAtZero: true,
        max: 400,
      },
    },
  };

  return <Bar data={data} options={options} />;
}

function WeeklyReportOverview() {
  const [reports, setReports] = useState([
    { id: 1, risk: 'High', name: 'John Scott', report: 'Lorem ipsum dolor sit amet, consectetur adipisic...', roomNo: '001', date: '28/07/2024' },
    { id: 2, risk: 'Low', name: 'Jane Doe', report: 'Lorem ipsum dolor sit amet, consectetur adipisic...', roomNo: '002', date: '28/07/2024' },
    { id: 3, risk: 'High', name: 'Alice Johnson', report: 'Lorem ipsum dolor sit amet, consectetur adipisic...', roomNo: '003', date: '28/07/2024' },
    { id: 4, risk: 'High', name: 'Bob Brown', report: 'Lorem ipsum dolor sit amet, consectetur adipisic...', roomNo: '004', date: '28/07/2024' },
    { id: 5, risk: 'Low', name: 'Charlie Davis', report: 'Lorem ipsum dolor sit amet, consectetur adipisic...', roomNo: '005', date: '28/07/2024' },
    { id: 6, risk: 'High', name: 'Diana Evans', report: 'Lorem ipsum dolor sit amet, consectetur adipisic...', roomNo: '006', date: '28/07/2024' },
  ]);

  const handleRiskChange = (id, newRisk) => {
    setReports(prevReports =>
      prevReports.map(report =>
        report.id === id ? { ...report, risk: newRisk } : report
      )
    );
  };

  return (
    <div className="mt-8">
      <h2 className="text-xs font-semibold mb-4">Weekly Report Overview</h2>
      <table className="w-full border-collapse">
        <thead>
          <tr className="bg-gray-200 font-normal text-xs">
            <th className="p-3 text-left">Risk level</th>
            <th className="p-3 text-left">Name</th>
            <th className="p-3 text-left">Report</th>
            <th className="p-3 text-left">Room no.</th>
            <th className="p-3 text-left">Date</th>
          </tr>
        </thead>
        <tbody>
          {reports.map((report) => (
            <tr key={report.id} className="border-b text-xs">
              <td className="p-3">
                <select
                  className={`w-full h-7 text-center ${report.risk === 'High' ? 'bg-[#17C1A6]' : 'bg-[#EDA145]'
                    }`}
                  value={report.risk}
                  onChange={(e) => handleRiskChange(report.id, e.target.value)}
                >
                  <option value="High">High</option>
                  <option value="Low">Low</option>
                </select>
              </td>
              <td className="p-3 font-medium">{report.name}</td>
              <td className="p-3 font-normal">{report.report}</td>
              <td className="p-3 font-normal">{report.roomNo}</td>
              <td className="p-3 font-normal">{report.date}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

function ReportHistory() {
  const [searchTerm, setSearchTerm] = useState('');
  const reports = [
    { name: 'John Scott', date: '28/06/2024', status: 'green' },
    { name: 'Jane Doe', date: '28/06/2024', status: 'yellow' },
    { name: 'Alice Johnson', date: '28/06/2024', status: 'yellow' },
    { name: 'Bob Brown', date: '28/06/2024', status: 'green' },
    { name: 'Charlie Davis', date: '28/06/2024', status: 'yellow' },
    { name: 'Diana Evans', date: '28/06/2024', status: 'green' },
    { name: 'Ethan Foster', date: '28/06/2024', status: 'green' },
    { name: 'Grace Harris', date: '28/06/2024', status: 'yellow' },
    { name: 'Henry Irving', date: '28/06/2024', status: 'green' },
    { name: 'Isla Johnson', date: '28/06/2024', status: 'green' },
  ];

  const filteredReports = reports.filter(report =>
    report.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <div className="bg-white border shadow rounded-lg p-4 w-full h-full ">
      <div className="relative mb-4">
        <input
          type="text"
          placeholder="Report History"
          className="w-full pl-4 pr-10 py-2 text-xs sm:text-sm font-normal text-[#626262] border rounded-lg focus:outline-none"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
        <div className="absolute inset-y-0 right-0 flex items-center pr-3 pointer-events-none">
          <Search className="h-4 w-4 sm:h-5 sm:w-5 text-gray-400" />
        </div>
      </div>
      <ul className="overflow-y-auto space-y-2">
        {filteredReports.map((report, index) => (
          <li key={index} className="flex justify-between items-center p-2 border-b border-gray-200 hover:bg-gray-50 transition-colors duration-150">
            <div className="flex-grow pr-2">
              <p className="font-semibold text-sm">{report.name}</p>
              <p className="text-xs text-black">Theft {report.date}</p>
            </div>
            <span className={`w-3 h-3 flex-shrink-0 ${report.status === 'green' ? 'bg-[#17C1A6]' : 'bg-[#EDA145]'}`}></span>
          </li>
        ))}
      </ul>
    </div>
  );
}

const SystemManagement = ({ subModule }) => {
  const [activeTab, setActiveTab] = useState("Today's Arrivals");

  const renderContent = () => {
    switch (subModule) {
      case 'Overall':
        return (
          <>
            <SystemHeader />
            <Tabs activeTab={activeTab} onTabChange={setActiveTab} />
            {activeTab === "Occupancy" ? <OccupancyChart /> : <ReservationTable subModule={subModule} />}
          </>
        );
      case 'Frontdesk':
        return <ReservationTable subModule={subModule} />;
      case 'Housekeeping':
        return <ReservationTable subModule={subModule} />;
      case 'Security':
        return <Security />;
      default:
        return <div>Invalid submodule</div>;
    }
  };

  return (
    <div className="container mx-auto p-4">
      <h2 className="text-2xl font-bold mb-4">{subModule}</h2>
      {renderContent()}
    </div>
  );
};

export default SystemManagement;
