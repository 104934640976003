// eslint-disable-next-line no-unused-vars
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Link, useParams } from 'react-router-dom'; 
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';

const Overlay = ({ closeOverlay }) => {
    const { id } = useParams();
    
    console.log('Rendering Overlay with id:', id);

    const [showDropdown, setShowDropdown] = useState(false);
    const toggleDropdown = () => setShowDropdown(!showDropdown);

    return (
        <div style={{
            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: 'rgba(0,0,0,0.5)',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            zIndex: 1000
        }}>
            <div style={{ backgroundColor: 'white', padding: '20px', borderRadius: '8px', position: 'relative', width: '600px', maxHeight: '90vh', overflowY: 'auto' }} className='text-gray-700 cursor-pointer'>
                <button onClick={closeOverlay} style={{ position: 'absolute', top: '10px', right: '10px' }} className='rounded-full w-[25px] h-[25px] border-gray-700 border font-medium hover:bg-gray-700 hover:text-white transition-all duration-300 ease-in'>X</button>
                <h2 className="text-base mb-4 font-medium">Select a section to edit</h2>
                <ul className='w-full p-2 flex flex-col items-center text-[14px] gap-3'>
                    {/* <Link to={`/edit-property-name/${id}`} className='w-full'>
                        <li className='hover:bg-custom-gray-200 w-full h-[30px] rounded-[36px] transition-all duration-300 ease-in flex items-center justify-center'>Name of your Place</li>
                    </Link> */}
                    <Link to={`/edit-property-description/${id}`} className='w-full'>
                        <li className='hover:bg-custom-gray-200 w-full h-[30px] rounded-[36px] transition-all duration-300 ease-in flex items-center justify-center'>Property Description</li>
                    </Link>
                    <Link to={`/edit-property-accessibility-features/${id}`} className='w-full'>
                        <li className='hover:bg-custom-gray-200 w-full h-[30px] rounded-[36px] transition-all duration-300 ease-in flex items-center justify-center'>Accessibility features</li>
                    </Link>
                    {/* <Link to={`/edit-property-availability/${id}`} className='w-full'>
                        <li className='hover:bg-custom-gray-200 w-full h-[30px] rounded-[36px] transition-all duration-300 ease-in flex items-center justify-center'>Availability</li>
                    </Link> */}
                    <Link to={`/edit-property-cancellation/${id}`} className='w-full'>
                        <li className='hover:bg-custom-gray-200 w-full h-[30px] rounded-[36px] transition-all duration-300 ease-in flex items-center justify-center'>Cancellation</li>
                    </Link>
                    <Link to={`/edit-property-houserules/${id}`} className='w-full'>
                        <li className='hover:bg-custom-gray-200 w-full h-[30px] rounded-[36px] transition-all duration-300 ease-in flex items-center justify-center'>Check in Check out time</li>
                    </Link>
                    <Link to={`/edit-property-general-facilities/${id}`} className='w-full'>
                        <li className='hover:bg-custom-gray-200 w-full h-[30px] rounded-[36px] transition-all duration-300 ease-in flex items-center justify-center'>General Facilities</li>
                    </Link>
                    <Link to={`/edit-property-language/${id}`} className='w-full'>
                        <li className='hover:bg-custom-gray-200 w-full h-[30px] rounded-[36px] transition-all duration-300 ease-in flex items-center justify-center'>Language spoken by staff</li>
                    </Link>
                    <Link to={`/edit-property-location/${id}`} className='w-full'>
                        <li className='hover:bg-custom-gray-200 w-full h-[30px] rounded-[36px] transition-all duration-300 ease-in flex items-center justify-center'>Property Location</li>
                    </Link>
                    <Link to={`/edit-property-meals/${id}`} className='w-full'>
                        <li className='hover:bg-custom-gray-200 w-full h-[30px] rounded-[36px] transition-all duration-300 ease-in flex items-center justify-center'>Meal Plans</li>
                    </Link>
                    <Link to={`/edit-property-parking/${id}`} className='w-full'>
                        <li className='hover:bg-custom-gray-200 w-full h-[30px] rounded-[36px] transition-all duration-300 ease-in flex items-center justify-center'>Parking</li>
                    </Link>
                    <li className='w-full'>
                        <div className='relative'>
                            <button
                                onClick={toggleDropdown}
                                className="flex items-center justify-center gap-4 w-full hover:bg-custom-gray-200 h-[30px] rounded-[36px] transition-all duration-300 ease-in"
                            >
                                Photos
                                <FontAwesomeIcon
                                    icon={faChevronDown}
                                    className={`ml-2 transition-transform ${showDropdown ? 'rotate-180' : ''}`}
                                />
                            </button>
                            {showDropdown && (
                                <ul className="absolute w-full space-y-1 bg-base-white shadow-md p-4 rounded-xl">
                                    <Link to={`/edit-facility-images/${id}`} className='w-full'>
                                    <li className='hover:bg-custom-gray-200 w-full h-[30px] rounded-[36px] transition-all duration-300 ease-in flex items-center justify-center text-gray-600'>
                                        Facility
                                    </li>
                                    </Link>
                                    <Link to={`/edit-staff-images/${id}`} className='w-full'>
                                    <li className='hover:bg-custom-gray-200 w-full h-[30px] rounded-[36px] transition-all duration-300 ease-in flex items-center justify-center text-gray-600'>
                                        Staff
                                    </li>
                                    </Link>
                                    <Link to={`/edit-food-images/${id}`} className='w-full'>
                                    <li className='hover:bg-custom-gray-200 w-full h-[30px] rounded-[36px] transition-all duration-300 ease-in flex items-center justify-center text-gray-600'>
                                        Food
                                    </li>
                                    </Link>
                                </ul>
                            )}
                        </div>
                    </li>
                    <Link to={`/edit-property-safety-facilities/${id}`} className='w-full'>
                        <li className='hover:bg-custom-gray-200 w-full h-[30px] rounded-[36px] transition-all duration-300 ease-in flex items-center justify-center'>Safety Facilities</li>
                    </Link>
                    {/* Add more items as needed */}
                </ul>
            </div>
        </div>
    );
};

Overlay.propTypes = {
    closeOverlay: PropTypes.func.isRequired,
};

export default Overlay;
