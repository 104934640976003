import { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import home from '../../../assets/ListingProcess/home.svg'
import image1 from '../../../assets/ListingProcess/image1.svg'
import image2 from '../../../assets/ListingProcess/image2.svg'
import logo from '../../../assets/ListingProcess/logo.svg'
import ke from "../../../assets/settings/keflag.svg";
import tick from '../../../assets/ListingProcess/tick.svg'
import web from '../../../assets/ListingProcess/web.svg'
import quick from '../../../assets/ListingProcess/quick.svg'
import support from '../../../assets/ListingProcess/support.svg'
import secure from '../../../assets/ListingProcess/secure.svg'
import commission from '../../../assets/ListingProcess/commission.svg'
import Navbar from './HostNavbar/Navbar';
const faqs = [
  {
    question: "How do I list my property?",
    answer: "To list your property, click on the 'List A Property' button and follow the on-screen instructions to complete the registration process."
  },
  {
    question: "What are the benefits of listing on Laara stays?",
    answer: "Listing on Laara stays provides you with access to a global customer base, quality bookings, and full control over your property and finances."
  },
  {
    question: "How can I manage my bookings?",
    answer: "You can manage your bookings through our easy-to-use platform. Update prices, availability, and respond to guest inquiries all in one place."
  }
];
const sections = [
  {
    src: quick,
    title: "Quick registration",
    description: "Set up your property listing in 15 minutes",
  },
  {
    src: support,
    title: "Robust support",
    description: "We offer customer support in 40 languages",
  },
  {
    src: secure,
    title: "Secure growth",
    description: "Get guaranteed payouts and fraud protection",
  },
  {
    src: commission,
    title: "Clear commission",
    description: "Set up your property listing in 15 minutes",
  },
];
const SectionCard = ({ src, title, description }) => (
  <div className="flex flex-col flex-1 justify-center items-center">
    <img loading="lazy" src={src} className="aspect-square w-[76px]" />
    <div className="self-stretch mt-5 text-3xl text-center text-black">
      {title}
    </div>
    <div className="mt-5 text-xl text-center text-neutral-500">
      {description}
    </div>
  </div>
);
export default function LandingPage() {
    const [activeIndex, setActiveIndex] = useState(null);
    const navigate = useNavigate();

    const toggleFAQ = (index) => {
      setActiveIndex(activeIndex === index ? null : index);
    };
  return (
    <div className="flex flex-col">
      <Navbar/>
      <div className="flex flex-col w-full bg-white max-md:max-w-full">
      <div className="flex flex-col justify-center py-20 pr-1 pl-16 w-full bg-laara-primary max-md:pl-5 max-md:max-w-full">
        <div className="mt-24 mb-32 ml-4 max-md:my-10 max-md:max-w-full">
            <div className="flex gap-5 max-md:flex-col">
             <div className="flex flex-col w-6/12 max-md:ml-0 max-md:w-full">
                <div className="flex flex-col grow text-white max-md:mt-10 max-md:max-w-full">
                <div className="text-6xl font-semibold tracking-widest max-md:max-w-full max-md:text-4xl">
                <span>List your <br />   </span> 
                    <span className=' mt-20'>property <br /></span>
                    <span>on Laara stays{" "}</span>
                </div>
                <div className="mt-8 text-xl max-md:max-w-full">
                    Whether hosting is your side passion or full-time <br />
                    job, register your holiday rental on Laara.com <br />
                    reach travellers worldwide
                </div>
                <Link to="/hostlogin" className="px-3.5 py-4 mt-12 text-lg font-medium tracking-normal leading-5 text-center text-laara-primary bg-white rounded-[36px] max-md:px-5 max-md:mt-10 max-md:w-52 md:max-w-[459px]">
                    Get started
                </Link>
                </div>
            </div>
            <div className="flex flex-col ml-auto w-6/12 max-md:ml-0 max-md:w-full">
                <img
                loading="lazy"
                srcSet={home}
                className="mt-12 w-full aspect-[1.35]  max-md:mt-10 max-md:max-w-full"
                />
            </div>
            </div>
        </div>
    </div>
        <div className="flex justify-center items-center px-16 py-16 w-full bg-slate-50 max-md:px-5 max-md:max-w-full">
          <div className="flex overflow-hidden relative flex-col px-14 py-20 max-w-full min-h-[482px] w-[884px] max-md:px-5">
            <img
              loading="lazy"
              src="https://cdn.builder.io/api/v1/image/assets/TEMP/26bc2d1f6e951f5fddd2e275eefbff6e2d42cafa578352d8c01e8410714c9295?"
              className="object-cover absolute inset-0 size-full"
            />
            <div className="relative mt-28 text-4xl font-medium text-laara-primary max-md:mt-10 max-md:max-w-full">
              Reach a unique global customer base
            </div>
            <div className="relative self-center mt-6 mb-16 text-xl text-black w-[598px] max-md:mb-10 max-md:max-w-full">
              When you list your property on laara stays customers from all over
              the world are able to notice and book your property
            </div>
          </div>
        </div>
      </div>
      <div className="flex flex-col pt-5 w-full bg-white max-md:max-w-full ">
        <div className="self-center w-full max-w-[1098px] max-md:max-w-full max-md:px-5">
          <div className="flex gap-5 max-md:flex-col">
            <div className="flex flex-col w-6/12 max-md:ml-0 max-md:w-full">
              <div className="flex overflow-hidden relative flex-col grow justify-center rounded-xl min-h-[416px] max-md:mt-10 max-md:max-w-full">
                <img
                  loading="lazy"
                  srcSet={image1}
                  className="w-full aspect-[1.18] max-md:max-w-full"
                />
              </div>
            </div>
            <div className="flex flex-col ml-5 w-6/12 max-md:ml-0 max-md:w-full">
              <div className="flex flex-col self-stretch my-auto max-md:mt-10 max-md:max-w-full">
                <div className="flex flex-col justify-center pt-2.5 max-md:max-w-full">
                  <div className="flex flex-col px-5 max-md:max-w-full">
                    <div className="text-2xl font-semibold tracking-wide text-black max-md:max-w-full">
                      Get quality bookings quickly
                    </div>
                    <div className="flex gap-5 mt-8 text-xl text-zinc-800 max-md:flex-wrap">
                      <img
                        loading="lazy"
                        src={tick}
                        className="shrink-0 my-auto w-6 aspect-[1.59]"
                      />
                      <div className="flex-auto max-md:max-w-full">
                        Our listing strength checklist helps you complete your
                        property setup to attract more guests
                      </div>
                    </div>
                  </div>
                </div>
                <div className="flex gap-5 px-5 mt-6 text-xl text-zinc-800 max-md:flex-wrap">
                  <img
                    loading="lazy"
                    src={tick}
                    className="shrink-0 my-auto w-6 aspect-[1.59]"
                  />
                  <div className="flex-auto max-md:max-w-full">
                    Our listing strength checklist helps you complete your
                    property setup to attract more guests
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="flex justify-center items-center px-16 py-20 mt-24 w-full bg-stone-50 bg-opacity-60 max-md:px-5 max-md:mt-10 max-md:max-w-full">
          <div className="px-px mt-14 mb-7 w-full max-w-[1106px] max-md:mt-10 max-md:max-w-full">
            <div className="flex gap-5 max-md:flex-col">
              <div className="flex flex-col w-6/12 max-md:ml-0 max-md:w-full">
                <div className="flex flex-col self-stretch my-auto max-md:mt-10 max-md:max-w-full">
                  <div className="text-2xl font-semibold tracking-wide text-black max-md:max-w-full">
                    Maintain full control over your property and finances
                  </div>
                  <div className="flex gap-5 mt-8 text-xl text-zinc-800 max-md:flex-wrap">
                    <img
                      loading="lazy"
                      src={tick}
                      className="shrink-0 my-auto w-6 aspect-[1.59]"
                    />
                    <div className="flex-auto">
                      Receive protection against liability claims from guests
                      and neighbours up to €/£/$1,000,000 for every reservation
                    </div>
                  </div>
                </div>
              </div>
              <div className="flex flex-col ml-5 w-6/12 max-md:ml-0 max-md:w-full">
                <div className="flex overflow-hidden relative flex-col grow justify-center rounded-xl shadow-2xl min-h-[416px] max-md:mt-10 max-md:max-w-full">
                  <img
                    loading="lazy"
                    srcSet={image2}
                    className="object-cover absolute inset-0 size-full"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="flex justify-center items-center px-16 py-20 w-full bg-white max-md:px-5 max-md:max-w-full">
    <div className="flex flex-col mb-5 max-w-full w-[925px]">
      <div className="text-center text-4xl font-medium text-black max-md:max-w-full">
        Manage your property with ease
      </div>
      <div className="flex gap-5 mt-10 max-md:flex-col max-md:pr-5 max-md:mt-10 max-md:max-w-full">
        {sections.slice(0, 2).map((section, index) => (
          <SectionCard
            key={index}
            src={section.src}
            title={section.title}
            description={section.description}
          />
        ))}
      </div>
      <div className="flex gap-5 justify-between px-px mt-12 max-md:flex-col max-md:mt-10 max-md:max-w-full">
        {sections.slice(2).map((section, index) => (
          <SectionCard
            key={index}
            src={section.src}
            title={section.title}
            description={section.description}
          />
        ))}
      </div>
    </div>
  </div>
      <div className="flex flex-col justify-center px-12 py-16 w-full bg-slate-50 max-md:px-5 max-md:max-w-full">
      <div className="justify-between max-md:max-w-full">
        <div className="flex gap-5 max-md:flex-col">
          <div className="flex flex-col w-full max-md:ml-0 max-md:w-full">
            <div className="flex flex-col grow font-medium text-black max-md:max-w-full">
              <div className="text-[40px] max-md:max-w-full">
                Your questions answered
              </div>
              <div className="flex flex-col gap-5 justify-center px-0.5 py-7 mt-5 text-3xl max-md:flex-wrap max-md:mt-10 max-md:max-w-full">
              {faqs.map((faq, index) => (
                <div
                  key={index}
                  className="mb-5 border-b-2 border-gray-300 cursor-pointer"
                  onClick={() => toggleFAQ(index)}
                >
                  <div className="flex justify-between items-center py-3 text-xl font-medium">
                    {faq.question}
                     <svg
                className={`transform transition-transform duration-3000 ${
                  activeIndex === index ? 'rotate-180' : ''
                }`}
                width="40"
                height="40"
                viewBox="0 0 40 40"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M33.2008 14.9141L22.3341 25.7807C21.0508 27.0641 18.9508 27.0641 17.6674 25.7807L6.80078 14.9141"
                  stroke="#6D6D6D"
                  strokeWidth="2.5"
                  strokeMiterlimit="10"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
                  </div>
                  {activeIndex === index && (
                    <div className="py-3 text-lg text-gray-700">
                      {faq.answer}
                    </div>
                  )}
                </div>
              ))}
                </div>
              <div className="mt-5 text-2xl text-zinc-800 max-md:mt-5 max-md:max-w-full w-full">
                Still have questions? Find answers to all your questions on our FAQ
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    </div>
  )
}
