
import { useEffect, useState } from 'react';

import Availability from './AvailabilityTable';
import Overview from './Overview';
import ReviewDetails from './Reviews';
import NearbyAttractions from './NearbyAttractions';
import FAQList from './FAQ';
import HostingAndCancellation from './HouseRules';
import { IoIosFitness } from "react-icons/io";
import { BiSolidDrink } from "react-icons/bi";
import { FaSpa, FaWifi } from "react-icons/fa";
import { useNavigate, useParams } from 'react-router-dom';
import { fetchPropertyById } from '@/api';
import { TfiAngleLeft } from 'react-icons/tfi';


function Hotels() {

  const { id } = useParams();
  const navigate = useNavigate

  const [propertydata, setPropertyData] = useState(null);
  const [loading, setLoading ] = useState(false);



    const scrollToSection = (sectionId) => {
        const element = document.getElementById(sectionId);
        if (element) {
            element.scrollIntoView({ behavior: 'smooth' });
        }
    };


    useEffect ( () => {

      if(!id) {
        console.error ("ID is Undefined, cannot fetch");
        return;
      }
      const GetData = async () => {

        setLoading(true);
        try {
          const Data = await fetchPropertyById(id);
          setPropertyData(Data);
        } catch (err) {
          console.log(err);
        } finally {
          setLoading(false);
        }
      };
      GetData();
    }, [id]);

    if (!propertydata || !propertydata.data) return <p>No data available or error loading data</p>;
    console.log(propertydata)
    console.log(propertydata.data?.name)
    const Images = propertydata.data?.propertyImages;
    const imageUrls = Images.map(imageObj => imageObj.images.url);
    const images = imageUrls
    const address = propertydata.data?.address;
    const avgRating = propertydata.data?.avgRating ?? 0 ;
    const hostNames = propertydata.data?.host;
    const Amenitiesdata = propertydata.data?.propertyAmenities;


    const hotelData = {
        ratingValue: 4.5,
        hotelName: "Grand Vista Hotel",
        address: "1234 Sunset Boulevard, Los Angeles, CA",
        amenities: [
          { icon: <IoIosFitness size={22} />, name: "Fitness Center" },
          { icon: <BiSolidDrink size={22} />, name: "Bar/Lounge" },
          { icon: <FaWifi size={22} />, name: "Free Wifi" },
          { icon: <FaSpa size={22} />, name: "Spa and Wellness Center" },
        ],
        host: {
          image: "https://via.placeholder.com/100?text=Host",
          name: "Alice Johnson",
          description: "Super Host: 10 years Hosting",
          languages: ["English", "French"],
          location: "Based in Los Angeles, CA",
          details: "Welcome to Grand Vista Hotel, your ultimate destination for luxury and comfort. Experience world-class amenities and impeccable service in the heart of Los Angeles. Whether you are here for business or leisure, our hotel provides everything you need for a memorable stay.",
        },
        staff: [
          { image: "https://via.placeholder.com/100?text=Staff+1" },
          { image: "https://via.placeholder.com/100?text=Staff+2" },
          { image: "https://via.placeholder.com/100?text=Staff+3" },
          { image: "https://via.placeholder.com/100?text=Staff+4" },
        ],
      };
      
         

    const propertyData = {
        location: 'Downtown',
        images: [
            { url: 'https://via.placeholder.com/400x300' },
            { url: 'https://via.placeholder.com/600x400' },
            { url: 'https://via.placeholder.com/400x300' },
            // Add more images as needed
        ],
        amenities: ['Fitness Center', 'Bar/Lounge', 'Free Wifi', 'Spa and Wellness Center'],
        host: {
            name: 'John Doe',
            image: 'https://via.placeholder.com/100',
            languages: ['English', 'Spanish'],
            location: 'New York, USA',
        },
        description: 'This space offers a comfortable stay with modern amenities and is situated in a prime location. The host is highly rated and provides excellent service.',
        staff: [
            { image: 'https://via.placeholder.com/100' },
            { image: 'https://via.placeholder.com/100' },
            // Add more staff images as needed
        ],
    };
    
    const hostingRules = [
        'No smoking inside the property.',
        'No pets allowed.',
        'Maximum occupancy is 4 people.',
      ];
      
    const cancellationPolicy = [
        'Free cancellation up to 7 days before check-in.',
        '50% refund for cancellations made within 3-7 days of check-in.',
        'No refund for cancellations made within 3 days of check-in.',
      ];

    const faqData = [
        { question: 'What is your return policy?', answer: 'You can return items within 30 days for a full refund.' },
        { question: 'How long does shipping take?', answer: 'Shipping typically takes 3-5 business days.' },
        { question: 'Do you ship internationally?', answer: 'Yes, we offer international shipping.' },
        { question: 'How can I track my order?', answer: 'You will receive a tracking number via email once your order ships.' },
        // Add more FAQ items here
      ];

    const attractionsData = [
        { name: 'Central Park', distance: 1.2 },
        { name: 'Empire State Building', distance: 2.5 },
        { name: 'Statue of Liberty', distance: 5.1 },
        { name: 'Times Square', distance: 0.9 },
        { name: 'Brooklyn Bridge', distance: 3.8 },
        { name: 'Metropolitan Museum of Art', distance: 2.0 },
      ];

    return (
        <>
            {/* Navigation */}
            <div className='w-full'>
                <hr />
                <div className='w-full mt-4 p-2 lg:4 md:px-10 lg:px-20'>
                    <h3 className='text-[18px] text-[hsla(0,0%,31%,1)] font-semibold mb-4 flex items-center gap-1'>
                        <TfiAngleLeft size={18}  className='hover:scale-150 font-bold hover:shadow-2xl' onClick={() => navigate(-1)} />
                      {propertydata.data?.name}, {propertydata.data?.address.city}, {propertydata.data?.address.county}, {propertydata.data?.address.country}
                    </h3>
                    <nav>
                        <ul className='flex flex-col sm:flex-row p-2.5 sm:flex-wrap gap-2 sm:gap-4'>
                            <li className='text-base relative group'>
                                <button
                                    onClick={() => scrollToSection('overview')}
                                    className='relative  text-gray-800 group-hover:text-blue-400'
                                >
                                    Overview
                                    <span className='block absolute inset-x-0 bottom-[-4px] h-[2px] bg-blue-500 transition-transform transform scale-x-0 group-hover:scale-x-100'></span>
                                </button>
                            </li>
                            <li className='text-base relative group'>
                                <button
                                    onClick={() => scrollToSection('overview')}
                                    className='relative text-gray-800 group-hover:text-blue-400'
                                >
                                    General Amenities
                                    <span className='block absolute inset-x-0 bottom-[-4px] h-[2px] bg-blue-500 transition-transform transform scale-x-0 group-hover:scale-x-100'></span>
                                </button>
                            </li>
                            <li className='text-base relative group'>
                                <button
                                    onClick={() => scrollToSection('rooms-pricing')}
                                    className='relative text-gray-800 group-hover:text-blue-400'
                                >
                                    Rooms Availability & Pricing
                                    <span className='block absolute inset-x-0 bottom-[-4px] h-[2px] bg-blue-500 transition-transform transform scale-x-0 group-hover:scale-x-100'></span>
                                </button>
                            </li>
                            <li className='text-base relative group'>
                                <button
                                    onClick={() => scrollToSection('reviews')}
                                    className='relative text-gray-800 group-hover:text-blue-400'
                                >
                                    Reviews
                                    <span className='block absolute inset-x-0 bottom-[-4px] h-[2px] bg-blue-500 transition-transform transform scale-x-0 group-hover:scale-x-100'></span>
                                </button>
                            </li>
                            <li className='text-base relative group'>
                                <button
                                    onClick={() => scrollToSection('nearby-attractions')}
                                    className='relative text-gray-800 group-hover:text-blue-400'
                                >
                                    Nearby Attractions
                                    <span className='block absolute inset-x-0 bottom-[-4px] h-[2px] bg-blue-500 transition-transform transform scale-x-0 group-hover:scale-x-100'></span>
                                </button>
                            </li>
                            <li className='text-base relative group'>
                                <button
                                    onClick={() => scrollToSection('faq')}
                                    className='relative text-gray-800 group-hover:text-blue-400'
                                >
                                    FAQ
                                    <span className='block absolute inset-x-0 bottom-[-4px] h-[2px] bg-blue-500 transition-transform transform scale-x-0 group-hover:scale-x-100'></span>
                                </button>
                            </li>
                            <li className='text-base relative group'>
                                <button
                                    onClick={() => scrollToSection('cancellation-policy')}
                                    className='relative text-gray-800 group-hover:text-blue-400'
                                >
                                    Cancellation Policy & Rules
                                    <span className='block absolute inset-x-0 bottom-[-4px] h-[2px] bg-blue-500 transition-transform transform scale-x-0 group-hover:scale-x-100'></span>
                                </button>
                            </li>
                        </ul>
                    </nav>
                </div>
            </div>
            <hr className='my-6' />
            {/* Section Content */}
            <div className='w-full  justify-items-center'>
                <div className='w-full mt-4  lg:4 md:px-10 lg:px-20'>
                    <section id='overview' >
                        {/* Content for Overview */}
                        <Overview 
                            ratingValue={avgRating}
                            hotelName={propertydata.data?.name}
                            address={address}
                            images={imageUrls}
                            amenities={Amenitiesdata}
                            host={hotelData.host}
                            staff={hotelData.staff}
                        />
                    </section>                    
                    <section id='rooms-pricing' className='mt-6 '>
                        {/* Content for Rooms Availability & Pricing */}
                        <Availability />
                    </section>
                    <hr className='my-6' />
                    <section id='reviews' className='mt-6'>
                        <h2 className='text-xl font-semibold p-4 mb-4'>Reviews Categories</h2>
                        {/* Content for Reviews */}
                        <ReviewDetails />
                    </section>
                    <hr className='my-6' />
                    <section id='nearby-attractions' className=' p-4'>
                        <h2 className='text-xl font-semibold mb-4'>Nearby Attractions</h2>
                        {/* Content for Nearby Attractions */}
                        <NearbyAttractions attractions={attractionsData} />
                    </section>
                    <hr className='my-6' />
                    <section id='faq' className='mt-6'>
                        {/* Content for FAQ */}
                        <FAQList  faqs={faqData} />
                    </section>
                    <hr className='my-6' />
                    <section id='cancellation-policy' className='mt-6'>
                        <h2 className='text-xl font-semibold mb-4'>Cancellation Policy & Rules</h2>
                        {/* Content for Cancellation Policy & Rules */}
                        <HostingAndCancellation hostingRules={hostingRules}  name={propertydata.data?.name} cancellationPolicy={cancellationPolicy} />
                    </section>
                    <hr className='my-6' />
                </div>
            </div>
        </>
    );
}

export default Hotels;
