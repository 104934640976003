import React from 'react';
// import { Link } from "react-router-dom";
// import { ArrowRight } from 'lucide-react';
import Guest1 from "@/assets/Testimonials/Guest1.svg";
import Guest2 from "@/assets/Testimonials/Guest2.svg";
import Guest3 from "@/assets/Testimonials/Guest3.svg";

const Testimonials = () => {
  return (
    <div className="w-full h-auto px-4 sm:px-6 lg:px-8 xl:px-[88px] relative">
      <h2 className="text-xl sm:text-xl font-semibold text-center mb-6 sm:mb-10 text-[#3C3C3C]">What Our Guests Say</h2>

      <div className="flex flex-col lg:flex-row justify-between items-start h-auto">
        <div className="flex flex-col sm:flex-row lg:flex-col xl:flex-row gap-4 sm:gap-6 lg:gap-4 xl:gap-10 items-center sm:items-start lg:items-center xl:items-end w-full lg:w-auto">
          <div className="w-full sm:w-1/3 lg:w-full xl:w-[203px] h-[200px] sm:h-[260px] rounded-2xl overflow-hidden">
            <img src={Guest1} alt="Guest 1" className="w-full h-full object-cover" />
          </div>

          <div className="w-full sm:w-1/3 lg:w-full xl:w-[203px] h-[200px] sm:h-[260px] rounded-2xl overflow-hidden">
            <img src={Guest2} alt="Guest 2" className="w-full h-full object-cover" />
          </div>

          <div className="w-full sm:w-1/3 lg:w-full xl:w-[315px] h-[300px] sm:h-[350px] lg:h-[400px] xl:h-[453px] rounded-3xl overflow-hidden">
            <img src={Guest3} alt="Featured Guest" className="w-full h-full object-cover" />
          </div>
        </div>

        <div className="flex flex-col justify-between lg:max-w-[50%] h-full mt-8 lg:mt-0 lg:ml-10 xl:ml-20">
          <div className='w-full max-w-[428px] h-auto mt-1 mb-8 lg:mb-60 flex-grow'>
            <h3 className="text-lg text-[#3C3C3C] font-medium">Tommy Murphy</h3>
            <p className="text-[#4F4F4F] font-normal text-lg sm:text-lg">Global Directives Architect</p>
            <p className="mt-2 text-[#4F4F4F] font-normal text-sm sm:text-base">
              "Listing our property was a breeze. The process was smooth, support was excellent, and our bookings have soared. We're thrilled with the results!"
            </p>
          </div>

          {/* <Link to="/testimonials">
            <button className="self-start bg-[#0466C8] text-white p-3 rounded-full w-[50px] h-[50px] mt-auto">
              <ArrowRight size={24} />
            </button>
          </Link> */}
        </div>
      </div>
    </div>
  );
};

export default Testimonials;
