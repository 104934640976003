import React, { useState } from "react";
import Consider from "../../../components/Listings/Consider";
import { setFormData } from "@/redux/Listing/listingSlice";
import { useDispatch, useSelector } from "react-redux";

const PropertyLocation = () => {
  const dispatch = useDispatch();
  const address = useSelector((state) => state.hostListing.formData.address);

  const handlechange = (e) => {
    const { name, value } = e.target;
    dispatch(setFormData({ address: { ...address, [name]: value } }));
  };

  return (
    <form className="flex flex-col">
      <h4 className=" font-medium text-xl ">
        Where is the property you are listing?
      </h4>
      <div className="flex items-start gap-4 mt-5">
        <div className=" py-5 px-4 md:px-9 bg-laara-secondary/35 border border-[#6D6D6D] rounded-[8px] xl:max-w-[728px] w-full h-[618px]   ">
          <div className="max-w-[653px] flex flex-col gap-3 ">
            {/* COUNTRY */}
            <div className="space-y-1">
              <label htmlFor="country" className="name text-base">
                Country/region
              </label>
              <input
                type="text"
                id="country"
                name="country"
                placeholder="Kenya"
                required
                value={address?.country}
                className=" border py-1 px-3 text-base rounded-[8px] w-full outline-none block"
                onChange={handlechange}
              />
            </div>

            {/* COUNTY */}
            <div className="space-y-1">
              <label htmlFor="county" className="name text-base">
                County
              </label>
              <input
                type="text"
                id="county"
                name="county"
                placeholder="Nairobi"
                required
                value={address?.county}
                className=" border py-1 px-3 text-base rounded-[8px] w-full outline-none block"
                onChange={handlechange}
              />
            </div>

            {/* TOWN */}
            <div className="space-y-1">
              <label htmlFor="town" className="name text-base">
                Town
              </label>
              <input
                type="text"
                id="town"
                name="town"
                placeholder="Nairobi"
                required
                value={address?.town}
                className=" border py-1 px-3 text-base rounded-[8px] w-full outline-none block"
                onChange={handlechange}
              />
            </div>

            {/* STREET */}
            <div className="space-y-1">
              <label htmlFor="street" className="name text-base">
                Street
              </label>
              <input
                type="text"
                id="street"
                name="street"
                placeholder="Moi Avenue"
                required
                value={address?.street}
                className=" border py-1 px-3 text-base rounded-[8px] w-full outline-none block"
                onChange={handlechange}
              />
            </div>

            {/* ADDRESS */}
            <div className="space-y-1">
              <label htmlFor="address" className="name text-base">
                Physical address
              </label>
              <input
                type="text"
                id="address"
                name="physicalAddress"
                value={address?.physicalAddress}
                className=" border py-1 px-3 text-base rounded-[8px] w-full outline-none block"
                onChange={handlechange}
              />
            </div>

            {/* APARTMENT */}
            <div className="space-y-1">
              <label htmlFor="apartment" className="name text-base">
                Apartment/floor number (optional)
              </label>
              <input
                type="text"
                id="apartment"
                name="apartment"
                value={address?.apartment}
                className=" border py-1 px-3 text-base rounded-[8px] w-full outline-none block"
                onChange={handlechange}
              />
            </div>

            {/* POST CODE */}
            <div className="space-y-1">
              <label htmlFor="post" className="name text-base">
                Post code
              </label>
              <input
                type="text"
                id="post"
                name="postCode"
                value={address?.postCode}
                className=" border py-1 px-3 text-base rounded-[8px] w-full outline-none block"
                onChange={handlechange}
              />
            </div>

            {/*CITY */}
            <div className="space-y-1">
              <label htmlFor="city" className="name text-base">
                City
              </label>
              <input
                type="text"
                id="city"
                name="city"
                placeholder="Nairobi"
                required
                value={address?.city}
                className="border py-1 px-3 text-base rounded-[8px] w-full outline-none block"
                onChange={handlechange}
              />
            </div>
          </div>
        </div>

        <Consider
          title={"What needs to be included in my address?"}
          data={[
            "enter your street name",
            "enter your house number",
            "enter apartment/floor number if available",
            "provide your post code",
            "spell the street name correctly",
          ]}
        />
      </div>
    </form>
  );
};

export default PropertyLocation;
