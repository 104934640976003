import React from "react";
import { Link } from "react-router-dom";

const Banner = () => {
  return (
    <section className="max-w-[1248px] max-h-[210px] h-full w-full   ">
      <section className="bg-banner1 bg-no-repeat bg-auto bg-bottom max-w-[77rem] w-full h-[12.125rem] rounded-[1rem] ">
        <article className="w-full  text-laara-secondary space-y-3 text-center ">
          <h4 className="text-lg font-bold capitalize pt-3">
            Give your guest a reason to trust you
          </h4>

          <p className="text-base ">
            Try our exclusive feature that allows hosts to be verified. <br />
            Get your unique verification ID today.
          </p>

          <div>
            <Link to="/register" className="ruby-register-btn ">
              Register
            </Link>
          </div>
        </article>
      </section>
    </section>
  );
};

export default Banner;
