import { useState, useEffect } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLessThan } from "@fortawesome/free-solid-svg-icons";
import { getPropertyById, updatePropertyLanguages } from "@/api"; // Adjust import path as needed
import './editproperty.css';

const defaultLanguagesList = [
  "English",
  "Spanish",
  "French",
  "German",
  "Chinese",
];

const EditPropertyLanguage = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  const [selectedLanguages, setSelectedLanguages] = useState([]);
  const [languagesList, setLanguagesList] = useState(defaultLanguagesList);
  const [property, setProperty] = useState(null);

useEffect(() => {
  const fetchProperty = async () => {
    try {
      const propertyData = await getPropertyById(id);
      setProperty(propertyData);
      console.log("Property Data:", propertyData);

      // Extract languages from property data and convert to title case
      const fetchedLanguages =
        propertyData.property.propertyLanguages?.map(
          (lang) => lang.language.name.charAt(0).toUpperCase() + lang.language.name.slice(1).toLowerCase()
        ) || [];
      console.log("Fetched Languages:", fetchedLanguages);

      // Update selectedLanguages with fetched languages
      setSelectedLanguages(fetchedLanguages);

      // Show languages list only if there are languages in the property
      if (fetchedLanguages.length > 0) {
        setLanguagesList((prevList) => [
          ...new Set([...prevList, ...fetchedLanguages]),
        ]); // Avoid duplicates
      }
    } catch (error) {
      console.error("Error fetching property data:", error);
    }
  };

  fetchProperty();
}, [id]);


 const handleLanguageChange = (language) => {
  setSelectedLanguages((prevSelectedLanguages) =>
    prevSelectedLanguages.includes(language)
      ? prevSelectedLanguages.filter((lang) => lang !== language) // Remove if unchecked
      : [...prevSelectedLanguages, language] // Add if checked
  );
};

const handleSubmit = async (event) => {
  event.preventDefault();

  // Convert selected languages to title case
  const formattedLanguages = selectedLanguages.map((language) => ({
    name: language.charAt(0).toUpperCase() + language.slice(1).toLowerCase(), // Ensure title case
  }));

  const requestPayload = {
    languages: formattedLanguages, // Only include checked languages
  };

  console.log("Payload being sent:", requestPayload); // Log for debugging

  try {
    const response = await updatePropertyLanguages(id, requestPayload);
    console.log("Response from server:", response);
    navigate(`/view-property/${id}`);
  } catch (error) {
    console.error("Error updating languages:", error);
  }
};


  return (
    <div className="p-8 bg-base-white">
      <section className="top h-16 flex items-center p-2 gap-3">
        <div className="flex items-center gap-4 text-lg text-gray-600 w-[700px]">
          <Link to={`/view-property/${id}`}>
            <FontAwesomeIcon icon={faLessThan} className="text-base" />
          </Link>
          <h3>Languages Spoken</h3>
        </div>
      </section>
      <form className="flex flex-col p-2" onSubmit={handleSubmit}>
        <h4 className="font-normal text-[18px] leading-[42px]">
          Please select the language spoken by staff
        </h4>
        {languagesList.length > 0 && (
          <div className="flex items-start gap-4 mt-5">
            <div className="p-4 flex flex-col gap-2 bg-laara-secondary/35 max-w-[1248px] w-full h-[360px] border-[0.2px] border-custom-gray-300 justify-center overflow-y-auto">
              {languagesList.map((language) => (
                <div key={language} className="flex items-center">
                  <input
                    type="checkbox"
                    id={language}
                    checked={selectedLanguages.includes(language)}
                    onChange={() => handleLanguageChange(language)}
                    className="custom-checkbox1 mr-2"
                  />
                  <label htmlFor={language} className="text-base">
                    {language}
                  </label>
                </div>
              ))}
            </div>
          </div>
        )}
       
        <div className="w-full flex justify-end">
          <button
            type="submit"
            className="mt-5 py-2 px-4 bg-blue-500 text-white rounded-[36px] w-[216px] h-[55px]"
          >
            Save Changes
          </button>
        </div>
      </form>
    </div>
  );
};

export default EditPropertyLanguage;
