import { useState } from 'react';
import { IoChevronBackSharp } from "react-icons/io5";
import PromotionCard from './PromotionCard'; // Import the PromotionCard component

const Promotions = () => {
  const [activeButton, setActiveButton] = useState('history'); // Default active button

  // Dummy data for the promotion details
  const promoDetails = [
    {
      hotelName: 'Golden Star Hotel',
      offer: 'Black Friday Offer',
      startDate: '2024-11-25',
      endDate: '2024-12-02',
      price: '$70',
      originalPrice: '$1500',
      discount: '50% off',
    },
    {
      hotelName: 'Golden Star Hotel',
      offer: 'Black Friday Offer',
      startDate: '2024-11-25',
      endDate: '2024-12-02',
      price: '$70',
      originalPrice: '$1500',
      discount: '50% off',
    },
    {
      hotelName: 'Golden Star Hotel',
      offer: 'Black Friday Offer',
      startDate: '2024-11-25',
      endDate: '2024-12-02',
      price: '$70',
      originalPrice: '$1500',
      discount: '50% off',
    },
    // Add more dummy data if needed
  ];

  return (
    <main className="flex flex-col p-4 w-full md:w-11/12 lg:w-3/4 xl:w-2/3 mx-16">
      <div className="flex items-center gap-2 mb-4">
        <IoChevronBackSharp size={20} className="hover:scale-150 hover:drop-shadow-lg cursor-pointer" />
        <h1 className="text-sm lg:text-xl font-bold">Promotions</h1>
        <button
          onClick={() => setActiveButton('create')}
          className="ml-auto w-[140px] h-[30px] bg-blue-500 text-white rounded-full p-2 text-xs lg:text-sm hover:bg-blue-600 transition-all duration-300"
        >
          Create Promotion
        </button>
      </div>
      <div className="flex flex-col lg:flex-row gap-4 mb-6 justify-center lg:justify-start">
        <button
          onClick={() => setActiveButton('start')}
          className={`text-sm p-2 border-b-2 ${activeButton === 'start' ? 'text-[hsla(210,96%,40%,1)] border-blue-500' : 'text-black border-transparent'} hover:border-blue-500 hover:text-blue-500 transition-all duration-300`}
        >
          Start Promotion
        </button>
        <button
          onClick={() => setActiveButton('schedule')}
          className={`text-xs lg:text-sm p-2 border-b-2 ${activeButton === 'schedule' ? 'text-[hsla(210,96%,40%,1)] border-blue-500' : 'text-black border-transparent'} hover:border-blue-500 hover:text-blue-500 transition-all duration-300`}
        >
          Schedule a Promotion
        </button>
        <button
          onClick={() => setActiveButton('history')}
          className={`text-sm p-2 border-b-2 ${activeButton === 'history' ? 'text-[hsla(210,96%,40%,1)] border-blue-500' : 'text-black border-transparent'} hover:border-blue-500 hover:text-blue-500 transition-all duration-300`}
        >
          Promotion History
        </button>
      </div>
      <div className="flex  justify-center lg:justify-start gap-4">
        {promoDetails.map((details, index) => (
          <PromotionCard key={index} details={details} />
        ))}
      </div>
    </main>
  );
};

export default Promotions;
