import React, { useState } from 'react'

export const FreeAmenities = () => {
    const [checkedItems, setCheckedItems] = useState({});

    const handleCheckboxChange = (item) => {
        setCheckedItems(prev => ({ ...prev, [item]: !prev[item] }));
    };

    const FreeItems = ['Chairs', 'Pen', 'Notebook', 'Tables', 'Projector', 'Bottled Water', 'Microphones', 'Catering Service', 'AV equipment', 'Decoration Services', 'Built-in Speakers', 'Security Personnel', 'Workspace desk'];

    return (
        <form className='relative space-y-4 sm:space-y-6'>
            <div>
                <h4 className="font-medium text-xl">What Can the guests use in this room?</h4>

                <div className="w-full max-w-full sm:max-w-[433px] p-3 sm:p-4 border bg-[#FFFFFF] rounded-lg">
                    <h2 className="text-xl font-medium mb-1 sm:mb-2">Free Amenities</h2>
                    <p className='text-[#9F9C9C] font-normal text-xs sm:text-sm mb-2 sm:mb-3'>Selected items come at free cost to guests</p>
                    <div className="grid grid-cols-1 gap-4">
                        {FreeItems.map((item) => (
                            <label key={item} className="inline-flex items-center">
                                <input
                                    type="checkbox"
                                    className="mr-2 w-4 h-4 sm:w-5 sm:h-5 accent-laara-primary"
                                    name={item.toLowerCase().replace(' ', '-')}
                                    checked={checkedItems[item] || false}
                                    onChange={() => handleCheckboxChange(item)}
                                />
                                <span className={`text-base sm:text-sm md:text-lg font-normal leading-relaxed cursor-pointer ${checkedItems[item] ? 'text-[#0466C8]' : 'text-black'}`}>
                                    {item}
                                </span>
                            </label>
                        ))}
                    </div>
                </div>
            </div>
        </form>
    )
}