import { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { setPricing } from '@/redux/Listing/listingSlice';
import Header from './globalrooms/Header';
import useFetchRoomDetails from './globalrooms/useFetchRoomDetails'; 
import { useParams } from 'react-router-dom';

const EditPricing = () => {
  const { id, number } = useParams(); 
  console.log('ID:', id, 'Number:', number);
  const dispatch = useDispatch();
  const { rooms, loading, error: fetchError } = useFetchRoomDetails(id);
  const [roomType, setRoomType] = useState('');

  const [state, setState] = useState({
    priceOneGuestHalfBoardDouble: 0,
    priceTwoGuestsHalfBoardDouble: 0,
    priceOneGuestFullBoardDouble: 0,
    priceTwoGuestsFullBoardDouble: 0,
    priceOneGuestHalfBoardSingle: 0,
    priceTwoGuestsHalfBoardSingle: 0,
    priceOneGuestFullBoardSingle: 0,
    priceTwoGuestsFullBoardSingle: 0,
  });
  const [error, setError] = useState('');

  useEffect(() => {  
    if (rooms.length > 0) {
      const room = rooms[0]; 
      setRoomType(room.roomTypes.name);  
      const pricing = room.pricings || [];
    
      const initialState = {
        priceOneGuestHalfBoardDouble: pricing.find(p => p.pricingOption === 'half-board' && p.bedType === 'Double' && p.guests === 1)?.price || 0,
        priceTwoGuestsHalfBoardDouble: pricing.find(p => p.pricingOption === 'half-board' && p.bedType === 'Double' && p.guests === 2)?.price || 0,
        priceOneGuestFullBoardDouble: pricing.find(p => p.pricingOption === 'full-board' && p.bedType === 'Double' && p.guests === 1)?.price || 0,
        priceTwoGuestsFullBoardDouble: pricing.find(p => p.pricingOption === 'full-board' && p.bedType === 'Double' && p.guests === 2)?.price || 0,
        priceOneGuestHalfBoardSingle: pricing.find(p => p.pricingOption === 'half-board' && p.bedType === 'Single' && p.guests === 1)?.price || 0,
        priceTwoGuestsHalfBoardSingle: pricing.find(p => p.pricingOption === 'half-board' && p.bedType === 'Single' && p.guests === 2)?.price || 0,
        priceOneGuestFullBoardSingle: pricing.find(p => p.pricingOption === 'full-board' && p.bedType === 'Single' && p.guests === 1)?.price || 0,
        priceTwoGuestsFullBoardSingle: pricing.find(p => p.pricingOption === 'full-board' && p.bedType === 'Single' && p.guests === 2)?.price || 0,
      };

      setState(initialState);
    }
  }, [rooms]);

  const handleInputChange = (event, key) => {
    const value = event.target.value;
    setState(prevState => {
      const updatedState = {
        ...prevState,
        [key]: value
      };
      
      if (validatePrices(updatedState)) {
        const pricingData = [
          {
            price: parseFloat(updatedState.priceOneGuestHalfBoardDouble),
            pricingOption: 'half-board',
            bedType: 'Double',
            guests: 1,
          },
          {
            price: parseFloat(updatedState.priceTwoGuestsHalfBoardDouble),
            pricingOption: 'half-board',
            bedType: 'Double',
            guests: 2,
          },
          {
            price: parseFloat(updatedState.priceOneGuestFullBoardDouble),
            pricingOption: 'full-board',
            bedType: 'Double',
            guests: 1,
          },
          {
            price: parseFloat(updatedState.priceTwoGuestsFullBoardDouble),
            pricingOption: 'full-board',
            bedType: 'Double',
            guests: 2,
          },
          {
            price: parseFloat(updatedState.priceOneGuestHalfBoardSingle),
            pricingOption: 'half-board',
            bedType: 'Single',
            guests: 1,
          },
          {
            price: parseFloat(updatedState.priceTwoGuestsHalfBoardSingle),
            pricingOption: 'half-board',
            bedType: 'Single',
            guests: 2,
          },
          {
            price: parseFloat(updatedState.priceOneGuestFullBoardSingle),
            pricingOption: 'full-board',
            bedType: 'Single',
            guests: 1,
          },
          {
            price: parseFloat(updatedState.priceTwoGuestsFullBoardSingle),
            pricingOption: 'full-board',
            bedType: 'Single',
            guests: 2,
          }
        ];

        console.log("Dispatching pricing data:", pricingData);
        dispatch(setPricing(pricingData));
      }
      return updatedState;
    });
  };

  const calculateEarnings = (price) => {
    const numericPrice = parseFloat(price);
    return isNaN(numericPrice) ? 0 : Math.max(0, numericPrice - (numericPrice * 0.10));
  };

  const validatePrices = (stateToValidate) => {
    const priceFields = [
      'priceOneGuestHalfBoardDouble',
      'priceTwoGuestsHalfBoardDouble',
      'priceOneGuestFullBoardDouble',
      'priceTwoGuestsFullBoardDouble',
      'priceOneGuestHalfBoardSingle',
      'priceTwoGuestsHalfBoardSingle',
      'priceOneGuestFullBoardSingle',
      'priceTwoGuestsFullBoardSingle'
    ];

    let valid = true;
    let validationError = '';

    for (const field of priceFields) {
      if (stateToValidate[field] === '' || isNaN(parseFloat(stateToValidate[field])) || parseFloat(stateToValidate[field]) <= 0) {
        validationError = `Please enter a valid price for ${field.replace(/([A-Z])/g, ' $1').toLowerCase()}.`;
        valid = false;
        break;
      }
    }
    
    setError(validationError);
    return valid;
  };

  const renderPricingSection = (title, priceKey1, priceKey2, style) => (
    <div className="mt-10 max-md:mt-10 max-md:max-w-full w-[1077px]">
      <div className="flex flex-col md:flex-row gap-5">
        <div className="flex flex-col w-full md:w-[66%]">
          <div className="flex flex-col grow justify-center max-md:mt-6">
            <div className={`flex flex-col justify-center px-8 py-9 rounded-md ${style} border border-neutral-200 max-md:px-5`}>
              <div className="flex flex-col pb-1.5">
                <div className="text-2xl font-medium text-black">{title}</div>
                <div className="flex flex-col gap-5 pt-1.5 mt-7">
                  {[priceKey1, priceKey2].map((key, index) => (
                    <div key={index} className="flex flex-col md:flex-row gap-5">
                      <div className="flex flex-col flex-1 text-black">
                        <div className="text-base font-medium">
                          Price for {index === 0 ? 'one guest' : 'two guests'}
                        </div>
                        <div className="flex items-center py-3 mt-3 text-sm whitespace-nowrap rounded-lg border border-blue-400 transition-colors duration-300">
                          <div className="self-stretch my-auto px-2">US$</div>
                          <div className="shrink-0 self-start w-px h-7 border border-solid bg-neutral-500" />
                          <input
                            type="number"
                            placeholder="Enter price"
                            value={state[key]}
                            onChange={(e) => handleInputChange(e, key)}
                            className="flex-auto self-stretch my-auto px-2 py-1 border-none outline-none text-black bg-transparent placeholder-black"
                          />
                        </div>
                      </div>
                      <div className="flex flex-col flex-1 text-neutral-600">
                        <div className="text-base font-medium">
                          Earning after Laara commission
                        </div>
                        <div className="flex items-center py-3 mt-3 text-sm whitespace-nowrap rounded-lg border border-neutral-600">
                          <div className="self-stretch my-auto px-2">US$</div>
                          <div className="shrink-0 self-start w-px h-7 border border-solid bg-neutral-500" />
                          <div className="flex-auto self-stretch my-auto px-2">
                            {calculateEarnings(state[key]).toFixed(2)}
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );

  if (loading) {
    return (
      <div className="flex justify-center items-center h-screen">
        <div className="w-16 h-16 border-4 border-t-4 border-gray-200 border-t-blue-500 border-solid rounded-full animate-spin" aria-label="Loading"></div>
      </div>
    );
  }
  if (fetchError) {
    return (
      <div className="flex justify-center items-center h-screen">
        <div className="text-xl text-red-500">Error: {fetchError}</div>
      </div>
    );
  }

  return (
    <div className="p-8 bg-base-white">
      <Header linkTo={`/room-details/${id}/${roomType}/${number}`} title="Pricings" />
      <div className="max-w-screen-xl mx-auto px-4">
        <h1 className="text-[18px] font-semibold">Edit Pricing</h1>
        {renderPricingSection('Double Bed Half Board', 'priceOneGuestHalfBoardDouble', 'priceTwoGuestsHalfBoardDouble', 'bg-laara-secondary/35')}
        {renderPricingSection('Double Bed Full Board', 'priceOneGuestFullBoardDouble', 'priceTwoGuestsFullBoardDouble', 'bg-laara-secondary/35')}
        {renderPricingSection('Single Bed Half Board', 'priceOneGuestHalfBoardSingle', 'priceTwoGuestsHalfBoardSingle', 'bg-laara-secondary/35')}
        {renderPricingSection('Single Bed Full Board', 'priceOneGuestFullBoardSingle', 'priceTwoGuestsFullBoardSingle', 'bg-laara-secondary/35')}
        <div className="flex gap-10 mt-8 ">
          <button className="px-6 py-2 bg-blue-500 text-white rounded-[36px] w-[216px] hover:bg-blue-400 transition-all duration-300 ease-in">Save Changes</button>
          <button className="px-6 py-2 bg-gray-500 text-white rounded-[36px] w-[216px] hover:bg-gray-400 transition-all duration-300 ease-in">Cancel</button>
        </div>
      </div>
    </div>
  );
};

export default EditPricing;
