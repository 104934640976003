import { Link } from "react-router-dom";
import logo from "../../../assets/ListingProcess/logo.svg";
import hotels from "../../../assets/ListingProcess/hotels.svg";
import rentals from "../../../assets/ListingProcess/rentals.svg";
import boardrooms from "../../../assets/ListingProcess/boardrooms.svg";
import wedding from "../../../assets/ListingProcess/wedding.svg";
import party from "../../../assets/ListingProcess/party.svg";
import expo from "../../../assets/ListingProcess/expo.svg";
import ke from "../../../assets/settings/keflag.svg";
import Navbar from "./HostNavbar/Navbar";
const properties = [
  {
    name: "Hotels",
    imgSrc: hotels,
    url: "/hotels", // Add the URL here
    type: "HOTEL",
  },
  {
    name: "Vacation Rentals",
    imgSrc: rentals,
    url: "/hotels", // Add the URL here
    type: "VACATION_RENTAL",
  },
  {
    name: "Board Rooms",
    imgSrc: boardrooms,
    url: "/hotels", // Add the URL here
    type: "BOARD_ROOM",
  },
  {
    name: "Wedding Receptions",
    imgSrc: wedding,
    url: "/hotels", // Add the URL here
    type: "WEDDING_RECEPTION",
  },
  {
    name: "Party Venues",
    imgSrc: party,
    url: "/hotels", // Add the URL here
    type: "PARTY_VENUE",
  },
  {
    name: "Exhibition & Expo Spaces",
    imgSrc: expo,
    url: "/hotels", // Add the URL here
    type: "EXHIBITION_EXPO_SPACE",
  },
];

const rows = [];
for (let i = 0; i < properties.length; i += 3) {
  rows.push(properties.slice(i, i + 3));
}
export default function ListProperty() {
  return (
    <div className="flex flex-col pb-20 bg-white">
      <Navbar/>
      <div className="flex flex-col justify-center px-20 mt-16 w-full max-md:px-5 max-md:mt-10 max-md:max-w-full">
        <div className="flex flex-col max-md:mr-2 max-md:max-w-full">
          <div className="text-3xl font-semibold tracking-normal leading-10 text-zinc-800 max-md:max-w-full">
            Listing your property on Laara stays
          </div>
          <div className="mt-4 text-xl text-neutral-500 max-md:max-w-full">
            To get started, select the property you want to list on Laara stays{" "}
          </div>
        </div>
        <div className="flex flex-col justify-center mt-12 max-md:mt-10 max-md:mr-2 max-md:max-w-full">
          {rows.map((row, rowIndex) => (
            <div key={rowIndex} className="flex gap-5 mb-5 max-md:flex-col">
              {row.map((property, propertyIndex) => (
                <div
                  key={propertyIndex}
                  className="flex flex-col w-[33%] max-md:w-full"
                >
                  <div className="flex flex-col grow justify-center items-center px-3 py-16 w-full text-center rounded-xl border border-solid bg-slate-50 bg-opacity-60 border-zinc-300">
                    <div className="flex justify-items-center flex-col max-w-full w-[196px] ">
                      <img
                        loading="lazy"
                        src={property.imgSrc}
                        className="self-center aspect-square w-[58px]"
                      />
                      <div className="mt-6 text-lg font-medium text-black">
                        {property.name}
                      </div>
                      <Link
                        to={property.url}
                        state={{ type: property.type }} // Pass the type as state
                        className="px-9 py-2 h-10 mt-6 text-lg tracking-wider leading-5 text-white capitalize bg-laara-primary rounded-[36px] max-md:px-5"
                      >
                        List property
                      </Link>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
