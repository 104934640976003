import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Home from "@/pages/Home/Home";
import Settings from "@/pages/Settings/Settings";
import FinanceSettings from "./components/Settings/FinanceSettings";
import Privacy from "./components/Settings/Privacy";
import Preference from "./components/Settings/Preference";
import Account from "./components/Settings/Account";
import Reservations from "@/pages/Reservations/Reservations";
import { NoChat } from "@/pages/Messages/NoMessage/NoChat";
import { Chat } from "@/pages/Messages/WithMessage/Chat";
import Login from "@/pages/LoginAndRegistration/Login";
import Register from "@/pages/LoginAndRegistration/Register";
import Updates from "@/pages/Updates/Updates";
import Wishlist from "./pages/Wishlist/Wishlist";
import AllWishes from "./components/Wishlist/AllWishes";
import Layout from "@/Layout";
import NewPassword from "./pages/LoginAndRegistration/newPassword";
import ForgotPassword from "./pages/ForgotAndReset/forgotPassword";
import NewPass from "./pages/ForgotAndReset/SetNewPassword.jsx";
import Reset from "./pages/LoginAndRegistration/ResetPassword";
import ResetOtp from "./pages/LoginAndRegistration/OtpReset";
import { Profile } from "@/pages/Profile/Profile";
import SearchResults from "./pages/SearchResults/SearchResults";
import SearchWish from "./components/Wishlist/SearchWish";
import { Edit } from "./components/Profile/Edit";
import HostReview from "./pages/HostReview/HostReview";
import ReviewDetail from "./components/HostReview/ReviewDetail";
import Updatesproperty from "./pages/Updates/Updatesproperty";
import { PublicOthers } from "./components/Profile/PublicOthers";
import { HostProfile } from "./components/Profile/HostProfile";
import { HostEdit } from "@/components/Profile/HostEdit";
import { Support } from "./pages/Support/Support";
import { Searchproperties } from "./components/Support/Searchproperties";
import { MessageSent } from "./components/Support/MessageSent";
import { Message } from "./components/Support/Message";
import { Gettingstarted } from "./components/Support/Gettingstarted";
import { Creatingaccount } from "./components/Support/Creatingaccount";
import Bookings from "./pages/Bookings/Bookings";
import BookingDetails from "./pages/Bookings/BookingDetails";
import ReviewBooking from "./pages/Bookings/ReviewBooking";
import ScanBookingQRCode from "./pages/Bookings/ScanBookingQRCode";
import Notifications from "./components/Notifications/Notifications";
import Nonotifications from "./components/Notifications/noNotifications";
import PopNotifications from "./components/Notifications/NotificationsPopUp";

import PendingPromo from "./pages/Promotions/Host-Properties/PendingPromo";
//import PromoHistory from "./pages/Promotions/Host-Properties/PromotionsHistory";
import SchedulePromo from "./pages/Promotions/Host-Properties/schedulepromotion";
import ChangeSuccess from "./components/OverLays/Passswords/Changed";
import CheckMail from "./components/OverLays/Passswords/checkmail";

import LandingPage from "./pages/Listings/Home/LandingPage";
import HostLogin from "./pages/Listings/Home/HostLogin";
import HostRegister from "./pages/Listings/Home/HostRegister";
import ListProperty from "./pages/Listings/Home/ListProperty";
import Pricing from "./pages/Listings/Pricing/Pricing";
import Listing from "./pages/Listings/Listing";
import HostProperties from "./pages/HostProperties/HostProperties";
import ViewProperty from "./pages/HostProperties/ViewProperty";
import PropertyPhotos from "./pages/Listings/Photos/PropertyPhotos";
import Promotions from "./pages/Promotions/Host-Properties/Promotions";
import EditPropertyName from "./pages/HostProperties/EditProperty/PropertyName";
import EditPropertyDescription from "./pages/HostProperties/EditProperty/PropertyDescription";
import EditPropertyAccessibility from "./pages/HostProperties/EditProperty/PropertyAccessibility";
import EditPropertyLanguage from "./pages/HostProperties/EditProperty/PropertyLanguage";
import EditHouseRules from "./pages/HostProperties/EditProperty/PropertyHouserules";
import EditPropertyLocation from "./pages/HostProperties/EditProperty/PropertyLocation";
import LocationPicker from "./pages/HostProperties/EditProperty/LocationPicker";
import PropertyCancellation from "./pages/HostProperties/EditProperty/PropertyCancellation";
import PropertyFacilities from "./pages/HostProperties/EditProperty/PropertyFacilities";
import EditPropertyParking from "./pages/HostProperties/EditProperty/PropertyParking";
import SafetyAmenities from "./pages/HostProperties/EditProperty/PropertySafetyFacilities";
import MealPlans from "./pages/HostProperties/EditProperty/PropertyMealplans";
import UploadPhotos from "./pages/HostProperties/EditProperty/UploadPhotos";
import ViewRoom from "./pages/HostProperties/ViewRoom";
import EditRoomDetails from "./pages/HostProperties/EditRoom/RoomDetail";
import EditRoomAmenities from "./pages/HostProperties/EditRoom/RoomAmenities";
import EditPricing from "./pages/HostProperties/EditRoom/RoomPricing";
import EditRoomDescription from "./pages/HostProperties/EditRoom/RoomDescription";
import EditRoomPhoto from "./pages/HostProperties/EditRoom/RoomPhotos";
import { Availability } from "./pages/HostProperties/EditProperty/PropertyAvailability";
import EditBathroom from "./pages/HostProperties/EditRoom/RoomBathroom";
import { RoomPayments } from "./pages/HostProperties/EditRoom/RoomPayment";
import Hotels from "./pages/GuestProperties/Hotels/Hotels";

import ViewGuestRoom from "./pages/GuestProperties/ViewRoom";
import PropertiesAmenities from "./pages/GuestProperties/Hotels/Properties/PropertiesAmenies";
import BookingPageStart from "./pages/GuestProperties/IdVerification/FirstBooking";
import BookingPageFinal from "./pages/GuestProperties/IdVerification/FinalBooking";
import BookingPage from "./pages/GuestProperties/IdVerification/BookingPage";
import ReservationDetails from "./pages/Reservations/ReservationDetails";
import AdminDashboard from "./pages/AdminDash/AdminDashboard";
import PropertyImages from "./pages/GuestProperties/Hotels/PropertyImages";
import ReviewReservation from "./pages/Reservations/ReviewReservation";
import ScanReservationQRCode from "./pages/Reservations/ScanReservationQRCode";
import ReservationOnMap from "./pages/Reservations/ReservationOnMap";
import Error404 from "./pages/Error/Error404";

//import FacilityPhotos from "./pages/HostProperties/EditProperty/PropertyFacilitiesphotos";
import BookingSuccess from "./pages/GuestProperties/IdVerification/BookingSuccess";
import Booking from "./pages/GuestProperties/IdVerification/Booking";
import RoomTable from "./pages/GuestProperties/Hotels/Table2";

import ChatBookings from "./pages/Bookings/ChatBookings";
import ChatReservations from "./pages/Reservations/ChatReservations";
import ProtectedRoutes from "./components/ProtectedRoutes/ProtectedRoutes";



const App = () => {
  return (
    <Router>
      <Layout>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="register" element={<Register />} />
          <Route path="/login" element={<Login />} />

          <Route element={<ProtectedRoutes />} >
            <Route path="/reservations" element={<Reservations />} />
            <Route path="/reservations/:id" element={<ReservationDetails />} />
            <Route
              path="/reviewreservation/:id"
              element={<ReviewReservation />}
            />
            <Route
              path="/scanreservation/:id"
              element={<ScanReservationQRCode />}
            />
            <Route path="/reservationlocation" element={<ReservationOnMap />} />
            <Route path="/settings" element={<Settings />} />
            <Route path="/financesettings" element={<FinanceSettings />} />
            <Route path="/privacy" element={<Privacy />} />
            <Route path="/preference" element={<Preference />} />
            <Route path="/account" element={<Account />} />
            <Route path="/NoChat" element={<NoChat />} />
            <Route path="/chats" element={<Chat />} />
            <Route path="/updates" element={<Updates />} />
            <Route path="/hostreview" element={<HostReview />} />
            <Route path="/review/:id" element={<ReviewDetail />} />
            <Route path="/resetPassword" element={<Reset />} />
            <Route path="/newPassword" element={<NewPassword />} />
            <Route path="/Forgotpassword" element={<ForgotPassword />}></Route>
            <Route path="/OTPReset" element={<ResetOtp />}></Route>
            <Route path="/SetNewPassword" element={<NewPass />} />
            <Route path="/wishlist" element={<Wishlist />} />
            <Route path="/allwishes" element={<AllWishes />} />
            <Route path="/SearchWish" element={<SearchWish />} />
            <Route path="/profile" element={<Profile />} />
            <Route path="/edit-profile" element={<Edit />} />
            <Route path="/public-profile" element={<PublicOthers />} />
            <Route path="/host-profile/:hostId" element={<HostProfile />} />
            <Route path="/host-edit/:id" element={<HostEdit />} />
            <Route path="/searchresults" element={<SearchResults />} />
            <Route
              path="/updatesproperty/:propertyId"
              element={<Updatesproperty />}
            />
            <Route path="/support" element={<Support />} />
            <Route path="/searchproperties" element={<Searchproperties />} />
            <Route path="/messagesent" element={<MessageSent />} />
            <Route path="/message" element={<Message />} />
            <Route path="/gettingstarted" element={<Gettingstarted />} />
            <Route path="/guide/create-account" element={<Creatingaccount />} />
            <Route path="/bookings" element={<Bookings />} />
            <Route path="/bookings/:ref" element={<BookingDetails />} />
            <Route path="/review-booking/:ref" element={<ReviewBooking />} />
            <Route path="/scan-booking/:ref" element={<ScanBookingQRCode />} />
            <Route path="/Notifications" element={<Notifications />} />
            <Route path="/noNotifications" element={<Nonotifications />} />
            <Route path="/notification" element={<PopNotifications />} />

            {/* Guest properties */}
            <Route path="hotel-guest/:id" element={<Hotels />} />
            <Route path="Verification1" element={<BookingPageStart/>} />
            <Route path="Verification2" element={<BookingPageFinal />} />
            <Route path="Booking" element={<BookingPage />} />
            <Route path="property-images" element={<PropertyImages />} />


          <Route path="/Guestproperties/:propertyId/:roomType" element={<ViewGuestRoom />} />
          <Route path="/PropertyAmenities" element={<PropertiesAmenities />} />
          <Route path="hotel-guest" element={<Hotels />} />
          <Route path="BookingSuccess" element={<BookingSuccess />} />
          < Route path="/Book" element={<Booking />} />
          <Route
            path="/Guestproperties/:propertyId/:roomType"
            element={<ViewGuestRoom />}
          />


            <Route path="/Guestproperties/:propertyId/:roomType" element={<ViewGuestRoom />} />
            <Route path="/PropertyAmenities" element={<PropertiesAmenities />} />
            <Route path="hotel-guest" element={<Hotels />} />
            {/* <Route path="Verification" element={<App1 />} /> */}
            <Route
              path="/Guestproperties/:propertyId/:roomType"
              element={<ViewGuestRoom />}
            />



          <Route path="/Promo" element={<SchedulePromo />} />
          <Route path="/Promotions" element={<Promotions />} />
          <Route path="/ActivePromo" element={<PendingPromo />} />
          <Route path="/Success" element={<ChangeSuccess />} />
          <Route path="/Checkmail" element={<CheckMail />} />
          <Route path="/landingpage" element={<LandingPage />} />
          <Route path="/hostlogin" element={<HostLogin />} />
          <Route path="/hostregister" element={<HostRegister />} />
          <Route path="/listproperty" element={<ListProperty />} />
          <Route path="/pricing" element={<Pricing />} />
          <Route path="/hotels" element={<Listing />} />
          <Route path="/host-properties/:hostId" element={<HostProperties />} />
          <Route path="/view-property/:id" element={<ViewProperty />} />
          <Route path="/photos" element={<PropertyPhotos />} />
          <Route path="Table" element={<RoomTable/>} />
          <Route
            path="/edit-property-name/:id"
            element={<EditPropertyName />}
          />
          <Route
            path="/edit-property-description/:id"
            element={<EditPropertyDescription />}
          />
          <Route
            path="/edit-property-accessibility-features/:id"
            element={<EditPropertyAccessibility />}
          />
          <Route
            path="/edit-property-language/:id"
            element={<EditPropertyLanguage />}
          />
          <Route
            path="/edit-property-houserules/:id"
            element={<EditHouseRules />}
          />
          <Route
            path="/edit-property-location/:id"
            element={<EditPropertyLocation />}
          />
          <Route path="/location-picker/:id" element={<LocationPicker />} />
          <Route
            path="/edit-property-cancellation/:id"
            element={<PropertyCancellation />}
          />
          <Route
            path="/edit-property-general-facilities/:id"
            element={<PropertyFacilities />}
          />
          <Route
            path="/edit-property-parking/:id"
            element={<EditPropertyParking />}
          />
          <Route
            path="/edit-property-safety-facilities/:id"
            element={<SafetyAmenities />}
          />
          <Route path="/edit-property-meals/:id" element={<MealPlans />} />
          <Route
            path="/edit-property-availability/:id"
            element={<Availability />}
          />

          <Route path="/upload-photos/:id" element={<UploadPhotos />} />
          <Route
            path="/room-details/:id/:roomType/:number"
            element={<ViewRoom />}
          />
          <Route
            path="/property/:id/rooms/:roomNumber/edit-room-details"
            element={<EditRoomDetails />}
          />
          <Route
            path="/property/:id/rooms/:number/edit-room-amenities"
            element={<EditRoomAmenities />}
          />
          <Route
            path="/property/:id/rooms/:number/edit-room-pricing"
            element={<EditPricing />}
          />
          <Route
            path="/property/:id/rooms/:number/edit-room-description"
            element={<EditRoomDescription />}
          />
          <Route
            path="/property/:id/rooms/:number/edit-room-photos"
            element={<EditRoomPhoto />}
          />
          <Route
            path="/property/:id/rooms/:number/edit-bathrooms"
            element={<EditBathroom />}
          />
          <Route
            path="/property/:id/rooms/:number/edit-payments"
            element={<RoomPayments />}
          />

            <Route path="/Promo" element={<SchedulePromo />} />
            <Route path="/Promotions" element={<Promotions />} />
            <Route path="/ActivePromo" element={<PendingPromo />} />
            <Route path="/Success" element={<ChangeSuccess />} />
            <Route path="/Checkmail" element={<CheckMail />} />
            <Route path="/landingpage" element={<LandingPage />} />
            <Route path="/hostlogin" element={<HostLogin />} />
            <Route path="/hostregister" element={<HostRegister />} />
            <Route path="/listproperty" element={<ListProperty />} />
            <Route path="/pricing" element={<Pricing />} />
            <Route path="/hotels" element={<Listing />} />
            <Route path="/host-properties/:hostId" element={<HostProperties />} />
            <Route path="/view-property/:id" element={<ViewProperty />} />
            <Route path="/photos" element={<PropertyPhotos />} />
            <Route
              path="/edit-property-name/:id"
              element={<EditPropertyName />}
            />
            <Route
              path="/edit-property-description/:id"
              element={<EditPropertyDescription />}
            />
            <Route
              path="/edit-property-accessibility-features/:id"
              element={<EditPropertyAccessibility />}
            />
            <Route
              path="/edit-property-language/:id"
              element={<EditPropertyLanguage />}
            />
            <Route
              path="/edit-property-houserules/:id"
              element={<EditHouseRules />}
            />
            <Route
              path="/edit-property-location/:id"
              element={<EditPropertyLocation />}
            />
            <Route path="/location-picker/:id" element={<LocationPicker />} />
            <Route
              path="/edit-property-cancellation/:id"
              element={<PropertyCancellation />}
            />
            <Route
              path="/edit-property-general-facilities/:id"
              element={<PropertyFacilities />}
            />
            <Route
              path="/edit-property-parking/:id"
              element={<EditPropertyParking />}
            />
            <Route
              path="/edit-property-safety-facilities/:id"
              element={<SafetyAmenities />}
            />
            <Route path="/edit-property-meals/:id" element={<MealPlans />} />
            <Route
              path="/edit-property-availability/:id"
              element={<Availability />}
            />
            <Route path="/upload-photos/:id" element={<UploadPhotos />} />
            <Route
              path="/room-details/:id/:roomType/:number"
              element={<ViewRoom />}
            />
            <Route
              path="/property/:id/rooms/:roomNumber/edit-room-details"
              element={<EditRoomDetails />}
            />
            <Route
              path="/property/:id/rooms/:number/edit-room-amenities"
              element={<EditRoomAmenities />}
            />
            <Route
              path="/property/:id/rooms/:number/edit-room-pricing"
              element={<EditPricing />}
            />
            <Route
              path="/property/:id/rooms/:number/edit-room-description"
              element={<EditRoomDescription />}
            />
            <Route
              path="/property/:id/rooms/:number/edit-room-photos"
              element={<EditRoomPhoto />}
            />
            <Route
              path="/property/:id/rooms/:number/edit-bathrooms"
              element={<EditBathroom />}
            />
            <Route
              path="/property/:id/rooms/:number/edit-payments"
              element={<RoomPayments />}
            />
            <Route
              path="/property/:id/rooms/:number/edit-payments"
              element={<RoomPayments />}
            />

            <Route path="/upload-photos/:id" element={<UploadPhotos />} />
            <Route path="/room-details/:id/:roomType" element={<ViewRoom />} />
            <Route
              path="/edit-room-details/:id/:roomId"
              element={<EditRoomDetails />}
            />
            <Route path="/admin" element={<AdminDashboard />} />
            <Route path="/chatbookings/:ref" element={<ChatBookings />} />
            <Route path="/chatreservations/:ref" element={<ChatReservations />} />
          </Route>

          

          {/* CATCH ALL ROUTES WITH UNDEFINED URLS == ERROR 404 */}
          <Route path="*" element={<Error404 />} />
        </Routes>
      </Layout>
    </Router>
  );
};

export default App;
