import React, { useState } from 'react';
import Header from './globalrooms/Header';
import Consider from '@/components/Listings/Consider';

export const RoomPayments = () => {
    const [paymentOption, setPaymentOption] = useState('');

    return (
        
                <form className="bg-base-white p-8">
                    <Header title={"Payment"}/>
                  <div className='flex gap-4' >
                    <div className="w-full max-w-[728px] p-4 bg-laara-secondary border rounded-lg border-custom-gray-300">
                        <div className="w-full p-4 sm:w-full font-normal leading-[30px] text-left border rounded-lg mb-4">
                            <h2 className="text-base font-normal ">Select how guests can pay for their stay</h2>
                            <div className="space-y-4">
                                <div className="p-4 rounded-lg flex items-start">
                                    <input
                                        type="radio"
                                        className="mt-1.5 mr-3 w-5 h-5"
                                        checked={paymentOption === 'online'}
                                        onChange={() => setPaymentOption('online')}
                                    />
                                    <div>
                                        <span className="font-medium">Online payment, guests pay the entire amount during reservation</span>
                                        <ul className="mt-2 space-y-1 text-sm text-[#4F4F4F] ">
                                            <li className="flex items-center ">
                                                <svg className='w-5 h-5 mr-2'  viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M1.66797 7.08594H12.7013" stroke="#6D6D6D" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                                                    <path d="M5 13.75H6.075" stroke="#6D6D6D" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                                                    <path d="M9.16797 13.75H12.0846" stroke="#6D6D6D" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                                                    <path d="M6.64844 17.0839H14.6318C17.5984 17.0839 18.3318 16.3505 18.3318 13.4255V5.74219" stroke="#6D6D6D" stroke-linecap="round" stroke-linejoin="round" />
                                                    <path d="M16.6596 3.1224C16.143 2.9724 15.4763 2.91406 14.6346 2.91406H5.36797C2.40964 2.91406 1.66797 3.6474 1.66797 6.5724V13.4141C1.66797 15.3641 1.99297 16.3391 3.09297 16.7724" stroke="#6D6D6D" stroke-linecap="round" stroke-linejoin="round" />
                                                    <path d="M18.3346 1.66406L1.66797 18.3307" stroke="#6D6D6D" stroke-linecap="round" stroke-linejoin="round" />
                                                </svg>
                                                Reduced cancellations
                                            </li>
                                            <li className="flex items-center ">
                                                <svg className='w-5 h-5 mr-2'  viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M15.032 11.2864C14.682 11.6281 14.482 12.1198 14.532 12.6448C14.607 13.5448 15.432 14.2031 16.332 14.2031H17.9154V15.1948C17.9154 16.9198 16.507 18.3281 14.782 18.3281H6.35704C6.61537 18.1115 6.84036 17.8448 7.01536 17.5448C7.3237 17.0448 7.4987 16.4531 7.4987 15.8281C7.4987 13.9865 6.00703 12.4948 4.16536 12.4948C3.38203 12.4948 2.65703 12.7698 2.08203 13.2281V9.58647C2.08203 7.86147 3.49036 6.45312 5.21536 6.45312H14.782C16.507 6.45312 17.9154 7.86147 17.9154 9.58647V10.7865H16.232C15.7654 10.7865 15.3404 10.9698 15.032 11.2864Z" stroke="#6D6D6D" stroke-linecap="round" stroke-linejoin="round" />
                                                    <path d="M2.08203 10.3401V6.53183C2.08203 5.54017 2.69036 4.6568 3.61536 4.3068L10.232 1.8068C11.2654 1.41513 12.3737 2.18182 12.3737 3.29015V6.45681" stroke="#6D6D6D" stroke-linecap="round" stroke-linejoin="round" />
                                                    <path d="M18.7977 11.639V13.3557C18.7977 13.8141 18.431 14.189 17.9644 14.2057H16.331C15.431 14.2057 14.606 13.5474 14.531 12.6474C14.481 12.1224 14.681 11.6307 15.031 11.289C15.3394 10.9724 15.7644 10.7891 16.231 10.7891H17.9644C18.431 10.8057 18.7977 11.1807 18.7977 11.639Z" stroke="#6D6D6D" stroke-linecap="round" stroke-linejoin="round" />
                                                    <path d="M5.83203 10H11.6654" stroke="#6D6D6D" stroke-linecap="round" stroke-linejoin="round" />
                                                    <path d="M7.4987 15.8333C7.4987 16.4583 7.3237 17.05 7.01536 17.55C6.84036 17.85 6.61537 18.1167 6.35704 18.3333C5.7737 18.8583 5.00703 19.1667 4.16536 19.1667C2.9487 19.1667 1.89037 18.5167 1.31537 17.55C1.00703 17.05 0.832031 16.4583 0.832031 15.8333C0.832031 14.7833 1.31536 13.8417 2.08203 13.2333C2.65703 12.775 3.38203 12.5 4.16536 12.5C6.00703 12.5 7.4987 13.9917 7.4987 15.8333Z" stroke="#6D6D6D" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                                                    <path d="M2.86719 15.8323L3.69218 16.6573L5.46718 15.0156" stroke="#6D6D6D" stroke-linecap="round" stroke-linejoin="round" />
                                                </svg>
                                                More payment options for guests
                                            </li>
                                            <li className="flex items-center mb-2">
                                                <svg className='w-5 h-5 mr-2' viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M8.73983 1.85781L4.58151 3.41614C3.62317 3.77448 2.83984 4.9078 2.83984 5.9328V12.1245C2.83984 13.1078 3.48985 14.3995 4.28152 14.9911L7.86484 17.6661C9.03984 18.5495 10.9732 18.5495 12.1482 17.6661L15.7315 14.9911C16.5232 14.3995 17.1732 13.1078 17.1732 12.1245V5.9328C17.1732 4.9078 16.3898 3.77448 15.4315 3.41614L11.2732 1.85781C10.5648 1.59948 9.4315 1.59948 8.73983 1.85781Z" stroke="#6D6D6D" stroke-linecap="round" stroke-linejoin="round" />
                                                    <path d="M9.99714 9.09741C9.96381 9.09741 9.92214 9.09741 9.8888 9.09741C9.10547 9.07241 8.48047 8.4224 8.48047 7.63074C8.48047 6.8224 9.13881 6.16406 9.94714 6.16406C10.7555 6.16406 11.4138 6.8224 11.4138 7.63074C11.4055 8.43074 10.7805 9.07241 9.99714 9.09741Z" stroke="#6D6D6D" stroke-linecap="round" stroke-linejoin="round" />
                                                    <path d="M8.33828 11.4328C7.53828 11.9661 7.53828 12.8411 8.33828 13.3745C9.24661 13.9828 10.7383 13.9828 11.6466 13.3745C12.4466 12.8411 12.4466 11.9661 11.6466 11.4328C10.7466 10.8245 9.25495 10.8245 8.33828 11.4328Z" stroke="#6D6D6D" stroke-linecap="round" stroke-linejoin="round" />
                                                </svg>
                                                Fraud and card protection
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>

                        </div>
                        <div className="w-full max-w-[728px] p-4 bg-laara-secondary border rounded-lg relative">
                        <div className="max-w-[637px] h-[170px] p-4 w-full gap-0 sm:w-full text-[20px] font-normal leading-[30px] text-left">
                        <h2 className="text-base font-normal">Select how guests can pay for their stay</h2>
                            <div className="p-4 rounded-lg flex items-center">
                                <input
                                    type="radio"
                                    className="mr-3 w-5 h-5"
                                    checked={paymentOption === 'property'}
                                    onChange={() => setPaymentOption('property')}
                                />
                                <span className="font-normal text-sm">Payment at the Property, guests pay at the property upon arrival</span>
                            </div>
                        </div>
                    </div>

                    </div>
                    <Consider
                title={"You are unsure about your price?"}
                data={["Don't worry, you can set it when ready"]}/>
                  </div>  
                    <div className="w-full flex justify-end gap-5 items-center mt-5">
                        <button
                        type="submit"
                        className="py-2 px-4 bg-blue-500 text-white rounded-[36px] w-[216px] h-[55px]"
                        >
                        Save Changes
                        </button>
                    </div>
                </form>
    );
};
