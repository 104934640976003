import { useDispatch, useSelector } from "react-redux";
import { setAmenities } from "@/redux/Listing/listingSlice";
import { Checkbox } from "@/components/ui/checkbox";

const SafetyFeatures = () => {
  const dispatch = useDispatch();
  const selectedAmenities = useSelector(
    (state) => state.hostListing.formData.amenities || []
  );

  const safetyFeatures = [
    "smoke detectors",
    "carbon monoxide detectors",
    "fire extinguishers",
    "first aid kits",
    "emergency exits",
    "CCTV",
    "security alarms",
    "safety instructions",
    "lock on bedroom doors",
  ];

  const handleToggleAmenity = (isChecked, category, amenity) => {
    // Toggle the selection of the amenity
    const updatedAmenities = selectedAmenities?.some(
      (a) => a.name === amenity && a.category === category
    )
      ? selectedAmenities.filter(
          (a) => !(a.name === amenity && a.category === category)
        )
      : [
          ...selectedAmenities,
          {
            name: amenity,
            description: `${amenity} available`, // Customize description
            category,
          },
        ];

    dispatch(setAmenities(updatedAmenities));
  };

  const renderCheckboxes = (category, items) => (
    <div key={category} className="space-y-4">
      {/* <p className="text-[24px] font-medium capitalize">{category}</p> */}
      <div className="flex flex-col gap-2">
        {items.map((item) => (
          <div key={item} className="flex items-center gap-3">
            <Checkbox
              id={`${category}-${item}`}
              name={item}
              className="size-6 rounded-lg"
              checked={selectedAmenities?.some((a) => a.name === item)}
              onCheckedChange={(isChecked) =>
                handleToggleAmenity(isChecked, category, item)
              }
            />
            <label
              htmlFor={`${category}-${item}`}
              className={`capitalize text-base ${
                selectedAmenities?.some(
                  (a) => a.name === item && a.category === category
                ) && "text-laara-primary"
              }`}
            >
              {item}
            </label>
          </div>
        ))}
      </div>
    </div>
  );

  return (
    <form className="max-w-[733px]">
      <div className="space-y-5">
        <h4 className=" font-medium text-xl ">
          What safety features are available at your property?
        </h4>
        <p className="text-lg text-[#626262] ">
          Please select the safety features available at your property
        </p>
      </div>

      <div className="mt-5 border border-[#A3A3A3] rounded-[6px] py-6 px-4 md:px-[30px] space-y-2.5 w-full ">
        {renderCheckboxes("Safety", safetyFeatures)}
      </div>
    </form>
  );
};

export default SafetyFeatures;
