import React from "react";
import { CircleX } from "lucide-react";
import Unauthenticated from "./Unauthenticated";
import Authenticated from "./Authenticated";

const MobileDrawer = ({
  setOpenMobileDrawer,
  auth,
  setOpenDrawer,
  openDrawer,
}) => {
  return (
    <div className="py-10 px-5 flex flex-col gap-11 ">
      <CircleX
        onClick={() => setOpenMobileDrawer(false)}
        className="text-laara-primary size-[34px] ml-auto "
      />
      {/* <Authentication auth={auth} /> */}
      <div className="flex flex-col  xl:hidden">
        {auth ? (
          <Authenticated
            setOpenDrawer={setOpenDrawer}
            openDrawer={openDrawer}
          />
        ) : (
          <Unauthenticated />
        )}
      </div>
    </div>
  );
};

export default MobileDrawer;
