import React from 'react';

const DeclineModal = ({ isOpen, onClose, onSubmit, declineReason, setDeclineReason }) => {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-gray-600 bg-opacity-50">
      <div className="bg-white p-6 rounded-lg w-full max-w-md">
        <h2 className="text-xl font-semibold mb-4">Reason for Decline Verification</h2>
        <textarea
          className="w-full h-32 p-2 border rounded mb-4"
          value={declineReason}
          onChange={(e) => setDeclineReason(e.target.value)}
          placeholder="Write the reason for declining..."
        ></textarea>
        <div className="flex justify-end gap-4">
          <button
            className="px-4 py-2 text-[#0466c8] border border-[#0466c8] rounded-lg"
            onClick={onClose}
          >
            Cancel
          </button>
          <button
            className="px-4 py-2 bg-[#0466C8] text-white rounded-lg"
            onClick={onSubmit}
          >
            Done
          </button>
        </div>
      </div>
    </div>
  );
};

export default DeclineModal;
