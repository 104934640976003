import { useState, useEffect } from 'react';
import { getPropertyById } from '@/api'; 

const useFetchRoomDetails = (id) => {
    const [rooms, setRooms] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchRooms = async () => {
            try {
                setLoading(true);
                const propertyData = await getPropertyById(id);
                console.log("Id:", id);

                if (propertyData && propertyData.property && propertyData.property.rooms) {
                    setRooms(propertyData.property.rooms); // Correctly accessing the rooms data
                } else {
                    throw new Error('No rooms data found for this property.');
                }
            } catch (err) {
                setError(err.message);
                console.error('Error fetching rooms:', err);
            } finally {
                setLoading(false); 
            }
        };

        if (id) {
            fetchRooms();
        }
    }, [id]);

    // Log rooms data after it has been updated
    useEffect(() => {
        console.log("Rooms:", rooms);
    }, [rooms]);

    return { rooms, loading, error };
};

export default useFetchRoomDetails;
