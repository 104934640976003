import { useState, useEffect } from "react";
import { useNavigate } from 'react-router-dom';
import back from "../../assets/settings/back.svg";
import arrowright from '../../assets/settings/arrowright.svg'
import arrowdelete from '../../assets/settings/arrowdelete.svg'
const Settings = () => {
  const [activeTab, setActiveTab] = useState('Privacy & Security');
  const navigate = useNavigate();

  const handleBackClick = () => {
    navigate(-1);  
  };

  useEffect(() => {
    if (activeTab === 'Financial Information') {
      navigate('/financesettings');
    }
    if (activeTab === 'User Preferences') {
      navigate('/preference');
    }
    if (activeTab === 'Account') {
      navigate('/account');
    }
  }, [activeTab, navigate]);
  const renderContent = () => {
    switch (activeTab) {
      case 'Account':
        return ;
      case 'Financial Information':
        return ;
      case 'Privacy & Security':
        return (
          <div className="flex flex-col pb-20 bg-white">
            <div className="flex flex-col self-center mt-0 w-full max-w-[1440px] max-md:mt-10 max-md:max-w-full">
              <div className="flex flex-col mt-11 max-md:mt-0 max-md:mr-2 max-md:max-w-full">
        <div className="flex flex-col md:flex-row gap-5 max-md:flex-wrap">
          <div className="flex flex-col flex-1 max-md:max-w-full">
            <div className="text-2xl font-medium text-neutral-600 max-md:max-w-full">
              Connected Devices
            </div>
            <div className="mt-2.5 text-lg text-zinc-500 max-md:max-w-full">
              Update your billing details
            </div>
          </div>
          <button className="flex gap-2.5 justify-center p-5 text-lg font-medium text-center text-sky-600 border border-sky-600 border-solid rounded-[36px] max-md:w-full">
            <span>Reset Password</span>
            <img
              loading="lazy"
              src={arrowright}
              className="shrink-0 w-6 aspect-square"
            />
          </button>
        </div>
        <div className="flex flex-col md:flex-row gap-5 mt-24 max-md:flex-wrap max-md:mt-10">
          <div className="flex flex-col flex-1 max-md:max-w-full">
            <div className="text-2xl font-medium text-neutral-600 max-md:max-w-full">
              Change your account password
            </div>
            <div className="mt-2.5 text-lg text-zinc-500 max-md:max-w-full">
              Update or reset your the password of your account
            </div>
          </div>
          <button className="flex gap-2.5 justify-center p-5 text-lg font-medium text-center text-sky-600 border border-sky-600 border-solid rounded-[36px] max-md:w-full">
            <span>Reset Password</span>
            <img
              loading="lazy"
              src={arrowright}
              className="shrink-0 w-6 aspect-square"
            />
          </button>
        </div>
        <div className="flex flex-col md:flex-row gap-5 mt-24 max-md:flex-wrap max-md:mt-10">
          <div className="flex flex-col flex-1 max-md:max-w-full">
            <div className="text-2xl font-medium text-neutral-600 max-md:max-w-full">
              Connected Devices
            </div>
            <div className="mt-2.5 text-lg text-zinc-500 max-md:max-w-full">
              2 devices are connected to your account
            </div>
          </div>
          <button className="flex gap-2.5 justify-center p-5 text-lg font-medium text-center text-sky-600 border border-sky-600 border-solid rounded-[36px] max-md:w-full">
            <span>Reset Password</span>
            <img
              loading="lazy"
              src={arrowright}
              className="shrink-0 w-6 aspect-square"
            />
          </button>
        </div>
        <div className="flex flex-col md:flex-row gap-5 mt-24 max-md:flex-wrap max-md:mt-10">
          <div className="flex flex-col flex-1 max-md:max-w-full">
            <div className="text-2xl font-medium text-red-400 max-md:max-w-full">
              Delete Account
            </div>
            <div className="mt-2.5 text-lg text-zinc-500 max-md:max-w-full">
              Permanently delete your account
            </div>
          </div>
          <button className="flex gap-2.5 justify-center p-5 text-lg font-medium text-center text-red-400 border border-red-400 border-solid rounded-[36px] max-md:w-full">
            <span>Delete Account</span>
            <img
              loading="lazy"
              src={arrowdelete}
              className="shrink-0 w-6 aspect-square"
            />
          </button>
        </div>
      </div>
            </div>
          </div>
        );
      case 'User Preferences':
        return ;
      default:
        return null;
    }
  };

  return (
    <div className="flex flex-col pb-10 ">
      <div className="flex flex-col self-center mt-0 w-full max-w-[1440px] max-md:mt-10 max-md:max-w-full">
        <div className="flex flex-col px-20 mt-16 w-full leading-[120%] max-md:px-5 max-md:mt-10 max-md:max-w-full">
          <div className="flex flex-col max-md:max-w-full">
            <div className="flex flex-col leading-[120%] max-md:max-w-full">
        <button 
         onClick={handleBackClick}
        className="flex gap-3 self-start pr-5 text-4xl font-bold whitespace-nowrap text-neutral-600 max-md:ml-2">
          <img
            loading="lazy"
            src={back}
            className="shrink-0 my-auto w-9 aspect-square"
            alt="Settings Icon"
          />
          <span>Settings</span>
        </button>
        <div className="mt-5 flex flex-col w-full">
          <div className="flex gap-2 text-xl rounded-xl text-zinc-500 max-md:flex-wrap max-md:mr-2 max-md:max-w-full">
            {['Account', 'Financial Information', 'Privacy & Security', 'User Preferences'].map((tab) => (
              <div
                key={tab}
                onClick={() => setActiveTab(tab)}
                className={`justify-center items-center p-5 whitespace-nowrap max-md:px-5 cursor-pointer w-full ${
                  activeTab === tab ? 'font-medium text-sky-600 border-b border-sky-600 border-solid' : 'bg-neutral-200 bg-opacity-40'
                }`}
              >
                {tab}
              </div>
            ))}
          </div>
          <div className="flex-grow mt-5 pb-5 w-full">
            {renderContent()}
          </div>
        </div>
      </div>
    </div>
    </div>
    </div>
    </div>
  );
};

export default Settings;
