import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLessThan } from "@fortawesome/free-solid-svg-icons";

const Header = ({ linkTo, title }) => (
  <section className="top h-16 m-2 ml-0">
    <div className="text-lg text-gray-600 w-[700px]">
      <Link to={linkTo} className="flex items-center gap-4">
        <FontAwesomeIcon icon={faLessThan} className="text-base" />
        <h3>{title}</h3>
      </Link>
    </div>
  </section>
);

export default Header;

