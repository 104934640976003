import Banner from "@/components/BookingsCard/Banner";
import Banner2 from "@/components/BookingsCard/Banner2";
import Banner3 from "@/components/BookingsCard/Banner3";

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const BookingsCarousel = () => {
  let settings = {
    arrows: false,
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  return (
    <div className="max-w-[1248px] w-full h-[210px] mx-auto">
      <Slider {...settings}>
        <Banner />
        <Banner2 />
        <Banner3 />
      </Slider>
    </div>
  );
};

export default BookingsCarousel;
