import { useState, useEffect } from "react";
import Consider from "@/components/Listings/Consider";
import { Link, useParams, useNavigate } from "react-router-dom";
import { getPropertyById, editPropertyDescription } from "@/api";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLessThan } from "@fortawesome/free-solid-svg-icons";

const EditPropertyDescription = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  const [description, setDescription] = useState("");
  const [property, setProperty] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  console.log("PropertyId: ", id);

  useEffect(() => {
    const fetchProperty = async () => {
      try {
        setLoading(true);
        const propertyData = await getPropertyById(id);
        setProperty(propertyData);
        setDescription(propertyData?.property?.description || "");
      } catch (err) {
        setError("Failed to load property details.");
      } finally {
        setLoading(false);
      }
    };

    fetchProperty();
  }, [id]);

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      setLoading(true);
      setError("");
      await editPropertyDescription(id, description);
      console.log("New description saved: ", description);
      navigate(`/view-property/${id}`);
    } catch (err) {
      setError("Failed to update property description. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  if (loading) {
    return (
      <div className="flex justify-center items-center h-screen">
        <div className="w-16 h-16 border-4 border-t-4 border-gray-200 border-t-blue-500 border-solid rounded-full animate-spin"></div>
      </div>
    );
  }
  if (error) return <p>{error}</p>;

  return (
    <div className="p-8 bg-base-white">
      <section className="top h-16 flex items-center p-2 gap-3">
        <div className="flex items-center gap-4 text-lg text-gray-600 w-[700px]">
          <Link to={`/view-property/${id}`}>
            <FontAwesomeIcon icon={faLessThan} className="text-base" />
          </Link>
          <h3>Property Details</h3>
        </div>
      </section>

      <form className="flex flex-col p-2" onSubmit={handleSubmit}>
        <h4 className="font-semibold text-[32px] leading-[42px]">
          How would you describe your property?
        </h4>
        <div className="flex items-start gap-4 mt-5">
          <div className="p-4 flex flex-col gap-2 bg-laara-secondary/35 max-w-[728px] w-full h-[400px]">
            <label htmlFor="description" className="text-base m-4 ml-0">
              Property description
            </label>
            <textarea
              id="description"
              placeholder="Type new description"
              value={description}
              className="w-full h-full border py-2 px-3 rounded-lg block outline-none bg-transparent mt-3"
              onChange={(e) => setDescription(e.target.value)} // Update state when typing
            />
          </div>

          <Consider
            title={"What should I consider when writing a description"}
            data={[
              "Highlight unique features",
              "Be honest and descriptive",
              "Include key amenities",
            ]}
          />
        </div>

        <div className="w-full flex justify-end">
          <button
            type="submit"
            className="mt-5 py-2 px-4 bg-blue-500 text-white rounded-[36px] w-[216px] h-[55px]"
          >
            Save Changes
          </button>
        </div>
      </form>
    </div>
  );
};

export default EditPropertyDescription;
