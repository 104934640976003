import { Link } from "react-router-dom";
import star from "@/assets/shared/star.png";
import location from "@/assets/shared/location.png";
import scanbarcode from "@/assets/shared/scan-barcode.svg";
import dayjs from "dayjs";
import { GoHeart, GoHeartFill } from "react-icons/go";
import heartFill from "@/assets/shared/heart.svg";
import "./reservation.css";
import { getStatus, getStatusBadgeColor } from "@/lib/utils";
import axios from "axios";
import { baseURL, headers } from "@/constants/apiconfig";
import { useSelector } from "react-redux";

const Reservation = ({ reservations }) => {
  const checkInDate = dayjs(reservations.startDate).format("DD MMM YYYY");
  const checkOutDate = dayjs(reservations.endDate).format("DD MMM YYYY");

  const token = useSelector((state) => state.user.accessToken);

  const addToWishlist = async (id) => {
    try {
      const res = await axios.post(
        `${baseURL}/users/wishlist`,
        { id: id, name: "liked" },
        { headers: headers(token) }
      );
      return res;
    } catch (error) {
      throw new Error(error);
    }
  };

  return (
    <article className="w-full flex flex-col items-start md:max-w-[688px] md:flex-row md:items-center xl:items-start gap-4 lg:w-full lg:max-w-[980px] xl:gap-[38px] xl:max-w-[1264px] xl:h-[254px]  ">
      <div className="md:w-[300px] lg:w-auto h-full xl:max-w-[360px] xl:w-full relative">
        <img
          src={reservations.rooms?.roomTypes?.roomTypeImages[0]?.images?.url}
          loading="lazy"
          alt="reservation"
          className={`w-full md:w-[300px] ${
            reservations.status === "upcoming" ? "md:h-[331px]" : "md:h-[275px]"
          }  lg:w-[360px] lg:h-[236px] object-cover rounded-[24px] `}
        />

        <div className="absolute top-4 right-4 cursor-pointer size-8">
          {reservations?.rooms?.userPropertyWishlist ? (
            <img src={heartFill} alt="liked icon" className="size-8 " />
          ) : (
            <GoHeart
              className="text-white size-8"
              onClick={() => addToWishlist(reservations.ref)}
            />
          )}
        </div>
      </div>

      <div className="w-full md:max-w-[357px] xl:max-w-[773px] flex flex-col justify-between gap-2 xl:gap-3">
        <div className="flex justify-between items-center md:flex-col md:items-start">
          <div className="flex flex-col md:flex-row-reverse md:items-center md:justify-between md:w-full gap-1 xl:w-full xl:flex-col xl:items-start xl:justify-start">
            {/* Status */}
            <small
              className={`${getStatusBadgeColor(reservations.status)} 
              } text-white rounded-[36px] p-2 text-[12px] w-fit capitalize `}
            >
              {getStatus(reservations.status)}
            </small>
            <div className="w-full md:w-fit xl:w-full flex flex-col items-start xl:flex-row xl:items-center xl:justify-between gap-2 xl:gap-5">
              <h3 className="font-medium text-base ">
                {reservations.rooms?.properties?.name}
              </h3>
              <>
                {(reservations.status === "completed" ||
                  reservations.status === "awaiting review") && (
                  <Link className="hidden text-laara-primary xl:flex flex-end text-base">
                    Check Availability
                  </Link>
                )}
              </>
            </div>
          </div>
        </div>
        {/* location */}
        <div className="flex items-center justify-between md:flex-col md:items-start">
          <div className="w-full flex items-center gap-5 justify-between">
            <div className="flex items-center gap-2">
              <img src={location} loading="lazy" alt="location" />
              <p className="text-[#292929] ">
                {reservations.rooms?.properties?.address?.city ||
                  reservations.rooms?.properties?.address?.county}
                , {reservations.rooms?.properties?.address?.country}
              </p>
              <Link
                to={{ pathname: "/reservationlocation" }}
                state={{
                  address: reservations?.rooms?.properties?.address || null,
                }}
                className="text-laara-primary ml-2 hidden text-sm xl:flex"
              >
                Show On Map
              </Link>
            </div>

            {/* Hide this on large devices Show on mobile & Tablet*/}

            <div className=" flex items-center gap-1 ">
              <img src={star} alt="rating" className="size-5" />
              <p className="text-[#7B7B7B]  ">
                {reservations.rooms?.properties?.propertyRatings?.avgRating}
              </p>
            </div>
          </div>
        </div>
        <Link
          to={{ pathname: "/reservationlocation" }}
          state={{
            address: reservations?.rooms?.properties?.address || null,
          }}
          className="text-laara-primary xl:hidden"
        >
          Show On Map
        </Link>

        {/* extra info */}
        <div className="hidden md:flex items-center justify-between">
          <div className="flex flex-col gap-1 md:text-[14px] xl:text-base text-[#626262] ">
            <p>Check In Date: {checkInDate}</p>
            <p>Check Out Date: {checkOutDate}</p>
          </div>
          <div>
            <p className="hidden xl:flex text-laara-primary text-base font-normal ">
              {getStatus(reservations.status) === "pending"
                ? "Payment To Be Made -"
                : getStatus(reservations.status) === "upcoming" ||
                    getStatus(reservations.status) === "checked in"
                  ? "Payment Completed -"
                  : null}
              <span className="font-bold text-base">
                ${reservations.totalPrice.toLocaleString()}
              </span>
            </p>
          </div>
        </div>
        {/* Mobile */}
        <p className="md:hidden text-end text-laara-primary font-bold text-base md:py-0">
          ${reservations.totalPrice.toLocaleString()}
        </p>

        {/* Buttons */}
        <div className="flex flex-col gap-2.5 md:gap-2 lg:flex-row xl:gap-3 capitalize">
          {reservations.status === "PENDING_PAYMENT" && (
            <>
              <Link
                to={{ pathname: `/reservations/${reservations.ref}` }}
                state={{
                  status: reservations.status,
                  address: reservations?.rooms?.properties?.address || null,
                }}
                className="reservation-btn reservation-btn-blue same-width "
              >
                Reservation Details
              </Link>
              <Link
                className="reservation-btn cancel-reservation-btn same-width"
                to="/cancel-reservation"
              >
                Cancel Reservation
              </Link>
            </>
          )}

          {reservations.status === "UPCOMING" && (
            <>
              <Link
                className="reservation-btn reservation-btn-blue gap-1"
                to="/scan-code"
              >
                <img
                  src={scanbarcode}
                  alt="scan icon"
                  loading="lazy"
                  className="size-[22px]"
                />
                Scan Code To Check In
              </Link>
              <Link
                className="reservation-btn reservation-btn-light "
                to="/reservation-details"
              >
                Reservation Details
              </Link>
              <Link
                className="reservation-btn cancel-reservation-btn"
                to="/cancel-reservation"
              >
                Cancel
              </Link>
            </>
          )}
          {reservations.status === "CHECKED_IN" && (
            <>
              <Link
                className="reservation-btn reservation-btn-blue gap-1 "
                to="/scan-code"
              >
                <img
                  src={scanbarcode}
                  alt="scan icon"
                  loading="lazy"
                  className="size-[22px]"
                />
                Scan Code To Check Out
              </Link>
              <Link
                className="reservation-btn reservation-btn-light"
                to="/reservation-details"
              >
                Reservation Details
              </Link>
            </>
          )}
          {reservations.status === "COMPLETED" && (
            <>
              <Link
                className="reservation-btn reservation-btn-blue "
                to="/view-property"
              >
                View Property
              </Link>
            </>
          )}
          {reservations.status === "AWAITING_REVIEW" && (
            <Link
              className="flex items-center justify-center reservation-btn reservation-btn-blue"
              to="/give-review"
            >
              Give A Review
            </Link>
          )}
        </div>
      </div>
    </article>
  );
};

export default Reservation;
