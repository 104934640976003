import Consider from "../../../components/Listings/Consider";
import { useSelector, useDispatch } from "react-redux";
import { setFormData } from "@/redux/Listing/listingSlice";

const PropertyDetails = () => {
  const dispatch = useDispatch();
  const description = useSelector(
    (state) => state.hostListing.formData.description
  );

  const handleChange = (e) => {
    dispatch(setFormData({ description: e.target.value }));
  };

  return (
    <div>
      <h4 className=" font-medium text-xl ">
        How would you describe your property?
      </h4>
      <div className="flex items-start gap-4 mt-5 ">
        <div className=" p-4 flex flex-col gap-2 bg-laara-secondary/35 xl:max-w-[728px] w-full h-[400px] relative border border-[#A3A3A3] rounded-[6px]  ">
          <p className="text-lg ">Description</p>
          <div className="absolute bottom-4 w-full">
            <textarea
              name="property-description"
              id="property-description"
              placeholder="Generate your property details"
              value={description}
              onChange={handleChange}
              rows={5}
              className="w-[90%] lg:max-w-[653px] border border-[#6D6D6D] rounded-[8px] py-2 px-4 outline-none "
            ></textarea>
          </div>
        </div>
        <Consider
          title={"What should i consider when writing a description"}
          data={["stick to the facts", "avoid abbreviations", "keep it short"]}
        />
      </div>
    </div>
  );
};

export default PropertyDetails;
