// queryClient.js
import { QueryClient } from "@tanstack/react-query";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retries: 0, // Ensure global default is set to 0
    },
  },
});

export default queryClient;
