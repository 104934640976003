export default function Property({property}) {
    return (
      <div className="flex flex-col justify-center self-stretch px-10 max-w-screen-md max-md:px-5">
         {/* For small screens */}
      <div className="block md:hidden">
        <div className="flex flex-col self-stretch text-xs font-medium text-black max-w-[350px]">
          <div className="flex gap-5 bg-white rounded-none shadow-sm">
            <img
              loading="lazy"
              srcSet={property.image}
              className="w-[100px] aspect-square min-h-full object-cover rounded-md"
            />
            <div className="flex flex-col justify-center px-5 my-auto">
              <span className="text-base capitalize">{property.heading}</span>
              <span className="mt-1">{property.text}</span>
              <span className="mt-1">{property.subtext}</span>
              <span className="mt-1 text-sky-600 underline">{property.link}</span>
            </div>
          </div>
        </div>
      </div>
         {/* For Larger screens */}
        <div className="flex flex-col max-md:max-w-full max-md:hidden">
          <div className="p-5 rounded-3xl border border-solid border-neutral-300 max-md:max-w-full">
            <div className="flex gap-5 max-md:flex-row max-md:gap-5 items-center">
              <div className="flex flex-col w-[33%] max-md:ml-0 max-md:w-auto">
                <div className="flex justify-center items-center max-md:mt-10">
                  <img
                    loading="lazy"
                    srcSet={property.image}
                    className="aspect-square object-cover rounded-md"
                  />
                </div>
              </div>
              <div className="flex flex-col ml-5 w-[67%] max-md:ml-5 max-md:w-auto">
                <div className="flex flex-col justify-center text-2xl font-medium text-black max-md:mt-0">
                  <div className="text-3xl capitalize">{property.heading}</div>
                  <div className="mt-1">{property.text}</div>
                  <div className="mt-1">{property.subtext}</div>
                  <div className="mt-1 text-base text-sky-600 underline">
                    {property.link}
                  </div>
                </div>
              </div>
            </div>
          </div>
          </div>
           </div>
    )}