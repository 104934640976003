import { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { profileData } from "@/constants/data";
import Verify from "@/assets/profile/verify.svg";
import profile from "@/assets/profile/Profile.svg";
import Location from "@/assets/shared/location.png";
import eyeIcon from "@/assets/profile/eye.svg";
import editIcon from "@/assets/profile/edit-2.png";
import Profile3 from "@/assets/profile/Profile3.png"
import rating from "@/assets/profile/Star 1.png";
import Background from "@/assets/profile/background.png"
import House from "@/assets/profile/House.png"
import { Public } from '@/components/Profile/Public';
import eye from "@/assets/profile/eye2.png";

export const Profile = () => {
    const [isPublicView, setIsPublicView] = useState(false);
    const navigate = useNavigate();


    const togglePublicView = () => {
        setIsPublicView(!isPublicView);
        // eslint-disable-next-line no-undef
        setIsEditMode(false);
    };

    const goToEditPage = () => {
        navigate('/edit-profile');
    };

    const gotoUpdatesPage = () => {
        navigate('/updates');
    };

    return (
        <div className="max-w-[1440px] mx-auto px-4">
            <div className=" max-w-[1088px] mx-auto mt-[50px] left-[88px] flex flex-col gap-[52px]">
                <div className='flex flex-col md:flex-row justify-between items-center'>
                    <div className="flex items-center gap-3">
                        <div className="relative w-[100px] h-[100px] flex-shrink-0">
                            <img src={profile} alt={profileData.name} className="w-full h-full" />
                            <span className="absolute bottom-0 right-1 w-[20px] h-[20px] bg-[#17C1A6] rounded-full border-2 border-white"></span>
                        </div>
                        <div>
                            <div className="flex items-center">
                                <h2 className="text-[25px] font-semibold">{profileData.name}</h2>
                                <img src={Verify} alt="Verified" className="ml-1 w-[20px] h-[20px]" />
                            </div>
                            <div className="flex items-center mt-2">
                                <img src={Location} alt="Location" className="w-[22px] h-[22px]" />
                                <p className="ml-2 opacity-50 text-[15px]">{profileData.location}</p>
                            </div>
                        </div>
                    </div>



                    <div className="flex flex-col sm:flex-row gap-3 mt-4 md:mt-0">
                        <button
                            onClick={togglePublicView}
                            className={` border border-blue-600 text-white px-3 py-2 text-sm rounded-xl mb-2 sm:mb-0 flex items-center justify-center w-full sm:w-auto 
                                ${isPublicView ? 'bg-laara-primary' : 'bg-white'}`} 
                        >
                            
                            <img
                                src={isPublicView ? eye : eyeIcon}
                                alt={`${isPublicView ? 'See Private View' : 'See Public View'} icon`}
                                className="w-3 h-3 mr-2"
                            />
                            <span className="text-sm font-normal"
                                style={{ color: isPublicView ? 'white' : '#0466c8' }}>
                                {isPublicView ? 'See Private View' : 'See Public View'}
                            </span>
                        </button>

                        {!isPublicView && (
                            <button
                                onClick={goToEditPage}
                                className={`relative w-full bg-laara-primary text-white px-3 py-2 text-sm rounded-xl mb-2 sm:mb-0 flex items-center justify-center sm:w-auto `}
                            >
                                <img
                                    src={editIcon}
                                    alt={`Edit Profile icon`}
                                    className={` w-3 h-3 mr-2`}
                                />
                                <span className="text-[16px] font-normal leading-[19.2px] tracking-[0.005em]">
                                    Edit Profile
                                </span>
                            </button>
                        )}
                    </div>
                </div>



                {!isPublicView && (
                    <div className="flex justify-between gap-[20px] ">
                        {/* Side */}
                        <div className="max-w-[294px] flex flex-col justify-between">
                            <div className="w-full max-w-[294px] h-full flex flex-col justify-between">
                                <div>
                                    {profileData.stats.map(({ label, value }) => (
                                        <div key={label} className="w-full flex ml-8 pr-4 mb-20">
                                            <div className="w-[89px]">   
                                                <h2 className="font-bold text-laara-primary text-[36px] leading-[46px] tracking-[0.005em]">{value}</h2>
                                                <span className="text-sm sm:text-base text-[#626262] leading-tight tracking-tight">{label}</span>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                                <div className="w-full min-h-[191px] mt-20 p-4 rounded-[40px] bg-laara-primary  bg-cover" style={{ backgroundImage: `url(${Background})` }}>
                                    <div className="max-w-[246px] h-auto sm:h-[143px] gap-[20px] flex flex-col">
                                        <div className="max-w-[91px] h-auto sm:h-[63px]">
                                            <h4 className="font-medium text-lg leading-7 sm:leading-9 text-[20px] sm:text-[26px] tracking-tight text-left text-laara-secondary">
                                                90%
                                            </h4>
                                            <span className="font-medium text-base leading-5 sm:leading-[28px] tracking-[0.005em] text-left text-laara-secondary">
                                                Complete
                                            </span>
                                        </div>

                                        <div className="max-w-full h-auto mt-4 px-2 sm:px-0">
                                            <p className="font-normal text-sm leading-5 tracking-[0.0025em] text-left text-[#C9FAFF]">
                                                Your profile is almost done! Fill in your phone number to have a complete profile on our platform.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/* Main */}
                        <div className="flex-1 gap-20 ">
                            {['Email address', 'Phone Number', 'Bio'].map((field) => (
                                <div key={field} className={`mb-4 p-2 ${field === 'Bio' ? 'min-h-[132px]' : 'min-h-[76px]'} overflow-hidden`}>
                                    <div className="text-[#4F4F4F] text-lg sm:text-xl font-medium leading-tight mb-2">
                                        {field}
                                    </div>
                                    <div className={`text-[#626262] text-sm sm:text-base font-normal leading-relaxed break-words ${field === 'Phone Number' ? 'font-semibold' :
                                        field === 'Bio' ? 'font-normal' :
                                            'font-medium'
                                        }`}>
                                        {field === 'Email address' && profileData.email}
                                        {field === 'Phone Number' && profileData.phone}
                                        {field === 'Bio' && profileData.bio}
                                    </div>
                                </div>
                            ))}

                            <div>
                                <div className="flex justify-between items-center mb-4">
                                    <h3 className="text-[#4F4F4F] text-xl font-medium">Your Reviews</h3>
                                    <Link to="/reviews" className="text-blue-500 text-sm">See all</Link>
                                </div>

                                <div className="grid grid-cols-1 lg:grid-cols-2 gap-4">
                                    {profileData.reviews.map((review, index) => (
                                        <div key={index} className="bg-white rounded-[30px] border border-gray-100 p-3 sm:p-4">
                                            <div className="relative flex flex-col sm:flex-row">
                                                <img src={Profile3} alt="Eric" className="w-16 h-16 sm:w-20 sm:h-20 rounded-full mb-2 sm:mb-0 sm:mr-4" />
                                                <div className="flex-grow pr-4">
                                                    <div className="flex justify-between items-start mb-2">
                                                        <div>
                                                            <h3 className="font-semibold text-base sm:text-lg text-laara-primary truncate">
                                                                {review.author}
                                                            </h3>
                                                            <div className="flex items-center mt-1">
                                                                {[...Array(5)].map((_, i) => (
                                                                    <img key={i} src={rating} alt="Rating" className="w-3 h-3 sm:w-4 sm:h-4 rounded-full" />
                                                                ))}
                                                                <span className="ml-1 text-xs sm:text-sm text-gray-600">{review.rating}</span>
                                                            </div>
                                                        </div>
                                                        <div className="text-right text-xs sm:text-sm">
                                                            <span className="block text-[#888888]">{review.time}</span>
                                                            <span className="block text-[#4F4F4F]">{review.date}</span>
                                                        </div>
                                                    </div>
                                                    <p className="text-[#4F4F4F] text-sm sm:text-base italic font-normal leading-snug mt-2 line-clamp-3 sm:line-clamp-none">
                                                        {review.text || "He is an excellent guest who I would love to host again, He is an excellent guest who I would love to host again He is an excellent guest who I would love to host again"}
                                                    </p>
                                                </div>
                                                <button className="absolute top-[-1px] right-[-15px] p-2">
                                                    <div className="flex flex-col items-center justify-between w-4 h-4 sm:w-5 sm:h-5">
                                                        <div className="w-1 h-1 bg-black rounded-full"></div>
                                                        <div className="w-1 h-1 bg-black rounded-full"></div>
                                                        <div className="w-1 h-1 bg-black rounded-full"></div>
                                                    </div>
                                                </button>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>
                )}

                {!isPublicView && (
                    <div className="w-full mb-20">
                        <div className="flex justify-between items-center mb-4">
                            <h3 className="text-[#4F4F4F] text-xl font-medium">Properties You Follow</h3>
                            <Link to="/properties" className="text-blue-500 text-sm">See all</Link>
                        </div>
                        <div className="flex flex-col md:flex-row justify-between space-x-0 md:space-x-4 ">
                            {profileData.properties.map((property, index) => (
                                <div key={index} className="w-full md:w-[350px] h-[390px] border rounded-lg overflow-hidden mb-4 md:mb-0 bg-white ">
                                    <img src={House} alt={property.name} className="w-full h-48 object-cover" />
                                    <div className="p-4">
                                        <h3 className="text-[22px] font-medium leading-[32px] tracking-[0.005em] text-left mb-2">
                                            {property.name}
                                        </h3>

                                        <div className="flex items-center mb-3">
                                            <img src={Location} alt="Location" className="w-[15px] h-[15px] mr-2" />
                                            <span className="text-sm text-gray-600">{property.location}</span>
                                            <div className="ml-auto flex items-center">
                                                <svg className="w-4 h-4 text-yellow-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
                                                </svg>
                                                <span className="text-sm text-gray-600 ml-1">{property.rating}</span>
                                            </div>
                                        </div>
                                        <button
                                        onClick={gotoUpdatesPage} 
                                        className="w-full h-[47px] py-2 px-4 gap-2 bg-laara-primary text-white rounded-3xl mb-4 transition duration-300">
                                            Read Updates
                                        </button>

                                        <Link to="#" className="block text-red-500 text-[16px] font-semibold leading-[19.2px] tracking-[0.005em] text-center py-2 px-4 underline hover:text-red-600 transition duration-300">
                                            Unfollow Property
                                        </Link>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                )}
            </div>
            {isPublicView && (
                <div className="mt-4">
                    <Public />
                </div>
            )}


        </div >
    );
};
