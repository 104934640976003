import React from 'react';

function EditTaskModal({ isOpen, onClose, task, onSave }) {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-gray-600 bg-opacity-50 flex justify-center items-center">
      <div className="bg-white p-6 rounded-lg shadow-lg w-full max-w-[821px]">
        <h2 className="text-lg font-semibold mb-4 text-[#0466C8]">Edit Task</h2>
        {task && (
          <form>
            <div className="mb-4">
              <label className="block text-sm font-medium text-gray-700">User</label>
              <input
                type="text"
                value={task.user}
                onChange={(e) => onSave({ ...task, user: e.target.value })}
                className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3"
              />
            </div>
            <div className="mb-4">
              <label className="block text-sm font-medium text-gray-700">Task</label>
              <input
                type="text"
                value={task.task}
                onChange={(e) => onSave({ ...task, task: e.target.value })}
                className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3"
              />
            </div>
            <div className="mb-4">
              <label className="block text-sm font-medium text-gray-700">Date</label>
              <input
                type="date"
                value={task.date}
                onChange={(e) => onSave({ ...task, date: e.target.value })}
                className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3"
              />
            </div>
            <div className="mb-4">
              <label className="block text-sm font-medium text-gray-700">Notes</label>
              <textarea
                type="text"
                value={task.status}
                onChange={(e) => onSave({ ...task, status: e.target.value })}
                className="mt-1 block w-full h-32 border border-gray-300 rounded-md shadow-sm py-2 px-3"
                placeholder="Enter status"
              />
            </div>
            <div className="flex justify-end space-x-4">
              <button className="px-4 py-2 border border-[#0466C8] text-[#0466C8] rounded" onClick={onClose}>
                Cancel
              </button>
              <button className="px-4 py-2 bg-[#0466C8] text-white rounded" onClick={onClose}>
                Done
              </button>
            </div>
          </form>
        )}
      </div>
    </div>
  );
}

export default EditTaskModal;
