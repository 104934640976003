import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { setRoomAmenities } from "@/redux/Listing/listingSlice";
import useFetchRoomDetails from "./globalrooms/useFetchRoomDetails";
import './globalrooms/rooms.css';
import Header from './globalrooms/Header';
import { useParams } from "react-router-dom";

const defaultAmenities = {
  General: [
    "Clothes rack",
    "Flat screen TV",
    "Air conditioning",
    "Linen",
    "Workspace desk",
    "Wake-up service",
    "Towels",
    "Wardrobe or closet",
    "Heating",
    "Fan",
    "Safety deposit box",
    "Entire unit located on ground floor",
  ],
  "Outdoor and Views": ["Balcony", "Ocean view", "Nature view", "Terrace"],
  "Food and Beverage": [
    "Electric kettle",
    "Tea/coffee maker",
    "Dining area",
    "Dining table",
    "Microwave",
    "Refrigerator",
  ],
};

const EditRoomAmenities = () => {
  const { id, number } = useParams();
  const dispatch = useDispatch();
  const { rooms, loading, error } = useFetchRoomDetails(id);

  const [currentAmenities, setCurrentAmenities] = useState({
    General: [],
    "Outdoor and Views": [],
    "Food and Beverage": [],
  });

  const [roomType, setRoomType] = useState(''); 

 useEffect(() => {
  console.log("Fetched Rooms Data:", rooms);
  console.log("Property ID:", id);
  console.log("Room Number from Params:", number);

  if (rooms && rooms.length > 0) {
    const room = rooms.find(r => r.number === number);
    console.log("Selected Room:", room);

    if (room) {
      const amenitiesMap = {
        General: [],
        "Outdoor and Views": [],
        "Food and Beverage": [],
      };

      // Assuming roomType might be under room.roomTypes.name
      const type = room.roomType?.name || room.roomTypes?.name || ''; 
      setRoomType(type); // Safeguard for undefined or nested roomType

      room.roomTypes.roomTypeAmenities.forEach((amenity) => {
        const { category, name } = amenity.amenities;
        if (amenitiesMap[category]) {
          amenitiesMap[category].push(name);
        }
      });

      setCurrentAmenities(amenitiesMap);
      console.log("Mapped Amenities:", amenitiesMap);
    } else {
      console.error("Room not found with number:", number);
    }
  }
}, [rooms, id, number]);

  const handleToggleAmenity = (category, amenity) => {
    const updatedAmenities = {
      ...currentAmenities,
      [category]: currentAmenities[category].includes(amenity)
        ? currentAmenities[category].filter((a) => a !== amenity)
        : [...currentAmenities[category], amenity],
    };

    setCurrentAmenities(updatedAmenities);
    console.log("Updated Amenities State:", updatedAmenities);
  };

  const handleSaveChanges = () => {
    dispatch(setRoomAmenities(
      Object.entries(currentAmenities).flatMap(([cat, items]) =>
        items.map((item) => ({ name: item, category: cat, description: `${item} available` }))
      )
    ));
    // Optionally add code here to navigate away or show a success message
    console.log("Amenities saved:", currentAmenities);
  };
  console.log("roomType:", roomType);

  const renderCheckboxes = (category, items) => (
    <div key={category} className="space-y-4 text-gray-700">
      <p className="text-[18px] font-medium capitalize">{category}</p>
      <div className="flex flex-col gap-2">
        {items.map((item) => (
          <div key={item} className="flex items-center gap-3 cursor-pointer">
            <input
              id={`${category}-${item}`}
              type="checkbox"
              className="size-5 custom-checkbox1 text-sm"
              checked={currentAmenities[category]?.includes(item)}
              onChange={() => handleToggleAmenity(category, item)}
            />
            <label
              htmlFor={`${category}-${item}`}
              className={`capitalize text-sm ${
                currentAmenities[category]?.includes(item) ? "text-laara-primary" : ""
              }`}
            >
              {item}
            </label>
          </div>
        ))}
      </div>
    </div>
  );

  if (loading) {
    return (
      <div className="flex justify-center items-center h-screen">
        <div className="w-16 h-16 border-4 border-t-4 border-gray-200 border-t-blue-500 border-solid rounded-full animate-spin" aria-label="Loading"></div>
      </div>
    );
  }

  if (error) return <p>Error: {error}</p>;

  return (
    <div className='p-8 bg-base-white'>
      <Header linkTo={`/room-details/${id}/${roomType}/${number}`} title="Room Details" />
      <h4 className="font-semibold text-[18px] leading-[26px]">
        What can your guests use in this room?
      </h4>
      <form className="flex flex-col max-w-[1264px]">
        <div className="border border-custom-gray-200 rounded-lg mt-8 p-8 w-full">
          <div className="flex items-start gap-4 mt-5">
            <div className="space-y-4">
              {Object.entries(defaultAmenities).map(([category, items]) =>
                renderCheckboxes(category, items)
              )}
            </div>
          </div>
        </div>
        <div className='w-full flex justify-end max-w-[1264px]'>
          <button
            type="button"
            onClick={handleSaveChanges}
            className="mt-8 p-3 bg-blue-500 text-white rounded-[36px] hover:bg-blue-600 w-[200px]"
          >
            Save Changes
          </button>
        </div>
      </form>
    </div>
  );
};

export default EditRoomAmenities;
