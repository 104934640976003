import { useState } from 'react';
import { languages } from "@/constants/data";
const LanguageChanger = () => {
  const [selectedLanguage, setSelectedLanguage] = useState(languages[0]);

  const handleChangeLanguage = (language) => {
    setSelectedLanguage(language);
  };

  return (
    <div className="flex gap-5 mt-24 max-md:flex-wrap max-md:mt-10">
      <div className="flex flex-col flex-1 leading-[120%] max-md:max-w-full">
        <div className="text-2xl font-medium text-neutral-600 max-md:max-w-full">
          Change your display language
        </div>
        <div className="mt-2.5 text-lg text-neutral-400 max-md:max-w-full">
          Update display language from{" "}
          <span className="text-sky-600">{selectedLanguage.name}</span> to your preferred language
        </div>
      </div>
      <div className="flex flex-col justify-center my-auto bg-white">
        <div className="flex flex-col justify-center">
          <div className="flex gap-2 p-3.5 rounded-xl border border-solid border-neutral-300">
            <img
              loading="lazy"
              srcSet={selectedLanguage.flag}
              className="shrink-0 aspect-[2] w-[50px] rounded-sm"
              alt={selectedLanguage.name}
            />
            <select
              className="shrink-0 my-auto h-auto"
              onChange={(e) => handleChangeLanguage(languages[e.target.value])}
              value={languages.findIndex((language) => language.name === selectedLanguage.name)}
            >
              {languages.map((language, index) => (
                <option key={index} value={index}>
                  {language.name}
                </option>
              ))}
            </select>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LanguageChanger;
