// eslint-disable-next-line no-unused-vars
import React, { useState } from "react";
import { Link, useParams } from "react-router-dom";
import Consider from "@/components/Listings/Consider";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLessThan } from "@fortawesome/free-solid-svg-icons";
import "./editproperty.css";

const PropertyCancellation = () => {
  const { id } = useParams();
  const [freeCancellation, setFreeCancellation] = useState("");
  const [cancellationCharge, setCancellationCharge] = useState("");

  const handleCancellationChange = (event) => {
    setFreeCancellation(event.target.value);
  };

  const handleChargeChange = (event) => {
    setCancellationCharge(event.target.value);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    // Save the cancellation policies
    console.log("Free Cancellation:", freeCancellation);
    console.log("Cancellation Charge:", cancellationCharge);
    // Navigate to another page or show a success message
  };

  return (
    <div className="p-8 bg-base-white flex">
      <div className="flex-1">
        <section className="top h-16 flex items-center p-2 gap-3">
          <div className="flex items-center gap-4 text-lg text-gray-600 w-[700px]">
            <Link to={`/view-property/${id}`}>
              <FontAwesomeIcon icon={faLessThan} className="text-base" />
            </Link>
            <h3>Cancellation Policies</h3>
          </div>
        </section>
        <section className="flex w-full max-w-[1264px] gap-5">
          <form
            className="flex flex-col p-2 max-w-[840px] w-full max-h-[700px] h-full"
            onSubmit={handleSubmit}
          >
            <div className="flex flex-col gap-4 mt-5 text-gray-700 text-[14px]">
              <div className="flex flex-col gap-4 p-5 max-w-[840px] w-full justify-center items-center bg-laara-secondary/35 border-[0.2px] border-custom-gray-300 rounded-lg">
                <div className="flex flex-col gap-4 max-w-[700px] w-full p-2">
                  <div className="flex flex-col w-full p-6 rounded-lg border border-custom-gray-300">
                    <label className="text-[14px] mb-4">
                      When can the guests cancel their bookings for free?
                    </label>
                    <select
                      value={freeCancellation}
                      onChange={handleCancellationChange}
                      className="border py-2 px-3 rounded-lg block outline-none bg-transparent text-sm"
                    >
                      <option value="" disabled>
                        Select an option
                      </option>
                      <option value="1 day">
                        Before 18:00 on the day of arrival
                      </option>
                      <option value="2 days">
                        Up to 1 day before the day of arrival
                      </option>
                      <option value="3 days">
                        Up to 2 days before the day of arrival
                      </option>
                      <option value="1 week">
                        Up to 3 days before the day of arrival
                      </option>
                      <option value="2 weeks">
                        Up to 7 days before the day of arrival
                      </option>
                      <option value="1 month">
                        Up to 14 days before the day of arrival
                      </option>
                    </select>
                  </div>
                  <div className="flex flex-col w-full mt-4">
                    <label className="text-[14px]">
                      How much is the client charged if they cancel the booking
                      after the free cancellation window?
                    </label>
                    <div className="flex flex-col gap-2 mt-2">
                      <label className="flex items-center gap-2">
                        <input
                          type="radio"
                          value="first night"
                          checked={cancellationCharge === "first night"}
                          onChange={handleChargeChange}
                          className="form-radio appearance-none h-4 w-4 border border-gray-300 rounded-full checked:bg-blue-500 checked:border-transparent focus:outline-none relative"
                        />
                        <span className="checked-dot absolute inset-0 flex items-center justify-center pointer-events-none">
                          <span className="block w-2 h-2 bg-white rounded-full"></span>
                        </span>
                        Cost of the first night
                      </label>
                      <label className="flex items-center gap-2">
                        <input
                          type="radio"
                          value="50% total price"
                          checked={cancellationCharge === "50% total price"}
                          onChange={handleChargeChange}
                          className="form-radio appearance-none h-4 w-4 border border-gray-300 rounded-full checked:bg-blue-500 checked:border-transparent focus:outline-none relative"
                        />
                        <span className="checked-dot absolute inset-0 flex items-center justify-center pointer-events-none">
                          <span className="block w-2 h-2 bg-white rounded-full"></span>
                        </span>
                        50% of the total price
                      </label>
                      <label className="flex items-center gap-2">
                        <input
                          type="radio"
                          value="100% total price"
                          checked={cancellationCharge === "100% total price"}
                          onChange={handleChargeChange}
                          className="form-radio appearance-none h-4 w-4 border border-gray-300 rounded-full checked:bg-blue-500 checked:border-transparent focus:outline-none relative"
                        />
                        <span className="checked-dot absolute inset-0 flex items-center justify-center pointer-events-none">
                          <span className="block w-2 h-2 bg-white rounded-full"></span>
                        </span>
                        100% of the total price
                      </label>
                    </div>
                  </div>
                </div>
              </div>
              <div className="w-full flex justify-end gap-5 items-center mt-5">
                <button
                  type="submit"
                  className="py-2 px-4 bg-blue-500 text-white rounded-[36px] w-[216px] h-[55px]"
                >
                  Save Changes
                </button>
              </div>
            </div>
          </form>
          <Consider
            title={"Cancellation Policies"}
            data={[
              "Define the free cancellation period.",
              "Specify the charges after the free cancellation window.",
              "Ensure clear communication of policies to guests.",
            ]}
            className="mt-5"
          />
        </section>
      </div>
    </div>
  );
};

export default PropertyCancellation;
