// eslint-disable-next-line no-unused-vars
import React from 'react';
import { useNavigate } from 'react-router-dom';
import './updates.css'; 
import { properties } from '@/constants/data';
import locationIcon from "@/assets/shared/location.png";
import starIcon from "@/assets/shared/star.png";

const Updates = () => {
  const navigate = useNavigate();

  const goToUpdatesProperty = (propertyId) => {
    navigate(`/updatesproperty/${propertyId}`);
  };

  return (
    <div className="updates-page font-poppins">
      <header className="updates-header">
        <h1 className="updates-title font-bold">Properties You Follow</h1>
        <p className="updates-subtitle">Select any to read full update and details</p>
      </header>
      <div className="properties-list">
        {properties.map((property, index) => (
          <div key={index} className="property-card">
            <img src={property.propertyImage} alt={property.propertyName} className="property-image" />
            <div className="property-list-details ml-2 mr-2 mb-2">
              <h2 className="property-name">{property.propertyName}</h2>
              <div className="properties-list-location flex items-center text-sm text-gray-600 mb-2">
                <img src={locationIcon} alt="Location Icon" className="w-4 h-4 mr-2" />
                <p>{property.location}</p>
                <img src={starIcon} alt="Star Icon" className="w-4 h-4 ml-4 mr-2" />
                <p>{property.rating}</p>
              </div>
              <button 
                onClick={() => goToUpdatesProperty(property.propertyId)}
                className="view-updates-button">
                View Updates
              </button>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Updates;
