import { useState } from 'react';
import { currencies } from "@/constants/data";
const CurrencyChanger = () => {
  const [selectedCurrency, setSelectedCurrency] = useState(currencies[0]);

  const handleChangeCurrency = (currency) => {
    setSelectedCurrency(currency);
  };

  return (
    <div className="flex gap-5 mt-24 max-md:flex-wrap max-md:mt-10">
      <div className="flex flex-col flex-1 leading-[120%] max-md:max-w-full">
        <div className="text-2xl font-medium text-neutral-600 max-md:max-w-full">
          Change your payment currency
        </div>
        <div className="mt-2.5 text-lg text-neutral-400 max-md:max-w-full">
          Update your currency from{" "}
          <span className="text-orange-400">{selectedCurrency.name}</span> to a currency of
          your choice
        </div>
      </div>
      <div className="flex flex-col justify-center my-auto bg-white">
        <div className="flex flex-col justify-center">
          <div className="flex gap-2 p-3.5 rounded-xl border border-solid border-neutral-300">
            <img
              loading="lazy"
              src={selectedCurrency.flag}
              className="shrink-0 aspect-[2] w-[50px] rounded-sm "
              alt={selectedCurrency.name}
            />
            <select
              className="shrink-0 my-auto h-auto"
              onChange={(e) => handleChangeCurrency(currencies[e.target.value])}
              value={currencies.findIndex((currency) => currency.name === selectedCurrency.name)}
            >
              {currencies.map((currency, index) => (
                <option key={index} value={index}>
                  {currency.name}
                </option>
              ))}
            </select>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CurrencyChanger;
