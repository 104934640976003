import PropTypes from "prop-types";
import { Link, useParams } from "react-router-dom";
import { useState } from "react";

// Utility function to format room type names for display (capitalize words)
const formatRoomTypeName = (name) => {
  if (!name) return "";
  return name
    .replace(/-/g, " ") // Replace hyphens with spaces for display purposes
    .split(" ") // Split the string into words
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1)) // Capitalize the first letter of each word
    .join(" "); // Join the words back into a string
};

// Utility function to format room type names for URLs 
const formatRoomTypeForUrl = (name) => {
  if (!name) return "";
  return name.replace(/\s+/g, "-"); 
};

// Utility function to format room numbers for URLs 
const formatRoomNumberForUrl = (number) => {
  if (!number) return "";
  return number.replace(/\s+/g, "-"); // Replace spaces with hyphens
};

const RoomDetailsTab = ({ rooms = [], closeRoomDetailsTab }) => {
  const { id } = useParams();
  const [selectedRoomType, setSelectedRoomType] = useState(null);
  const [showRoomNumberModal, setShowRoomNumberModal] = useState(false);

  const roomTypes = rooms.reduce((acc, room) => {
    if (room.roomTypes) {
      const existingType = acc.find(
        (type) => type.name === room.roomTypes.name
      );
      if (existingType) {
        existingType.rooms.push(room); // Add room to existing room type
      } else {
        acc.push({
          name: room.roomTypes.name,
          rooms: [room],
        });
      }
    }
    return acc;
  }, []);

  const handleRoomTypeClick = (roomType) => {
    if (roomType.rooms.length > 1) {
      setSelectedRoomType(roomType);
      setShowRoomNumberModal(true); // Show the room number modal
    } else {
      // Redirect to room details page for the single room
      const room = roomType.rooms[0];
      const urlName = formatRoomTypeForUrl(roomType.name);
      const formattedRoomNumber = formatRoomNumberForUrl(room.number);
      window.location.href = `/room-details/${id}/${urlName}/${formattedRoomNumber}`;
    }
  };


  const closeRoomNumberModal = () => {
    setShowRoomNumberModal(false);
  };

  return (
    <div
      style={{
        position: "fixed",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: "rgba(0,0,0,0.5)",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        zIndex: 1000,
      }}
    >
      <div
        style={{
          backgroundColor: "white",
          padding: "20px",
          borderRadius: "8px",
          position: "relative",
          width: "600px",
          maxHeight: "90vh",
          overflowY: "auto",
        }}
        className="text-gray-700 cursor-pointer"
      >
        <button
          onClick={closeRoomDetailsTab}
          style={{
            position: "absolute",
            top: "10px",
            right: "10px",
          }}
          className="rounded-full w-[25px] h-[25px] border-gray-700 border font-medium hover:bg-gray-700 hover:text-white transition-all duration-300 ease-in"
        >
          X
        </button>
        <h2 className="text-base mb-4 font-medium">Room Types</h2>
        <ul className="w-full p-2 flex flex-col items-center text-[14px] gap-3">
          {roomTypes.length > 0 ? (
            roomTypes.map((roomType, index) => {
              const formattedName = formatRoomTypeName(roomType.name);
              return (
                <li
                  key={index}
                  onClick={() => handleRoomTypeClick(roomType)}
                  className="hover:bg-custom-gray-200 w-full h-[30px] rounded-[36px] transition-all duration-300 ease-in flex items-center justify-center cursor-pointer"
                >
                  {formattedName}
                </li>
              );
            })
          ) : (
            <li className="w-full h-[30px] rounded-[36px] flex items-center justify-center">
              No Room Types Available
            </li>
          )}
        </ul>

        {/* Room Number Modal */}
        {showRoomNumberModal && (
          <div
            style={{
              position: "fixed",
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              backgroundColor: "rgba(0,0,0,0.5)",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              zIndex: 1100, // Slightly higher zIndex for room number modal
            }}
          >
            <div
              style={{
                backgroundColor: "white",
                padding: "20px",
                borderRadius: "8px",
                position: "relative",
                width: "400px",
                maxHeight: "70vh",
                overflowY: "auto",
              }}
              className="text-gray-700 cursor-pointer"
            >
              <button
                onClick={closeRoomNumberModal}
                style={{
                  position: "absolute",
                  top: "10px",
                  right: "10px",
                }}
                className="rounded-full w-[25px] h-[25px] border-gray-700 border font-medium hover:bg-gray-700 hover:text-white transition-all duration-300 ease-in"
              >
                X
              </button>
              <h3 className="text-base mb-2 font-medium">Select Room Number</h3>
              <ul className="w-full p-2 flex flex-col items-center text-[14px] gap-3">
                {selectedRoomType &&
                  selectedRoomType.rooms.map((room, index) => (
                    <Link
                      key={index}
                      to={`/room-details/${id}/${formatRoomTypeForUrl(
                        selectedRoomType.name,
                      )}/${room.number}`}
                      className="w-full"
                    >
                      <li className="hover:bg-custom-gray-200 w-full h-[30px] rounded-[36px] transition-all duration-300 ease-in flex items-center justify-center">
                        {room.number}
                      </li>
                    </Link>
                  ))}
              </ul>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

RoomDetailsTab.propTypes = {
  rooms: PropTypes.arrayOf(
    PropTypes.shape({
      number: PropTypes.string.isRequired,
      roomTypes: PropTypes.shape({
        id: PropTypes.number,
        name: PropTypes.string,
        description: PropTypes.string,
        maxGuests: PropTypes.number,
        typeSize: PropTypes.number,
        bathroom: PropTypes.shape({
          isPrivate: PropTypes.bool,
          bathroomAmenties: PropTypes.arrayOf(
            PropTypes.shape({
              amenities: PropTypes.shape({
                category: PropTypes.string,
                description: PropTypes.string,
                name: PropTypes.string,
                icon: PropTypes.string,
              }),
            }),
          ),
        }),
        roomTypeImages: PropTypes.arrayOf(
          PropTypes.shape({
            images: PropTypes.shape({
              url: PropTypes.string,
            }),
          }),
        ),
        roomTypePolicies: PropTypes.arrayOf(
          PropTypes.shape({
            policies: PropTypes.shape({
              description: PropTypes.string,
              type: PropTypes.string,
              isMandatory: PropTypes.bool,
            }),
          }),
        ),
        roomTypeAmenities: PropTypes.arrayOf(
          PropTypes.shape({
            amenities: PropTypes.shape({
              description: PropTypes.string,
              name: PropTypes.string,
              category: PropTypes.string,
              icon: PropTypes.string,
            }),
          }),
        ),
        bedTypes: PropTypes.arrayOf(
          PropTypes.shape({
            bedType: PropTypes.shape({
              description: PropTypes.string,
              name: PropTypes.string,
            }),
          }),
        ),
        pricings: PropTypes.array,
        mealOptions: PropTypes.array,
      }),
    }),
  ),
  closeRoomDetailsTab: PropTypes.func.isRequired,
};

export default RoomDetailsTab;
