import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams, useLocation } from "react-router-dom";
import arrowleft from "@/assets/shared/arrowleft.svg";
import { bookingDetails } from "@/constants/data";
import locationIcon from "@/assets/bookings/location-black.svg";
import noSmoking from "@/assets/bookings/handle-x.svg";
import pet from "@/assets/bookings/cat.svg";
import breakfast from "@/assets/bookings/tea-drink.svg";
import basketball from "@/assets/bookings/play-basketball.svg";
import wifi from "@/assets/bookings/Wifi.svg";
import parking from "@/assets/bookings/parking.svg";
import laundry from "@/assets/bookings/clothes-crew-neck.svg";
import taxi from "@/assets/bookings/taxi.svg";
import tick from "@/assets/shared/check-one.svg";
import scanbarcode from "@/assets/shared/scan-barcode.svg";
import tree from "@/assets/bookings/tree.svg";
import tv from "@/assets/bookings/tv-one.svg";
import hotel from "@/assets/bookings/hotel.svg";
import shower from "@/assets/bookings/shower-head.svg";
import { Heart } from "lucide-react";
import { baseURL, headers } from "../../constants/apiconfig";
import { useSelector } from "react-redux";
import { icon } from "leaflet";
import dayjs from 'dayjs';
import avatar from "@/assets/shared/avatar.svg";



const BookingDetails = () => {
  const { ref } = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const [booking, setBooking] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const { currentUser, accessToken } = useSelector((state) => state.user);
  const token = currentUser?.stsTokenManager?.accessToken || accessToken;
  const [propertyAmenities, setPropertyAmenities] = useState([]);
  const [roomAmenities, setRoomAmenities] = useState([]);
  const { status } = location.state || {};
  const Checkin = booking?.startDate ? dayjs(booking.startDate).format('DD MMMM YYYY') : '';
  const Checkout = booking?.endDate ? dayjs(booking.endDate).format('DD MMMM YYYY') : '';
  const ArriveTime = booking?.startDate ? dayjs(booking.startDate).format('h:mmA') : '';
  const nights = booking?.startDate && booking?.endDate
    ? dayjs(booking.endDate).diff(dayjs(booking.startDate), 'day')
    : 0;
  useEffect(() => {
  }, [location.state]);

  useEffect(() => {
    const fetchBookingDetails = async () => {
      try {
        const response = await fetch(`${baseURL}/users/reservations/${ref}`, {
          headers: headers(token),
        });
        if (!response.ok) {
          throw new Error("Failed to fetch booking details");
        }
        const data = await response.json();
        setBooking(data.reservation);
        console.log(data)
        setPropertyAmenities(data.reservation.rooms.properties.propertyAmenities.map(item => ({
          name: item.amenities.name,
          description: item.amenities.description,
          category: item.amenities.category
        })));
        setRoomAmenities(data.reservation.rooms.roomTypes.roomTypeAmenities.map(item => ({
          name: item.amenities.name,
          description: item.amenities.description,
          category: item.amenities.category
        })));
      } catch (err) {
        setError(err.message);
      } finally {
        setTimeout(() => {
          setLoading(false);
        }, 1500);
      }
    };

    fetchBookingDetails();
  }, [ref]);

  if (loading) {
    return (
      <div className="flex justify-center items-center h-screen">
        <div className="w-16 h-16 border-4 border-t-4 border-gray-200 border-t-blue-500 border-solid rounded-full animate-spin"></div>
      </div>
    );
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  if (!booking) {
    return <div>No booking data available</div>;
  }


  const statusColor =
    status === "upcoming"
      ? "text-laara-primary"
      : status === "pending"
        ? "text-[#F5BD02]"
        : status === "checked in"
          ? "text-[#14C804]"
          : status === "COMPLETED"
            ? "text-[#008080]"
            : status === "awaiting review"
              ? "text-[#449FFB]"
              : "text-black";

  return (
    <section className="w-full py-5 bg-[#FAFAFA] ">
      <section className="2xl:max-w-[1440px] xl:max-w-[1280px] w-full mx-auto px-5 md:mb-10 lg:px-10 xl:px-7 2xl:px-10   ">
        <section className="xl:max-w-[1264px] xl:px-2 w-full space-y-5 ">
          {/* TOP SECTION */}
          <div className="flex items-center gap-2  ">
            <img
              src={arrowleft}
              alt="arrow-left icon"
              onClick={() => navigate(-1)}
              className="size-6 cursor-pointer"
            />
            <h4 className="text-xl lg:text-lg font-medium text-[#626262] ">
              Bookings Details
            </h4>
          </div>

          {/* MAIN SECTION */}
          <section className="flex flex-col lg:flex-row xl:flex-row items-start justify-between gap-6 lg:gap-6 xl:gap-[64px]  ">
            {/* RIGHT SIDE */}
            <div className="max-w-[519px] w-full space-y-6 ">
              {/* CONTAINER */}
              <div className=" p-4 rounded-[8px] flex flex-col lg:flex-row xl:flex-row  border border-[#62626233/20] ">
                {/* IMAGES */}
                <div className="w-full lg:w-[156px] flex flex-wrap items-start gap-2 md:gap-3 ">
                  {booking?.rooms.roomTypes.roomTypeImages.map((item, index) => (
                    <img
                      key={index}
                      src={item.images.url}
                      alt="listing image"
                      className="w-[140px] h-[120px] object-cover rounded-[12px] "
                    />
                  ))}
                </div>

                {/* BOOKING INFO */}
                <div className="py-2 lg:p-2 space-y-4  ">
                  <div>
                    <h4 className="text-lg font-bold text-[#383838] capitalize ">
                      {booking.rooms.properties.name}
                    </h4>
                    <p className="text-base text-[#565656] capitalize ">
                      {booking.rooms.properties.name}
                    </p>
                  </div>

                  {/* LOCATION INFO */}
                  <div>
                    <div className="flex items-center justify- gap-2">
                      <img
                        src={locationIcon}
                        alt="location icon"
                        className="size-5"
                      />
                      <p className="text-base text-[#383838] ">
                        Location Information
                      </p>
                    </div>
                    <ul className="list-disc list-inside">
                      {booking?.location?.map((item, index) => (
                        <li
                          key={index}
                          className="text-[#565656] text-sm pl-1 capitalize "
                        >
                          {item}
                        </li>
                      ))}
                    </ul>
                  </div>

                  {/* AMENITIES */}
                  <div className="space-y-4">
                    <h5 className="text-lg font-bold text-[#383838]">Property Amenities</h5>
                    {propertyAmenities.map((amenity, index) => (
                      <div key={index} className="flex items-center gap-2">
                        <p className="text-base text-[#565656]">{amenity.name}</p>
                      </div>
                    ))}
                  </div>
                </div>
              </div>

              {/* BOOKING DETAILS */}
              <div className="space-y-2 border border-[#62626233/20] rounded-[8px]">
                <div className="px-1 pt-4 ">
                  <div className="p-3 space-y-3">
                    <h5 className="text-lg font-bold text-[#383838] ">
                      Booking Details
                    </h5>

                    {/* CHECK IN CHECK OUT */}
                    <div className=" flex items-center gap-6 ">
                      <div className="p-2 border-l border-[#07689F] rounded-l-[4px]">
                        <h5 className="text-lg font-bold text-[#383838] ">
                          Check - In
                        </h5>
                        <p className="text-base text-[#383838] font-medium capitalize ">
                          {Checkin}
                        </p>
                      </div>

                      <div className="p-2 border-l border-[#07689F] rounded-l-[4px] ">
                        <h5 className="text-lg font-bold text-[#383838] ">
                          Check - Out
                        </h5>
                        <p className="text-base text-[#383838] font-medium capitalize ">
                          {Checkout}
                        </p>
                      </div>
                    </div>

                    {/* GUEST INFO */}
                    <div>
                      <ul className="text-base capitalize ">
                        <li>Stays: {nights} {nights === 1 ? 'night' : 'nights'}</li>
                        <li>
                          selected rooms for:
                          <ul className="list-inside list-disc ">
                            <li>{booking?.occupants} Occupants</li>

                          </ul>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>

                {/* ROOM TYPE */}
                <div>
                  <div className="px-4 pb-4 space-y-1 ">
                    <h4 className="text-lg font-bold text-[#383838] ">
                      The Room:{" "}
                      <span className="font-normal capitalize ">
                        {booking.rooms.properties.name}
                      </span>
                    </h4>

                    <div className="space-y-4  ">
                      <h5 className="text-lg font-bold text-[#383838]">Room Amenities</h5>
                      <div className="flex items-center gap-3">
                        {roomAmenities.map((amenity, index) => (
                          <div key={index} >
                            <p className="flex items-center gap-3 bg-[#DAECFE] rounded-[4px] px-2 py-1 ">{amenity.name}</p>
                          </div>
                        ))}
                      </div>

                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* LEFT SIDE */}
            <div className="space-y-4 md:space-y-6 xl:space-y-12 xl:max-w-[681px] md:max-w-[519px] w-full">
              {/* USER INFO */}
              <div className=" border border-[#62626233/20] rounded-[8px] p-4 w-full xl:h-[120px]  ">
                {/* GUEST DETAILS */}
                <div className="flex items-center justify-between flex-wrap">
                  <div className="flex items-center gap-4">
                    <img
                      src={avatar}
                      alt="avatar"
                      className="size-[60px] rounded-full"
                    />
                    <div>
                      <p
                        className={`lg:hidden ${statusColor}  text-sm md:text-base font-medium `}
                      >
                        {booking?.status}
                      </p>
                      <h5 className="text-[#383838] text-sm md:text-base font-bold capitalize ">
                        {booking?.creator?.firstName}{" "}
                        {booking?.creator?.lastName}
                      </h5>
                      <p className="text-[#383838] text-sm md:text-base">
                        {booking?.creator.emailAddress}
                      </p>
                      <p className="text-[#383838] text-sm md:text-base">
                        {booking?.phone}
                      </p>
                    </div>
                  </div>

                  {/* STATUS */}
                  <p
                    className={`hidden lg:flex p-2 rounded-[4px] ${statusColor} text-sm xl:text-base border border-[#07689F] font-medium `}
                  >
                    {status}
                  </p>

                  {/* MESSAGE GUEST */}
                  <Link
                    to={`/chatbookings/${ref}`}
                    className="hidden xl:flex text-[#07689F]"
                  >
                    Message Guest
                  </Link>
                </div>
              </div>

              {/* ADDITIONAL SERVICES  */}
              <div className=" border border-[#62626233/20] rounded-[8px] p-4 space-y-4 ">
                <div>
                  <h5 className="text-[#383838] text-base font-bold capitalize ">
                    Additional services Booked
                  </h5>

                  <div className="">
                    {booking?.additionalServices?.map((item, index) => (
                      <div key={index} className="flex items-center gap-2">
                        <div className="size-[18px] border border-[#383838]" />
                        <p className="capitalize text-[#383838] text-base font-medium ">
                          {item}
                        </p>
                      </div>
                    ))}
                    {booking?.additionalServices?.includes("cab booking") && (
                      <div className="flex items-center gap-2 ml-4">
                        <img src={taxi} alt="taxi icon" className="size-5" />
                        <p className="text-[#383838] text-sm ">Laara Cabs</p>
                      </div>
                    )}
                  </div>
                </div>

                {/* SPECIAL REQUESTS */}
                <div>
                  <h5 className="text-[#383838] text-base font-bold capitalize ">
                    Special Requests
                  </h5>

                  <div className="">
                    {booking?.specialRequests?.map((item, index) => (
                      <p
                        key={index}
                        className="capitalize text-[#383838] text-base"
                      >
                        {item}
                      </p>
                    ))}
                  </div>
                </div>

                {/* ARRIVAL TIME */}
                <div>
                  <h5 className="text-[#383838] text-base font-bold capitalize ">
                    Guest Arrival Time
                  </h5>

                  <div className="flex items-center gap-2">
                    <img src={tick} alt="icon" className="size-5" />
                    <p className="text-[#383838] text-base">
                      Check - In: {ArriveTime}
                    </p>
                  </div>
                </div>
              </div>

              {/* BOOKING ID */}
              <div className=" border border-[#62626233/20] rounded-[8px] py-2 ">
                <div className="px-4 pt-4 space-y-4 ">
                  <h5 className="text-[#383838] text-base font-bold capitalize ">
                    Booking ID
                  </h5>
                  <p className="text-black font-medium ">{booking?.ref}</p>
                  {status === "checked in" ? (
                    <Link
                      to={`/scan-booking/${ref}`}
                      className="flex items-center justify-center gap-2 capitalize bg-laara-primary rounded-[36px] text-white font-medium w-full h-10"
                    >
                      <img
                        src={scanbarcode}
                        alt="scan icon"
                        loading="lazy"
                        className="size-[22px]"
                      />
                      scan code to check out
                    </Link>
                  ) : status === "upcoming" ? (
                    <Link
                      to={`/scan-booking/${ref}`}
                      className="flex items-center justify-center gap-2 capitalize bg-laara-primary rounded-[36px] text-white font-medium w-full h-10"
                    >
                      <img
                        src={scanbarcode}
                        alt="scan icon"
                        loading="lazy"
                        className="size-[22px]"
                      />
                      scan code to check in
                    </Link>
                  ) : status === "AWAITING_REVIEW" ? (
                    <Link
                      to={`/review-booking/${ref}`}
                      className="flex items-center justify-center gap-2 capitalize text-laara-primary rounded-[36px] border border-laara-primary font-medium w-full h-10"
                    >
                      <Heart className="text-laara-primary size-[18px]" />
                      give a review
                    </Link>
                  ) : status === "COMPLETED" ? (
                    <Link
                      to={`/review-booking/${ref}`}
                      className="flex items-center justify-center gap-2 capitalize text-laara-primary rounded-[36px] border border-laara-primary font-medium w-full h-10"
                    >
                      <Heart className="text-laara-primary size-[18px]" />
                      review
                    </Link>
                  ) : null}
                </div>
              </div>
            </div>
          </section>
        </section>
      </section>
    </section>
  );
};

export default BookingDetails;
