// eslint-disable-next-line no-unused-vars
import React, { useState } from 'react';
import Consider from "@/components/Listings/Consider";          
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCamera, faLessThan } from '@fortawesome/free-solid-svg-icons';

const UploadPhotos = () => {
  const navigate = useNavigate();
  const [dragging, setDragging] = useState(false);

  const handleDragOver = (e) => {
    e.preventDefault();
    setDragging(true);
  };

  const handleDragLeave = () => {
    setDragging(false);
  };

  const handleDrop = (e) => {
    e.preventDefault();
    setDragging(false);
    // Handle file upload here
  };

  const handleFileSelect = () => {
    // const files = e.target.files; // Remove or use this variable
    // Handle file upload here
  };

  return (
    <div className="p-8 bg-base-White">
      <section className="top h-16 flex items-center p-2 gap-3">
        <div className="flex items-center gap-4 text-lg text-gray-600 w-[700px]">
          <button onClick={() => navigate(-1)}>
            <FontAwesomeIcon icon={faLessThan} className="text-base" />
          </button>
          <h3>Upload Photos for Property</h3>
        </div>
      </section>
      <h3 className="m-3 ml-0 p-2">Upload Your Property Photos</h3>
      <section className='flex justify-between '>
      <div className="max-w-[728px] w-full p-4 flex flex-col items-center bg-laara-secondary/25 m-2">
        <p className="text-center font-normal m-2">Upload at least 5 high quality photos.</p>
        <div
          className={`w-full max-w-[625px] h-[300px] border-2 border-dashed ${dragging ? 'border-blue-500' : 'border-gray-400'} rounded-lg flex flex-col items-center justify-center cursor-pointer relative`}
          onDragOver={handleDragOver}
          onDragLeave={handleDragLeave}
          onDrop={handleDrop}
        >
          <input
            type="file"
            multiple
            accept=".jpg,.jpeg,.png"
            className="absolute inset-0 opacity-0 cursor-pointer"
            onChange={handleFileSelect}
          />
          <FontAwesomeIcon icon={faCamera} className="text-4xl text-gray-600" />
          <p className="text-gray-600 ml-2 mb-8">Drag and drop files here or click to select files</p>
          <button className="flex gap-3 items-center justify-center border-laara-primary rounded-[36px] border m-4 mb-1 p-3 text-laara-primary">
          <FontAwesomeIcon icon={faCamera} className="text-base" />
          Upload Photos
        </button>
        <p className="text-sm text-gray-500">JPG/JPEG or PNG, maximum 40MB each</p>
        </div>
        </div>
        
        <Consider
          title={"Here are some tips on taking great photos of your property"}
          data={[
            "Only use photos others have taken if you have permission",
            "If you don't know who took a photo, it's best not to use it",
            "Make sure your photos are well-lit and clear",
            "Show different angles and aspects of your property"
          ]}
          className="mb-4"
        />
        </section>
        
        <div className="w-full flex justify-end">
          <button
            type="submit"
            className="py-2 px-4 bg-blue-500 text-white rounded-[36px] w-[216px] h-[55px]"
          >
            Save Changes
          </button>
        </div>
    </div>
  );
};

export default UploadPhotos;
