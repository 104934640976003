import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { IoCloseSharp } from "react-icons/io5";
import { sendPasswordResetEmail } from "firebase/auth";
import { auth } from "../../firebase/firebase"; // Ensure this path is correct based on your project structure
import Logo from "../../assets/forgortPassword/Main logo.svg";
import ImageLeft from "../../assets/forgortPassword/2f6e83cbd1 1.svg";

const ForgotPassword = () => {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  const [formData, setFormData] = useState({ email: '' });
  const [errors, setErrors] = useState({});
  const [successMessage, setSuccessMessage] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const { email } = formData;

    // Simple validation
    if (!email) {
      setErrors({ email: 'Email is required' });
      return;
    }

    try {
      await sendPasswordResetEmail(auth, email);
      setSuccessMessage('Password reset link sent to your email.');
      setErrors({});
    } catch (error) {
      setErrors({ submit: error.message });
    }
  };

  return (
    <section className="min-h-screen flex flex-col lg:flex-row lg:p-6 ">
      {/* Left column for the image */}
      <div className="relative lg:w-[40%] flex flex-col items-center justify-center lg:pl-4  lg:p-0 lg:items-start">
        <button
          className="absolute top-5 left-10 lg:hidden text-[hsla(0,0%,16%,1)] transition-all duration-300 ease-in-out hover:scale-150"
          onClick={() => navigate(-1)}
        >
          <IoCloseSharp size={20} />
        </button>
        <div className="lg:bg-white bg-[hsla(185,100%,98%,1)]  py-4 w-full h-[4.125rem] mx-auto  lg:w-[15.36875rem] lg:h-[5rem] mb-6 mx-auto lg:mb-12">
          <img src={Logo} alt="" className=" mx-auto w-[5rem] h-[1.625rem] lg:w-[15.36875rem] lg:h-[5rem]" />
        </div>
        <img
          src={ImageLeft}
          alt="Image banner Left"
          className="mx-auto lg:pl-6 w-[133px] lg:mt-6 h-[128] lg:w-[429px] lg:h-[415px] lg:mb-0"
        />
      </div>

      {/* Right column for the form */}
      <div className="flex-1 flex items-center justify-start p-2 lg:p-4 lg:pl-4  bg-white ">
        <div className="w-full max-w-md h-[214px] lg:h-[349px] lg:max-w-[615px] lg:p-12  relative">
          <h3 className=" text-lg lg:text-2xl font-medium text-center font-poppins leading-[1.875rem] text-black mb-2 lg:mb-2">
            Forgot Password
          </h3>
          <p className="lg:text-xs text-[10px] font-poppins text-center leading-[18px] text-blue-700 mb-3 lg:mb-3">
            Enter the email address associated with your account
          </p>
          <form onSubmit={handleSubmit} className="space-y-5">
            <label className="block relative">
              <input
                type="email"
                name="email"
                className={`w-full h-[36px] lg:h-[47px] border border-black rounded-md px-4 py-2 text-sm focus:outline-none focus:border-blue-400 ${
                  errors.email ? "border-red-500" : ""
                }`}
                placeholder="Email"
                value={formData.email}
                onChange={handleChange}
                required
              />
              {errors.email && (
                <p className="text-red-500 mt-1 text-xs">{errors.email}</p>
              )}
            </label>
            <button
              type="submit"
              className="w-full h-[46px] lg:h-[58px] bg-blue-700 hover:bg-blue-800 text-white rounded-[36px] text-lg flex items-center justify-center transition duration-150 ease-in-out"
            >
              Next
            </button>
          </form>
          {successMessage && (
            <p className="text-green-500 mt-4 text-sm">{successMessage}</p>
          )}
          {errors.submit && (
            <p className="text-red-500 mt-4 text-sm">{errors.submit}</p>
          )}
          <button
            className="absolute hidden lg:block top-[-60px] right-4 text-[hsla(0,0%,64%,1)] transition-all duration-300 ease-in-out hover:scale-150"
            onClick={() => navigate(-1)}
          >
            <IoCloseSharp size={24} />
          </button>
        </div>
      </div>
    </section>
  );
};

export default ForgotPassword;
