import { useState } from 'react';
import PropTypes from 'prop-types';

const ReviewCard = ({ review }) => {
    const [showToast, setShowToast] = useState(false);
    const [showTooltip, setShowTooltip] = useState(false); // State for tooltip hover

    const copyToClipboard = () => {
        navigator.clipboard.writeText(review.message).then(() => {
            // Show the toast notification
            setShowToast(true);

            // Automatically hide the toast after 3 seconds
            setTimeout(() => {
                setShowToast(false);
            }, 3000);
        });
    };

    const renderStars = (rating) => {
        const stars = [];
        for (let i = 0; i < 5; i++) {
            stars.push(
                <svg
                    key={i}
                    className={`w-4 h-4 ${i < rating ? 'text-yellow-400' : 'text-gray-300'}`}
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
                </svg>
            );
        }
        return stars;
    };

    return (
        <div className="border border-gray-300 rounded-lg p-4 mb-4 bg-white flex flex-col max-w-[430px] w-full max-h-[350px] h-full relative">
            {/* Toast Notification */}
            {showToast && (
                <div className="absolute top-0 right-0 bg-gray-700 text-white text-sm px-4 py-2 rounded-lg shadow-lg z-10">
                    Copied!
                </div>
            )}

            <div className="flex">
                <div className="h-full">
                    <img
                        src="default-profile.png" 
                        alt={`${review.user.firstName} ${review.user.lastName}`}
                        className="w-12 h-12 rounded-full mr-4"
                    />
                </div>
                <div className="w-full">
                    <div className="flex items-center w-full">
                        <div className="flex-1">
                            <div className="flex items-center justify-between mb-1">
                                <div>
                                    <h3 className="text-lg font-semibold text-laara-primary">{review.user.firstName} {review.user.lastName || ""}</h3>
                                    <div className="flex items-center">
                                        {renderStars(review.rating.rating)}
                                        <span className="ml-2 text-sm font-medium text-gray-700">{review.rating.rating.toFixed(1)}</span>
                                    </div>
                                </div>
                                
                                <div className='flex'>
                                    <div className="text-sm text-gray-500 text-right">
                                        {/* Format time to '11:30' */}
                                        <div>{new Date(review.createdAt).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: false })}</div>
                                        
                                        {/* Format date to 'June 3, 2030' */}
                                        <div>{new Date(review.createdAt).toLocaleDateString('en-US', { 
                                        month: 'long', 
                                        day: 'numeric', 
                                        year: 'numeric' 
                                        })}</div>
                                    </div>

                                    <div className="relative">
                                        <button
                                            onClick={copyToClipboard}
                                            onMouseEnter={() => setShowTooltip(true)}
                                            onMouseLeave={() => setShowTooltip(false)}
                                            className="text-gray-500 hover:bg-gray-200 p-1 rounded-full transition-colors duration-300 ml-1"
                                        >
                                            <svg
                                                className="w-5 h-5"
                                                fill="currentColor"
                                                viewBox="0 0 24 24"
                                                xmlns="http://www.w3.org/2000/svg"
                                            >
                                                <path d="M12 7a1.5 1.5 0 110-3 1.5 1.5 0 010 3zm0 7a1.5 1.5 0 110-3 1.5 1.5 0 010 3zm0 7a1.5 1.5 0 110-3 1.5 1.5 0 010 3z" />
                                            </svg>
                                        </button>

                                        {/* Tooltip for Copy Button */}
                                        {showTooltip && (
                                            <div className="absolute bottom-[-20px] right-0 bg-gray-600 text-white text-xs px-2 py-1 rounded-lg shadow-lg">
                                                Copy
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <p className="mt-2 text-gray-700">{review.message}</p>                    
                </div>
            </div>
        </div>
    );
};

// Define PropTypes for ReviewCard
ReviewCard.propTypes = {
    review: PropTypes.shape({
        message: PropTypes.string.isRequired,
        rating: PropTypes.shape({
            rating: PropTypes.number.isRequired,
        }).isRequired,
        createdAt: PropTypes.string.isRequired,
        user: PropTypes.shape({
            firstName: PropTypes.string.isRequired,
            lastName: PropTypes.string,
        }).isRequired,
    }).isRequired,
};

const ReviewsSection = ({ properties }) => {
    // Collect all reviews from properties, ensure it's always an array
    const allReviews = properties.flatMap(property => Array.isArray(property.reviews) ? property.reviews : []);
    
    // Display the latest reviews, up to four
    const latestReviews = allReviews.slice(0, 4);
  
    return (
        <div className=" p-4 max-w-[430px] w-full max-h-[350px] h-full">
            {/* Review Cards */}
            <div>
                {latestReviews.length > 0 ? (
                    latestReviews.map((review, index) => (
                        <ReviewCard key={review.createdAt + index} review={review} />
                    ))
                ) : (
                    <p className="text-gray-700">No reviews available.</p>
                )}
            </div>
        </div>
    );
};

// Define PropTypes for ReviewsSection
ReviewsSection.propTypes = {
    properties: PropTypes.arrayOf(
        PropTypes.shape({
            reviews: PropTypes.arrayOf(
                PropTypes.shape({
                    message: PropTypes.string.isRequired,
                    rating: PropTypes.shape({
                        rating: PropTypes.number.isRequired,
                    }).isRequired,
                    createdAt: PropTypes.string.isRequired,
                    user: PropTypes.shape({
                        firstName: PropTypes.string.isRequired,
                        lastName: PropTypes.string,
                    }).isRequired,
                }).isRequired
            ).isRequired,
        }).isRequired
    ).isRequired,
};

export default ReviewsSection;
