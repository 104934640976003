import { useState } from 'react';

const RoomTable = () => {
  const [tableData, setTableData] = useState([
    { id: 1, roomType: 'Standard Single Room', guests: 1, basePrice: 120, nights: 7, serviceFee: 30, total: 870 },
    { id: 2, roomType: 'Standard Single Room', guests: 2, basePrice: 120, nights: 7, serviceFee: 30, total: 870 },
  ]);

  const handleRoomSelection = (id) => {
    setTableData(
      tableData.map((room) =>
        room.id === id ? { ...room, selectRoom: !room.selectRoom } : room
      )
    );
  };

  return (
    <div className="p-4">
      <table className="min-w-full border-collapse border border-gray-300">
        <thead className="bg-blue-100">
          <tr>
            <th className="border border-gray-300 p-2 text-left">Room Type</th>
            <th className="border border-gray-300 p-2 text-left">Number of Guests</th>
            <th className="border border-gray-300 p-2 text-left">Prices</th>
            <th className="border border-gray-300 p-2 text-left">Availability</th>
            <th className="border border-gray-300 p-2 text-left">Select Rooms</th>
          </tr>
        </thead>
        <tbody>
          {tableData.map((room) => (
            <tr key={room.id}>
              <td className="border border-gray-300 p-2">{room.roomType}</td>
              <td className="border border-gray-300 p-2">{room.guests}</td>
              <td className="border border-gray-300 p-2">
                <div>
                  <div className="flex justify-between">
                    <div>${room.basePrice} * {room.nights} Nights</div>
                    <div>${room.basePrice * room.nights}</div>
                  </div>
                  <div className="flex justify-between">
                    <div>Service Fee</div>
                    <div>${room.serviceFee}</div>
                  </div>
                  <div className="flex justify-between font-bold">
                    <div>Total</div>
                    <div>${room.total}</div>
                  </div>
                </div>
              </td>
              <td className="border border-gray-300 p-2">
                <span className={Math.random() > 0.5 ? 'text-blue-500' : 'text-red-500'}>
                  {Math.random() > 0.5 ? 'Available' : 'Not Available'}
                </span>
              </td>
              <td className="border border-gray-300 p-2">
                <button
                  onClick={() => handleRoomSelection(room.id)}
                  className={`px-4 py-2 rounded ${
                    room.selectRoom ? 'bg-green-500 text-white' : 'bg-gray-300'
                  }`}
                >
                  {room.selectRoom ? 'Reserved' : 'Select'}
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default RoomTable;
