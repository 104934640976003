import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { setRoomAmenities } from "@/redux/Listing/listingSlice";
import { Checkbox } from "@/components/ui/checkbox";

const amenities = {
  General: [
    "Clothes rack",
    "Flat screen TV",
    "Air conditioning",
    "Linen",
    "Workspace desk",
    "Wake-up service",
    "Towels",
    "Wardrobe or closet",
    "Heating",
    "Fan",
    "Safety deposit box",
    "Entire unit located on ground floor",
  ],
  "Outdoor and Views": ["Balcony", "Ocean view", "Nature view", "Terrace"],
  "Food and Beverage": [
    "Electric kettle",
    "Tea/coffee maker",
    "Dining area",
    "Dining table",
    "Microwave",
    "Refrigerator",
  ],
};

export default function RoomAmmenities() {
  const dispatch = useDispatch();
  const selectedAmenities = useSelector(
    (state) => state.hostListing.formData?.roomType.amenities || []
  );

  const handleToggleAmenity = (isChecked, category, amenity) => {
    // Toggle the selection of the amenity
    const updatedAmenities = selectedAmenities?.some(
      (a) => a.name === amenity && a.category === category
    )
      ? selectedAmenities.filter(
          (a) => !(a.name === amenity && a.category === category)
        )
      : [
          ...selectedAmenities,
          {
            name: amenity,
            description: `${amenity} available`, // Customize description
            category,
          },
        ];

    dispatch(setRoomAmenities(updatedAmenities));
  };

  const renderCheckboxes = (category, items) => (
    <div key={category} className="space-y-2">
      <p className="text-lg font-medium capitalize">{category}</p>
      <div className="flex flex-col gap-2">
        {items.map((item) => (
          <div key={item} className="flex items-center gap-3">
            <Checkbox
              id={`${category}-${item}`}
              name={item}
              className="size-6 rounded-lg"
              checked={selectedAmenities?.some(
                (a) => a.name === item && a.category === category
              )}
              onCheckedChange={(isChecked) =>
                handleToggleAmenity(isChecked, category, item)
              }
            />
            <label
              htmlFor={`${category}-${item}`}
              className={`capitalize text-lg ${
                selectedAmenities?.some(
                  (a) => a.name === item && a.category === category
                ) && "text-laara-primary"
              }`}
            >
              {item}
            </label>
          </div>
        ))}
      </div>
    </div>
  );

  return (
    <form className="flex flex-col max-w-[733px]">
      <h4 className="font-medium text-xl">
        What can your guests use in this room?
      </h4>
      <div className="border border-[#A3A3A3] rounded-[6px] mt-5 p-4 md:p-8 w-full ">
        <div className="flex items-start gap-4 mt-5">
          <div className="space-y-4">
            {Object.entries(amenities).map(([category, items]) =>
              renderCheckboxes(category, items)
            )}
          </div>
        </div>
      </div>
    </form>
  );
}
