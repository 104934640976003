import React from 'react';
import { X } from 'lucide-react';

function DeleteTaskModal({ isOpen, onClose, onConfirm }) {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-gray-600 bg-opacity-50 flex justify-center items-center">
      <div className="bg-white rounded-lg shadow-md p-6 w-[516px] h-[468px] flex flex-col justify-center items-center">
        <div className="w-[108px] h-[108px] border-4 border-[#C03744] rounded-full flex items-center justify-center mb-4">
          <X className="w-16 h-16 text-[#C03744]" />
        </div>
        <h2 className="text-xl font-semibold mb-2">Confirm Deletion</h2>
        <p className="text-gray-600 text-center mb-6">
          By clicking the "Delete" button below, you will permanently delete this task.
        </p>
        <div className="flex w-full space-x-4">
          <button className="flex-1 py-2 px-4 border border-gray-300 rounded-full text-gray-700" onClick={onClose}>
            Cancel
          </button>
          <button className="flex-1 py-2 px-4 bg-[#C03744] text-white rounded-full" onClick={onConfirm}>
            Delete
          </button>
        </div>
      </div>
    </div>
  );
}

export default DeleteTaskModal;
