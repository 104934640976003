import * as React from "react";
import { useState } from "react";

import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { IoChevronDownOutline, IoChevronUp } from "react-icons/io5";

const RoomTypes = [
  { type: "Full Board - Standard Single Room", guests: "2 Adults", image: "https://cdn.builder.io/api/v1/image/assets/TEMP/495b921a72b44473a83e3f12e0f266e4ca2bee5114306204b4bd278651cb672f?apiKey=39797f691da94f489f399c076bea1d31&" },
  { type: "Half Board - Standard Single Room", guests: "2 Adults", image: "https://cdn.builder.io/api/v1/image/assets/TEMP/495b921a72b44473a83e3f12e0f266e4ca2bee5114306204b4bd278651cb672f?apiKey=39797f691da94f489f399c076bea1d31&" },
  { type: "Half Board - Standard Single Room", guests: "2 Adults", image: "https://cdn.builder.io/api/v1/image/assets/TEMP/495b921a72b44473a83e3f12e0f266e4ca2bee5114306204b4bd278651cb672f?apiKey=39797f691da94f489f399c076bea1d31&" },
  { type: "Half Board - Standard Single Room", guests: "2 Adults", image: "https://cdn.builder.io/api/v1/image/assets/TEMP/495b921a72b44473a83e3f12e0f266e4ca2bee5114306204b4bd278651cb672f?apiKey=39797f691da94f489f399c076bea1d31&" },
  { type: "Half Board - Standard Single Room", guests: "2 Adults", image: "https://cdn.builder.io/api/v1/image/assets/TEMP/495b921a72b44473a83e3f12e0f266e4ca2bee5114306204b4bd278651cb672f?apiKey=39797f691da94f489f399c076bea1d31&" },
  { type: "Half Board - Standard Single Room", guests: "2 Adults", image: "https://cdn.builder.io/api/v1/image/assets/TEMP/495b921a72b44473a83e3f12e0f266e4ca2bee5114306204b4bd278651cb672f?apiKey=39797f691da94f489f399c076bea1d31&" },
  // Add more rooms here
]

function Availability() {

  const [startDate, setStartDate] = useState(null); // Check-in date
  const [endDate, setEndDate] = useState(null); 
  const [ isOpen, setIsOpen] =useState(false)
  
  return (
    <div className="flex flex-col p-4 py-2 border-t border-zinc-600 border-opacity-20">
      <div className="text-lg font-medium tracking-normal leading-loose text-neutral-700 mb-4">
        Availability
      </div>
      <div className="flex flex-col p-2 w-full max-md:max-w-full">
        <div className="flex flex-wrap gap-4 items-start py-2 w-full tracking-normal p-2 rounded-xl max-md:max-w-full">
          {/* Check-In Date */}
          <div className="flex flex-col flex-1 min-w-[240px]">
            <div className="text-base leading-7 text-neutral-600 mb-2">
              Select Check-In Date
            </div>
            <div className="flex flex-col justify-center px-6 py-4 border border-blue-100 rounded-[36px] text-stone-300">
              <div className="flex gap-2 items-center">
                <img
                  loading="lazy"
                  src="https://cdn.builder.io/api/v1/image/assets/TEMP/d5f2b560aa719fd096cfc00869d24976bc87b28f17c05cba5ca8e079f3bbbc16?apiKey=39797f691da94f489f399c076bea1d31&"
                  className="w-6 aspect-square"
                />
              <DatePicker
                selected={startDate}
                onChange={(date) => setStartDate(date)}
                placeholderText="e.g. Mon 05/24"
                className="bg-transparent text-stone-500 focus:outline-none"
                dateFormat="EEE MM/dd"
                selectsStart
                startDate={startDate}
                endDate={endDate}
              />
              </div>
            </div>
          </div>
          {/* Check-Out Date */}
          <div className="flex flex-col flex-1 min-w-[240px]">
            <div className="text-base leading-7 text-neutral-600 mb-2">
              Select Check-Out Date
            </div>
            <div className="flex flex-col justify-center px-6 py-4 border border-blue-100 rounded-[36px] text-stone-300">
              <div className="flex gap-2 items-center">
                <img
                  loading="lazy"
                  src="https://cdn.builder.io/api/v1/image/assets/TEMP/dc2c0c8ebf693cf053d2d1ddc2e5eccb01846f2400bf2ed8cd21a260606f71bc?apiKey=39797f691da94f489f399c076bea1d31&"
                  className="w-6 aspect-square"
                />
              <DatePicker
                selected={endDate}
                onChange={(date) => setEndDate(date)}
                placeholderText="e.g. Fri 09/24"
                className="bg-transparent text-stone-500 font-poppins focus:outline-none"
                dateFormat="EEE MM/dd"
                selectsEnd
                startDate={startDate}
                endDate={endDate}
                minDate={startDate} // Ensure check-out is after check-in
              />
              </div>
            </div>
          </div>
          {/* Number of Guests */}
          <div className="flex flex-col justify-center min-w-[240px] w-[420px]">
            <div className="text-base leading-loose text-neutral-600 mb-2">
              Select Number of Guest
            </div>
            <div className="flex flex-col px-6 py-4 border border-blue-100 rounded-[36px] text-stone-500">
              <div className="flex gap-2 items-center">
                <img
                  loading="lazy"
                  src="https://cdn.builder.io/api/v1/image/assets/TEMP/a488e1b2565f90428a7158c209b49178f83a7e5a7297d84661a7ab4de6c592f1?apiKey=39797f691da94f489f399c076bea1d31&"
                  className="w-6 aspect-square"
                />
                <div>2 Adults</div>
                <img
                  loading="lazy"
                  src="https://cdn.builder.io/api/v1/image/assets/TEMP/a488e1b2565f90428a7158c209b49178f83a7e5a7297d84661a7ab4de6c592f1?apiKey=39797f691da94f489f399c076bea1d31&"
                  className="w-6 aspect-square"
                />
                <div>0 Children</div>
                <div>1 Room</div>
                <div>{isOpen ?<IoChevronUp size={27}/> : <IoChevronDownOutline size={27}/> }</div>                
              </div>           
            </div>
          </div>
          {/* Apply Changes Button */}
          <button className="flex items-center px-3 py-4 mt-9 my-auto text-white bg-sky-600 rounded-[36px] duration-300 hover:bg-blue-600 text-center w-[150px] cursor-pointer">
            Apply Changes
          </button>
        </div>
        {/* Room Availability Table */}
        <div className="flex flex-col mt-4">
          {/* Header */}
          <div className="flex flex-wrap justify-between p-2 text-base tracking-normal leading-loose border-b bg-sky-600 bg-opacity-20 border-zinc-600 border-opacity-20 text-zinc-800">
            <div className="flex-1 px-5 py-2 text-center border-r border-zinc-600 border-opacity-20 min-w-[240px]">
              Room Type
            </div>
            <div className="flex-1 px-5 py-2 text-center border-r border-zinc-600 border-opacity-20 min-w-[240px]">
              Number of guest
            </div>
            <div className="flex-1 px-5 py-2 text-center whitespace-nowrap min-w-[240px]">
              Prices
            </div>
          </div>
          {/* Room Options */}
          {RoomTypes.map((room, index) => (
            <div key={index} className="flex flex-wrap justify-between mt-4 border-t border-b border-zinc-600 border-opacity-20">
              <div className="flex flex-col flex-1 px-2 py-2 border-r border-zinc-600 border-opacity-20 min-w-[240px] text-neutral-600">
                <div className="text-base">{room.type}</div>
                <div className="flex items-start mt-2">
                  <img src={room.image} className="w-6 aspect-square" alt="Room" />
                  <div className="ml-2">Single bed</div>
                </div>
                <div className="text-sm text-sky-600 mt-2">View more</div>
              </div>
              <div className="flex flex-1 px-5 py-2 border-r border-zinc-600 border-opacity-20 min-w-[240px]">
                <div className="flex items-center mx-auto">
                  <img src="https://cdn.builder.io/api/v1/image/assets/TEMP/a488e1b2565f90428a7158c209b49178f83a7e5a7297d84661a7ab4de6c592f1?apiKey=39797f691da94f489f399c076bea1d31&" className="w-6 aspect-square" alt="Guest" />
                  <img src="https://cdn.builder.io/api/v1/image/assets/TEMP/a488e1b2565f90428a7158c209b49178f83a7e5a7297d84661a7ab4de6c592f1?apiKey=39797f691da94f489f399c076bea1d31&" className="w-6 aspect-square" alt="Guest" />
                </div>
              </div>
              <div className="flex flex-col flex-1 items-center p-4 mt-2 text-sky-600 text-base">
                <div className="px-5 py-2 border border-sky-600 rounded-[36px] w-[300px] text-center cursor-pointer duration-300 hover:text-white hover:bg-blue-500">
                  Show prices
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default Availability;
