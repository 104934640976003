import React, { useState } from 'react';
import { Bar } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';
import Money from "@/assets/AdminDash/money.svg";
import SolarHand from "@/assets/AdminDash/solar_hand-money-linear.svg";
import Placeholder1 from "@/assets/AdminDash/placeholder.svg";

function StatsCard({ title, amount, iconType }) {
  const iconClasses = `w-10 h-10 rounded-full flex-shrink-0 mr-2 flex items-center justify-center ${iconType === 'money' ? 'bg-[#E6F7FF]' : 'bg-[#FFF3D7]'
    }`;

  return (
    <div className="bg-white p-4 rounded border border-gray-200 w-full sm:w-[321px] h-[210px] shadow">
      <div className="flex items-center mb-2 w-full sm:w-[281px] sm:ml-6 mt-4">
        <div className={iconClasses}>
          <img
            src={iconType === 'money' ? Money : SolarHand}
            alt={iconType === 'money' ? 'Money icon' : 'Hand icon'}
            className="w-7 h-7"
          />
        </div>
        <h3 className="text-base font-medium text-black">{title}</h3>
      </div>
      <p className="text-2xl font-semibold w-full sm:w-[281px] sm:ml-6">{amount}</p>
    </div>
  );
}

function PayoutsCard() {
  const payouts = [
    { id: 1, type: 'Subscription renewal alert', time: '10:00-11:30' },
    { id: 2, type: 'Payments due', time: '10:00-11:30' },
    { id: 3, type: 'Subscription renewal alert', time: '10:00-11:30' },
    { id: 4, type: 'Bill payment', time: '10:00-11:30' },
    { id: 5, type: 'Subscription renewal alert', time: '10:00-11:30' },
    { id: 6, type: 'Subscription renewal alert', time: '10:00-11:30' },
  ];

  return (
    <div className="bg-white p-3 rounded-lg shadow border border-gray-200 h-[530px] overflow-auto">
      <h3 className="text-xl font-medium mb-4">Payouts this month</h3>
      <table className="w-full text-left">
        <tbody>
          {payouts.map((payout) => (
            <tr key={payout.id} className="border-b hover:bg-[#0466C8] transition-all group">
              <td className="py-4 text-sm">
                <span className="inline-block w-8 h-8 rounded-full bg-gray-200 text-center leading-8 group-hover:bg-white group-hover:text-[#0466C8]">
                  {payout.id}
                </span>
              </td>
              <td className="py-4 text-base text-[#3C3C3C] font-medium group-hover:text-white">
                {payout.type}
                <p className="text-sm font-normal group-hover:text-white">3 of 4 payments, Recharge</p>
              </td>
              <td className="py-4 font-medium text-sm group-hover:text-white">{payout.time}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

function AnnualSubscriptionsChart() {
  const data = {
    labels: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
    datasets: [
      {
        label: 'Subscriptions',
        data: [5, 8, 12, 8, 10, 5, 10, 10, 8, 12, 7, 10],
        backgroundColor: '#0466C8',
        barThickness: 16,
      },
      {
        label: 'Cancellations',
        data: [2, 3, 4, 3, 5, 2, 6, 4, 3, 5, 3, 4],
        backgroundColor: '#D1D5DB',
        barThickness: 16,
      },
    ],
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      x: {
        stacked: true,
        grid: {
          display: false,
        },
      },
      y: {
        stacked: true,
        reverse: true,
        grid: {
          color: '#E5E7EB',
          drawBorder: false,
          drawTicks: false,
        },
        ticks: {
          padding: 10,
        },
      },
    },
    plugins: {
      legend: {
        position: 'top',
        align: 'start',
      },
    },
  };

  return (
    <div className="w-full h-[300px] bg-white border rounded-lg p-4">
      <h2 className="text-lg font-medium text-[#4F4F4F] text-center mb-1">Annual Subscriptions</h2>
      <div className="h-[250px]">
        <Bar data={data} options={options} />
      </div>
    </div>
  );
}

const transactions = [
  { id: 1, name: 'Willy Omondi', paymentMethod: 'Mobile Money', propertyType: 'Hotel', date: '08/06/2024', amount: 'KES. 50,000', status: 'Confirmed' },
  { id: 2, name: 'Willy Omondi', paymentMethod: 'Mobile Money', propertyType: 'Villa', date: '08/06/2024', amount: 'KES. 50,000', status: 'Cancelled' },
  { id: 3, name: 'Willy Omondi', paymentMethod: 'Mobile Money', propertyType: 'Resort', date: '08/06/2024', amount: 'KES. 50,000', status: 'Confirmed' },
  { id: 4, name: 'Willy Omondi', paymentMethod: 'Mobile Money', propertyType: 'Campsite', date: '08/06/2024', amount: 'KES. 50,000', status: 'Pending' },
  { id: 5, name: 'Willy Omondi', paymentMethod: 'Mobile Money', propertyType: 'Guest Suite', date: '08/06/2024', amount: 'KES. 50,000', status: 'Confirmed' },
  { id: 6, name: 'Willy Omondi', paymentMethod: 'Credit Card', propertyType: 'Apartment', date: '08/07/2024', amount: 'KES. 75,000', status: 'Confirmed' },
  { id: 7, name: 'Willy Omondi', paymentMethod: 'Bank Transfer', propertyType: 'Cottage', date: '08/07/2024', amount: 'KES. 120,000', status: 'Pending' },
  { id: 8, name: 'Willy Omondi', paymentMethod: 'Credit Card', propertyType: 'Hostel', date: '08/07/2024', amount: 'KES. 30,000', status: 'Cancelled' },
  { id: 9, name: 'Willy Omondi', paymentMethod: 'Mobile Money', propertyType: 'Bed & Breakfast', date: '08/08/2024', amount: 'KES. 40,000', status: 'Confirmed' },
  { id: 10, name: 'Willy Omondi', paymentMethod: 'Bank Transfer', propertyType: 'Lodge', date: '08/08/2024', amount: 'KES. 85,000', status: 'Pending' },
];


function TransactionsTable({ data }) {
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 4;

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = data.slice(indexOfFirstItem, indexOfLastItem);

  const pageNumbers = [];
  for (let i = 1; i <= Math.ceil(data.length / itemsPerPage); i++) {
    pageNumbers.push(i);
  }

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  return (
    <div className="overflow-x-auto">
      <div className="flex flex-col sm:flex-row items-center gap-4 p-4">
        <h2 className="text-xl font-semibold">Transactions</h2>
        <span className="text-[#0466C8] mt-2 sm:mt-0 bg-[#B4D9FE] px-3 py-1 rounded font-semibold text-xs bg-opacity-20">
          {data.length} Transactions
        </span>
      </div>
      <p className="p-4 text-[#626262] font-normal text-base">
        These are all the transactions
      </p>

      <table className="w-full min-w-[640px] divide-y divide-gray-200">
        <thead className="bg-[#F5F7F9] border-t ">
          <tr className='text-sm font-semibold text-[#4F4F4F]'>
            <th className="p-3 text-left w-8"></th>
            <th className="p-3 text-left ">Name</th>
            <th className="p-3 text-left ">Payment Method</th>
            <th className="p-3 text-left ">Property Type</th>
            <th className="p-3 text-left ">Date Transacted</th>
            <th className="p-3 text-left ">Amount</th>
            <th className="p-3 text-left ">Status</th>
          </tr>
        </thead>
        <tbody className="bg-white divide-y divide-gray-200">
          {currentItems.map((transaction) => (
            <tr key={transaction.id} className='font-medium text-sm text-[#434343]'>
              <td className="p-3"></td>
              <td className="p-3 flex items-center">
                <input type="checkbox" className="rounded w-4 h-4 mr-3" />
                <img className="w-8 h-8 rounded-full mr-2" src={Placeholder1} alt="User" />
                <span>{transaction.name}</span>
              </td>
              <td className="p-3 ">{transaction.paymentMethod}</td>
              <td className="p-3 ">{transaction.propertyType}</td>
              <td className="p-3 ">{transaction.date}</td>
              <td className="p-3 ">{transaction.amount}</td>
              <td className="p-3 ">
                <span className={` w-full max-w-[97px] px-3 py-1 inline-flex text-sm leading-5 font-medium rounded ${transaction.status === 'Confirmed' ? 'border border-[#108975] text-[#108975]' : transaction.status === 'Cancelled' ? 'border border-[#C03744] text-[#C03744]' : 'border border-[#CC7914] text-[#CC7914]'}`}>
                  {transaction.status}
                </span>
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      <div className="flex justify-end p-4 ">
        <div className="border rounded-lg font-semibold px-2 py-1.5">
          <button
            className={`px-3 py-1 rounded mr-2 ${currentPage === 1 ? 'text-gray-500' : 'text-black'}`}
            onClick={() => handlePageChange(currentPage - 1)}
            disabled={currentPage === 1}
          >
            &lt;
          </button>
          {pageNumbers.map((number) => (
            <button
              key={number}
              className={`px-3 py-1 mr-2 ${currentPage === number ? 'bg-[#108975] text-white' : 'text-black'} rounded`}
              onClick={() => handlePageChange(number)}
            >
              {number}
            </button>
          ))}
          <button
            className="px-3 py-1 mr-2 text-black rounded"
            onClick={() => handlePageChange(currentPage + 1)}
            disabled={currentPage === pageNumbers.length}
          >
            &gt;
          </button>
        </div>
      </div>
    </div>
  );
}

function FinancialManagement() {
  return (
    <div className="container mx-auto px-4 min-h-screen py-4">
      <div className="flex flex-col lg:flex-row gap-4">
        <div className="w-full lg:w-7/12 flex flex-col gap-4">
          <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 ">
            <StatsCard title="Upcoming Commisions" amount="KES. 15,000" iconType="money" />
            <StatsCard title="Commisions" amount="KES. 100,000" iconType="hand" />
          </div>
          <div className="w-full">
            <AnnualSubscriptionsChart />
          </div>
        </div>
        <div className="w-full lg:w-5/12 flex flex-col gap-4">
          <PayoutsCard />
        </div>
      </div>
      <div className="mt-10 overflow-x-auto">
        <TransactionsTable data={transactions} />
      </div>
    </div>
  );
}

export default FinancialManagement;
