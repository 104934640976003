import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import arrow from "@/assets/searchresults/chev-right.png";
import star from "@/assets/shared/star.png";
import { headers, baseURL } from "@/constants/apiconfig";
import axios from "axios";
import { useSelector } from "react-redux";

import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "@/components/ui/tooltip";

const PropertyCard = ({ property, setPin, setOpenMap }) => {
  const [followedProperties, setFollowedProperties] = useState([]);
  const token = useSelector((state) => state.user.accessToken);

  const followProperty = async (id) => {
    const name = property.name;
    const propertyData = { id, name };
    try {
      const res = await axios.post(`${baseURL}/users/follow`, propertyData, {
        headers: headers(token),
      });

      if (res.data.status === "success") {
        setFollowedProperties((prev) => [...prev, id]);
      }

      return res.data;
    } catch (error) {
      throw new Error(error);
    }
  };

  return (
    <article
      onClick={() => {
        // setPin(property); // need coordinates from backend
      }}
      className="w-full flex flex-col gap-5 xl:flex-row xl:maxs-w-[792px] xl:w-full xl:h-[307px] xl:gap-6 md:maxs-w-[590px] md:gap-6 mx-auto"
    >
      <div className="w-full md:max-w-[590px] xl:max-w-[352px] ">
        <img
          src={property.propertyImages[0]?.images?.url} //property.propertyImages[0].images.url
          alt="hotel image"
          className="w-full h-[289px] md:w-[590px] md:h-[307px] xl:w-[352px] xl:h-[307px] rounded-[10px] object-cover"
        />
      </div>

      <div className="flex flex-col justify-between w-full xl:max-w-[436px] xl:h-[307px]">
        <div className="flex w-full justify-between items-end xl:items-start  ">
          {/* LEFT SIDE */}
          <div className="flex flex-col gap-4 ">
            <div className="flex flex-col gap-2">
              {/* HOTEL NAME */}
              <h2 className="capitalize text-[#3C3C3C] text-lg xl:text-black xl:text-xl font-semibold ">
                {property.name}
              </h2>

              <TooltipProvider>
                <Tooltip>
                  <TooltipTrigger asChild>
                    <p
                      onClick={() => {
                        // setOpenMap(true), setPin(property); // property.address.coordinates
                      }}
                      className="text-sm font-medium text-laara-primary
                      underline capitalize"
                    >
                      {property?.address?.city}, {property?.address?.country}
                    </p>
                  </TooltipTrigger>
                  <TooltipContent
                    onClick={() => {
                      // setOpenMap(true), setPin(property);
                    }}
                  >
                    <p className="cursor-pointer">Show on map</p>
                  </TooltipContent>
                </Tooltip>
              </TooltipProvider>
            </div>

            {/* property.rooms.roomTypes.name */}
            {/* property.rooms.roomTypes.pricings[0].price */}
            {/* ROOM TYPE */}
            <div className="text-[#3C3C3C] xl:text-black capitalize">
              <h4 className="text-sm font-medium ">
                {property?.rooms[0]?.roomTypes?.name}
              </h4>
              <p className="text-[14px] leading-[21px]  ">
                {/* size of beds */}
                {/* {property.beds.length} {property.beds[0]} */}
              </p>
            </div>

            {/* GREEN TEXT */}
            <div className="text-[#108975] text-sm xl:text-base xl:font-medium ">
              {/* property.mealOptions */}
              {property?.mealOptions[0]?.name}
              {/* <p>All meals included</p>
              <p>Free cancellation</p>
              <p>No repayments needed</p> */}
            </div>
          </div>

          {/* RIGHT SIDE */}
          <div className="flex justify-end">
            {/* ratings & Reviews */}
            <div>
              <div className="flex flex-col gap-6 ">
                {/* Rating */}
                <div className="">
                  <div className="flex items-center justify-end xl:justify-end ">
                    {/* stars */}
                    <div className="flex items-center gap-[3px]">
                      <img
                        src={star}
                        alt="rating"
                        className="size-3 xl:size-3.5"
                      />
                      <img
                        src={star}
                        alt="rating"
                        className="size-3 xl:size-3.5"
                      />
                      <img
                        src={star}
                        alt="rating"
                        className="size-3 xl:size-3.5"
                      />
                      <img
                        src={star}
                        alt="rating"
                        className="size-3 xl:size-3.5"
                      />
                      <img
                        src={star}
                        alt="rating"
                        className="size-3 xl:size-3.5"
                      />
                    </div>
                    <p className="text-[#108975] capitalize text-[14px] leading-[21px] xl:hidden pl-1 ">
                      {/* property?.propertyRatings?.avgRating */}
                    </p>
                    <p className="hidden text-[#108975] capitalize text-xs xl:flex pl-1 ">
                      {/* excellent */}
                      {property?.propertyRatings?.avgRating}
                    </p>
                  </div>

                  <div className="flex items-center gap-2 justify-end">
                    <p className="text-xs ">
                      {property?._count?.reviews} Reviews
                    </p>
                    {/* <p className="hidden xl:flex text-[#CC7914] text-[12px] font-semibold leading-[18px] ">
                      Comfort 5/5
                    </p> */}
                  </div>
                </div>

                {/* GUESTS & DURATION */}
                <p className="text-[12px] leading-[18px] text-[#6D6D6D] text-end xl:text-center ">
                  {/* {property.duration} nights, {property.guests} guests */}
                </p>

                {/* PRICE */}
                <div className="text-end">
                  <h3 className="text-2xl font-medium text-[#3C3C3C] xl:text-black ">
                    KSH{" "}
                    {property?.rooms[0]?.roomTypes?.pricings[0]?.price.toLocaleString()}
                  </h3>
                  <small className="text-[#6D6D6D] text-[12px] leading-[18px] ">
                    Tax and fees included
                  </small>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* buttons */}
        <div className="flex flex-col md:flex-row xl:flex-row gap-2.5 mt-4 xl:mt-0">
          <button
            onClick={() => followProperty(property?.id)}
            // disabled={followedProperties?.includes(property?.id)}
            className="bg-laara-primary text-white text-sm font-medium py-2 px-2.5 w-full h-[40px] rounded-[36px] text-center xl:py-2 xl:px-2.5 "
          >
            Follow property
            {/* {followedProperties?.includes(property?.id)
              ? "Followed"
              : "Follow property"} */}
          </button>
          <Link
            to={`/hotel-guest/${property?.id}`}
            className="flex items-center justify-center gap-1  bg-laara-secondary border border-laara-primary h-[40px] text-laara-primary text-sm font-medium py-2 px-2.5 w-full rounded-[36px] tdext-center xl:py-3 xl:px-2.5"
          >
            See availability
            <img src={arrow} alt="icon" className="" />
          </Link>
        </div>
      </div>
    </article>
  );
};

export default PropertyCard;
