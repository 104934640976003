import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import arrowleft from "@/assets/shared/arrowleft.svg";
import QRCode from "react-qr-code";

import { MdOutlineQrCodeScanner } from "react-icons/md";
import { useSelector } from "react-redux";

const ScanReservationQRCode = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [qrCOde, setQrCode] = useState(null);
  const token = useSelector((state) => state.user.accessToken);

  return (
    <section className="w-full mx-auto p-5 md:px-10 bg-[#FAFAFA] ">
      <section className="max-w-[1264px] w-full mx-auto">
        <section className="w-full space-y-5 ">
          {/* TOP SECTION */}
          <div className="flex items-center gap-2 md:gap-4 m ">
            <img
              src={arrowleft}
              alt="arrow-left icon"
              onClick={() => navigate(-1)}
              className="size-6 cursor-pointer"
            />
            <h4 className="text-lg font-medium text-[#626262] ">Scan</h4>
          </div>

          <div className="w-full h-[500px] md:w-[500px] xl:w-[500px] border rounded-[36px] mx-auto flex flex-col items-center justify-between">
            <div className="w-full h-full flex items-center justify-center">
              <div className="w-[299px] h-[299px] bg-white flex items-center justify-center border-2 rounded-[30px] overflow-hidden">
                {qrCOde && <QRCode value={qrCOde} size={250} />}
              </div>
            </div>
            <div className="w-full h-[4rem] bg-[#0s00000]/20 flex flex-col items-center relative">
              <div
                onClick={() => setQrCode(id)}
                className="bg-white rounded-full border px-8 py-2 w-fit shadow-lg cursor-pointer absolute -top-[1.7rem] flex items-center justify-center mb-2"
              >
                <MdOutlineQrCodeScanner className="text-black" size={30} />
              </div>
              <p className="text-[#383838] font-medium text-base text-center mt-[1.5rem]">
                Generate QR Code
              </p>
            </div>
          </div>
        </section>
      </section>
    </section>
  );
};

export default ScanReservationQRCode;
