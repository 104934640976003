import { useEffect, useRef } from "react";
import { MapContainer, Marker, Popup, TileLayer } from "react-leaflet";

const Map = ({
  width,
  height,
  mdWidth,
  mdHeight,
  xlHeight,
  xlWidth,
  pin,
  setOpenMap,
  openMap,
}) => {
  const mapRef = useRef();

  useEffect(() => {
    if (mapRef.current && pin) {
      const map = mapRef.current;
      if (
        pin.coordinates &&
        pin.coordinates.latitude &&
        pin.coordinates.longitude
      ) {
        map.setView([pin.coordinates.latitude, pin.coordinates.longitude], 13);
      } else {
        console.error("Invalid coordinates in pin:", pin);
      }
    }
  }, [pin]);

  return (
    <div
      id="map"
      className={`w-full w-[${width}] h-[${height}] md:w-[${mdWidth}] md:h-[${mdHeight}] xl:w-[${xlWidth}] xl:h-[${xlHeight}] mx-auto `}>
      <MapContainer
        ref={mapRef}
        className={`w-full h-full max-w-[${width}] max-h-[${height}] md:w-[${mdWidth}] md:h-[${mdHeight}] xl:w-[${xlWidth}] xl:h-[${xlHeight}] rounded-[14px] mx-auto z-0`}
        center={[
          pin?.coordinates?.latitude || 0,
          pin?.coordinates?.longitude || 0,
        ]}
        zoom={13}
        scrollWheelZoom={false}>
        <TileLayer
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />
        {pin.coordinates &&
          pin.coordinates.latitude &&
          pin.coordinates.longitude && (
            <Marker
              position={[pin.coordinates.latitude, pin.coordinates.longitude]}>
              <Popup>
                {pin.location}
                {!openMap && (
                  <div
                    className="hidden xl:flex hover:underline hover:cursor-pointer text-laara-primary font-medium ml-3"
                    onClick={() => {
                      setOpenMap(true);
                    }}>
                    Show on map
                  </div>
                )}
              </Popup>
            </Marker>
          )}
      </MapContainer>
    </div>
  );
};

export default Map;
