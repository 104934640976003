// eslint-disable-next-line no-unused-vars
import React, { useState, useEffect } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLessThan } from "@fortawesome/free-solid-svg-icons";
import Consider from "@/components/Listings/Consider";
import "./editproperty.css";
import { getPropertyById, updateAmenities } from "@/api";

const amenitiesList = {
  general: [
    "Free WiFi",
    "Air conditioning",
    "Non-smoking rooms",
    "Pet friendly",
    "Family rooms",
    "Room service",
    "24-hour front desk",
    "Concierge Service",
  ],
  bedroom: ["Comfortable beds", "Wardrobe/closet", "Ironing facilities"],
  kitchen: [
    "Refrigerator",
    "Microwave",
    "Kitchennete or Full kitchen",
    "Coffee/Tea maker",
    "Dishwasher",
    "Stove",
    "Oven",
  ],
  mediaTechnology: [
    "Flat-screen TV",
    "Cable/Satelite channels",
    "Streaming Services(e.g.Netflix)",
    "DVD player",
  ],
  foodDrink: ["On-site restaurant/bar", "Breakfast Options", "Minibar"],
  outdoorView: [
    "Balcony/Terrace",
    "Garden",
    "Outdoor Furniture",
    "BBQ facilities",
    "Sea View/ Mountain View/ City View",
  ],
  services: [
    "Daily housekeeping",
    "Laundry Service",
    "Dry Cleaning",
    "Shoe shine",
    "Trouser Press",
    "Wake-up service",
    "Fax/Photocopying",
    "Meeting/ Banquet facilities",
  ],
  business: ["Laptop-friendly workspace", "Ethernet connection"],
  wellness: [
    "Hot tub",
    "Swimming pool",
    "Sauna",
    "Fitness Center",
    "Spa and Wellness Center",
  ],
};

const PropertyFacilities = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [selectedAmenities, setSelectedAmenities] = useState({
    general: [],
    bedroom: [],
    kitchen: [],
    mediaTechnology: [],
    foodDrink: [],
    outdoorView: [],
    services: [],
    business: [],
    wellness: [],
  });

  // Fetch property details including amenities
  useEffect(() => {
    const fetchData = async () => {
      try {
        // Fetch property data by ID
        const propertyData = await getPropertyById(id);

        // Ensure property and amenities exist before accessing them
        const amenitiesData = propertyData?.property?.propertyAmenities || [];

        // Initialize amenities based on available list
        const initialAmenities = Object.keys(amenitiesList).reduce(
          (acc, category) => {
            acc[category] = [];
            return acc;
          },
          {},
        );

        // If amenities data exists, populate the initialAmenities object
        amenitiesData.forEach((amenity) => {
          const category = amenity.category?.toLowerCase().replace(/ /g, "");
          const name = amenity.name?.trim().toLowerCase();

          if (
            initialAmenities[category] &&
            amenitiesList[category]?.some(
              (a) => a.trim().toLowerCase() === name,
            )
          ) {
            initialAmenities[category].push(name);
          }
        });

        // Update the selected amenities state
        setSelectedAmenities(initialAmenities);
      } catch (error) {
        console.error("Error fetching property amenities:", error);
      }
    };

    fetchData();
  }, [id]);

  const handleCheckboxChange = (category, item) => {
    setSelectedAmenities((prevSelectedAmenities) => {
      const isChecked = prevSelectedAmenities[category]?.includes(item);

      const updatedCategory = isChecked
        ? prevSelectedAmenities[category].filter((i) => i !== item)
        : [...(prevSelectedAmenities[category] || []), item];

      return {
        ...prevSelectedAmenities,
        [category]: updatedCategory,
      };
    });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    // Format the updated amenities by looping through the selectedAmenities object
    const updatedAmenities = [];
    Object.entries(selectedAmenities).forEach(([category, items]) => {
      const formattedCategory = category
        .replace(/([A-Z])/g, " $1") // Convert camelCase to space-separated words
        .trim()
        .replace(/\b\w/g, (c) => c.toUpperCase()); // Capitalize the first letter of each word

      items.forEach((item) => {
        updatedAmenities.push({
          name: item.trim(),
          category: formattedCategory,
        });
      });
    });

    console.log("Sending amenities data:", { amenities: updatedAmenities });

    try {
      await updateAmenities(id, { amenities: updatedAmenities });
      console.log("Updated Amenities:", updatedAmenities);
      navigate(`/view-property/${id}`);
    } catch (error) {
      console.error("Error updating amenities:", error);
    }
  };

  const renderAmenities = (category, items) => (
    <div key={category} className="flex flex-col w-full p-4">
      <h4 className="text-[16px] mb-5 capitalize">{category}</h4>
      {items.map((item) => (
        <label key={item} className="flex items-center gap-2 mb-3">
          <input
            type="checkbox"
            checked={selectedAmenities[category]?.includes(item) || false}
            onChange={() => handleCheckboxChange(category, item)}
            className="custom-checkbox1"
          />
          {item}
        </label>
      ))}
    </div>
  );

  return (
    <div className="flex flex-col min-h-screen p-8 bg-base-white">
      <div className="flex-1">
        <section className="top h-16 flex items-center p-2 gap-3">
          <div className="flex items-center gap-4 text-lg text-gray-600 w-[700px]">
            <Link to={`/view-property/${id}`}>
              <FontAwesomeIcon icon={faLessThan} className="text-base" />
            </Link>
            <h3>General Amenities</h3>
          </div>
        </section>
        <section className="flex w-full max-w-[1264px] gap-5">
          <form
            className="flex flex-col p-2 max-w-[840px] w-full h-full"
            onSubmit={handleSubmit}
          >
            <div className="flex flex-col gap-4 mt-5 text-gray-700 text-[14px] overflow-y-auto">
              <div className="flex flex-col gap-4 p-5 max-w-[840px] w-full justify-center items-center ">
                <div className="flex flex-col gap-4 max-w-[700px] w-full p-2">
                  <h2 className="text-xl mb-4">
                    What can guests use at your place?
                  </h2>
                  {Object.entries(amenitiesList).map(([category, items]) =>
                    renderAmenities(category, items),
                  )}
                </div>
              </div>
              <div className="w-full flex justify-end gap-5 items-center mt-5">
                <button
                  type="submit"
                  className="py-2 px-4 bg-blue-500 text-white rounded-[36px] w-[216px] h-[55px]"
                >
                  Save Changes
                </button>
              </div>
            </div>
          </form>
          <Consider
            title={"General Amenities"}
            data={[
              "Select the amenities available at your place.",
              "Make sure to include all relevant categories.",
              "Provide accurate information for your guests.",
            ]}
            className="mt-5"
          />
        </section>
      </div>
    </div>
  );
};

export default PropertyFacilities;
