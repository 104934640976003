import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import arrowleft from "@/assets/shared/arrowleft.svg";
import { XCircle } from "lucide-react";
import { Rating } from "@smastrom/react-rating";
import { baseURL, headers } from "../../constants/apiconfig";
import { useSelector } from "react-redux";

const ReviewBooking = () => {
  const navigate = useNavigate();
  const [rating, setRating] = useState(3);
  const [hoveredRating, setHoveredRating] = useState(0);
  const [message, setMessage] = useState("");
  const { ref: bookingRef } = useParams();
  const { currentUser, accessToken } = useSelector((state) => state.user);
  const token = currentUser?.stsTokenManager?.accessToken || accessToken;

 

  const getRating = (rating) => {
    switch (rating) {
      case 1: return "Poor";
      case 2: return "Nothing special";
      case 3: return "Average";
      case 4: return "Very good";
      case 5: return "Excellent";
      default: return "None";
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!token) {
      return;
    }
  
    const reviewData = { message, bookingRef, rating };
    try {
      const response = await fetch(`${baseURL}/reviews/property/`, {
        method: 'POST',
        headers: headers(token),
        body: JSON.stringify(reviewData),
      });
  
      if (response.ok) {
        navigate(-1);
      } else {
        const errorData = await response.json();
        console.error('Error details:', errorData);
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };
  

  return (
    <section className="w-full py-5 bg-[#FAFAFA]">
      <section className="2xl:max-w-[1440px] xl:max-w-[1280px] w-full mx-auto px-5 md:mb-10 lg:px-10 xl:px-7 2xl:px-10">
        <section className="xl:max-w-[1264px] xl:px-2 w-full space-y-5">
          <div className="flex items-center gap-2">
            <img
              src={arrowleft}
              alt="arrow-left icon"
              onClick={() => navigate(-1)}
              className="size-6 cursor-pointer"
            />
            <h4 className="text-xl lg:text-lg font-medium text-[#626262]">
              Review
            </h4>
          </div>

          <form onSubmit={handleSubmit} className="bg-white rounded-[12px] p-3 xl:p-8 shadow-xl w-full">
            <div className="flex items-center justify-between">
              <p className="text-[#626262] text-lg">Rating & Feedback Form</p>
              <XCircle className="text-[#626262] size-6" />
            </div>

            <div className="h-[1px] w-full bg-[#C5C5C580]/50 my-4" />

            <div className="p-2 space-y-6">
              <p className="text-[#626262] text-lg">
                How good was your experience?
              </p>
              <div>
                <div>
                  <Rating
                    style={{ maxWidth: 348 }}
                    value={rating}
                    onChange={setRating}
                    onHoverChange={setHoveredRating}
                  />
                  <div>
                    <p className="text-[12px] text-[#626262]">{`Selected: ${getRating(rating)}`}</p>
                  </div>
                </div>
              </div>

              <div className="flex flex-col gap-2">
                <label className="text-[#626262] text-lg">
                  What can you say about the guest?
                </label>
                <textarea
                  placeholder="write here..."
                  rows={5}
                  value={message}
                  onChange={(e) => setMessage(e.target.value)}
                  className="border border-[#C5C5C580] rounded-[12px] p-2 outline-none"
                ></textarea>
              </div>

              <div className="text-right">
                <button type="submit" className="bg-laara-primary text-white text-lg font-medium rounded-[36px] py-3 px-[14px]">
                  Post Review
                </button>
              </div>
            </div>
          </form>
        </section>
      </section>
    </section>
  );
};

export default ReviewBooking;
