// ViewRoom.js

import React from 'react';
import { useParams } from 'react-router-dom';
import { properties } from '@/constants/data'; // Adjust the import path as needed

const ViewGuestRoom = () => {
    const { propertyId, roomType } = useParams();

    // Find the property and the room
    const property = properties.find(p => p.propertyId === propertyId);
    const room = property?.rooms.find(r => r.roomType === decodeURIComponent(roomType));

    if (!room) return <div>Room not found.</div>;

    return (
        <div className="container mx-auto p-4">
            <h1 className="text-2xl font-semibold mb-4">Room Details</h1>
            <div className="flex flex-col lg:flex-row">
                <div className="w-full lg:w-1/2 mb-4 lg:mb-0">
                    <img
                        src={room.mainImage} // Replace with the actual image field
                        alt={room.roomType}
                        className="w-full h-64 object-cover rounded-lg"
                    />
                </div>
                <div className="w-full lg:w-1/2 lg:pl-4">
                    <h2 className="text-xl font-medium mb-2">{room.roomType}</h2>
                    <p className="text-gray-600 mb-4">{room.description}</p> {/* Replace with actual description field */}
                    <div className="mb-4">
                        <h3 className="text-lg font-semibold mb-2">Amenities</h3>
                        <ul className="list-disc list-inside text-gray-600">
                            {room.amenities.map((amenity, index) => (
                                <li key={index}>{amenity}</li>
                            ))}
                        </ul>
                    </div>
                    <div className="mb-4">
                        <h3 className="text-lg font-semibold mb-2">Price</h3>
                        <p className="text-gray-600">{`$${room.price} per night`}</p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ViewGuestRoom;
