import React, { useState, useRef, useEffect } from 'react';

import PlusButton from "@/assets/message/Plus.svg"
import SendButton from "@/assets/message/Send.svg"

export const MessageInput = ({ value, onChange, onSend }) => {
  const [showOptions, setShowOptions] = useState(false);
  const textareaRef = useRef(null);

  const handleButtonClick = () => {
    setShowOptions(!showOptions);
  };



  useEffect(() => {
    if (textareaRef.current) {
      textareaRef.current.style.height = 'auto';
      textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`;
    }
  }, [value]);

  return (
    <div className="border-t p-2 max-w-[950px] w-full">
      <div className="flex items-start bg-gray-100 rounded-lg relative ">
        <button className="mr-2 z-10 mt-1" onClick={handleButtonClick} style={{ color: '#0466c8' }}>
        <img src={PlusButton} alt="Back" />
        </button>
        <textarea
          ref={textareaRef}
          value={value}
          onChange={onChange}
          onKeyPress={(e) => {
            if (e.key === 'Enter' && !e.shiftKey) {
              e.preventDefault();
              onSend();
            }
          }}
          placeholder="Type message here"
          className="flex-1 bg-transparent outline-none resize-none overflow-hidden min-h-[24px] max-h-auto"
        />
        <button className="text-blue-500 ml-2 mt-1 " onClick={onSend}>
        <img src={SendButton} alt="Back" />
        </button>
        {showOptions && (
          <div className="absolute left-0 bottom-full mb-2 w-48 rounded z-20 space-y-2">
            <button className="flex items-center  w-full text-left">
              <div className="flex items-center justify-center h-7 w-7 rounded-full bg-[#0466c8] text-white">
                <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M3 9a2 2 0 012-2h.93a2 2 0 001.664-.89l.812-1.22A2 2 0 0110.07 4h3.86a2 2 0 011.664.89l.812 1.22A2 2 0 0018.07 7H19a2 2 0 012 2v9a2 2 0 01-2 2H5a2 2 0 01-2-2V9z"
                  />
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M15 13a3 3 0 11-6 0 3 3 0 016 0z"
                  />
                </svg>
              </div>
            </button>
            <button className="flex items-center w-full text-left">
              <div className="flex items-center justify-center h-7 w-7 rounded-full bg-[#0466c8] text-white">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-5 w-5"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M9 12h6m-6 4h6m2 5H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z"
                  />
                </svg>
              </div>
            </button>
          </div>
        )}
      </div>
    </div >
  );
};