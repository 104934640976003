import { createSlice } from "@reduxjs/toolkit";

// CURRENT USER : {
// email
// avatar
// displayName
// lastSeen
// uid
// }

const initialState = {
  userEmail: null,
  avatar: null,
  displayName: null,
  lastSeen: null,
  uid: null,
  isAuthenticated: false,
  error: null,
  loading: false,
  accessToken: null,
};

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setToken: (state, action) => {
      state.accessToken = action.payload;
    },
    signInStart: (state) => {
      state.loading = true;
    },
    signInSuccess: (state, action) => {
      state.userEmail = action.payload.email;
      state.avatar = action.payload.avatar;
      state.displayName = action.payload.displayName;
      state.lastSeen = action.payload.lastSeen;
      state.uid = action.payload.uid;
      state.isAuthenticated = true;
      state.loading = false;
      state.error = null;
    },
    signInFailure: (state, action) => {
      state.error = action.payload;
      state.loading = false;
    },
    updateUserStart: (state) => {
      state.loading = true;
    },
    updateUSerSuccess: (state, action) => {
      state.loading = false;
      state.currentUser = action.payload;
    },
    updateUserFailed: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    deleteUserStart: (state) => {
      state.loading = true;
    },
    deleteUserSuccess: (state) => {
      state.loading = false;
      state.currentUser = null;
      state.error = null;
    },
    deleteUserFailed: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    logOutUserStart: (state) => {
      state.loading = true;
    },
    logOutUserSuccess: (state) => {
      state.loading = false;
      state.currentUser = null;
      state.error = null;
      state.isAuthenticated = false;
    },
    logOutUserFailed: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export const {
  signInStart,
  signInSuccess,
  signInFailure,
  updateUSerSuccess,
  updateUserStart,
  updateUserFailed,
  deleteUserStart,
  deleteUserFailed,
  deleteUserSuccess,
  logOutUserFailed,
  logOutUserStart,
  logOutUserSuccess,
  setToken,
} = userSlice.actions;

export default userSlice.reducer;
