import scheduled from '../../../assets/Promotions/Scheduled.svg';

const PendingPromo = () => {
  return (
    <>
      <div className=" flex items-center justify-center bg-white">
        <div className='w-[500px] h-[261px] bg-white rounded-[20px] flex flex-col items-center justify-center p-4 shadow-lg'>
          <img src={scheduled} alt="Scheduled Promotion" className='mb-4' />
          <h2 className="text-xl font-bold mb-2 leading-[28.8px]">Promotion Scheduled</h2>
          <p className="text-center text-[13px]">Promotion description: Black Friday promotion 2024 <br /> Promotion date: Till 30/11/2024</p>
        </div>
      </div>
    </>
  );
}

export default PendingPromo;