import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch, faSlidersH } from '@fortawesome/free-solid-svg-icons';
import Icon from "@/assets/message/man.svg"

export const NoChat = () => {
  return (
    <div className="w-full md:w-80 lg:w-96 h-screen bg-[#F8FAF8] flex flex-col">
  <div className="flex justify-between items-center z-10 p-4 w-full">
    <h2 className="text-2xl md:text-[30px] font-medium">Messages</h2>
    <div className="flex space-x-4">
      <button className="p-2 text-black hover:bg-gray-100 rounded-full">
        <FontAwesomeIcon icon={faSearch} />
      </button>
      <button className="p-2 text-black hover:bg-gray-100 rounded-full">
        <FontAwesomeIcon icon={faSlidersH} />
      </button>
    </div>
  </div>

  <div className="flex-grow flex flex-col items-center justify-center">
    <div className="w-full max-w-[362px] flex flex-col items-center">
      <img src={Icon} alt="Profile" className="rounded-full w-32 h-32 mb-4" />
      <h1 className="font-bold text-black text-center">You don't have any Messages</h1>
      <p className="text-sm text-gray-600 text-center mt-2">
        When you receive a new message, it will<br />appear here
      </p>
    </div>
  </div>
</div>
  )
}
