import { Link } from "react-router-dom";
import star from "../../assets/shared/star.svg"

export default function ReviewItem({ review }) {
  return (
    <div className="max-md:max-w-full">
      <div className="flex flex-col gap-5 max-md:flex-col max-md:gap-0">
        <div className="flex flex-col max-md:w-full">
          <div className="flex flex-col grow justify-center px-2 py-4 bg-white rounded-lg max-md:mt-8 max-md:max-w-full">
            <div className="flex max-md:flex-wrap gap-2 p-2">
              <div className="max-w-[60px] w-full">
                <img
                  loading="lazy"
                  src={review.avatarSrc}
                  className="shrink-0 rounded-full aspect-square w-[60px]"
                />
              </div>
              
              <div className="flex flex-col self-stretch">
                <div className="flex gap-5 justify-between">
                  <div className="flex flex-col">
                    <div className="justify-center text-sm font-normal text-zinc-600">
                      {review.name}
                    </div>
                    <div className="flex flex-col justify-center items-center mt-2">
                      <div className="flex">
                        {/* Rating icons */}
                        {[...Array(review.rating)].map((_, index) => (
                          <img
                            key={index}
                            loading="lazy"
                            src={star}
                            className="shrink-0 w-4 aspect-square fill-yellow-500"
                          />
                        ))}
                        <div className="text-sm font-normal text-zinc-600 ml-1">
                          {review.rating}.0
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="flex flex-col justify-between my-auto text-sm">
                    <div className="self-end leading-[120%] text-zinc-500">
                      12:00
                    </div>
                        
                    <div className="mt-2 tracking-normal text-right leading-[162.5%] text-neutral-600">
                      {review.date}
                    </div>
                  </div>
                </div>
                <div className="flex flex-col pb-2 mt-8">
                  <div className="text-sm font-normal tracking-normal leading-7 text-neutral-600">
                    {review.text}
                  </div>
                  <Link to={`/review/${review.id}`} className="justify-center self-end text-sm font-normla text-center text-neutral-700">
                    See more
                  </Link>
                </div>
              </div>
              
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
