import React from 'react';
import pic1 from '@/assets/newSpecialOffers/pic1.png';
import pic2 from '@/assets/newSpecialOffers/pic2.png';
import pic3 from '@/assets/newSpecialOffers/pic3.png';
// import CustomBtn from '@/components/ui/CustomBtn';
// import { Link } from "react-router-dom";

const NewSpecialOffers = () => {
  return (
    <div className="xl:mx-auto w-full xl:px-[88px] space-y-6 px-4 lg:px-0 mt-10">
      <div className="flex flex-col lg:flex-row gap-8 w-full h-full">
        {/* Text Section */}
        <div className="w-full lg:w-1/2 flex flex-col gap-4 items-start">
          <h1 className="font-semibold text-xl text-[#3C3C3C] lg:text-xl py-2">
            <span className="relative after:absolute after:bottom-0 after:left-0 after:w-28 after:h-1 after:bg-laara-primary py-2 leading-relaxed">
              Special Offers
            </span>{' '}
            on Vacation Rental
          </h1>
          <p className="text-[#4F4F4F] text-base font-normal mb-8 lg:mb-12">
            Planning a vacation shouldn't feel like work. Book a hotel, order food, and schedule rides—all in one place! Craving tasty flavors? Order from top-rated restaurants. Need a car hire? Our integrated transportation service has you covered. Don't just take a trip – create a story worth telling. Every traveler is unique, and so is every stay with Laara Group.
          </p>
          {/* <Link to="/landingpage">
          <CustomBtn text={'Book now'} />
          </Link> */}
        </div>

        {/* Image Section */}
        <div className="relative w-full lg:w-1/2 h-[160px] sm:h-[240px] lg:h-[340px]">
          {/* First Image */}
          <div className="absolute bottom-[10%] left-[10%] w-[45%] h-[100%] rounded-[30%] overflow-hidden border-4 sm:border-8 border-background">
            <img src={pic1} alt="Luxury pool" className="w-full h-full object-cover" />
          </div>
          {/* Second Image */}
          <div className="absolute bottom-[10%] right-[25%] w-[29%] h-[65%] rounded-full overflow-hidden border-4 sm:border-8 border-background">
            <img src={pic2} alt="Venice bridge" className="w-full h-full object-cover" />
          </div>
          {/* Third Image */}
          <div className="absolute top-0 right-[5%] w-[29%] h-[65%] rounded-full overflow-hidden border-4 sm:border-8 border-background">
            <img src={pic3} alt="Modern interior" className="w-full h-full object-cover" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default NewSpecialOffers;