import { useState, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLessThan } from '@fortawesome/free-solid-svg-icons';
import { getPropertyById, updatePropertyParking } from '@/api'; // Adjust import path as needed

const EditPropertyParking = () => {
    const { id } = useParams();
    const [parkingAvailable, setParkingAvailable] = useState(null);
    const [paidParkingDetails, setPaidParkingDetails] = useState({ cost: '', time: '' });
    const [reservationRequired, setReservationRequired] = useState(null);
    const [parkingLocation, setParkingLocation] = useState(null);
    const [parkingType, setParkingType] = useState(null);

    useEffect(() => {
        const fetchProperty = async () => {
            try {
                const propertyData = await getPropertyById(id);
                const amenities = propertyData.property.propertyAmenities || {};
                const accessibilityFeatures = amenities.accessibilityFeatures || [];
    
                // Extract parking-related features
                const parkingFeature = accessibilityFeatures.find(
                    feature => feature.features.category === 'Parking'
                );
    
                // Check if parking feature exists and set parking state
                if (parkingFeature) {
                    const parkingDetails = parkingFeature.features.feature;
    
                    setParkingAvailable(parkingDetails.includes("Disabled parking spot") ? 'Yes' : 'No');
    
                    // If "Yes, paid" is part of the parking details, handle paid parking info
                    if (parkingDetails.includes("paid")) {
                        setPaidParkingDetails({
                            cost: '', // Assuming you'll get the cost from another field or default value
                            time: '', // Assuming you'll get the time from another field or default value
                        });
                    }
                } else {
                    setParkingAvailable('No'); // Default to "No" if no parking feature is found
                }
            } catch (error) {
                console.error("Error fetching property data:", error);
            }
        };
    
        fetchProperty();
    }, [id]);    

    const handleSubmit = async (event) => {
        event.preventDefault();

        const formattedData = {
            parkingAvailable,
            paidParking: parkingAvailable === 'Yes, paid' ? paidParkingDetails : undefined,
            reservationRequired,
            location: parkingLocation,
            type: parkingType,
        };

        try {
            await updatePropertyParking(id, formattedData);
            console.log("Updated parking details:", formattedData);
            // Navigate to the appropriate page or show a success message
        } catch (error) {
            console.error("Error updating parking details:", error);
        }
    };

    return (
        <div className="p-6 bg-base-white">
            <section className='top h-16 flex items-center p-2 gap-3'>
                <div className='flex items-center gap-4 text-lg text-gray-600 w-[700px]'>
                    <Link to={`/view-property/${id}`}>
                        <FontAwesomeIcon icon={faLessThan} className="text-base" />
                    </Link>
                    <h3>Amenities</h3>
                </div>
            </section>
            <h2 className="text-lg font-normal mb-2">
                Tell us about the parking situation at your place
            </h2>
            <form onSubmit={handleSubmit}>
                <section className='bg-laara-secondary/15 p-4 w-full max-w-[728px] border-[0.2px] border-custom-gray-300 rounded-lg'>
                    
                    {/* Parking Availability */}
                    <div className="mb-4 p-4">
                        <h3 className="text-md font-normal mb-2">Is parking available to guests?</h3>
                        <div className="flex flex-col gap-3">
                            {['Yes, free', 'Yes, paid', 'No'].map(option => (
                                <label key={option} className="flex items-center gap-2">
                                    <input
                                        type="radio"
                                        name="parkingAvailable"
                                        value={option}
                                        checked={parkingAvailable === option}
                                        onChange={() => setParkingAvailable(option)}
                                        className="form-radio"
                                    />
                                    <span className="radio-checkmark"></span>
                                    <span>{option}</span>
                                </label>
                            ))}
                        </div>
                        {parkingAvailable === 'Yes, paid' && (
                            <div className="mt-4">
                                <h4 className="text-[16px] font-normal mb-2">Select payment parameters</h4>
                                <div className='flex'>
                                    <input
                                        type="text"
                                        placeholder="Cost"
                                        value={paidParkingDetails.cost}
                                        onChange={(e) => setPaidParkingDetails({ ...paidParkingDetails, cost: e.target.value })}
                                        className="border p-2 rounded w-3/4 outline-none"
                                    />
                                    <select
                                        value={paidParkingDetails.time}
                                        onChange={(e) => setPaidParkingDetails({ ...paidParkingDetails, time: e.target.value })}
                                        className="border p-2 rounded w-1/4 outline-none"
                                    >
                                        <option value="">Select time</option>
                                        <option value="per_day">Per day</option>
                                        <option value="per_hour">Per hour</option>
                                    </select>
                                </div>
                            </div>
                        )}
                    </div>

                    {/* Reservation Required */}
                    <div className="mb-4 p-4">
                        <h3 className="text-md font-normal mb-2">Do guests need to reserve a parking spot?</h3>
                        <div className="flex flex-col gap-3">
                            {['Yes', 'No'].map(option => (
                                <label key={option} className="flex items-center gap-2">
                                    <input
                                        type="radio"
                                        name="reservationRequired"
                                        value={option}
                                        checked={reservationRequired === option}
                                        onChange={() => setReservationRequired(option)}
                                        className="form-radio"
                                    />
                                    <span className="radio-checkmark"></span>
                                    <span>{option}</span>
                                </label>
                            ))}
                        </div>
                    </div>

                    {/* Parking Location */}
                    <div className="mb-4 p-4">
                        <h3 className="text-md font-normal mb-2">Where is the parking located?</h3>
                        <div className="flex flex-col gap-3">
                            {['Onsite', 'Offsite'].map(option => (
                                <label key={option} className="flex items-center gap-2">
                                    <input
                                        type="radio"
                                        name="parkingLocation"
                                        value={option}
                                        checked={parkingLocation === option}
                                        onChange={() => setParkingLocation(option)}
                                        className="form-radio"
                                    />
                                    <span className="radio-checkmark"></span>
                                    <span>{option}</span>
                                </label>
                            ))}
                        </div>
                    </div>

                    {/* Parking Type */}
                    <div className='p-4'>
                        <h3 className="text-md font-normal mb-2">Is the parking public or private?</h3>
                        <div className="flex gap-3 flex-col">
                            {['Public', 'Private'].map(option => (
                                <label key={option} className="flex items-center gap-2">
                                    <input
                                        type="radio"
                                        name="parkingType"
                                        value={option}
                                        checked={parkingType === option}
                                        onChange={() => setParkingType(option)}
                                        className="form-radio"
                                    />
                                    <span className="radio-checkmark"></span>
                                    <span>{option}</span>
                                </label>
                            ))}
                        </div>
                    </div>
                </section>
                <button
                    type="submit"
                    className="mt-3 py-2 px-4 bg-blue-500 text-white rounded-[36px] w-[216px] h-[55px]"
                >
                    Save Changes
                </button>
            </form>
        </div>
    );
};

export default EditPropertyParking;
