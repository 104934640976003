import { useState, useEffect } from "react";
import { MdCircleNotifications } from "react-icons/md";
import { AiOutlineClockCircle } from "react-icons/ai"; // For loading state
import Profile from "../../assets/Notifications/Notification Logo.svg";
import { useNavigate } from "react-router-dom";

// Default data for notifications with profile photo, title, message, timestamp, and read status
const defaultNotifications = [
  {
    profilePhoto: `${Profile}`,
    title: "Hotel listing update 🏨",
    message: "Check out our new uploaded hotel and airbnb listing in the Neyri locations",
    timestamp: "2 Nov 2024",
    isRead: false,
  },
  {
    profilePhoto: `${Profile}`,
    title: "Airbnb listing update 🏨",
    message: "Check out our new uploaded hotel and airbnb listing in the Neyri locations",
    timestamp: "2 Nov 2024",
    isRead: false,
  },
  {
    profilePhoto: `${Profile}`,
    title: "Website revamp 🔥",
    message: "Check out our new uploaded hotel and airbnb listing in the Neyri locations",
    timestamp: "2 Nov 2024",
    isRead: true,
  },
];

const PopNotifications = ({ closeOverlay }) => {
  const [notifications, setNotifications] = useState([]);
  const [loading, setLoading] = useState(true); // State to manage loading

  const navigate = useNavigate();

  const handleClick = (route) => {
    navigate(route);
    closeOverlay(); // Close the overlay when redirecting
  };

  useEffect(() => {
    // Simulate fetching data from an API
    setTimeout(() => {
      setNotifications(defaultNotifications); // Set default data after delay
      setLoading(false); // Set loading to false after fetching
    }, 1000); // Simulate a 1-second delay
  }, []);

  return (
    <div className="mt-1 mb-1 mr-14 flex p-2 bg-white border-[1px] max-w-full lg:w-[506px] mx-auto rounded shadow-lg overflow-auto">
      {loading ? (
        <div className="flex flex-col mx-auto items-center justify-center h-full text-gray-500">
          <AiOutlineClockCircle size={48} className="mb-4 text-gray-600 animate-spin" />
          <span className="text-lg text-center px-4">Loading Notifications...</span>
        </div>
      ) : notifications.length === 0 ? (
        <div className="flex flex-col mx-auto w-[192px] h-[154px] items-center relative">
          <MdCircleNotifications size={90} color="grey" className="mb-1 " />
          <span className="flex w-[170px] text-center text-base font-poppins font-semibold text-[hsla(0,0%,16%,1)]">No New Notifications</span>
          <span className="text-center flex w-[170px] lg:w-[200px] h-[48px] text-sm text-[hsla(0,0%,53%,1)] font-normal font-poppins">New notifications will be displayed here</span>
        </div>
      ) : (
        <div className="flex w-full flex-col flex-grow">
          {notifications.map((notification, index) => (
            <div
              key={index}
              className="relative flex flex-col bg-[hsla(0,0%,100%,1)] w-full mb-2 rounded hover:shadow-md transition-shadow duration-200 mb-0"
              onClick={() => handleClick("/notifications")} // Example route
            >
              <div className="flex items-start p-1 hover:shadow-custom-lg relative">
                <img
                  src={notification.profilePhoto}
                  alt="Profile"
                  className="w-12 h-12 rounded-full mr-4 flex-shrink-0"
                />
                <div className="flex-1">
                  <div className="flex justify-between items-center space-y-2">
                    <h3 className="font-poppins text-[16px] font-medium leading-[1.5rem] tracking-[0.005em] text-left flex items-center">
                      {notification.title}
                    </h3>
                    <div
                      className={`absolute top-1 right-1 w-3 h-3 mt-2 rounded-full ${notification.isRead ? null : 'bg-[hsla(185,100%,47%,1)]'}`}
                    ></div>
                  </div>
                  <p className="font-poppins text-[12px] font-normal leading-[1.5rem] tracking-[0.0025em] text-left text-[hsla(0,0%,16%,1)]">{notification.message}</p>
                  <span className="font-poppins text-[11px] font-normal leading-[1.5rem] tracking-[0.0025em] text-left text-[hsla(0,0%,53%,1)]">{notification.timestamp}</span>
                </div>
              </div>
              {index < notifications.length - 1}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default PopNotifications;
