import React, { useState } from 'react';

function BookingDashboard() {
  const dashboardItems = [
    { title: 'Active bookings', value: '$1,980', link: 'View' },
    { title: 'Payment Due', value: '$30,480', link: 'View' },
    { title: 'Payment Due', value: '$1,980', link: 'View' },
    {
      title: 'Messages',
      details: [
        { status: 'Pending', count: '22', color: 'text-[#CC7914]', borderColor: 'border-gray-300' },
        { status: 'In Progress', count: '22', color: 'text-[#0466C8]', borderColor: 'border-gray-300' },
        { status: 'Resolved', count: '22', color: 'text-[#108975]', borderColor: 'border-gray-300' }
      ],
      width: 'lg:w-[239px]'
    }
  ];

  return (
    <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-4 mb-8 w-full">
      {dashboardItems.map((item, index) => (
        <div 
          key={index} 
          className={`bg-white p-6 rounded-lg shadow-md flex flex-col justify-between h-[162px] w-full ${item.width}`}
        >
          <div className="flex justify-between items-center">
            <h2 className="text-sm text-[#383838] font-normal">{item.title}</h2>
            {item.link && <a href="#" className="text-[#0466c8] text-sm font-normal">{item.link}</a>}
          </div>
          {item.value ? (
            <p className="text-3xl font-bold">{item.value}</p>
          ) : (
            <div className="flex flex-col text-sm space-y-2">
              {item.details.map((detail, i) => (
                <span 
                  key={i} 
                  className={`${detail.color} ${detail.borderColor} border rounded-lg px-4 py-1 inline-flex justify-between items-center`}
                >
                  <span className='text-sm font-normal'>{detail.status}</span>
                  <span className="font-normal text-black">{detail.count}</span>
                </span>
              ))}
            </div>
          )}
        </div>
      ))}
    </div>
  );
}

function PaymentTable() {
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;
  const payments = Array(32).fill({
    host: 'Brooklyn Simmons',
    propertyType: 'Hotel',
    amountDue: 400.48
  });

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = payments.slice(indexOfFirstItem, indexOfLastItem);

  const pageNumbers = [];
  for (let i = 1; i <= Math.ceil(payments.length / itemsPerPage); i++) {
    pageNumbers.push(i);
  }

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  return (
    <div className='w-full max-w-[537px]'>
      <div className="flex justify-between items-center">
        <h2 className="text-lg font-medium mb-4">Payment Due</h2>
        <div className="flex items-center mb-4">
        <div className="border rounded-lg font-semibold px-2 py-1.5">
          <button
            className={`px-3 py-1 rounded mr-2 ${currentPage === 1 ? 'text-gray-500' : 'text-black'}`}
            onClick={() => handlePageChange(currentPage - 1)}
            disabled={currentPage === 1}
          >
            &lt;
          </button>
          {pageNumbers.map((number) => (
            <button
              key={number}
              className={`px-3 py-1 mr-2 ${currentPage === number ? 'bg-[#108975] text-white' : 'text-black'} rounded`}
              onClick={() => handlePageChange(number)}
            >
              {number}
            </button>
          ))}
          <button
            className="px-3 py-1 mr-2 text-black rounded"
            onClick={() => handlePageChange(currentPage + 1)}
            disabled={currentPage === pageNumbers.length}
          >
            &gt;
          </button>
        </div>
      </div>
      </div>

      <div className="overflow-x-auto">
        <table className="w-full">
          <thead>
            <tr className="border-b border-t bg-[#EEEEEE] font-bold text-sm text-[#262626]">
              <th className="text-left p-3">Host</th>
              <th className="text-left p-3">Property type</th>
              <th className="text-left p-3">Amount Due</th>
              <th className="text-left p-3">Action</th>
            </tr>
          </thead>
          <tbody>
            {currentItems.map((payment, index) => (
              <tr key={index} className='border-b text-sm text-[#262626] font-normal'>
                <td className="p-3">{payment.host}</td>
                <td className="p-3">{payment.propertyType}</td>
                <td className="p-3">${payment.amountDue.toFixed(2)}</td>
                <td className="p-3"><button className="text-[#0466C8]">Pay</button></td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}

const BookingManagement = () => {
  return (
    <div className="container mx-auto p-4">
      <BookingDashboard />
      <PaymentTable />
    </div>
  );
};

export default BookingManagement;
