import PropTypes from 'prop-types';

const PropertyImages = ({ mainImage, additionalImages = [] }) => {
    return (
        <div className='property-images h-[345px] flex gap-3'>
            <div className='main-image bg-gray-200 h-full w-3/4 rounded-[16px]'>
                {mainImage ? (
                    <img src={mainImage} alt='Main Property' className='w-full h-full object-cover rounded-[16px]' />
                ) : (
                    <p>No main image available</p>
                )}
            </div>
            <div className='additional-images flex gap-2 flex-col'>
                {additionalImages.length > 0 ? (
                    additionalImages.slice(1, 4).map((image, index) => (
                        <div key={index} className='additional-image bg-gray-200 w-full h-[105px] rounded-[16px]'>
                            {image ? (
                                <img src={image} alt={`Additional ${index + 1}`} className='w-full h-full object-cover rounded-[16px]' />
                            ) : (
                                <p>No image available</p>
                            )}
                        </div>
                    ))
                ) : (
                    <p>No additional images available</p>
                )}
            </div>
        </div>
    );
};

PropertyImages.propTypes = {
    mainImage: PropTypes.string.isRequired,
    additionalImages: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default PropertyImages;
