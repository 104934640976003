import { useEffect, useState } from 'react';
import { useParams, Link } from 'react-router-dom';
import { getPropertyById } from '@/api';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLessThan } from "@fortawesome/free-solid-svg-icons";
import EditRoomOverlay from '@/components/HostProperties/EditRoomOverlay';

const ViewRoom = () => {
  const { id, roomType } = useParams();
  const [property, setProperty] = useState(null);
  const [room, setRoom] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  const [groupedAdditionalFacilities, setGroupedAdditionalFacilities] = useState({});
  const [safetyFacilities, setSafetyFacilities] = useState([]);

   const [isOverlayOpen, setIsOverlayOpen] = useState(false);

   const openOverlay = () => setIsOverlayOpen(true);
   const closeOverlay = () => setIsOverlayOpen(false);

  
  useEffect(() => {
    const fetchPropertyAndRoomData = async () => {
      try {
        const response = await getPropertyById(id);

        if (!response || !response.property) {
          throw new Error('Invalid data structure');
        }

        const fetchedProperty = response.property;
        setProperty(fetchedProperty);

        const rooms = fetchedProperty.rooms || [];
        if (rooms.length === 0) {
          throw new Error('No rooms available for this property');
        }

        const decodedRoomType = decodeURIComponent(roomType);
        const foundRoom = rooms.find(r => r.roomTypes.name.toLowerCase() === decodedRoomType.toLowerCase());
        if (!foundRoom) {
          throw new Error('Room type not found');
        }

        setRoom(foundRoom);

        // Group amenities by category
        const amenities = foundRoom.roomTypes.roomTypeAmenities || [];
        const grouped = amenities.reduce((acc, item) => {
          const category = item.amenities.category || 'Uncategorized';
          if (!acc[category]) {
            acc[category] = [];
          }
          acc[category].push(item.amenities.description || item.amenities.name);
          return acc;
        }, {});
        
        // Add bathroom amenities
        const bathroomAmenities = foundRoom.roomTypes.bathroom?.bathroomAmenties || [];
        bathroomAmenities.forEach(item => {
          const category = item.amenities.category || 'Uncategorized';
          if (!grouped[category]) {
            grouped[category] = [];
          }
          grouped[category].push(item.amenities.description || item.amenities.name);
        });

        setGroupedAdditionalFacilities(grouped);

        // Extract safety facilities
        const safetyAmenities = fetchedProperty.propertyAmenities.filter(amenity => amenity.amenities.category === "Safety");
        setSafetyFacilities(safetyAmenities);

      } catch (err) {
        setError(err.message || 'Failed to fetch room and property data.');
      } finally {
        setLoading(false);
      }
    };

    fetchPropertyAndRoomData();
  }, [id, roomType]);

  const openModal = (index) => {
    if (room.roomTypes.roomTypeImages && room.roomTypes.roomTypeImages.length > 0) {
      setCurrentImageIndex(index);
      setIsModalOpen(true);
    }
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const nextImage = () => {
    if (room.roomTypes.roomTypeImages && room.roomTypes.roomTypeImages.length > 0) {
      setCurrentImageIndex((prevIndex) => (prevIndex + 1) % room.roomTypes.roomTypeImages.length);
    }
  };

  const prevImage = () => {
    if (room.roomTypes.roomTypeImages && room.roomTypes.roomTypeImages.length > 0) {
      setCurrentImageIndex((prevIndex) => (prevIndex - 1 + room.roomTypes.roomTypeImages.length) % room.roomTypes.roomTypeImages.length);
    }
  };

  if (loading) {
    return (
      <div className="flex justify-center items-center h-screen">
        <div className="w-16 h-16 border-4 border-t-4 border-gray-200 border-t-blue-500 border-solid rounded-full animate-spin" aria-label="Loading"></div>
      </div>
    );
  }

  if (error) return <div className="text-red-500">Something went wrong: {error}</div>;

  if (!room) return <div>No room details available.</div>;

  const remainingImagesCount = room.roomTypes.roomTypeImages.length - 5;

 // Accessibility Features Section
  const accessibilityFeatures = property?.accessibilityFeatures || [];
  const filteredCategories = ["Bathroom", "Common Areas", "Bathroom", "Communication"];
  
  // Filter and group features
  const groupedFeatures = accessibilityFeatures.reduce((acc, item) => {
    const { category, feature } = item.features;
    if (category && feature && filteredCategories.includes(category)) {
      if (!acc[category]) {
        acc[category] = [];
      }
      acc[category].push(feature);
    }
    return acc;
  }, {});

  const roomPricing = room.roomTypes.pricings;

  return (
    <div className="bg-base-white p-8">
      <section className="top h-16 mb-2">
        <div className="text-lg text-gray-600 w-[700px]">
          <Link
            to={`/view-property/${id}`}
            className="flex items-center gap-4"
          >
            <FontAwesomeIcon icon={faLessThan} className="text-[18px]" />
            <h3>Room Details</h3>
          </Link>
        </div>
      </section>

      <div className="flex flex-col lg:flex-row">
        <section className='rightside max-w-[827px] w-full'>
          <section className='max-w-[827px] flex flex-col w-full gap-2'>
          {/* Pricing per Night */}
          <div className="text-base font-normal">
            {roomPricing?.[0]?.price 
              ? `Price $${roomPricing[0].price} per Night`
              : 'Price Not Available'}
          </div>

            {/* Main Image */}
            <div className="w-full h-[400px]">
              <img
                src={room.roomTypes.roomTypeImages[0]?.images.url || ''}
                alt={room.roomTypes.name || 'Room Image'}
                className="w-full h-96 object-cover rounded-lg"
              />
            </div>

            {/* Rest of the Images in a Grid */}
            <div className='w-full h-[95px] grid grid-cols-4 gap-2'>
              {room.roomTypes.roomTypeImages.slice(1, 5).map((image, index) => (
                <div key={index} className="w-full h-[95px]">
                  <img
                    src={image?.images.url || ''}
                    alt={`Image ${index + 1}`}
                    className="w-full h-full object-cover rounded-lg cursor-pointer"
                    onClick={() => openModal(index + 1)}
                  />
                </div>
              ))}

              {remainingImagesCount > 0 && (
                <div className="relative w-full h-[95px] cursor-pointer" onClick={() => openModal(5)}>
                  <img
                    src={room.roomTypes.roomTypeImages[5]?.images.url || ''}
                    alt="More images"
                    className="w-full h-full object-cover rounded-lg"
                  />
                  <div className="absolute inset-0 bg-black bg-opacity-50 flex items-center justify-center text-white font-semibold text-lg">
                    +{remainingImagesCount} more
                  </div>
                </div>
              )}
            </div>
          </section>
          <section className='roominfo text-gray-700 pt-4'>
            <div className='description mt-4 p-2'>
              <h3 className='text-[18px] font-medium leading-7 tracking-tight text-left '>Description</h3>
              <p className='text-sm font-normal leading-6 tracking-tight text-left'>{room.roomTypes.description || 'No description available'}</p>
            </div>

            {/* Room General Facilities Section */}
            <div className="generalroomfacilities mt-4 p-2">
              <h3 className="text-gray-700 text-[18px] font-medium mb-2">
                General Room Facilities
              </h3>
              <div className="flex flex-wrap gap-2 mt-2">
                {(room.roomTypes.roomTypeAmenities || [])
                .filter((amenity) => amenity.amenities.category === "General")
                .map((amenity, index) => (
                  <div
                    key={index}
                    className="text-gray-600 w-[190px] h-[45px] rounded-[10px] p-2 flex items-center justify-center border hover:bg-custom-gray-200 transition-all duration-300 text-[14px]"
                  >
                    {amenity.amenities.name}
                  </div>
                ))}
              </div>
            </div>

            {/* Accessibility Features Section */}
            <div className="accessibilityfeatures w-full max-w-[920px] p-2 text-gray-600 mt-4 mb-2 flex flex-col gap-8">
              <h3 className="text-gray-700 text-[18px] font-semibold mb-2">
                Accessibility Features
              </h3>

              {Object.entries(groupedFeatures).length > 0 ? (
                Object.entries(groupedFeatures).map(([category, features], index) => (
                  <div key={index}>
                    <h4 className="text-gray-700 text-[16px] font-medium">
                      {category}
                    </h4>
                    <ul className="list-disc list-inside pl-4 text-gray-700">
                      {features.map((feature, featureIndex) => (
                        <li key={featureIndex} className="text-[14px]">
                          {feature}
                        </li>
                      ))}
                    </ul>
                  </div>
                ))
              ) : (
                <div>No accessibility features available.</div>
              )}

            </div>
          </section>
        </section>
        <section className="leftside max-w-[405px] w-full p-4 tect-gray-700">
            <div className="p-4">
               <button
                onClick={openOverlay}
                className="h-12 w-full bg-blue-500 text-white rounded-[36px]"
            >
                Edit Room Details
            </button>

            {isOverlayOpen && (
                <EditRoomOverlay closeOverlay={closeOverlay} roomNumber={room.number}/>
            )}
            </div>
            <div className='p-4 w-full'>
                <h3 className='text-[18px] leading-7 tracking-tight text-left text-gray-700  font-semibold mb-2'>About The Room</h3>
                <ul className="list-disc list-inside pl-4 text-gray-700 txt-sm">
                    <li>{room.roomTypes.name}</li>
                    <li>{room.roomTypes.maxGuests} guests max</li>
                    <li>Room Size - {room.roomTypes.typeSize} sq. ft.</li>
                    <li>Bed Types: {room.roomTypes.bedTypes.map(bed => bed.bedType.name).join(', ')}</li>
                    <li>{room.roomTypes.roomTypePolicies.map(policy => policy.policies.description).join(', ')}</li>
                </ul>
            </div>
            
            {/* Additional Room Facilities Section */}
            <div className='p-4'>
              <h3 className='text-[18px] leading-7 tracking-tight text-left text-gray-700 font-medium mb-2'>
                Additional Room Facilities
              </h3>
              {Object.entries(groupedAdditionalFacilities).length > 0 ? (
                Object.entries(groupedAdditionalFacilities).map(([category, features], index) => (
                  <div key={index} className="mb-2 mt-3">
                    <h4 className="text-gray-700 text-[16px] font-medium mb-2">
                      {category}
                    </h4>
                    <ul className="list-disc list-inside pl-4 text-gray-700">
                      {features.map((feature, featureIndex) => (
                        <li key={featureIndex} className="text-[14px] mt-2">
                          {feature}
                        </li>
                      ))}
                    </ul>
                  </div>
                ))
              ) : (
                <div>No additional room facilities available.</div>
              )}
            </div>
            <div className='p-4'>
                <h3 className='text-[18px] leading-7 tracking-tight text-left text-gray-700 font-medium mb-2'>
                Safety Facilities
              </h3>
             <ul className="list-disc list-inside pl-4 text-gray-700">
                {safetyFacilities.map((facility, index) => (
                  <li key={index} className="text-sm mb-1">
                    {facility.amenities.name}
                  </li>
                ))}
              </ul>
            </div>
        </section>
      </div>

      {isModalOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-70 flex items-center justify-center z-50" role="dialog" aria-labelledby="modal-title" aria-describedby="modal-description">
  <div className="relative max-w-4xl mx-auto bg-white p-4 rounded-lg">
    <button
      onClick={closeModal}
      className="absolute top-2 right-2 text-white text-xl"
      aria-label="Close Modal"
    >
      &times;
    </button>
    <div className="flex items-center justify-between">
      <button
        onClick={prevImage}
        className="bg-gray-200 p-2 rounded-full"
        aria-label="Previous Image"
      >
        &lt;
      </button>
      <img
        src={room.roomTypes.roomTypeImages[currentImageIndex]?.images.url || ''}
        alt={`Image ${currentImageIndex + 1}`}
        className="w-full h-auto max-h-[80vh] object-cover"
        id="modal-description"
      />
      <button
        onClick={nextImage}
        className="bg-gray-200 p-2 rounded-full"
        aria-label="Next Image"
      >
        &gt;
      </button>
    </div>
  </div>
</div>

      )}
    </div>
  );
};

export default ViewRoom;
