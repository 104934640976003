import React, { useState } from 'react';
import heroBg from '@/assets/hero/newHero/Skybg.svg';
import box from "@/assets/hero/box.svg";
import car from "@/assets/hero/car.svg";
import house from "@/assets/hero/house.svg";
import Ellipse1 from '@/assets/hero/newHero/elips/Ellipse 1.png';
import Ellipse2 from '@/assets/hero/newHero/elips/Ellipse 2.png';
import Ellipse3 from '@/assets/hero/newHero/elips/Ellipse 3.png';

const NewHero = () => {
    const [activeButton, setActiveButton] = useState(null);

    const Elipses = [
        { id: 1, img: Ellipse1 },
        { id: 2, img: Ellipse2 },
        { id: 3, img: Ellipse3 },
    ];

    const handleButtonClick = (buttonName) => {
        setActiveButton(buttonName);
    };

    const getNumberStyle = (number) => {
        const isActive = (activeButton === 'Stays' && number === 1) ||
            (activeButton === 'Expos' && number === 2) ||
            (activeButton === 'Boardrooms' && number === 3);

        return `w-14 h-14 sm:w-16 sm:h-16 flex items-center justify-center rounded-full text-3xl cursor-pointer
             ${isActive
                ? 'bg-[#FFFFFF] text-black border-2 border-white'
                : 'bg-transparent text-white border border-white'}`;
    };

    const getButtonStyle = (buttonName) => {
        const isActive = activeButton === buttonName;
        return `flex flex-row border items-center px-2.5 sm:px-[18px] py-[8px] sm:py-5 gap-[8px] sm:gap-[12px] rounded-[32px] h-[28px] sm:h-[49px] ${isActive
                ? 'text-white border-white'
                : 'border-laara-primary text-laara-primary'
            }`;
    };

    const getImageStyle = (buttonName) => {
        const isActive = activeButton === buttonName;
        return `w-[16px] sm:w-[20px] ${isActive ? 'filter brightness-0 invert' : ''}`;
    };

    return (
        <div className="xl:mx-auto h-[722px] xl:px-[88px] ">
            <div className="">
            <header className="xl:mx-auto w-full space-y-4">
                <div
                    className="w-full text-[#F4FEFF] flex flex-col-reverse lg:flex-row items-center justify-center lg:items-start lg:justify-between gap-8 lg:gap-0 p-4 lg:px-8 lg:py-16 relative rounded-3xl"
                    style={{
                        backgroundImage: `url(${heroBg})`,
                        backgroundSize: "cover",
                        backgroundPosition: "center",
                        backgroundRepeat: "no-repeat",
                        height: "694.52px",
                    }}
                >
                    {/* Left section with numbers and buttons */}
                    <div className="flex flex-col items-center justify-end mx-auto lg:ml-[99px] lg:pt-12 mt-[50px]">
                        <div className="flex justify-between gap-4 lg:gap-8 md:gap-3 mb-10">
                            <div className="flex flex-col items-center relative">
                                <div className={`${getNumberStyle(1)} relative z-10`}>
                                    <span>1</span>
                                </div>
                                <div className="w-[2px] h-[60px] bg-white"></div>
                                <div className={`${getNumberStyle(2)} relative z-10`}>
                                    <span>2</span>
                                </div>
                                <div className="w-[2px] h-[60px] bg-white"></div>
                                <div className={`${getNumberStyle(3)} relative z-10`}>
                                    <span>3</span>
                                </div>
                            </div>

                            <div className="flex flex-col gap-4 mr-20 ">
                                <p className="text-[#3C3C3C] text-base font-normal text-center lg:text-left">
                                    Find accommodations that meet your specific needs
                                </p>
                                <h1 className="text-3xl  sm:text-[44px] text-laara-primary font-semibold sm:leading-relaxed leading-loose text-center lg:text-left">
                                    Revolutionize <br /> your Travel  <br />Experience
                                </h1>
                            </div>
                        </div>
                        <span className="flex flex-wrap justify-center gap-[8px] sm:gap-[20px] mb-5">
                            <button
                                className={getButtonStyle('Stays')}
                                onClick={() => handleButtonClick('Stays')}
                            >
                                <img src={house} alt="laara stays" className={getImageStyle('Stays')} />
                                <p className="text-xs sm:text-lg">Stays</p>
                            </button>

                            <button
                                className={getButtonStyle('Expos')}
                                onClick={() => handleButtonClick('Expos')}
                            >
                                <img src={box} alt="laara stays" className={getImageStyle('Expos')} />
                                <p className="text-xs sm:text-lg">Expos</p>
                            </button>

                            <button
                                className={getButtonStyle('Boardrooms')}
                                onClick={() => handleButtonClick('Boardrooms')}
                            >
                                <img src={car} alt="laara stays" className={getImageStyle('Boardrooms')} />
                                <p className="text-xs sm:text-lg">Boardrooms</p>
                            </button>
                        </span>
                    </div>

                    {/* Ellipses and Text */}
                    <div className="flex flex-col bottom-1 hidden lg:flex absolute right-1 text-black text-center ">
                        <h2 className="text-xl font-semibold text-black mb-2">Awesome places</h2>
                        <div className="flex items-center">
                            <div className="flex -space-x-4">
                                {Elipses.map(ellipse => (
                                    <div className=" w-28 h-28 rounded-full border-2 border-white overflow-hidden" key={ellipse.id}>
                                        <div className="w-40 h-40">
                                            <img src={ellipse.img} alt={`Ellipse ${ellipse.id}`} className=' w-full h-full object-cover' />
                                        </div>
                                    </div>
                                ))}
                            </div>
                            <div className="max-w-28 text-[#6D6D6D] font-normal text-sm text-center flex flex-col hidden xl:block">
                                <span>Discover The World!</span>
                                <span>One Adventure At A Time</span>
                            </div>
                        </div>
                    </div>
                </div>
            </header>
            </div> 
        </div>
    );
};

export default NewHero;