import React from "react";
import { useNavigate, useLocation } from "react-router-dom";
import arrowleft from "@/assets/shared/arrowleft.svg";
import GMaps from "@/components/shared/Maps/GMaps";

const ReservationOnMap = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { latitude, longitude } = location.state.address;
  const coordinates = { latitude, longitude };

  return (
    <section className="w-full mx-auto p-5 md:px-10 bg-[#FAFAFA] ">
      <section className="max-w-[1264px] w-full mx-auto">
        <section className="w-full space-y-5 ">
          {/* TOP SECTION */}
          <div className="flex items-center gap-2 md:gap-4 m ">
            <img
              src={arrowleft}
              alt="arrow-left icon"
              onClick={() => navigate(-1)}
              className="size-6 cursor-pointer"
            />
            <h4 className="text-lg font-medium text-[#626262] ">Map</h4>
          </div>

          {/* MAP SECTION */}
          <div className="xl:max-w-[1264px] h-[632px] w-full border ">
            <GMaps
              containerStyle={{ width: "100%", height: "632px" }}
              interactive={false}
              autoPlaces={false}
              pin={coordinates}
            />
          </div>
        </section>
      </section>
    </section>
  );
};

export default ReservationOnMap;
