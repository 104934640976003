import React from 'react';

const HostingAndCancellation = ({ hostingRules, name, cancellationPolicy }) => {
  return (
    <div className="p-4">
      <div className="flex flex-col md:flex-row gap-6">
        {/* Column 1: Hosting Rules */}
        <div className="flex-1  p-4 rounded-lg">
          <h3 className="text-xl font-semibold mb-2"> {name} Hosting Rules</h3>
          <ul className="list-disc list-inside text-gray-700">
            {hostingRules.map((rule, index) => (
              <li key={index} className="py-1">{rule}</li>
            ))}
          </ul>
        </div>

        {/* Vertical Separator */}
        <div className="hidden md:block w-px bg-gray-300"></div>

        {/* Column 2: Cancellation Policy */}
        <div className="flex-1 p-4 rounded-lg">
          <h3 className="text-xl font-semibold mb-2">Cancellation Policy</h3>
          <ul className="list-disc list-inside text-gray-700">
            {cancellationPolicy.map((policy, index) => (
              <li key={index} className="py-1">{policy}</li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default HostingAndCancellation;
