import success from "../../../assets/Overlays/Success.svg"

const ChangeSuccess = () => {
  return (
    <div className=" mx-auto flex flex-col justify-center rounded shadow-lg w-[606px] h-[532px] top-[153px] left-[365px]">
        <div className="mx-auto ">
            <img src={success} alt="" className="lg:w-[324px] lg:h-[293.45px] mb-2" />
        </div>
        <div className=" mx-auto">
            <h3 className="text-2xl text-center font-bold w-[420px] mb-2 h-[18px]">Password changed successfully</h3>
            <p className="text-2xl text-center lg:w-[420px] lg:h-[18px]">Proceed to login</p>
        </div>
    </div>
  )
}

export default ChangeSuccess
