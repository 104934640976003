import React from 'react';
import ProgressBar from './ProgressBar'; // Import the ProgressBar component
 
 function BookingPageFinal() {
   return (
    <div className='p-4 w-full'>
      <ProgressBar currentStep={3} />
      <div className="flex flex-col lg:flex-row gap-8 p-8">
        
        {/* Left Section */}
        <div className="lg:w-1/3 border border-gray-300 p-6 rounded-lg ">
          
          
          {/* Hotel Information */}
          <div className="mb-4 border  p-4 rounded-lg">
            <h2 className="text-lg font-semibold mb-4">Almost done! Just fill in the * required info</h2>
            <h3 className="text-lg font-semibold">Hotel</h3>
            <p className="text-gray-700">Golden Star Hotel</p>
            <p className="text-gray-500">Kiambu 00232, Kenya</p>
            <div className="flex flex-wrap gap-4 mt-2 text-gray-600">
              <span>📺 Flatscreen TV</span>
              <span>❄️ Air Conditioning</span>
              <span>🌐 Free WiFi</span>
              <span>🕒 24hrs Front Desk</span>
              <span>🅿️ Parking Area</span>
            </div>
          </div>
  
          {/* Booking Details */}
          <div className="mb-4 border p-4 rounded-lg">
            <h3 className="text-lg font-semibold">Your booking details</h3>
            <div className="flex justify-between text-gray-700">
              <div>
                <p>Check in</p>
                <p className="text-sm">Wed 7 Aug 2024</p>
                <p className="text-sm">13:00 - 20:00</p>
              </div>
              <div className='border-l-2 border-gray-400 '></div>
              <div>
                <p>Check out</p>
                <p className="text-sm">Wed 7 Aug 2024</p>
                <p className="text-sm">13:00 - 20:00</p>
              </div>
            </div>
            <div className="text-gray-700 mt-2 mb-2 font-semibold flex ">
              <span className='w-full'>Number of nights </span>
              <span className='flex justify-end w-full'>2 Nights</span>
              </div>
            <hr  />
            <div className='mt-3 mb-2'>
              <h3 className='font-semibold'>Your Selection</h3>
              <span>1 room for 2 Adults</span>
            </div>
            <p className="text-blue-600 mt-2 underline">Change your selection</p>
          </div>
  
          {/* Price Summary */}
          <div className="mb-4 border p-4 rounded-lg ">
            <h3 className="text-lg font-semibold">Your price summary</h3>
            <div className="bg-blue-100 p-4 rounded-lg">
              <div className='flex gap-11'>
                <p className="font-semibold">Total Price</p>
                <div className=' ml-5'>
                  <span>Ksh 6450</span>
                  <p className="text-sm">Including taxes & charges</p>
                  <p className="text-sm">In property currency: US$50</p>
                </div>
              </div>
              
            </div>
            <p className="font-semibold mt-2 text-sm mb-2">Price Details</p>
            <p className="text-xs">
              Includes KES 950.05 in taxes and charges<br/>
              <div className='flex gap-14 pt-2 pb-2'>
                <div>
                16% VAT KES 844.49<br/>
                KES 844.49
                </div>
                <div>
                2% City tax KES 105.5<br/>
                KES 105.55
                </div>
              </div>
              This price is converted to show you the approximate cost in KES. You'll pay in US$ or KES. The exchange rate may change before you pay.
            </p>
            <p className="text-blue-600 mt-2 underline text-sm">Hide Details</p>
          </div>
  
          {/* Payment Schedule */}
          <div className="mb-4 border p-4 rounded-lg">
            <h3 className="text-lg font-semibold">Payment Schedule</h3>
            <p className="text-gray-500 text-sm">No payment today, you will pay when you stay</p>
          </div>
  
          {/* Cancellation Fee */}
          <div className="mb-4 border p-4 rounded-lg">
            <h3 className="text-lg font-semibold">Cancellation fee</h3>
            <p className="text-gray-500 text-sm">Free cancellation before 18:00 on the day of arrival</p>
          </div>
        </div>
  
        {/* Right Section */}
        <div className="lg:w-2/3  p-6 rounded-lg border border-gray-300">
        <div className='border p-4 rounded-lg'>
          <h2 className="text-lg font-semibold mb-4">Choose how to pay</h2>
  
          <div className="space-y-4">
            {/* Pay Now Option */}
            <div className=" p-4 ">
              <input type="radio" name="payment" id="pay-now" className="mr-2" />
              <label htmlFor="pay-now" className="text-gray-700">
                Pay now
              </label>
              <p className="text-sm text-gray-500 mt-1">Pay total fee of $1,200 for reservation</p>
              <p className="text-sm text-gray-500 mt-1">
                Pay in full upfront and unlock property discounts, potential refunds (subject to cancellation policy), and even discounts on Loara rides.
              </p>
              <a href="#" className="text-blue-600 text-sm underline mt-2 block">See less</a>
            </div>
            <hr />
  
            {/* Pay at Particular Time & Date Option */}
            <div className=" p-4 ">
              <input type="radio" name="payment" id="pay-later" className="mr-2" />
              <label htmlFor="pay-later" className="text-gray-700">
                Pay at particular time & date
              </label>
              <p className="text-sm text-gray-500 mt-1">
                Pay total fee of $1,200 at your preferred date & time
              </p>
              <a href="#" className="text-blue-600 text-sm underline mt-2 block">See more</a>
            </div>
  
            {/* Pay at the Property Option */}
            <div className=" p-4">
              <input type="radio" name="payment" id="pay-property" className="mr-2" defaultChecked />
              <label htmlFor="pay-property" className="text-gray-700">
                Pay at the Property
              </label>
              <p className="text-sm text-gray-500 mt-1">
                Pay $180 (15%) of total booking fee and the balance later
              </p>
              <a href="#" className="text-blue-600 text-sm underline mt-2 block">See more</a>
            </div>
            <hr />
          </div>
        </div>

          {/* Make Payments Button */}
          <div className="mt-6 flex justify-end ">
            <button className=" text-blue-500 py-2 px-4 border border-b-2 border-blue-400 rounded-full hover:text-white hover:bg-blue-600">
              Make payments
            </button>
          </div>
        </div>
      </div>
     </div>
   );
 }
 
 export default BookingPageFinal;
 
