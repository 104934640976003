import axios from "axios";

// Base URL for the API
const BASE_URL = "https://dev.api.laaragroup.com";

// Axios instance for API requests
const api = axios.create({
  baseURL: BASE_URL,
  headers: {
    accept: "application/json",
    "x-app-id": "3a2f3e5b-4a89-4fcb-a7e1-31421c7a6344",
  },
});

export const getToken = () => {
  // Retrieve the state from localStorage
  const persistedState = localStorage.getItem("persist:root");
  if (!persistedState) {
    return null;
  }
  try {
    // Parse the serialized state
    const state = JSON.parse(persistedState);
    // Extract the user object from the state
    const userState = state.user ? JSON.parse(state.user) : {};

    // Return the accessToken if available
    return userState.accessToken || null;
  } catch (error) {
    throw new Error("Error parsing persisted state:", error);
    // return null;
  }
};
export const getHostId = () => {
  // Retrieve the state from localStorage
  const persistedState = localStorage.getItem("persist:root");
  if (!persistedState) {
    return null;
  }
  try {
    // Parse the serialized state
    const state = JSON.parse(persistedState);
    // Extract the user object from the state
    const userState = state.user ? JSON.parse(state.user) : {};

    // Return the accessToken if available
    return userState.uid || null;
  } catch (error) {
    return "Error parsing persisted state:", error;
  }
};

// Function to get host profile
export const getHostProfile = async () => {
  const token = getToken(); 
  if (!token) {
    throw new Error("No authentication token found.");
  }

  try {
    const response = await api.get("/users/host/profile", {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    const { status, user } = response.data;

    if (!status) {
      throw new Error("Failed to fetch host profile. Status not true.");
    }

    if (!user || !user.properties) {
      throw new Error(
        "Invalid response structure. User data or properties not found."
      );
    }

    return { user }; // Return the user object wrapped in an object
  } catch (error) {
    if (error.response) {
      throw new Error("API Error:", error.response.data);
    } else {
      throw new Error("Error:", error.message);
    }
    // throw new Error("Error fetching host profile: " + error.message);
  }
};

// Function to get user profile
export const getUserProfile = async () => {
  const token = getToken(); // Retrieve the token from localStorage
  if (!token) {
    throw new Error("No authentication token found.");
  }

  try {
    const response = await api.get("/users/profile", {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    const { status, user } = response.data;

    if (!status) {
      throw new Error("Failed to fetch user profile. Status not true.");
    }

    if (!user) {
      throw new Error("Invalid response structure. User data not found.");
    }

    return { user }; // Return the user object wrapped in an object
  } catch (error) {
    if (error.response) {
      throw new Error("API Error:", error.response.data);
    } else {
      throw new Error("Error:", error.message);
    }
    // throw new Error("Error fetching user profile: " + error.message);
  }
};

// Function to fetch a property by its ID
export const getPropertyById = async (id) => {
  const token = getToken();
  if (!token) {
    throw new Error("No authentication token found.");
  }

  try {
    const response = await api.get(`/properties/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    return response.data;
  } catch (error) {
    if (error.response) {
      throw new Error("API Error:", error.response.data);
    } else {
      throw new Error("Error: " + error.message);
    }
    // throw new Error("Error fetching property by ID: " + error.message);
  }
};
//function to search property by Id
export const searchPropertyById = async (id) => {
  const token = getToken();
  if (!token) {
    throw new Error("No authentication token found.");
  }

  try {
    const response = await api.get(`/search/stays/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    // Return the response data
    return response.data;
  } catch (error) {
    if (error.response) {
      throw new Error("API Error: " + error.response.data.message);
    } else {
      throw new Error("Error: " + error.message);
    }
  }
};

// Function to fetch host properties and their details using the property IDs
export const fetchHostProperties = async () => {
  const token = getToken();
  if (!token) {
    throw new Error("No authentication token found.");
  }

  try {
    // Fetch the host profile, which contains the property IDs
    const response = await api.get("/users/host/profile", {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    const { status, user } = response.data;

    if (!status) {
      throw new Error("Failed to fetch host profile.");
    }

    // Check if the profile contains properties
    if (!user || !Array.isArray(user.properties)) {
      throw new Error("Invalid response structure. Properties not found.");
    }

    // Extract the property IDs
    const ids = user.properties.map((property) => property.id);

    // Fetch details for each property using the property IDs
    const propertyDetailsPromises = ids.map((id) => getPropertyById(id));

    // Wait for all property details to be fetched
    const propertiesDetails = await Promise.all(propertyDetailsPromises);

    return propertiesDetails;
  } catch (error) {
    // Log the full error response for debugging
    if (error.response) {
      throw new Error("API Error:", error.response.data);
    } else {
      throw new Error("Error: " + error.message);
    }
  }
};

// Base function to edit property details
export const editPropertyDetails = async (id, data) => {
  const token = getToken();
  if (!token) {
    throw new Error("No authentication token found.");
  }

  try {
    const response = await api.put(`/properties/${id}`, data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    return response.data;
  } catch (error) {
    if (error.response) {
      throw new Error("API Error:", error.response.data);
    } else {
      throw new Error("Error:", error.message);
    }
    // throw new Error("Error updating property details: " + error.message);
  }
};

// Edit property name
export const editPropertyName = async (id, data) => {
  const token = getToken(); // Retrieve the token from localStorage
  if (!token) {
    throw new Error("No authentication token found.");
  }

  const url = `/properties/${id}`; // URL relative to the base API

  try {
    const response = await api.put(
      url,
      { name: data.name }, // Sending the property name in the request body
      {
        headers: {
          Authorization: `Bearer ${token}`, // Set the Authorization header
          "Content-Type": "application/json", // Set content type to JSON
        },
      }
    );

    return response.data; // Return response data from the API
  } catch (error) {
    // Handle the error properly
    if (error.response) {
      throw new Error("API Error:", error.response.data);
    } else {
      throw new Error("Error:", error.message);
    }
    // throw new Error("Failed to update property name");
  }
};

// Edit property description
export const editPropertyDescription = async (id, description) => {
  const token = getToken(); // Get the token from localStorage
  if (!token) {
    throw new Error("No authentication token found.");
  }

  const url = `/properties/${id}`; // URL relative to the base API

  try {
    const response = await api.put(
      url,
      { description }, // Sending the description in the request body
      {
        headers: {
          Authorization: `Bearer ${token}`, // Set the Authorization header
          "Content-Type": "application/json", // Set content type to JSON
        },
      }
    );

    return response.data; // Return response data from API
  } catch (error) {
    // Handle the error properly
    if (error.response) {
      throw new Error("API Error:", error.response.data);
    } else {
      throw new Error("Error:", error.message);
    }
    // throw new Error("Failed to update property description");
  }
};

const validateAccessibilityData = (data) => {
  if (!data || typeof data !== "object") {
    throw new Error("Invalid data format");
  }

  // Ensure accessibilityFeatures is an array and contains valid objects
  const validAccessibilityFeatures = data.accessibilityFeatures?.map(
    (feature) => ({
      feature: feature.feature || "",
      category: feature.category || "General",
    })
  );

  return {
    ...data,
    accessibilityFeatures: validAccessibilityFeatures || [],
  };
};

export const updatePropertyAccessibilityFeatures = async (id, data) => {
  const token = getToken(); // Get the token from localStorage
  if (!token) {
    throw new Error("No authentication token found.");
  }

  const url = `/properties/${id}/accessibility-features`; // URL relative to the base API

  try {
    // Validate the data structure before sending it
    const validData = validateAccessibilityData(data);

    // console.log("Data being sent:", validData);

    const response = await api.put(
      url,
      validData, // Send the validated data
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    );

    // console.log("API response:", response.data); // Log the API response for debugging

    return response.data; // Return response data from API
  } catch (error) {
    // Handle the error properly
    if (error.response) {
      throw new Error("API Error:", error.response.data);
      // throw new Error(
      //   `API Error: ${error.response.data.message || error.response.data}`
      // );
    } else {
      throw new Error("Error:", error.message);
      // throw new Error(`Error: ${error.message}`);
    }
  }
};

// Function to clean the address data by removing null or empty values
const cleanData = (data) => {
  const cleanedData = {};
  for (const key in data) {
    if (data[key] !== null && data[key] !== undefined) {
      // Remove empty string check
      cleanedData[key] = data[key];
    }
  }
  return cleanedData;
};

export const updatePropertyAddress = async (id, addressData, token) => {
  try {
    const cleanedAddressData = cleanData(addressData);
    // console.log("Request data:", JSON.stringify(cleanedAddressData, null, 2));

    // Ensure latitude and longitude are numbers
    if (typeof cleanedAddressData.latitude === "string") {
      cleanedAddressData.latitude = parseFloat(cleanedAddressData.latitude);
    }
    if (typeof cleanedAddressData.longitude === "string") {
      cleanedAddressData.longitude = parseFloat(cleanedAddressData.longitude);
    }

    await api.put(
      `/properties/${id}/address`,
      { address: cleanedAddressData },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
  } catch (error) {
    // throw new Error("Error updating property address:", error);
    if (error.response && error.response.data) {
      throw new Error("API Error Response Data:", error.response.data);
    } else {
      throw new Error("No detailed error response from the API", error);
    }
    // throw error;
  }
};

//Update Room Details
export const updateRoomDetails = async (id, updatedDetails) => {
  try {
    await api.put(`/properties/${id}/rooms`, updatedDetails, {
      headers: {
        Authorization: `Bearer ${getToken()}`,
        "Content-Type": "application/json",
      },
    });
  } catch (error) {
    throw new Error("Error saving room details:", error);
    // throw error;
  }
};

//update propertylanguage
export const updatePropertyLanguages = async (propertyId, requestPayload) => {
  const token = getToken(); // Get the token from localStorage or some other secure storage
  if (!token) {
    throw new Error("No authentication token found.");
  }

  const url = `/properties/${propertyId}/languages`; // Relative URL if your base URL is set in the Axios instance

  try {
    await api.put(url, requestPayload, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    });
  } catch (error) {
    // Handle the error properly
    if (error.response) {
      throw new Error("API Error:", error.response.data);
    } else {
      throw new Error("Error:", error.message);
    }
    // throw new Error("Failed to update property languages");
  }
};

//update mealplans
export const updateMealPlans = async (id, mealPlansData, token) => {
  try {
    await api.put(`${BASE_URL}/properties/${id}/meal-plans`, mealPlansData, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    });
  } catch (error) {
    throw new Error("Error updating meal plans:", error);
    // throw error;
  }
};

//update parking
export const updatePropertyParking = async (id, parkingDetails) => {
  const token = getToken(); // Retrieve the token from localStorage
  if (!token) {
    throw new Error("No authentication token found.");
  }

  try {
    // Construct the amenities payload with the required structure
    const amenitiesData = {
      amenities: [
        {
          name: parkingDetails.parkingAvailable || "Parking", // Use the name or default to "Parking"
          description: parkingDetails.paidParking
            ? `Paid parking available: ${parkingDetails.paidParking.cost} ${parkingDetails.paidParking.time}`
            : parkingDetails.parkingAvailable || "No parking available",
          category: "Parking",
          icon: "parking-icon-url", // Adjust this if you have specific icons or leave it blank
        },
        // Add other amenities here if needed
      ],
    };

    const response = await api.put(
      `/properties/${id}/amenities`,
      amenitiesData,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    );

    return response.data; // Return response data from the API
  } catch (error) {
    // Handle the error properly
    if (error.response) {
      // throw new Error("API Error:", error.response.data);
      throw new Error(
        `API Error: ${error.response.data.message || error.response.data}`
      );
    } else {
      throw new Error("Error:", error.message);
    }
  }
};

// Update safety amenities function
export const updatePropertySafetyFacilities = async (id, data) => {
  const token = getToken();
  if (!token) {
    throw new Error("No authentication token found.");
  }

  if (!data || !Array.isArray(data.amenities)) {
    throw new Error("Invalid data provided.");
  }

  const payload = {
    amenities: data.amenities.map((option) => ({
      name: option,
      category: "Safety",
    })),
  };

  try {
    const response = await api.put(`/properties/${id}/amenities`, payload, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    // console.log("API response:", response.data);
    return response.data;
  } catch (error) {
    if (error.response) {
      // throw new Error("API Error:", error.response.data);
      throw new Error(
        `API Error: ${error.response.data.message || error.response.data}`
      );
    } else {
      throw new Error("Error:", error.message);
    }
  }
};

// Update property policies
export const updatePropertyPolicies = async (id, policiesData) => {
  const token = getToken(); // Get the token from localStorage
  if (!token) {
    throw new Error("No authentication token found.");
  }

  const url = `/properties/${id}/policies`;
  // console.log(
  //   "Policies data being sent:",
  //   JSON.stringify({ policies: policiesData }, null, 2)
  // ); // Wrap policiesData in an object

  try {
    const response = await api.put(
      url,
      { policies: policiesData }, // Send the policiesData inside an object
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    );

    // console.log("API response:", response.data); // Log the API response for debugging

    return response.data; // Return response data from API
  } catch (error) {
    // Handle the error properly
    if (error.response) {
      // throw new Error("API Error:", error.response.data);
      throw new Error(
        `API Error: ${error.response.data.message || error.response.data}`
      );
    } else {
      throw new Error("Error:", error.message);
    }
  }
};

// Function to update property amenities
export const updateAmenities = async (id, amenitiesData) => {
  const token = getToken();
  if (!token) {
    throw new Error("No authentication token found.");
  }

  // Validate data
  if (!amenitiesData || typeof amenitiesData !== "object") {
    throw new Error("Invalid amenitiesData");
  }

  try {
    // console.log("Sending amenities data:", amenitiesData);
    const response = await api.put(
      `/properties/${id}/amenities`,
      amenitiesData,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    );

    // console.log("API Response:", response.data);
    return response.data;
  } catch (error) {
    if (error.response) {
      throw new Error(
        "API Error Response:",
        error.response.data,
        "Error Status:",
        error.response.status
      );
      // throw new Error("Error Status:", error.response.status);
    } else {
      throw new Error("Error:", error.message || error.response?.data?.message);
    }
    // throw new Error(
    //   "Error updating property amenities: " +
    //     (error.response?.data?.message || error.message)
    // );
  }
};

//update room bathroom
export const updateRoomBathroom = async (propertyId, typeId, bathroomData) => {
  try {
    const response = await api.put(
      `/properties/${propertyId}/room-type/${typeId}/bathroom`,
      bathroomData,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("authToken")}`,
          "Content-Type": "application/json",
        },
      }
    );
    return response.data;
  } catch (error) {
    throw new Error(error.response?.data.message || error.message);
  }
};

// Function to fetch property data
export async function fetchPropertyById(id) {
  try {
      // Axios GET request using the custom Axios instance
      const response = await api.get(`${BASE_URL}/search/stays/${id}`);
      return response.data;
  } catch (error) {
      console.error('Error fetching property data:', error);
      throw error;
  }
}
export const uploadRoomImages = async (propertyId, roomTypeId, formData) => {
  try {
    const token = getToken();
    const response = await api.put(
      `/properties/${propertyId}/room-type/${roomTypeId}/images`,
      formData,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
        },
      }
    );

    return response.data;
  } catch (error) {
    throw new Error(
      "Error uploading room images:",
      error.response ? error.response.data : error.message
    );
    // throw error;
  }
};


// utils/api.js or services/api.js


export const makeBooking = async (bookingData) => {
  try {
    const response = await api.post('/booking', bookingData);

    return response.data; // Axios automatically parses JSON
  } catch (error) {
    console.error('An error occurred:', error);
  }
};

export const uploadPropertyPhotos = async (id, formData) => {
  try {
    const token = getToken();
    const response = await api.put(
      `/properties/${id}/images`, formData,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
        },
      }
    );
    return response;
  } catch (error) {
    console.error("Error uploading images:", error.response ? error.response.data : error.message);

    throw error;
  }
};


export const uploadFoodPhotos = async (id, formData) => {
  try {
    const token = getToken();
    const response = await api.put(
      `/properties/${id}/food-images`, formData,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
        },
      }
    );
    return response;
  } catch (error) {
    console.error("Error uploading images:", error.response ? error.response.data : error.message);
    throw error;
  }
}

export const uploadStaffPhotos = async (id, formData) => {
  try {
    const token = getToken();
    const response = await api.put(
      `/properties/${id}/staff-images`, formData,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
        },
      }
    );
    return response;
  } catch (error) {
    console.error("Error uploading images:", error.response ? error.response.data : error.message);
    throw error;
  }
}

