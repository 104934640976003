import rubywomen from "@/assets/ruby/rubywomen.png";
import Secondary from "@/assets/ruby/Secondary.png";
import { Link } from "react-router-dom";

const Ruby = () => {
  return (
    <section className="w-full mx-auto bg-laara-secondary">
      <section className="ruby-padding mx-auto xl:px-[88px] w-full  ">
        <div className="bg-ruby-vector max-w-auto h-[636px] md:max-w-screen xl:max-h-[370px] xl:h-full rounded-[12px] relative overflow-hidden">
          <img
            src={Secondary}
            alt="ruby"
            className="w-[8rem] md:w-[10rem] h-auto object-contain absolute top-4 right-[1rem] md:right-5 md:top-8 "
          />
          <div>
            <div className="">
              <img
                src={rubywomen}
                className="max-w-[500px] h-auto md:max-w-[581px] absolute -right-2 bottom-0 md:right-0"
              />
            </div>
            <article className="max-w-[597px] text-laara-secondary px-[20px] pt-[60px] md:px-[46px] md:pt-[5rem] xl:py-[46px] xl:pl-[46px]">
              <h4 className="ruby-head">
                Receive special discounts on your{" "}
                <span className="ruby-head-span">
                  Accommodation Bookings & Hosted Properties
                </span>
              </h4>

              <p className="ruby-sub">
                Register or Sign In and Subscribe to the Ruby Loyalty Program to
                access exclusive benefits
              </p>

              <div className="flex items-center gap-3">
                <Link to="/register" className="ruby-register-btn ">
                  Register
                </Link>
                <Link to="/login" className="ruby-sign-in-btn">
                  Sign In
                </Link>
              </div>
            </article>
          </div>
        </div>
      </section>
    </section>
  );
};

export default Ruby;
