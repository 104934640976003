import { Link } from "react-router-dom";
import { useState, useEffect } from "react";
import arrowleft from "../../assets/shared/arrowleft.svg";
import plus from "../../assets/shared/plus.svg";
import { Dialog, DialogContent, DialogTrigger } from "@/components/ui/dialog";
import { Input } from "@/components/ui/input";
import { useNavigate } from "react-router-dom";
import { roomImage } from "@/constants/data";
import axios from "axios";
import { baseURL, headers } from '@/constants/apiconfig';
import { useSelector } from "react-redux";
export default function Wishlist() {
  const [listName, setListName] = useState('');
  const [createdListName, setCreatedListName] = useState('');
  const [wishlists, setWishlists] = useState([]);
  const [isEmpty, setIsEmpty] = useState(true);
  const navigate = useNavigate();
  const { currentUser, accessToken } = useSelector((state) => state.user);
  const token = currentUser?.stsTokenManager?.accessToken || accessToken;
  useEffect(() => {
    // Fetch the wishlist data from the API
    axios.get(`${baseURL}/users/wishlist`, {
      headers: headers(token),
    })
      .then((response) => {
        const { wishlists } = response.data;
        setWishlists(wishlists);
        setIsEmpty(wishlists.length === 0);  // Check if the wishlists array is empty
      })
      .catch((error) => {
        console.error("Error fetching wishlists:", error);
      });
  }, []);

  const handleInputChange = (event) => {
    setListName(event.target.value);
  };

  const handleCreateList = () => {
    setCreatedListName(listName);
  };

  return (
    <section className="mx-auto max-w-[1440px]">
      <section className="max-w-[1264px] w-full mx-auto space-y-7 max-sm:px-5 p-5 py-[60px]">
        <div className="flex flex-col mt-10 max-w-full">
          <div className="flex gap-5 w-full md:w-[250px]">
            <button className="flex justify-center items-center" onClick={() => navigate(-1)}>
              <img loading="lazy" src={arrowleft} className="w-8 aspect-square" />
            </button>
            <span className="flex-auto text-3xl font-semibold leading-10 text-zinc-600">
              Wishlists
            </span>
          </div>
          {isEmpty ? (
            <div className="justify-center self-start mt-10 w-full md:w-[581px]">
              <div className="flex gap-5 flex-col md:flex-row md:gap-0">
                <div className="flex flex-col w-full md:w-[45%]">
                  <div className="flex flex-col grow justify-center self-stretch py-16">
                    <div className="flex flex-col justify-center">
                      <span className="text-3xl font-medium tracking-normal leading-9 text-zinc-800">
                        You have no items on your wishlists
                      </span>
                      <span className="mt-4 text-lg tracking-normal leading-7 text-zinc-500">
                        Save apartments to your wishlist for easy booking later
                      </span>
                    </div>
                  </div>
                </div>
                <div className="flex flex-col w-full md:ml-5 md:w-[55%]">
                  <Link to="/" className="flex flex-col grow justify-center px-16 py-20 w-full text-xl leading-6 rounded-3xl bg-neutral-200 bg-opacity-50 text-stone-500 md:px-5 mt-10 md:mt-0">
                    <div className="flex flex-col mt-14 items-center">
                      <img loading="lazy" src={plus} className="self-center w-11 aspect-square" />
                      <span>Create a new list</span>
                    </div>
                  </Link>
                </div>
              </div>
            </div>
          ) : (
            <div className="flex gap-5 flex-col md:flex-row mt-10 w-3/4">
              {wishlists.map((wishlist, index) => (
                <div key={index} className="flex flex-col w-full ">
                  <Link to='/allwishes' className="flex flex-col grow w-full bg-white leading-[120%] rounded-[36px] md:mt-6">
                    <img
                      loading="lazy"
                      src={wishlist.properties.image || roomImage}
                      className="w-full h-3/4 rounded-xl sm:w-[400px]"
                    />
                    <div className="flex flex-col mt-4">
                      <div className="text-2xl font-semibold text-sky-600">
                        {wishlist.name}
                      </div>
                      <div className="mt-2.5 text-lg text-stone-500">
                        {wishlist.count} saved stays
                      </div>
                    </div>
                  </Link>
                </div>
              ))}
              <div className="flex flex-col w-full md:ml-5 ">
                <Dialog>
                  <DialogTrigger asChild>
                    <button className="items-center flex flex-col grow justify-center px-16 py-20 w-full sm:max-md:w-[400px] text-xl leading-6 rounded-3xl bg-neutral-200 bg-opacity-50 text-stone-500 md:px-5 mt-6">
                      <div className="flex flex-col mt-14 items-center">
                        <img loading="lazy" src={plus} className="self-center w-11 aspect-square" />
                        <span>Create a new list</span>
                      </div>
                    </button>
                  </DialogTrigger>
                  <DialogContent className="flex flex-col px-8 pt-5 pb-20 bg-white rounded-3xl shadow-sm max-w-[564px] md:px-5">
                    <div className="flex flex-col justify-center p-2.5">
                      <div className="self-center mt-24 text-2xl font-medium text-center text-zinc-800 md:mt-10">
                        Name this wishlist
                      </div>
                    </div>
                    <div className="flex flex-col mt-9 text-base">
                      <div className="flex gap-5 justify-between flex-wrap">
                        <div className="text-zinc-800">List Name</div>
                        <div className="text-zinc-600">0/40</div>
                      </div>
                      <Input
                        id="listName"
                        value={listName}
                        onChange={handleInputChange}
                        placeholder="Enter list name"
                        className="w-full p-2 border rounded-3xl focus:outline-none focus:ring focus:ring-blue-500 h-20 mt-4"
                      />
                    </div>
                    <div className="flex flex-col justify-center mt-9 text-base text-center text-white">
                      <button
                        onClick={handleCreateList}
                        className="justify-center items-center px-16 py-6 bg-sky-600 rounded-full md:px-5 w-full text-white hover:bg-sky-700 focus:outline-none focus:ring focus:ring-blue-500"
                      >
                        Create
                      </button>
                    </div>
                  </DialogContent>
                </Dialog>
              </div>
            </div>
          )}
        </div>
      </section>
    </section>
  );
}
