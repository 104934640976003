import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams, useLocation } from "react-router-dom";
import arrowleft from "@/assets/shared/arrowleft.svg";
import locationicon from "@/assets/shared/location.svg";
import scanbarcode from "@/assets/shared/scan-barcode.svg";
import avatar from "@/assets/shared/avatar.svg";
import axios from "axios";
import { useQuery } from "@tanstack/react-query";
import { headers, baseURL } from "@/constants/apiconfig";
import { getStatus, getStatusBadgeColor } from "@/lib/utils";
import dayjs from "dayjs";
import Pay from "@/components/shared/PayWith/Pay";

import { Heart } from "lucide-react";
import { useSelector, useDispatch } from "react-redux";
import { launchPayModal } from "@/redux/paynow/PayNowSlice";
import GMaps from "@/components/shared/Maps/GMaps";

import { TbAirConditioning, TbSmokingNo } from "react-icons/tb";
import { IoIosWifi } from "react-icons/io";
import {
  MdHeatPump,
  MdSmokingRooms,
  MdOutlineFamilyRestroom,
  MdOutlineHelp,
  MdOutlineTv,
  MdKingBed,
} from "react-icons/md";
import { GiSittingDog, GiHanger, GiTowel, GiComputerFan } from "react-icons/gi";
import { FaBellConcierge } from "react-icons/fa6";
import { BiBlanket, BiCloset } from "react-icons/bi";
import { BsPersonWorkspace } from "react-icons/bs";
import { FcSafe } from "react-icons/fc";
import { TbStairsDown } from "react-icons/tb";
import CompleteReservation from "@/components/ReservationPage/CompleteReservation";

const ReservationDetails = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const { status, address } = location.state || {};

  // console.log(address);
  const { latitude, longitude, physicalAddress } = address || null;

  const token = useSelector((state) => state.user?.accessToken);

  // STATE OF PAY MODAL
  const payModal = useSelector((state) => state.payNowModal.openPayModal);
  const paidReservation = useSelector(
    (state) => state.payNowModal.reservationComplete
  );

  // console.log(payModal, paidReservation);

  const fetchUserReservations = async () => {
    const res = await axios.get(`${baseURL}/users/reservations/${id}`, {
      headers: headers(token),
    });
    return res.data;
  };

  const resrervationQuery = useQuery({
    queryKey: ["reservation"],
    queryFn: fetchUserReservations,
    retry: 0,
  });

  const { data, isLoading, isError, isSuccess } = resrervationQuery;

  // GET GENERAL AMENITIES OF PROPERTY
  const genAmenities =
    data?.reservation?.rooms?.properties?.propertyAmenities?.filter(
      (amenities) => amenities.amenities.category === "General"
    );

  // GET GENERAL ROOM AMENITIES OF PROPERTY
  const roomAmenities =
    data?.reservation?.rooms?.roomTypes?.roomTypeAmenities.filter(
      (amenities) => amenities.amenities.category === "General"
    );
  // console.log(genAmenities, roomAmenities);

  const checkInDate = dayjs(data?.reservation?.startDate).format("DD MMM YYYY");
  const checkOutDate = dayjs(data?.reservation?.endDate).format("DD MMM YYYY");

  // const startDate = data?.reservation?.startDate;
  // const endDate = data?.reservation?.endDate;

  const start = dayjs(checkInDate);
  const end = dayjs(checkOutDate);

  // Calculate the difference in days
  const duration = end.diff(start, "day");

  const generalAmenitiesIcons = {
    "free wi-fi": <IoIosWifi className="size-6 text-[#888888] " />,
    "air conditioning": (
      <TbAirConditioning className="size-6 text-[#888888] " />
    ),
    heating: <MdHeatPump className="size-6 text-[#888888] " />,
    "non-smoking rooms": <TbSmokingNo className="size-6 text-[#888888] " />,
    "smoking rooms": <MdSmokingRooms className="size-6 text-[#888888] " />,
    "family rooms": (
      <MdOutlineFamilyRestroom className="size-6 text-[#888888] " />
    ),
    "pet-friendly accomodations": (
      <GiSittingDog className="size-6 text-[#888888] " />
    ),
    "24 hour front desk": <MdOutlineHelp className="size-6 text-[#888888] " />,
    "concierge service": <FaBellConcierge className="size-6 text-[#888888] " />,
    "room service": <FaBellConcierge className="size-6 text-[#888888] " />,
  };

  const roomAmenitiesIcons = {
    "clothes rack": <GiHanger className="size-5 text-[#888888] " />,
    "flat screen tv": <MdOutlineTv className="size-5 text-[#888888] " />,
    "air conditioning": (
      <TbAirConditioning className="size-5 text-[#888888] " />
    ),
    linen: <BiBlanket className="size-5 text-[#888888] " />,
    "workspace desk": <BsPersonWorkspace className="size-5 text-[#888888] " />,
    "wake-up service": <MdKingBed className="size-5 text-[#888888] " />,
    towels: <GiTowel className="size-5 text-[#888888] " />,
    "wardrobe or closet": <BiCloset className="size-5 text-[#888888] " />,
    heating: <MdHeatPump className="size-5 text-[#888888] " />,
    fan: <GiComputerFan className="size-5 text-[#888888] " />,
    "safety deposit box": <FcSafe className="size-5 text-[#888888] " />,
    "Entire unit located on ground floor": (
      <TbStairsDown className="size-5 text-[#888888] " />
    ),
  };

  const mapsContainer = { width: "100%", height: "169px" };
  const mapsCoordinates = { latitude: latitude, longitude: longitude };

  return (
    <section className=" w-full mx-auto p-5 md:px-10 bg-[#FAFAFA] ">
      <section className="max-w-[1264px] w-full mx-auto">
        <section className=" w-full space-y-5 relative ">
          {/* TOP SECTION */}
          <div className="flex items-center gap-2  ">
            <img
              src={arrowleft}
              alt="arrow-left icon"
              onClick={() => navigate(-1)}
              className="size-6 cursor-pointer"
            />
            <h4 className="text-xl lg:text-lg font-medium text-[#626262] ">
              Reservation Details
            </h4>
          </div>

          {/* PAY NOW MODAL */}
          {payModal && (
            <div className="fixed inset-0 flex items-center justify-center bg-black/50  z-50">
              <div className="bg-white py-2 px-4 rounded-lg shadow-lg w-full md:max-w-[632px] lg:max-w-[844px] ">
                <Pay
                  price={data?.reservation?.totalPrice}
                  checkIn={checkInDate}
                  checkOut={checkOutDate}
                />
              </div>
            </div>
          )}

          {/* COMPLETE RESERVATION MODAL */}
          {paidReservation && (
            <div className="fixed inset-0 flex items-center justify-center bg-black/50  z-50">
              <div className="w-full md:max-w-[413px]  ">
                <CompleteReservation
                  propertyName={data?.reservation?.rooms?.properties?.name}
                  checkIn={checkInDate}
                  checkOut={checkOutDate}
                />
              </div>
            </div>
          )}

          {/* MAIN SECTION */}
          <section className="flex flex-col lg:flex-row xl:flex-row items-start justify-between gap-6 lg:gap-6 xl:gap-[64px]  ">
            {/* RIGHT SIDE */}
            <div className="max-w-[519px] w-full space-y-6 ">
              {/* CONTAINER */}
              <div className=" p-4 rounded-[8px] flex flex-col lg:flex-row xl:flex-row  border border-[#62626233/20] ">
                {/* IMAGES */}
                <div className="w-full lg:w-[156px] flex flex-wrap items-start gap-2 md:gap-3 ">
                  {data?.reservation?.rooms?.roomTypes?.roomTypeImages?.map(
                    (item, index) => (
                      <img
                        key={index}
                        src={item.images?.url}
                        alt="listing image"
                        className="w-[140px] h-[120px] object-cover rounded-[12px] "
                      />
                    )
                  )}
                </div>

                {/* BOOKING INFO */}
                <div className="py-2 lg:p-2 space-y-4  ">
                  <div>
                    <h4 className="text-base font-medium text-[#383838] capitalize ">
                      {data?.reservation?.rooms?.properties?.name}
                    </h4>
                  </div>

                  {/* LOCATION INFO */}
                  <div>
                    <div className="flex items-start justify- gap-2">
                      <img
                        src={locationicon}
                        alt="location icon"
                        className="size-5"
                      />
                      <p className="text-base text-[#4F4F4F] capitalize ">
                        {physicalAddress},{" "}
                        {data?.reservation?.rooms?.properties?.address?.county},{" "}
                        {data?.reservation?.rooms?.properties?.address?.country}
                      </p>
                    </div>
                  </div>

                  {/* MAP PREVIEW */}
                  <div className="space-y-2">
                    <p className="text-base font-medium text-[#3C3C3C] ">
                      Map Preview
                    </p>
                    <div className="w-full md:w-[287px] h-[169px] relative">
                      <GMaps
                        containerStyle={mapsContainer}
                        interactive={false}
                        placesAutoComplete={false}
                        pin={mapsCoordinates}
                      />
                      <Link
                        to={{ pathname: "/reservationlocation" }}
                        state={{
                          address: mapsCoordinates,
                        }}
                        className="py-1.5 px-2 text-xs text-laara-primary bg-laara-secondary rounded-[6px] absolute top-2 left-2 "
                      >
                        View larger map
                      </Link>
                    </div>
                  </div>

                  {/* AMENITIES */}
                  <div>
                    <p className="text-[#3C3C3C] text-base font-medium ">
                      General Amenities
                    </p>
                    {genAmenities?.map((amenity, index) => (
                      <div
                        key={index}
                        className="flex items-center gap-2.5 capitalize"
                      >
                        <span className=" text-gray-500">
                          {generalAmenitiesIcons[amenity.amenities.name]}
                        </span>
                        <span className="text-base text-[#4F4F4F] ">
                          {amenity.amenities.name}
                        </span>
                      </div>
                    ))}
                  </div>
                </div>
              </div>

              {/* ROOM DETAILS */}
              <div className="space-y-2 border border-[#62626233/20] rounded-[8px]">
                <div className="px-1 pt-4 ">
                  <div className="px-3">
                    <h5 className="text-base text-[#383838] ">Room Details</h5>
                  </div>
                </div>

                {/* ROOM TYPE */}
                <div>
                  <div className="px-4 pb-4 space-y-1 ">
                    <h4 className="text-base text-[#383838] ">
                      The Room:{" "}
                      <span className="font-normal capitalize ">
                        {/* {booking?.roomType} */}
                      </span>
                    </h4>

                    <p className="text-[#383838] text-base ">Room Facilities</p>
                    <div className="flex items-center gap-4 flex-wrap">
                      {roomAmenities?.map((item, index) => (
                        <div key={index} className=" flex items-center gap-2">
                          <span className="text-base text-[#4F4F4F] ">
                            {item.amenities.name}
                          </span>
                          <span>
                            {
                              roomAmenitiesIcons[
                                item.amenities.name.toLowerCase()
                              ]
                            }
                          </span>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* LEFT SIDE */}
            <div className="space-y-4 md:space-y-6 xl:space-y-12 xl:max-w-[681px] md:max-w-[519px] w-full">
              {/* USER INFO */}
              <div className=" border border-[#62626233/20] rounded-[8px] p-4 w-full xl:h-[120px]  ">
                {/* GUEST DETAILS */}
                <div className="flex items-center justify-between flex-wrap">
                  <div className="flex items-center gap-4">
                    <img
                      src={avatar}
                      alt="avatar"
                      className="size-[60px] rounded-full object-cover"
                    />
                    <div>
                      <p
                        className={`lg:hidden 
                        text-sm md:text-base font-medium capitalize ${getStatusBadgeColor(
                          status,
                          "text"
                        )} `}
                      >
                        {getStatus(status)}
                      </p>
                      <h5 className="text-[#383838] text-sm md:text-base  capitalize ">
                        {data?.reservation?.creator?.firstName}{" "}
                        {data?.reservation?.creator?.lastName}
                      </h5>
                      <p className="text-[#383838] text-sm md:text-base">
                        {data?.reservation?.creator?.emailAddress}
                      </p>
                      <p className="text-[#383838] text-sm md:text-base">
                        {data?.reservation?.creator?.phoneNumber}
                      </p>
                      <Link
                    to={`/chatreservations/${id}`}
                    className="hidden xl:flex text-[#07689F]"
                  >
                    Message Host
                  </Link>
                    </div>
                  </div>

                  {/* STATUS */}
                  <p
                    className={`hidden lg:flex p-2 rounded-[4px] text-sm xl:text-base border border-[#07689F] font-medium capitalize ${getStatusBadgeColor(
                      status,
                      "text"
                    )} `}
                  >
                    {getStatus(status)}
                  </p>
                </div>
              </div>

              {/* BOOKING DETAILS */}
              <div className="space-y-2 border border-[#62626233/20] rounded-[8px]">
                <div className="px-1 pt-4 ">
                  <div className="p-3 space-y-3">
                    <h5 className="text-base font-medium text-[#383838] ">
                      Booking Details
                    </h5>

                    {/* CHECK IN CHECK OUT */}
                    <div className=" flex items-center gap-6 ">
                      <div className="p-2 border-l border-[#07689F] ">
                        <h5 className="text-base text-[#383838] ">
                          Check - In
                        </h5>
                        <p className="text-base text-[#383838] capitalize ">
                          {checkInDate}
                        </p>
                      </div>

                      <div className="p-2 border-l border-[#07689F]  ">
                        <h5 className="text-base text-[#383838] ">
                          Check - Out
                        </h5>
                        <p className="text-base text-[#383838] capitalize ">
                          {checkOutDate}
                        </p>
                      </div>
                    </div>

                    {/* GUEST INFO */}
                    <div className="text-[#383838]">
                      <ul className="text-base capitalize ">
                        <p>Stays {duration} days</p>
                        <p>
                          selected rooms for: {data?.reservation?.occupants}
                        </p>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>

              {/* BOOKING ID */}
              <div className=" border border-[#62626233/20] rounded-[8px] py-2 ">
                <div className="px-4 pt-4 space-y-4 ">
                  <h5 className="text-[#383838] text-base capitalize ">
                    Reservation Ref
                  </h5>

                  <p className="text-black font-medium ">
                    {data?.reservation?.ref}
                  </p>
                  {status === "CHECKED_IN" ? (
                    <Link
                      to={`/scanreservation/${data?.reservation?.ref}`}
                      className="flex items-center justify-center gap-2 capitalize bg-laara-primary rounded-[36px] text-white font-medium w-full h-10"
                    >
                      <img
                        src={scanbarcode}
                        alt="scan icon"
                        loading="lazy"
                        className="size-[22px]"
                      />
                      scan code to check out
                    </Link>
                  ) : status === "UPCOMING" ? (
                    <div className="flex flex-col gap-3 lg:flex-row lg:items-center lg:justify-between">
                      <button
                        className="reservation-btn cancel-reservation-btn same-width"
                        // to="/cancel-reservation"
                      >
                        Cancel Reservation
                      </button>
                      <Link
                        to={`/scanreservation/${data?.reservation?.ref}`}
                        className="flex items-center justify-center gap-2 capitalize bg-laara-primary rounded-[36px] text-white font-medium w-full h-10"
                      >
                        <img
                          src={scanbarcode}
                          alt="scan icon"
                          loading="lazy"
                          className="size-[22px]"
                        />
                        scan code to check in
                      </Link>
                    </div>
                  ) : status === "AWAITING_REVIEW" ? (
                    <Link
                      to={`/reviewreservation/${data?.reservation?.ref}`}
                      className="flex items-center justify-center gap-2 capitalize text-laara-primary rounded-[36px] border border-laara-primary font-medium w-full h-10"
                    >
                      <Heart className="text-laara-primary size-[18px]" />
                      Give a review
                    </Link>
                  ) : status === "COMPLETED" ? (
                    <Link
                      to={`/reviewreservation/${data?.reservation?.ref}`}
                      className="ml-auto flex items-center justify-center gap-2 capitalize text-laara-primary rounded-[36px] border border-laara-primary font-medium px-5 h-10"
                    >
                      Give a review
                    </Link>
                  ) : status === "PENDING_PAYMENT" ? (
                    <div className="flex flex-col gap-3 md:flex-row md:items-end md:justify-between">
                      {/* <Link
                        to={`/scanreservation/${data?.reservation?.ref}`}
                        className="flex items-center justify-center gap-2 capitalize bg-laara-primary rounded-[36px] text-white font-medium p-3.5 h-10 "
                      >
                        <img
                          src={scanbarcode}
                          alt="scan icon"
                          loading="lazy"
                          className="size-[22px]"
                        />
                        scan code to check in
                      </Link> */}
                      <button className=" reservation-btn cancel-reservation-btn w-full md:w-fit h-10 text-base ">
                        Cancel Reservation
                      </button>
                      <button
                        onClick={() => dispatch(launchPayModal())}
                        className=" ml-auto flex items-center justify-center text-laara-primary text-base bg-laara-secondary rounded-[36px] border border-laara-primary font-medium w-full md:w-fit px-8 py-3.5 h-10"
                      >
                        Pay now
                      </button>
                      {/* <Link
                        to={`/reviewreservation/${data?.reservation?.ref}`}
                        className="ml-auto flex items-center justify-center gap-2 capitalize text-laara-primary rounded-[36px] border border-laara-primary font-medium px-5 h-10"
                      >
                        Give a review
                      </Link> */}
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
          </section>
        </section>
      </section>
    </section>
  );
};

export default ReservationDetails;
