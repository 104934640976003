import React, { useState, useCallback, useEffect } from "react";
import { FiUploadCloud, FiX } from "react-icons/fi";
import { useDropzone } from "react-dropzone";
import Consider from "@/components/Listings/Consider";
import { setRoomPhotos } from "@/redux/Listing/listingSlice";
import { useSelector, useDispatch } from "react-redux";

const BRPhotos = () => {
    const selectedPhotos =
        useSelector((state) => state.hostListing.formData.roomType.roomphotos) ||
        [];
    const dispatch = useDispatch();
    const [photos, setPhotos] = useState(selectedPhotos);

    const onDrop = useCallback((acceptedFiles) => {
        setPhotos((prevPhotos) => [
            ...prevPhotos,
            ...acceptedFiles.map((file) =>
                Object.assign(file, {
                    preview: URL.createObjectURL(file),
                })
            ),
        ]);
    }, []);

    useEffect(() => {
        dispatch(setRoomPhotos(photos));
    }, [photos, dispatch]);

    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        onDrop,
        accept: {
            "image/jpeg": [".jpg", ".jpeg"],
            "image/png": [".png"],
        },
        maxSize: 40 * 1024 * 1024, // 40MB
    });

    const removePhoto = (index) => {
        setPhotos((prevPhotos) => prevPhotos.filter((_, i) => i !== index));
    };

    return (
        <form>
            <div>
                <h2 className="font-medium text-xl mb-8">
                    What does your boadroom look like?
                </h2>
                <div className="flex flex-wrap lg:flex-nowrap gap-20">
                    <div className="w-[1077px] h-[379px] border overflow-auto mx-auto bg-laara-secoondary rounded-lg">
                        <p className="text-base font-medium text-left mb-2 p-1">
                            <span className="font-bold text-black">
                                Upload at least 5 high quality photos of the room.
                            </span>
                            <span className="text-[#6D6D6D]">
                                {" "}
                                Photos should showcase the boardroom seating and facilities
                            </span>
                        </p>
                        <div
                            {...getRootProps()}
                            className="border-2 border-dashed border-black rounded-lg p-3 mx-3 mb-2 text-center cursor-pointer h-[270px] overflow-y-auto flex flex-col justify-center items-center"
                        >
                            <input {...getInputProps()} />
                            {photos.length > 0 ? (
                                <div className="grid grid-cols-2 gap-2 w-full">
                                    {photos.map((photo, index) => (
                                        <div key={index} className="relative">
                                            <img
                                                src={photo.preview}
                                                alt={`Room photo ${index + 1}`}
                                                className="w-full h-30 object-cover rounded"
                                            />
                                            <button
                                                type="button"
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                    removePhoto(index);
                                                }}
                                                className="absolute top-1 right-1 bg-red-500 text-white rounded-full p-0.5 text-xs"
                                            >
                                                <FiX size={20} />
                                            </button>
                                        </div>
                                    ))}
                                    <div className="flex items-center justify-center h-30 bg-gray-100 rounded">
                                        <FiUploadCloud className="text-2xl text-gray-400" />
                                    </div>
                                </div>
                            ) : (
                                <div className="flex flex-col items-center justify-center w-full">
                                    <FiUploadCloud
                                        className="text-2xl text-gray-400 mb-1 "
                                        size={100}
                                    />
                                    {isDragActive ? (
                                        <p className="text-sm text-gray-500">
                                            Drop the files here ...
                                        </p>
                                    ) : (
                                        <>
                                            <p className="text-base font-medium text-left mb-2">
                                                Drag and drop or
                                            </p>
                                            <button
                                                type="button"
                                                className="w-full max-w-[223px] py-3 px-6 text-l text-laara-primary font-semibold border border-laara-primary rounded-full flex items-center justify-center"
                                            >
                                                <svg
                                                    width="25"
                                                    height="25"
                                                    viewBox="0 0 25 25"
                                                    fill="none"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    className="mr-3"
                                                >
                                                    <path
                                                        d="M7.26017 22.5H17.7402C20.5002 22.5 21.6002 20.81 21.7302 18.75L22.2502 10.49C22.3902 8.33 20.6702 6.5 18.5002 6.5C17.8902 6.5 17.3302 6.15 17.0502 5.61L16.3302 4.16C15.8702 3.25 14.6702 2.5 13.6502 2.5H11.3602C10.3302 2.5 9.13017 3.25 8.67017 4.16L7.95017 5.61C7.67017 6.15 7.11017 6.5 6.50017 6.5C4.33017 6.5 2.61017 8.33 2.75017 10.49L3.27017 18.75C3.39017 20.81 4.50017 22.5 7.26017 22.5Z"
                                                        stroke="#0466C8"
                                                        strokeWidth="1.5"
                                                        strokeLinecap="round"
                                                        strokeLinejoin="round"
                                                    />
                                                    <path
                                                        d="M11 8.5H14"
                                                        stroke="#0466C8"
                                                        strokeWidth="1.5"
                                                        strokeLinecap="round"
                                                        strokeLinejoin="round"
                                                    />
                                                    <path
                                                        d="M12.5 18.5C14.29 18.5 15.75 17.04 15.75 15.25C15.75 13.46 14.29 12 12.5 12C10.71 12 9.25 13.46 9.25 15.25C9.25 17.04 10.71 18.5 12.5 18.5Z"
                                                        stroke="#0466C8"
                                                        strokeWidth="1.5"
                                                        strokeLinecap="round"
                                                        strokeLinejoin="round"
                                                    />
                                                </svg>

                                                <span>upload photos</span>
                                            </button>
                                        </>
                                    )}
                                    <p className="text-sm font-normal text-[#6D6D6D] mt-2">
                                        JPG/JPEG or PNG, maximum 40MB each
                                    </p>
                                </div>
                            )}
                            <div className="self-center mt-3.5 text-black">Upload a maximum of 200 photos</div>
                        </div>
                    </div>
                    <div className="w-full lg:w-1/2 p-4 ">
                        <Consider
                            title={
                                "Here are some tips on taking great photos of your property"
                            }
                            data={[
                                "If you dont know who took a photo its best not to use it",
                                "Only use photos others have taken if you have permission",
                            ]}
                        />
                    </div>
                </div>
            </div>
        </form>
    );
};

export default BRPhotos;
