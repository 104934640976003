import { useEffect, useMemo, useState } from "react";
import { reviewsData } from "@/constants/data";
import ReviewItem from "@/components/HostReview/ReviewItem";
import arrowleft from "../../assets/shared/arrowleft.svg"
import progress from "../../assets/Reviews/progress.svg"
import star from "../../assets/shared/star.svg"
import arrowdropdown from "../../assets/Reviews/arrowdropdown.svg"
import { useLocation, useNavigate } from "react-router-dom";


export default function HostReview() {
  const [showMore, setShowMore] = useState(false); 
  const [activeTab, setActiveTab] = useState('Public');
  const navigate = useNavigate();
  const location = useLocation();

  const propertyIds = useMemo(() => {
    return location.state?.propertyIds || [];
  }, [location.state]);

  useEffect(() => {
    console.log('Property IDs received:', propertyIds);
  }, [propertyIds]);

  const handleToggle = () => {
    setShowMore(!showMore);
  };
  return (
    <div className="flex justify-center items-center px-16  max-md:px-5">
    <div className="flex flex-col px-2 pt-9 w-full max-w-screen-xl max-md:max-w-full">
      <div className="flex gap-4 py-4 text-2xl tracking-wide leading-7 text-center text-neutral-700 max-md:flex-wrap">
        <button>
        <img
          loading="lazy"
          src={arrowleft}
          className="shrink-0 my-auto w-6 aspect-square"
          onClick={() => navigate(-1)}
        />
        </button>
        <div className="max-md:max-w-full text-lg font-medium"> Reviews</div>
      </div>
      <div className="justify-end p-2 mt-8 max-md:max-w-full">
        <div className="flex gap-5 max-md:flex-col max-md:gap-0">
          <div className="flex flex-col w-6/12 max-md:ml-0 max-md:w-full">
            <div className="flex grow gap-5 py-2 pl-2 text-xl tracking-wide leading-6 border-r border-solid border-zinc-500 border-opacity-50 max-md:flex-wrap max-md:mt-8">
              <div className="flex flex-col justify-center items-center p-4 text-center text-white whitespace-nowrap bg-laara-primary rounded-2xl h-[40px] w-[40px]">
                <span className="justify-center text-sm">4.69</span>
              </div>
              <div className="justify-center p-2 text-neutral-700 max-md:max-w-full text-sm">
                Your overall review score based on 678 reviews
              </div>
            </div>
          </div>
          <div className="flex flex-col ml-5 w-6/12 max-md:ml-0 max-md:w-full">
            <div className="flex grow gap-3 py-2 pr-2 max-md:flex-wrap max-md:mt-8">
              <img
                loading="lazy"
                src={progress}
                className="shrink-0 aspect-square w-[40px]"
                alt="Main Image"
              />
              <div className="flex gap-2 my-auto">
                <div className="flex gap-px my-auto">
                  {Array(5).fill().map((_, index) => (
                    <img
                      key={index}
                      loading="lazy"
                      src={star}
                      className="shrink-0 w-4 aspect-square fill-yellow-500"
                      alt={`Star ${index + 1}`}
                    />
                  ))}
                </div>
                <div className="text-sm font-medium text-zinc-600">5.0</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="flex gap-5 py-2 pr-2 mt-8 font-normal max-md:flex-wrap max-md:pr-5 text-base">
      <div
        className={`cursor-pointer justify-center border-b border-solid  ${
          activeTab === 'Public' ? 'text-sky-600 border-sky-600' : 'text-zinc-600'
        }`}
        onClick={() => setActiveTab('Public')}
      >
        Public Reviews
      </div>
      <div
        className={`cursor-pointer justify-center border-b border-solid ${
          activeTab === 'Private' ? 'text-sky-600 border-sky-600' : 'text-zinc-600'
        }`}
        onClick={() => setActiveTab('Private')}
      >
        Private Reviews
      </div>
    </div>
      <div className="pt-4 mt-8 max-md:max-w-full">
        <div className="flex gap-5 max-md:flex-col max-md:gap-0">
          <div className="flex flex-col w-[200px] max-md:ml-0 max-md:w-full">
            <div className="flex flex-col max-md:mt-10 font-normal">
              <div className="justify-center text-base whitespace-nowrap text-neutral-700">
                Categories
              </div>
              <div className="flex flex-col mt-5">
                <div className="flex flex-col">
                  <div className="text-sm text-zinc-600">
                    Cleanliness
                  </div>
                  <div className="flex gap-2">
                  <div className="flex gap-px">
                      {Array(5).fill().map((_, index) => (
                        <img
                          key={index}
                          loading="lazy"
                          src={star}
                          className="shrink-0 w-4 aspect-square fill-yellow-500"
                          alt={`Star ${index + 1}`}
                        />
                      ))}
                    </div>
                    <div className="text-sm text-zinc-600">
                      5.0
                    </div>
                  </div>
                </div>
                <div className="flex flex-col mt-3">
                  <div className="text-sm text-zinc-600">
                    Accuracy
                  </div>
                  <div className="flex gap-2">
                  <div className="flex gap-px">
                    {Array(5).fill().map((_, index) => (
                      <img
                        key={index}
                        loading="lazy"
                        src={star}
                        className="shrink-0 w-4 aspect-square fill-yellow-500"
                        alt={`Star ${index + 1}`}
                      />
                    ))}
                  </div>
                    <div className="text-sm text-zinc-600">
                      5.0
                    </div>
                  </div>
                </div>
                <div className="flex flex-col mt-3">
        <div className="text-sm text-zinc-600">
          Communication
        </div>
        <div className="flex gap-2">
        <div className="flex gap-px">
            {Array(5).fill().map((_, index) => (
              <img
                key={index}
                loading="lazy"
                src={star}
                className="shrink-0 w-4 aspect-square fill-yellow-500"
                alt={`Star ${index + 1}`}
              />
            ))}
          </div>
          <div className="text-sm text-zinc-600">
            5.0
          </div>
        </div>
      </div>
      {showMore && (
        <div className="flex flex-col mt-3">
          {/* Additional content here */}
          <div className="text-xl font-semibold text-zinc-600">
            Additional Information
          </div>
          <div className="flex gap-2">
          <div className="flex gap-px">
          {Array(5).fill().map((_, index) => (
            <img
              key={index}
              loading="lazy"
              src={star}
              className="shrink-0 w-4 aspect-square fill-yellow-500"
              alt={`Star ${index + 1}`}
            />
          ))}
        </div>
            <div className="text-sm font-medium text-zinc-600">
              5.0
            </div>
          </div>
        </div>
      )}
      <div className="flex gap-0 items-center justify-between  mt-5 text-sm font-normal text-center text-neutral-700 cursor-pointer" onClick={handleToggle}>
        <span className="ml-[30%]">{showMore ? 'See less' : 'See more'}</span>
        <img
          loading="lazy"
          src={arrowdropdown}
          className="shrink-0 my-auto w-6 aspect-square"
          alt="Toggle icon"
        />
      </div>
    </div>
            </div>
          </div>
          <div className="flex flex-col ml-5 w-[82%] max-md:ml-0 max-md:w-full">
          <div className="flex flex-col grow max-md:mt-10 max-md:max-w-full">
            <div className="justify-center self-start text-base font-normal tracking-normal leading-9 text-neutral-600">
             {activeTab} Reviews
            </div>
            <div className="flex flex-col px-2 pt-2 mt-1 max-md:max-w-full">
              <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                {reviewsData.map((review) => (
                  <ReviewItem key={review.id} review={review} />
                ))}
              </div>
            </div>
          </div>
        </div>
        </div>
      </div>
    </div>
  </div>
  )
}
