
import PropTypes from 'prop-types';

const PromotionCard = ({ details }) => {
  return (
    <div className="flex flex-col bg-white border border-gray-200 rounded-lg lg:w-[394.67px] between-2  lg:h-[335px] hover:shadow-lg p-6 mb-6">
      <h2 className="text-xl font-bold mb-2">{details.hotelName}</h2>
      <p className="text-lg text-gray-700 mb-2">{details.offer}</p>
      <p className="text-gray-600 mb-1">Start Date: {details.startDate}</p>
      <p className="text-gray-600 mb-1">End Date: {details.endDate}</p>
      <p className="text-green-600 mb-2">Price: {details.price}</p>
      <p className="text-red-600 line-through mb-2">Original Price: {details.originalPrice}</p>
      <p className="text-blue-500 font-bold mb-4">{details.discount}</p>
      <div className="flex gap-4">
        <button className="bg-red-500 text-white px-4 py-2 rounded hover:bg-red-600 transition-all">Delete</button>
        <button className="bg-gray-500 text-white px-4 py-2 rounded hover:bg-gray-600 transition-all">Delete Details</button>
      </div>
    </div>
  );
};

PromotionCard.propTypes = {
  details: PropTypes.shape({
    hotelName: PropTypes.string.isRequired,
    offer: PropTypes.string.isRequired,
    startDate: PropTypes.string.isRequired,
    endDate: PropTypes.string.isRequired,
    price: PropTypes.string.isRequired,
    originalPrice: PropTypes.string.isRequired,
    discount: PropTypes.string.isRequired,
  }).isRequired,
};

export default PromotionCard;
