import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  openPayModal: false,
  reservationComplete: false,
};

const payNowModalSlice = createSlice({
  name: "payNowModal",
  initialState,
  reducers: {
    launchPayModal: (state) => {
      state.openPayModal = true;
    },
    closePayModal: (state) => {
      state.openPayModal = false;
    },
    launchCompleteReservation: (state) => {
      state.openPayModal = false;
      state.reservationComplete = true;
    },
    closeCompleteReservation: (state) => {
      state.reservationComplete = false;
    },
  },
});

export const {
  launchPayModal,
  closePayModal,
  launchCompleteReservation,
  closeCompleteReservation,
} = payNowModalSlice.actions;
export default payNowModalSlice.reducer;
