import { useState, useEffect } from "react";
import Consider from "@/components/Listings/Consider";
import { Link, useParams, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLessThan } from '@fortawesome/free-solid-svg-icons';
import { getPropertyById, editPropertyName } from "@/api"; // Use editPropertyName instead of editPropertyDetails

const EditPropertyName = () => {
  const { id } = useParams(); // Retrieve id from URL parameters
  const navigate = useNavigate();

  const [name, setName] = useState("");
  const [property, setProperty] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  // Fetch property details by ID
  useEffect(() => {
    if (!id) {
      console.error("ID is undefined");
      return;
    }

    const fetchProperty = async () => {
      try {
        setLoading(true);
        const propertyData = await getPropertyById(id); // Fetch property by ID
        setProperty(propertyData);
        setName(propertyData?.property?.name || ""); // Set existing property name, fallback empty
      } catch (error) {
        console.error("Error fetching property:", error.message);
        setError("Failed to load property details. Please try again.");
      } finally {
        setLoading(false);
      }
    };

    fetchProperty();
  }, [id]);

  // Handle form submission
  const handleSubmit = async (event) => {
    event.preventDefault();
    if (property) {
      try {
        setLoading(true);
        setError(null);

        console.log(`Updating property name with ID: ${id}`, { name });

        // Use editPropertyName instead of editPropertyDetails
        const response = await editPropertyName(id, { name });
        console.log("API response for updating property name:", response);

        if (response && response.status) { // Check for success response
          setProperty(prev => ({ ...prev, property: { ...prev.property, name } }));
          console.log("Property name updated successfully");

          // Redirect to the view property page after successful update
          navigate(`/view-property/${id}`);
        } else {
          console.error("Failed to update property name:", response);
          setError("Failed to update property name. Please try again.");
        }
      } catch (error) {
        console.error("Error updating property name:", error.message);
        setError("Failed to update property name. Please try again.");
      } finally {
        setLoading(false);
      }
    } else {
      console.error("No property to update");
    }
  };

  return (
    <div className="p-8 bg-base-white">
      <section className="top h-16 flex items-center p-2 gap-3">
        <div className="flex items-center gap-4 text-lg text-gray-600 w-[700px]">
          <Link to={`/view-property/${id}`}>
            <FontAwesomeIcon icon={faLessThan} className="text-base" />
          </Link>
          <h3>Name of the property</h3>
        </div>
      </section>

      {loading ? (
        <p>Loading...</p> // Show loading state
      ) : (
        <form className="flex flex-col p-2" onSubmit={handleSubmit}>
          <h4 className="font-semibold text-[32px] leading-[42px]">
            What is the name of your place?
          </h4>
          <div className="flex items-start gap-4 mt-5">
            <div className="p-4 flex flex-col gap-2 bg-laara-secondary/35 max-w-[728px] w-full h-[400px]">
              <label htmlFor="name" className="text-base">
                Property name
              </label>
              <input
                type="text"
                id="name"
                placeholder="Name"
                value={name}
                className="w-[20rem] border py-2 px-3 rounded-lg block outline-none bg-transparent text-sm"
                onChange={(e) => setName(e.target.value)}
              />
            </div>

            <Consider
              title={"What should I consider when choosing a name"}
              data={["Stick to the facts", "Avoid abbreviations", "Keep it short"]}
            />
          </div>

          {error && (
            <div className="text-red-500">
              {error}
            </div>
          )}

          <div className="w-full flex justify-end">
            <button
              type="submit"
              className="mt-5 py-2 px-4 bg-blue-500 text-white rounded-[36px] w-[216px] h-[55px]"
              disabled={loading} // Disable button when loading
            >
              {loading ? "Saving..." : "Save Changes"} {/* Show loading state */}
            </button>
          </div>
        </form>
      )}
    </div>
  );
};

export default EditPropertyName;
