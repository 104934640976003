import React from 'react';
import ProgressBar from './ProgressBar'; // Import the ProgressBar component
 
 function BookingPageStart() {
   return (
    <div className='p-4 w-full'>
      <ProgressBar currentStep={2} />
      <div className="flex flex-col lg:flex-row gap-8 p-8">
        
        {/* Left Section */}
        <div className="lg:w-1/3 border border-gray-300 p-6 rounded-lg ">
          
          
          {/* Hotel Information */}
          <div className="mb-4 border  p-4 rounded-lg">
            <h2 className="text-lg font-semibold mb-4">Almost done! Just fill in the * required info</h2>
            <h3 className="text-lg font-semibold">Hotel</h3>
            <p className="text-gray-700">Golden Star Hotel</p>
            <p className="text-gray-500">Kiambu 00232, Kenya</p>
            <div className="flex flex-wrap gap-4 mt-2 text-gray-600">
              <span>📺 Flatscreen TV</span>
              <span>❄️ Air Conditioning</span>
              <span>🌐 Free WiFi</span>
              <span>🕒 24hrs Front Desk</span>
              <span>🅿️ Parking Area</span>
            </div>
          </div>
  
          {/* Booking Details */}
          <div className="mb-4 border p-4 rounded-lg">
            <h3 className="text-lg font-semibold">Your booking details</h3>
            <div className="flex justify-between text-gray-700">
              <div>
                <p>Check in</p>
                <p className="text-sm">Wed 7 Aug 2024</p>
                <p className="text-sm">13:00 - 20:00</p>
              </div>
              <div className='border-l-2 border-gray-400 '></div>
              <div>
                <p>Check out</p>
                <p className="text-sm">Wed 7 Aug 2024</p>
                <p className="text-sm">13:00 - 20:00</p>
              </div>
            </div>
            <div className="text-gray-700 mt-2 mb-2 font-semibold flex ">
              <span className='w-full'>Number of nights </span>
              <span className='flex justify-end w-full'>2 Nights</span>
              </div>
            <hr  />
            <div className='mt-3 mb-2'>
              <h3 className='font-semibold'>Your Selection</h3>
              <span>1 room for 2 Adults</span>
            </div>
            <p className="text-blue-600 mt-2 underline">Change your selection</p>
          </div>
  
          {/* Price Summary */}
          <div className="mb-4 border p-4 rounded-lg ">
            <h3 className="text-lg font-semibold">Your price summary</h3>
            <div className="bg-blue-100 p-4 rounded-lg">
              <div className='flex gap-11'>
                <p className="font-semibold">Total Price</p>
                <div className=' ml-5'>
                  <span>Ksh 6450</span>
                  <p className="text-sm">Including taxes & charges</p>
                  <p className="text-sm">In property currency: US$50</p>
                </div>
              </div>
              
            </div>
            <p className="font-semibold mt-2 text-sm mb-2">Price Details</p>
            <p className="text-xs">
              Includes KES 950.05 in taxes and charges<br/>
              <div className='flex gap-14 pt-2 pb-2'>
                <div>
                16% VAT KES 844.49<br/>
                KES 844.49
                </div>
                <div>
                2% City tax KES 105.5<br/>
                KES 105.55
                </div>
              </div>
              This price is converted to show you the approximate cost in KES. You'll pay in US$ or KES. The exchange rate may change before you pay.
            </p>
            <p className="text-blue-600 mt-2 underline text-sm">Hide Details</p>
          </div>
    
          {/* Cancellation Fee */}
          <div className="mb-4 border p-4 rounded-lg">
            <h3 className="text-lg font-semibold">Cancellation fee</h3>
            <p className="text-gray-500 text-sm">Free cancellation before 18:00 on the day of arrival</p>
          </div>
        </div>
  
        {/* Right Section */}
        <div className="lg:w-2/3  p-4 rounded-lg border border-gray-300 ">
            <div className="flex items-center p-4 bg-gray-100 rounded-lg mb-6">
            <img 
              src="https://via.placeholder.com/50" 
              alt="User Profile" 
              className="w-12 h-12 rounded-full mr-4"
            />
            <div>
              <p className="font-medium">Please sign in, Jeff Kibe</p>
              <p className='font-base text-sm'>Book with your saved Details</p>
              <a href="#" className="text-blue-600">Sign in</a>
            </div>
          </div>

      {/* Form title */}
      <div className='border p-4 rounded-lg'>
        <h2 className="text-xl font-semibold mb-4">Enter your details</h2>

        {/* Form section */}
        <form>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mb-6">
              {/* First Name */}
              <div>
                <label htmlFor="firstName" className="block text-sm font-medium mb-1">
                  First name<span className="text-red-500">*</span>
                </label>
                <input
                  type="text"
                  id="firstName"
                  className="w-full border border-gray-300 rounded-lg px-3 py-2"
                  placeholder="First name"
                  required
                />
              </div>

              {/* Last Name */}
              <div>
                <label htmlFor="lastName" className="block text-sm font-medium mb-1">
                  Last name<span className="text-red-500">*</span>
                </label>
                <input
                  type="text"
                  id="lastName"
                  className="w-full border border-gray-300 rounded-lg px-3 py-2"
                  placeholder="Last name"
                  required
                />
              </div>
            </div>

            {/* Email */}
            <div className="mb-6">
              <label htmlFor="email" className="block text-sm font-medium mb-1">
                Email Address<span className="text-red-500">*</span>
              </label>
              <input
                type="email"
                id="email"
                className="w-1/2 border border-gray-300 rounded-lg px-3 py-2"
                placeholder="Email Address"
                required
              />
            </div>

            {/* Country/Region */}
            <div className="mb-6">
              <label htmlFor="country" className="block text-sm font-medium mb-1">
                Country/region<span className="text-red-500">*</span>
              </label>
              <select
                id="country"
                className="w-1/2 border border-gray-300 rounded-lg px-3 py-2"
                required
              >
                <option value="Kenya">Kenya</option>
                <option value="USA">USA</option>
                <option value="UK">UK</option>
              </select>
            </div>

            {/* Phone Number */}
            <div className="mb-6">
              <label htmlFor="phone" className="block text-sm font-medium mb-1">
                Telephone (mobile number preferred)<span className="text-red-500">*</span>
              </label>
              <div className="flex">
                <select
                  id="countryCode"
                  className="border border-gray-300 rounded-l-lg px-3 py-2"
                >
                  <option value="+254">+254</option>
                  <option value="+1">+1</option>
                  <option value="+44">+44</option>
                </select>
                <input
                  type="tel"
                  id="phone"
                  className="w-2/5 border border-gray-300 rounded-r-lg px-3 py-2"
                  placeholder="Phone number"
                  required
                />
              </div>
              <small className="text-gray-500">To help the accommodation be in touch</small>
            </div>

            {/* Booking options */}
            <div className="mb-6">
              <label className="block text-sm font-medium mb-1">Who are booking?</label>
              <div className="space-y-2">
                <div className="flex items-center">
                  <input
                    type="checkbox"
                    id="mainGuest"
                    className="form-checkbox h-5 w-5 text-blue-600"
                  />
                  <label htmlFor="mainGuest" className="ml-2 text-gray-700">
                    Am the main guest
                  </label>
                </div>
                <div className="flex items-center">
                  <input
                    type="checkbox"
                    id="bookingForSomeoneElse"
                    className="form-checkbox h-5 w-5 text-blue-600"
                  />
                  <label htmlFor="bookingForSomeoneElse" className="ml-2 text-gray-700">
                    Booking for someone else
                  </label>
                </div>
              </div>
            </div>
          </form>
       </div>
        {/* House rules */}
        <div className="p-4 rounded-lg border mb-5 mt-5">
          <h3 className="font-semibold mb-2">Review house rules</h3>
          <p className='mb-2 text-base'>Your host would like you to agree to the following house rules:</p>
          <ul className="list-disc list-inside text-gray-700">
            <li>No smoking</li>
            <li>Maximum Occupancy</li>
            <li>No Parties or Events</li>
          </ul>
          <p className="mt-2 text-gray-600">
            By continuing to the next step, you are agreeing to these house rules.
          </p>
        </div>

          {/* Make Payments Button */}
          <div className="mt-6 flex justify-end ">
            <button className=" duration-300 text-blue-500 py-2 px-4 border border-b-2 border-blue-400 rounded-full hover:text-white hover:bg-blue-600">
              Continue
            </button>
          </div>
        </div>
      </div>
     </div>
   );
 }
 
 export default BookingPageStart;
 
