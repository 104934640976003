import { useState,useEffect } from 'react';
export default function GuestOverlay({ updateTotalGuests }) {
  const [adults, setAdults] = useState(3);
  const [children, setChildren] = useState(0);
  const [infants, setInfants] = useState(0);
  const increment = (setter) => () => setter((prev) => prev + 1);
  const decrement = (setter) => () => setter((prev) => Math.max(0, prev - 1));

  useEffect(() => {
    updateTotalGuests(adults, children, infants);
  }, [adults, children, infants, updateTotalGuests]);  

  return (
    <div className="mt-5 md:mt-3 -ml-52 max-w-lg z-50 absolute md:-ml-44 flex flex-col justify-center p-5 bg-white rounded-xl border-0 border-solid shadow-2xl border-neutral-400">
      <div className="flex flex-col">
        <div className="flex gap-0 justify-between text-base tracking-wide whitespace-nowrap mb-6">
          <div className="my-auto text-zinc-800">Adults</div>
          <div className="flex items-center gap-1 leading-[56%] text-neutral-500">
            <button onClick={decrement(setAdults)} className="flex justify-center items-center w-8 h-8 border border-solid border-neutral-500 rounded-full text-xl">
              -
            </button>
            <div className="flex justify-center items-center w-8 h-8 text-sky-600 rounded-full">
              {adults}
            </div>
            <button onClick={increment(setAdults)} className="flex justify-center items-center w-8 h-8 border border-solid border-neutral-500 rounded-full text-xl">
              +
            </button>
          </div>
        </div>
        <div className="flex gap-0 justify-between mt-6">
          <div className="flex flex-col">
            <div className="text-base tracking-wide text-zinc-800">Children</div>
            <div className="text-sm tracking-wide text-neutral-500">Ages 3 to 16</div>
          </div>
          <div className="flex items-center gap-1 self-start text-base tracking-wide leading-loose whitespace-nowrap text-neutral-500">
            <button onClick={decrement(setChildren)} className="flex justify-center items-center w-8 h-8 border border-solid border-neutral-400 rounded-full text-xl">
              -
            </button>
            <div className="flex justify-center items-center w-8 h-8 text-sky-600 rounded-full">
              {children}
            </div>
            <button onClick={increment(setChildren)} className="flex justify-center items-center w-8 h-8 border border-solid border-neutral-500 rounded-full text-xl">
              +
            </button>
          </div>
        </div>
        <div className="flex gap-0 justify-between mt-6">
          <div className="flex flex-col">
            <div className="text-base tracking-wide text-zinc-800">Infants</div>
            <div className="text-sm tracking-wide text-neutral-500">Below 2</div>
          </div>
          <div className="flex items-center gap-1 self-start text-base tracking-wide leading-loose whitespace-nowrap text-neutral-500">
            <button onClick={decrement(setInfants)} className="flex justify-center items-center w-8 h-8 border border-solid border-neutral-400 rounded-full text-xl">
              -
            </button>
            <div className="flex justify-center items-center w-8 h-8 text-sky-600 rounded-full">
              {infants}
            </div>
            <button onClick={increment(setInfants)} className="flex justify-center items-center w-8 h-8 border border-solid border-neutral-500 rounded-full text-xl">
              +
            </button>
          </div>
        </div>
        <div className="flex gap-2.5 mt-6">
         <input type="checkbox" id="pets" className="shrink-0 self-start h-4 w-4 rounded border border-solid border-neutral-400 cursor-pointer" />
            <div className="flex flex-col">
                <label htmlFor="pets" className="text-sm tracking-wide leading-4 text-zinc-800 cursor-pointer">
                I am travelling with pets
                </label>
                <span className="mt-1.5 text-xs tracking-normal text-neutral-500">
                Only stays that allow pets will be provided
                </span>
            </div>
         </div>
    </div>
</div>
  );
}
